import { PaymentAPI } from '@service/payment.service';
import { useAppDispatch } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { notification } from 'antd';
import React, { forwardRef, useImperativeHandle } from 'react';

export interface AddPaymentFormRef {
    onFormSubmit?: () => void;
}

export interface AddPaymentFormProps {
    setLoading: (loading: boolean) => void;
    onCancelModal: () => void;
}

function AddPaymentForm(props: AddPaymentFormProps, ref: React.Ref<AddPaymentFormRef>) {
    const [api, contextHolder] = notification.useNotification();
    const { setLoading, onCancelModal } = props;
    const dispatch = useAppDispatch();

    const elements = useElements();

    const stripe = useStripe();

    useImperativeHandle(ref, () => ({
        onFormSubmit: () => {
            handleSubmit();
        },
    }));

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        setLoading(true);
        // Use Stripe.js to confirm the payment method without redirecting
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement as any,
        });

        if (error) {
            api.error({
                message: 'Payment Creation Failed',
                description: error.message,
            });
            setLoading(false);
        } else {
            await PaymentAPI.addPayment(paymentMethod.id)
                .then((data) => {
                    console.log(data);
                    api.success({
                        message: 'Successfully Payment Creation',
                        description: 'Payment method added successfully!',
                    });
                    setLoading(false);
                    onCancelModal();
                    getPayments();
                })
                .catch((error) => {
                    console.log(error);
                    api.error({
                        message: 'Payment ID Creation Failed',
                        description:
                            'Payment ID creation failed in HASH but payment creation success in stripe',
                    });
                    setLoading(false);
                });
            // Make API call to your backend or directly to Stripe's API with paymentMethod.id
            console.log(paymentMethod);
        }
    };

    const getPayments = () => {
        dispatch(getPaymentMethods());
    };
    return (
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
            {contextHolder}
            <CardElement />
        </div>
    );
}

export default forwardRef<AddPaymentFormRef, AddPaymentFormProps>(AddPaymentForm);
