import { FC } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import './PieChartWithPaddingAngle.scss';
import { IInvoiceTypeCount } from '@models/dashboard.model';
import { useTranslation } from 'react-i18next';

interface PieChartProps {
    data: IInvoiceTypeCount[];
}

const COLORS = ['#C5B5FD', '#7CD4FD', '#FEC84B', '#285FF6', '#85AFFF', '#98A2B3'];

const renderLegend = (props: any) => {
    const { payload } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();

    return (
        <div className='legend-wrapper'>
            <ul className='custom-legend'>
                {payload.slice(0, 3).map((entry: any, index: any) => (
                    <li key={`item-${index}`}>
                        <span className='dot' style={{ backgroundColor: entry.color }}></span>
                        <span className='count'>{entry.payload.value}</span>
                        {entry.value === 'Debit Note' && t('DEBIT_NOTE')}
                        {entry.value === 'Simplified Credit Note' && t('SIMPLIFIED_CREDIT_NOTE')}
                        {entry.value === 'Simplified Tax Invoice' && t('SIMPLIFIED_TAX_INVOICE')}
                    </li>
                ))}
            </ul>
            <ul className='custom-legend'>
                {payload.slice(3, 6).map((entry: any, index: any) => (
                    <li key={`item-${index}`}>
                        <span className='dot' style={{ backgroundColor: entry.color }}></span>
                        <span className='count'>{entry.payload.value}</span>
                        {entry.value === 'Credit Note' && t('CREDIT_NOTE')}
                        {entry.value === 'Simplified Debit Note' && t('SIMPLIFIED_DEBIT_NOTE')}
                        {entry.value === 'Standard Tax Invoice' && t('STANDARD_TAX_INVOICE')}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const CustomTooltip = (props: any) => {
    const { active, payload } = props;

    const { t } = useTranslation();

    if (active && payload && payload.length) {
        const singleData = payload[0].payload;

        const nameTranslations: { [key: string]: string } = {
            'Debit Note': t('DEBIT_NOTE'),
            'Simplified Credit Note': t('SIMPLIFIED_CREDIT_NOTE'),
            'Simplified Tax Invoice': t('SIMPLIFIED_TAX_INVOICE'),
            'Credit Note': t('CREDIT_NOTE'),
            'Simplified Debit Note': t('SIMPLIFIED_DEBIT_NOTE'),
            'Standard Tax Invoice': t('STANDARD_TAX_INVOICE'),
        };

        const translatedLabel = nameTranslations[singleData.Name] || singleData.Name;

        return (
            <div className='tooltip-wrapper'>
                <p>
                    {translatedLabel}: {payload[0].value}
                </p>
            </div>
        );
    }

    return null;
};

const PieChartWithPaddingAngle: FC<PieChartProps> = ({ data }) => {
    return (
        <ResponsiveContainer width={'100%'} height={387}>
            <PieChart>
                <Pie
                    data={data}
                    innerRadius={80}
                    outerRadius={120}
                    fill='#8884d8'
                    paddingAngle={1}
                    nameKey='Name'
                    dataKey='Value'
                    width={20}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend content={renderLegend} />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PieChartWithPaddingAngle;
