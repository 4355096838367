import React, { useEffect, useMemo } from 'react';
import { Modal, Row, Col } from 'antd';
import {
    Control,
    FieldErrors,
    UseFormHandleSubmit,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CoreFileLogo, CoreTitle } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { ICountry } from '@models/generic.model';
import countryList from 'react-select-country-list';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getActiveEntities, selectActiveEntityInfo } from '@store/slices/entitySlice';
import { ILogoInfo } from '../../../../packages/CoreFileCropper/CoreFileLogo';
import { IAddDevicePayload } from '@models/organization.model';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { INVOICE_TYPE_OPTIONS } from '@utils/InvoiceTypeOptions';
import GenericCheckbox from '@components/common/GenericCheckbox';
import { getDevices } from '@store/slices/deviceSlice';

// Define props interface
interface AddPhaseOneDeviceProps {
    organizationId: string;
    isModalOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    isAddDevice: boolean;
    control: Control<any>;
    watch: UseFormWatch<any>;
    errors: FieldErrors<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    setFormValue: UseFormSetValue<any>;
    logoInfo: ILogoInfo;
    setLogoInfo: React.Dispatch<React.SetStateAction<ILogoInfo>>;
}

const AddPhaseOneDevice: React.FC<AddPhaseOneDeviceProps> = ({
    organizationId,
    isModalOpen,
    onOk,
    onCancel,
    isAddDevice,
    control,
    watch,
    errors,
    handleSubmit,
    onSubmit,
    setFormValue,
    logoInfo,
    setLogoInfo,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { status, data: activeEntity } = useAppSelector(selectActiveEntityInfo);
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);

    useEffect(() => {
        if (organizationId) {
            dispatch(getActiveEntities(organizationId));
            dispatch(getDevices(organizationId));
        }
    }, []);

    const isSimplifiedBoth = watch('InvoiceType') === '0100' || watch('InvoiceType') === '1100';
    const isStandardBoth = watch('InvoiceType') === '1000' || watch('InvoiceType') === '1100';
    const IsManualIrn = watch('IsManualIRN');

    return (
        <Modal
            width={890}
            open={isModalOpen}
            onOk={onOk}
            okText={t('SAVE')}
            onCancel={onCancel}
            cancelText={t('CANCEL')}
            maskClosable={false}
            confirmLoading={isAddDevice}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='add-device-title'>
                    <CoreTitle tag='h4' text={t('ADD_DEVICE')} />
                </div>
                <Row gutter={16}>
                    <Col span={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='DeviceName'
                            level={t('DEVICE_NAME')}
                            marginBottom={20}
                            rules={{
                                required: {
                                    value: true,
                                    message: `${t('DEVICE_NAME_FIELD_IS_REQUIRED')}`,
                                },
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericSelect
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='Country'
                            level={t('COUNTRY')}
                            options={countryOptions}
                            rules={{
                                required: true,
                            }}
                            setFormValue={setFormValue}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <InlineGenericSelect
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='Location'
                            level={t('BUSINESS_LOCATION')}
                            placeHolder={t('SELECT_BUSINESS_LOCATION')}
                            options={countryOptions}
                            rules={{
                                required: true,
                            }}
                            setFormValue={setFormValue}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='Category'
                            level={t('BUSINESS_SECTOR')}
                            placeHolder={t('BUSINESS_SECTOR')}
                            marginBottom={20}
                            rules={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <InlineGenericSelect
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='InvoiceType'
                            level={t('INVOICE_TYPE')}
                            placeHolder={t('SELECT_INVOICE_TYPE')}
                            options={INVOICE_TYPE_OPTIONS}
                            sorting={false}
                            rules={{
                                required: true,
                            }}
                            setFormValue={setFormValue}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericSelect
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='EntityId'
                            level={t('ENTITY')}
                            placeHolder={t('SELECT_ENTITY')}
                            options={activeEntity}
                            rules={{
                                required: activeEntity.length > 0,
                            }}
                            loading={status === 'loading'}
                            setFormValue={setFormValue}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    {!IsManualIrn && (
                        <>
                            {isSimplifiedBoth && (
                                <>
                                    <Col span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='SimplifiedInvoicePrefix'
                                            level={t('SIMPLIFIED_INVOICE_PREFIX')}
                                            marginBottom={20}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='SimplifiedCreditNotePrefix'
                                            level={t('SIMPLIFIED_CREDIT_NOTE_PREFIX')}
                                            marginBottom={20}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='SimplifiedDebitNotePrefix'
                                            level={t('SIMPLIFIED_DEBIT_NOTE_PREFIX')}
                                            marginBottom={20}
                                        />
                                    </Col>
                                </>
                            )}
                            {isStandardBoth && (
                                <>
                                    <Col span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='StandardInvoicePrefix'
                                            level={t('STANDARD_INVOICE_PREFIX')}
                                            marginBottom={20}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='StandardCreditNotePrefix'
                                            level={t('STANDARD_CREDIT_NOTE_PREFIX')}
                                            marginBottom={20}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='StandardDebitNotePrefix'
                                            level={t('STANDARD_DEBIT_NOTE_PREFIX')}
                                            marginBottom={20}
                                        />
                                    </Col>
                                </>
                            )}
                        </>
                    )}
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <GenericCheckbox
                            tooltip={t(
                                'CHECK_THIS_BOX_IF_YOU_WANT_TO_MANUALLY_CREATE_INVOICE_REFERENCE_NUMBER_IRNS',
                            )}
                            control={control}
                            levelCol={24}
                            inputCol={24}
                            dataKey='IsManualIRN'
                            level={t('CREATE_MANUAL_IRN')}
                        />
                    </Col>
                    <Col span={12}>
                        <GenericCheckbox
                            control={control}
                            levelCol={24}
                            inputCol={24}
                            dataKey='Logo.ShowOnPdf'
                            level={t('Show on PDF')}
                        />
                    </Col>
                    <Col span={24}>
                        <div className='logo-title'>Logo Information</div>
                        <CoreFileLogo
                            url={logoInfo.url}
                            maxHeight={200}
                            maxWidth={200}
                            maxSize={800}
                            onFileUpload={setLogoInfo}
                        />
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default AddPhaseOneDevice;
