import { InfoCircleOutlined } from '@ant-design/icons';
import { IInvoiceItemCharges } from '@models/invoice.model';
import { CoreButton } from '@packages';
import { Input, Modal, Select, Tooltip } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IReasonSelectionProps {
    isOpenModal: boolean;
    onSelectReason: (reason: IExemtedReason) => void;
    onCancelReason: () => void;
    taxCategory: string;
    setHideModal: () => void;
}
export type IExemtedReason = Pick<IInvoiceItemCharges, 'Reason' | 'ReasonCode' | 'ReasonEn'>;
const ReasonSelection: FC<IReasonSelectionProps> = ({
    taxCategory,
    isOpenModal,
    onSelectReason,
    onCancelReason,
}) => {
    const { t } = useTranslation();
    const [exemptedReason, setExemptedReason] = useState<IExemtedReason>();

    const handleSubmit = () => {
        exemptedReason && onSelectReason(exemptedReason);
        setExemptedReason(undefined);
    };

    const onChangeReason = (value: string, option: any) => {
        const reasonOption = option as IExemptedReasonObject;
        setExemptedReason({
            Reason: reasonOption?.arabic_display_value,
            ReasonEn: reasonOption?.english_display_value,
            ReasonCode: reasonOption?.reason_code as any,
        });
    };

    return (
        <Modal
            width={800}
            open={isOpenModal}
            closable={false}
            title={t('VAT_EXEMPTED_REASON')}
            footer={[
                <CoreButton
                    key={1}
                    text={t('CANCEL')}
                    type={'basic'}
                    onClick={onCancelReason}
                    icon={
                        <Tooltip title={t('CANCEL_VAT_REASON_TOOLTIP')}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    }
                />,
                <CoreButton
                    disabled={!exemptedReason}
                    key={2}
                    text={t('SAVE')}
                    type={'primary'}
                    onClick={handleSubmit}
                    className='submit-modal-button'
                />,
            ]}
        >
            <div>
                {taxCategory === 'E' || taxCategory === 'Z' ? (
                    <>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>
                                {t('VAT_EXEMPTED_REASON_ENGLISH')}
                            </label>
                            <Select
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder={t('VAT_EXEMPTED_REASON_ENGLISH')}
                                style={{ width: '100%' }}
                                showSearch={false}
                                options={
                                    taxCategory === 'E' ? exemptionTaxOption() : ZeroTaxOption()
                                }
                                value={exemptedReason?.ReasonEn}
                                onChange={onChangeReason}
                            />
                        </div>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>
                                {t('VAT_EXEMPTED_REASON_ARABIC')}
                            </label>
                            <Select
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder={t('VAT_EXEMPTED_REASON_ARABIC')}
                                style={{ width: '100%' }}
                                showSearch={false}
                                options={
                                    taxCategory === 'E'
                                        ? exemptionTaxOptionArabic()
                                        : ZeroTaxOptionArabic()
                                }
                                value={exemptedReason?.Reason}
                                onChange={onChangeReason}
                            />
                        </div>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>{t('VAT_EXEMPTED_REASON_CODE')}</label>
                            <Input
                                placeholder={t('VAT_EXEMPTED_REASON_CODE')}
                                style={{ width: '100%' }}
                                value={exemptedReason?.ReasonCode}
                                disabled={true}
                            />
                        </div>
                    </>
                ) : taxCategory === 'O' ? (
                    <>
                        <Input
                            onChange={(event) => {
                                const value = event?.target?.value;
                                setExemptedReason({ Reason: value } as any);
                            }}
                            style={{ width: '100%' }}
                            placeholder={t('INPUT_YOUR_TAX_REASON')}
                            className={`exempted-reason-select`}
                            value={exemptedReason?.Reason}
                        />
                    </>
                ) : (
                    <Input
                        className='exempted-reason-select remove-dropdown'
                        style={{ cursor: 'not-allowed' }}
                        disabled
                    />
                )}
            </div>
        </Modal>
    );
};

export default ReasonSelection;

function exemptionTaxOption(): IExemptedReasonObject[] {
    return [
        {
            label: 'Financial services mentioned in Article 29 of the VAT Regulations',
            value: 'Financial services mentioned in Article 29 of the VAT Regulations',
            english_display_value:
                'Financial services mentioned in Article 29 of the VAT Regulations',
            arabic_display_value:
            'الخدمات المالية',
            id: 1,
            reason_code: 'VATEX-SA-29',
        },
        {
            label: 'Life insurance services mentioned in Article 29 of the VAT Regulations',
            value: 'Life insurance services mentioned in Article 29 of the VAT Regulations',
            english_display_value:
                'Life insurance services mentioned in Article 29 of the VAT Regulations',
            arabic_display_value:
            'عقد تأمين على الحياة',
            id: 2,
            reason_code: 'VATEX-SA-29-7',
        },
        {
            label: 'Real estate transactions mentioned in Article 30 of the VAT Regulations',
            value: 'Real estate transactions mentioned in Article 30 of the VAT Regulations',
            english_display_value:
                'Real estate transactions mentioned in Article 30 of the VAT Regulations',
            arabic_display_value:
            'التوريدات العقارية المعفاة من الضريبة',
            id: 3,
            reason_code: 'VATEX-SA-30',
        },
    ];
}

function exemptionTaxOptionArabic(): IExemptedReasonObject[] {
    return [
        {
            label: 'الخدمات المالية',
            value: 'الخدمات المالية',
            english_display_value:
                'Financial services mentioned in Article 29 of the VAT Regulations',
            arabic_display_value:
            'الخدمات المالية',
            id: 1,
            reason_code: 'VATEX-SA-29',
        },
        {
            label: 'عقد تأمين على الحياة',
            value: 'عقد تأمين على الحياة',
            english_display_value:
                'Life insurance services mentioned in Article 29 of the VAT Regulations',
            arabic_display_value:
                'عقد تأمين على الحياة',
            id: 2,
            reason_code: 'VATEX-SA-29-7',
        },
        {
            label: 'التوريدات العقارية المعفاة من الضريبة',
            value: 'التوريدات العقارية المعفاة من الضريبة',
            english_display_value:
                'Real estate transactions mentioned in Article 30 of the VAT Regulations',
            arabic_display_value:
                'التوريدات العقارية المعفاة من الضريبة',
            id: 3,
            reason_code: 'VATEX-SA-30',
        },
    ];
}

function ZeroTaxOption(): IExemptedReasonObject[] {
    return [
        {
            label: 'Export of goods',
            value: 'Export of goods',
            english_display_value: 'Export of goods',
            arabic_display_value: 'ة من السلع صادرات',
            id: 1,
            reason_code: 'VATEX-SA-32',
        },
        {
            label: 'Export of services',
            value: 'Export of services',
            english_display_value: 'Export of services',
            arabic_display_value: 'ة من الخدمات ص',
            id: 2,
            reason_code: 'VATEX-SA-33',
        },
        {
            label: 'The international transport of Goods',
            value: 'The international transport of Goods',
            english_display_value: 'The international transport of Goods',
            arabic_display_value: 'ع الدولي النقل',
            id: 3,
            reason_code: 'VATEX-SA-34-1',
        },
        {
            label: 'International transport of passengers',
            value: 'International transport of passengers',
            english_display_value: 'International transport of passengers',
            arabic_display_value: 'ب الدولي النقل',
            id: 4,
            reason_code: 'VATEX-SA-34-2',
        },
        {
            label: 'Services directly connected and incidental to a Supply of international passenger transport',
            value: 'Services directly connected and incidental to a Supply of international passenger transport',
            english_display_value:
                'Services directly connected and incidental to a Supply of international passenger transport',
            arabic_display_value:
                'الخدمات المرتبطة مباشرة أو عرضيًابتوريد النقل الدولي للركاب',
            id: 5,
            reason_code: 'VATEX-SA-34-3',
        },
        {
            label: 'Supply of a qualifying means of transport',
            value: 'Supply of a qualifying means of transport',
            english_display_value: 'Supply of a qualifying means of transport',
            arabic_display_value: 'توريد وسائل النقل المؤهلة',
            id: 6,
            reason_code: 'VATEX-SA-34-4',
        },
        {
            label: 'Any services relating to Goods or passenger transportation, as defined in article twenty-five of these Regulations',
            value: 'Any services relating to Goods or passenger transportation, as defined in article twenty-five of these Regulations',
            english_display_value:
                'Any services relating to Goods or passenger transportation, as defined in article twenty-five of these Regulations',
            arabic_display_value:
                'الخدمات ذات الصلة بنقل السلع أو الركاب، وفقًا للتعريف الوارد بالمادةالخامسة والعشرين من الالئحة التنفيذية لنظام ضريبة القيامةالمضافة',
            id: 7,
            reason_code: 'VATEX-SA-34-5',
        },
        {
            label: 'Medicines and medical equipment',
            value: 'Medicines and medical equipment',
            english_display_value: 'Medicines and medical equipment',
            arabic_display_value: 'الطبية والمعدات ا',
            id: 8,
            reason_code: 'VATEX-SA-35',
        },
        {
            label: 'Qualifying metals',
            value: 'Qualifying metals',
            english_display_value: 'Qualifying metals',
            arabic_display_value: 'ة المعاد',
            id: 9,
            reason_code: 'VATEX-SA-36',
        },
        {
            label: 'Private education to citizen',
            value: 'Private education to citizen',
            english_display_value: 'Private education to citizen',
            arabic_display_value: 'ن الخاصة التعليمية الخدمات',
            id: 10,
            reason_code: 'VATEX-SA-29',
        },
        {
            label: 'Private healthcare to citizen',
            value: 'Private healthcare to citizen',
            english_display_value: 'Private healthcare to citizen',
            arabic_display_value: 'ن الخاصة الصحية الخدمات',
            id: 11,
            reason_code: 'VATEX-SA-HEA',
        },
        {
            label: 'Supply of qualified military goods',
            value: 'Supply of qualified military goods',
            english_display_value: 'Supply of qualified military goods',
            arabic_display_value: 'ة العسكرية السلع توريد',
            id: 12,
            reason_code: 'VATEX-SA-MLTRY',
        },
    ];
}

function ZeroTaxOptionArabic(): IExemptedReasonObject[] {
    return [
        {
            label: 'ة من السلع صادرات',
            value: 'ة من السلع صادرات',
            english_display_value: 'Export of goods',
            arabic_display_value: 'ة من السلع صادرات',
            id: 1,
        },
        {
            label: 'ة من الخدمات ص',
            value: 'ة من الخدمات ص',
            english_display_value: 'Export of services',
            arabic_display_value: 'ة من الخدمات ص',
            id: 2,
        },
        {
            label: 'ع الدولي النقل',
            value: 'ع الدولي النقل',
            english_display_value: 'The international transport of Goods',
            arabic_display_value: 'ع الدولي النقل',
            id: 3,
        },
        {
            label: 'ب الدولي النقل',
            value: 'ب الدولي النقل',
            english_display_value: 'International transport of passengers',
            arabic_display_value: 'ب الدولي النقل',
            id: 4,
        },
        {
            label: 'الخدمات المرتبطة مباشرة أو عرضيًابتوريد النقل الدولي للركاب',
            value: 'الخدمات المرتبطة مباشرة أو عرضيًابتوريد النقل الدولي للركاب',
            english_display_value:
                'Services directly connected and incidental to a Supply of international passenger transport',
            arabic_display_value:
            'الخدمات المرتبطة مباشرة أو عرضيًابتوريد النقل الدولي للركاب',
            id: 5,
        },
        {
            label: 'توريد وسائل النقل المؤهلة',
            value: 'توريد وسائل النقل المؤهلة',
            english_display_value: 'Supply of a qualifying means of transport',
            arabic_display_value: 'توريد وسائل النقل المؤهلة',
            id: 6,
        },
        {
            label: 'الخدمات ذات الصلة بنقل السلع أو الركاب، وفقًا للتعريف الوارد بالمادةالخامسة والعشرين من الالئحة التنفيذية لنظام ضريبة القيامةالمضافة',
            value: 'الخدمات ذات الصلة بنقل السلع أو الركاب، وفقًا للتعريف الوارد بالمادةالخامسة والعشرين من الالئحة التنفيذية لنظام ضريبة القيامةالمضافة',
            english_display_value:
                'Any services relating to Goods or passenger transportation, as defined in article twenty-five of these Regulations',
            arabic_display_value:
            'الخدمات ذات الصلة بنقل السلع أو الركاب، وفقًا للتعريف الوارد بالمادةالخامسة والعشرين من الالئحة التنفيذية لنظام ضريبة القيامةالمضافة',
            id: 7,
        },
        {
            label: 'الطبية والمعدات ا',
            value: 'الطبية والمعدات ا',
            english_display_value: 'Medicines and medical equipment',
            arabic_display_value: 'الطبية والمعدات ا',
            id: 8,
        },
        {
            label: 'ة المعاد',
            value: 'ة المعاد',
            english_display_value: 'Qualifying metals',
            arabic_display_value: 'ة المعاد',
            id: 9,
        },
        {
            label: 'ن الخاصة التعليمية الخدمات',
            value: 'ن الخاصة التعليمية الخدمات',
            english_display_value: 'Private education to citizen',
            arabic_display_value: 'ن الخاصة التعليمية الخدمات',
            id: 10,
        },
        {
            label: 'ن الخاصة الصحية الخدمات',
            value: 'ن الخاصة الصحية الخدمات',
            english_display_value: 'Private healthcare to citizen',
            arabic_display_value: 'ن الخاصة الصحية الخدمات',
            id: 11,
        },
        {
            label: 'ة العسكرية السلع توريد',
            value: 'ة العسكرية السلع توريد',
            english_display_value: 'Supply of qualified military goods',
            arabic_display_value: 'ة العسكرية السلع توريد',
            id: 12,
        },
    ];
}

export interface IExemptedReasonObject {
    label: string;
    value: string;
    english_display_value: string;
    arabic_display_value: string;
    id: number;
    reason_code?: string;
}
