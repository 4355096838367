import { getOrganizations } from '@store/slices/organizationSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import './Authorization.scss';
import { getUserDetails, userSelectRole } from '@store/slices/userSlice';
import { organizationRedirect } from '../../helper/organizationRedirect';

const Authorization = () => {
    const { isAdmin, isOrganizationAdmin } = useAppSelector(userSelectRole);
    useEffect(() => {
        authorization();
    }, [isAdmin, isOrganizationAdmin]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isPageLoading, setLoading] = useState<boolean>(true);

    const redirectBasedOnRole = async () => {
        if (isAdmin) {
            navigate('/users', { replace: true });
        }
    };
    const redirectForOrganizationAdmin = async () => {
        if (isOrganizationAdmin) {
            await dispatch(getOrganizations())
                .then(unwrapResult)
                .then((organizations) => {
                    organizationRedirect(organizations, navigate, '/dashboard');
                });
        }
    };
    const authorization = async () => {
        await dispatch(getUserDetails());
        await redirectBasedOnRole();
        await redirectForOrganizationAdmin();
    };

    return (
        <div className='authorization-wrapper'>
            <Spin size='large' spinning={isPageLoading} delay={500} />
        </div>
    );
};

export default Authorization;
