import { Col, Image, Row } from 'antd';
import './AdvanceInvoicing.scss';
import pre_validation from '../../assets/State-of-the-art pre-validation.png';
import analytics from '../../assets/Advanced AI powered analytics.png';
import archiving from '../../assets/E-archiving and  data storage.png';

const AdvanceInvoicing = () => {
    return (
        <div className='advance-invoicing-wrapper'>
            <div className='advance-invoicing-header'>
                <h1 className='advance-invoicing-title'>Advanced invoicing with global coverage</h1>
                <p className='advance-invoicing-sub-title'>
                    Automatically create invoices, receipts, and more based on the rules of hundreds
                    of local tax laws.
                </p>
            </div>
            <div className='advance-invoicing-cards'>
                <Row align='middle' gutter={[24, 12]}>
                    <Col sm={24} lg={8}>
                        <div className='invoicing-single-card'>
                            <p className='invoicing-single-card-title'>
                                State-of-the-art pre-validation
                            </p>
                            <div className='invoicing-single-card-image'>
                                <Image src={pre_validation} preview={false} />
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} lg={8}>
                        <div className='invoicing-single-card'>
                            <p className='invoicing-single-card-title'>
                                Advanced AI powered analytics
                            </p>
                            <div className='invoicing-single-card-middle-image'>
                                <Image src={analytics} preview={false} />
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} lg={8}>
                        <div className='invoicing-single-card'>
                            <p className='invoicing-single-card-title'>
                                E-archiving and data storage
                            </p>
                            <div className='invoicing-single-card-image'>
                                <Image src={archiving} preview={false} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AdvanceInvoicing;
