import React, { useState } from 'react';
import { message, Steps } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import './InventoryStepper.scss';
import { InventoryInvoiceSelect, InventoryMapField, InventorySelectFile } from '../../inventory';
import { useDispatch } from 'react-redux';
import { setTitle } from '@store/slices/dynamicTitleSlice';

const steps = [
    {
        title: 'Configure',
    },
    {
        title: 'Map Fields',
    },
    {
        title: 'Preview',
    },
];

const InventoryStepper = () => {
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(0);

    const onStepChange = (value: number) => {
        setCurrent(value);
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    if (current === 0) {
        dispatch(setTitle('Items - Select file'));
    } else if (current === 1) {
        dispatch(setTitle('Map Fields'));
    } else if (current === 2) {
        dispatch(setTitle('Invoice - Select File'));
    }

    return (
        <div className='inventory-stepper-wrapper'>
            <div className='inventory-stepper-inner-wrapper'>
                <div>
                    <div className='steps-wrapper'>
                        <Steps
                            current={current}
                            onChange={onStepChange}
                            size={'small'}
                            items={steps}
                        />
                    </div>
                    <div className='inventory-stepper-content'>
                        {current === 0 && <InventorySelectFile />}
                        {current === 1 && <InventoryMapField />}
                        {current === 2 && <InventoryInvoiceSelect />}
                    </div>
                </div>
                {current === 0 && (
                    <div className='inventory-select-file-right'>
                        <div>
                            <CoreTitle tag='h5' text='Page Tips' />
                            <ul>
                                <li>
                                    You can download the{' '}
                                    <span className='download-link'>sample xls file</span> to get
                                    detailed information about the data fields used while importing.
                                </li>
                                <li>
                                    If you have files in other formats, you can convert it to an
                                    accepted file format using any online/offline converter.
                                </li>
                                <li>
                                    You can configure your import settings and save them for future
                                    too!
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>

            <div className='inventory-step-button'>
                <div className='inventory-step-button-group'>
                    <div className='inventory-prev-button'>
                        {current > 0 && <CoreButton onClick={() => prev()} text='Previous' />}
                    </div>
                    <div className='inventory-button'>
                        {current < steps.length + 1 && (
                            <CoreButton onClick={() => prev()} text='Cancel' />
                        )}
                        {current < steps.length - 1 && (
                            <CoreButton type='primary' onClick={() => next()} text='Next' />
                        )}
                        {current === steps.length - 1 && (
                            <CoreButton
                                type='primary'
                                onClick={() => message.success('Processing complete!')}
                                text='Import'
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InventoryStepper;
