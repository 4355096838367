import React, { useEffect } from 'react';
import './OrganizationSetUpRoot.scss';
import { OrganizationSetupLayoutWrapper } from '@components';
import { getOrganization } from '@store/slices/organizationSlice';
import { useAppDispatch } from '@store/hooks';
import { Outlet, useParams } from 'react-router-dom';

const OrganizationSetUpRoot = () => {
    const dispatch = useAppDispatch();
    const { organizationId = '' } = useParams();

    useEffect(() => {
        dispatch(getOrganization(organizationId));
    }, []);

    return (
        <OrganizationSetupLayoutWrapper>
            <Outlet />
        </OrganizationSetupLayoutWrapper>
    );
};

export default OrganizationSetUpRoot;
