import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { IPolicyCreate } from '@models/policy.model';
import { getActions, selectActionOption } from '@store/slices/actionSlice';
import { PolicyAPI } from '@service/policy.service';
interface ICreatePolicyProps {
    onPageRefresh: () => void;
}

const CreatePolicy = ({ onPageRefresh }: ICreatePolicyProps) => {
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [isModalLoading, setModalLoading] = useState(false);

    const dispatch = useAppDispatch();
    const actionList = useAppSelector(selectActionOption);

    useEffect(() => {}, []);

    const {
        control,
        handleSubmit,
        reset,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<IPolicyCreate>();

    const onSubmit = async (data: IPolicyCreate) => {
        try {
            setModalLoading(true);
            await PolicyAPI.createPolicy(data);
            message.success('Policy Created Successfully');
            setIsActionModalOpen(false);
            setModalLoading(false);
            onPageRefresh();
        } catch {
            message.error('Policy Create Failed');
            setModalLoading(false);
        }
    };

    const handleOkForPolicy = () => {
        handleSubmit(onSubmit)();
    };

    const handleCancelForAction = () => {
        setIsActionModalOpen(false);
        reset();
    };

    const getAllActions = () => {
        dispatch(
            getActions({
                PageLimit: 100,
            }),
        );
    };

    useEffect(() => {
        getAllActions();
    }, []);

    return (
        <>
            <CoreButton
                type='primary'
                text='Create Policy'
                isFullWidth={true}
                onClick={() => setIsActionModalOpen(true)}
            />

            <Modal
                width={500}
                open={isActionModalOpen}
                onOk={handleOkForPolicy}
                okText='Create'
                onCancel={handleCancelForAction}
                maskClosable={false}
                closable={false}
                confirmLoading={isModalLoading}
            >
                <form onSubmit={handleSubmit(handleOkForPolicy)}>
                    <div className='add-user-title'>
                        <CoreTitle tag='h5' text={'Create Policy'} />
                    </div>
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='PolicyName'
                        level='Policy Name'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericSelect
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Actions'
                        level='Actions'
                        options={actionList}
                        marginBottom={20}
                        setFormValue={setFormValue}
                        rules={{
                            required: true,
                        }}
                        isMultipleSelect={true}
                    />
                </form>
            </Modal>
        </>
    );
};
export default CreatePolicy;
