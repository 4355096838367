import { Key, useEffect, useState } from 'react';
import { Modal, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined } from '@ant-design/icons';
import { CoreStatus } from '@packages';
import { useLocation, useNavigate } from 'react-router-dom';
import './CustomerListTable.scss';
import InviteClient from '@components/invite-client/InviteClient';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getCustomers, selectCustomers } from '@store/slices/customerSlice';
import { CustomerStatus, ICustomer } from '@models/customer.model';
import { UtcToLocalDate } from '@utils/date';
import { DEFAULT_DELAY_TIME, DEFAULT_EMPTY_VALUE, DEFAULT_TABLE_SIZE } from '@config/constants';
import { StatusCode } from '@models/generic.model';
import { useTranslation } from 'react-i18next';
import { ICustomerFilterData } from '../customer-filter-inputs/CustomerFilterInputs';

interface ICustomerListableProps {
    filterData?: ICustomerFilterData;
    query?: string;
}

const CustomerListTable = ({ filterData, query }: ICustomerListableProps) => {
    const { t, i18n } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { state }: { state?: { delay: boolean } } = useLocation();
    const [isDelayLoading, setDelayLoading] = useState(false);
    const [pageInfo, setPageInfo] = useState<{ PageLimit: number; PageNumber: number }>({
        PageLimit: 10,
        PageNumber: 1,
    });
    const customers = useAppSelector(selectCustomers);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const goToCustomerUpdate = (e: any, key: Key) => {
        e.stopPropagation();
        navigate('/customers/update/' + key);
    };

    const columns: ColumnsType<ICustomer> = [
        {
            title: t('LEGAL_REGISTRATION_NAME'),
            dataIndex: i18n.language === 'sa' ? 'LegalRegistrationName' : 'LegalRegistrationNameEn',
            render: (text: string, data) => (
                <Tooltip
                    title={
                        i18n.language === 'sa'
                            ? data?.LegalRegistrationNameEn
                            : data?.LegalRegistrationName
                    }
                >
                    <span>{text || DEFAULT_EMPTY_VALUE}</span>
                </Tooltip>
            ),
        },
        {
            title: t('VAT_REGISTRATION_NUMBER'),
            dataIndex: 'VatRegistrationNumber',
            render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
        },
        {
            title: t('OTHER_BUYER_ID'),
            dataIndex: 'CompanyRegistrationNumber',
            render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
        },
        {
            title: t('CREATED_DATE'),
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            title: t('ACTIVE_STATUS'),
            dataIndex: 'Status',
            render: (status: CustomerStatus, data) =>
                data?.IsArchived ? (
                    <CoreStatus type='inactive' status={StatusCode.Archive} />
                ) : data?.IsActive ? (
                    <CoreStatus type='success' status={StatusCode.Active} />
                ) : (
                    <CoreStatus type='inactive' status={StatusCode.Inactive} />
                ),
        },
        {
            title: '',
            dataIndex: 'ItemId',
            render: (key: any) => (
                <div className='action-buttons'>
                    <div className='edit-table-icon' onClick={(e) => goToCustomerUpdate(e, key)}>
                        <EditOutlined />
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = (record: ICustomer) => {
        navigate(`/customers/details/${record.ItemId}`);
    };

    const getAllCustomers = () => {
        dispatch(
            getCustomers({
                PageLimit: pageInfo.PageLimit,
                PageNumber: pageInfo.PageNumber,
                Search: query,
                ...filterData,
            }),
        );
    };

    useEffect(() => {
        if (state?.delay) {
            setDelayLoading(true);
            setTimeout(() => {
                getAllCustomers();
                setDelayLoading(false);
            }, DEFAULT_DELAY_TIME);
            return;
        }
        getAllCustomers();
    }, [pageInfo, filterData, query, state]);

    return (
        <div className='ksa-table-wrapper table-auto-scroll-with-header'>
            <Table
                loading={
                    customers.status === 'loading' ? true : isDelayLoading === true ? true : false
                }
                columns={columns}
                dataSource={customers.data}
                rowKey={'ItemId'}
                rowClassName='cursor-pointer'
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => handleRowClick(record), // click row
                    };
                }}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: customers.totalCount ?? 0,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    onChange: (PageNumber, PageLimit) => {
                        setPageInfo({ PageNumber, PageLimit });
                    },
                    current: pageInfo.PageNumber,
                }}
            />
            <Modal
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={400}
                footer={null}
                closable={false}
            >
                <InviteClient handleOk={handleOk} handleCancel={handleCancel} />
            </Modal>
        </div>
    );
};

export default CustomerListTable;
