import { Col, Row, Select, Tooltip, TreeSelect } from 'antd';
import { FC, Key } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import GenericErrorMessage, { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { CustomRules } from '@models/rules.model';
import { isFiledFoundError, isRequiredValue } from '@utils/errorMessageOption';
import _ from 'lodash';
interface IInlineGenericTreeSelectParams {
    control: Control<any, any>;
    errors: FieldErrors<any>;
    level: string;
    dataKey: string;
    options: any[];
    setFormValue: UseFormSetValue<any>;
    onChange?: (value: any, option?: any) => void;
    rules?: CustomRules;
    allowClear?: boolean;
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    disabled?: boolean;
    placeHolder?: string;
    children?: React.ReactNode;
    hideError?: boolean;
    gutter?: number;
    loading?: boolean;
    tooltip?: string;
    sorting?: boolean;
    className?: string;
    showSearch?: boolean;
    errorMessageSize?: ErrorMessageSize;
    marginBottom?: number;
}

const InlineGenericTreeSelectGroup: FC<IInlineGenericTreeSelectParams> = ({
    control,
    errors,
    level,
    dataKey,
    rules,
    options,
    setFormValue,
    onChange,
    allowClear,
    disabled,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    placeHolder,
    children,
    hideError,
    gutter = 130,
    loading = false,
    tooltip,
    className = '',
    showSearch = true,
    errorMessageSize,
    marginBottom = 15,
}) => {
    const { t } = useTranslation();

    return (
        <Row gutter={gutter} align='middle' style={{ marginBottom: marginBottom }}>
            {showLevel && (
                <Col span={levelCol}>
                    <label className='general-label'>
                        {t(level)}
                        {rules?.required && <span className='required-field'>*</span>}
                        {tooltip && <GenericTooltip text={tooltip} />}
                    </label>
                </Col>
            )}
            <Col span={inputCol}>
                <div className='general-select-wrapper'>
                    <Controller
                        render={({ field }) => (
                            <TreeSelect
                                className={className}
                                {...field}
                                treeData={options}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                showSearch={showSearch}
                                style={{ width: '100%' }}
                                placeholder={placeHolder ? placeHolder : `Search ${level}`}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                onChange={(value, option) => {
                                    console.log({ option, value });
                                    setFormValue &&
                                        setFormValue(dataKey, value, {
                                            shouldValidate: true,
                                        });
                                    onChange && onChange(value, option);
                                }}
                                dropdownStyle={{ minWidth: 300 }}
                                treeDefaultExpandAll={true}
                                allowClear={allowClear || false}
                                disabled={disabled}
                                loading={loading}
                            />
                        )}
                        control={control}
                        name={dataKey}
                        rules={{ ...rules, required: isRequiredValue(rules) }}
                    />
                    {!hideError && (
                        <GenericErrorMessage
                            errors={errors}
                            dataKey={dataKey}
                            rules={rules}
                            level={level}
                            errorMessageSize={errorMessageSize}
                        />
                    )}
                    {children}
                </div>
            </Col>
        </Row>
    );
};

export default InlineGenericTreeSelectGroup;
