import { RequestStatus } from '@models/async-status.enum';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPolicyGetPayload, IPolicyList } from '@models/policy.model';
import { PolicyAPI } from '@service/policy.service';
import { RootState } from '@store/store';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';

interface IPolicyState {
    policies: {
        status: RequestStatus;
        data: IPolicyList;
    };
}

const initialState: IPolicyState = {
    policies: {
        status: 'idle',
        data: [],
    },
};

export const getPolicies = createAsyncThunk('getPolicies', async (payload: IPolicyGetPayload) => {
    try {
        return await PolicyAPI.getPolicy(payload);
    } catch (error: any) {
        throw error as any;
    }
});

export const PolicySlice = createSlice({
    name: 'policySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPolicies.pending, (state) => {
                state.policies.status = 'loading';
            })
            .addCase(getPolicies.rejected, (state) => {
                state.policies.status = 'failed';
                state.policies.data = [];
            })
            .addCase(getPolicies.fulfilled, (state, action) => {
                state.policies.status = 'idle';
                state.policies.data = action.payload?.Policies || [];
            });
    },
});

export const selectPoliciesInfo = (state: RootState) => state.policy.policies;

export const selectPolicyOptions = (state: RootState) => ({
    isLoading: state.policy.policies.status === 'loading' ? true : false,
    data: state.policy.policies.data.map((policy) => ({
        ...policy,
        value: policy?.PolicyName || DEFAULT_EMPTY_VALUE,
        label: policy?.PolicyName || DEFAULT_EMPTY_VALUE,
        hoverName: policy?.PolicyName,
    })),
});
export default PolicySlice.reducer;
