import React from 'react';
import './CoreLogo.scss'
import LogoWithNameSvg from "./logo-with-name.svg";
import LogoSvg from "./logo.svg";
import { APP_CONFIG } from "@config/constants";

interface ICoreLogoProps {
    size: 'large' | 'medium' | 'small';
    isWithName?: boolean;
    LogoWithName?: any;
    Logo?: any;
}

const CoreLogo = (props: ICoreLogoProps) => {
    const { size = 'medium', isWithName = true, LogoWithName = LogoWithNameSvg, Logo =  LogoSvg } = props
    return (
        <div className={`core-logo ${size}`}>
            <img className="core-logo-img" src={isWithName ? LogoWithName : Logo} alt="main logo"/>
            {isWithName && <div className="env">{APP_CONFIG.environment}</div>}
        </div>
    );
};

export default CoreLogo;

