import React from 'react';
import './PricingCalculatorEstimationCard.scss';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default function PricingCalculatorEstimationCard() {
    return (
        <div className="invoice-card">
            <div className="header">
                <h3>Invoices</h3>
                <span className="price">$139.70 / month</span>
            </div>

            <div className="estimation-card-content">
                <div className="estimation-card-label-text">
                    Number of organizations*
                    <Tooltip className='generic-tooltip'>
                        <ExclamationCircleOutlined />
                    </Tooltip>
                </div>

                <div className="estimation-card-input-control">
                    <div className="estimation-card-icon" >
                        <button>-</button>
                        <input type="text" value="1" readOnly />
                        <button>+</button>
                    </div>
                    <div className="estimation-card-billing-cycle">
                        per month
                        <span className="estimation-card-dropdown-icon">▼</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
