export const antdThemeToken = {
    token: {
        colorPrimary: '#285FF6',
        colorLink: '#285FF6',
        colorLinkHover: '#285FF6',
        colorTextBase: '#667085',
        colorText: '#101828',
        controlHeight: 44,
        fontFamily: 'Inter',
        fontSize: 14,
        borderRadius: 8,
        colorErrorBg: '#FEF3F2',
    },
};
