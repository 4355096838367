const CheckedIcon = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='20' fill='white' />
            <rect
                x='0.5'
                y='0.5'
                width='39'
                height='39'
                rx='19.5'
                stroke='white'
                strokeOpacity='0.2'
            />
            <path
                d='M23 14C23.6975 14 24.0462 14 24.3323 14.0767C25.1088 14.2847 25.7153 14.8912 25.9233 15.6677C26 15.9538 26 16.3025 26 17V23.9C26 25.1601 26 25.7902 25.7548 26.2715C25.539 26.6948 25.1948 27.039 24.7715 27.2548C24.2902 27.5 23.6601 27.5 22.4 27.5H17.6C16.3399 27.5 15.7098 27.5 15.2285 27.2548C14.8052 27.039 14.461 26.6948 14.2452 26.2715C14 25.7902 14 25.1601 14 23.9V17C14 16.3025 14 15.9538 14.0767 15.6677C14.2847 14.8912 14.8912 14.2847 15.6677 14.0767C15.9538 14 16.3025 14 17 14M17.75 22.25L19.25 23.75L22.625 20.375M18.2 15.5H21.8C22.22 15.5 22.4301 15.5 22.5905 15.4183C22.7316 15.3464 22.8463 15.2316 22.9183 15.0905C23 14.9301 23 14.72 23 14.3V13.7C23 13.28 23 13.0699 22.9183 12.9095C22.8463 12.7684 22.7316 12.6537 22.5905 12.5817C22.4301 12.5 22.22 12.5 21.8 12.5H18.2C17.78 12.5 17.5699 12.5 17.4095 12.5817C17.2684 12.6537 17.1537 12.7684 17.0817 12.9095C17 13.0699 17 13.28 17 13.7V14.3C17 14.72 17 14.9301 17.0817 15.0905C17.1537 15.2316 17.2684 15.3464 17.4095 15.4183C17.5699 15.5 17.78 15.5 18.2 15.5Z'
                stroke='url(#paint0_linear_6951_94382)'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_6951_94382'
                    x1='20'
                    y1='12.5'
                    x2='20'
                    y2='27.5'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default CheckedIcon;
