import React from 'react';
import { Tabs, TabsProps } from 'antd';
import './PreferenceTabs.scss';
import PreferenceInvoices from '../../preference/preference-invoice/PreferenceInvoices';

const onChange = (key: string) => {
    console.log(key);
};

const items: TabsProps['items'] = [
    {
        key: '1',
        label: `Invoice`,
        children: <PreferenceInvoices />,
    },
    {
        key: '2',
        label: `Templates`,
        children: `Content of Templates`,
    },
];

const PreferenceTabs = () => {
    return (
        <div className='preference-tabs-wrapper'>
            <div className='preference-left-bar'>
                <Tabs
                    className='preference-tabs'
                    tabPosition='left'
                    defaultActiveKey='1'
                    type='card'
                    items={items}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default PreferenceTabs;
