import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IInvoiceCreation, IInvoicePayload, IInvoiceResult } from '@models/invoice.model';
import { AxiosError } from 'axios';
import { processQuery } from '@utils/processQuery';

export const INVOICE_API = {
    createInvoice: async (payload: IInvoiceCreation): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            });
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getAllInvoices: async (payload: IInvoicePayload): Promise<IInvoiceResult> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/GetInvoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getInvoice: async (invoiceId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceQuery/GetInvoice${processQuery({ invoiceId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    uploadCSVFile: async (
        file: File,
        data: { OrganizationId: string; DeviceId: string; EntityId: string },
    ): Promise<any> => {
        try {
            const url = APP_CONFIG.eisUrl + 'CsvCommand/UploadCsv';
            const bodyFormData = new FormData();
            bodyFormData.append('File', file);
            bodyFormData.append('OrganizationId', data?.OrganizationId);
            bodyFormData.append('FileName', file.name);
            bodyFormData.append('DeviceId', data?.DeviceId);
            bodyFormData.append('EntityId', data?.EntityId);

            const response = await AXIOS_INSTANCE.post(url, bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data.Result;
        } catch (error) {
            console.error('upload failed test', { error });
            throw error;
        }
    },

    getUploadCSVFiles: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CsvQuery/GetMapConfigs`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { PageLimit: 1000 });
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getUploadCSVFileById: async (mapConfigId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CsvQuery/GetMapConfig` + processQuery({ mapConfigId });
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    saveMapping: async (payload: any): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CsvCommand/SaveMappingConfig`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    submitInvoices: async (payload: any): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CsvCommand/SubmitInvoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
