import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { processQuery } from '@utils/processQuery';
import { IAddDevicePayload, IDevice, IDeviceIdPayload, IUpdateDevicePayload } from '@models/organization.model';
import { IGenericResponse } from '@models/generic.model';

export const DeviceAPI = {
    getActiveDevices: async (OrganizationId: string): Promise<IDevice[]> => {
        const url =
            APP_CONFIG.eisUrl +
            `OrganizationQuery/ActiveDevices${processQuery({ OrganizationId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return (response?.data?.Result?.DeviceList as IDevice[]) || [];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getDevice: async (deviceId: string): Promise<IGenericResponse<IDevice>> => {
        const url = APP_CONFIG.eisUrl + `OrganizationQuery/Device${processQuery({ deviceId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getDevices: async (organizationId: string): Promise<IDevice[]> => {
        const url =
            APP_CONFIG.eisUrl + `OrganizationQuery/Devices${processQuery({ organizationId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return (response?.data?.Result?.DeviceList as IDevice[]) || [];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    addDevice: async (payload: IAddDevicePayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/AddDevice`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    removeDevice: async (payload: IDeviceIdPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/RemoveDevice`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    enableDevice: async (payload: IDeviceIdPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/EnableDevice`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    disableDevice: async (payload: IDeviceIdPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/DisableDevice`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getEntityDevices: async (organizationId: string, entityId: string): Promise<IDevice[]> => {
        const url =
            APP_CONFIG.eisUrl +
            `OrganizationQuery/EntityDevices${processQuery({ organizationId, entityId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return (response?.data?.Result?.DeviceList as IDevice[]) || [];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    updateDevice: async (payload: IUpdateDevicePayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/UpdateDevice`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
