import InlineGenericInput from '@components/common/InlineGenericInput';
import { IPrePayment } from '@models/invoice.model';
import { CoreButton, CoreFileUploadDragger } from '@packages';
import { Col, Modal, Row } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import './PrePayments.scss';
import InlineGenericDate from '@components/common/InlineGenericDate';
import { IFileInfo } from 'packages/CoreFileUploader/CoreFileUploader';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { TAX_CATEGORY } from '@utils/InvoiceUtils';
import { useAppDispatch } from '@store/hooks';
import { addPrePayment } from '@store/slices/einvoiceSlice';
import { useTranslation } from 'react-i18next';

interface CreatePrePaymentProps {
    isShowModal: boolean;
    setShowModal: (isShow: boolean) => void;
}

const CreatePrePayment: FC<CreatePrePaymentProps> = ({ isShowModal, setShowModal }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        watch,
        reset,
    } = useForm<IPrePayment>({
        mode: 'onChange',
        defaultValues: {},
    });
    const createPrePayment = (data: IPrePayment) => {
        dispatch(addPrePayment({ ...data, Amount: Number(data.Amount) }));
        onHideModal();
    };

    const onHideModal = () => {
        setShowModal(false);
        reset();
    };

    return (
        <Modal
            closable={false}
            open={isShowModal}
            maskClosable={false}
            footer={[]}
            title={t('ADD_PREMAYMENT')}
        >
            <form id='createInvoice'>
                <div className='new-form-inner-wrapper'>
                    <InlineGenericSelect
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        options={TAX_CATEGORY || []}
                        dataKey='TaxType'
                        level='TAX_TYPE'
                        placeHolder={t('TAX_TYPE')}
                        rules={{
                            required: true,
                        }}
                        marginBottom={0}
                        onChange={(value, option) => {
                            setFormValue('TaxCategory', option.value, { shouldValidate: true });
                            setFormValue('TaxPercent', option.percent, { shouldValidate: true });
                            setFormValue('TaxType', option.label, { shouldValidate: true });
                        }}
                        errorMessageSize='small'
                        setFormValue={setFormValue}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='DocumentId'
                        level='DOCUMENT_ID'
                        rules={{
                            required: true,
                        }}
                        marginBottom={15}
                        errorMessageSize='small'
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Amount'
                        allowDecimal
                        type='number'
                        level='AMOUNT'
                        rules={{
                            required: true,
                        }}
                        marginBottom={15}
                        errorMessageSize='small'
                    />

                    <InlineGenericDate
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        setFormValue={setFormValue}
                        dataKey='IssueDateTime'
                        level='ISSUE_DATE'
                        rules={{
                            required: true,
                        }}
                        marginBottom={15}
                        errorMessageSize='small'
                        disableTodayAfterDate={true}
                    />

                    <div key='footer' className='modal-footer'>
                        <CoreButton
                            key={1}
                            text={t('CANCEL')}
                            htmlType='button'
                            type='basic'
                            onClick={onHideModal}
                            className='cancel-button'
                        />
                        <CoreButton
                            key={2}
                            text={t('ADD')}
                            type='primary'
                            onClick={() => {
                                handleSubmit(createPrePayment)();
                            }}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default CreatePrePayment;
