import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { RequestStatus } from '@models/async-status.enum';
import { IEntity, IEntityIdPayload } from '@models/organization.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EntityAPI } from '@service/entity.service';
import { RootState } from '@store/store';

interface IEntityState {
    activeEntity: {
        status: RequestStatus;
        data: IEntity[];
    };
    isLoading: boolean;
    entities: IEntity[];
    entity: IEntity | null;
}

const initialState: IEntityState = {
    isLoading: false,
    activeEntity: {
        status: 'idle',
        data: [],
    },
    entities: [],
    entity: null,
};

export const getActiveEntities = createAsyncThunk('active-entities', async (orgId: string) => {
    try {
        const response = await EntityAPI.getActiveEntities(orgId);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getEntities = createAsyncThunk(
    'organization/entities',
    async (organizationId: string) => {
        try {
            const response = await EntityAPI.getEntities(organizationId);
            return response.Result;
        } catch (error: any) {
            console.log(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getEntity = createAsyncThunk('organization/entity', async (entityId: string) => {
    try {
        const response = await EntityAPI.getEntity(entityId);
        return response.Result;
    } catch (error: any) {
        console.log(error);
        return error?.response?.Message || error?.message;
    }
});

export const enableEntity = createAsyncThunk(
    'organization/enable-entity',
    async (payload: IEntityIdPayload) => {
        try {
            const response = await EntityAPI.enableEntity(payload);
            return response.Result;
        } catch (error: any) {
            throw error?.response?.Message || error?.message;
        }
    },
);
export const disableEntity = createAsyncThunk(
    'organization/disable-entity',
    async (payload: IEntityIdPayload) => {
        try {
            const response = await EntityAPI.disableEntity(payload);
            return response.Result;
        } catch (error: any) {
            throw error?.response?.Message || error?.message;
        }
    },
);

export const EntitySlice = createSlice({
    name: 'entity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getActiveEntities.pending, (state) => {
                state.activeEntity.status = 'loading';
            })
            .addCase(getActiveEntities.rejected, (state) => {
                state.activeEntity.status = 'failed';
                state.activeEntity.data = [];
            })
            .addCase(getActiveEntities.fulfilled, (state, action) => {
                state.activeEntity.status = 'idle';
                state.activeEntity.data = action.payload;
            });
        builder.addCase(getEntities.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEntities.fulfilled, (state, action) => {
            state.isLoading = false;
            state.entities = action.payload || [];
        });
        builder.addCase(getEntities.rejected, (state, action) => {
            state.isLoading = false;
            state.entities = [];
        });
        builder.addCase(getEntity.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEntity.fulfilled, (state, action) => {
            state.isLoading = false;
            state.entity = action.payload;
        });
        builder.addCase(getEntity.rejected, (state, action) => {
            state.isLoading = false;
        });
    },
});

export const selectActiveEntityInfo = (state: RootState) => ({
    ...state.entity.activeEntity,
    data: state.entity.activeEntity.data.map((entity) => ({
        ...entity,
        value: entity?.EntityId,
        label: entity?.LegalRegistrationName || DEFAULT_EMPTY_VALUE,
        hoverName: entity?.LegalRegistrationNameEn,
    })),
});
export default EntitySlice.reducer;
