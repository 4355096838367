export const GET_CURRENCIES_CODE_OPTIONS = [
    {
        value: 'SAR',
        label: 'SAR',
        Symbol: 'SAR',
    },
    {
        value: 'USD',
        label: 'USD',
        Symbol: 'USD',
    },
    {
        value: 'EUR',
        label: 'EUR',
        Symbol: 'EUR',
    },
    {
        value: 'AED',
        label: 'AED',
        Symbol: 'AED',
    },
    {
        value: 'OMR',
        label: 'OMR',
        Symbol: 'OMR',
    },
    {
        value: 'BHD',
        label: 'BHD',
        Symbol: 'BHD',
    },
    {
        value: 'QAR',
        label: 'QAR',
        Symbol: 'QAR',
    },
    {
        value: 'JOD',
        label: 'JOD',
        Symbol: 'JOD',
    },
];

export const GET_DEFAULT_CURRENCY_CODE = 'SAR';
