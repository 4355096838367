import { CoreTitle } from '@packages';
import './DashboardBlockCard.scss';
import { Divider, Spin } from 'antd';
import React, { CSSProperties } from 'react';

type variantColor = 'green' | 'red' | 'yellow' | 'orange' | 'blue' | 'purple' | 'pink';

interface IBlockCardProps {
    title: string;
    value: number;
    // percentage: number;
    variant?: variantColor;
    // rightArrow?: boolean;
    bottomArrow?: boolean;
    style?: CSSProperties | undefined;
    dashboardChipsLoading: boolean;
}

const DashboardBlockCard: React.FC<IBlockCardProps> = ({
    title,
    value,
    // percentage,
    variant,
    // rightArrow,
    // bottomArrow,
    dashboardChipsLoading,
    style,
}) => {
    return (
        <div className={`dashboard-block-card-wrapper ${variant}`} style={style}>
            {dashboardChipsLoading ? (
                <div className='chips-loader'>
                    <Spin size='large' spinning={true} delay={500} />
                </div>
            ) : (
                <>
                    <div className='block-card-header'>
                        <Divider type='vertical' className='block-card-divider' />
                        <CoreTitle tag='h6' text={title} className='block-card-title' />
                    </div>
                    <div className='block-card-calculation'>
                        <p className='block-card-value'>{value}</p>
                        <div className='block-card-percentage'>
                            {/* <p className='percentage-value'>{percentage}%</p> */}
                            {/* <ArrowIcon
                            className={`block-card-arrow-icon ${
                                bottomArrow ? 'rotate-90' : ''
                            }`}
                        /> */}
                        </div>
                    </div>
                </>
            )}
            {/* {rightArrow && (
                <div
                    className="block-card-arrow right"
                >
                    <ArrowRightOutlined style={{ color: '#475467' }} />
                </div>
            )} */}

            {/* {bottomArrow && (
                <div
                    className="block-card-arrow bottom"
                >
                    <ArrowRightOutlined style={{ color: '#475467' }} />
                </div>
            )} */}
        </div>
    );
};

export default DashboardBlockCard;
