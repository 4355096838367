import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const TotalSvg = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M7.08333 12.2222C7.08333 13.2961 7.95389 14.1667 9.02778 14.1667H10.8333C11.9839 14.1667 12.9167 13.2339 12.9167 12.0834C12.9167 10.9328 11.9839 10 10.8333 10H9.16667C8.01607 10 7.08333 9.06728 7.08333 7.91669C7.08333 6.76609 8.01607 5.83335 9.16667 5.83335H10.9722C12.0461 5.83335 12.9167 6.70391 12.9167 7.7778M10 4.58335V5.83335M10 14.1667V15.4167M18.3333 10C18.3333 14.6024 14.6024 18.3334 10 18.3334C5.39763 18.3334 1.66667 14.6024 1.66667 10C1.66667 5.39765 5.39763 1.66669 10 1.66669C14.6024 1.66669 18.3333 5.39765 18.3333 10Z'
            stroke='currentColor'
            strokeWidth='1.67'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
const TotalIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={TotalSvg} {...props} />
);

export default TotalIcon;
