import React, { useEffect, useState } from 'react';
import { message, Upload } from 'antd';
import type {  UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { RcFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import { fileStorageAPI } from '@service/file-storage.service';
import './CoreFileLogo.scss';
import { generateUUID } from '@utils/uuid';
import GenericTooltip from '@components/common/GenericTooltip';

const fileAcceptValue = {
  image: 'image/png, image/jpeg',

}
export interface ILogoInfo {
  width?: number;
  height?: number;
  url: string;
}

export interface ICoreFileCropperProps {
  url?: string;
  onFileUpload?: (info: ILogoInfo) => void,
  onRemoveFile?: (info: ILogoInfo) => void,
  uploadHint?: string,
  maxWidth?: number,
  maxHeight?: number,
  maxSize?: number // maxSize kb
}

export default function CoreFileLogo({ maxWidth, maxHeight, url, onFileUpload, maxSize }: ICoreFileCropperProps) {
  const [file, setFile] = useState<UploadFile<any>>();
  const { t } = useTranslation();

  const props: UploadProps = {
    accept: fileAcceptValue.image,
    multiple: false,
    maxCount: 1,
    async beforeUpload(file: RcFile) {
      try {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
          message.error(t('YOU_CAN_ONLY_UPLOAD_IMAGE_FILE'));
        }

        const getBase64File = await fileStorageAPI.getBase64File(file);

        const image = new Image();
        image.src = getBase64File;

        await new Promise<void>((resolve) => {
          image.onload = () => resolve();
        });

        const { width, height } = image;
        const uploadSize = file.size / 1024; // convert to kb size
        console.log({ width, height, size: file.size, maxSize, uploadSize});

        if(maxSize && (uploadSize > maxSize)){
          message.error(t(`Unsuccessful upload. Please use an image size of ${maxSize}KB or smaller`));
          return false
        }
        if ((maxHeight && maxWidth) && (width > maxWidth) || (height > (maxHeight as number))) {
          message.error(t(`Unsuccessful upload. Please use an image size of ${maxWidth}x${maxHeight}px or smaller`));
          return false;
        }

        onFileUpload && onFileUpload({ width, height, url: getBase64File });
        return false;
      } catch (error: any) {
        message.error(error?.message || t('FILE_UPLOAD_FAILED'));
        setFile({ name: file.name, uid: file.uid, status: 'error' });
        return false;
      }
    },
    onRemove() {
      onFileUpload && onFileUpload({ width: undefined, height: undefined, url: '' })
    }
  };

  useEffect(() =>{
    if(!url){
      setFile(undefined)
      return
    }
    setFile({ name: 'Unknown file', uid: generateUUID(), status: 'done', url });
  },[url])

  const onPreview = async (file: UploadFile) => {
    console.log(file);
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as any);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <ImgCrop rotationSlider showGrid cropShape='rect'>
      <Upload
        {...props}
        listType='picture-card'
        fileList={file ? [file] : []}
        onPreview={onPreview}
      >
        {!file && <p>
          + Upload
          <GenericTooltip text={
            `File Format: PNG, JPG
            ${maxWidth && maxHeight && `(${maxWidth}x${maxHeight})` || ''}.
            ${maxSize ? `Maximum file size: ${maxSize}KB`: ''}`} />
          </p>} 
      </Upload>
    </ImgCrop>
  );
}
