import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const CustomerSvg = () => (
    <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M14.6666 16.5C14.6666 15.337 14.6666 14.7555 14.5231 14.2824C14.1999 13.217 13.3662 12.3834 12.3009 12.0602C11.8277 11.9167 11.2462 11.9167 10.0832 11.9167H5.91659C4.75362 11.9167 4.17213 11.9167 3.69897 12.0602C2.63363 12.3834 1.79995 13.217 1.47678 14.2824C1.33325 14.7555 1.33325 15.337 1.33325 16.5M11.7499 5.25C11.7499 7.32107 10.071 9 7.99992 9C5.92885 9 4.24992 7.32107 4.24992 5.25C4.24992 3.17893 5.92885 1.5 7.99992 1.5C10.071 1.5 11.7499 3.17893 11.7499 5.25Z'
            stroke='currentColor'
            strokeWidth='1.67'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
const CustomerIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={CustomerSvg} {...props} />
);

export default CustomerIcon;
