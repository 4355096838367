import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IContactUsPayload } from '@models/contactUs.model';

export const ContactUsAPI = {
    submitContactUs: async (payload: IContactUsPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SupportCommand/SubmitSupportRequest`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
