import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { EInvoiceType, IInvoiceCreation } from '@models/invoice.model';
import { PAYMENT_MEANS } from '@utils/InvoiceUtils';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IPaymentMeans {
    control: Control<IInvoiceCreation, any>;
    errors: FieldErrors<IInvoiceCreation>;
    setFormValue: UseFormSetValue<IInvoiceCreation>;
    watch: UseFormWatch<IInvoiceCreation>;
}
const INSTRUCTION_NOTE_REQUIRED: EInvoiceType[] = [
    EInvoiceType['Credit Note'],
    EInvoiceType['Debit Note'],
    EInvoiceType['Simplified Credit Note'],
    EInvoiceType['Simplified Debit Note'],
];
const PaymentMeans: FC<IPaymentMeans> = ({ control, errors, setFormValue, watch }) => {
    const { t } = useTranslation();
    return (
        <>
            <Row align='middle' gutter={20}>
                <Col md={12} lg={8}>
                    <InlineGenericSelect
                        levelCol={24}
                        inputCol={24}
                        control={control}
                        errors={errors}
                        dataKey='PaymentMeans.PaymentMeansCode'
                        level='PAYMENT_METHOD'
                        placeHolder={t('SELECT_PAYMENT_METHOD')}
                        options={PAYMENT_MEANS}
                        setFormValue={setFormValue}
                        rules={{ required: true }}
                        sorting={false}
                        allowClear
                        showSearch={false}
                        marginBottom={0}
                        errorMessageSize='small'
                    />
                </Col>
                <Col md={12} lg={8}>
                    <InlineGenericInput
                        levelCol={24}
                        inputCol={24}
                        control={control}
                        errors={errors}
                        dataKey='PaymentMeans.PaymentTerms'
                        level='PAYMENT_TERMS'
                        marginBottom={15}
                        rules={{
                            required: watch('PaymentMeans.PaymentMeansCode') == '30' ? true : false,
                        }}
                    />
                </Col>
                {watch('PaymentMeans.PaymentMeansCode') === '1' && (
                    <Col md={12} lg={8}>
                        <InlineGenericInput
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='PaymentMeans.OtherPaymentMeans'
                            level={t('SPECIFY_OTHER_PAYMENT_MEANS')}
                            marginBottom={15}
                            rules={{
                                required: true,
                            }}
                            errorMessageSize='small'
                        />
                    </Col>
                )}

                {/*{INSTRUCTION_NOTE_REQUIRED.includes(watch('InvoiceType') as EInvoiceType) && (*/}
                {/*    <Col md={12} lg={8}>*/}
                {/*        <InlineGenericInput*/}
                {/*            levelCol={24}*/}
                {/*            inputCol={24}*/}
                {/*            control={control}*/}
                {/*            errors={errors}*/}
                {/*            dataKey='PaymentMeans.InstructionNote'*/}
                {/*            level='INSTRUCTION_NOTE'*/}
                {/*            rules={{*/}
                {/*                required: true,*/}
                {/*            }}*/}
                {/*            marginBottom={15}*/}
                {/*            errorMessageSize='small'*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*)}*/}

                <Col md={12} lg={8}>
                    <InlineGenericInput
                        levelCol={24}
                        inputCol={24}
                        control={control}
                        errors={errors}
                        dataKey='PaymentMeans.PaymentAccountIdentifier'
                        level={t('SUPPLIER_BANK_ACCOUNT_NUMBER')}
                        marginBottom={15}
                        rules={{
                            required: watch('PaymentMeans.PaymentMeansCode') == '42' ? true : false,
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PaymentMeans;
