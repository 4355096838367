import React, { useEffect } from 'react';
import { getTiers, selectUpdatedTiredData } from '@store/slices/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Carousel } from 'antd';
import ComparisonCard from '../comparison-card/ComparisonCard';
import './ComparisonTab.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export interface IComparisonTabProps {
    type: 'month' | 'year';
}

const ComparisonTab = ({ type }: IComparisonTabProps) => {
    const dispatch = useAppDispatch();
    const tiers = useAppSelector(selectUpdatedTiredData(type));

    useEffect(() => {
        dispatch(getTiers());
    }, [type]);

    return (
        <div className='comparison-tab-wrapper'>
            <Carousel
                slidesToShow={3}
                arrows
                draggable
                prevArrow={<LeftOutlined />}
                nextArrow={<RightOutlined />}
                responsive={[
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ]}
            >
                {tiers?.length &&
                    tiers.map((tier) => (
                        <div key={tier.ItemId}>
                            <ComparisonCard
                                title={tier?.Name}
                                description={tier?.Description}
                                price={`${
                                    type === 'month' ? tier.MonthlyPrice : tier.YearlyPrice
                                } ${tier?.Currency}`}
                                period={`${type === 'month' ? 'month' : 'yearly'}`}
                                type={type}
                                tier={tier || undefined}
                            />
                        </div>
                    ))}
            </Carousel>
        </div>
    );
};

export default ComparisonTab;
