import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { Key } from 'react';
import { GET_CURRENCIES_CODE_OPTIONS } from './CurrencyCodeOptions';

export function displayCurrency({ Code, Amount }: { Code: string; Amount?: Key }): string {
    if (!Amount && !Code) return DEFAULT_EMPTY_VALUE;
    const amountModify = Amount ? Number(Amount) : 0;

    const currencyInfo = GET_CURRENCIES_CODE_OPTIONS.find((value) => value?.value === Code);
    if (currencyInfo) {
        const length = currencyInfo?.Symbol?.length;
        return currencyInfo?.Symbol + (length > 1 ? ' ' : '') + amountModify?.toFixed(2) || '0.00';
    }
    return Code + ' ' + amountModify?.toFixed(2) || '0.00';
}
