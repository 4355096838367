import React from 'react';
import { CoreAuthConfirmation } from '@packages';
import { useTranslation } from 'react-i18next';

const VerifyAccount = () => {
    const { t } = useTranslation();
    return (
        <CoreAuthConfirmation
            title={t('CONGRATULATIONS')}
            description={t('YOUR_ACCOUNT_HAS_BEEN_VERIFIED_SUCCESSFYLLY')}
            linkTo='login'
        />
    );
};

export default VerifyAccount;
