/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './CardView.scss';
import { Radio } from 'antd';

export interface ICardViewProps {
    type: string;
    number: string;
    expiry: string;
    status?: string;
    onClick: () => void;
    selectedCardId?: string;
    cardId: string;
}

export default function CardView({
    type,
    number,
    expiry,
    status,
    onClick,
    selectedCardId,
    cardId,
}: ICardViewProps) {
    return (
        <div
            className='credit-card round-box-shadow '
            style={
                (selectedCardId && selectedCardId === cardId && { border: '2px solid #285FF6' }) ||
                {}
            }
        >
            <Radio value={cardId}>
                <div className='card-view-wrapper' onClick={onClick}>
                    <div className='payment-card-image-wrapper'>
                        <img
                            className='payment-card-image'
                            src={type === 'visa' ? LOGO.visa : LOGO.master}
                            alt='Visa Logo'
                            width={40}
                            height={30}
                        />
                    </div>
                    <div className='payment-card-header'>
                        <div className='payment-card-type'>{type}</div>
                        <div className='payment-card-number'>Expiry {expiry}</div>
                    </div>
                </div>
            </Radio>
        </div>
    );
}

export const LOGO = {
    visa: 'https://www.pngplay.com/wp-content/uploads/12/Visa-Card-Logo-PNG-Clipart-Background-HD.png',
    master: 'https://w7.pngwing.com/pngs/530/165/png-transparent-logo-mastercard-pentagram-flat-design-brand-mastercard-text-trademark-orange-thumbnail.png',
};
