import { Tabs, TabsProps } from 'antd';
import './SettingTabs.scss';
import { CoreTitle } from '@packages';
import { useTranslation } from 'react-i18next';
import { Security, UserAccount } from "../../index";

const SettingTabs = () => {
    const { t } = useTranslation();

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `${t('PROFILE_SETTINGS')}`,
            children: <UserAccount />,
        },
        // {
        //     key: '2',
        //     label: `Subscription Details`,
        //     children: <Subscription />,
        // },
        {
            key: '2',
            label: `${t('SECURITY')}`,
            children: <Security />,
        },
    ];

    return (
        <div className='setting-container'>
            <div className='setting-header'>
                <CoreTitle tag='h2' text={t('ACCOUNT_SETTINGS')} />
                <p className='setting-sub-title'>{t('MANAGE_YOUR_PREFERENCES_HERE')}</p>
            </div>
            <Tabs defaultActiveKey='1' items={items} />
        </div>
    );
};

export default SettingTabs;
