import './SupportHome.scss';
import { CoreButton, CoreTitle } from '@packages';
import { Col, Row } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { SupportCard } from '../../index';

const SupportHome = () => {
    return (
        <div className='support-home-wrapper'>
            <div className='support-home-header'>
                <CoreTitle tag={'h3'} text='Troubleshooting resources' />
                <CoreButton
                    type='primary'
                    text='Create New Ticket'
                    linkTo={'/support/create-ticket'}
                    size='small'
                />
            </div>
            <div>
                <Row gutter={24}>
                    <Col span={8} className='margin-top-20'>
                        <SupportCard
                            title={'SUPPORT ARTICLES'}
                            subTitle={
                                'Visit our docs to search for help across a variety if topics.'
                            }
                            buttonLabel={'Browse articles'}
                            buttonLink={'dashboard'}
                        />
                    </Col>
                    <Col span={8} className='margin-top-20'>
                        <SupportCard
                            title={'COMMUNITY'}
                            subTitle={'Engage with DigitalOceans community of expert for answer.'}
                            buttonLabel={'Visit our community'}
                            buttonLink={'dashboard'}
                        />
                    </Col>
                    <Col span={8} className='margin-top-20'>
                        <SupportCard
                            title={'STATUS PAGE'}
                            subTitle={'Check the current status of DigitalOceans services.'}
                            buttonLabel={'Visit status dashboard'}
                            buttonLink={'dashboard'}
                        />
                    </Col>
                </Row>
            </div>

            <div className='support-home-tickets'>
                <CoreTitle tag={'h5'} text='SUPPORT TICKETS' />
                <p className='support-home-tickets-sub-title'>
                    Create new tickets and view current support cases in the Help Center
                </p>

                <div className='support-home-tickets-actions'>
                    <Link to={'/support/my-ticket'} className='ticket-link'>
                        View your tickets
                    </Link>
                    <ArrowRightOutlined className='support-home-icon' />
                </div>
            </div>
        </div>
    );
};
export default SupportHome;
