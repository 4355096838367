import { FC, useMemo } from 'react';
import './Address.scss';
import { Control, FieldErrors, UseFormSetValue, useWatch } from 'react-hook-form';
import { CustomerType, ICustomerCreate } from '@models/customer.model';
import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import countryList from 'react-select-country-list';
import { RegexValidators } from '@config/regex-validator-constant';
import GenericPostalCode from '@components/common/GenericPostalCode';
import { useTranslation } from 'react-i18next';
import GenericStreetName from '@components/common/GenericStreetName';
import GenericInputMaxLimit from '@components/common/GenericInputMaxLimit';
import GenericBuildingNumber from "@components/common/GenericBuildingNumber";

interface IAddressInfoParams {
    control: Control<ICustomerCreate, any>;
    errors: FieldErrors<ICustomerCreate>;
    setValue: UseFormSetValue<ICustomerCreate>;
    customerType: CustomerType;
}

const AddressInfo: FC<IAddressInfoParams> = ({ control, errors, setValue, customerType }) => {
    const countries = useMemo(() => countryList().getData(), []);
    const { t } = useTranslation();

    const countryCode = useWatch({
        control,
        name: 'Address.CountryCode',
        defaultValue: 'SA',
    });

    return (
        <div className='address-wrapper'>
            <GenericBuildingNumber
                control={control}
                errors={errors}
                dataKey='Address.BuildingNumber'
                level={t('BUILDING_NUMBER')}
                type={countryCode === 'SA' ? 'customer' : 'number'}
                rules={{
                    required: true,
                    pattern: {
                        value: RegexValidators.ONLY_NUMERIC,
                        message: t('BUILDING_NUMBER_SHOULD_BE_NUMERIC'),
                    },
                    maxLength:
                        countryCode === 'SA'
                            ? { value: 4, message: t('BUILDING_NUMBER_MUST_BE_4_DIGITS') }
                            : undefined,
                    minLength: countryCode === 'SA'
                        ? { value: 4, message: t('BUILDING_NUMBER_MUST_BE_4_DIGITS') }
                        : undefined,
                }}
                marginBottom={20}
                errorMessageSize='small'
            />

            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Address.AdditionalNumber'
                level={t('ADDITIONAL_NUMBER')}
                rules={{
                    maxLength: {
                        value: 4,
                        message: t('ADDITIONAL_NUMBER_MUST_BE_4_DIGITS'),
                    },
                }}
                type='number'
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericStreetName
                control={control}
                errors={errors}
                dataKey='Address.Street'
                type='arabic'
                level={t('STREET_ARABIC')}
                rules={{
                    required: customerType === 'Business' ? true : false,
                    maxLength: 1000,
                    pattern: {
                        value: RegexValidators.ARABIC_CHARACTER,
                        message: `${t('STREET_ONLY_ALLOW_ARABIC')}`,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericStreetName
                control={control}
                errors={errors}
                dataKey='Address.StreetEn'
                level={t('STREET_ENGLISH')}
                type='english'
                rules={{
                    required: customerType === 'Business' ? true : false,
                    pattern: {
                        message: `${t('STREET_ONLY_ALLOW_ENGLISH')}`,
                        value: RegexValidators.ENGLISH_CHARACTER,
                    },
                    maxLength: 1000,
                }}
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericInputMaxLimit
                control={control}
                errors={errors}
                dataKey='Address.AdditionalStreet'
                level={t('ADDITIONAL_STREET_ARABIC')}
                rules={{
                    maxLength: 127,
                    pattern: {
                        value: RegexValidators.ARABIC_CHARACTER,
                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                    },
                }}
                type='arabic'
                maxLimit={127}
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericInputMaxLimit
                control={control}
                errors={errors}
                dataKey='Address.AdditionalStreetEn'
                level={t('ADDITIONAL_STREET_ENGLISH')}
                rules={{
                    maxLength: 127,
                    pattern: {
                        value: RegexValidators.ENGLISH_CHARACTER,
                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                    },
                }}
                type='english'
                maxLimit={127}
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericInputMaxLimit
                control={control}
                errors={errors}
                dataKey='Address.City'
                level={t('CITY_ARABIC')}
                rules={{
                    required: customerType === 'Business' ? true : false,
                    maxLength: 127,
                    pattern: {
                        value: RegexValidators.ARABIC_CHARACTER,
                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                    },
                }}
                maxLimit={127}
                type='arabic'
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericInputMaxLimit
                control={control}
                errors={errors}
                dataKey='Address.CityEn'
                level={t('CITY_ENGLISH')}
                rules={{
                    required: customerType === 'Business' ? true : false,
                    maxLength: 127,
                    pattern: {
                        value: RegexValidators.ENGLISH_CHARACTER,
                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                    },
                }}
                maxLimit={127}
                type='english'
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericInputMaxLimit
                control={control}
                errors={errors}
                dataKey='Address.District'
                level={t('DISTRICT_ARABIC')}
                rules={{
                    required: customerType === 'Business' ? true : false,
                    maxLength: 127,
                    pattern: {
                        value: RegexValidators.ARABIC_CHARACTER,
                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                    },
                }}
                maxLimit={127}
                type='arabic'
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericInputMaxLimit
                control={control}
                errors={errors}
                dataKey='Address.DistrictEn'
                level={t('DISTRICT_ENGLISH')}
                rules={{
                    required: customerType === 'Business' ? true : false,
                    maxLength: 127,
                    pattern: {
                        value: RegexValidators.ENGLISH_CHARACTER,
                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                    },
                }}
                maxLimit={127}
                type='english'
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericInputMaxLimit
                control={control}
                errors={errors}
                dataKey='Address.ProvinceOrState'
                level={t('PROVINCE_ARABIC')}
                rules={{
                    required: customerType === 'Business' ? true : false,
                    maxLength: 127,
                    pattern: {
                        value: RegexValidators.ARABIC_CHARACTER,
                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                    },
                }}
                maxLimit={127}
                type='arabic'
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericInputMaxLimit
                control={control}
                errors={errors}
                dataKey='Address.ProvinceOrStateEn'
                level={t('PROVINCE_ENGLISH')}
                rules={{
                    required: customerType === 'Business' ? true : false,
                    maxLength: 127,
                    pattern: {
                        value: RegexValidators.ENGLISH_CHARACTER,
                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                    },
                }}
                maxLimit={127}
                type='english'
                marginBottom={20}
                errorMessageSize='small'
            />

            <GenericPostalCode
                control={control}
                errors={errors}
                type={countryCode === 'SA' ? 'string' : 'alphanumeric'}
                dataKey='Address.PostalCode'
                level={t('POSTAL_CODE')}
                rules={{
                    required: customerType === 'Business',
                    pattern: {
                        value:
                            countryCode === 'SA'
                                ? RegexValidators.ONLY_NUMERIC
                                : RegexValidators.ALPHA_NUMERIC,
                        message:
                            countryCode === 'SA'
                                ? 'PostalCode should be only numeric'
                                : 'PostalCode should be alpha numeric',
                    },
                }}
                hideError={false}
                marginBottom={20}
                errorMessageSize='small'
            />

            <InlineGenericSelect
                control={control}
                errors={errors}
                dataKey='Address.CountryCode'
                level={t('COUNTRY')}
                options={countries}
                rules={{
                    required: true,
                }}
                setFormValue={setValue}
                marginBottom={5}
                errorMessageSize='small'
            />
        </div>
    );
};

export default AddressInfo;
