import './NotificationDropDown.scss';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import OrganizationNotification from '../organization-notification/OrganizationNotification';
import AnnouncementNotification from '../announcement-notification/AnnouncementNotification';

const items: TabsProps['items'] = [
    {
        key: '1',
        label: `Organization`,
        children: <OrganizationNotification />,
    },
    {
        key: '2',
        label: `Announcements`,
        children: <AnnouncementNotification />,
    },
];

const NotificationDropDown = () => {
    return (
        <div className='drop-down-notification'>
            <div className='notification-panel'>
                <p className='notification-type'>Notifications</p>
                <div className='unread-icon'>2</div>
            </div>
            <Tabs defaultActiveKey='1' items={items} />
        </div>
    );
};

export default NotificationDropDown;
