import React from 'react';
import './AnnouncementNotification.scss';

const AnnouncementNotification = () => {
    return (
        <div className='announcement-notification-wrapper'>
            <p className='notification-header'>Announcements Notification</p>
        </div>
    );
};

export default AnnouncementNotification;
