import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { CoreList } from '@packages';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import './OrganizationDetail.scss';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { useTranslation } from 'react-i18next';
import { getCountryNameFromCode } from '@utils/getCountryNameFromCode';

const OrganizationDetail = () => {
    const { t } = useTranslation();
    const { organization } = useSelector((state: RootState) => state.organization);

    const organizationInformationTableData = [
        {
            key: `${t('LEGAL_REGISTRATION_NAME')}:`,
            value: organization?.LegalRegistrationName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('VAT_REGISTRATION_NUMBER')}:`,
            value: organization?.VatRegistrationNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('OTHER_SELLER_ID')}:`,
            value: organization?.CompanyRegistrationNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('OTHER_SELLER_ID_TYPE')}:`,
            value: organization?.CompanyRegistrationScheme || DEFAULT_EMPTY_VALUE,
        },
    ];

    const organizationAddressTableData = [
        {
            key: `${t('BUILDING_NUMBER')}:`,
            value: organization?.Address?.BuildingNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('STREET_ARABIC')}:`,
            value: organization?.Address?.Street || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('STREET_ENGLISH')}:`,
            value: organization?.Address?.StreetEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_STREET_ARABIC')}:`,
            value: organization?.Address?.AdditionalStreet || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_STREET_ENGLISH')}:`,
            value: organization?.Address?.AdditionalStreetEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_NUMBER')}:`,
            value: organization?.Address?.AdditionalNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CITY_ARABIC')}:`,
            value: organization?.Address?.City || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CITY_ENGLISH')}:`,
            value: organization?.Address?.CityEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PROVINCE_ARABIC')}:`,
            value: organization?.Address?.ProvinceOrState || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PROVINCE_ENGLISH')}:`,
            value: organization?.Address?.ProvinceOrStateEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('DISTRICT_ARABIC')}:`,
            value: organization?.Address?.District || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('DISTRICT_ENGLISH')}:`,
            value: organization?.Address?.DistrictEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('POSTAL_CODE')}:`,
            value: organization?.Address?.PostalCode || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('COUNTRY')}:`,
            value: getCountryNameFromCode(
                organization?.Address?.CountryCode || DEFAULT_EMPTY_VALUE,
            ),
        },
    ];

    const organizationContactTableData = [
        {
            key: `${t('FIRST_NAME_ARABIC')}:`,
            value: organization?.Contact?.FirstName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('FIRST_NAME_ENGLISH')}:`,
            value: organization?.Contact?.FirstNameEn || DEFAULT_EMPTY_VALUE,
        },

        {
            key: `${t('LAST_NAME_ARABIC')}:`,
            value: organization?.Contact?.LastName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LAST_NAME_ENGLISH')}:`,
            value: organization?.Contact?.LastNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME_ARABIC')}:`,
            value: organization?.Contact?.MiddleName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME_ENGLISH')}:`,
            value: organization?.Contact?.MiddleNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE_ARABIC')}:`,
            value: organization?.Contact?.JobTitle || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE_ENGLISH')}:`,
            value: organization?.Contact?.JobTitleEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PHONE_NUMBER')}:`,
            value: organization?.PhoneNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('EMAIL')}:`,
            value: organization?.Email || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('FAX')}:`,
            value: organization?.Fax || DEFAULT_EMPTY_VALUE,
        },
    ];

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `${t('ORGANIZATION_INFORMATION')}`,
            children: <CoreList listData={organizationInformationTableData} />,
        },
        {
            key: '2',
            label: `${t('ADDRESS_INFORMATION')}`,
            children: <CoreList listData={organizationAddressTableData} />,
        },
        {
            key: '3',
            label: `${t('CONTACT_INFORMATION')}`,
            children: <CoreList listData={organizationContactTableData} />,
        },
    ];

    return (
        <div className='round-box-shadow top-tab-wrapper'>
            <Tabs defaultActiveKey='1' className='tabs-custom-content' items={items} />
        </div>
    );
};

export default OrganizationDetail;
