import { WarningOutlined } from '@ant-design/icons';
import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { CoreButton } from '@packages';
import { Checkbox, Col, Modal, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';

interface IAddressEditProps {
    isOpenModal: boolean;
    onCancelModal: () => void;
    onUpdateAddress: () => void;
    addressType: AddressEditType;
}

export type AddressEditType = 'billing' | 'shipping';

export default function AddressEdit({
    isOpenModal,
    onCancelModal,
    onUpdateAddress,
    addressType,
}: IAddressEditProps) {
    const { t } = useTranslation();
    const countries = useMemo(() => countryList().getData(), []);

    const title = addressType === 'billing' ? 'Edit Billing Address' : 'Edit Shipping Address';

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        watch,
        reset,
    } = useForm<any>({
        mode: 'onChange',
        defaultValues: {},
    });

    const onSubmit = () => {
        onCancelModal();
        reset();
    };
    const onCancelButton = () => {
        onCancelModal();
        reset();
    };
    const handelCheckBox = (e: CheckboxChangeEvent) => {
        const checkValue = e.target.checked;
        console.log(handelCheckBox);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal
                width={800}
                open={isOpenModal}
                closable={false}
                title={title}
                className='billing-edit'
                footer={[
                    <CoreButton key={1} text={'Cancel'} type={'basic'} onClick={onCancelButton} />,
                    <CoreButton
                        key={2}
                        text={'Save'}
                        type={'primary'}
                        onClick={() => {
                            handleSubmit(onSubmit)();
                        }}
                        className='submit-modal-button'
                    />,
                ]}
            >
                <div className='address-info'>
                    This address appears on your monthly invoice and should be the legal address of
                    your home or business
                </div>

                {addressType === 'billing' && (
                    <div className='warning-info'>
                        <WarningOutlined className='warning-icon' />
                        <span className='card-title'>Warning:</span> Updating your account address
                        may be update your <span className='primary-color'>tax location</span>
                    </div>
                )}

                {addressType === 'shipping' && (
                    <div className='general-input-wrapper'>
                        <Controller
                            render={({ field }) => (
                                <Checkbox className='general-checkbox' onChange={handelCheckBox}>
                                    Use same information as billing address
                                </Checkbox>
                            )}
                            control={control}
                            name='Billing'
                        />
                    </div>
                )}

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Company'
                    level='Company'
                    rules={{
                        required: true,
                    }}
                    marginBottom={15}
                    errorMessageSize='small'
                />

                <InlineGenericSelect
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='COUNTRY'
                    level={t('COUNTRY')}
                    options={countries}
                    rules={{
                        required: true,
                    }}
                    setFormValue={setFormValue}
                    marginBottom={5}
                    errorMessageSize='small'
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Address1'
                    level='Address Line 1'
                    rules={{
                        required: true,
                    }}
                    marginBottom={15}
                    errorMessageSize='small'
                />
                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Address2'
                    level='Address Line 2'
                    marginBottom={15}
                    errorMessageSize='small'
                />
                <Row align='middle' gutter={20}>
                    <Col md={24} lg={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='City'
                            level='City'
                            marginBottom={15}
                            errorMessageSize='small'
                        />
                    </Col>
                    <Col md={24} lg={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='State'
                            level='State'
                            marginBottom={15}
                            errorMessageSize='small'
                        />
                    </Col>
                </Row>
                <Row align='middle' gutter={20}>
                    <Col md={24} lg={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='ZipCode'
                            level='Zip Code'
                            marginBottom={15}
                            errorMessageSize='small'
                        />
                    </Col>
                    <Col md={24} lg={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='PhoneNumber'
                            level='Phone Number'
                            marginBottom={15}
                            errorMessageSize='small'
                        />
                    </Col>
                </Row>
            </Modal>
        </form>
    );
}
