import { authAPI } from '@service/auth.service';
import axios, { AxiosInstance } from 'axios';
import { cloneDeep } from 'lodash';
import { localStoreAPI } from '@service/local-storage.service';

export const DefaultHeaders = {
    accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
};

export const AXIOS_INSTANCE: AxiosInstance = axios.create({
    headers: DefaultHeaders,
});

AXIOS_INSTANCE.interceptors.request.use(function (config: any) {
    const accessToken = localStoreAPI.getAccessToken();
    const anonymousToken = localStoreAPI.getAnonymousToken();
    const isLogin = !!accessToken;
    if (isLogin) {
        config.headers.Authorization = 'Bearer ' + accessToken;
    } else {
        config.headers.Authorization = 'Bearer ' + anonymousToken;
    }
    return config;
});

AXIOS_INSTANCE.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        // whatever you want to do with the error
        if (error.response.status === 401) {
            const refreshToken = localStoreAPI.getRefreshToken();
            const copyOriginalRequest = cloneDeep(originalRequest);
            if (!refreshToken) {
                localStoreAPI.clearLocalStorage();
                window.location.href = '/login';
                return;
            }

            const newToken = await authAPI.tokenRefresh(refreshToken as any).catch((error) => {
                localStoreAPI.clearLocalStorage();
                window.location.href = '/login';
                return;
            });
            copyOriginalRequest.headers.Authorization = 'Bearer ' + newToken;
            return AXIOS_INSTANCE(copyOriginalRequest);
        }
        return Promise.reject(error);
    },
);
