import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    getAvailableOrganizations,
    selectAvailableOrganizationsInfo,
} from '@store/slices/organizationSlice';
import './DashboardAccessibility.scss';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { useForm } from 'react-hook-form';
import { IDashboardAccessibility } from '@models/dashboard.modal';
import { getActiveEntities, selectActiveEntityInfo } from '@store/slices/entitySlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
    getActiveDevices,
    getEntityDevices,
    selectActiveDeviceInfo,
    selectedEntityDeviceInfo,
} from '@store/slices/deviceSlice';
import { IEntity } from '@models/organization.model';
import { CoreButton } from "@packages";

const { RangePicker } = DatePicker;

const DashboardAccessibility = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);

    const availableOrganizationInfo = useAppSelector(selectAvailableOrganizationsInfo);
    const entitiesInfo = useAppSelector(selectActiveEntityInfo);
    const devicesInfo = useAppSelector(selectedEntityDeviceInfo);
    const activeDevicesInfo = useAppSelector(selectActiveDeviceInfo);

    useEffect(() => {
        dispatch(getAvailableOrganizations());
    }, []);

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<IDashboardAccessibility>({
        mode: 'onTouched',
        defaultValues: {
            FilterType: `${t('PREVIOUS_SEVEN_DAYS')}`,
        },
    });

    const options = [
        { value: '7d', label: "PREVIOUS_SEVEN_DAYS" },
        { value: '30d', label: "PREVIOUS_ONE_MONTH" },
        { value: '1yr', label: "PREVIOUS_ONE_YEAR" },
        {
            value: 'Custom',
            label: `${t('CUSTOM')}`,
        },
    ];

    const handleFilterChange = (value: string) => {
        if (value === `${t('CUSTOM')}`) {
            setOpen(!open);
        }
    };

    const getAllOrgDependency = (orgId: string) => {
        dispatch(getActiveEntities(orgId))
            .then(unwrapResult)
            .then((result) => {
                if (result && result.length === 1) {
                    const entity = result[0];
                    setFormValue('EntityId', entity?.EntityId || '');
                    getAllEntityDependency(entity?.EntityId, entity);
                } else {
                    setFormValue('EntityId', '');
                    dispatch(getActiveDevices(orgId));
                }
            });
    };

    const getAllEntityDependency = (entityId: string, entity: IEntity) => {
        dispatch(
            getEntityDevices({
                organizationId: entity?.OrganizationId || '',
                entityId: entityId,
            }),
        )
            .then(unwrapResult)
            .then((result) => {
                if (result && result.length === 1) {
                    const device = result[0];
                    setFormValue('DeviceId', device?.DeviceId || '');
                } else {
                    setFormValue('DeviceId', '');
                }
            });
    };

    const onSubmit = () => {
        console.log('output');
    };

    return (
        <div className='dashboard-accessibility'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='dashboard-accessibility-form'>
                    <RangePicker
                        open={open}
                        style={{ visibility: 'hidden', width: 0, position: 'absolute' }}
                        onOpenChange={(open) => setOpen(open)}
                    />
                    <InlineGenericSelect
                        sorting={false}
                        className='dashboard-date-filter-dropdown'
                        marginBottom={-15}
                        control={control}
                        errors={errors}
                        showLevel={false}
                        inputCol={24}
                        levelCol={24}
                        dataKey='FilterType'
                        level='Filter'
                        placeHolder='Filter'
                        options={options}
                        onChange={handleFilterChange}
                        setFormValue={setFormValue}
                        rules={{
                            required: false,
                        }}
                    />
                    <InlineGenericSelect
                        className='dashboard-filter-dropdown'
                        marginBottom={-15}
                        control={control}
                        errors={errors}
                        showLevel={false}
                        inputCol={24}
                        dataKey='OrganizationId'
                        level={t('SELECT_ORGANIZATION')}
                        placeHolder={t('SELECT_ORGANIZATION')}
                        options={availableOrganizationInfo.data}
                        setFormValue={setFormValue}
                        onChange={getAllOrgDependency}
                        rules={{
                            required: false,
                        }}
                    />
                    <InlineGenericSelect
                        className='dashboard-filter-dropdown'
                        marginBottom={-15}
                        control={control}
                        errors={errors}
                        showLevel={false}
                        inputCol={24}
                        dataKey='EntityId'
                        level={t('SELECT_ENTITY')}
                        placeHolder={t('SELECT_ENTITY')}
                        options={entitiesInfo.data}
                        setFormValue={setFormValue}
                        onChange={getAllEntityDependency}
                        rules={{
                            required: false,
                        }}
                    />
                    <InlineGenericSelect
                        className='dashboard-filter-dropdown'
                        marginBottom={-15}
                        control={control}
                        errors={errors}
                        showLevel={false}
                        inputCol={24}
                        dataKey='DeviceId'
                        level={t('SELECT_DEVICE')}
                        placeHolder={t('SELECT_DEVICE')}
                        options={
                            entitiesInfo.data.length === 0
                                ? activeDevicesInfo.data
                                : devicesInfo.data
                        }
                        setFormValue={setFormValue}
                        rules={{
                            required: false,
                        }}
                    />
                </div>

                <div className='dashboard-accessibility-actions'>
                    <CoreButton text='Apply' type='primary'/>
                </div>
            </form>
        </div>
    );
};

export default DashboardAccessibility;
