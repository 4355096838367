import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DEFAULT_TABLE_SIZE } from '@config/constants';
import DetailsModal from '../Details-modal/Details-modal';
import { IApiService } from '@models/api-services.model';
import { ApiServicesAPI } from '@service/api-services.service';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getApiServices, selectApiServices } from '@store/slices/apiServicesSlice';
import { UtcToLocalDate } from '@utils/date';
import { message, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ConfirmationModal } from '@components';

const columns = (
    t: TFunction<'translation', undefined>,
    navigate: NavigateFunction,
    onDeleteClick: (keyId: string) => void,
): ColumnsType<IApiService> => [
    {
        title: t('CREATED_DATE'),
        dataIndex: 'CreatedAt',
        render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
    },
    {
        title: t('Expire Date'),
        dataIndex: 'ExpiresAt',
        render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
    },
    {
        title: t('DESCRIPTION'),
        dataIndex: 'Description',
        render: (text: string) => <span>{text}</span>,
    },
    {
        title: t('Is Active'),
        dataIndex: 'IsActive',
        render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
    },
    {
        title: '',
        dataIndex: 'APIKey',
        render: (key: string) => (
            <div className='action-buttons'>
                <div
                    className='edit-table-icon'
                    onClick={() => navigate(`/api-services/update/${key}`)}
                    aria-hidden='true'
                >
                    <EditOutlined />
                </div>
                <div
                    className='edit-table-icon'
                    onClick={(event) => {
                        event?.stopPropagation();
                        onDeleteClick(key);
                    }}
                    aria-hidden='true'
                >
                    <DeleteOutlined style={{ color: 'red' }} />
                </div>
            </div>
        ),
    },
];
export default function ServiceTable() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data, status } = useAppSelector(selectApiServices);
    const [isLoading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState<IApiService | undefined>();
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [deleteModalId, setDeleteModalId] = useState<string>('');

    const getAllApiServices = () => {
        dispatch(getApiServices());
    };

    useEffect(() => {
        getAllApiServices();
    }, []);

    const onDeleteService = async () => {
        try {
            setLoading(true);
            const response = await ApiServicesAPI.deleteApiService(deleteModalId);
            getAllApiServices();
            setLoading(false);
            setDeleteModalId('');
        } catch {
            setLoading(false);
            message.error(t('CUSTOMER_DATA_LOAD_FAILED'));
        }
    };

    const onDeleteClick = (keyId: string) => {
        setDeleteModalId(keyId);
    };

    const onModalClick = () => {
        setShowDetailsModal(false);
        setSelectedData(undefined);
    };

    return (
        <div className='ksa-table-wrapper table-auto-scroll-with-header' style={{ marginTop: 10 }}>
            <Table
                loading={status === 'loading'}
                columns={columns(t, navigate, onDeleteClick)}
                dataSource={data}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            // click row
                            setSelectedData(record);
                            setShowDetailsModal(true);
                        },
                    };
                }}
                rowClassName='cursor-pointer'
                rowKey={'APIKey'}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: 0,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${t('OF')} ${total} ${t('ITEMS')}`,
                }}
            />

            <DetailsModal
                data={selectedData as IApiService}
                isModalOpen={showDetailsModal}
                handleOk={onModalClick}
            />

            <ConfirmationModal
                title='Delete API & Services'
                description='Do you want to delete this API & Services?'
                buttonText='Delete'
                isDeleteModalOpen={!!deleteModalId}
                handleDeleteOk={onDeleteService}
                handleDeleteCancel={() => setDeleteModalId('')}
                isLoading={isLoading}
            />
        </div>
    );
}
