import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, ResponsiveContainer, Label } from 'recharts';

const NoDataPieChart = () => {
    const { t } = useTranslation();
    const noData = [{ name: 'No Data', count: 1 }];
    return (
        <ResponsiveContainer width={'100%'} height={295}>
            <PieChart>
                <Pie
                    widths={200}
                    cy='50%'
                    data={noData}
                    innerRadius={80}
                    outerRadius={120}
                    fill='red'
                    paddingAngle={1}
                    nameKey='name'
                    dataKey='count'
                >
                    <Cell fill="#e0e0e0" />
                    <Label
                        value={t('NO_DATA')}
                        position='center'
                        fill='black'
                        fontSize={20}
                        fontWeight={600}
                    />
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default NoDataPieChart;
