import React from "react";
import { CoreButton, CoreLoginWrapper, CoreTitle } from "@packages";
import "./CoreAuthConfirmation.scss"
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

interface ICoreAuthConfirmationProps {
    title?: string;
    description?: string;
    subDescription?: string;
    linkTo?: string;
    isLoading?: boolean;
}

const CoreAuthConfirmation = (props: ICoreAuthConfirmationProps) => {
    const { t } = useTranslation();
    const { title, description, subDescription, linkTo, isLoading = false } = props;
    return (
        <CoreLoginWrapper>
            <div className="confirm-signup-wrapper">
                <CoreTitle tag='h1' text={title} />
                <div className="sub-message">
                    <p>{description}</p>
                    {subDescription && <p>{subDescription}</p>}
                </div>
                {isLoading && <Spin size="large"></Spin>}
                {linkTo && <CoreButton htmlType="button" type="primary" text={t('SIGN_IN')} linkTo={linkTo} />}
            </div>
        </CoreLoginWrapper>
    );
};

export default CoreAuthConfirmation;