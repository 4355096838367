import React from 'react';
import { FileTextOutlined, GroupOutlined, SaveOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import './MenuItems.scss';
import { CustomerIcon } from '../../icons';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import DashboardIcon from '../../icons/DashboardIcon';
import { useTranslation } from 'react-i18next';
import { useGetMenuSelectedKey } from '@hooks';
import { useAppSelector } from '@store/hooks';
import { userSelectRole } from '@store/slices/userSlice';

export const MenuItems = () => {
    const { t } = useTranslation();
    const { isAdmin, isOrganizationAdmin } = useAppSelector(userSelectRole);
    type MenuItem = Required<MenuProps>['items'][number];

    const selectedKey = useGetMenuSelectedKey();

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const items: MenuItem[] = [
        (isAdmin &&
            getItem(
                <div className='full-width-height'>{t('ACCESS_MANAGEMENT')}</div>,
                'access-management',
                <FileTextOutlined />,
                [
                    getItem(
                        <Link to='/users' className='justify-between'>
                            <div>{t('USERS')}</div>
                        </Link>,
                        'users',
                    ),
                    getItem(
                        <Link to='/roles' className='justify-between'>
                            <div>{t('ROLES')}</div>
                        </Link>,
                        'roles',
                    ),
                    getItem(
                        <Link to='/policies' className='justify-between'>
                            <div>{t('POLICIES')}</div>
                        </Link>,
                        'policies',
                    ),
                    getItem(
                        <Link to='/actions' className='justify-between'>
                            <div>{t('ACTIONS')}</div>
                        </Link>,
                        'actions',
                    ),
                ],
            )) ||
            null,
        (isOrganizationAdmin &&
            getItem(
                <Link to='/dashboard'>{t('DASHBOARD')}</Link>,
                'dashboard',
                <DashboardIcon />,
            )) ||
            null,
        (isOrganizationAdmin &&
            getItem(
                <Link to='/organizations '>{t('ORGANIZATIONS')}</Link>,
                'organizations',
                <GroupOutlined />,
            )) ||
            null,
        (isOrganizationAdmin &&
            getItem(
                <Link to='/customers'>{t('CUSTOMERS')}</Link>,
                'customers',
                <CustomerIcon />,
            )) ||
            null,
        // getItem(<Link to='/items'>{t('PRODUCTS')}</Link>, 'items', <InventoryIcon />),

        (isOrganizationAdmin &&
            getItem(
                <Link to='/e-invoices' className='full-width-height'>
                    {t('E_INVOICES')}
                </Link>,
                'e-invoices',
                <FileTextOutlined />,
            )) ||
            null,
        (isAdmin &&
            getItem(
                <Link to='/billing' className='full-width-height'>
                    Billing
                </Link>,
                'billing',
                <FileTextOutlined />,
            )) ||
            null,
        (isAdmin &&
            getItem(
                <Link to='/template' className='full-width-height'>
                    Template
                </Link>,
                'template',
                <FileTextOutlined />,
            )) ||
            null,
        // (isAppUser &&
        //     getItem(
        //         <Link to='/support' className='full-width-height'>
        //             Support
        //         </Link>,
        //         'support',
        //         <FileTextOutlined />,
        //     )) ||
        //     null,
        (isAdmin &&
            getItem(<Link to='/archives'>{t('ARCHIVES')}</Link>, 'archives', <SaveOutlined />)) ||
            null,
        (isOrganizationAdmin &&
            getItem(
                <Link to='/api-services'>{t('API & Services')}</Link>,
                'api-services',
                <SaveOutlined />,
            )) ||
            null,
    ].filter(Boolean);

    return (
        <div className='menu-wrapper'>
            <Menu
                mode='inline'
                defaultSelectedKeys={['']}
                defaultOpenKeys={[]}
                style={{ height: '100%' }}
                items={items}
                selectedKeys={selectedKey}
            />
        </div>
    );
};

export default MenuItems;
