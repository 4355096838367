import React from 'react';

const RedWave = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 480 75'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_10698_359)'>
                <path
                    d='M105.481 25.0943C87.3948 25.6246 27.8601 22.6639 0.353516 21.1173V57.264C0.353516 68.4803 9.44616 77.573 20.6625 77.573H459.337C470.553 77.573 479.646 68.4803 479.646 57.264V40.3398C441.212 38.3513 409.523 28.4486 390.344 27.7457C372.257 27.0829 369.996 26.4201 365.475 25.0943C358.325 22.9982 324.565 7.37232 298.781 3.88321C269.39 -0.093878 228.696 12.5002 204.957 12.5002C181.218 12.5002 169.914 13.8259 164.262 15.1516C160.064 16.1363 128.089 24.4315 105.481 25.0943Z'
                    fill='url(#paint0_linear_10698_359)'
                />
                <path
                    d='M0.353516 21.4447C27.8601 23.0195 87.3948 26.034 105.481 25.4941C128.089 24.8192 160.064 16.3732 164.262 15.3706C169.914 14.0208 181.218 12.671 204.957 12.671C228.696 12.671 269.39 -0.15207 298.781 3.89733C324.565 7.44988 358.325 23.3599 365.475 25.4941C369.996 26.8439 372.257 27.5188 390.344 28.1937C409.523 28.9094 441.212 38.9921 479.646 41.0168'
                    stroke='#F04438'
                    strokeWidth='2.24245'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_10698_359'
                    x1='240'
                    y1='8.42571'
                    x2='240'
                    y2='106.828'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#F04438' stopOpacity='0.33' />
                    <stop offset='1' stopColor='white' stopOpacity='0' />
                </linearGradient>
                <clipPath id='clip0_10698_359'>
                    <rect
                        width='479.292'
                        height='77.1742'
                        fill='white'
                        transform='translate(0.353516 0.398926)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RedWave;
