import { CoreTitle } from '@packages';
import './DataQualitySummaryCard.scss';
import { Row, Col, Spin } from 'antd';
import { FC } from "react";
import { IDataQualitySummary } from "@models/dashboard.model";
import { useTranslation } from 'react-i18next';

interface DataQualitySummaryCardProps extends IDataQualitySummary {
    dataQualityLoading: boolean;
}

const DataQualitySummaryCard: FC<DataQualitySummaryCardProps> = ({ cleared, clearedWithWarning, dataQualityLoading }) => {
    const { t } = useTranslation();
    const totalCount = cleared + clearedWithWarning;

    return (
        <div className='data-quality-summary-wrapper'>
            {dataQualityLoading ? (
                <div className='loading-spinner'>
                    <Spin size='large' />
                </div>
            ) : (
                <>
                    <CoreTitle tag='h6' text={t('DATA_QUALITY_SUMMARY')} />
                    <Row className='data-quality-summary-row' gutter={10}>
                        <Col span={12} className='data-quality-summary-column yellow'>
                            <CoreTitle tag='h6' text={cleared.toString()} />
                            <p className='data-quality-summary-column-text'>{t('CLEARED_INVOICES')}</p>
                        </Col>
                        <Col span={12} className='data-quality-summary-column gray'>
                            <CoreTitle tag='h6' text={clearedWithWarning.toString()} />
                            <p className='data-quality-summary-column-text'>{t('CLEARED_WITH_WARNING')}</p>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}

export default DataQualitySummaryCard;
