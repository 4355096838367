import { Col, Image, Row, Timeline } from 'antd';
import './FlexibilityBusiness.scss';
import CheckMark from '../../svg/CheckMark';
import dashboard from '../../assets/dashboard.png';

const FlexibilityBusiness = () => {
    return (
        <div className='flexibility-business-wrapper'>
            <Row align='middle' gutter={[48, 18]}>
                <Col xs={24} lg={12}>
                    <div className='flexibility-title-section'>
                        <h1>Flexibility to fit your business</h1>
                        <div className='flexibility-image-wrapper'>
                            <Image src={dashboard} preview={false} />
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div className='timeline-section'>
                        <Timeline
                            className='custom-timeline'
                            items={[
                                {
                                    dot: <CheckMark />,
                                    children:
                                        'Get out-of-the-box self-billing, third party billing, and summary invoices.',
                                },
                                {
                                    dot: <CheckMark />,
                                    children:
                                        'Get out-of-the-box self-billing, third party billing, and summary invoices.',
                                },
                                {
                                    dot: <CheckMark />,
                                    children:
                                        'Get out-of-the-box self-billing, third party billing, and summary invoices.',
                                },
                                {
                                    dot: <CheckMark />,
                                    children:
                                        'Get out-of-the-box self-billing, third party billing, and summary invoices.',
                                },
                                {
                                    dot: <CheckMark />,
                                    children:
                                        'Get out-of-the-box self-billing, third party billing, and summary invoices.',
                                },
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default FlexibilityBusiness;
