import { useRef, useState } from 'react';
import './CustomerList.scss';
import { Col, Row, Tag } from 'antd';
import { CoreButton, CoreSearchInput } from '@packages';
import CustomerListTable from '../../components/customer-list-table/CustomerListTable';

import { useNavigate } from 'react-router-dom';
import { identity, isEmpty, omit, pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { splitCamelCase } from '@utils/splitCamelCase';
import { CustomerFilterInputs } from "../../index";
import { FilterDropDown } from "@components";
import { ICustomerFilterData } from "../../components/customer-filter-inputs/CustomerFilterInputs";

const CustomerList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState<ICustomerFilterData>();
    const [query, setQuery] = useState<string>();

    const myFilterRef = useRef<any>();
    const handleFilterSubmit = (data: ICustomerFilterData) => {
        setFilterData(data);
        myFilterRef?.current?.setHideFilter();
    };

    const clearAllFilters = () => {
        setFilterData({} as ICustomerFilterData);
    };

    const clearFilter = (item: string) => {
        if (!filterData) return;
        const removeFilter = omit(filterData, item) as any;
        setFilterData(removeFilter);
    };
    const filterNullFilterData = pickBy(filterData, identity);
    return (
        <div className='customer-list-container'>
            <div className='margin-30'>
                <div className='filter-header'>
                    <CoreSearchInput placeholder='Search' handleInputChange={setQuery} />
                    <FilterDropDown
                        content={
                            <CustomerFilterInputs
                                handleFilterSubmit={handleFilterSubmit}
                                defaultValues={filterData}
                            />
                        }
                        ref={myFilterRef}
                    ></FilterDropDown>
                    <CoreButton
                        className='submit-button'
                        type='primary'
                        text={t('CREATE_NEW')}
                        isFullWidth={true}
                        onClick={() => navigate('/customers/create')}
                    />
                </div>

                {!isEmpty(filterNullFilterData) && (
                    <Row className='filter-wrapper' justify='space-between'>
                        <Col span={20}>
                            {Object.entries(filterNullFilterData).map(([key, value]) => (
                                <Tag
                                    key={key}
                                    className='margin-bottom-10'
                                    closable
                                    onClose={() => clearFilter(key)}
                                >
                                    <span>{t(splitCamelCase(key))}</span>:{' '}
                                    <span className='filter-value'>{value}</span>
                                </Tag>
                            ))}
                        </Col>
                        <Col span={4}>
                            <Row justify='end'>
                                <div>
                                    <CoreButton
                                        type='basic'
                                        isFullWidth={false}
                                        text={t('CLEAR_ALL')}
                                        onClick={clearAllFilters}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
            <div className='margin-top-20'>
                <CustomerListTable filterData={{ ...filterData }} query={query} />
            </div>
        </div>
    );
};

export default CustomerList;
