import { configureStore } from '@reduxjs/toolkit';
import organizationReducer from './slices/organizationSlice';
import customerReducer from './slices/customerSlice';
import einvoiceReducer from './slices/einvoiceSlice';
import authReducer from './slices/authSlice';
import setup2FAReducer from './slices/setup-2FASlice';
import DynamicTitleReducer from './slices/dynamicTitleSlice';
import UserReducer from './slices/userSlice';
import inventoriesReducer from './slices/InventoriesSlice';
import subscribeReducer from './slices/subscribeSlice';
import dashboardReducer from './slices/dashboardSlice';
import appLanguageReducer from './slices/languageSlice';
import deviceReducer from './slices/deviceSlice';
import entityReducer from './slices/entitySlice';
import actionReducer from './slices/actionSlice';
import policyReducer from './slices/policySlice';
import roleReducer from './slices/roleSlice';
import paymentReducer from './slices/paymentSlice';
import adminTemplateReducer from './slices/adminTemplateSlice';
import invoiceMappingReducer from './slices/invoiceMappingSlice';
import subscriptionOverviewReducer from './slices/subscriptionOverviewSlice';
import apiServicesReducer from './slices/apiServicesSlice';
import paymentCalculatorReducer from './slices/paymentCalculator';

export const store = configureStore({
    reducer: {
        organization: organizationReducer,
        auth: authReducer,
        einvoice: einvoiceReducer,
        customers: customerReducer,
        enable2FAData: setup2FAReducer,
        dynamicTitle: DynamicTitleReducer,
        user: UserReducer,
        inventory: inventoriesReducer,
        subscribe: subscribeReducer,
        dashboard: dashboardReducer,
        language: appLanguageReducer,
        device: deviceReducer,
        entity: entityReducer,
        action: actionReducer,
        policy: policyReducer,
        role: roleReducer,
        payment: paymentReducer,
        adminTemplate: adminTemplateReducer,
        invoiceMapping: invoiceMappingReducer,
        subscriptionOverview: subscriptionOverviewReducer,
        apiServices: apiServicesReducer,
        paymentCalculator: paymentCalculatorReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
