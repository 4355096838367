import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const DownArrowSvg = () => (
    <svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M3.75 6.125L7.5 9.875L11.25 6.125'
            stroke='currentColor'
            strokeWidth='1.67'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

const DownArrowIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={DownArrowSvg} {...props} />
);

export default DownArrowIcon;
