import React from 'react';
import './TemplateItem.scss';
import dummy_invoice from '../../../assets/images/dummy-invoice.png';
import { Button, Dropdown, Image, MenuProps, Space } from 'antd';
import { CoreButton } from '@packages';
import { DownOutlined, SettingOutlined } from '@ant-design/icons';

const TemplateItem = () => {
    const items: MenuProps['items'] = [
        {
            label: 'Preview',
            key: '0',
        },
        {
            label: 'Set As Default',
            key: '1',
        },
        {
            label: 'Delete',
            key: '2',
        },
        {
            label: 'CLONE AS',
            key: '3',
        },
        {
            label: 'Payment Receipt',
            key: '5',
        },
    ];

    return (
        <div className='template-item-wrapper'>
            <Image
                src={dummy_invoice}
                preview={false}
                width={'100%'}
                height={'100%'}
                className='image'
            />
            <div className='template-item-action-button'>
                <CoreButton text='Edit' type='primary' size='small' />
                <Dropdown menu={{ items }} trigger={['click']} className='template-item-setting'>
                    <Button onClick={(e) => e.preventDefault()}>
                        <Space>
                            <SettingOutlined />
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
};
export default TemplateItem;
