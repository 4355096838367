import { CoreButton, CoreTitle } from "@packages";
import "./CancelAutoRenewal.scss";
import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useNavigate } from "react-router-dom";

const CancelAutoRenewal = () => {
    const navigate = useNavigate();

    const onChange = (checkedValues: CheckboxValueType[]) => {
        console.log('checked = ', checkedValues);
    };

    return (
        <div className="auto-renewal-wrapper">
            <CoreTitle tag='h2' text="We're curious why you're canceling auto-renewal?" />
            <p className="auto-renewal-sub-title">Please provide us with ine reason</p>

            <div className='margin-top-24'>
                <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                    <div className="checkbox-wrapper">
                        <div className="checkbox-inner-wrapper">
                            <Checkbox value="A" className="checkbox-title">The product has a technical issue</Checkbox>
                        </div>
                        <div className="checkbox-inner-wrapper">
                            <Checkbox value="B" className="checkbox-title">I don't use it enough</Checkbox>
                        </div>
                        <div className="checkbox-inner-wrapper">
                            <Checkbox value="C" className="checkbox-title">It's too expensive</Checkbox></div>
                        <div className="checkbox-inner-wrapper">
                            <Checkbox value="D" className="checkbox-title">I want to renew manually</Checkbox>
                        </div>
                    </div>

                    <div className="auto-renewal-actions">
                        <CoreButton type='secondary' text="Back" onClick={() => {
                            navigate(`/subscribe`, {
                                state: {
                                    currentTab: "payment",
                                },
                            });
                        }}
                        />
                        <CoreButton type='primary' text="Submit and continue" />
                    </div>
                </Checkbox.Group>
            </div>

        </div>
    )
}

export default CancelAutoRenewal