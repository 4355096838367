import { CoreButton } from '@packages';
import { Modal } from 'antd';
import React, { useRef, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentForm, { AddPaymentFormRef } from './AddPaymentForm';
import { APP_CONFIG } from '@config/constants';
import { useTranslation } from 'react-i18next';

interface IAddPaymentProps {
    isOpenModal: boolean;
    onCancelModal: () => void;
}
const stripePromise = loadStripe(APP_CONFIG.stripeApiKey || '');

export default function AddPayment({ isOpenModal, onCancelModal }: IAddPaymentProps) {
    const { t } = useTranslation();
    const childRef = useRef<AddPaymentFormRef>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const onCancelButton = () => {
        onCancelModal();
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
        childRef &&
            childRef.current &&
            childRef.current.onFormSubmit &&
            childRef.current?.onFormSubmit();
    };

    return (
        <Modal
            width={800}
            open={isOpenModal}
            closable={false}
            title={t('ADD_A_PAYMENT')}
            footer={[
                <CoreButton key={1} text={t('CANCEL')} type={'basic'} onClick={onCancelButton} />,
                <CoreButton
                    onClick={onSubmit}
                    htmlType='submit'
                    key={2}
                    text={t('ADD')}
                    type={'primary'}
                    className='submit-modal-button'
                    loading={isLoading}
                />,
            ]}
        >
            <Elements stripe={stripePromise}>
                <AddPaymentForm
                    setLoading={setLoading}
                    onCancelModal={onCancelModal}
                    ref={childRef}
                />
            </Elements>
        </Modal>
    );
}
