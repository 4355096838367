import { IInvoiceLinesItem } from '@models/invoice.model';
import { TAX_CATEGORY, TaxCategoryType } from '@utils/InvoiceUtils';
import { Select } from 'antd';
import { useState } from 'react';
import ReasonSelection, { IExemtedReason } from './ReasonSelection';
import GenericTooltip from '@components/common/GenericTooltip';
import { useTranslation } from 'react-i18next';

interface IPriceTaxLineProps {
    data: IInvoiceLinesItem;
    updateInvoiceLine: (itemId: string) => (object: Partial<IInvoiceLinesItem>) => void;
    isRequiredShow: boolean;
}
function PriceTaxLine({ data, updateInvoiceLine, isRequiredShow }: IPriceTaxLineProps) {
    const { t } = useTranslation();
    const [isShowExemptedReasonModal, setExemptedReasonShowModal] = useState<boolean>(false);
    const [selectedTaxCategory, setTaxCategoryType] = useState<TaxCategoryType>();

    const onSelectExemptedReason = (reason: IExemtedReason) => {
        selectedTaxCategory && onSetPriceTaxLine(selectedTaxCategory, reason);
    };
    const onSetPriceTaxLine = (taxCategory: TaxCategoryType, reason?: IExemtedReason) => {
        const { value: TaxCategory, percent: TaxPercent } = taxCategory || {};
        const TaxExemptionCode = reason?.Reason;
        updateInvoiceLine(data.SerialId)({
            TaxCategory,
            TaxPercent,
            TaxExemptionCode,
            ...reason,
        });
        setExemptedReasonShowModal(false);
        setTaxCategoryType(undefined);
    };
    const onCancelReason = () => {
        updateInvoiceLine(data.SerialId)({
            TaxExemptionCode: undefined,
            TaxCategory: undefined,
            TaxPercent: undefined,
            Reason: undefined,
            ReasonEn: undefined,
            ReasonCode: undefined,
        });
        setExemptedReasonShowModal(false);
        setTaxCategoryType(undefined);
    };
    return (
        <>
            <div className='col price-tax' style={{ display: 'flex' }}>
                <Select
                    value={data?.TaxCategory || undefined}
                    placeholder={t('SELECT_RATE')}
                    className={`TAX-select ${
                        isRequiredShow && !data?.TaxCategory && 'required-select-border'
                    }`}
                    showSearch={false}
                    options={TAX_CATEGORY || []}
                    onChange={(value, options) => {
                        const taxCategory = options as TaxCategoryType;
                        if (taxCategory.value === 'S') {
                            onSetPriceTaxLine(taxCategory, undefined);
                            return;
                        }
                        onSetPriceTaxLine(taxCategory);
                        setTaxCategoryType(taxCategory);
                        setExemptedReasonShowModal(true);
                    }}
                />
                <div style={{ position: 'relative', marginTop: 7, marginLeft: 2 }}>
                    {data?.TaxExemptionCode && <GenericTooltip text={data?.TaxExemptionCode} />}
                </div>
            </div>

            {selectedTaxCategory && (
                <ReasonSelection
                    isOpenModal={isShowExemptedReasonModal}
                    onSelectReason={onSelectExemptedReason}
                    taxCategory={selectedTaxCategory?.value}
                    setHideModal={() => setExemptedReasonShowModal(false)}
                    onCancelReason={onCancelReason}
                />
            )}
        </>
    );
}

export default PriceTaxLine;
