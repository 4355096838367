import { EInvoiceType } from '@models/invoice.model';
import {
    GET_ALL_INVOICE_TYPES_WITH_TREE,
    GET_INITIAL_INVOICE_TYPES,
    GET_SIMPLIFIED_INVOICE_TYPES,
    GET_STANDARD_INVOICE_TYPES,
    IInvoiceTypeOption,
} from '@utils/InvoiceUtils';

export const INVOICE_TYPE_OPTIONS = [
    {
        value: '1000',
        label: 'STANDARD_INVOICE',
        display_value: 'Standard Invoice',
    },
    {
        value: '0100',
        label: 'SIMPLIFIED_INVOICE',
        display_value: 'Simplified Invoice',
    },
    {
        value: '1100',
        label: 'BOTH',
        display_value: 'Both',
    },
];

export function invoiceTypeOptions(invoiceType: string): IInvoiceTypeOption[] {
    if (invoiceType === '1000') return GET_STANDARD_INVOICE_TYPES;
    if (invoiceType === '0100') return GET_SIMPLIFIED_INVOICE_TYPES;
    return GET_ALL_INVOICE_TYPES_WITH_TREE;
}

export function invoiceTypeOptionsForInitial(invoiceType: string): IInvoiceTypeOption[] {
    if (invoiceType === '1000')
        return [
            {
                value: EInvoiceType['Standard Tax Invoice'],
                label: 'Standard Tax Invoice',
                displayKey: 'STANDARD_TAX_INVOICE',
            },
        ];
    if (invoiceType === '0100')
        return [
            {
                value: EInvoiceType['Simplified Tax Invoice'],
                label: 'Simplified Tax Invoice',
                displayKey: 'SIMPLIFIED_TAX_INVOICE',
            },
        ];
    return GET_INITIAL_INVOICE_TYPES;
}
