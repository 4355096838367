import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input, message, Popover } from "antd";
import { CoreButton, CoreTitle } from "../../packages";
import { RegexValidators } from "@config/regex-validator-constant";
import './CoreLoginPage.scss'
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { postLogin } from "@store/slices/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { plainTextToLocalizationText } from "@utils/getLocalizationKey";

interface ICoreLoginPageProps {
    title: string;
    subTitle: string;
}

export interface ILogin {
    email: string;
    password: string;
}

const CoreLoginPage = (props: ICoreLoginPageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isPageLoading, setLoading] = useState<boolean>(false);


    const {
        title = '',
        subTitle = '',
    } = props

    const redirectPageAfterLogin = async () => {
        navigate('/authorization')
    }


    const { control, handleSubmit, formState: { errors, isValid } } = useForm<ILogin>({ mode: "onTouched" });


    const [isInputFocused, setIsInputFocused] = useState(false);
    const inputRef = useRef<any>(null);
    const [hasError, setHasError] = useState(false);


    const [password, setPassword] = useState("");
    const [isTenChar, setIsTenChar] = useState(false);
    const [containsUpperAndLower, setContainsUpperAndLower] = useState(false);
    const [containsNumber, setContainsNumber] = useState(false);
    const [containsSpecial, setContainsSpecial] = useState(false);


    const onChangePassword = (e: any) => {
        password.length >= 0 && setHasError(false)

        const pass = e.target.value;
        pass.length >= 10 ? setIsTenChar(true) : setIsTenChar(false);
        pass.match(RegexValidators.UPPERCASE_LOWERCASE)
            ? setContainsUpperAndLower(true)
            : setContainsUpperAndLower(false);
        pass.match(RegexValidators.ONLY_ONE_NUMBER)
            ? setContainsNumber(true)
            : setContainsNumber(false);
        pass.match(RegexValidators.SPECIAL_CHARACTER)
            ? setContainsSpecial(true)
            : setContainsSpecial(false);
        setPassword(pass);
    };

    const onsubmit = ({ email }: ILogin) => {
        setLoading(true);
        dispatch(postLogin({ password, email }))
            .then(unwrapResult)
            .then((result) => {
                setLoading(false);
                if (Object.prototype.hasOwnProperty.call(result, 'method')) {
                    navigate('/two-step-verification');
                } else {
                    redirectPageAfterLogin();
                }
            })
            .catch((error) => {
                message.error(t(plainTextToLocalizationText(error?.message)) || 'Login Failed');
                setLoading(false);
            });
    };


    const handleInputFocus = () => {
        password.length < 1 && setHasError(true);
        setIsInputFocused(true);
    };

    const handleInputBlur = () => {
        password.length < 1 ? setHasError(true) : setHasError(false);
        setIsInputFocused(false);
    };

    const content = (
        <div style={{ width: "100%" }} className="mb-10">
            <p className="validation-header">
                {t('YOUR_PASSWORD_NEEDS_TO_BE_AT_LEAST')}
            </p>
            <div className="error-validation">
                {!isTenChar ? (
                    <BorderOutlined className="success-box" />
                ) : (
                    <CheckSquareOutlined className="success-checkbox" />
                )}
                <p className="validation-message">{t('10_CHARACTERS_LONG')}</p>
            </div>
            <div className="error-validation">
                {!containsUpperAndLower ? (
                    <BorderOutlined className="success-box" />
                ) : (
                    <CheckSquareOutlined className="success-checkbox" />
                )}
                <p className="validation-message">{t('INCLUDING_1_UPPERCASE_AND_1_LOWERCASE')}</p>
            </div>

            <div className="error-validation">
                {!containsSpecial ? (
                    <BorderOutlined className="success-box" />
                ) : (
                    <CheckSquareOutlined className="success-checkbox" />
                )}
                <p className="validation-message">{t('INCLUDING_1_SPECIAL_CHARACTER')}</p>
            </div>

            <div className="error-validation">
                {!containsNumber ? (
                    <BorderOutlined className="success-box" />
                ) : (
                    <CheckSquareOutlined className="success-checkbox" />
                )}
                <p className="validation-message">{t('ADD_1_NUMBER')}</p>
            </div>

        </div>
    )

    const isValidationMatch = isTenChar && containsUpperAndLower && containsNumber && containsSpecial;

    const handleKeyPress = (event: any) => {
        if (event.key === ' ') {
            event.preventDefault();
        }
    };

    return (
        <div className='core-login-wrapper'>
            <div className="core-login-wrapper-content">
                <div>
                    <CoreTitle tag="h1" text={title} />
                    <p className='core-login-sub-title'>{subTitle}</p>
                </div>
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div className='general-input-wrapper'>
                        <label className='general-label'>{t('EMAIL_ADDRESS')}</label>
                        <Controller
                            name="email"
                            render={({ field }) => <Input {...field} placeholder={t('EMAIL_ADDRESS')}
                                                          className="general-input" />}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: true,
                                pattern: RegexValidators.EMAIL,
                            }}
                        />
                        {errors?.email?.type === "required" && (
                            <span className='error-message'>{t('EMAIL_FIELD_IS_REQUIRED')}</span>)}
                        {errors?.email?.type === "pattern" && (
                            <span className='error-message'>{t("INVALID_EMAIL_ADDRESS")}</span>)}
                    </div>

                    <div className='general-input-wrapper'>
                        <label className='general-label'>{t('PASSWORD')}</label>
                        <Controller
                            name="password"
                            render={({ field }) => <Popover visible={isInputFocused} content={content}
                                                            placement='right'>
                                <div>
                                    <Input.Password placeholder={t('PASSWORD')} ref={inputRef}
                                                    onFocus={handleInputFocus}
                                                    onBlur={handleInputBlur}
                                                    className="general-input" onChange={onChangePassword}
                                                    onKeyPress={handleKeyPress} />
                                </div>
                            </Popover>}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: false,
                            }}
                        />
                        {hasError &&
                            <span className="error-message">{t('PASSWORD_IS_REQUIRED')}</span>}
                    </div>

                    <div className='core-login-buttons'>

                        <NavLink to={'/recover-account'} className='forgot-password'>{t('FORGOT_PASSWORD')}</NavLink>
                    </div>
                    <CoreButton loading={isPageLoading}
                                type="primary" htmlType="submit" disabled={!isValidationMatch} isFullWidth={true}
                                text={t('SIGN_IN')} />
                </form>
                <div className='core-login-in-buttons'>
                    <p>{t('DONT_HAVE_AN_ACCOUNT_YET?')}
                        <span className="sign-in-link" onClick={() => navigate('/sign-up')}>{t('SIGN_UP')}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CoreLoginPage;