import { Col, Row } from 'antd';
import './InventoryOverview.scss';
import { useAppSelector } from '@store/hooks';
import { selectSelectedInventory } from '@store/slices/InventoriesSlice';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { displayCurrency } from '@utils/displayCurrency';
import { useTranslation } from 'react-i18next';

const InventoryOverview = () => {
    const { t } = useTranslation();
    const inventory = useAppSelector(selectSelectedInventory);
    console.log(inventory, 'inventory');
    return (
        <div className='inventory-overview-wrapper'>
            <Row align='middle' className='margin-bottom-20'>
                <Col span={6}>
                    <p className='overview-label'>{t('ITEM_TYPE')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>
                        {inventory?.InventoryType || DEFAULT_EMPTY_VALUE}
                    </p>
                </Col>
            </Row>
            <Row align='middle' className='margin-bottom-20'>
                <Col span={6}>
                    <p className='overview-label'>{t('NAME_ARABIC')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>{inventory?.Name || DEFAULT_EMPTY_VALUE}</p>
                </Col>
            </Row>
            <Row align='middle' className='margin-bottom-20'>
                <Col span={6}>
                    <p className='overview-label'>{t('NAME_ENGLISH')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>{inventory?.NameEn || DEFAULT_EMPTY_VALUE}</p>
                </Col>
            </Row>
            <Row align='middle' className='margin-bottom-20'>
                <Col span={6}>
                    <p className='overview-label'>{t('UNIT')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>{inventory?.UnitCode || DEFAULT_EMPTY_VALUE}</p>
                </Col>
            </Row>

            <Row align='middle' className='margin-bottom-20'>
                <Col span={6}>
                    <p className='overview-label'>{t('BASE_QUANTITY')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>
                        {inventory?.BaseQuantity ?? DEFAULT_EMPTY_VALUE}
                    </p>
                </Col>
            </Row>

            <p className='overview-item-title'>{t('PURCHASE_INFORMATION')}</p>

            <Row align='middle' className='margin-bottom-30'>
                <Col span={6}>
                    <p className='overview-label'>{t('COST_PRICE')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>
                        {displayCurrency({
                            Code: inventory?.PurchaseCurrency || '',
                            Amount: inventory?.PurchasePrice || '',
                        })}
                    </p>
                </Col>
            </Row>

            <Row align='middle' className='margin-bottom-30'>
                <Col span={6}>
                    <p className='overview-label'>{t('DESCRIPTION')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>
                        <p className='overview-item'>
                            {inventory?.PurchaseDescription || DEFAULT_EMPTY_VALUE}
                        </p>
                    </p>
                </Col>
            </Row>

            <p className='overview-item-title'>{t('SALES_INFORMATION')}</p>

            <Row align='middle' className='margin-bottom-30'>
                <Col span={6}>
                    <p className='overview-label'>{t('SALES_PRICE')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>
                        {displayCurrency({
                            Code: inventory?.SaleCurrency || '',
                            Amount: inventory?.SalePrice || '',
                        })}
                    </p>
                </Col>
            </Row>

            <Row align='middle' className='margin-bottom-30'>
                <Col span={6}>
                    <p className='overview-label'>{t('DESCRIPTION')}</p>
                </Col>
                <Col span={18}>
                    <p className='overview-item'>
                        <p className='overview-item'>
                            {inventory?.SaleDescription || DEFAULT_EMPTY_VALUE}
                        </p>
                    </p>
                </Col>
            </Row>
        </div>
    );
};

export default InventoryOverview;
