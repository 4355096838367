import './HeroSection.scss';
import hero_banner from '../../assets/Credit Note and Tax Invoice.png';
import curl_image from '../../assets/CURL.png';

const HeroSection = () => {
    return (
        <div className='hero-section-wrapper'>
            <div className='title-wrapper'>
                <h1 className='hero-section-title'>Locally Compliant Invoicing</h1>
                <p className='hero-section-sub-title'>
                    Generate tax compliant invoices and credit-notes that include all required
                    information globally.
                </p>
            </div>
            <div className='image-container'>
                <img src={hero_banner} alt='hero-banner' className='banner-image' />
                <img src={curl_image} alt='curl' className='curl-image' />
            </div>
        </div>
    );
};

export default HeroSection;
