import { Tag } from 'antd';
import './TrackConsumption.scss';
import { CoreButton, CoreTitle } from '@packages';

const TrackConsumption = () => {
    return (
        <div className='track-consumption-wrappr round-box-shadow'>
            <Tag color='#285FF6'>TIP</Tag>
            <CoreTitle tag='h4' text='Looking for another way to track consumption?' />
            <p className='cunsumption-message'>
                Set up consumption alerts to track how much a project or team is spending
            </p>
            <p className='cunsumption-message'>
                You'll be notified via email whenever your specific trigger is set off
            </p>
            <CoreButton
                type='secondary'
                text='SET UP ALERTS'
                isFullWidth={true}
                className='alert-action'
            />
        </div>
    );
};

export default TrackConsumption;
