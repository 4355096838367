import { IInvoiceItemCharges, IInvoiceLinesItem } from '@models/invoice.model';
import { cloneDeep } from 'lodash';

export async function addAllowanceTaxToInvoiceLineItems(
    invoiceData: IInvoiceLinesItem[],
): Promise<IInvoiceLinesItem[]> {
    // Loop through invoice lines
    const items = await invoiceData.map((item) => {
        const lineItem: IInvoiceLinesItem = item ? cloneDeep(item) : ({} as IInvoiceLinesItem);
        if (!lineItem.AllowanceCharges) {
            lineItem.AllowanceCharges = [];
            return lineItem;
        }
        const allowanceCharges = lineItem.AllowanceCharges.map((charge) => {
            return {
                ...charge,
                AllowanceTax: {
                    Category: lineItem.TaxCategory,
                    Percent: lineItem.TaxPercent,
                },
            } as IInvoiceItemCharges;
        });
        return { ...lineItem, AllowanceCharges: allowanceCharges };
    });

    return items;
}
