import { useEffect } from 'react';
import './ManageSubscription.scss';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import { CoreTitle } from '@packages';
import { useLocation } from 'react-router-dom';
import { Collapse, CollapseProps, Divider } from 'antd';
import { getTier, singleTierData } from '@store/slices/subscribeSlice';
import { payAsGoTiredData, payAsYouGoId } from '@utils/payAsGoTireData';
import { CheckCircleFilled, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import OrderSummary from '../../components/manage-subscriptions/order-summary/OrderSummary';
import BillingAddressSection from '../../components/manage-subscriptions/billing-address-section/BillingAddressSection';
import PaymentMethodSection from '../../components/manage-subscriptions/payment-method-section/PaymentMethodSection';
import HeaderLayout from '@components/main-layout/header-layout/HeaderLayout';

const CollapseContent = [
    {
        key: '1',
        label: 'So how does the 12-days free trail work?',
        content:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    {
        key: '2',
        label: 'Why do you need my credit card for the free trail?',
        content:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    {
        key: '3',
        label: 'How do I cancel if I&apos;m not super impressed',
        content:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
];

export default function ManageSubscription() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const planId = queryParams.get('id');
    const recurringType = queryParams.get('type');

    const { data: singleTier } = useAppSelector(singleTierData);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getPaymentMethods());
        if (planId !== payAsYouGoId) {
            dispatch(getTier({ TierId: planId || '' }));
        }
    }, []);

    const CollapseItems: CollapseProps['items'] = CollapseContent.map((item) => ({
        key: item.key,
        label: item.label,
        children: <p>{item.content}</p>,
    }));

    return (
        <HeaderLayout>
            <div className='mange-subscription-wrapper'>
                <div className='mange-subscription-column'>
                    <div className='column-one'>
                        <div className='left-collapse-section'>
                            <p className='complete-step-message'>The Last Step</p>
                            <CoreTitle tag='h1' text='Complete payment' />
                            <div className='collapse-wrapper'>
                                <Collapse
                                    items={CollapseItems}
                                    defaultActiveKey={['1']}
                                    bordered={false}
                                    expandIcon={({ isActive }) =>
                                        isActive ? (
                                            <MinusCircleOutlined
                                                style={{ fontSize: '18px', color: '#A4A7AE' }}
                                            />
                                        ) : (
                                            <PlusCircleOutlined
                                                style={{ fontSize: '18px', color: '#A4A7AE' }}
                                            />
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='column-two'>
                        <div className='order-summary-wrapper'>
                            <div className='order-summary round-box-shadow '>
                                <div className='order-header-wrapper'>
                                    <p>Your Plan</p>
                                    <div className='plan-name'>
                                        <p>
                                            {planId === payAsYouGoId
                                                ? payAsGoTiredData?.Name
                                                : singleTier?.Name}
                                        </p>
                                        <CheckCircleFilled className='checked-icon' />
                                    </div>
                                </div>
                                <div className='order-details'>
                                    <OrderSummary
                                        planId={planId || ''}
                                        recurringType={recurringType || ''}
                                        singleTier={singleTier || null}
                                    />

                                    <Divider
                                        style={{
                                            margin: '20px 0',
                                            borderBlockStart: '1px solid #EAECF0',
                                        }}
                                    />

                                    <BillingAddressSection />

                                    <Divider
                                        style={{
                                            margin: '20px 0',
                                            borderBlockStart: '1px solid #EAECF0',
                                        }}
                                    />

                                    <PaymentMethodSection
                                        planId={planId || ''}
                                        recurringType={recurringType || ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HeaderLayout>
    );
}
