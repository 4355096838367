import { DatePicker, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getDashboardDate } from "@utils/date";
import { setFilter } from "@store/slices/dashboardSlice";
import { useAppDispatch } from "@store/hooks";
const { RangePicker } = DatePicker;

const DashboardFilter = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('7d');

    const options = [
        { value: '1d', label: t('TO_DAY') },
        { value: '7d', label: t('LAST_7_DAYS') },
        { value: '30d', label: t('LAST_3_MONTHS') },
        {
            value: 'Custom',
            label: `${t('CUSTOM')}`,
        },
    ];

    const handleFilterChange = (value: string) => {
        setSelectedValue(value)
        if (value === `${t('CUSTOM')}`) {
            setOpen(!open);
        } else {
            const dashboardDate = getDashboardDate(value)
            dispatch(setFilter({ From: dashboardDate.previousDate, To: dashboardDate.currentDate }))
        }
    };


    const disabledDate = (current: any) => {
        const previousNinetyDays = new Date();
        previousNinetyDays.setDate(previousNinetyDays.getDate() - 90);
        return current && (current.valueOf() < previousNinetyDays.valueOf() || current.valueOf() > Date.now());
    };

    const handleDateChange = (dates: any, dateStrings: [string, string]) => {
        dispatch(setFilter({ From: dateStrings[0], To: dateStrings[1] }))
        setOpen(false);
    };

    return (
        <>
            <RangePicker
                open={open}
                disabledDate={disabledDate}
                style={{ visibility: 'hidden', width: 0, position: 'absolute' }}
                onChange={handleDateChange}
                onOpenChange={(open) => setOpen(open)}
            />
            <Select
                className='template-select'
                placeholder={t('SELECT_FILTER')}
                value={selectedValue}
                options={options}
                onChange={handleFilterChange}
                style={{
                    width: "210px"
                }}
            />
        </>
    )
}

export default DashboardFilter;
