const DownloadingIcon = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='20' fill='white' />
            <rect
                x='0.5'
                y='0.5'
                width='39'
                height='39'
                rx='19.5'
                stroke='white'
                strokeOpacity='0.2'
            />
            <path
                d='M21.5 12.7021V15.8001C21.5 16.2201 21.5 16.4301 21.5817 16.5905C21.6537 16.7317 21.7684 16.8464 21.9095 16.9183C22.0699 17.0001 22.28 17.0001 22.7 17.0001H25.7979M17.75 22.25L20 24.5M20 24.5L22.25 22.25M20 24.5L20 20M21.5 12.5H17.6C16.3399 12.5 15.7098 12.5 15.2285 12.7452C14.8052 12.961 14.461 13.3052 14.2452 13.7285C14 14.2098 14 14.8399 14 16.1V23.9C14 25.1601 14 25.7902 14.2452 26.2715C14.461 26.6948 14.8052 27.039 15.2285 27.2548C15.7098 27.5 16.3399 27.5 17.6 27.5H22.4C23.6601 27.5 24.2902 27.5 24.7715 27.2548C25.1948 27.039 25.539 26.6948 25.7548 26.2715C26 25.7902 26 25.1601 26 23.9V17L21.5 12.5Z'
                stroke='url(#paint0_linear_6951_94376)'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_6951_94376'
                    x1='20'
                    y1='12.5'
                    x2='20'
                    y2='27.5'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default DownloadingIcon;
