import React, { useState } from 'react';
import { Button, Col, Modal, Row, Table, Tag, Tooltip } from 'antd';
import { CoreButton, CoreSearchInput, CoreStatus } from '@packages';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import InviteClient from '../../invite-client/InviteClient';
import FilterDropDown from '../../filter-drop-down/FilterDropDown';
import { useAppDispatch } from '@store/hooks';
import { disableEntity, enableEntity, getEntities } from '@store/slices/entitySlice';
import { UtcToLocalDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import './EntityListTable.scss';
import { EntityFilterInputs } from '../../../features/organization';

interface IEntityTableData {
    key: React.Key;
    EntityId: string;
    LegalRegistrationName: string;
    LegalRegistrationNameEn: string;
    CompanyRegistrationNumber: string;
    CreatedAt: string;
    IsActive: boolean;
    ActionButtons: boolean;
    EditButtons: string;
    VatRegistrationNumber: string;
}

const displayValues: any = {
    organizationId: 'Organization ID',
    legalRegistrationName: 'Legal Registration Name',
    vatRegistrationNumber: 'VAT Registration Number',
    organizationLegacyId: 'Organization Legacy ID',
    dateRange: 'Date Range',
    activeStatus: 'Active Status',
    organizationRegistrationScheme: 'Organization Registration Scheme',
};

const EntityListTable = () => {
    const { t } = useTranslation();
    const [entityList, setEntityList] = useState<IEntityTableData[]>([]);
    const { organizationId = '' } = useParams();
    const dispatch = useAppDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate();

    const handleActive = (e: any, EntityId: string, IsActive: boolean) => {
        e.stopPropagation();
        if (!IsActive) {
            dispatch(enableEntity({ EntityId })).finally(() => {
                dispatch(getEntities(organizationId));
            });
        } else {
            dispatch(disableEntity({ EntityId })).finally(() => {
                dispatch(getEntities(organizationId));
            });
        }
    };

    const columns: ColumnsType<IEntityTableData> = [
        {
            title: `${t('ORGANIZATION_ID')}`,
            dataIndex: ['LegalRegistrationName', 'LegalRegistrationNameEn'],
            render: (text, data) => (
                <Tooltip title={data.LegalRegistrationNameEn}>
                    <span>{data.LegalRegistrationName}</span>
                </Tooltip>
            ),
        },
        {
            title: `${t('LEGAL_REGISTRATION_NAME')}`,
            dataIndex: ['LegalRegistrationName', 'LegalRegistrationNameEn'],
            render: (text, data) => (
                <Tooltip title={data.LegalRegistrationNameEn}>
                    <span>{data.LegalRegistrationName}</span>
                </Tooltip>
            ),
        },
        {
            title: `${t('VAT_REGISTRATION_NUMBER')}`,
            dataIndex: 'VatRegistrationNumber',
        },
        {
            title: `${t('COMPANY_REGISTRATION_NUMBER')}`,
            dataIndex: 'CompanyRegistrationNumber',
        },
        {
            title: `${t('CREATED_DATE')}`,
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },

        {
            title: `${t('STATUS')}`,
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? t('ACTIVE') : t('INACTIVE')}
                />
            ),
        },
        {
            title: '',
            dataIndex: 'IsActive',
            render: (IsActive: boolean, data: IEntityTableData) => (
                <div className='action-buttons'>
                    <div className='edit-table-icon' style={{ display: 'flex', gap: 10 }}>
                        {!IsActive && (
                            <Button
                                size='small'
                                type='primary'
                                disabled={data?.IsActive}
                                onClick={(e) => handleActive(e, data?.EntityId || '', IsActive)}
                            >
                                {t('ACTIVE')}
                            </Button>
                        )}

                        {IsActive && (
                            <Button
                                size='small'
                                disabled={!data?.IsActive}
                                onClick={(e) => handleActive(e, data?.EntityId || '', IsActive)}
                            >
                                {t('DEACTIVATE')}
                            </Button>
                        )}
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = (record: IEntityTableData) => {
        navigate(`/organizations/${organizationId}/entity/${record.key}`);
    };

    const [filterList, setFilterList] = useState<any>();

    const handleFilterSubmit = (data: any) => {
        formatData(data);
    };
    const clearAllFilters = () => {
        setFilterList([]);
    };
    const formatData = (data: any) => {
        const keys = Object.keys(data);
        const filters: any[] = [];
        keys.forEach((key) => {
            if (data[key] && displayValues[key]) {
                filters.push({
                    key: displayValues[key],
                    value: key === 'dateRange' ? getFormatterDateRange(data[key]) : data[key],
                });
            }
        });
        setFilterList(filters);
    };
    const getFormatterDateRange = (dates: any) => {
        return (
            new Date(dates['startDate']).toDateString() +
            ' - ' +
            new Date(dates['endDate']).toDateString()
        );
    };
    const clearFilter = (item: any) => {
        const filterIndex = filterList.findIndex((filterID: any) => filterID.key === item.key);
        filterList.splice(filterIndex, 1);
        filterList.length > 0 ? setFilterList(filterList) : setFilterList([]);
    };

    return (
        <div className='round-box-shadow'>
            <div className='space-for-search margin-30'>
                <div className='filter-header'>
                    <CoreSearchInput placeholder='Search' />
                    <FilterDropDown
                        content={<EntityFilterInputs handleFilterSubmit={handleFilterSubmit} />}
                    />
                    <CoreButton
                        className='submit-button'
                        type='primary'
                        text={t('ADD_ENTITY')}
                        isFullWidth={true}
                        onClick={() => navigate(`/entity/create`)}
                    />
                </div>

                {filterList && filterList.length > 0 && (
                    <Row className='filter-wrapper' justify='space-between'>
                        <Col span={20}>
                            {filterList.map((item: any, index: number) => (
                                <Tag
                                    key={index}
                                    className='margin-bottom-10'
                                    closable
                                    onClose={() => clearFilter(item)}
                                >
                                    <span>{item.key}</span>:{' '}
                                    <span className='filter-value'>{item.value}</span>
                                </Tag>
                            ))}
                        </Col>
                        <Col span={4}>
                            <Row justify='end'>
                                <div>
                                    <CoreButton
                                        type='basic'
                                        isFullWidth={false}
                                        text={t('CLEAR_ALL')}
                                        onClick={clearAllFilters}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
            <div className='ksa-table-wrapper'>
                <Table
                    // loading={isLoading}
                    columns={columns}
                    dataSource={entityList}
                    rowClassName='cursor-pointer'
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => handleRowClick(record), // click row
                        };
                    }}
                    pagination={false}
                />
            </div>
            <Modal
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={400}
                footer={null}
                closable={false}
            >
                <InviteClient handleOk={handleOk} handleCancel={handleCancel} />
            </Modal>
        </div>
    );
};

export default EntityListTable;
