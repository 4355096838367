import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { estimationItemsData } from '@utils/estimationItemData';

interface IPaymentCalculatorState {
    invoices?: number;
    organizations?: number;
    users?: number;
    entities?: number;
    devices?: number;
}

export type PaymentCalculatorType = keyof IPaymentCalculatorState;

const initialState: IPaymentCalculatorState = {};

export const PaymentCalculator = createSlice({
    name: 'paymentCalculator',
    initialState,
    reducers: {
        removeCalculatorItem: (state, action: { type: string; payload: PaymentCalculatorType }) => {
            const keyName = action.payload;
            if (keyName in state) {
                delete state[keyName];
            }
        },
        addNewCalculatorItem: (state, action: { type: string; payload: PaymentCalculatorType }) => {
            const keyName = action.payload;
            state[keyName] = 1;
        },
        changeCalculatorItem: (
            state,
            action: { type: string; payload: { name: PaymentCalculatorType; count: number } },
        ) => {
            const { name, count } = action.payload;
            state[name] = count;
        },
    },
});
export const { removeCalculatorItem, addNewCalculatorItem, changeCalculatorItem } =
    PaymentCalculator.actions;

export const paymentCalculator = (state: RootState) => state.paymentCalculator;

export const selectEstimationData = createSelector([paymentCalculator], (calculator) => {
    const calculatorKeys = Object.keys(calculator);
    return estimationItemsData.filter((item) => !calculatorKeys.includes(item.type));
});

export default PaymentCalculator.reducer;
