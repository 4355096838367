export const UserRoles = {
    anonymous: 'anonymous',
    appuser: 'appuser',
    admin: 'admin',
    organizationAdmin: 'organization-admin',
};

export const ORGANIZATION_ID_HASH = 'Hash';

export type UserRolesValues = (typeof UserRoles)[keyof typeof UserRoles];
