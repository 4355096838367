import React, { useEffect } from 'react';
import TemplateMenuWrapper from '../../template-generator/template-menu-wrapper/TemplateMenuWrapper';
import { useForm } from 'react-hook-form';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { useTranslation } from 'react-i18next';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { GET_ALL_INVOICE_TYPES, GET_INVOICE_TYPE_CODE_BY_NAME } from '@utils/InvoiceUtils';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    getAvailableOrganizations,
    selectAvailableOrganizationsInfo,
} from '@store/slices/organizationSlice';
import { getActiveEntities, selectActiveEntityInfo } from '@store/slices/entitySlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
    getActiveDevices,
    getDevices,
    getEntityDevices,
    selectActiveDeviceInfo,
    selectedEntityDeviceInfo,
} from '@store/slices/deviceSlice';
import { IEntity } from '@models/organization.model';
import { CoreButton } from '@packages';
import { PDF_SIZE_OPTIONS } from '@utils/pdfSizeOptions';
import { PdfAPI } from '@service/pdf.service';
import { Col, Row } from 'antd';
import { PdfConfigurationPayload } from '@models/pdf.model';

const TemplateProperties = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const availableOrganizationInfo = useAppSelector(selectAvailableOrganizationsInfo);
    const entitiesInfo = useAppSelector(selectActiveEntityInfo);
    const devicesInfo = useAppSelector(selectedEntityDeviceInfo);
    const activeDevicesInfo = useAppSelector(selectActiveDeviceInfo);

    const getAllFetchRequest = () => {
        dispatch(getAvailableOrganizations());
    };

    useEffect(() => {
        getAllFetchRequest();
    }, []);

    const getAllOrgDependency = (orgId: string) => {
        setFormValue('EntityId', '');
        dispatch(getActiveEntities(orgId))
            .then(unwrapResult)
            .then((result) => {
                if (result.length === 0) {
                    setFormValue('EntityId', '');
                    dispatch(getActiveDevices(orgId));
                } else {
                    const entity = result[0];
                    setFormValue('EntityId', entity?.EntityId || '');
                    getAllEntityDependency(entity?.EntityId, entity);
                }
            });
    };

    const getAllEntityDependency = (entityId: string, entity: IEntity) => {
        dispatch(
            getEntityDevices({
                organizationId: entity?.OrganizationId || '',
                entityId: entityId,
            }),
        ).then(unwrapResult);
    };

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        reset,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
        defaultValues: {
            templateName: '',
            invoiceType: '',
            organizationId: '',
            entityId: '',
            deviceIds: [],
            options: {
                margin: 60,
                size: '',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
        },
    });

    const onSubmit = async (data: PdfConfigurationPayload) => {
        const payload: PdfConfigurationPayload = JSON.parse(JSON.stringify(data));
        payload.options.margin = +data.options.margin;
        payload.options.top = +data.options.top;
        payload.options.bottom = +data.options.bottom;
        payload.options.left = +data.options.left;
        payload.options.right = +data.options.right;
        try {
            const response = await PdfAPI.createPdfConfiguration(payload);
            reset();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <TemplateMenuWrapper>
            <div className='template-form-wrapper'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='template-form-inner-wrapper'>
                        <InlineGenericInput
                            control={control}
                            levelCol={24}
                            inputCol={24}
                            errors={errors}
                            dataKey='templateName'
                            level={t('TEMPLATES_NAME')}
                            type='text'
                            marginBottom={20}
                            errorMessageSize='small'
                            rules={{
                                required: true,
                            }}
                        />

                        <InlineGenericSelect
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='invoiceType'
                            level={t('INVOICE_TYPE')}
                            placeHolder={t('SELECT_INVOICE_TYPE')}
                            options={GET_ALL_INVOICE_TYPES.map((invoice) => ({
                                ...invoice,
                                label: t(invoice.displayKey),
                            }))}
                            setFormValue={setFormValue}
                            marginBottom={5}
                            errorMessageSize='small'
                            rules={{
                                required: true,
                            }}
                        />

                        <InlineGenericSelect
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='organizationId'
                            level={t('ORGANIZATION')}
                            placeHolder={t('SELECT_ORGANIZATION')}
                            options={availableOrganizationInfo.data}
                            onChange={getAllOrgDependency}
                            setFormValue={setFormValue}
                            marginBottom={0}
                            errorMessageSize='small'
                            rules={{
                                required: true,
                            }}
                        />

                        <InlineGenericSelect
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='entityId'
                            level={t('ENTITY')}
                            placeHolder={t('SELECT_ENTITY')}
                            options={entitiesInfo.data}
                            setFormValue={setFormValue}
                            onChange={getAllEntityDependency}
                            marginBottom={0}
                            errorMessageSize='small'
                        />

                        <InlineGenericSelect
                            isMultipleSelect={true}
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='deviceIds'
                            level={t('DEVICE')}
                            placeHolder={t('SELECT_DEVICE')}
                            options={
                                entitiesInfo.data.length === 0
                                    ? activeDevicesInfo.data
                                    : devicesInfo.data
                            }
                            setFormValue={setFormValue}
                            rules={{
                                required: true,
                            }}
                        />

                        <InlineGenericSelect
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='options.size'
                            level={t('PDF_SIZE')}
                            placeHolder={t('SELECT_PDF_SIZE')}
                            options={PDF_SIZE_OPTIONS}
                            setFormValue={setFormValue}
                            marginBottom={5}
                            errorMessageSize='small'
                            sorting={false}
                            rules={{
                                required: true,
                            }}
                        />
                        <InlineGenericInput
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            type={'number'}
                            dataKey='options.margin'
                            level={'Global Margin'}
                            marginBottom={20}
                            errorMessageSize='small'
                            rules={{
                                required: true,
                            }}
                        />
                        <Row gutter={6}>
                            <Col span={6}>
                                <InlineGenericInput
                                    levelCol={24}
                                    inputCol={24}
                                    control={control}
                                    errors={errors}
                                    type={'number'}
                                    dataKey='options.top'
                                    level={'Margin Top'}
                                    marginBottom={20}
                                    errorMessageSize='small'
                                />
                            </Col>
                            <Col span={6}>
                                <InlineGenericInput
                                    levelCol={24}
                                    inputCol={24}
                                    control={control}
                                    errors={errors}
                                    type={'number'}
                                    dataKey='options.bottom'
                                    level={'Margin Bottom'}
                                    marginBottom={20}
                                    errorMessageSize='small'
                                />
                            </Col>
                            <Col span={6}>
                                <InlineGenericInput
                                    levelCol={24}
                                    inputCol={24}
                                    control={control}
                                    errors={errors}
                                    type={'number'}
                                    dataKey='options.left'
                                    level={'Margin Left'}
                                    marginBottom={20}
                                    errorMessageSize='small'
                                />
                            </Col>
                            <Col span={6}>
                                <InlineGenericInput
                                    levelCol={24}
                                    inputCol={24}
                                    control={control}
                                    errors={errors}
                                    type={'number'}
                                    dataKey='options.right'
                                    level={'Margin Right'}
                                    marginBottom={20}
                                    errorMessageSize='small'
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className='save-button'>
                        <CoreButton htmlType='submit' type='primary' text={t('SAVE')} />
                    </div>
                </form>
            </div>
        </TemplateMenuWrapper>
    );
};
export default TemplateProperties;
