import _ from 'lodash';

export const flattenData = (collection: any[], flattened?: any[]) => {
    if (!flattened) {
        flattened = [];
    }
    _.each(collection, (obj) => {
        flattened!.push(obj);
        flattenData(obj.children, flattened);
    });
    return flattened;
};
