import React, { MouseEventHandler } from 'react';
import { Button, ButtonProps } from "antd";
import './CoreButton.scss';
import { NavLink } from "react-router-dom";

interface ICoreButtonProps extends Pick<ButtonProps, 'loading' | 'disabled' | 'htmlType' | 'icon' | 'onClick' | 'size' | 'className'>  {
    text?: string;
    type?: 'primary' | 'secondary' | 'success' | 'danger' | 'basic' | 'primaryHover',
    antType?: "primary" | "text" | "link" | "default" | "dashed" | undefined,
    isFullWidth?: boolean,
    linkTo?: string;
}

const CoreButton = (props: ICoreButtonProps) => {
    const {
        type = "",
        antType,
        text ,
        isFullWidth = false,
        linkTo= undefined,
        htmlType,
        className,
        ...others
    } = props

    return (
        <>
            {linkTo ? (
                <NavLink to={linkTo}>

                    {({ isActive }) => (
                        isActive ?
                            <Button
                                className={`core-button primary ${className || ''} ${isFullWidth ? 'full-width' : ''}`}
                                {...others}
                            >{text}</Button>
                            :
                            <Button
                                type={antType}
                                className={`core-button ${className || ''} ${type} ${isFullWidth ? 'full-width' : ''}`}
                                htmlType={htmlType}
                                {...others}
                            >{text}</Button>
                    )
                    }
                </NavLink>
            ) : (
                <Button
                    type={antType}
                    className={`core-button ${className || ''} ${type} ${isFullWidth ? 'full-width' : ''}`}
                    {...others}
                    htmlType={htmlType}

                >{text}</Button>
            )}
        </>
    );
};

export default CoreButton;