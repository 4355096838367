import _ from 'lodash';
import { IMapField } from './../../constant';
import { InvoiceHeader } from '@models/upload-csv';
import { Table } from 'antd';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import './UploadDetailsTable.scss';

export interface IUploadDetailsTable {
    apiHeader: InvoiceHeader[];
    localHeader: IMapField[];
}

const columns = [
    {
        title: 'Invoice Name',
        dataIndex: 'Label',
        render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
    },
    {
        title: 'Invoice Header',
        dataIndex: 'InvoiceHeader',
        render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
    },
    {
        title: 'File Header',
        dataIndex: 'FileHeader',
        render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
    },
];
export default function UploadDetailsTable({ apiHeader, localHeader }: IUploadDetailsTable) {
    const data = mergedData(apiHeader, localHeader);
    return (
        <div className='ksa-table-wrapper upload-table-auto-scroll-with-header '>
            <Table
                columns={columns}
                dataSource={data}
                rowClassName='cursor-pointer'
                rowKey={'InvoiceHeader'}
                scroll={{ x: 600 }}
                pagination={false}
            />
        </div>
    );
}

export function mergedData(data1: InvoiceHeader[], data2: IMapField[]) {
    return _.map(data1, (item1) => {
        const matchingItem = _.find(data2, { key: item1.InvoiceHeader });
        return {
            ...item1,
            Label: matchingItem ? matchingItem.label : null,
        };
    });
}
