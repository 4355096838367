import { PlusCircleFilled, RightOutlined } from '@ant-design/icons';
import { CoreButton, CoreTitle } from '@packages';
import React from 'react';
import './EnterpriseCard.scss';

const EnterpriseCard = () => {
    return (
        <div className='enterprise-card-wrapper round-box-shadow'>
            <div className='enterprise-card-header'>
                <CoreTitle text='Enterprise' tag='h5' />
                <p className='sub-title'>For large companies</p>
            </div>

            <div className='enterprise-card-header'>
                <CoreTitle text='Individual pricing' tag='h5' />
                <p className='sub-title'>Individual deals for every scale</p>
            </div>

            <div className='enterprise-card-icon-section'>
                <div className='icon-section-block'>
                    <div className='icon-section-single-block'>
                        <PlusCircleFilled style={{ color: 'white', fontSize: '24px' }} />
                        <p>Seats</p>
                    </div>
                    <div className='icon-section-single-block'>
                        <PlusCircleFilled style={{ color: 'white', fontSize: '24px' }} />
                        <p>Services</p>
                    </div>
                </div>
                <div className='icon-section-block'>
                    <div className='icon-section-single-block'>
                        <PlusCircleFilled style={{ color: 'white', fontSize: '24px' }} />
                        <p> Volume Discounts</p>
                    </div>
                </div>
                <div className='icon-section-block'>
                    <div className='icon-section-single-block'>
                        <PlusCircleFilled style={{ color: 'white', fontSize: '24px' }} />
                        <p> SLA & SSO</p>
                    </div>
                </div>
                <div className='icon-section-block'>
                    <div className='icon-section-single-block'>
                        <PlusCircleFilled style={{ color: 'white', fontSize: '24px' }} />
                        <p> Custom Agreements</p>
                    </div>
                </div>
                <div className='icon-section-block'>
                    <div className='icon-section-single-block'>
                        <PlusCircleFilled style={{ color: 'white', fontSize: '24px' }} />
                        <p> White-Glove Onboarding</p>
                    </div>
                </div>
            </div>

            <div>
                <CoreButton
                    antType='text'
                    text='Contact Us'
                    size='small'
                    icon={<RightOutlined />}
                    className='contact-us-button'
                />
            </div>
        </div>
    );
};

export default EnterpriseCard;
