/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import './ProcessingSummaryCard.scss';
import { CoreTitle } from '@packages';
import { Button, Popover, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { IProcessingSummary } from '@models/dashboard.model';
import { isLeafFoundInNodes, propagateTotalCounts } from '@utils/calculateInvoiceSummary';
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

interface IProcessingSummaryCardProps {
    summary: IProcessingSummary[];
    title: string;
    total: number;
    rightPosition?: number;
}

export const DefaultLeftPosition = 340;

const ProcessingSummaryCard: React.FC<IProcessingSummaryCardProps> = ({
                                                                          summary,
                                                                          title,
                                                                          total,
                                                                          rightPosition = 20,
                                                                      }) => {
    const [selectedData, setSelectedData] = useState<IProcessingSummary[]>([]);
    const [selectedTitle, setSelectedTitle] = useState<string>('');
    const [isResetChild, setResetChild] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [clicked, setClicked] = useState(false);

    const handleClickChange = (open: boolean) => {
        setClicked(open);
    };
    const { t } = useTranslation();

    useEffect(() => {
        setIsLoading(true);

        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [])

    const popoverContent = (
        <div className='popover-wrapper'>
            {summary?.map((data, index) => (
                <>
                    {data?.leaf && (
                        <p
                            key={data?.id + index}
                            className='popover-content'
                            onClick={() => {
                                setClicked(false);
                                setSelectedData(data.leaf || []);
                                setSelectedTitle(data?.name);
                            }}
                        >
                            {data?.name || 'Not Found'}
                        </p>
                    )}
                </>
            ))}
        </div>
    );

    useEffect(() => {
        setResetChild(true);
        setTimeout(() => setResetChild(false), 100);
    }, [selectedData]);

    if (!summary || summary.length == 0) return <></>;

    const isFoundNodes = isLeafFoundInNodes(summary);
    
    

    return (
        <div className='processing-summary-card-wrapper'>
            <div className='processing-summary-card' style={{ left: rightPosition, top: 150 }}>
                {isLoading ? (
                    <div className="processing-loader">
                        <Spin size="small" spinning={true} />
                    </div>
                ) : (
                    <>
                        <CoreTitle tag="h6" text={title} className="processing-summary-title" />
                        <p className="total-count">{total} {t("INVOICES")}</p>
                        {isFoundNodes && (
                            <div className='processing-summary-actions'>
                                <div className="popover-circle" />
                                <div className="popover-divider" />
                                <Popover
                                    placement="right"
                                    content={popoverContent}
                                    className="processing-summary-popover"
                                    trigger="click"
                                    open={clicked}
                                    onOpenChange={handleClickChange}
                                >
                                    <Button
                                        size='small'
                                        type="primary"
                                        ghost
                                        icon={<PlusOutlined />}
                                    />
                                </Popover>
                            </div>
                        )}
                    </>
                )}
            </div>
            <>
                {!isResetChild ? (
                    <ProcessingSummaryCard
                        summary={selectedData}
                        title={selectedTitle}
                        total={propagateTotalCounts(selectedData)}
                        rightPosition={DefaultLeftPosition + rightPosition}
                    />
                ) : (
                    <></>
                )}
            </>
        </div>
    );
};

export default ProcessingSummaryCard;
