import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const ActivateSvg = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5652_4673)'>
            <path
                d='M18.3333 9.23818V10.0049C18.3323 11.8019 17.7504 13.5504 16.6744 14.9897C15.5984 16.429 14.086 17.4819 12.3628 17.9914C10.6395 18.501 8.79768 18.4398 7.11202 17.817C5.42636 17.1942 3.98717 16.0433 3.00909 14.5357C2.03101 13.0282 1.56645 11.2449 1.68469 9.45178C1.80293 7.65866 2.49763 5.95179 3.66519 4.58575C4.83275 3.2197 6.41061 2.26767 8.16345 1.87164C9.91629 1.47561 11.7502 1.6568 13.3916 2.38818M18.3333 3.33341L9.99996 11.6751L7.49996 9.17508'
                stroke='#667085'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_5652_4673'>
                <rect width='20' height='20' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
const ActivateIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={ActivateSvg} {...props} />
);

export default ActivateIcon;
