import './Subscribe.scss';
import { Tabs } from 'antd';
import { CoreTitle } from '@packages';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import { useTranslation } from 'react-i18next';
import OverviewWrapper from '../../components/overview/overview-wrapper/OverviewWrapper';
import Invoice from '../../components/invoice/Invoice';
import BillingTab from '../../components/billing/billing-tab/BillingTab';
import PaymentDetails from '../../components/payment-details/PaymentDetails';

type tabsType = 'subscription' | 'payment' | 'overview' | 'invoice' | 'billing';

export default function Subscribe() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { state }: { state?: { currentTab: tabsType } } = useLocation();
    const [activeTabsKey, setActiveTabsKey] = useState<tabsType>(state?.currentTab || 'overview');

    const tabItems = [
        {
            label: 'Overview',
            key: 'overview',
            children: <OverviewWrapper />,
        },
        {
            label: 'Invoice',
            key: 'invoice',
            children: <Invoice />,
        },
        {
            label: 'Payment Details',
            key: 'payment',
            children: <PaymentDetails />,
        },
        {
            label: 'Billing',
            key: 'billing',
            children: <BillingTab />,
        },
    ];

    useEffect(() => {
        dispatch(getPaymentMethods());
    }, []);

    return (
        <div className='subscribe-wrapper'>
            <CoreTitle tag='h2' text={t('YOUR_SUBSCRPTION')} />
            <p className='subscribe-sub-title'>
                Keep your account more secure with following preferences.
            </p>
            <div className='subscribe-inner-wrapper'>
                <Tabs
                    activeKey={activeTabsKey}
                    onChange={(value) => {
                        setActiveTabsKey(value as tabsType);
                    }}
                    items={tabItems}
                />
            </div>
        </div>
    );
}
