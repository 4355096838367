import { FC, ReactNode } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { useGetMenuSelectedKey } from '@hooks';
import {
    FooterIcon,
    HeaderIcon,
    ItemTableIcon,
    PropertiesIcon,
    TotalIcon,
} from '@components/icons';
import './TemplateMenuWrapper.scss';
import { MemoizeTemplatePreview } from '../template-preview/TemplatePreview';

interface IProps {
    children: ReactNode;
}

export const TemplateMenuWrapper: FC<IProps> = ({ children }) => {
    type MenuItem = Required<MenuProps>['items'][number];

    const selectedKey = useGetMenuSelectedKey();

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const items: MenuItem[] = [
        getItem(
            <Link to='/template-properties'>
                Template <br /> Properties
            </Link>,
            'template-properties',
            <PropertiesIcon />,
        ),
        getItem(<Link to='/template-header'>Header</Link>, 'template-header', <HeaderIcon />),
        getItem(
            <Link to='/template-item-table'>Item Table</Link>,
            'template-item-table',
            <ItemTableIcon />,
        ),
        getItem(<Link to='/template-total'>Total</Link>, 'template-total', <TotalIcon />),
        getItem(<Link to='/template-footer'>Footer</Link>, 'template-footer', <FooterIcon />),
    ];

    return (
        <div className='template-menu-wrapper'>
            <Menu
                mode='inline'
                defaultSelectedKeys={['']}
                defaultOpenKeys={[]}
                style={{ height: '100%' }}
                items={items}
                selectedKeys={selectedKey}
            />
            {children}
            <MemoizeTemplatePreview />
        </div>
    );
};

export default TemplateMenuWrapper;
