import './BillingHome.scss';
import BillingHeader from '../../components/billing-header/BillingHeader';
import EstimatedCard from '../../components/estimated-card/EstimatedCard';
import DateSummary from '../../components/date-summary/DateSummary';
import { Tabs } from 'antd';
import BillingHistory from '../../components/billing-history/BillingHistory';
import BillingAddress from '../../components/address/Address';
import BillingAlert from '../../components/billing-alert/BillingAlert';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetBillingQuery } from '@utils/getBillingQuery';
import SubscriptionList from '../../components/subscription/subscription-list/SubscriptionList';
import TierList from '../../components/tier/TierList';

type billingTabsType =
    | 'billing-information'
    | 'billing-history'
    | 'billing-settings'
    | 'subscription';

const BillingHome = () => {
    const [activeTabsKey, setActiveTabsKey] = useState<billingTabsType>('billing-information');
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = GetBillingQuery(location?.search);

    useEffect(() => {
        setActiveTabsKey(queryParams as billingTabsType);
    }, [location?.search]);

    return (
        <div className='billing-container'>
            <Tabs
                activeKey={activeTabsKey.toString()}
                onChange={(value) => {
                    setActiveTabsKey(value as billingTabsType);
                    navigate(`/billing?${value}`);
                }}
                defaultActiveKey='billing-information'
                items={[
                    {
                        label: 'Billing Information',
                        key: 'billing-information',
                        children: (
                            <>
                                <BillingHeader />
                                <EstimatedCard />
                                <DateSummary />
                            </>
                        ),
                    },
                    {
                        label: 'Billing History',
                        key: 'billing-history',
                        children: <BillingHistory />,
                    },
                    {
                        label: 'Billing Settings',
                        key: 'billing-settings',
                        children: (
                            <>
                                <BillingAddress />
                                <BillingAlert />
                            </>
                        ),
                    },
                    {
                        label: 'Subscription',
                        key: 'subscription',
                        children: <SubscriptionList />,
                    },
                    {
                        label: 'Tier',
                        key: 'tier',
                        children: <TierList />,
                    },
                ]}
            />
        </div>
    );
};
export default BillingHome;
