import './Footer.scss';
import { Row, Col, Input, Checkbox, Divider, Image, Grid } from 'antd';
import { CoreButton } from '@packages';
import FooterLogo from '../../svg/FooterLogo';
import {
    FacebookOutlined,
    InstagramOutlined,
    LinkedinOutlined,
    TwitterOutlined,
} from '@ant-design/icons';

const { useBreakpoint } = Grid;

const Footer = () => {
    const screens = useBreakpoint();
    const buttonSize = screens.lg ? 'large' : 'middle';

    return (
        <div className='footer-wrapper'>
            <Row gutter={[32, 8]} justify='center'>
                <Col xs={24} sm={12} lg={8}>
                    <div className='footer-logos'>
                        <div className='footer-logo-wrapper'>
                            <FooterLogo />
                        </div>
                        <p className='footer-description'>
                            Generate tax compliant invoices and credit-notes that include all
                            required information globally.
                        </p>
                    </div>
                </Col>

                <Col xs={12} sm={12} lg={4}>
                    <h4 className='footer-headers'>Products</h4>
                    <ul className='footer-links'>
                        <li>
                            <a href='#'>Lookup</a>
                        </li>
                        <li>
                            <a href='#'>Tax</a>
                        </li>
                        <li>
                            <a href='#'>Invoicing</a>
                        </li>
                        <li>
                            <a href='#'>Reporting</a>
                        </li>
                        <li>
                            <a href='#'>Returns</a>
                        </li>
                        <li>
                            <a href='#'>Data sharing</a>
                        </li>
                    </ul>
                </Col>

                <Col xs={12} sm={8} lg={4}>
                    <h4 className='footer-headers'>Industries</h4>
                    <ul className='footer-links'>
                        <li>
                            <a href='#'>Marketplace</a>
                        </li>
                        <li>
                            <a href='#'>Software</a>
                        </li>
                        <li>
                            <a href='#'>Digital content</a>
                        </li>
                        <li>
                            <a href='#'>Travel</a>
                        </li>
                    </ul>
                </Col>

                <Col xs={12} sm={8} lg={4}>
                    <h4 className='footer-headers'>Resources</h4>
                    <ul className='footer-links'>
                        <li>
                            <a href='#'>Blog</a>
                        </li>
                        <li>
                            <a href='#'>Resources center</a>
                        </li>
                        <li>
                            <a href='#'>Customer Stories</a>
                        </li>
                        <li>
                            <a href='#'>Coverage</a>
                        </li>
                        <li>
                            <a href='#'>Webinars</a>
                        </li>
                        <li>
                            <a href='#'>API docs</a>
                        </li>
                    </ul>
                </Col>
                <Col xs={12} sm={8} lg={4}>
                    <h4 className='footer-headers'>Company</h4>
                    <ul className='footer-links'>
                        <li>
                            <a href='#'>Our team</a>
                        </li>
                        <li>
                            <a href='#'>Careers</a>
                        </li>
                        <li>
                            <a href='#'>Help center</a>
                        </li>
                        <li>
                            <a href='#'>Customer support</a>
                        </li>
                    </ul>
                </Col>
            </Row>

            <Row gutter={[32, 16]} align='middle' className='margin-top-16' justify='center'>
                <Col xs={{ span: 24, order: 2 }} sm={{ span: 8, order: 1 }}>
                    <div className='social-icons'>
                        <a
                            href='https://www.facebook.com'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FacebookOutlined className='single-icon' />
                        </a>
                        <a
                            href='https://www.instagram.com'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <InstagramOutlined className='single-icon' />
                        </a>
                        <a href='https://www.twitter.com' target='_blank' rel='noopener noreferrer'>
                            <TwitterOutlined className='single-icon' />
                        </a>
                        <a
                            href='https://www.linkedin.com'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <LinkedinOutlined className='single-icon' />
                        </a>
                    </div>
                </Col>

                <Col xs={{ span: 24, order: 1 }} sm={{ span: 16, order: 1 }}>
                    <div className='newsletter-section'>
                        <div className='newsletter-form'>
                            <Input
                                placeholder='Enter your business email address'
                                className='input-filed'
                            />
                            <CoreButton
                                text='Subscribe to Newsletter'
                                type='primary'
                                size={buttonSize}
                            />
                        </div>
                        <Checkbox className='newsletter-checkbox'>
                            I agree to E-invoice Solutions
                            <span className='checkbox-span'> Privacy Notice</span>
                        </Checkbox>
                    </div>
                </Col>
            </Row>

            <Divider className='footer-divider' />

            <div className='footer-bottom'>
                <div>
                    <p>© 2023 E-Invoice Solutions. All Rights Reserved</p>
                </div>
                <div>
                    <a href='#'>Terms of services</a> <a href='#'>Privacy Notice</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
