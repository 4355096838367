import { useState } from 'react';
import { CoreTitle } from '@packages';
import './TwoFactorAuthenticationStepper.scss';
import { Col, Row, Steps } from 'antd';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@store/hooks';
import { isRequest2FASetup, selectInitialSetup2FA } from '@store/slices/setup-2FASlice';
import { DownloadRecoveryCodes, SecurityKey, SetupAuthenticatorApp } from "../../index";
import { useTranslation } from 'react-i18next';

const TwoFactorAuthenticationStepper = () => {
    const { t } = useTranslation();
    const isRequest2FA = useAppSelector(isRequest2FASetup);
    const { data } = useAppSelector(selectInitialSetup2FA);

    const [current, setCurrent] = useState(0);

    const onStepChange = (value: number) => {
        setCurrent(value);
    };

    const next = () => {
        setCurrent(current + 1);
    };

    if (!isRequest2FA) {
        return <Navigate to='/setting' />;
    }
    const steps = [
        {
            title: t('SETUP_AUNTHENTICATOR_APP'),
            description: t('SETUP_WITH_AUNTHENTICATOR_APP'),
        },
        {
            title: t('DOWNLOAD_RECOVERY_CODES'),
            description: t('DOWNLOAD_RECOVERY_CODES'),
        },
        {
            title: t('SECURITY_KEY'),
            description: t('GET_SECURITY_KEY'),
        },
    ];
    return (
        <div className='two-factor-auth-wrapper'>
            <div className='two-factor-auth-stepper-header'>
                <CoreTitle tag='h2' text={t('ENABLE_TWO_FACTOR_aUTHENTICATION')} />
                <p className='two-factor-auth-stepper-sub-title'>
                    {t('KEEP_YOUR_ACCOUNT_MORE_SECURE_WITH_FOLLOWING_PREFERENCES')}
                </p>
            </div>
            <div className='two-factor-auth-step-area'>
                <Row>
                    <Col span={8}>
                        <Steps
                            className='left-steps'
                            current={current}
                            onChange={(stepNumber) =>
                                current > stepNumber && onStepChange(stepNumber)
                            }
                            direction='vertical'
                            size={'small'}
                            items={steps}
                        />
                    </Col>
                    <Col span={16}>
                        <div className='two-factor-auth-stepper-content'>
                            {current === 0 && (
                                <SetupAuthenticatorApp
                                    qrCodeUrl={data?.QrCodeImageData}
                                    next={next}
                                />
                            )}
                            {current === 1 && <DownloadRecoveryCodes next={next} />}
                            {current === 2 && <SecurityKey />}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TwoFactorAuthenticationStepper;
