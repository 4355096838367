/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MoreOutlined } from '@ant-design/icons';
import { ConfirmationModal } from '@components';
import { CloneIcon, EditIcon } from '@components/icons';
import ActivateIcon from '@components/icons/ActivateIcon';
import DeactivateIcon from '@components/icons/DeactivateIcon';
import UnArchiveIcon from '@components/icons/UnArchiveIcon';
import { INVENTORY_API } from '@service/inventory.service';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    FetchInventories,
    onUpdateSelectedInventory,
    selectSelectedInventory,
} from '@store/slices/InventoriesSlice';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './InventoryDetails.scss';
import { useTranslation } from 'react-i18next';
import { IInventory } from '@models/inventory.model';

const DropDownEdit: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const inventory = useAppSelector(selectSelectedInventory);
    const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
    const [isUnArchiveModalOpen, setUnArchiveModalOpen] = useState(false);
    const [isActiveModalOpen, setActiveModalOpen] = useState(false);
    const [isDeactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [isCloneModalOpen, setCloneModalOpen] = useState(false);

    const [isButtonLoading, setButtonLoading] = useState(false);
    const dispatch = useAppDispatch();

    const modifyData = (data: any) => {
        const modifiedData = {
            ItemId: data.ItemId,
            CreatedAt: data.CreatedAt,
            UpdatedAt: data.UpdatedAt,
            IsActive: data.IsActive,
            IsArchived: data.IsArchived,
            InventoryType: data.InventoryType,
            Name: data.Name,
            NameEn: data.NameEn,
            UnitCode: data.UnitCode,
            Sale: {
                Currency: data.SaleCurrency,
                Description: data.SaleDescription,
                Price: data.SalePrice,
            },
            Purchase: {
                Currency: data.PurchaseCurrency,
                Description: data.PurchaseDescription,
                Price: data.PurchasePrice,
            },
            BaseQuantity: data.BaseQuantity,
        };

        return modifiedData;
    };

    const createNewInventory = async () => {
        if (!inventory) return;
        setButtonLoading(true);

        const modifyInventory = modifyData(inventory);
        console.log(modifyInventory, 'modifyInventory');

        const response = await INVENTORY_API.createInventory(modifyInventory as IInventory);
        setButtonLoading(false);
        if (response === true) {
            dispatch(FetchInventories({ PageLimit: 10, PageNumber: 1 }));
            setCloneModalOpen(false);
            message.success('Item Clone Successfully');
        } else {
            message.error('Item Clone Failed');
        }
    };
    const onInventoryActive = async () => {
        if (!inventory) {
            return;
        }
        setButtonLoading(true);
        const response = await INVENTORY_API.activeInventory(inventory?.ItemId || '');
        setButtonLoading(false);

        if (response) {
            dispatch(onUpdateSelectedInventory({ ...inventory, IsActive: true }));
            setActiveModalOpen(false);
            message.success('Item activated successfully');
        } else {
            message.error('Item activated failed');
        }
    };
    const onInventoryDeactivate = async () => {
        if (!inventory) {
            return;
        }
        setButtonLoading(true);
        const response = await INVENTORY_API.deActiveInventory(inventory?.ItemId || '');
        setButtonLoading(false);
        if (response) {
            dispatch(onUpdateSelectedInventory({ ...inventory, IsActive: false }));

            message.success('Item deactivated successfully');
            setDeactivateModalOpen(false);
        } else {
            message.error('Item deactivated failed');
        }
    };
    const onInventoryArchive = async () => {
        if (!inventory) {
            return;
        }
        setButtonLoading(true);
        const response = await INVENTORY_API.archiveInventory(inventory?.ItemId || '');
        setButtonLoading(false);
        if (response) {
            dispatch(
                onUpdateSelectedInventory({
                    ...inventory,
                    IsArchived: true,
                    IsActive: false,
                    // Status: 'Archive',
                }),
            );
            message.success(`Item Archive Successfully`);
            setArchiveModalOpen(false);
        } else {
            message.error('Item Archive Failed');
        }
    };
    const onInventoryUnArchive = async () => {
        if (!inventory) {
            return;
        }
        setButtonLoading(true);
        const response = await INVENTORY_API.unarchiveInventory(inventory?.ItemId || '');
        setButtonLoading(false);
        if (response) {
            dispatch(
                onUpdateSelectedInventory({
                    ...inventory,
                    IsActive: false,
                    IsArchived: false,
                    // Status: 'Inactive',
                }),
            );
            message.success('Item Unarchive Successfully');
            setUnArchiveModalOpen(false);
        } else {
            message.error('Item Unarchive Failed');
        }
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='customer-dropdown-item'
                    onClick={() => {
                        navigate(`/items/update/${inventory?.ItemId}`);
                    }}
                >
                    <EditIcon />
                    <p>{t('EDIT')}</p>
                </div>
            ),
        },
        {
            key: '12',
            label: (
                <div
                    className='inventory-details-morevert-options'
                    onClick={() => setCloneModalOpen(true)}
                    aria-hidden='true'
                >
                    <CloneIcon />
                    <p>{t('CLONE_ITEM')}</p>
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    className='customer-dropdown-item'
                    onClick={() =>
                        inventory?.IsArchived === true
                            ? setUnArchiveModalOpen(true)
                            : setArchiveModalOpen(true)
                    }
                >
                    {inventory?.IsArchived === true ? (
                        <>
                            <UnArchiveIcon />
                            <p>{t('UNARCHIVE')}</p>
                        </>
                    ) : (
                        <>
                            <UnArchiveIcon />
                            <p>{t('ARCHIVE')}</p>
                        </>
                    )}
                </div>
            ),
        },
        (inventory?.IsArchived === false && {
            key: '3',
            label: (
                <div
                    className='customer-dropdown-item'
                    onClick={() => {
                        inventory?.IsActive === true
                            ? setDeactivateModalOpen(true)
                            : setActiveModalOpen(true);
                    }}
                >
                    {inventory?.IsActive === true ? (
                        <>
                            <DeactivateIcon />
                            <p>{t('DEACTIVATE')}</p>
                        </>
                    ) : (
                        <>
                            <ActivateIcon />
                            <p>{t('ACTIVATE')}</p>
                        </>
                    )}
                </div>
            ),
        }) ||
        (undefined as any),
    ];
    return (
        <>
            <Dropdown
                menu={{ items }}
                trigger={['click']}
                placement='bottomLeft'
                arrow
                overlayClassName='customer-details-dropdown'
            >
                <Button className='ks-only-icon-button with-border' icon={<MoreOutlined />} />
            </Dropdown>
            <ConfirmationModal
                title={t('MOVE_TO_ARCHIVE')}
                description='Do you want to make this item archive?'
                buttonType='Archive'
                isDeleteModalOpen={isArchiveModalOpen}
                handleDeleteOk={() => onInventoryArchive()}
                handleDeleteCancel={() => setArchiveModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title={t('MOVE_TO_UNARCHIVE')}
                description='Do you want to make this item unarchive?'
                buttonType='Unarchive'
                isDeleteModalOpen={isUnArchiveModalOpen}
                handleDeleteOk={() => onInventoryUnArchive()}
                handleDeleteCancel={() => setUnArchiveModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title='Deactivate Item'
                description='Would you like to deactivate this item?'
                buttonType='Inactivate'
                buttonText='Deactivate'
                isDeleteModalOpen={isDeactivateModalOpen}
                handleDeleteOk={() => onInventoryDeactivate()}
                handleDeleteCancel={() => setDeactivateModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title='Activate Item'
                description='Would you like to activate this item?'
                buttonType='Active'
                buttonText='Activate'
                isDeleteModalOpen={isActiveModalOpen}
                handleDeleteOk={() => onInventoryActive()}
                handleDeleteCancel={() => setActiveModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title='Clone Item'
                description='Do you want to clone this item?'
                buttonType='Clone'
                isDeleteModalOpen={isCloneModalOpen}
                handleDeleteOk={() => createNewInventory()}
                handleDeleteCancel={() => setCloneModalOpen(false)}
                isLoading={isButtonLoading}
            />
        </>
    );
};

export default DropDownEdit;
