import { useEffect, useState } from 'react';
import { IAddDevicePayload, ILogoData, IUpdateDevicePayload } from '@models/organization.model';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useForm } from 'react-hook-form';
import { Col, message, Row } from 'antd';
import { CoreBackButton, CoreButton, CoreTitle } from '@packages';
import { getDevice, selectDeviceInfo } from '@store/slices/deviceSlice';
import { DeviceAPI } from '@service/device.service';
import { useTranslation } from 'react-i18next';
import GenericCheckbox from '@components/common/GenericCheckbox';
import { CoreFileLogo } from '@packages';
import { ILogoInfo } from 'packages/CoreFileCropper/CoreFileLogo';
import { getSubscription } from '@store/slices/subscribeSlice';

const DeviceCreate = () => {
    const { t } = useTranslation();
    const [isLoadingPage, setLoadingPage] = useState<boolean>(false);
    const [logoInfo, setLogoInfo] = useState<ILogoInfo>({} as ILogoInfo);
    const { data: device } = useAppSelector(selectDeviceInfo);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { organizationId = '', deviceId = '' } = useParams();

    useEffect(() => {
        if (deviceId) {
            dispatch(getDevice(deviceId));
        }
    }, [deviceId]);

    const { control, handleSubmit, reset } = useForm<IAddDevicePayload>({
        mode: 'onTouched',
    });

    useEffect(() => {
        if (device) {
            reset({
                Logo: {
                    ShowOnPdf: device.Logo?.ShowOnPdf || false,
                } as ILogoData,
            });
            setLogoInfo({
                url: device.Logo?.Data || '',
                height: device.Logo?.Height || 0,
                width: device.Logo?.Width || 0,
            });
        }
    }, [device]);

    const goBack = () => {
        navigate(`/organizations/${organizationId}`, {
            state: {
                currentTab: 3,
                delay: true,
            },
        });
    };

    const onSubmit = async (data: IAddDevicePayload) => {
        const { url, height, width } = logoInfo || {};
        const Logo: ILogoData = {
            ShowOnPdf: data?.Logo?.ShowOnPdf ?? false,
            Data: url,
            Height: height,
            Width: width,
        };
        const payload: IUpdateDevicePayload = { DeviceId: deviceId, Logo: Logo };
        !url && delete payload.Logo;
        try {
            setLoadingPage(true);
            await DeviceAPI.updateDevice(payload);
            message.success(t('LOGO_SUCCESSFULLY_UPDATED'));
            await dispatch(getSubscription());
            goBack();
            setLoadingPage(false);
        } catch {
            message.error(t('LOGO_UPDATE_FAILED'));
            setLoadingPage(false);
        }
    };

    return (
        <div className='add-device'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='device-title-area'>
                    <CoreBackButton text={''} onClick={goBack} />
                    <CoreTitle tag='h1' text={t('UPDATE_LOGO')} />
                </div>
                <div className='add-device-form-wrapper'>
                    <Row>
                        <Col className='checkbox-col' span={12}>
                            <GenericCheckbox
                                control={control}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Logo.ShowOnPdf'
                                level={t('Show on PDF')}
                            />
                        </Col>
                        <Col span={24}>
                            <div className='logo-title'>Logo Information</div>
                            <CoreFileLogo
                                url={logoInfo.url}
                                maxHeight={200}
                                maxWidth={200}
                                maxSize={800}
                                onFileUpload={setLogoInfo}
                            />
                        </Col>
                    </Row>

                    <div className='add-device-buttons'>
                        <CoreButton
                            loading={isLoadingPage}
                            text={t('UPDATE')}
                            type='primary'
                            htmlType={'submit'}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DeviceCreate;
