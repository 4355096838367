import './Profile.scss';
import { CoreButton } from '@packages';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '@store/hooks';
import { selectLoggedUserInfo } from '@store/slices/userSlice';
import avatar from '@assets/images/Avatar.png';
import { useTranslation } from 'react-i18next';
import { BasicInfo } from "../index";

const Profile = () => {
    const { t } = useTranslation();
    const { userDetails } = useAppSelector(selectLoggedUserInfo);

    return (
        <div className='profile-container'>
            <div className='cover-photo'>
                <div className='profile-photo'>
                    <img src={userDetails?.DisplayPictureUrl || avatar} alt='Profile' />
                    <div className='profile-info'>
                        <div className='profile-name'>
                            <div>{userDetails?.FirstName}</div>
                            <div>{userDetails?.LastName}</div>
                        </div>
                        <div className='profile-mail'>{userDetails?.Email}</div>
                    </div>
                    <div className='button-section'>
                        <Link to='/setting'>
                            <CoreButton icon={<EditOutlined />} type='basic' text={t('EDIT')} />
                        </Link>
                    </div>
                </div>
            </div>
            <BasicInfo />
        </div>
    );
};

export default Profile;
