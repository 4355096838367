export function getDaysLeftInMonth(): number {
    // Create a new Date object with the current date
    const currentDate = new Date();

    // Get the last day of the current month
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // Calculate the difference between the last day of the month and the current date
    const timeDifference = lastDayOfMonth.getTime() - currentDate.getTime();
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days

    // Return the number of days left in the month
    return daysLeft;
}
