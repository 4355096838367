import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table, Tooltip } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import InviteClient from '@components/invite-client/InviteClient';
import { CoreButton, CoreSearchInput, CoreStatus } from '@packages';
import { IDevice, IDeviceTableData } from '@models/organization.model';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getCountryNameFromCode } from '@utils/getCountryNameFromCode';
import {
    disableDevice,
    enableDevice,
    getEntityDevices,
    selectEntityDevicesInfo,
} from '@store/slices/deviceSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { ConfirmationModal } from '@components';
import { DEFAULT_DELAY_TIME } from '@config/constants';
import { INVOICE_TYPE_OPTIONS } from '@utils/InvoiceTypeOptions';

const EntityDevices = () => {
    const { t } = useTranslation();
    const [deviceList, setDeviceList] = useState<IDeviceTableData[]>([]);
    const { isLoading, data: entityDevices } = useAppSelector(selectEntityDevicesInfo);
    const { entities } = useSelector((state: RootState) => state.entity);
    const { organizationId = '', entityId = '' } = useParams();
    const dispatch = useAppDispatch();
    getCountryNameFromCode('BD');

    const [isActiveModalOpen, setActiveModalOpen] = useState(false);
    const [isDeactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [deviceId, setDeviceId] = useState<string>('');
    const [delayLoading, setDelayLoading] = useState(false);

    useEffect(() => {
        if (organizationId && entityId) {
            setDelayLoading(true);
            setTimeout(() => {
                dispatch(getEntityDevices({ organizationId, entityId }));
                setDelayLoading(false);
            }, DEFAULT_DELAY_TIME);
            return;
        }
    }, []);

    const formatDeviceListData = () => {
        const data: IDeviceTableData[] = [];
        if (entityDevices.length) {
            entityDevices?.forEach((device: IDevice) => {
                data.push({
                    key: device?.DeviceId || '',
                    DeviceId: device?.DeviceId || '',
                    Category: device?.Category || '---',
                    CommonName: device?.CommonName || '---',
                    Country: device?.Country || '---',
                    Email: device?.Email || '---',
                    InvoiceType: device?.InvoiceType || '---',
                    Location: device?.Location || '---',
                    OrganizationUnitName: device?.OrganizationUnitName || '---',
                    HasCSID: device?.HasCSID || false,
                    IsActive: device.IsActive || false,
                    ActionButtons: device.IsActive || false,
                    EntityID: device.EntityId || '',
                });
            });
        }
        setDeviceList(data);
    };

    useEffect(() => {
        if (!isLoading && entityDevices) {
            formatDeviceListData();
        }
    }, [entityDevices]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate();

    const handleDeviceStatus = (e: any, DeviceId: string, IsActive: boolean) => {
        e.stopPropagation();
        setDeviceId(DeviceId);
        if (!IsActive) {
            setActiveModalOpen(true);
        } else {
            setDeactivateModalOpen(true);
        }
    };

    const onDeviceDeactivate = () => {
        setButtonLoading(true);
        dispatch(disableDevice({ DeviceId: deviceId })).finally(() => {
            setTimeout(() => {
                dispatch(getEntityDevices({ organizationId, entityId }));
                setDelayLoading(false);
            }, DEFAULT_DELAY_TIME);
        });
        setButtonLoading(false);
        setDeactivateModalOpen(false);
    };

    const onDeviceActivate = () => {
        setButtonLoading(true);
        dispatch(enableDevice({ DeviceId: deviceId })).finally(() => {
            setTimeout(() => {
                dispatch(getEntityDevices({ organizationId, entityId }));
                setDelayLoading(false);
            }, DEFAULT_DELAY_TIME);
        });
        setButtonLoading(false);
        setActiveModalOpen(false);
    };

    const columns: ColumnsType<IDeviceTableData> = [
        {
            title: `${t('ENTITY_ID')}`,
            dataIndex: 'EntityID',
            render: (text, data) => (
                <Tooltip title={data?.EntityID}>
                    <span>{data.EntityID?.slice(-5)}</span>
                </Tooltip>
            ),
        },
        {
            title: `${t('COMMON_NAME')}`,
            dataIndex: 'CommonName',
        },
        {
            title: `${t('ORGANIZATION_TIN_NUMBER')}`,
            dataIndex: 'OrganizationUnitName',
        },
        {
            title: `${t('INVOICE_TYPE')}`,
            dataIndex: 'InvoiceType',
            render: (InvoiceType: string) => {
                const invoiceType = INVOICE_TYPE_OPTIONS.find(
                    (data) => data.value === InvoiceType,
                )?.label;
                return <span>{t(`${invoiceType}`)}</span>;
            },
        },
        {
            title: `${t('HAS_CSID')}`,
            dataIndex: 'HasCSID',
            render: (text: boolean) => <span>{text ? t('YES') : t('NO')}</span>,
        },
        {
            title: `${t('ACTIVE_STATUS')}`,
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? t('ACTIVE') : t('INACTIVE')}
                />
            ),
        },
        {
            title: '',
            dataIndex: 'IsActive',
            render: (IsActive: boolean, data: IDeviceTableData) => (
                <div className='action-buttons'>
                    <div className='edit-table-icon' style={{ display: 'flex', gap: 10 }}>
                        {!IsActive && (
                            <Button
                                size='small'
                                type='primary'
                                disabled={data?.IsActive}
                                onClick={(e) =>
                                    handleDeviceStatus(e, data?.DeviceId || '', IsActive)
                                }
                            >
                                {t('ACTIVATE')}
                            </Button>
                        )}

                        {IsActive && (
                            <Button
                                size='small'
                                disabled={!data?.IsActive}
                                onClick={(e) =>
                                    handleDeviceStatus(e, data?.DeviceId || '', IsActive)
                                }
                            >
                                {t('DEACTIVATE')}
                            </Button>
                        )}
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = (record: any) => {
        navigate(`/organizations/${organizationId}/device/${record.key}`);
    };

    const entityLength = entities.length === 0 ? 1 : entities.length;

    return (
        <div className='round-box-shadow'>
            <div className='space-for-search margin-30'>
                <Row gutter={16}>
                    <Col span={21}>
                        <CoreSearchInput placeholder={t('SEARCH')} />
                    </Col>
                    <Col span={3} style={{ padding: '0px' }}>
                        <CoreButton
                            className='submit-button'
                            type='primary'
                            text={t('ADD_DEVICE')}
                            isFullWidth={true}
                            onClick={() =>
                                navigate(`/organizations/${organizationId}/device/create`)
                            }
                            disabled={deviceList.length === entityLength * 3}
                        />
                    </Col>
                </Row>
            </div>
            <div className='ksa-table-wrapper'>
                <div className='general-device-limit'>
                    {entityLength === 0 ? (
                        <p className='warning-label'>
                            {t('YOU_HAVE_RECHED_THE_MAXIMUM_DEVICE_ALLOWED_FOR_THE_ORGANIZATION')}
                        </p>
                    ) : deviceList.length === entityLength * 3 ? (
                        <p className='warning-label'>
                            {t('YOU_HAVE_RECHED_THE_MAXIMUM_DEVICE_ALLOWED_FOR_(3-PER-ENTITY)')}
                        </p>
                    ) : (
                        ''
                    )}
                </div>
                <Table
                    columns={columns}
                    dataSource={deviceList}
                    loading={isLoading || delayLoading}
                    rowClassName='cursor-pointer'
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => handleRowClick(record),
                        };
                    }}
                    pagination={false}
                />
            </div>
            <Modal
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={400}
                footer={null}
                closable={false}
            >
                <InviteClient handleOk={handleOk} handleCancel={handleCancel} />
            </Modal>

            <ConfirmationModal
                title={t('DEACTIVATE_DEVICE')}
                description={t('WOULD_YOU_LIKE_TO_DEACTIVATE_THIS_DEVICE')}
                buttonType='Inactivate'
                buttonText={t('DEACTIVATE')}
                isDeleteModalOpen={isDeactivateModalOpen}
                handleDeleteOk={() => onDeviceDeactivate()}
                handleDeleteCancel={() => setDeactivateModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title={t('ACTIVATE_DEVICE')}
                description={t('WOULD_YOU_LIKE_TO_ACTIVATE_THIS_DEVICE')}
                buttonType='activate'
                buttonText={t('ACTIVATE')}
                isDeleteModalOpen={isActiveModalOpen}
                handleDeleteOk={() => onDeviceActivate()}
                handleDeleteCancel={() => setActiveModalOpen(false)}
                isLoading={isButtonLoading}
            />
        </div>
    );
};

export default EntityDevices;
