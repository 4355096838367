import { AXIOS_INSTANCE, DefaultHeaders } from '../config/axios-config';
import { APP_CONFIG } from '../config/constants';
import axios, { AxiosError } from 'axios';
import { localStoreAPI } from './local-storage.service';
import {
    I2FAPayload,
    ILoginResponse,
    ITokenResponse,
    IRecoverAccount,
    IActivateAccount,
    IResetPassword,
    ICreateAccount,
} from '@models/auth.model';

export const authAPI = {
    createAccount: async (payload: ICreateAccount): Promise<any> => {
        const url = APP_CONFIG.eisUrl + 'AccessManagement/CreateAccount';
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            return err.response?.data;
        }
    },

    login: async (user: { email: string; password: string }): Promise<ILoginResponse> => {
        const url = APP_CONFIG.identityUrl + `Token`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                grant_type: 'password',
                email: user.email,
                password: user.password,
            });
            const data = response.data;
            if (Object.prototype.hasOwnProperty.call(data, 'refreshTokenId')) {
                localStoreAPI.setAccessToken(data?.token);
                localStoreAPI.setRefreshToken(data?.refreshTokenId);
            }
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    login2FA: async ({ App, Token, OTP }: I2FAPayload): Promise<ITokenResponse> => {
        const url = APP_CONFIG.identityUrl + `Token`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                grant_type: 'twofa',
                App,
                Token,
                OTP,
            });
            const data = response.data;
            localStoreAPI.setAccessToken(data?.token);
            localStoreAPI.setRefreshToken(data?.refreshTokenId);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    tokenRefresh: async (token: string): Promise<string> => {
        const url = APP_CONFIG.identityUrl + `Token`;
        try {
            const response = await axios.post<any>(
                url,
                {
                    grant_type: 'refresh_token',
                    refresh_token_id: token,
                },
                {
                    headers: {
                        ...DefaultHeaders,
                        Authorization: 'Bearer ' + localStoreAPI.getAnonymousToken(),
                    },
                },
            );
            const newToken = response.data?.token;
            localStoreAPI.setAccessToken(newToken);
            return newToken;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getAnonymousToken: async (): Promise<string> => {
        const url = APP_CONFIG.identityUrl + `Token`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                grant_type: 'authenticate_site',
            });
            const token = response.data.token;
            localStoreAPI.setAnonymousToken(token);
            return token;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    activateAccount: async (payload: IActivateAccount): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `SecurityCommand/ActivateAccount`;

        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            return err.response?.data;
        }
    },

    recoverAccount: async (payload: IRecoverAccount): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `SecurityCommand/RecoverAccount`;

        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    resetPassword: async (payload: IResetPassword): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `SecurityCommand/ResetPassword`;

        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
