import React from 'react';
import { Spin } from 'antd';
import { useAppSelector } from '@store/hooks';
import { selectPaymentMethods, selectPaymentMethodsIsLoading } from '@store/slices/paymentSlice';
import './PaymentCardListView.scss';

export default function PaymentMethodCard() {
    const paymentMethods = useAppSelector(selectPaymentMethods);
    const isLoading = useAppSelector(selectPaymentMethodsIsLoading);
    return (
        <Spin spinning={isLoading}>
            {paymentMethods?.map((data) => (
                <div key={data.ID} className='method-card'>
                    <img
                        className='payment-card-image'
                        src={data.Brand === 'visa' ? LOGO.visa : LOGO.master}
                        alt='Visa Logo'
                        width={40}
                        height={30}
                    />

                    <div className='payment-card-right'>
                        <div>Ending in {data.Last4}</div>
                        <div>Expires {data.Expire}</div>
                    </div>
                </div>
            ))}
        </Spin>
    );
}

export const LOGO = {
    visa: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png',
    master: 'https://upload.wikimedia.org/wikipedia/commons/b/b7/MasterCard_Logo.svg',
};
