import { CoreButton } from '@packages';
import React from 'react';

interface IBillingAlertDetailsProps {
    openEditModal: () => void;
}
export default function BillingAlertDetails({openEditModal}: IBillingAlertDetailsProps) {
    return (
        <div className='alert-content card-container'>
            <div className='header-billing'>
                <div className='bold-title'>Billing Alert</div>
                <CoreButton type='basic' text='Add Alert' size='small' onClick={openEditModal} />
            </div>
            <div className='billing-content'>
                Setup automatic billing alerts to receive emails when a specified usage amount is
                reached for spend across your entire team
            </div>
        </div>
    );
}
