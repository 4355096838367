import { RequestStatus } from '@models/async-status.enum';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { ActionAPI } from '@service/action.service';
import {
    IAction,
    IActionId,
    IActionIdsGetPayload,
    IActionDeletePayload,
} from '@models/action.model';
import { ISelectOption } from '@models/generic.model';
import { EResourceType } from '@utils/resourceService';
import { uniqBy } from 'lodash';

interface IActionState {
    resourcePath: {
        status: RequestStatus;
        data: any;
    };
    actionId: {
        status: RequestStatus;
        actionIds: IActionId[];
    };
    action: {
        status: RequestStatus;
        actions: IAction[];
    };
}

const initialState: IActionState = {
    resourcePath: {
        status: 'idle',
        data: {},
    },
    actionId: {
        status: 'idle',
        actionIds: [],
    },
    action: {
        status: 'idle',
        actions: [],
    },
};

export const getResourcePaths = createAsyncThunk(
    'resource-path',
    async (fromService: EResourceType) => {
        try {
            const response = await ActionAPI.getResource(fromService);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getActionIds = createAsyncThunk(
    'getActionIds',
    async (payload: IActionIdsGetPayload) => {
        try {
            const response = await ActionAPI.getActionIds(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getActions = createAsyncThunk('getActions', async (payload: IActionIdsGetPayload) => {
    try {
        const response = await ActionAPI.getActions(payload);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const deleteAction = createAsyncThunk(
    'deleteAction',
    async (payload: IActionDeletePayload) => {
        try {
            const response = await ActionAPI.deleteAction(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const ActionSlice = createSlice({
    name: 'entity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getResourcePaths.pending, (state) => {
                state.resourcePath.status = 'loading';
            })
            .addCase(getResourcePaths.rejected, (state) => {
                state.resourcePath.status = 'failed';
            })
            .addCase(getResourcePaths.fulfilled, (state, action) => {
                state.resourcePath.status = 'idle';
                state.resourcePath.data = action.payload;
            })
            .addCase(getActionIds.pending, (state) => {
                state.actionId.status = 'loading';
            })
            .addCase(getActionIds.rejected, (state) => {
                state.actionId.status = 'failed';
            })
            .addCase(getActionIds.fulfilled, (state, action) => {
                state.actionId.status = 'idle';
                state.actionId.actionIds = action.payload.ActionIds;
            })
            .addCase(getActions.pending, (state) => {
                state.action.status = 'loading';
            })
            .addCase(getActions.rejected, (state) => {
                state.action.status = 'failed';
            })
            .addCase(getActions.fulfilled, (state, action) => {
                state.action.status = 'idle';
                state.action.actions = action.payload.Actions;
            })
            .addCase(deleteAction.pending, (state) => {
                state.action.status = 'loading';
            })
            .addCase(deleteAction.rejected, (state) => {
                state.action.status = 'failed';
            })
            .addCase(deleteAction.fulfilled, (state, action) => {
                state.action.status = 'idle';
                state.action.actions = action.payload.Actions;
            });
    },
});

export const formattedResourcePaths = (state: RootState) => {
    const resourceOriginalPaths = state.action?.resourcePath?.data?.paths;
    const resourceFormattedPaths = [];
    for (const key in resourceOriginalPaths) {
        const singleOption = {
            label: key,
            value: key,
            method: Object.keys(resourceOriginalPaths[key])[0],
        };
        resourceFormattedPaths.push(singleOption);
    }
    return resourceFormattedPaths;
};
export const formattedActionIds = (state: RootState) => {
    const originalActionIds = state.action?.actionId.actionIds;
    const formatActionIds: ISelectOption[] = [];
    if (originalActionIds.length) {
        originalActionIds.forEach((data) => {
            const singleOption = {
                label: data.ActionId,
                value: data.ActionId,
            };
            formatActionIds.push(singleOption);
        });
    }
    return formatActionIds || [];
};

export const selectActionOption = (state: RootState) => {
    const actions = state.action?.action?.actions || [];
    const uniqueActions = uniqBy(actions, 'ActionId').map((action) => {
        const actionValue = action.ActionId + '.*';
        return {
            value: actionValue,
            label: actionValue,
            hoverName: actionValue,
        };
    });
    const mergeActions = actions.map((action) => {
        const actionValue = action?.ActionId + '.' + action?.ActionName;
        return {
            value: actionValue,
            label: actionValue,
            hoverName: actionValue,
        };
    });
    return uniqueActions.concat(mergeActions);
};
export default ActionSlice.reducer;
