import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import './InventoryFilterInputs.scss';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { IInventoryGetPayload } from '@models/inventory.model';
import { UNIT_CODE_OPTIONS } from '@utils/unitCodeOptions';

export type IInventoryFilterData = Omit<
    IInventoryGetPayload,
    'PageLimit' | 'Search' | 'PageNumber'
>;

interface IInventoryFiltersProps {
    handleFilterSubmit: (data: IInventoryFilterData) => void;
    defaultValues?: Partial<IInventoryFilterData>;
}

const InventoryFilterInputs = ({ handleFilterSubmit, defaultValues }: IInventoryFiltersProps) => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm<IInventoryFilterData>({ mode: 'onTouched' });

    const onSubmit = (data: IInventoryFilterData) => {
        handleFilterSubmit(data);
    };

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    return (
        <div className='inventory-filter-wrapper'>
            <div className='margin-bottom-20'>
                <CoreTitle tag='h6' text='Filters' />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={10}>
                    <Col span={12}>
                        <InlineGenericSelect
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='InventoryType'
                            level='Item Type'
                            options={[
                                { label: 'Goods', value: 'Goods' },
                                { label: 'Services', value: 'Services' },
                            ]}
                            setFormValue={setValue}
                            errorMessageSize='small'
                            allowClear={true}
                            marginBottom={-5}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericSelect
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='IsActive'
                            level='ACTIVE_STATUS'
                            options={[
                                { label: 'Active', value: 1 },
                                { label: 'Deactivate', value: 0 },
                            ]}
                            setFormValue={setValue}
                            errorMessageSize='small'
                            allowClear={true}
                            marginBottom={-5}
                        />
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <InlineGenericInput
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Name'
                            level='Name'
                            errorMessageSize='small'
                            marginBottom={10}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericSelect
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='UnitCode'
                            level='Unit'
                            options={UNIT_CODE_OPTIONS}
                            setFormValue={setValue}
                            errorMessageSize='small'
                            allowClear={true}
                            marginBottom={-5}
                        />
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col span={12}>
                        <InlineGenericInput
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='SellingPrice'
                            level='Sale Price'
                            errorMessageSize='small'
                            marginBottom={10}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericInput
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='CostPrice'
                            level='Purchase Price'
                            type='number'
                            errorMessageSize='small'
                            marginBottom={10}
                        />
                    </Col>
                </Row>

                <Row justify='end'>
                    <CoreButton
                        type='primary'
                        htmlType='submit'
                        isFullWidth={false}
                        text='Apply Now'
                    />
                </Row>
            </form>
        </div>
    );
};

export default InventoryFilterInputs;
