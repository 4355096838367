import { ReactNode } from 'react';
import './ReasonCard.scss';

interface ReasonCardProps {
    icon: ReactNode;
    title: string;
}

const ReasonCard = ({ icon, title }: ReasonCardProps) => {
    return (
        <div className='reason-card-wrapper'>
            <div className='icon-wrapepr'>{icon}</div>
            <p className='reason-card-title'>{title}</p>
        </div>
    );
};

export default ReasonCard;
