import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    ICredit,
    ISubscriptionInvoices,
    ISubscriptionInvoicesPayload,
} from '@models/subscription.model';
import { SubscribeAPI } from '@service/subscription.service';

interface ISubscriptionOverviewState {
    isLoading: boolean;
    credits: ICredit[];
    subscriptionInvoices: ISubscriptionInvoices[];
    subscriptionInvoice: ISubscriptionInvoices | null;
}

const initialState: ISubscriptionOverviewState = {
    isLoading: false,
    credits: [],
    subscriptionInvoices: [],
    subscriptionInvoice: null,
};

export const getCredits = createAsyncThunk('getCredits', async () => {
    try {
        const response = await SubscribeAPI.GetCredits();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});
export const getSubscriptionInvoices = createAsyncThunk(
    'getSubscriptionInvoices',
    async (payload: ISubscriptionInvoicesPayload) => {
        try {
            const response = await SubscribeAPI.GetSubscriptionInvoices(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);
export const getSubscriptionInvoice = createAsyncThunk(
    'getSubscriptionInvoice',
    async (invoiceId: string) => {
        try {
            const response = await SubscribeAPI.GetSubscriptionInvoice(invoiceId);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const subscriptionOverview = createSlice({
    name: 'subscriptionOverview',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCredits.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCredits.fulfilled, (state, action) => {
                state.isLoading = false;
                state.credits = action.payload;
            })
            .addCase(getCredits.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getSubscriptionInvoices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubscriptionInvoices.fulfilled, (state, action) => {
                state.isLoading = false;
                state.subscriptionInvoices = action.payload;
            })
            .addCase(getSubscriptionInvoices.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getSubscriptionInvoice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubscriptionInvoice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.subscriptionInvoice = action.payload;
            })
            .addCase(getSubscriptionInvoice.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export default subscriptionOverview.reducer;
