import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IApiServices, IApiService, ICreateAPiService } from '@models/api-services.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';

export const ApiServicesAPI = {
    getApiServices: async (): Promise<IApiServices> => {
        const url = APP_CONFIG.identityUrl + `APICommand/GetKeys`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {});
            return (response?.data?.APIKeys as IApiServices) || [];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getApiServiceById: async (apiKey: string): Promise<IApiService> => {
        const url = APP_CONFIG.identityUrl + `APIQuery/GetKey${processQuery({ apikey: apiKey })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data as IApiService;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    createApiService: async (payload: ICreateAPiService): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `APICommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    updateApiService: async (apiKey: string, payload: ICreateAPiService): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `APICommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { ...payload, apikey: apiKey });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    deleteApiService: async (apikey: string): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `APICommand/Remove`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { apikey });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
