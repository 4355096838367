export const CRS_OPTIONS = [
    // {
    //     value: 'TIN',
    //     label: 'Tax Identification Number',
    // },
    {
        value: 'CRN',
        label: 'COMMERCIAL_REGISTRATION_NUMBER',
        display_value: 'Commercial Registration Number',
    },
    {
        value: 'MOM',
        label: 'MOMRAH_LICENSE',
        display_value: 'MOMRAH license',
    },
    {
        value: 'MLS',
        label: 'MHRSD_LICENSE',
        display_value: 'MHRSD license',
    },
    {
        value: '700',
        label: '700_NUMBER',
        display_value: '700 Number',
    },
    {
        value: 'SAG',
        label: 'MISA_LICENSE',
        display_value: 'MISA license',
    },
    // {
    //     value: 'SAG',
    //     label: 'Sagia license',
    // },
    // {
    //     value: 'NAT',
    //     label: 'National ID',
    // },
    // {
    //     value: 'GCC',
    //     label: 'GCC ID',
    // },
    // {
    //     value: 'IQA',
    //     label: 'Iqama Number',
    // },
    // {
    //     value: 'PAS',
    //     label: 'Passport ID',
    // },
    {
        value: 'OTH',
        label: 'OTHER_ID',
        display_value: 'Other ID',
    },
];
