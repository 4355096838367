import React from 'react';
import './DateSummary.scss';
import { Collapse, CollapseProps } from 'antd';
import { displayCurrency } from '@utils/displayCurrency';
import { GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';

type CollapeKey = '1' | '2';

export default function DateSummary() {
    const genExtra = (key: CollapeKey) => {
        if (key === '1') {
            return <span> {displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 0 })}</span>;
        }
        return <span> {displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 2.34 })}</span>;
    };

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: (
                <div className='collapse-header'>
                    Container Registry (1)
                    <span className='middle'>View Container Registry</span>
                </div>
            ),
            children: <span></span>,
            extra: genExtra('1'),
        },
        {
            key: '2',
            label: (
                <div className='collapse-header'>
                    Droplets (1) <span className='middle'>View Droplets</span>
                </div>
            ),
            children: <span></span>,
            extra: genExtra('2'),
        },
    ];

    return (
        <div className='summary-content card-container'>
            <div className='header'>
                <div>
                    <div className='bold-title mb-2'>Month-to-date Summary December 1-11, 2023</div>
                    <div className='summary'>
                        These charges are factored into your account balance
                    </div>
                </div>
                <div className='align-left'>
                    <div className='mb-2 '>
                        Download- <span className='primary-color'>PDF</span>
                    </div>
                    <div className='primary-color'>Expand All</div>
                </div>
            </div>
            <div className='collapse'>
                <Collapse defaultActiveKey={['1']} bordered={false} items={items} />
            </div>
            <div className='calculation-total'>
                <div className='left-calculation'></div>
                <div className='right-calculation'>
                    <div className='cal-container pb-10 border-bottom'>
                        <div>SubTotal</div>
                        <div>
                            {displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 2.34 })}
                        </div>
                    </div>
                    <div className='pt-10 cal-container'>
                        <div>Total</div>
                        <div>
                            {displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 2.34 })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
