import { CoreButton } from '@packages';
import { Modal } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import './MakePayment.scss';
import { displayCurrency } from '@utils/displayCurrency';
import { GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import InlineGenericRadio from '@components/common/InlineGenericRadio';
import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
interface IMakePaymentProps {
    isOpenModal: boolean;
    onCancelModal: () => void;
}
export default function MakePayment({ isOpenModal, onCancelModal }: IMakePaymentProps) {
    const requiredAmount = displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 2.34 });

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        clearErrors,
        watch,
        reset,
    } = useForm<any>({
        mode: 'onChange',
        defaultValues: {
            PaymentType: PaymentType.CURRENT_AMOUNT,
            Amount: 2.34,
        },
    });
    const onSubmit = () => {
        onCancelModal();
        reset();
    };
    const onCancelButton = () => {
        onCancelModal();
        reset();
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal
                width={800}
                open={isOpenModal}
                closable={false}
                title={'Make a Payment'}
                footer={[
                    <CoreButton key={1} text={'Cancel'} type={'basic'} onClick={onCancelButton} />,
                    <CoreButton
                        key={2}
                        text={'Submit Payment'}
                        type={'primary'}
                        onClick={() => {
                            handleSubmit(onSubmit)();
                        }}
                        className='submit-modal-button'
                    />,
                ]}
            >
                <div className='payment-info'>
                    Your current estimation costs for this billing period are{' '}
                    <span className='card-title'>{requiredAmount}</span>
                </div>

                <InlineGenericRadio
                    levelCol={24}
                    inputCol={24}
                    setFormValue={setFormValue}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    options={[
                        {
                            label: `${requiredAmount} - Estimated cost for this billing period`,
                            value: PaymentType.CURRENT_AMOUNT,
                        },
                        {
                            label: `Custom Amount`,
                            value: PaymentType.CUSTOM_AMOUNT,
                        },
                    ]}
                    dataKey='PaymentType'
                    level={'Payment Amount'}
                    rules={{
                        required: true,
                    }}
                    marginBottom={20}
                    errorMessageSize='small'
                />

                {watch('PaymentType') === PaymentType.CUSTOM_AMOUNT && (
                    <>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='Amount'
                            level='Amount'
                            marginBottom={15}
                            errorMessageSize='small'
                            rules={{
                                required: true,
                            }}
                        />
                    </>
                )}

                <InlineGenericSelect
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='PaymentMethod'
                    level='Saved Payment Method'
                    options={[
                        {
                            value: '2244',
                            label: 'Visa Ending with 2244',
                        },
                        {
                            value: '7700',
                            label: 'Mastercard Ending with 7700',
                        },
                    ]}
                    rules={{
                        required: true,
                    }}
                    setFormValue={setFormValue}
                    marginBottom={5}
                    errorMessageSize='small'
                />
            </Modal>
        </form>
    );
}

export enum PaymentType {
    CUSTOM_AMOUNT = 'CUSTOM_AMOUNT',
    CURRENT_AMOUNT = 'CURRENT_AMOUNT',
}
