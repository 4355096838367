import React from 'react';
import './InventoryMapField.scss';
import { Controller, useForm } from 'react-hook-form';
import { CoreTitle } from '@packages';
import { Checkbox, Col, Row, Select } from 'antd';

interface IInventoryMapField {
    itemName: string;
    itemNameSecondary: string;
    rate: string;
    status: string;
    description: string;
    productType: string;
    account: string;
    usageUnit: string;
    purchaseDescription: string;
    purchaseAccount: string;
    purchaseRate: string;
    itemType: string;
    vendor: string;
    saveTheseSelections: string;
}

const InventoryMapField = () => {
    
    const options = [
        {
            value: '1',
            label: 'Mr.',
        },
        {
            value: '2',
            label: 'Ms.',
        },
        {
            value: '3',
            label: 'Mrs.',
        },
    ];

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IInventoryMapField>({ mode: 'onChange' });
    const onSubmit = (data: IInventoryMapField) => {
        console.log(data);
    };

    return (
        <div className='inventory-map-field-wrapper'>
            <div className='selected-device'>
                <p>
                    Your Selected File :{' '}
                    <span className='file-name'>Samsung R&D - batch 3.csv</span>
                </p>
                <p>The best match to each field on the selected file have been auto-selected.</p>
            </div>
            <CoreTitle tag='h5' text='Items Details' />
            <div className='inventory-map-field-form'>
                <Row gutter={130} align='middle' className='margin-bottom-6'>
                    <Col span={8} className='einvoice-padding'>
                        <p>e-Invoice Solutions Books Field</p>
                    </Col>
                    <Col span={16}>
                        <p>Imported file headers</p>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Item Name</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='itemName'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.itemName?.type === 'required' && (
                                <span className='error-message'>Item Name field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>
                                Item Name
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='itemNameSecondary'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.itemNameSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Item Name Secondary field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Rate</label>
                        </Col>
                        <Col span={8} className='padding-right-10'>
                            <Controller
                                name='rate'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.rate?.type === 'required' && (
                                <span className='error-message'>Rate field is required</span>
                            )}
                        </Col>
                        <Col span={8} className='padding-left-10'>
                            <Controller
                                name='rate'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='1234567.89'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.rate?.type === 'required' && (
                                <span className='error-message'>Rate field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Status</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='status'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.status?.type === 'required' && (
                                <span className='error-message'>Status field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Description</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='description'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.description?.type === 'required' && (
                                <span className='error-message'>Description field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Item Type</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='productType'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.productType?.type === 'required' && (
                                <span className='error-message'>Item Type field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Account</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='account'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.account?.type === 'required' && (
                                <span className='error-message'>Account field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Usage unit</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='usageUnit'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.usageUnit?.type === 'required' && (
                                <span className='error-message'>Usage unit field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Purchase Description</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='purchaseDescription'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.purchaseDescription?.type === 'required' && (
                                <span className='error-message'>
                                    Purchase Description field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Purchase Rate</label>
                        </Col>
                        <Col span={8} className='padding-right-10'>
                            <Controller
                                name='purchaseRate'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.purchaseRate?.type === 'required' && (
                                <span className='error-message'>
                                    Purchase Rate field is required
                                </span>
                            )}
                        </Col>
                        <Col span={8} className='padding-left-10'>
                            <Controller
                                name='purchaseRate'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='1234567.89'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.purchaseRate?.type === 'required' && (
                                <span className='error-message'>Purchase Rate is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Item Type</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='itemType'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.itemType?.type === 'required' && (
                                <span className='error-message'>Item Type field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Purchase Account</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='purchaseAccount'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.purchaseAccount?.type === 'required' && (
                                <span className='error-message'>
                                    Purchase Account field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Vendor</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='vendor'
                                render={({ field }) => (
                                    <Select
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className='inventory-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.vendor?.type === 'required' && (
                                <span className='error-message'>Vendor field is required</span>
                            )}
                        </Col>
                    </Row>
                </form>
                <div className='dropdown-list-item'>
                    <Row align='middle' gutter={130} className='margin-bottom-30'>
                        <Col span={24}>
                            <Controller
                                name='saveTheseSelections'
                                render={({ field }) => (
                                    <Checkbox className='general-checkbox'>
                                        Save these selections for use during future imports.
                                    </Checkbox>
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.saveTheseSelections?.type === 'required' && (
                                <span className='error-message'>
                                    Save these selections for use during future imports.
                                </span>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default InventoryMapField;
