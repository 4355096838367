import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getOrganizations } from '@store/slices/organizationSlice';
import { useNavigate } from 'react-router-dom';
import { userSelectRole } from '@store/slices/userSlice';
import { organizationRedirect } from '../helper/organizationRedirect';

const useCheckAuthorization = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isOrganizationAdmin } = useAppSelector(userSelectRole);

    const checkAuthorization = async () => {
        if (isOrganizationAdmin) {
            await dispatch(getOrganizations())
                .then(unwrapResult)
                .then((organizations) => {
                    organizationRedirect(organizations, navigate);
                });
        }
    };

    return checkAuthorization;
};

export default useCheckAuthorization;
