import { ICustomer } from '@models/customer.model';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { IAddress, IContact } from '@models/generic.model';
import { IEntity, IOrganization } from '@models/organization.model';
import { TFunction } from 'i18next';
import { getCountryNameFromCode } from '@utils/getCountryNameFromCode';
import { ITemplate } from "@models/template.modle";

type CoreList = { key: string; value: string }[];

export function getCustomerInformation(
    customer: ICustomer,
    t: TFunction<'translation', undefined>,
): CoreList {
    return [
        {
            key: `${t('CUSTOMER_TYPE')}:`,
            value: customer?.CustomerType || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LEGAL_REGISTRATION_NAME_ARABIC')}:`,
            value: customer?.LegalRegistrationName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LEGAL_REGISTRATION_NAME_ENGLISH')}:`,
            value: customer?.LegalRegistrationNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('VAT_REGISTRATION_NUMBER')}:`,
            value: customer?.VatRegistrationNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('OTHER_BUYER_ID_TYPE')}:`,
            value: customer?.CompanyRegistrationScheme || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('OTHER_BUYER_ID')}:`,
            value: customer?.CompanyRegistrationNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('TAX_SCHEME')}:`,
            value: customer?.TaxScheme || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CUSTOMER_EMAIL_ADDRESS')}:`,
            value: customer?.Email || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CUSTOMER_PHONE_NUMBER')}:`,
            value: customer?.PhoneNumber || DEFAULT_EMPTY_VALUE,
        },
    ];
}

export function getOrganizationInformation(
    organization: IOrganization,
    t: TFunction<'translation', undefined>,
): CoreList {
    return [
        {
            key: `${t('LEGAL_REGISTRATION_NAME')}:`,
            value: organization?.LegalRegistrationName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('VAT_REGISTRATION_NUMBER')}:`,
            value: organization?.VatRegistrationNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('OTHER_SELLER_ID')}:`,
            value: organization?.CompanyRegistrationNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('OTHER_SELLER_ID_TYPE')}:`,
            value: organization?.CompanyRegistrationScheme || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('FIRST_NAME')}:`,
            value: organization?.Contact?.FirstName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME')}:`,
            value: organization?.Contact?.MiddleName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LAST_NAME')}:`,
            value: organization?.Contact?.LastName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE')}:`,
            value: organization?.Contact?.JobTitle || DEFAULT_EMPTY_VALUE,
        },
    ];
}

export function getAddressInformation(
    address: IAddress,
    t: TFunction<'translation', undefined>,
): CoreList {
    return [
        {
            key: `${t('BUILDING_NUMBER')}:`,
            value: address?.BuildingNumber ?? DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_NUMBER')}:`,
            value: (address?.AdditionalNumber as any) ?? DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('STREET_ARABIC')}:`,
            value: address?.Street || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('STREET_ENGLISH')}:`,
            value: address?.StreetEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_STREET_ARABIC')}:`,
            value: address?.AdditionalStreet || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_STREET_ENGLISH')}:`,
            value: address?.AdditionalStreetEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CITY_ARABIC')}:`,
            value: address?.City || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CITY_ENGLISH')}:`,
            value: address?.CityEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('DISTRICT_ARABIC')}:`,
            value: address?.District || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('DISTRICT_ENGLISH')}:`,
            value: address?.DistrictEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PROVINCE_ARABIC')}:`,
            value: address?.ProvinceOrState || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PROVINCE_ENGLISH')}:`,
            value: address?.ProvinceOrStateEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('POSTAL_CODE')}:`,
            value: address?.PostalCode ?? DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('COUNTRY')}:`,
            value: getCountryNameFromCode(address?.CountryCode || DEFAULT_EMPTY_VALUE),
        },
    ];
}

export function getPersonInformation(
    contract: IContact,
    t: TFunction<'translation', undefined>,
): CoreList {
    return [
        {
            key: `${t('FIRST_NAME_ARABIC')}:`,
            value: contract?.FirstName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('FIRST_NAME_ENGLISH')}:`,
            value: contract?.FirstNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LAST_NAME_ARABIC')}:`,
            value: contract?.LastName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LAST_NAME_ENGLISH')}:`,
            value: contract?.LastNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME_ARABIC')}:`,
            value: contract?.MiddleName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME_ENGLISH')}:`,
            value: contract?.MiddleNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE_ARABIC')}:`,
            value: contract?.JobTitle || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE_ENGLISH')}:`,
            value: contract?.JobTitleEn || DEFAULT_EMPTY_VALUE,
        },
    ];
}

export function getEntityInformation(
    entity: IEntity,
    t: TFunction<'translation', undefined>,
): CoreList {
    return [
        {
            key: `${t('LEGAL_REGISTRATION_NAME_ARABIC')}:`,
            value: entity?.LegalRegistrationName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LEGAL_REGISTRATION_NAME_ENGLISH')}:`,
            value: entity?.LegalRegistrationNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('VAT_REGISTRATION_NUMBER')}:`,
            value: entity?.VatRegistrationNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('OTHER_SELLER_ID')}:`,
            value: entity?.CompanyRegistrationNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('OTHER_SELLER_ID_TYPE')}:`,
            value: entity?.CompanyRegistrationScheme || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('FIRST_NAME_ARABIC')}:`,
            value: entity?.Contact?.FirstName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('FIRST_NAME_ENGLISH')}:`,
            value: entity?.Contact?.FirstNameEn || DEFAULT_EMPTY_VALUE,
        },

        {
            key: `${t('LAST_NAME_ARABIC')}:`,
            value: entity?.Contact?.LastName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LAST_NAME_ENGLISH')}:`,
            value: entity?.Contact?.LastNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME_ARABIC')}:`,
            value: entity?.Contact?.MiddleName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME_ENGLISH')}:`,
            value: entity?.Contact?.MiddleNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE_ARABIC')}:`,
            value: entity?.Contact?.JobTitle || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE_ENGLISH')}:`,
            value: entity?.Contact?.JobTitleEn || DEFAULT_EMPTY_VALUE,
        },
    ];
}

export function getTemplateInformation(
    template: ITemplate,
    t: TFunction<'translation', undefined>,
): CoreList {
    return [
        {
            key: `Template ID:`,
            value: template?.TemplateId || '--',
        },
        {
            key: `Template Name:`,
            value: template?.TemplateName || '--',
        },
        {
            key: `Template HTML`,
            value: template?.TemplateHtml || '--',
        },
        {
            key: `Template Preview HTML:`,
            value: template?.PreviewHtml || '--',
        },
        {
            key: `Template Type:`,
            value: template?.TemplateType || '--',
        },
    ];
}



