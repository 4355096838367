import { CoreButton, CoreTitle } from '@packages';
import './BillingProfile.scss';

const BillingProfile = () => {
    return (
        <div className='billing-profile-wrapper round-box-shadow'>
            <div className='billing-profile-header'>
                <CoreTitle tag='h6' text='Billing Profile' />
                <CoreButton text='Edit' size='small' />
            </div>

            <div className='billing-profile-block-wrapper'>
                <div className='billing-profile-block'>
                    <CoreTitle tag='h6' text='Organization Created' />
                    <p className='block-sub-title'>8/28/22 - 02:00:28AM</p>
                </div>

                <div className='billing-profile-block'>
                    <CoreTitle tag='h6' text='Billing Email Address' />
                    <p className='block-sub-title'>Not added yet</p>
                </div>

                <div className='billing-profile-block'>
                    <CoreTitle tag='h6' text='Invocie Address' />
                    <p className='block-sub-title'>Sandweg 9,9 Frankfur amd Main, Hesse, Germany</p>
                </div>
            </div>
        </div>
    );
};

export default BillingProfile;
