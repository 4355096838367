import { FC } from 'react';
import { Input, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    isShowInvoiceLinesItemRequired,
    selectIsDeleteLineItem,
    updateInvoiceLineItem,
} from '@store/slices/einvoiceSlice';
import { IInvoiceLinesItem } from '@models/invoice.model';
import { displayCurrency } from '@utils/displayCurrency';
import UnitSelect from './UnitSelect';
import PriceTaxLine from './PriceTaxLine';
import MoreOverLine from './MoreOverLine';
import { getLineExtensionAmount } from '@utils/InvoiceCalculation';
import { checkArabicCharacterInput, checkCharacterInput } from '@utils/checkInputType';

interface IInvoiceSingleLine {
    data: IInvoiceLinesItem;
    indexNumber: number;
    onDeleteItem: (item: IInvoiceLinesItem) => void;
    documentTaxCurrencyCode?: string;
}
const InvoiceSingleLine: FC<IInvoiceSingleLine> = ({
    data,
    onDeleteItem,
    documentTaxCurrencyCode,
    indexNumber,
}) => {
    const dispatch = useAppDispatch();
    const isEnableDelete = useAppSelector(selectIsDeleteLineItem);
    const isRequiredShow = useAppSelector(isShowInvoiceLinesItemRequired);

    const updateInvoiceLine = (ItemId: string) => (object: Partial<IInvoiceLinesItem>) => {
        dispatch(updateInvoiceLineItem({ ItemId, Item: object }));
    };

    const onPasteValue = (e: any, type: string) => {
        const pastedText = e.clipboardData.getData('text');
        if (type === 'arabic') {
            const regex = /^[\u0600-\u06FF\s]*$/;
            if (!regex.test(pastedText)) {
                e.preventDefault();
            }
        } else {
            const regex = /^[A-Za-z\s]*$/;
            if (!regex.test(pastedText)) {
                e.preventDefault();
            }
        }
    };

    const disabledScrolling = (e: any) => {
        return e.target.addEventListener(
            'wheel',
            function (e: any) {
                e.preventDefault();
            },
            { passive: false },
        );
    };

    return (
        <div className='table-row' key={data.ID}>
            <div className='col'>
                <Input
                    onKeyPress={checkArabicCharacterInput}
                    onPaste={(event) => onPasteValue(event, 'arabic')}
                    onChange={(event) => {
                        const ItemName = event?.target?.value;
                        updateInvoiceLine(data.SerialId)({ ItemName });
                    }}
                    type='text'
                    className={`item-name ${isRequiredShow && !data.ItemName && 'required-border'}`}
                    value={data.ItemName}
                />
            </div>

            <div className='col'>
                <Input
                    onKeyPress={checkCharacterInput}
                    onPaste={(event) => onPasteValue(event, 'english')}
                    onChange={(event) => {
                        const ItemNameEn = event?.target?.value;
                        updateInvoiceLine(data.SerialId)({ ItemNameEn });
                    }}
                    type='text'
                    className={`item-name-english`}
                    value={data.ItemNameEn}
                />
            </div>

            <div className='col quantity'>
                <InputNumber
                    onChange={(value) => {
                        const Quantity = Number(value) ?? 0;
                        updateInvoiceLine(data.SerialId)({ Quantity });
                    }}
                    type='number'
                    className={`custom-number-input remove-dropdown ${
                        isRequiredShow && data.Quantity <= 0 && 'required-border'
                    }`}
                    value={data.Quantity}
                    min={0}
                    onFocus={disabledScrolling}
                />
            </div>
            <div className='col price-amount'>
                <InputNumber
                    className={`custom-number-input remove-dropdown ${
                        isRequiredShow && data?.PriceAmount <= 0 && 'required-border'
                    }`}
                    onChange={(value) => {
                        const PriceAmount = Number(value) ?? 0;
                        updateInvoiceLine(data.SerialId)({ PriceAmount });
                    }}
                    value={data.PriceAmount}
                    type='number'
                    min={0}
                    onKeyPress={(event: any) => {
                        const inputValue = event?.target?.value || '';
                        if (inputValue.length >= 12) {
                            event.preventDefault();
                            return;
                        }
                    }}
                    onFocus={disabledScrolling}
                />
            </div>
            <UnitSelect
                data={data}
                updateInvoiceLine={updateInvoiceLine}
                isRequiredShow={isRequiredShow}
            />
            <PriceTaxLine
                data={data}
                updateInvoiceLine={updateInvoiceLine}
                isRequiredShow={isRequiredShow}
            />

            <div className='col rounding-amount'>
                <div className='display-amount'>
                    {displayCurrency({
                        Amount: getLineExtensionAmount(data || 0) || 0,
                        Code: documentTaxCurrencyCode || 'SAR',
                    })}
                </div>
            </div>

            <MoreOverLine
                data={data}
                updateInvoiceLine={updateInvoiceLine}
                documentTaxCurrencyCode={documentTaxCurrencyCode}
            />

            {(indexNumber === 0 && !isEnableDelete && <></>) || (
                <div className='col action'>
                    <CloseOutlined
                        className='item-delete'
                        style={{ cursor: isEnableDelete ? 'pointer' : 'not-allowed' }}
                        onClick={() => isEnableDelete && onDeleteItem(data)}
                    />
                </div>
            )}
        </div>
    );
};

export default InvoiceSingleLine;
