import { IInvoiceLinesItem } from '@models/invoice.model';
import { CoreButton } from '@packages';
import { InputNumber, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { updateInvoiceLineItem } from '@store/slices/einvoiceSlice';
import { useAppDispatch } from '@store/hooks';

interface IDiscountAmountLineProps {
    isOpenModal: boolean;
    data: IInvoiceLinesItem;
    documentTaxCurrencyCode?: string;
    setHideModal: () => void;
}

function DiscountAmountLine({
    isOpenModal,
    data,
    documentTaxCurrencyCode,
    setHideModal,
}: IDiscountAmountLineProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const updateInvoiceLine = (ItemId: string) => (object: Partial<IInvoiceLinesItem>) => {
        dispatch(updateInvoiceLineItem({ ItemId, Item: object }));
    };
    return (
        <Modal
            width={500}
            open={isOpenModal}
            closable={false}
            title={t('DOCUMENT_DISCOUNT')}
            footer={[<div key={2} style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <CoreButton text={t('CANCEL')} type={'secondary'} onClick={setHideModal} />
                <CoreButton text={t('OK')} type={'primary'} onClick={setHideModal} />
            </div>]}
        >
            <div className='col discount'>
                <InputNumber
                    value={data.DiscountAmount}
                    type='number'
                    min={0}
                    max={(data.DiscountType === '%' && 100) || undefined}
                    className='discount-custom-group'
                    onChange={(value) => {
                        const DiscountAmount = value ?? 0;
                        updateInvoiceLine(data.SerialId)({ DiscountAmount });
                    }}
                    addonAfter={
                        <Select
                            value={data.DiscountType}
                            className='addon-select'
                            options={[
                                { value: '%', label: '%' },
                                {
                                    value: documentTaxCurrencyCode || 'Amount',
                                    label: documentTaxCurrencyCode || t('AMOUNT'),
                                },
                            ]}
                            onChange={(value, options) => {
                                const DiscountType = value;
                                updateInvoiceLine(data.SerialId)({
                                    DiscountType,
                                    DiscountAmount: 0,
                                });
                            }}
                        />
                    }
                    placeholder={t('DOCUMENT_DISCOUNT')}
                />
            </div>
        </Modal>
    );
}

export default DiscountAmountLine;
