import React, { useEffect, useState } from 'react';
import { CoreAuthConfirmation } from '@packages';
import { useSearchParams } from 'react-router-dom';
import { authAPI } from '@service/auth.service';
import { IActivateAccount } from '@models/auth.model';
import _ from 'lodash';
import { localStoreAPI } from '@service/local-storage.service';
import { useTranslation } from 'react-i18next';

const ActivateAccount = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isActivate, setIsActivate] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [searchParams] = useSearchParams();

    const activateAccount = async () => {
        setIsLoading(true);
        const activateAccountPayload: IActivateAccount = {
            email: searchParams.get('email') || '',
            activationCode: searchParams.get('token') || '',
        };
        const response = await authAPI.activateAccount(activateAccountPayload);
        setIsLoading(false);
        if (response?.Success) {
            setIsActivate(true);
        } else {
            setErrorMessage(response?.Message);
            setIsActivate(false);
        }
    };

    const getAnonymousToken = () => {
        setIsLoading(true);
        const anonymousToken = !!localStoreAPI.getAnonymousToken();
        if (anonymousToken) {
            activateAccount();
        } else {
            setTimeout(() => {
                getAnonymousToken();
            }, 1000);
        }
    };

    useEffect(() => {
        getAnonymousToken();
    }, []);
    return (
        <>
            {isLoading && (
                <CoreAuthConfirmation
                    title={t('PLEASE_WAIT_WE_ARE_VERIFYING_YOUR_ACCOUNT')}
                    isLoading={true}
                />
            )}
            {!isLoading && isActivate && (
                <CoreAuthConfirmation
                    title={t('CONGRATULATIONS')}
                    description={t('YOUR_ACCOUNT_HAS_BEEN_VERIFIED_SUCCESSFYLLY')}
                    linkTo='/login'
                />
            )}
            {!isLoading && !isActivate && (
                <CoreAuthConfirmation
                    title={t('YOUR_ACTIVATION_FAILED')}
                    description={_.startCase(_.toLower(errorMessage))}
                />
            )}
        </>
    );
};
export default ActivateAccount;
