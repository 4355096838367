import './Security.scss';
import { useAppSelector } from '@store/hooks';
import { selectUser2FaActivity } from '@store/slices/userSlice';
import { Activation } from "../../index";
import Disable2FAView from "./disable-2FA/disable2FA";

const Security = () => {
    const isEnable2FA = useAppSelector(selectUser2FaActivity);

    return (
        <div className='security-content-wrapper'>
            {isEnable2FA ? <Disable2FAView /> : <Activation />}
        </div>
    );
};
export default Security;
