import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    IDeletePolicyPayload,
    IPolicy,
    IPolicyCreate,
    IPolicyGetPayload,
    IPolicyResponse,
} from '@models/policy.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';

export const PolicyAPI = {
    getPolicy: async (payload: IPolicyGetPayload): Promise<IPolicyResponse> => {
        const url = APP_CONFIG.identityUrl + `PolicyQuery/Policies`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result as IPolicyResponse;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getPolicyById: async (PolicyId: string): Promise<IPolicy> => {
        const url = APP_CONFIG.identityUrl + `PolicyQuery/PolicyDto/${processQuery({ PolicyId })}`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url);
            return response?.data?.Result as IPolicy;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    createPolicy: async (payload: IPolicyCreate): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `PolicyCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    deletePolicy: async (payload: IDeletePolicyPayload): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `PolicyCommand/Delete`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
