import { useEffect, useState } from 'react';
import { Col, Row, Table, Tooltip } from 'antd';
import './InventoryListTable.scss';
import type { ColumnsType } from 'antd/es/table';
import InventoryDetails from '../../inventory/inventory-view/inventory-details/InventoryDetails';
import { useSelector } from 'react-redux';
import {
    FetchInventories,
    clearSelectedInventory,
    selectInventories,
    selectIsInventoryDetails,
    selectSelectedInventory,
    setSelectedInventory,
} from '@store/slices/InventoriesSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { InventoryStatus, ISingleInventory } from '@models/inventory.model';
import { EditOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_DELAY_TIME, DEFAULT_EMPTY_VALUE, DEFAULT_TABLE_SIZE } from '@config/constants';
import { CoreBackButton, CoreStatus } from '@packages';
import { StatusCode } from '@models/generic.model';
import { displayCurrency } from '@utils/displayCurrency';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { IInventoryFilterData } from '@components/inventory/inventory-filter-inputs/InventoryFilterInputs';

const columns = (t: TFunction<'translation', undefined>): ColumnsType<ISingleInventory> => [
    {
        title: t('NAME'),
        dataIndex: 'Name',
        render: (text: string, data) => (
            <Tooltip title={data?.NameEn}>
                <span>{data?.Name || DEFAULT_EMPTY_VALUE}</span>
            </Tooltip>
        ),
    },
    {
        title: t('TYPE'),
        dataIndex: 'InventoryType',
        render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
    },
    {
        title: t('UNIT'),
        dataIndex: 'UnitCode',
        render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
    },
    {
        title: t('SELLING_PRICE'),
        dataIndex: ['SalePrice', 'SaleCurrency'],
        render: (text, data) => (
            <Tooltip title={`${data?.SalePrice} ${data?.SaleCurrency}`}>
                <span>
                    {displayCurrency({ Code: data?.SaleCurrency, Amount: data?.SalePrice })}
                </span>
            </Tooltip>
        ),
    },
    {
        title: t('COST_PRICE'),
        dataIndex: ['PurchasePrice', 'PurchaseCurrency'],
        render: (text, data) => (
            <Tooltip title={`${data?.PurchasePrice} ${data?.PurchaseCurrency}`}>
                <Tooltip title={`${data?.PurchasePrice} ${data?.PurchaseCurrency}`}>
                    <span>
                        {displayCurrency({
                            Code: data?.PurchaseCurrency,
                            Amount: data?.PurchasePrice,
                        })}
                    </span>
                </Tooltip>
            </Tooltip>
        ),
    },
    {
        title: t('ACTIVE_STATUS'),
        dataIndex: 'IsActive',
        render: (status: InventoryStatus, data) =>
            data?.IsArchived ? (
                <CoreStatus type='inactive' status={StatusCode.Archive} />
            ) : data?.IsActive ? (
                <CoreStatus type='success' status={StatusCode.Active} />
            ) : (
                <CoreStatus type='inactive' status={StatusCode.Inactive} />
            ),
    },
];

const columns2 = (t: TFunction<'translation', undefined>): ColumnsType<ISingleInventory> => [
    {
        title: t('NAME'),
        dataIndex: 'Name',
        className: 'product-details-col',
        render: (text: string, data) => (
            <Tooltip title={data?.NameEn}>
                <span>{data?.Name || DEFAULT_EMPTY_VALUE}</span>
            </Tooltip>
        ),
    },
];

interface IInventoryListableProps {
    filterData?: IInventoryFilterData;
    query?: string;
}

const InventoryListTable = ({ filterData, query }: IInventoryListableProps) => {
    const { t } = useTranslation();
    const inventoryDetailsColumns = useSelector(selectIsInventoryDetails);
    const { status, data, totalCount } = useAppSelector(selectInventories);
    const { ItemId: selectedItemId } = useAppSelector(selectSelectedInventory) || {};
    const { state }: { state?: { delay: boolean } } = useLocation();
    const [isDelayLoading, setDelayLoading] = useState(false);
    const navigate = useNavigate();
    const [pageInfo, setPageInfo] = useState<{ PageLimit: number; PageNumber: number }>({
        PageLimit: 10,
        PageNumber: 1,
    });

    const dispatch = useAppDispatch();

    const handleRowClick = (record: ISingleInventory) => {
        dispatch(setSelectedInventory(record));
    };

    const getInventories = async () => {
        dispatch(
            FetchInventories({
                PageLimit: pageInfo.PageLimit,
                PageNumber: pageInfo.PageNumber,
                Search: query,
                ...filterData,
            }),
        );
    };

    const inventoryColum1 = [
        ...columns(t),
        {
            title: '',
            dataIndex: 'ItemId',
            render: (key: string) => (
                <div className='action-buttons'>
                    <div
                        className='edit-table-icon'
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/items/update/${key}`);
                        }}
                        aria-hidden='true'
                    >
                        <EditOutlined />
                    </div>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (state?.delay) {
            setDelayLoading(true);
            setTimeout(() => {
                getInventories();
                setDelayLoading(false);
            }, DEFAULT_DELAY_TIME);
            return;
        }
        getInventories();
        return () => {
            dispatch(clearSelectedInventory());
        };
    }, [pageInfo, filterData, query]);

    return (
        <div className='inventory-list-table-wrapper'>
            <div className='inventory-list-table-inner-wrapper'>
                <Row>
                    <Col
                        span={inventoryDetailsColumns ? 8 : 24}
                        className={`height-100 ${
                            inventoryDetailsColumns && 'details-scroll-height'
                        }`}
                    >
                        {inventoryDetailsColumns && (
                            <div style={{ marginLeft: '30px' }}>
                                <CoreBackButton
                                    linkTo='/items'
                                    onClick={() => dispatch(clearSelectedInventory())}
                                />
                            </div>
                        )}
                        <div
                            className={
                                inventoryDetailsColumns
                                    ? 'fixed-header-container'
                                    : 'ksa-table-wrapper table-auto-scroll-with-header'
                            }
                        >
                            <Table
                                loading={
                                    status === 'loading'
                                        ? true
                                        : isDelayLoading === true
                                        ? true
                                        : false
                                }
                                columns={inventoryDetailsColumns ? columns2(t) : inventoryColum1}
                                dataSource={data}
                                rowKey={'ItemId'}
                                rowClassName={(record: ISingleInventory) => {
                                    return record.ItemId === selectedItemId
                                        ? 'selected-row cursor-pointer'
                                        : 'cursor-pointer';
                                }}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: () => handleRowClick(record),
                                    };
                                }}
                                pagination={
                                    inventoryDetailsColumns
                                        ? false
                                        : {
                                              pageSize: DEFAULT_TABLE_SIZE,
                                              total: totalCount,
                                              showSizeChanger: false,
                                              showQuickJumper: false,
                                              showTotal: (total, range) =>
                                                  `${range[0]}-${range[1]} of ${total} items`,
                                              current: pageInfo.PageNumber,
                                              onChange: (PageNumber, PageLimit) => {
                                                  setPageInfo({ PageNumber, PageLimit });
                                              },
                                          }
                                }
                            />
                        </div>
                    </Col>
                    <Col span={16}>
                        {inventoryDetailsColumns && (
                            <div className='inventory-details-side-wrapper'>
                                <InventoryDetails />
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default InventoryListTable;
