import { Select } from 'antd';
import './LanguageMenu.scss';
import { useLanguage } from '@hooks';
import { useAppSelector } from '@store/hooks';
import { selectAppLanguage } from '@store/slices/languageSlice';
import GenericTooltip from '@components/common/GenericTooltip';
import { FlagIcon } from 'react-flag-kit';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE } from '@config/constants';

const LanguageMenu = () => {
    const { setCurrentLanguage } = useLanguage();
    const { selection } = useAppSelector(selectAppLanguage);
    const { t } = useTranslation();

    return (
        <div className='language-menu-wrapper'>
            <Select
                value={selection}
                defaultValue={DEFAULT_LANGUAGE}
                className='language-selection'
                size='small'
                bordered={false}
                onChange={(value) => setCurrentLanguage(value)}
                options={[
                    {
                        value: 'en',
                        label: (
                            <div>
                                <FlagIcon code='US' size={10} /> English
                            </div>
                        ),
                    },
                    {
                        value: 'sa',
                        label: (
                            <div>
                                <FlagIcon code='SA' size={10} /> عربي
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default LanguageMenu;
