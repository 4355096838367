import { EInvoiceType, IInvoiceLinesItem } from '@models/invoice.model';
import { getDaysLeftInMonth } from './daysLeftInMonth';
import { getFixedInvoiceType } from './InvoiceTypeSelectOption';
import { InvoiceHeader } from '@models/upload-csv';
import _ from 'lodash';

export enum EInvoiceTypeCode {
    B2B = '0100000',
    B2C = '0200000',
}

export const GET_ALL_INVOICE_TYPES: {
    value: EInvoiceType;
    label: keyof typeof EInvoiceType;
    displayKey: string;
}[] = [
    {
        value: EInvoiceType['Credit Note'],
        label: 'Credit Note',
        displayKey: 'CREDIT_NOTE',
    },
    {
        value: EInvoiceType['Debit Note'],
        label: 'Debit Note',
        displayKey: 'DEBIT_NOTE',
    },
    {
        value: EInvoiceType['Simplified Credit Note'],
        label: 'Simplified Credit Note',
        displayKey: 'SIMPLIFIED_CREDIT_NOTE',
    },
    {
        value: EInvoiceType['Simplified Debit Note'],
        label: 'Simplified Debit Note',
        displayKey: 'SIMPLIFIED_DEBIT_NOTE',
    },
    {
        value: EInvoiceType['Simplified Tax Invoice'],
        label: 'Simplified Tax Invoice',
        displayKey: 'SIMPLIFIED_TAX_INVOICE',
    },
    {
        value: EInvoiceType['Standard Tax Invoice'],
        label: 'Standard Tax Invoice',
        displayKey: 'STANDARD_TAX_INVOICE',
    },
];

export const GET_ALL_INVOICE_TYPES_WITH_TREE: IInvoiceTypeOption[] = [
    {
        value: EInvoiceType['Simplified Tax Invoice'],
        label: 'Simplified Tax Invoice',
        displayKey: 'SIMPLIFIED_TAX_INVOICE',
        children: [
            {
                value: EInvoiceType['Simplified Credit Note'],
                label: 'Simplified Credit Note',
                displayKey: 'SIMPLIFIED_CREDIT_NOTE',
            },
            {
                value: EInvoiceType['Simplified Debit Note'],
                label: 'Simplified Debit Note',
                displayKey: 'SIMPLIFIED_DEBIT_NOTE',
            },
        ],
    },
    {
        value: EInvoiceType['Standard Tax Invoice'],
        label: 'Standard Tax Invoice',
        displayKey: 'STANDARD_TAX_INVOICE',
        children: [
            {
                value: EInvoiceType['Credit Note'],
                label: 'Credit Note',
                displayKey: 'CREDIT_NOTE',
            },
            {
                value: EInvoiceType['Debit Note'],
                label: 'Debit Note',
                displayKey: 'DEBIT_NOTE',
            },
        ],
    },
];

export const GET_INITIAL_INVOICE_TYPES: IInvoiceTypeOption[] = [
    {
        value: EInvoiceType['Simplified Tax Invoice'],
        label: 'Simplified Tax Invoice',
        displayKey: 'SIMPLIFIED_TAX_INVOICE',
    },
    {
        value: EInvoiceType['Standard Tax Invoice'],
        label: 'Standard Tax Invoice',
        displayKey: 'STANDARD_TAX_INVOICE',
    },
];

export const GET_PHASE_ONE_INVOICE_TYPES: IInvoiceTypeOption[] = [
    {
        value: EInvoiceType['Simplified Tax Invoice'],
        label: 'Simplified Tax Invoice',
        displayKey: 'SIMPLIFIED_TAX_INVOICE',
    },
];

export const GET_SIMPLIFIED_INVOICE_TYPES: IInvoiceTypeOption[] = [
    {
        value: EInvoiceType['Simplified Tax Invoice'],
        label: 'Simplified Tax Invoice',
        displayKey: 'SIMPLIFIED_TAX_INVOICE',

        children: [
            {
                value: EInvoiceType['Simplified Credit Note'],
                label: 'Simplified Credit Note',
                displayKey: 'SIMPLIFIED_CREDIT_NOTE',
            },
            {
                value: EInvoiceType['Simplified Debit Note'],
                label: 'Simplified Debit Note',
                displayKey: 'SIMPLIFIED_DEBIT_NOTE',
            },
        ],
    },
];

export const GET_STANDARD_INVOICE_TYPES: IInvoiceTypeOption[] = [
    {
        value: EInvoiceType['Standard Tax Invoice'],
        label: 'Standard Tax Invoice',
        displayKey: 'STANDARD_TAX_INVOICE',

        children: [
            {
                value: EInvoiceType['Credit Note'],
                label: 'Credit Note',
                displayKey: 'CREDIT_NOTE',
            },
            {
                value: EInvoiceType['Debit Note'],
                label: 'Debit Note',
                displayKey: 'DEBIT_NOTE',
            },
        ],
    },
];

export const GET_MANDATORY_KEYS_BY_INVOICE_TYPE: EInvoiceType[] = [
    EInvoiceType['Credit Note'],
    EInvoiceType['Debit Note'],
    EInvoiceType['Simplified Debit Note'],
    EInvoiceType['Simplified Credit Note'],
];

export const GET_MANDATORY_KEYS_BY_CUSTOMER: EInvoiceType[] = [
    EInvoiceType['Credit Note'],
    EInvoiceType['Debit Note'],
    EInvoiceType['Standard Tax Invoice'],
];

export const INVOICE_TERMS = [
    {
        value: 15,
        label: 'NEXT_X_DAYS',
    },
    {
        value: 30,
        label: 'NEXT_X_DAYS',
    },
    {
        value: 45,
        label: 'NEXT_X_DAYS',
    },
    {
        value: 60,
        label: 'NEXT_X_DAYS',
    },
    {
        value: getDaysLeftInMonth(),
        label: 'DUE_END_OF_MONTH',
    },
];

export const TAX_CATEGORY: ITaxCategory[] = [
    {
        value: 'E',
        label: 'E [0%]',
        percent: 0,
    },
    {
        value: 'S',
        label: 'S [15%]',
        percent: 15,
    },
    {
        value: 'Z',
        label: 'Z [0%]',
        percent: 0,
    },
    {
        value: 'O',
        label: 'O [0%]',
        percent: 0,
    },
];

export const TAX_CATEGORY_DESCRIPTION =
    'E_EXEMPTED_S_STANDARD_TAX_Z_ZERO_RATED_TAX_O_OUTSIDE_SCOPE_OF_TAX';

type PaymentMeansOptions = Pick<TaxCategoryType, 'label' | 'value'> & { dataKey: string };

export const PAYMENT_MEANS: PaymentMeansOptions[] = [
    {
        value: '10',
        label: 'IN_CASH',
        dataKey: 'IN_CASH',
    },
    {
        value: '30',
        label: 'CREDIT',
        dataKey: 'CREDIT',
    },
    {
        value: '42',
        label: 'PAYMENT_TO_BANK_ACCOUNT',
        dataKey: 'PAYMENT_TO_BANK_ACCOUNT',
    },
    {
        value: '48',
        label: 'BANK_CARD',
        dataKey: 'BANK_CARD',
    },
    {
        value: '1',
        label: 'OTHERS',
        dataKey: 'OTHERS',
    },
];
export type TaxCategoryType = {
    value: string;
    label: string;
    percent: number;
};

export const GET_INVOICE_TYPE_CODE_BY_NAME = (
    name: EInvoiceType,
): { InvoiceType: string; InvoiceTypeCode: string; name: EInvoiceType } | undefined => {
    return GET_INVOICE_DATA_INFO.find((info) => info.name === name);
};

export const GET_INVOICE_TYPE_CODE_BY_TYPE_CODE = (
    type: string,
    invoiceTypeCode: string,
):
    | {
          InvoiceType: string;
          InvoiceTypeCode: string;
          name: EInvoiceType;
          displayName: string;
          displayKey: string;
      }
    | undefined => {
    const InvoiceTypeCode = getFixedInvoiceType(invoiceTypeCode);
    return (
        GET_INVOICE_DATA_INFO.find(
            (info) => info.InvoiceType === type && info.InvoiceTypeCode === InvoiceTypeCode,
        ) || undefined
    );
};

export const GET_INVOICE_DATA_INFO: {
    name: EInvoiceType;
    displayName: string;
    displayKey: string;
    InvoiceType: string;
    InvoiceTypeCode: EInvoiceTypeCode;
}[] = [
    {
        name: EInvoiceType['Standard Tax Invoice'],
        displayName: 'Standard Tax Invoice',
        displayKey: 'STANDARD_TAX_INVOICE',
        InvoiceType: '388',
        InvoiceTypeCode: EInvoiceTypeCode.B2B,
    },
    {
        name: EInvoiceType['Simplified Tax Invoice'],
        displayName: 'Simplified Tax Invoice',
        displayKey: 'SIMPLIFIED_TAX_INVOICE',
        InvoiceType: '388',
        InvoiceTypeCode: EInvoiceTypeCode.B2C,
    },
    {
        name: EInvoiceType['Credit Note'],
        displayName: 'Credit Note',
        displayKey: 'CREDIT_NOTE',
        InvoiceType: '381',
        InvoiceTypeCode: EInvoiceTypeCode.B2B,
    },
    {
        name: EInvoiceType['Debit Note'],
        displayName: 'Debit Note',
        displayKey: 'DEBIT_NOTE',
        InvoiceType: '383',
        InvoiceTypeCode: EInvoiceTypeCode.B2B,
    },
    {
        name: EInvoiceType['Simplified Credit Note'],
        displayName: 'Simplified Credit Note',
        displayKey: 'SIMPLIFIED_CREDIT_NOTE',
        InvoiceType: '381',
        InvoiceTypeCode: EInvoiceTypeCode.B2C,
    },
    {
        name: EInvoiceType['Simplified Debit Note'],
        displayName: 'Simplified Debit Note',
        displayKey: 'SIMPLIFIED_DEBIT_NOTE',
        InvoiceType: '383',
        InvoiceTypeCode: EInvoiceTypeCode.B2C,
    },
];

// Standard Tax Invoice, Simplified Tax Invoice
export const GET_INVOICE_REFERENCE_FILTER_INFO = {
    InvoiceType: ['388'],
    InvoiceTypeCode: ['0100000', '0200000'],
};

export const DEFAULT_LINES_ITEMS: Partial<IInvoiceLinesItem> = {
    SerialId: '8118024f-a208-411b-8056-0154efa4cb8e',
    TaxScheme: 'VAT',
    Quantity: 1,
    BaseQuantity: 1,
    PriceAmount: 0,
    AllowanceChargeIndicator: false,
    DiscountReason: 'N/A',
    DiscountType: '%',
    DiscountAmount: 0,
};

export const IS_INVOICE_ITEM_VALID = (item?: IInvoiceLinesItem): boolean => {
    if (!item) return false;
    if (!item?.ItemName || !item?.TaxCategory || !item?.UnitCode || item?.PriceAmount <= 0)
        return false;
    if (item.TaxCategory !== 'S' && !item.TaxExemptionCode) return false;
    return true;
};

export interface IInvoiceTypeOption {
    value: EInvoiceType;
    label: keyof typeof EInvoiceType;
    displayKey: string;
    children?: IInvoiceTypeOption[];
}

export interface ITaxCategory {
    value: string;
    label: string;
    percent: number;
}


export const mergedData = (data1: any[], data2: any[]): InvoiceHeader[] => {
    return _.map(data1, (item1) => {
        const matchingItem = _.find(data2, { key: item1?.invoiceHeader  });
        return {
            ...item1,
            label: matchingItem ? matchingItem.label : null,
        };
    });
}