import { Checkbox, Col, Input, Row } from 'antd';
import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import GenericErrorMessage, { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { CustomRules } from '@models/rules.model';
import { isFiledFoundError, isRequiredValue } from '@utils/errorMessageOption';
import { rules } from '@typescript-eslint/eslint-plugin';

interface IGenericCheckboxParams {
    control: Control<any, any>;
    level: string;
    dataKey: string;
    disabled?: boolean;
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    marginBottom?: number;
    children?: React.ReactNode;
    gutter?: number;
    tooltip?: string;

}

const GenericCheckbox: FC<IGenericCheckboxParams> = ({
    control,
    level,
    dataKey,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    marginBottom = 30,
    children,
    tooltip,
    gutter = 130,
    ...others
}) => {
    const { t } = useTranslation();

    return (
        <Row gutter={gutter} align='middle' style={{ marginBottom: marginBottom }}>
            <Col span={inputCol}>
                <Controller
                    name={dataKey as any}
                    render={({ field }) => (
                        <Checkbox className='general-checkbox' {...field} checked={field.value}>
                            {t(level)}
                            {tooltip && <GenericTooltip text={tooltip} />}
                        </Checkbox>
                    )}
                    control={control}
                />
                {children}
            </Col>
        </Row>
    );
};

export default GenericCheckbox;
