const BalanceIcon = () => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.08371 10.8333H7.08371M12.917 10.8333H17.917M10.0004 5.83333V17.5M10.0004 5.83333C11.151 5.83333 12.0837 4.90059 12.0837 3.75M10.0004 5.83333C8.84978 5.83333 7.91704 4.90059 7.91704 3.75M3.33371 17.5L16.667 17.5M3.33371 3.75001L7.91704 3.75M7.91704 3.75C7.91704 2.59941 8.84978 1.66667 10.0004 1.66667C11.151 1.66667 12.0837 2.59941 12.0837 3.75M12.0837 3.75L16.667 3.75M7.40074 11.947C7.06683 13.2255 5.93213 14.1667 4.58371 14.1667C3.23529 14.1667 2.10059 13.2255 1.76668 11.947C1.73939 11.8425 1.72575 11.7903 1.72444 11.5816C1.72363 11.4536 1.77118 11.1586 1.81214 11.0374C1.87896 10.8397 1.9513 10.7281 2.09597 10.5049L4.58371 6.66667L7.07145 10.5049C7.21612 10.7281 7.28845 10.8397 7.35528 11.0374C7.39624 11.1586 7.44379 11.4536 7.44298 11.5816C7.44166 11.7903 7.42802 11.8425 7.40074 11.947ZM18.2341 11.947C17.9002 13.2255 16.7655 14.1667 15.417 14.1667C14.0686 14.1667 12.9339 13.2255 12.6 11.947C12.5727 11.8425 12.5591 11.7903 12.5578 11.5816C12.557 11.4536 12.6045 11.1586 12.6455 11.0374C12.7123 10.8397 12.7846 10.7281 12.9293 10.5049L15.417 6.66667L17.9048 10.5049C18.0495 10.7281 18.1218 10.8397 18.1886 11.0374C18.2296 11.1586 18.2771 11.4536 18.2763 11.5816C18.275 11.7903 18.2614 11.8425 18.2341 11.947Z'
                stroke='#101828'
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default BalanceIcon;
