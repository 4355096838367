import React from 'react';
import './EstimatedCard.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { displayCurrency } from '@utils/displayCurrency';
import { GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import { Col, Row } from 'antd';
export default function EstimatedCard() {
    return (
        <div className='estimated-card card-container'>
            <div className='bold-title'>Estimated Due</div>
            <div className='content'>
                This is an estimate of the amount you owe based on your current month-to-date used
                after credits & prepayments.
            </div>
            <div className='bold-title amount'>
                {displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 230 })}
            </div>
            <div className='payment-views'>
                <Row align='middle' gutter={20} style={{ width: '100%' }}>
                    <Col span={24} sm={12} md={6} lg={6} xxl={5}>
                        <div className='card'>
                            <div className='card-title'>January 12, 2024</div>
                            <div>
                                Payment Due <ExclamationCircleOutlined />
                            </div>
                        </div>
                    </Col>
                    <Col span={24} sm={12} md={6} lg={6} xxl={5}>
                        <div className='card'>
                            <div className='card-title'>
                                {displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 0 })}
                            </div>
                            <div>
                                Prepayments <ExclamationCircleOutlined />
                            </div>
                        </div>
                    </Col>
                    <Col span={24} sm={12} md={6} lg={6} xxl={5}>
                        <div className='card'>
                            <div className='card-title'>
                                {displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 0 })}
                            </div>
                            <div>
                                Total Usage <ExclamationCircleOutlined />
                            </div>
                        </div>
                    </Col>
                    <Col span={24} sm={12} md={6} lg={6} xxl={5}>
                        <div className='card'>
                            <div className='card-title'>
                                {displayCurrency({ Code: GET_DEFAULT_CURRENCY_CODE, Amount: 0 })}
                            </div>
                            <div>
                                Estimated Due <ExclamationCircleOutlined />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
