import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    IGetAdminTemplatesPayload,
    ISingleAdminTemplatePayload,
    ITemplate
} from "@models/template.modle";
import { AdminTemplateAPI } from "@service/adminTemplate.service";
import { RootState } from "@store/store";


interface IAdminTemplateState {
    templates: {
        isLoading: boolean;
        data: ITemplate[] | [];
    };
    template: {
        isLoading: boolean;
        data: ITemplate | undefined;
    };
}

const initialState: IAdminTemplateState = {
    templates: {
        isLoading: false,
        data: [],
    },
    template: {
        isLoading: false,
        data: undefined,
    },
};

export const getAdminTemplates = createAsyncThunk('getAdminTemplates', async (payload: IGetAdminTemplatesPayload) => {
    try {
        const response = await AdminTemplateAPI.getAdminTemplates(payload);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getAdminSingleTemplate = createAsyncThunk('getAdminSingleTemplate', async (payload: ISingleAdminTemplatePayload) => {
    try {
        const response = await AdminTemplateAPI.getAdminSingleTemplate(payload);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const AdminTemplate = createSlice({
    name: 'adminTemplate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdminTemplates.pending, (state) => {
                state.templates.isLoading = true;
            })
            .addCase(getAdminTemplates.fulfilled, (state, action) => {
                state.templates.isLoading = false;
                state.templates.data = action.payload.Templates;
            })
            .addCase(getAdminTemplates.rejected, (state) => {
                state.templates.isLoading = true;
            })

            .addCase(getAdminSingleTemplate.pending, (state) => {
                state.template.isLoading = true;
            })
            .addCase(getAdminSingleTemplate.fulfilled, (state, action) => {
                state.template.isLoading = false;
                state.template.data = action.payload;
            })
            .addCase(getAdminSingleTemplate.rejected, (state) => {
                state.template.isLoading = true;
            })
    },
});

export const templatesData = (state: RootState) => state.adminTemplate.templates;
export const singleTemplateData = (state: RootState) => state.adminTemplate.template;

export default AdminTemplate.reducer;
