import { Modal } from 'antd';
import { FC } from 'react';
import './CreateCustomerModal.scss';
import { CustomerCreate } from '../../../customer';

type CreateCustomerModalProps = {
    isShowModal?: boolean;
    onResponse: ({ isCancel }: { isCancel: boolean }) => void;
};

const CreateCustomerModal: FC<CreateCustomerModalProps> = ({ isShowModal, onResponse }) => {
    return (
        <>
            <Modal
                className='customerModalWrapper'
                title='New Customer'
                closable={false}
                open={isShowModal}
                maskClosable={false}
                footer={[]}
            >
                <CustomerCreate isComponent={true} onResponse={onResponse} />
            </Modal>
        </>
    );
};

export default CreateCustomerModal;
