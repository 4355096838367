import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { CoreList } from '@packages';
import { IEntity } from '@models/organization.model';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { getCountryNameFromCode } from '@utils/getCountryNameFromCode';

const EntityDetail = ({ entity }: { entity?: IEntity | null }) => {
    const { t } = useTranslation();

    const entityInformationTableData = [
        {
            key: `${t('LEGAL_REGISTRATION_NAME_ARABIC')}:`,
            value: entity?.LegalRegistrationName,
        },
        {
            key: `${t('LEGAL_REGISTRATION_NAME_ENGLISH')}:`,
            value: entity?.LegalRegistrationNameEn,
        },
        {
            key: `${t('OTHER_SELLER_ID_TYPE')}:`,
            value: entity?.CompanyRegistrationScheme,
        },
        {
            key: `${t('OTHER_SELLER_ID')}:`,
            value: entity?.CompanyRegistrationNumber,
        },
    ];

    const addressInformationTableData = [
        {
            key: `${t('BUILDING_NUMBER')}:`,
            value: entity?.Address?.BuildingNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_NUMBER')}:`,
            value: entity?.Address?.AdditionalNumber || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('STREET_ARABIC')}:`,
            value: entity?.Address?.Street || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('STREET_ENGLISH')}:`,
            value: entity?.Address?.StreetEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_STREET_ARABIC')}:`,
            value: entity?.Address?.AdditionalStreet || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ADDITIONAL_STREET_ENGLISH')}:`,
            value: entity?.Address?.AdditionalStreetEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CITY_ARABIC')}:`,
            value: entity?.Address?.City || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CITY_ENGLISH')}:`,
            value: entity?.Address?.CityEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PROVINCE_ARABIC')}:`,
            value: entity?.Address?.ProvinceOrState || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PROVINCE_ENGLISH')}:`,
            value: entity?.Address?.ProvinceOrStateEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('DISTRICT_ARABIC')}:`,
            value: entity?.Address?.District || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('DISTRICT_ENGLISH')}:`,
            value: entity?.Address?.DistrictEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('POSTAL_CODE')}:`,
            value: entity?.Address?.PostalCode || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('COUNTRY')}:`,
            value: getCountryNameFromCode(entity?.Address?.CountryCode || DEFAULT_EMPTY_VALUE),
        },
    ];

    const contactInformationTableData = [
        {
            key: `${t('FIRST_NAME_ARABIC')}:`,
            value: entity?.Contact?.FirstName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('FIRST_NAME_ENGLISH')}:`,
            value: entity?.Contact?.FirstNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LAST_NAME_ARABIC')}:`,
            value: entity?.Contact?.LastName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LAST_NAME_ENGLISH')}:`,
            value: entity?.Contact?.LastNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME_ARABIC')}:`,
            value: entity?.Contact?.MiddleName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('MIDDLE_NAME_ENGLISH')}:`,
            value: entity?.Contact?.MiddleNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE_ARABIC')}:`,
            value: entity?.Contact?.JobTitle || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('JOB_TITLE_ENGLISH')}:`,
            value: entity?.Contact?.JobTitleEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('EMAIL')}:`,
            value: entity?.Email || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('FAX')}:`,
            value: entity?.Fax || DEFAULT_EMPTY_VALUE,
        },
    ];

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `${t('ENTITY_INFORMATION')}`,
            children: <CoreList listData={entityInformationTableData} />,
        },
        {
            key: '2',
            label: `${t('ADDRESS_INFORMATION')}`,
            children: <CoreList listData={addressInformationTableData} />,
        },
        {
            key: '3',
            label: `${t('CONTACT_INFORMATION')}`,
            children: <CoreList listData={contactInformationTableData} />,
        },
    ];
    return (
        <div className='round-box-shadow top-tab-wrapper'>
            <Tabs defaultActiveKey='1' items={items} />
        </div>
    );
};

export default EntityDetail;
