import React, { MouseEventHandler } from 'react';
import { Button } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import './CoreBackButton.scss';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ICoreBackButtonProps {
    text?: string;
    isFullWidth?: boolean,
    icon?: React.ReactNode;
    linkTo?: string;
    onClick?: (MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>) | undefined;
    isRound?: boolean;
    className?: string 
}

const CoreBackButton = (props: ICoreBackButtonProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const {
        text = `${t('GO_BACK')}`,
        isFullWidth = false,
        linkTo,
        icon = <ArrowLeftOutlined/>,
        onClick,
        isRound = true,
        className
    } = props;

    const goBack = () => {
        if (linkTo) {
            navigate(linkTo);
        } else {
            navigate(-1);
        }

    }

    return (
        <Button
            className={`core-back-button ${isFullWidth ? 'full-width' : ''} ${isRound ? '' : 'is-not-round'} ${className || ''}`}
            icon={icon}
            size="small"
            onClick={onClick ? onClick : goBack}
        >{text}</Button>

    )
};

export default CoreBackButton;