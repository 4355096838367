/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MoreOutlined } from '@ant-design/icons';
import { ConfirmationModal } from '@components';
import ActivateIcon from '@components/icons/ActivateIcon';
import DeactivateIcon from '@components/icons/DeactivateIcon';
import EditIcon from '@components/icons/EditIcon';
import UnArchiveIcon from '@components/icons/UnArchiveIcon';
import { ICustomer } from '@models/customer.model';
import { CustomerAPI } from '@service/customer.service';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IDropDownParams {
    customer?: ICustomer | null;
    getCustomerDetails: () => void;
}

const DropDownEdit: FC<IDropDownParams> = ({ customer, getCustomerDetails }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
    const [isUnArchiveModalOpen, setUnArchiveModalOpen] = useState(false);
    const [isActiveModalOpen, setActiveModalOpen] = useState(false);
    const [isDeactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='customer-dropdown-item'
                    onClick={() => {
                        navigate(`/customers/update/${customer?.ItemId}`);
                    }}
                >
                    <EditIcon />
                    <p>{t('EDIT')}</p>
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    className='customer-dropdown-item'
                    onClick={() =>
                        customer?.IsArchived === true
                            ? setUnArchiveModalOpen(true)
                            : setArchiveModalOpen(true)
                    }
                >
                    {customer?.IsArchived === true ? (
                        <>
                            <UnArchiveIcon />
                            <p>{t('UNARCHIVE')}</p>
                        </>
                    ) : (
                        <>
                            <UnArchiveIcon />
                            <p>{t('ARCHIVE')}</p>
                        </>
                    )}
                </div>
            ),
        },
        (customer?.IsArchived === false && {
            key: '3',
            label: (
                <div
                    className='customer-dropdown-item'
                    onClick={() => {
                        customer?.IsActive === true
                            ? setDeactivateModalOpen(true)
                            : setActiveModalOpen(true);
                    }}
                >
                    {customer?.IsActive === true ? (
                        <>
                            <DeactivateIcon />
                            <p>{t('DEACTIVATE')}</p>
                        </>
                    ) : (
                        <>
                            <ActivateIcon />
                            <p>{t('ACTIVATE')}</p>
                        </>
                    )}
                </div>
            ),
        }) ||
            (undefined as any),
    ].filter(Boolean);

    const onCustomerActive = async () => {
        if (!customer) {
            return;
        }
        setButtonLoading(true);
        const response = await CustomerAPI.activeCustomer(customer?.ItemId || '');
        setButtonLoading(false);

        if (response) {
            setActiveModalOpen(false);
            message.success(t('ACTIVATE_SUCCESSFUL'));
            getCustomerDetails();
        } else {
            message.error(t('ACTIVATE_FAILED'));
        }
    };
    const onCustomerDeactivate = async () => {
        if (!customer) {
            return;
        }
        setButtonLoading(true);
        const response = await CustomerAPI.deActiveCustomer(customer?.ItemId || '');
        setButtonLoading(false);
        if (response) {
            message.success(t('DEACTIVATE_SUCCESSFUL'));
            setDeactivateModalOpen(false);
            getCustomerDetails();
        } else {
            message.error(t('DEACTIVATE_FAILED'));
        }
    };
    const onCustomerArchive = async () => {
        if (!customer) {
            return;
        }
        setButtonLoading(true);
        const response = await CustomerAPI.archiveCustomer(customer?.ItemId || '');
        setButtonLoading(false);
        if (response) {
            message.success(t('ARCHIVE_SUCCESSFUL'));
            setArchiveModalOpen(false);
            getCustomerDetails();
        } else {
            message.error(t('ARCHIVE_FAILED'));
        }
    };
    const onCustomerUnArchive = async () => {
        if (!customer) {
            return;
        }
        setButtonLoading(true);
        const response = await CustomerAPI.unarchiveCustomer(customer?.ItemId || '');
        setButtonLoading(false);
        if (response) {
            message.success(t('UNARCHIVE_SUCCESSFUL'));
            setUnArchiveModalOpen(false);
            getCustomerDetails();
        } else {
            message.error(t('UNARCHIVE_FAILED'));
        }
    };
    return (
        <>
            <Dropdown
                menu={{ items }}
                trigger={['click']}
                placement='bottomLeft'
                arrow
                overlayClassName='customer-details-dropdown'
            >
                <Button className='ks-only-icon-button with-border' icon={<MoreOutlined />} />
            </Dropdown>
            <ConfirmationModal
                title={t('MOVE_TO_ARCHIVE')}
                description={t('ARCHIVE_STATUS_CHANGE')}
                buttonType={t('ARCHIVE')}
                isDeleteModalOpen={isArchiveModalOpen}
                handleDeleteOk={() => onCustomerArchive()}
                handleDeleteCancel={() => setArchiveModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title={t('MOVE_TO_UNARCHIVE')}
                description={t('UNARCHIVE_STATUS_CHANGE')}
                buttonType={t('UNARCHIVE')}
                isDeleteModalOpen={isUnArchiveModalOpen}
                handleDeleteOk={() => onCustomerUnArchive()}
                handleDeleteCancel={() => setUnArchiveModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title={t('DEACTIVATE_CUSTOMER')}
                description={t('DEACTIVATE_THIS_CUSTOMER')}
                buttonType={t('DEACTIVATE')}
                isDeleteModalOpen={isDeactivateModalOpen}
                handleDeleteOk={() => onCustomerDeactivate()}
                handleDeleteCancel={() => setDeactivateModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title={t('ACTIVATE_CUSTOMER')}
                description={t('ACTIVATE_THIS_CUSTOMER')}
                buttonType='Active'
                buttonText={t('ACTIVATE')}
                isDeleteModalOpen={isActiveModalOpen}
                handleDeleteOk={() => onCustomerActive()}
                handleDeleteCancel={() => setActiveModalOpen(false)}
                isLoading={isButtonLoading}
            />
        </>
    );
};

export default DropDownEdit;
