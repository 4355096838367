import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    ISubscribe,
    ISubscribePlanDetails,
    ISubscriptionConfiguration,
    ISubscriptionInvoicesPayload,
} from '@models/subscription.model';
import { ICreateTierPayload, ISingleTierPayload, IUpdateTierPayload } from '@models/billing.model';
import { processQuery } from '@utils/processQuery';

export const SubscribeAPI = {
    GetSubscription: async (): Promise<ISubscribePlanDetails> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Subscription`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result || {};
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    GetSubscriptionConfiguration: async (): Promise<ISubscriptionConfiguration> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/GetSubscriptionConfiguration`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);

            return response?.data || {};
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    AddTire: async (payload: ICreateTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/CreateTier`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateTierPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetTiers: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/GetTiers`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {});
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetTier: async (payload: ISingleTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/GetTier`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    Subscribe: async (payload: ISubscribe): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Subscribe`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },

    UpdateTire: async (payload: IUpdateTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/UpdateTier`;
        try {
            const response = await AXIOS_INSTANCE.post<IUpdateTierPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetCredits: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Credits`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscriptionInvoices: async (payload: ISubscriptionInvoicesPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Invoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Invoices;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscriptionInvoice: async (invoiceId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Invoice${processQuery({ invoiceId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetApplyCoupon: async (code: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CouponQuery/Apply${processQuery({ Code: code })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscriptionUsage: async (days: number): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Usage${processQuery({ days })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
