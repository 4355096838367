import React from 'react';

const PoundIcon = () => {
    return (
        <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='52' height='52' rx='26' fill='url(#paint0_linear_6969_71377)' />
            <rect
                x='0.5'
                y='0.5'
                width='51'
                height='51' 
                rx='25.5'
                stroke='white'
                strokeOpacity='0.2'
            />
            <path
                d='M22.5 28.6667C22.5 29.9553 23.5447 31 24.8333 31H27C28.3807 31 29.5 29.8807 29.5 28.5C29.5 27.1193 28.3807 26 27 26H25C23.6193 26 22.5 24.8807 22.5 23.5C22.5 22.1193 23.6193 21 25 21H27.1667C28.4553 21 29.5 22.0447 29.5 23.3333M26 19.5V21M26 31V32.5M36 26C36 31.5228 31.5228 36 26 36C20.4772 36 16 31.5228 16 26C16 20.4772 20.4772 16 26 16C31.5228 16 36 20.4772 36 26Z'
                stroke='url(#paint1_linear_6969_71377)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_6969_71377'
                    x1='0'
                    y1='0'
                    x2='52'
                    y2='52'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='white' stopOpacity='0.09' />
                    <stop offset='1' stopColor='white' stopOpacity='0' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_6969_71377'
                    x1='26'
                    y1='16'
                    x2='26'
                    y2='36'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default PoundIcon;
