import { CoreTitle } from '@packages';
import { FC } from 'react';
import { ITypesOfGeneratedInvoices } from '@models/dashboard.model';
import { IInvoiceTypeCount } from '@models/dashboard.model';
import { useTranslation } from 'react-i18next';
import './TypesOfGeneratedInvoices.scss';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { NoDataPieChart } from '@components';
import { Spin } from 'antd';

interface TypesOfGeneratedInvoicesProps {
    typesOfGeneratedInvoicesData: ITypesOfGeneratedInvoices;
    typesOfGeneratedInvoicesLoading: boolean;
}

const COLORS = ['#C5B5FD', '#7CD4FD', '#FEC84B', '#285FF6', '#85AFFF', '#98A2B3'];

interface LegendProps {
    payload?: any;
    t: (key: string) => string;
}

const renderLegend = (props: LegendProps) => {
    const { t, payload } = props;

    return (
        <div className='legend-wrapper'>
            <ul className='custom-legend'>
                {payload &&
                    payload.map((entry: any, index: any) => (
                        <li key={`item-${index}`}>
                            <div className='column-inner-div'>
                                <span
                                    className='dot'
                                    style={{ backgroundColor: entry.color }}
                                ></span>
                                {entry.value === 'Simplified Credit Note' && (
                                    <span className='legend-paragraph ml-1'>
                                        {' '}
                                        {t('SIMPLIFIED_CREDIT_NOTE')}
                                    </span>
                                )}
                                {entry.value === 'Simplified Debit Note' && (
                                    <span className='legend-paragraph ml-1'>
                                        {' '}
                                        {t('SIMPLIFIED_DEBIT_NOTE')}
                                    </span>
                                )}
                                {entry.value === 'Simplified Tax Invoice' && (
                                    <span className='legend-paragraph ml-1'>
                                        {' '}
                                        {t('SIMPLIFIED_TAX_INVOICE')}
                                    </span>
                                )}
                                {entry.value === 'Standard Tax Invoice' && (
                                    <span className='legend-paragraph ml-1'>
                                        {' '}
                                        {t('STANDARD_TAX_INVOICE')}
                                    </span>
                                )}
                                {entry.value === 'Standard Debit Note' && (
                                    <span className='legend-paragraph ml-1'>
                                        {' '}
                                        {t('STANDARD_DEBIT_NOTE')}
                                    </span>
                                )}
                                {entry.value === 'Standard Credit Note' && (
                                    <span className='legend-paragraph ml-1'>
                                        {' '}
                                        {t('STANDARD_CREDIT_NOTE')}
                                    </span>
                                )}
                            </div>
                            <div className='column-inner-div'>
                                <span className='count'>{entry.payload.value}</span>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

const TypesOfGeneratedInvoiceTooltip = (props: any) => {
    const { t } = useTranslation();
    const { active, payload } = props;

    if (active && payload && payload.length) {
        const singleData = payload[0].payload;

        const tooltipLabel: { [key: string]: string } = {
            'Simplified Tax Invoice': t('SIMPLIFIED_TAX_INVOICE'),
            'Simplified Credit Note': t('SIMPLIFIED_CREDIT_NOTE'),
            'Simplified Debit Note': t('SIMPLIFIED_DEBIT_NOTE'),
            'Standard Tax Invoice': t('STANDARD_TAX_INVOICE'),
            'Standard Credit Note': t('STANDARD_CREDIT_NOTE'),
            'Standard Debit Note': t('STANDARD_DEBIT_NOTE'),
        };

        const customLabel = tooltipLabel[singleData.type] || singleData.type;

        return (
            <div className='tax-code-tooltip-wrapper'>
                <p>
                    {customLabel}: {singleData.count}
                </p>
            </div>
        );
    }

    return null;
};

const TypesOfGeneratedInvoices: FC<TypesOfGeneratedInvoicesProps> = ({
    typesOfGeneratedInvoicesData,
    typesOfGeneratedInvoicesLoading,
}) => {
    const { t } = useTranslation();
    const invoicesData = typesOfGeneratedInvoicesData?.invoices ?? [];

    const totalCount = invoicesData.reduce((acc, invoice) => acc + invoice.count, 0);

    return (
        <div className='generative-invoices-wrapper'>
            <CoreTitle tag='h6' text={t('TYPES_OF_GENERATED_INVOICE')} />
            <ResponsiveContainer width={'100%'} height={295}>
                {typesOfGeneratedInvoicesLoading ? (
                    <div className='loading-spinner'>
                        <Spin size='large' />
                    </div>
                ) : totalCount === 0 ? (
                    <NoDataPieChart />
                ) : (
                    <PieChart>
                        <Pie
                            widths={200}
                            cy='50%'
                            innerRadius={80}
                            outerRadius={120}
                            data={typesOfGeneratedInvoicesData?.invoices}
                            fill='#8884d8'
                            paddingAngle={1}
                            nameKey='type'
                            dataKey='count'
                            width={20}
                            className='pie'
                        >
                            {typesOfGeneratedInvoicesData?.invoices.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip content={<TypesOfGeneratedInvoiceTooltip />} />
                        <Legend
                            align='right'
                            verticalAlign='top'
                            layout='vertical'
                            content={(props) => renderLegend({ ...props, t })}
                        />
                    </PieChart>
                )}
            </ResponsiveContainer>
        </div>
    );
};

export default TypesOfGeneratedInvoices;
