import { IProcessingSummary } from '@models/dashboard.model';
import _ from 'lodash';

export const summaryData: IProcessingSummary[] = [
    {
        title: 'Entity Id',
        id: '982dcb69-178a-4286-a1ea-16b284b34470',
        name: 'Entity 1',
        leaf: [
            {
                title: 'Zatca Status',
                id: 'CLEARED',
                name: 'CLEARED 1',
                leaf: [
                    {
                        title: 'Invoice Type Code',
                        id: 'simplified',
                        name: 'Simplified Note',
                        leaf: [
                            {
                                title: 'Invoice Type',
                                id: '388',
                                name: 'Tax Invoice',
                                count: 3,
                            },
                            {
                                title: 'Invoice Type',
                                id: '381',
                                name: 'Credit Note',
                                count: 2,
                            },
                        ],
                    },
                    {
                        title: 'Invoice Type Code',
                        id: 'standard',
                        name: 'Standard Note',
                        leaf: [
                            {
                                title: 'Invoice Type',
                                id: '388',
                                name: 'Tax Invoice',
                                count: 6,
                            },
                        ],
                    },
                ],
            },
            {
                title: 'Zatca Status',
                id: 'FAILED',
                name: 'FAILED 1',
                leaf: [
                    {
                        title: 'Invoice Type Code',
                        id: 'simplified',
                        name: 'Simplified Note',
                        leaf: [],
                    },
                    {
                        title: 'Invoice Type Code',
                        id: 'standard',
                        name: 'Standard Note',
                        leaf: [
                            {
                                title: 'Invoice Type',
                                id: '388',
                                name: 'Tax Invoice',
                                count: 1,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        title: 'Entity Id',
        id: '982dcb69-178a-4286-a1ea-16b284b34470',
        name: 'Entity 2',
        leaf: [
            {
                title: 'Zatca Status',
                id: 'CLEARED',
                name: 'CLEARED 2',
                leaf: [
                    {
                        title: 'Invoice Type Code',
                        id: 'simplified',
                        name: 'Simplified Note',
                        leaf: [
                            {
                                title: 'Invoice Type',
                                id: '388',
                                name: 'Tax Invoice',
                                count: 3,
                            },
                            {
                                title: 'Invoice Type',
                                id: '381',
                                name: 'Credit Note',
                                count: 2,
                            },
                        ],
                    },
                    {
                        title: 'Invoice Type Code',
                        id: 'standard',
                        name: 'Standard Note',
                        leaf: [
                            {
                                title: 'Invoice Type',
                                id: '388',
                                name: 'Tax Invoice',
                                count: 6,
                            },
                        ],
                    },
                ],
            },
            {
                title: 'Zatca Status',
                id: 'FAILED',
                name: 'FAILED 2',
                leaf: [
                    {
                        title: 'Invoice Type Code',
                        id: 'simplified',
                        name: 'Simplified Note',
                        leaf: [],
                    },
                    {
                        title: 'Invoice Type Code',
                        id: 'standard',
                        name: 'Standard Note',
                        leaf: [
                            {
                                title: 'Invoice Type',
                                id: '388',
                                name: 'Tax Invoice',
                                count: 1,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export function propagateCounts(summary?: IProcessingSummary[]): IProcessingSummary[] {
    if (!summary || !Array.isArray(summary)) {
        return undefined as any;
    }

    const newSummary = _.cloneDeep(summary);

    function computeCounts(node: IProcessingSummary): number {
        if (!node.leaf || node.leaf.length === 0) {
            node.leaf = [
                {
                    title: node.title,
                    id: node.id,
                    name: node.name,
                    count: node.count,
                    leaf: undefined,
                },
            ];
            return node.count || 0;
        }

        let total = 0;
        node.leaf.forEach((child) => {
            total += computeCounts(child);
        });

        node.count = total;
        return total;
    }

    newSummary.forEach((node: IProcessingSummary) => computeCounts(node));

    return newSummary;
}

export const propagateTotalCounts = (nodes: IProcessingSummary[]) => {
    if (!nodes) {
        return 0;
    }
    return nodes.reduce((acc, node) => acc + (node.count ?? 0), 0);
};

export const isLeafFoundInNodes = (nodes: IProcessingSummary[]) => {
    return nodes.some((node) => (node?.leaf && node.leaf.length > 0 ? true : false));
};
