import { CoreBackButton } from '@packages';
import { Modal } from 'antd';
import hljs from 'highlight.js';
// THIS IS VERY IMPORTANT.
import 'highlight.js/styles/github.css';

import './file-view.scss';
import { useEffect } from 'react';

interface IXmlViewProps {
    xmlText: string;
    isOpenModal: boolean;
    closeModal: () => void;
}

const XmlView = ({ closeModal, isOpenModal, xmlText }: IXmlViewProps) => {
    useEffect(() => {
        hljs.highlightAll();
    });
    return (
        <>
            <Modal
                open={isOpenModal}
                className='file-view-custom-modal'
                closable={false}
                maskClosable={false}
                footer={null}
            >
                <>
                    <CoreBackButton
                        onClick={closeModal}
                        text='Back'
                        className='file-view-back-button'
                    />
                    <div className='file-container'>
                        <pre>
                            <code className='xml'>{xmlText}</code>
                        </pre>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default XmlView;
