import { IOrganization } from '@models/organization.model';
import { NavigateFunction } from 'react-router-dom';

export const organizationRedirect = async (
    organizations: IOrganization[],
    navigate: NavigateFunction,
    defaultPath?: string,
) => {
    if (organizations?.length === 0 || !organizations) {
        navigate('/organization-initiate');
    } else if (organizations.length === 1 && !organizations[0]?.Workflow?.Completed) {
        if (organizations[0]?.Workflow?.CurrentIndex === 0) {
            navigate(
                `/organization-setup/${organizations[0].OrganizationId}/organization-information`,
            );
        }
        if (organizations[0]?.Workflow?.CurrentIndex === 1) {
            navigate(`/organization-setup/${organizations[0].OrganizationId}/entity`);
        }
        if (organizations[0]?.Workflow?.CurrentIndex === 2) {
            navigate(`/organization-setup/${organizations[0].OrganizationId}/invoice-phase`);
        }
        if (organizations[0]?.Workflow?.CurrentIndex === 3) {
            navigate(`/organization-setup/${organizations[0].OrganizationId}/device`);
        }
    } else if (defaultPath) {
        navigate(defaultPath);
    }
};
