import './SupportCard.scss';
import { CoreButton, CoreTitle } from '@packages';
import { ArrowRightOutlined } from '@ant-design/icons';

interface ISupportCardProps {
    title: string;
    subTitle: string;
    buttonLabel: string;
    buttonLink: string;
}

const SupportCard = (props: ISupportCardProps) => {
    const { title, subTitle, buttonLabel, buttonLink } = props;

    return (
        <div className='support-card-wrapper'>
            <div className='support-card-header'>
                <CoreTitle tag={'h4'} text={title} />
            </div>
            <p className='support-card-sub-title'>{subTitle}</p>
            <CoreButton
                type='secondary'
                text={buttonLabel}
                icon={<ArrowRightOutlined className='support-card-icon' />}
                className='support-card-actions'
                linkTo={`/${buttonLink}`}
                size='small'
            />
        </div>
    );
};
export default SupportCard;
