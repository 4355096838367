import React, { useState } from 'react';
import { Col, Row, Tabs, TabsProps, Modal } from 'antd';
import { CoreBackButton, CoreTitle, CoreButton, CoreList } from '@packages';
import { RevokeCertificate } from '@components';
import './CsidDetails.scss';

const csidInformationTableData = [
    {
        key: 'Organization ID:',
        value: '8bf2556c-1cle-4ba2-aa2b-7bcc0878339a',
    },
    {
        key: 'Organization Name:',
        value: 'Hashtag Solutions',
    },
    {
        key: 'Entity ID:',
        value: '--',
    },
    {
        key: 'Entity Name:',
        value: '--',
    },
    {
        key: 'CSR Certificate Encoded:',
        value:
            'dDOVEBd1NURUxNOWtHOTFVRUJoTUNRVmd4RERBSOJnTIZCOXNNOTESVFZER\n' +
            '4RHEKWExkb29xZkU2dHNMcDk1RkdUUHIwK1BIT29CbCt6UndrZEZDeGNISW9JR3\n' +
            'ZNSUdzQmdrcWhraUc5dzBC01E0eApnWiR3Z1pzdOIRWUpLd1ICQkFH0054UUNC\n' +
            'OHdIUVIL01pJbWlaUHkKTEdROkFRd1 BNekF3TkRNM01UTTFOekF3TURBek1RMH\n' +
            'UNBVEUgUkVRVUVTVCOtLSOtCq==',
    },
    {
        key: 'CSID:',
        value:
            'dDOVEBd1NURUxNOWtHOTFVRUJoTUNRVmd4RERBSOJnTIZCOXNNOTESVFZER\n' +
            '4RHEKWExkb29xZkU2dHNMcDk1RkdUUHIwK1BIT29CbCt6UndrZEZDeGNISW9JR3\n' +
            'ZNSUdzQmdrcWhraUc5dzBC01E0eApnWiR3Z1pzdOIRWUpLd1ICQkFH0054UUNC\n' +
            'OHdIUVIL01pJbWlaUHkKTEdROkFRd1 BNekF3TkRNM01UTTFOekF3TURBek1RMH\n' +
            'UNBVEUgUkVRVUVTVCOtLSOtCq==',
    },
    {
        key: 'Prod CSID',
        value:
            'dDOVEBd1NURUxNOWtHOTFVRUJoTUNRVmd4RERBSOJnTIZCOXNNOTESVFZER\n' +
            '4RHEKWExkb29xZkU2dHNMcDk1RkdUUHIwK1BIT29CbCt6UndrZEZDeGNISW9JR3\n' +
            'ZNSUdzQmdrcWhraUc5dzBC01E0eApnWiR3Z1pzdOIRWUpLd1ICQkFH0054UUNC\n' +
            'OHdIUVIL01pJbWlaUHkKTEdROkFRd1 BNekF3TkRNM01UTTFOekF3TURBek1RMH\n' +
            'UNBVEUgUkVRVUVTVCOtLSOtCq==',
    },
];

const items: TabsProps['items'] = [
    {
        key: '1',
        label: `Certificate Information`,
        children: <CoreList listData={csidInformationTableData} />,
    },
];

const CsidDetails = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (e: any) => {
        setIsModalOpen(true);
    };

    const handleYes = () => {
        setIsModalOpen(false);
    };

    const handleNo = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='container csid-details'>
            <CoreBackButton />
            <Row justify='space-between'>
                <Col>
                    <CoreTitle tag='h1' text='Certificate detail' />
                    <div>Create Date: 10/10/2022</div>
                </Col>
                <Col>
                    <CoreButton
                        type='primaryHover'
                        text='Revoke a certificate'
                        onClick={showModal}
                    />
                    <Modal
                        centered
                        open={isModalOpen}
                        onOk={handleYes}
                        onCancel={handleNo}
                        width={400}
                        footer={null}
                    >
                        <RevokeCertificate handleYes={handleYes} handleNo={handleNo} />
                    </Modal>
                </Col>
            </Row>

            <div className='round-box-shadow top-tab-wrapper margin-top-30'>
                <Tabs defaultActiveKey='1' items={items} />
            </div>
        </div>
    );
};

export default CsidDetails;
