import { Col, Radio, Row, Space } from 'antd';
import { FC, Key } from 'react';
import {
    Control,
    Controller,
    FieldErrors,
    UseFormClearErrors,
    UseFormSetValue,
} from 'react-hook-form';
import GenericErrorMessage, { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { CustomRules } from '@models/rules.model';
import { isRequiredValue } from '@utils/errorMessageOption';

interface IInlineGenericRadioParams {
    control: Control<any, any>;
    errors: FieldErrors<any>;
    clearErrors?: UseFormClearErrors<any>;
    level: string;
    dataKey: string;
    options: { value: Key; label: string; disabled?: boolean }[];
    setFormValue: UseFormSetValue<any>;
    onChange?: (value: any) => void;
    rules?: CustomRules;
    direction?: 'horizontal' | 'vertical';
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    children?: React.ReactNode;
    hideError?: boolean;
    gutter?: number;
    marginBottom?: number;
    tooltip?: string;
    errorMessageSize?: ErrorMessageSize;
}

const InlineGenericRadio: FC<IInlineGenericRadioParams> = ({
    control,
    errors,
    level,
    dataKey,
    rules,
    options,
    setFormValue,
    onChange,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    clearErrors,
    children,
    hideError,
    gutter = 130,
    marginBottom = 15,
    tooltip,
    direction = 'horizontal',
    errorMessageSize,
}) => {
    const { t } = useTranslation();

    return (
        <Row
            gutter={gutter}
            align='middle'
            style={{ marginBottom: marginBottom }}
            className='generic-radio-wrapper'
        >
            {showLevel && (
                <Col span={levelCol}>
                    <label className='general-label'>
                        {t(level)}
                        {rules?.required && <span className='required-field'>*</span>}
                        {tooltip && <GenericTooltip text={tooltip} />}
                    </label>
                </Col>
            )}
            <Col span={inputCol}>
                <div>
                    <Controller
                        render={({ field }) => (
                            <Radio.Group
                                {...field}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setFormValue &&
                                        setFormValue(dataKey, value, {
                                            shouldValidate: true,
                                        });
                                    onChange && onChange(value);
                                    clearErrors && clearErrors();
                                }}
                            >
                                <Space direction={direction}>
                                    {options.map((option) => (
                                        <Radio
                                            key={option.value}
                                            value={option.value}
                                            disabled={option?.disabled ?? false}
                                        >
                                            {option.label}
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        )}
                        control={control}
                        name={dataKey}
                        rules={{ ...rules, required: isRequiredValue(rules) }}
                    />
                    {!hideError && (
                        <GenericErrorMessage
                            errors={errors}
                            dataKey={dataKey}
                            rules={rules}
                            level={level}
                            errorMessageSize={errorMessageSize}
                        />
                    )}
                    {children}
                </div>
            </Col>
        </Row>
    );
};

export default InlineGenericRadio;
