import { CopyOutlined } from '@ant-design/icons';
import { Button, Input, message, Tooltip } from 'antd';
import React from 'react';

export interface IInputKey {
    apiKey: string;
}

export default function InputKey({ apiKey }: IInputKey) {
    const handleCopy = () => {
        const textarea = document.createElement('textarea');
        textarea.value = apiKey;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of the page
        textarea.style.left = '-9999px'; // Hide the textarea off-screen
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            message.error('Failed to copy API key (fallback method)');
        } finally {
            document.body.removeChild(textarea); // Clean up the temporary textarea
        }
    };
    return (
        <Input
            className='general-input'
            name='apikey'
            value={apiKey}
            readOnly
            suffix={
                <Tooltip title='Copy API Key'>
                    <Button
                        onClick={handleCopy}
                        icon={<CopyOutlined style={{ fontSize: 14 }} />}
                        color='default'
                        type='link'
                        size='small'
                    />
                </Tooltip>
            }
        />
    );
}
