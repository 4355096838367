import { IInvoiceLinesItem, ITaxExemptions } from '@models/invoice.model';

export function getTaxExemptions(invoices: IInvoiceLinesItem[]): ITaxExemptions[] {
    return invoices.map(({ Reason, ReasonCode, ReasonEn, TaxCategory }) => ({
        TaxCategory: TaxCategory || '',
        TaxExemptionReason: Reason || '',
        TaxExemptionReasonCode: ReasonCode || '',
        TaxExemptionReasonEn: ReasonEn || '',
    }));
}
