import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const GenericTooltip: FC<{ text: string }> = ({ text }) => {
    const { t } = useTranslation();
    return (
        <Tooltip
            title={t(text)}
            className='generic-tooltip'
            overlayStyle={{ backgroundColor: 'rgba(102, 112, 133, 0.85)', borderRadius: '4px' }}
        >
            <QuestionCircleOutlined
                style={{ fontSize: '12px', position: 'absolute', marginTop: '3px' }}
            />
        </Tooltip>
    );
};
export default GenericTooltip;
