import { Col, Image, Row, Tabs, TabsProps } from 'antd';
import './SolutionSupport.scss';
import ValidateTaxTab from '../validate-tax-tab/ValidateTaxTab';
import validate_image from '../../assets/Validate tax IDs.png';

const SolutionSupport = () => {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Validate tax IDs',
            children: <ValidateTaxTab />,
        },
        {
            key: '2',
            label: 'Apply the right taxes',
            children:
                'Some of the largest online marketplaces use E-Invoice Solutions to power their invoicing needs. Whether it is for their own intermediary services or to support their marketplace sellers with compliant invoicing.',
        },
        {
            key: '3',
            label: 'Comply with e-invoicing globally',
            children:
                'Some of the largest online marketplaces use E-Invoice Solutions to power their invoicing needs. Whether it is for their own intermediary services or to support their marketplace sellers with compliant invoicing.',
        },
        {
            key: '4',
            label: 'Issue compliant tax invoice',
            children: <ValidateTaxTab />,
        },
        {
            key: '5',
            label: 'Submit erroe-free tax invoice',
            children: <ValidateTaxTab />,
        },
        {
            key: '6',
            label: 'Comply with data-sharing regulations around the world',
            children: <ValidateTaxTab />,
        },
    ];

    return (
        <div className='solution-support-wrapper'>
            <h1 className='solution-support-title'>
                E-Invoice solution supports tax compliance at the transaction level and simplifies
                your global tax landscape
            </h1>
            <div className='solution-support-tab-section'>
                <Row gutter={[0, 26]} align='middle'>
                    <Col md={24} lg={13}>
                        <Tabs
                            defaultActiveKey='1'
                            items={items}
                            tabPosition='left'
                            className='support-tabs'
                        />
                    </Col>
                    <Col md={24} lg={11}>
                        <div className='solution-support-image'>
                            <Image src={validate_image} preview={false} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SolutionSupport;
