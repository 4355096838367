import './UserAccount.scss';
import { CoreFileUploadDragger } from '@packages';
import { Row, Col } from 'antd';
import { useState } from 'react';
import { useAppSelector } from '@store/hooks';
import { selectLoggedUserInfo } from '@store/slices/userSlice';
import { IFileInfo } from 'packages/CoreFileUploader/CoreFileUploader';
import avatar from '@assets/images/Avatar.png';
import { useTranslation } from 'react-i18next';
import { UserInfoForm } from "../../index";

const UserAccount = () => {
    const { t } = useTranslation();
    const [fileID, setFileID] = useState<string>();
    const { userDetails } = useAppSelector(selectLoggedUserInfo);
    const [localFile, setLocalFile] = useState<string>();
    const pictureUrl = localFile || userDetails?.DisplayPictureUrl;
    const onFileUpload = ({ uploadId, url }: IFileInfo) => {
        setLocalFile(url);
        setFileID(uploadId);
    };
    return (
        <div className='setting-content-wrapper'>
            <div className='profile-content'>
                <div className='title margin-top-14'>{t('PROFILE')}</div>
                <div className='margin-top-6'>{t('THIS_WILL_BE_DISPLAYED_ON_THE_PROFILE')}</div>
                <Row gutter={80} className='margin-top-20'>
                    <Col span={2}>
                        <div className='profile-photo'>
                            <img src={pictureUrl || avatar} alt='Profile' />
                        </div>
                    </Col>
                    <Col span={16} className='margin-left-10'>
                        <CoreFileUploadDragger
                            uploadHint='SVG, PNG, JPG or GIF (max. 800x400px)'
                            onFileUpload={onFileUpload}
                        />
                    </Col>
                </Row>
            </div>
            <div className='margin-top-20 margin-left-109'>
                <UserInfoForm fileID={fileID} fileUrl={localFile} />
            </div>
        </div>
    );
};
export default UserAccount;
