import './AdminTemplateDetails.scss';
import { CoreBackButton, CoreButton, CoreList, CoreTitle } from '@packages';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import { getAdminSingleTemplate, singleTemplateData } from '@store/slices/adminTemplateSlice';
import { useParams } from 'react-router-dom';
import { getTemplateInformation } from '@utils/helper';
import { useTranslation } from 'react-i18next';
import PdfView from '@components/file-view/PdfView';
import { fileStorageAPI } from '@service/file-storage.service';

type CoreList = { key: string; value: string }[];

const AdminTemplateDetails = () => {
    const { t } = useTranslation();
    const { TemplateId } = useParams();
    const dispatch = useAppDispatch();
    const [templateInfoData, setTemplateInfo] = useState<CoreList>([]);
    const [pdfUrl, setPDFUrl] = useState<string>();
    const [isPdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
    const [isLoadingTemplate, setLoadingTemplate] = useState<boolean>(false);

    const { isLoading, data: template } = useAppSelector(singleTemplateData);

    useEffect(() => {
        dispatch(getAdminSingleTemplate({ TemplateId: TemplateId || '' }));
    }, []);

    useEffect(() => {
        if (template) {
            const templateInfo = getTemplateInformation(template, t);
            setTemplateInfo(templateInfo);
        }
    }, [template]);

    const readPdfFile = async () => {
        if (!template?.PreviewTemplatePdfId) {
            return;
        }
        if (pdfUrl) {
            setPdfModalOpen(true);
            return;
        }
        setLoadingTemplate(true);
        const response = await fileStorageAPI.getFileUrl(template?.PreviewTemplatePdfId);
        setPDFUrl(response);
        setPdfModalOpen(true);
        setLoadingTemplate(false);
    };

    return (
        <div className='template-details-wrapper'>
            <CoreBackButton text='' />
            <div className='template-details-inner-wrapper'>
                <div className='template-details-header'>
                    <CoreTitle tag='h4' text='Template Details' />
                    <CoreButton
                        type='primary'
                        text='Preview PDF'
                        size='small'
                        onClick={readPdfFile}
                        loading={isLoadingTemplate}
                    />
                </div>
            </div>

            <div className='template-details-content'>
                <CoreList listData={templateInfoData} />
            </div>

            {pdfUrl && (
                <PdfView
                    url={pdfUrl}
                    isOpenModal={isPdfModalOpen}
                    closeModal={() => setPdfModalOpen(false)}
                />
            )}
        </div>
    );
};

export default AdminTemplateDetails;
