import React, { ReactNode } from 'react';
import { Col, Row } from "antd";
import './CoreList.scss';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const dummyData = (t: TFunction<'translation', undefined>) => [
    {
        key: `${t('LEGAL_REGISTRATION_NAME')}:`,
        value: "Hashtag Solutions",
    },
    {
        key: `${t('VAT_REGISTRATION_NUMBER')}:`,
        value: "316345678903263",
    },
    {
        key: `${t('COMPANY_REGISTRATION_NUMBER')}:`,
        value: "1222122",
    },
    {
        key: `${t('LEGAL_REGISTRATION_NAME_ARABIC')}:`,
        value: "VAT",
    },
]

interface IListData {
    key: string;
    value?: string | number | ReactNode | undefined;
}

interface ICoreListProps {
    listData: IListData[];
    leftCol?: number;
    rightCol?: number;
}

const CoreList = (props: ICoreListProps) => {
    const { t } = useTranslation();
    const { listData = dummyData(t), leftCol = 8, rightCol = 16 } = props
    return (
        <div className="list-wrapper">
            {listData.length && listData.map((data, index) => {
                return (
                    <div className="list-item" key={index}>
                        <Row>
                            <Col span={leftCol}>
                                <div className="list-item-left">{data.key}</div>
                            </Col>

                            <Col span={rightCol}>
                                <div className="list-item-right">{data.value}</div>
                            </Col>
                        </Row>
                    </div>
                )
            })}

        </div>
    );
};

export default CoreList;
