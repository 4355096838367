import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const CloneSvg = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5592_9430)'>
            <path
                d='M4.1665 12.5C3.38993 12.5 3.00165 12.5 2.69536 12.3731C2.28698 12.2039 1.96253 11.8795 1.79337 11.4711C1.6665 11.1648 1.6665 10.7765 1.6665 9.99996V4.33329C1.6665 3.39987 1.6665 2.93316 1.84816 2.57664C2.00795 2.26304 2.26292 2.00807 2.57652 1.84828C2.93304 1.66663 3.39975 1.66663 4.33317 1.66663H9.99984C10.7764 1.66663 11.1647 1.66663 11.471 1.79349C11.8794 1.96265 12.2038 2.28711 12.373 2.69549C12.4998 3.00177 12.4998 3.39006 12.4998 4.16663M10.1665 18.3333H15.6665C16.5999 18.3333 17.0666 18.3333 17.4232 18.1516C17.7368 17.9918 17.9917 17.7369 18.1515 17.4233C18.3332 17.0668 18.3332 16.6 18.3332 15.6666V10.1666C18.3332 9.23321 18.3332 8.7665 18.1515 8.40998C17.9917 8.09637 17.7368 7.8414 17.4232 7.68162C17.0666 7.49996 16.5999 7.49996 15.6665 7.49996H10.1665C9.23308 7.49996 8.76637 7.49996 8.40985 7.68162C8.09625 7.8414 7.84128 8.09637 7.68149 8.40998C7.49984 8.7665 7.49984 9.23321 7.49984 10.1666V15.6666C7.49984 16.6 7.49984 17.0668 7.68149 17.4233C7.84128 17.7369 8.09625 17.9918 8.40985 18.1516C8.76637 18.3333 9.23308 18.3333 10.1665 18.3333Z'
                stroke='#667085'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_5592_9430'>
                <rect width='20' height='20' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
const CloneIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={CloneSvg} {...props} />
);

export default CloneIcon;
