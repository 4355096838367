import React from 'react';
import { Tabs, TabsProps } from 'antd';
import './PreferenceInvoices.scss';
import PreferenceInvoicesGeneral from '../../preference/preference-invoices-general/PreferenceInvoicesGeneral';
import { CoreTitle } from '@packages';

const onChange = (key: string) => {
    console.log(key);
};

const items: TabsProps['items'] = [
    {
        key: '1',
        label: `General`,
        children: <PreferenceInvoicesGeneral />,
    },
];

const PreferenceInvoices = () => {
    return (
        <div className='preference-invoice-wrapper'>
            <CoreTitle tag='h4' text='Invoice Templates' />
            <Tabs
                className='preference-invoice-tabs'
                defaultActiveKey='1'
                items={items}
                onChange={onChange}
            />
        </div>
    );
};

export default PreferenceInvoices;
