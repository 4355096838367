export const UNIT_CODE_OPTIONS: { value: string; label: string }[] = [
    {
        value: 'mil',
        label: 'mil',
    },
    {
        value: 'cm',
        label: 'centimeter',
    },
    {
        value: 'mm',
        label: 'millimeter',
    },
    {
        value: 'km',
        label: 'kilometer',
    },
    {
        value: 'in',
        label: 'inch',
    },
    {
        value: 'ft',
        label: 'foot',
    },
    {
        value: 'yd',
        label: 'yard',
    },
    {
        value: 'm²',
        label: 'square metre',
    },
    {
        value: 'km²',
        label: 'square kilometer',
    },
    {
        value: 'in²',
        label: 'square inch',
    },
    {
        value: 'ft²',
        label: 'square foot',
    },
    {
        value: 'acre',
        label: 'acre',
    },
    {
        value: 'm³',
        label: 'cubic metre',
    },
    {
        value: 'Ml',
        label: 'megalitre',
    },
    {
        value: 'l',
        label: 'litre',
    },
    {
        value: 'ml',
        label: 'milliliter',
    },
    {
        value: 'kn',
        label: 'knot',
    },
    {
        value: 'Hz',
        label: 'hertz',
    },
    {
        value: 'kHz',
        label: 'kilohertz',
    },
    {
        value: 'mHz',
        label: 'milihertz',
    },
    {
        value: 'm',
        label: 'metre',
    },
    {
        value: 'kg',
        label: 'kilogram',
    },
    {
        value: 'g',
        label: 'gram',
    },
    {
        value: 'lb',
        label: 'pound',
    },
];
