import React from 'react';
import './ClientSection.scss';
import client_one from '../../assets/client-1.png';
import { Image } from 'antd';

const ClientSection = () => {
    return (
        <div className='client-section-wrapper'>
            <div className='client-section-inner-wrapper'>
                <p className='client-section-heading'>
                    Trusted by the world’s leading digital companies
                </p>
                <div className='client-images'>
                    <Image preview={false} src={client_one} alt='client one' />
                    <Image preview={false} src={client_one} alt='client two' />
                    <Image preview={false} src={client_one} alt='client three' />
                    <Image preview={false} src={client_one} alt='client four' />
                    <Image preview={false} src={client_one} alt='client five' />
                    <Image preview={false} src={client_one} alt='client siz' />
                    <Image preview={false} src={client_one} alt='client seven' />
                </div>
            </div>
        </div>
    );
};

export default ClientSection;
