import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import './InvoiceListTable.scss';
import type { ColumnsType } from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router-dom';
import { IInvoicePagination, IInvoicePayload, ISingleInvoice } from '@models/invoice.model';
import {
    DEFAULT_EMPTY_VALUE,
    DEFAULT_LONG_DELAY_TIME,
    DEFAULT_TABLE_SIZE,
} from '@config/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getInvoices, selectInvoices } from '@store/slices/einvoiceSlice';
import { UtcToLocalDate } from '@utils/date';
import { CoreStatus } from '@packages';
import { GET_INVOICE_TYPE_CODE_BY_TYPE_CODE } from '@utils/InvoiceUtils';
import { displayCurrency } from '@utils/displayCurrency';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import { debounce } from 'lodash';

const columns = (t: TFunction<'translation', undefined>): ColumnsType<ISingleInvoice> => [
    {
        title: t('INVOICE_NUMBER'),
        dataIndex: 'InvoiceId',
        render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
    },
    {
        title: t('CREATED_DATE'),
        dataIndex: 'CreatedAt',
        render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
    },
    {
        title: t('INVOICE_STATUS'),
        dataIndex: 'IsXMLFileGenerated',
        render: (text: string, data) =>
            data.IsXmlFileGenerated ? (
                <CoreStatus type='success' status={t('GENERATED')} />
            ) : (
                <CoreStatus type='inactive' status={t('FAILED')} />
            ),
    },
    {
        title: t('INVOICE_TYPE'),
        dataIndex: 'InvoiceTypeCode',
        render: (InvoiceTypeCode: string, data) =>
            t(
                GET_INVOICE_TYPE_CODE_BY_TYPE_CODE(data.InvoiceType, data.InvoiceTypeCode)
                    ?.displayKey || '',
            ),
    },
    {
        title: t('ZATCA_STATUS'),
        dataIndex: 'IsXMLFileGenerated',
        render: (text: string, data) =>
            data?.ZatcaStatus?.toUpperCase() == 'CLEARED' ? (
                <CoreStatus type='success' status={t('CLEARED')} />
            ) : data?.ZatcaStatus?.toUpperCase() === 'FAILED' ? (
                <CoreStatus type='failed' status={t('FAILED')} />
            ) : data?.ZatcaStatus?.toUpperCase() === 'CLEARED_WITH_WARNING' ? (
                <CoreStatus type='warning' status={t('CLEARED_WITH_WARNING')} />
            ) : data?.ZatcaStatus?.toUpperCase() === 'PENDING' ? (
                <CoreStatus type='pending' status={t('PENDING')} />
            ) : (
                <CoreStatus type='basic' status={data.ZatcaStatus} />
            ),
    },
    {
        title: t('TOTAL_AMOUNT'),
        dataIndex: 'TaxInclusiveAmount',
        render: (TaxInclusiveAmount, { DocumentCurrencyCode }) => (
            <span>
                {displayCurrency({
                    Code: DocumentCurrencyCode || GET_DEFAULT_CURRENCY_CODE,
                    Amount: TaxInclusiveAmount || 0,
                })}
            </span>
        ),
    },
    {
        title: t('TAX_AMOUNT'),
        dataIndex: 'TaxAmount',
        render: (TaxAmount, { TaxCurrencyCode }) => (
            <span>
                {displayCurrency({
                    Code: TaxCurrencyCode || GET_DEFAULT_CURRENCY_CODE,
                    Amount: TaxAmount || 0,
                })}
            </span>
        ),
    },
];

interface IInvoiceListTableProps {
    filterData?: Partial<IInvoicePayload>;
}

const InvoiceListTable = ({ filterData }: IInvoiceListTableProps) => {
    const { t } = useTranslation();
    const [pageInfo, setPageInfo] = useState<IInvoicePagination>({
        PageLimit: 10,
        PageNumber: 1,
    });
    const { state }: { state?: { delay: boolean } } = useLocation();
    const [isDelayLoading, setDelayLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { data, status, totalCount } = useAppSelector(selectInvoices);

    const handleRowClick = (record: any) => {
        navigate(`/e-invoices/details/${record.ItemId}`);
    };

    const debouncedGetAllInvoices = useCallback(
        debounce(() => {
            const payload: IInvoicePayload = pageInfo;
            const invoiceFilterData = filterData as IInvoicePayload;
            dispatch(
                getInvoices({
                    payload: { ...payload, ...invoiceFilterData },
                }),
            );
        }, 300), // adjust debounce delay as needed
        [pageInfo, filterData, dispatch]
    );

    useEffect(() => {
        if (state?.delay) {
            setDelayLoading(true);
            setTimeout(() => {
                debouncedGetAllInvoices();
                setDelayLoading(false);
            }, DEFAULT_LONG_DELAY_TIME);
            return;
        }
        debouncedGetAllInvoices();
        return () => {
            debouncedGetAllInvoices.cancel();
        };
    }, [pageInfo, filterData, state, debouncedGetAllInvoices]);

    return (
        <div className='ksa-table-wrapper table-auto-scroll-with-header'>
            <Table
                columns={columns(t)}
                dataSource={data}
                rowClassName='cursor-pointer'
                rowKey={'CreatedAt'}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => handleRowClick(record), // click row
                    };
                }}
                scroll={{ x: 600 }}
                loading={status === 'loading' ? true : isDelayLoading === true ? true : false}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: totalCount,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${t('OF')} ${total} ${t('ITEMS')}`,
                    onChange: (PageNumber, PageLimit) => {
                        setPageInfo({ PageNumber, PageLimit });
                    },
                    current: pageInfo.PageNumber,
                }}
            />
        </div>
    );
};

export default InvoiceListTable;
