import { template1PDF } from '@assets/pdf';
import { FC } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './TemplatePreview.scss';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import React from 'react';

type ITemplatePreviewProps = {
    //
};

const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const TemplatePreview: FC<ITemplatePreviewProps> = () => {
    console.warn('templage render');
    return (
        <div className='template-pdf-container'>
            <Document file={{ url: template1PDF }} options={options} className='pdf-preview'>
                <Page pageNumber={1} scale={0.87} />
            </Document>
        </div>
    );
};
export const MemoizeTemplatePreview = React.memo(TemplatePreview);
