import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const UnArchiveSvg = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M3.33329 6.66383C3.19662 6.6603 3.09737 6.65239 3.00814 6.63464C2.34699 6.50313 1.83016 5.9863 1.69865 5.32515C1.66663 5.16415 1.66663 4.97055 1.66663 4.58333C1.66663 4.19612 1.66663 4.00251 1.69865 3.84152C1.83016 3.18037 2.34699 2.66354 3.00814 2.53202C3.16914 2.5 3.36275 2.5 3.74996 2.5H16.25C16.6372 2.5 16.8308 2.5 16.9918 2.53202C17.6529 2.66354 18.1698 3.18037 18.3013 3.84152C18.3333 4.00251 18.3333 4.19612 18.3333 4.58333C18.3333 4.97055 18.3333 5.16415 18.3013 5.32515C18.1698 5.9863 17.6529 6.50313 16.9918 6.63464C16.9025 6.65239 16.8033 6.6603 16.6666 6.66383M3.33329 6.66667H16.6666V13.5C16.6666 14.9001 16.6666 15.6002 16.3941 16.135C16.1545 16.6054 15.772 16.9878 15.3016 17.2275C14.7668 17.5 14.0668 17.5 12.6666 17.5H7.33329C5.93316 17.5 5.2331 17.5 4.69832 17.2275C4.22791 16.9878 3.84546 16.6054 3.60578 16.135C3.33329 15.6002 3.33329 14.9001 3.33329 13.5V6.66667Z'
            stroke='#667085'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.5 9.16675L10 11.6667M10 11.6667L7.5 14.1667M10 11.6667L7.5 9.16675M10 11.6667L12.5 14.1667'
            stroke='#667085'
            strokeWidth='1.67'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
const UnArchiveIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={UnArchiveSvg} {...props} />
);

export default UnArchiveIcon;
