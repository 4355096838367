import { Table, Progress, Tooltip } from 'antd';
import './AvailableCreditsTable.scss';
import { CoreButton, CoreTitle } from '@packages';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import { getCredits } from '@store/slices/subscriptionOverviewSlice';
import { ICredit, ICreditTableData } from '@models/subscription.model';
import { UtcToLocalDate } from '@utils/date';
import { ColumnsType } from 'antd/es/table';
import AddCuponModal from './AddCouponModal';

const AvailableCreditsTable = () => {
    const dispatch = useAppDispatch();
    const { credits, isLoading } = useAppSelector((state) => state.subscriptionOverview);
    const [tableData, setTableData] = useState<any[]>([]);

    const [isAddCuponModalVisible, setIsAddCuponModalVisible] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getCredits());
    }, []);

    useEffect(() => {
        if (credits.length > 0) {
            const formattedData = mapCreditsToTableData(credits);
            setTableData(formattedData);
        }
    }, [credits]);

    const mapCreditsToTableData = (credits: ICredit[]) => {
        return credits.map((credit, index) => {
            const availablePercentage = (credit.Available / credit.TotalAmount) * 100;
            return {
                key: index,
                description: credit.Description,
                dateAdded: UtcToLocalDate(credit.DateAdded),
                activeDates: UtcToLocalDate(credit.Activated),
                totalAmount: credit.TotalAmount,
                used: credit.Used,
                available: credit.Available,
                availablePercentage: availablePercentage.toFixed(2),
                expires: UtcToLocalDate(credit.Expires),
            };
        });
    };

    const columns: ColumnsType<ICreditTableData> = [
        {
            title: 'Details',
            dataIndex: 'description',
            render: (description: string) => (
                <Tooltip title={description}>
                    <span className='cursor'>
                        {description.length > 10 ? `${description.slice(0, 10)}...` : description}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: 'Date Added',
            dataIndex: 'dateAdded',
        },
        {
            title: 'Active Dates',
            dataIndex: 'activeDates',
            render: (available: string, data: ICreditTableData) => (
                <div className='column-layout'>
                    <span>{available}</span>
                    <span>(EXPIRES {data.expires})</span>
                </div>
            ),
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
        },
        {
            title: 'Used',
            dataIndex: 'used',
            render: (text: string) => <div>{Number(text).toFixed(2)}</div>,
        },
        {
            title: 'Available',
            dataIndex: 'available',
            render: (available: string, data: ICreditTableData) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{available}</span>
                    <Progress
                        percent={data.availablePercentage}
                        size='small'
                        showInfo={false}
                        strokeColor='#AAC7B5'
                        trailColor='#a8a8a8'
                        className='progress-bar'
                    />
                </div>
            ),
        },
    ];

    return (
        <div className='round-box-shadow available-credits-table'>
            <div className='available-credits-table-header'>
                <CoreTitle tag='h6' text='Available Credits' />
                <CoreButton
                    text='Apply Code'
                    size='small'
                    onClick={() => setIsAddCuponModalVisible(true)}
                />
            </div>
            <Table
                columns={columns}
                dataSource={tableData}
                loading={isLoading}
                pagination={false}
                scroll={{ x: 600 }}
            />
            <p className='disclaimer'>
                *We will draw down on the credits in the order that it appears (from top to bottom).
            </p>
            <AddCuponModal
                isVisible={isAddCuponModalVisible}
                onClose={() => setIsAddCuponModalVisible(false)}
                onConfirm={() => setIsAddCuponModalVisible(false)}
            />
        </div>
    );
};

export default AvailableCreditsTable;
