import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { AxiosError } from 'axios';
import {
    IActionDeletePayload,
    IActionIdPayload,
    IActionIdsGetPayload,
    IActionIdsResult,
    IActionPayload,
    IActionResult,
} from '@models/action.model';
import { EResourceType } from '@utils/resourceService';

export const ActionAPI = {
    getResource: async (fromService: EResourceType) => {
        let url = '';
        if (fromService === EResourceType.Identity) {
            url = APP_CONFIG.identityUrl + `swagger/doc.json`;
        }
        if (fromService === EResourceType.EInvoiceService) {
            url = APP_CONFIG.eisUrl + `swagger/doc.json`;
        }
        if (fromService === EResourceType.MailService) {
            url = APP_CONFIG.mailUrl + `swagger/doc.json`;
        }
        if (fromService === EResourceType.Notification) {
            url = APP_CONFIG.notificationUrl + `swagger/doc.json`;
        }
        if (fromService === EResourceType.Storage) {
            url = APP_CONFIG.storageUrl + `swagger/doc.json`;
        }
        if (fromService === EResourceType.MultiFactorAuthentication) {
            url = APP_CONFIG.mfaUrl + `swagger/doc.json`;
        }
        if (fromService === EResourceType.SystemTokenService) {
            url = APP_CONFIG.stsUrl + `swagger/doc.json`;
        }
        if (fromService === EResourceType.PDFEngine) {
            url = APP_CONFIG.pdfEngineUrl + `swagger/doc.json`;
        }

        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getActions: async ({ PageLimit }: IActionIdsGetPayload): Promise<IActionResult> => {
        const url = APP_CONFIG.identityUrl + `ActionCommand/Actions`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { PageLimit });
            return response?.data?.Result as IActionResult;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getActionIds: async ({ PageLimit }: IActionIdsGetPayload): Promise<IActionIdsResult> => {
        const url = APP_CONFIG.identityUrl + `ActionQuery/ActionIds`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { PageLimit });
            return response?.data?.Result as IActionIdsResult;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    createActionId: async (payload: IActionIdPayload) => {
        const url = APP_CONFIG.identityUrl + `ActionCommand/CreateActionId`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { ...payload });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    createAction: async (payload: IActionPayload) => {
        const url = APP_CONFIG.identityUrl + `ActionCommand/CreateAction`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { ...payload });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    deleteAction: async (payload: IActionDeletePayload) => {
        const url = APP_CONFIG.identityUrl + `ActionCommand/DeleteAction`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { ...payload });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
