const CheckMark = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 60 60'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_bi_6951_95643)'>
                <rect width='60' height='60' rx='30' fill='white' fillOpacity='0.16' />
                <path
                    d='M40 29.0857V30.0057C39.9988 32.1621 39.3005 34.2604 38.0093 35.9875C36.7182 37.7147 34.9033 38.9782 32.8354 39.5896C30.7674 40.201 28.5573 40.1276 26.5345 39.3803C24.5117 38.633 22.7847 37.2518 21.611 35.4428C20.4373 33.6338 19.8798 31.4938 20.0217 29.342C20.1636 27.1903 20.9972 25.1421 22.3983 23.5028C23.7994 21.8635 25.6928 20.7211 27.7962 20.2459C29.8996 19.7706 32.1003 19.9881 34.07 20.8657M40 22L30 32.01L27 29.01'
                    stroke='url(#paint0_linear_6951_95643)'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <filter
                    id='filter0_bi_6951_95643'
                    x='-16'
                    y='-16'
                    width='92'
                    height='92'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feGaussianBlur in='BackgroundImageFix' stdDeviation='8' />
                    <feComposite
                        in2='SourceAlpha'
                        operator='in'
                        result='effect1_backgroundBlur_6951_95643'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_backgroundBlur_6951_95643'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation='2.5' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0'
                    />
                    <feBlend mode='normal' in2='shape' result='effect2_innerShadow_6951_95643' />
                </filter>
                <linearGradient
                    id='paint0_linear_6951_95643'
                    x1='30'
                    y1='20'
                    x2='30'
                    y2='40'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default CheckMark;
