import { RequestStatus } from '@models/async-status.enum';
import { IInventoryGetPayload, ISingleInventory } from '@models/inventory.model';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INVENTORY_API } from '@service/inventory.service';
import { RootState } from '@store/store';

interface InventoriesSliceState {
    InventoryList: {
        status: RequestStatus;
        data: ISingleInventory[];
        totalCount: number;
    };
    selectedInventory?: ISingleInventory;
    productSelect: {
        status: RequestStatus;
        data: ISingleInventory[];
        hasMore: boolean;
        PageLimit: number;
        PageNumber: number;
    };
}

const initialState: InventoriesSliceState = {
    InventoryList: {
        status: 'idle',
        data: [],
        totalCount: 0,
    },
    selectedInventory: undefined,
    productSelect: {
        status: 'idle',
        data: [],
        hasMore: true,
        PageLimit: 10,
        PageNumber: 1,
    },
};

export const FetchInventories = createAsyncThunk(
    'fetchInventory',
    async (payload: IInventoryGetPayload) => {
        try {
            const response = await INVENTORY_API.getAllInventories(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const FetchProductForSelect = createAsyncThunk(
    'fetchProductForSelect',
    async (payload: IInventoryGetPayload) => {
        try {
            const response = await INVENTORY_API.getAllInventories(payload);
            return response || { InventoryList: [], TotalCount: 0 };
        } catch (error: any) {
            throw error as any;
        }
    },
);

const InventoriesSlice = createSlice({
    name: 'InventoriesSlice',
    initialState,
    reducers: {
        setSelectedInventory: (state, action: PayloadAction<ISingleInventory>) => {
            state.selectedInventory = action.payload;
        },
        clearSelectedInventory: (state) => {
            state.selectedInventory = undefined;
        },
        onUpdateSelectedInventory: (state, action: PayloadAction<ISingleInventory>) => {
            state.selectedInventory = action.payload;
            state.InventoryList.data = state.InventoryList.data.map((inventory) =>
                inventory.ItemId === action.payload.ItemId ? action.payload : inventory,
            );
        },
        resetProductSelect: (state) => {
            state.productSelect.status = 'idle';
            state.productSelect.data = [];
            state.productSelect.hasMore = true;
            state.productSelect.PageNumber = 1;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(FetchInventories.pending, (state) => {
                state.InventoryList.status = 'loading';
            })
            .addCase(FetchInventories.rejected, (state) => {
                state.InventoryList.status = 'failed';
                state.InventoryList.data = [];
                state.InventoryList.totalCount = 0;
            })
            .addCase(FetchInventories.fulfilled, (state, action) => {
                const modifyInventory: ISingleInventory[] = action.payload?.InventoryList?.map(
                    (inventory): ISingleInventory => {
                        if (inventory.IsArchived) return { ...inventory, Status: 'Archive' };
                        if (inventory.IsActive) return { ...inventory, Status: 'Active' };
                        return { ...inventory, Status: 'Inactive' };
                    },
                );
                state.InventoryList.data = modifyInventory || [];
                state.InventoryList.status = 'idle';
                state.InventoryList.totalCount = action.payload.TotalCount;
            })

            .addCase(FetchProductForSelect.pending, (state) => {
                state.productSelect.status = 'loading';
            })
            .addCase(FetchProductForSelect.rejected, (state) => {
                state.productSelect.status = 'failed';
            })
            .addCase(FetchProductForSelect.fulfilled, (state, action) => {
                state.productSelect.status = 'idle';
                const inventoryList = action?.payload?.InventoryList || [];
                const totalCount = action?.payload?.TotalCount || 0;
                state.productSelect.data = state.productSelect?.data?.concat(inventoryList);
                state.productSelect.hasMore = state.productSelect.data.length < totalCount;
                state.productSelect.PageNumber = state.productSelect.PageNumber + 1;
            });
    },
});

export const {
    setSelectedInventory,
    clearSelectedInventory,
    onUpdateSelectedInventory,
    resetProductSelect,
} = InventoriesSlice.actions;

export const selectIsInventoryDetails = (state: RootState) => !!state.inventory.selectedInventory;
export const selectInventories = (state: RootState) => state.inventory.InventoryList;
export const selectSelectedInventory = (state: RootState) => state.inventory.selectedInventory;

export const selectInventoriesOptionByCurrency = (currencyCode: string) => (state: RootState) => {
    const productSelect = state.inventory?.productSelect;
    return {
        ...productSelect,
        data: productSelect?.data?.filter(
            (product) => !!product?.IsActive && product.PurchaseCurrency === currencyCode,
        ),
    };
};

export default InventoriesSlice.reducer;
