import React from 'react';
import './PricingCalculatorEmptyCard.scss';
import Icon, {
    CaretDownOutlined,
    DiffOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    FileSearchOutlined,
    PlusOutlined,
    ShareAltOutlined,
} from '@ant-design/icons'; // Assuming Ant Design for icons
import { CoreButton, CoreTitle } from '@packages';
import { Select, Tooltip } from 'antd';

export default function PricingCalculatorEmptyCard() {
    return (
        <div className='pricing-empty-card'>
            <div>
                <div className='card-header'>
                    <CoreTitle className='pricing-empty-card-title' tag='h4' text='Cost details' />
                    <Select className='pricing-empty-card-select' placeholder='USD' />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CoreButton
                        className='pricing-cal-empty-card-button'
                        type='basic'
                        text='Add to estimate'
                        icon={<PlusOutlined />}
                    />
                </div>

                <div className='info-message'>
                    <Tooltip title='' className='generic-tooltip'>
                        <ExclamationCircleOutlined
                            style={{
                                fontSize: '16px',
                                position: 'absolute',
                                marginRight: '12px',
                                color: 'blue',
                            }}
                        />
                    </Tooltip>
                    <p>Details of items added to your estimate are displayed in this panel</p>
                </div>
            </div>

            <div className='estimated-cost-footer'>
                <div className='estimated-text'>
                    <p className='estimated-label'>ESTIMATED COST</p>
                    <p className='estimated-value'>-- / mo</p>
                </div>
                <div className='icons-container'>
                    <div className='icon-wrapper'>
                        <FileSearchOutlined />
                        <DownloadOutlined />
                        <DiffOutlined />
                    </div>
                    <div className='icon-wrapper'>
                        <ShareAltOutlined /> <p> Share</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
