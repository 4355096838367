const NoteIcon = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='20' fill='white' />
            <rect
                x='0.5'
                y='0.5'
                width='39'
                height='39'
                rx='19.5'
                stroke='white'
                strokeOpacity='0.2'
            />
            <path
                d='M21.5 19.25H17M18.5 22.25H17M23 16.25H17M26 16.1V23.9C26 25.1601 26 25.7902 25.7548 26.2715C25.539 26.6948 25.1948 27.039 24.7715 27.2548C24.2902 27.5 23.6601 27.5 22.4 27.5H17.6C16.3399 27.5 15.7098 27.5 15.2285 27.2548C14.8052 27.039 14.461 26.6948 14.2452 26.2715C14 25.7902 14 25.1601 14 23.9V16.1C14 14.8399 14 14.2098 14.2452 13.7285C14.461 13.3052 14.8052 12.961 15.2285 12.7452C15.7098 12.5 16.3399 12.5 17.6 12.5H22.4C23.6601 12.5 24.2902 12.5 24.7715 12.7452C25.1948 12.961 25.539 13.3052 25.7548 13.7285C26 14.2098 26 14.8399 26 16.1Z'
                stroke='url(#paint0_linear_6951_94370)'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_6951_94370'
                    x1='20'
                    y1='12.5'
                    x2='20'
                    y2='27.5'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default NoteIcon;
