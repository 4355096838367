import InlineGenericTreeSelectGroup from '@components/common/InlineGenericTreeSelectGroup';
import { RequestStatus } from '@models/async-status.enum';
import { EInvoiceType, IInvoiceCreation } from '@models/invoice.model';
import { useAppSelector } from '@store/hooks';
import { selectIsFoundInvoice } from '@store/slices/einvoiceSlice';
import { invoiceTypeOptions, invoiceTypeOptionsForInitial } from '@utils/InvoiceTypeOptions';
import {
    EInvoiceTypeCode,
    GET_INVOICE_TYPE_CODE_BY_NAME,
    GET_PHASE_ONE_INVOICE_TYPES,
    IInvoiceTypeOption,
} from '@utils/InvoiceUtils';
import { useEffect, useState } from 'react';
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IInvoiceTypeProps {
    control: Control<IInvoiceCreation, any>;
    errors: FieldErrors<IInvoiceCreation>;
    setFormValue: UseFormSetValue<IInvoiceCreation>;
    isCreateNote: boolean;
    organizationPhase: number;
    selectedInvoiceType: string;
    invoiceForReferences: {
        data: any[];
        status: RequestStatus;
    };
    onInvoiceSelect: (option: IInvoiceSelectedType) => void;
}
export default function InvoiceType({
    control,
    errors,
    isCreateNote,
    organizationPhase,
    selectedInvoiceType,
    setFormValue,
    invoiceForReferences,
    onInvoiceSelect,
}: IInvoiceTypeProps) {
    const { t } = useTranslation();
    const [invoiceOption, setInvoiceOption] = useState<IInvoiceTypeOption[]>([]);
    const isFoundInvoice = useAppSelector(selectIsFoundInvoice);

    useEffect(() => {
        if (organizationPhase === 1) {
            setInvoiceOption(GET_PHASE_ONE_INVOICE_TYPES);
            return;
        }
        if (!isFoundInvoice && selectedInvoiceType) {
            setInvoiceOption(invoiceTypeOptionsForInitial(selectedInvoiceType));
            return;
        }
        if (selectedInvoiceType) {
            setInvoiceOption(invoiceTypeOptions(selectedInvoiceType));
            return;
        }
        setInvoiceOption([]);
    }, [organizationPhase, selectedInvoiceType, isFoundInvoice]);

    const options = invoiceOption.map((option) => ({
        ...option,
        title: t(option?.displayKey),
        children: option?.children?.map((op) => ({ ...op, title: t(op?.displayKey) } || [])),
    }));

    const onInvoiceChange = (value: string) => {
        const invoiceCodeInfo = getInvoiceTypeByValue(value);
        invoiceCodeInfo && onInvoiceSelect(invoiceCodeInfo);
    };

    return (
        <InlineGenericTreeSelectGroup
            control={control}
            levelCol={24}
            inputCol={24}
            gutter={0}
            errors={errors}
            dataKey='InvoiceType'
            level={t('INVOICE_TYPE')}
            placeHolder={t('SELECT_INVOICE_TYPE')}
            options={options}
            onChange={onInvoiceChange}
            rules={{
                required: true,
            }}
            disabled={isCreateNote}
            showSearch={false}
            setFormValue={setFormValue}
            loading={invoiceForReferences.status === 'loading' ? true : false}
            errorMessageSize='small'
            marginBottom={0}
        />
    );
}

export function getInvoiceTypeByValue(value: string): IInvoiceSelectedType | undefined {
    const invoiceTypeInfo = GET_INVOICE_TYPE_CODE_BY_NAME(value as EInvoiceType);
    if (invoiceTypeInfo?.InvoiceType === '388') {
        return;
    }
    if (invoiceTypeInfo)
        if (invoiceTypeInfo?.InvoiceTypeCode === EInvoiceTypeCode.B2B) {
            return {
                InvoiceType: '388',
                InvoiceTypeCode: EInvoiceTypeCode.B2B,
            };
        }
    return {
        InvoiceType: '388',
        InvoiceTypeCode: EInvoiceTypeCode.B2C,
    };
}

export interface IInvoiceSelectedType {
    InvoiceType: string;
    InvoiceTypeCode: EInvoiceTypeCode;
}
