import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    ICreateAdminTemplate,
    IDeleteAdminTemplate,
    IGetAdminTemplatesPayload,
    ISingleAdminTemplatePayload,
    IUpdateAdminTemplate
} from "@models/template.modle";

export const AdminTemplateAPI = {
    getAdminTemplates: async (payload: IGetAdminTemplatesPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateQuery/List`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url,payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getAdminSingleTemplate: async (payload: ISingleAdminTemplatePayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateQuery/View`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    addAdminTemplate: async (payload: ICreateAdminTemplate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateAdminTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    updateAdminTemplate: async (payload: IUpdateAdminTemplate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<IUpdateAdminTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    deleteAdminTemplate: async (payload: IDeleteAdminTemplate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateCommand/Delete`;
        try {
            const response = await AXIOS_INSTANCE.post<IDeleteAdminTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
