import { Divider, Image } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import './Activation.scss';
import security_img from '@assets/images/security.png';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { FetchEnable2FARequest, selectInitialSetup2FA } from '@store/slices/setup-2FASlice';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

const ActiveSecurity = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { status } = useAppSelector(selectInitialSetup2FA) || {};
    const on2FAsetup = () => {
        dispatch(FetchEnable2FARequest())
            .then(unwrapResult)
            .then((result) => {
                navigate('/setting/two-factor-authentication');
            });
    };

    return (
        <>
            <div className='security-content-inner-wrapper'>
                <CoreTitle tag='h4' text={t('ENABLE_TWO_FACTOR_AUTHENTICATION')} />
            </div>
            <Divider className='security-divider' />

            <div className='two-factor-authentication'>
                <Image src={security_img} preview={false} />
                <p className='authentication-title'>{t('TWO_FACTOR_ENABLED')}</p>
                <p className='authentication-sub-title'>
                    {t('TWO_FACTOR_AUTHENTICATION_ADDS_AN_EXTRA_LAYER_OF_SECURITY_TO_YOUR_ACCOUNT')}
                </p>
                <CoreButton
                    text={t('ENABLE_TWO_FACTOR_AUTHENTICATION')}
                    type='primary'
                    onClick={on2FAsetup}
                    loading={status === 'loading' ? true : false}
                />
                <p className='learn-more-button'></p>
            </div>
        </>
    );
};
export default ActiveSecurity;
