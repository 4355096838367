import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { UtcToLocalDate } from '@utils/date';
import { DeleteOutlined } from '@ant-design/icons';
import { CoreSearchInput } from '@packages';
import { message, Table } from 'antd';
import './ActionList.scss';
import CreateActionId from '../../../access-management/action/create-action-id/CreateActionId';
import { getActions } from '@store/slices/actionSlice';
import { useAppDispatch } from '@store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { IAction } from '@models/action.model';
import { ConfirmationModal } from '@components';
import { ActionAPI } from '@service/action.service';

function ActionList() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { actions } = useSelector((state: RootState) => state.action.action);

    useEffect(() => {
        dispatch(
            getActions({
                PageLimit: 100,
            }),
        );
    }, []);

    const [isDeleteActionModalOpen, setIsDeleteActionModalOpen] = useState(false);
    const [actionId, setActionId] = useState('');
    const [actionName, setActionName] = useState('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const onActionDelete = async () => {
        if (!actionId) return;
        setButtonLoading(true);

        try {
            await ActionAPI.deleteAction({ ActionId: actionId, ActionName: actionName });
            setButtonLoading(false);
            message.success(`Action Deleted Successfully`);
            setActionId('');
            dispatch(
                getActions({
                    PageLimit: 20,
                }),
            );
            setIsDeleteActionModalOpen(false);
        } catch {
            message.error('Action Delete Failed');
            setButtonLoading(false);
        }
    };

    const handleDeleteClick = (e: any, data: IAction) => {
        e.stopPropagation();
        setIsDeleteActionModalOpen(true);
        setActionId(data?.ActionId);
        setActionName(data?.ActionName);
    };

    const columns: ColumnsType<IAction> = [
        {
            title: 'Action Name',
            dataIndex: 'ActionName',
        },
        {
            title: 'Action ID',
            dataIndex: 'ActionId',
        },
        {
            title: 'Resource Path',
            dataIndex: 'ResourcePath',
        },
        {
            title: 'Created Date',
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            title: 'Method',
            dataIndex: 'Method',
        },
        {
            title: '',
            dataIndex: 'ItemId',
            render: (ItemId: string, data: IAction) => (
                <div className='action-buttons'>
                    <div className='invite-client-icon' onClick={(e) => handleDeleteClick(e, data)}>
                        <DeleteOutlined />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div>
            <div className='filter-header margin-30'>
                <CoreSearchInput placeholder='Search' />
                <div className='create-button-group'>
                    <CreateActionId />
                </div>
            </div>
            <div className='ksa-table-wrapper table-auto-scroll-without-header'>
                <Table
                    columns={columns}
                    rowKey={'ItemId'}
                    dataSource={actions}
                    rowClassName='cursor-pointer'
                />
            </div>
            <ConfirmationModal
                title='Delete Action'
                description='Would you like to delete this Action?'
                buttonText='Delete'
                isDeleteModalOpen={isDeleteActionModalOpen}
                handleDeleteOk={() => onActionDelete()}
                handleDeleteCancel={() => setIsDeleteActionModalOpen(false)}
                isLoading={buttonLoading}
            />
        </div>
    );
}

export default ActionList;
