import React from 'react';
import { Modal, Card, Row, Col, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CoreButton, CoreTitle } from '@packages';
import { addNewCalculatorItem, selectEstimationData } from '@store/slices/paymentCalculator';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export interface IEstimateModalProps {
    isVisibleModal: boolean;
    onHideModal: () => void;
}

export default function EstimateModal({ isVisibleModal, onHideModal }: IEstimateModalProps) {
    const estimationData = useAppSelector(selectEstimationData);
    const dispatch = useAppDispatch();
    return (
        <>
            <Modal
                title='Add to this estimate'
                closable={false}
                open={isVisibleModal}
                width={800}
                footer={[
                    <div key='footer' className='archive-feature-modal-footer'>
                        <CoreButton key={1} text='Ok' type='primary' onClick={onHideModal} />
                    </div>,
                ]}
            >
                <Row gutter={[16, 16]}>
                    {estimationData.map((item, index) => (
                        <Col span={8} key={index}>
                            <Card
                                hoverable={false}
                                actions={undefined}
                                style={{ height: '100%', borderRadius: 4, padding: 0 }}
                                bodyStyle={{ padding: 15 }}
                                onClick={() => dispatch(addNewCalculatorItem(item.type))}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CoreTitle text={item.title} tag='h6' />
                                    <Button
                                        shape='circle'
                                        type='primary'
                                        size='small'
                                        icon={<PlusOutlined />}
                                    />
                                </div>
                                <p>{item.description}</p>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Modal>
        </>
    );
}
