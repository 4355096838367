export interface IAddress {
    Street: string;
    StreetEn: string;
    BuildingNumber: number | string;
    AdditionalNumber: number | string;
    AdditionalStreet: string;
    AdditionalStreetEn?: string;

    City: string;
    CityEn?: string;
    CountryCode: string;
    District: string;
    DistrictEn?: string;
    PostalCode: number | string;
    ProvinceOrState: string;
    ProvinceOrStateEn?: string;
}

export interface IContact {
    FirstName: string;
    FirstNameEn: string;
    JobTitle: string;
    JobTitleEn: string;
    LastName: string;
    LastNameEn: string;
    MiddleName: string;
    MiddleNameEn: string;
}

export interface ICountry {
    label: string;
    value: string;
}

export interface IGenericResponse<T> {
    Result: T | null;
    SuccessCode: number;
    Message: string;
    Success: boolean;
}

export interface IDropdownOption {
    label: string;
    value: string;
    hoverName?: string;
}

export interface IFetchListPayload {
    PageLimit: number;
    PageNumber: number;
    Next?: string;
    Nonce?: string;
}

export enum StatusCode {
    Archive = 'Archive',
    Active = 'Active',
    Inactive = 'Inactive',
}

export interface ISelectOption {
    label: string;
    value: string;
}
