import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MySliceState {
    title: string;
    customerTitle: string;
}

const initialState: MySliceState = {
    title: '',
    customerTitle: '',
};

const dynamicTitleSlice = createSlice({
    name: 'dynamicTitleSlice',
    initialState,
    reducers: {
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
        setCustomerTitle: (state, action: PayloadAction<string>) => {
            state.customerTitle = action.payload;
        },
    },
});

export const { setTitle, setCustomerTitle } = dynamicTitleSlice.actions;
export default dynamicTitleSlice.reducer;
