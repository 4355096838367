import React, { useEffect, useState } from 'react';
import { getSubscription } from '@store/slices/subscribeSlice';
import { useAppDispatch } from '@store/hooks';
import CreateTier from '../../tier/CreateTier';

const SubscriptionList = () => {
    const dispatch = useAppDispatch();
    const [isTierModalOpen, setTierModalOpen] = useState<boolean>(false);
    useEffect(() => {
        dispatch(getSubscription());
    }, []);
    return (
        <div>
            <CreateTier
                isOpenModal={isTierModalOpen}
                onCancelModal={() => setTierModalOpen(false)}
            />
        </div>
    );
};
export default SubscriptionList;
