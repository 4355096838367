import { Select } from 'antd';
import './HomeLanguageMenu.scss';
import { useLanguage } from '@hooks';
import { useAppSelector } from '@store/hooks';
import { selectAppLanguage } from '@store/slices/languageSlice';
import { FlagIcon } from 'react-flag-kit';
import { DEFAULT_LANGUAGE } from '@config/constants';

const HomeLanguageMenu = () => {
    const { setCurrentLanguage } = useLanguage();
    const { selection } = useAppSelector(selectAppLanguage);

    return (
        <div className='home-language-menu-wrapper'>
            <Select
                value={selection}
                defaultValue={DEFAULT_LANGUAGE}
                className='home-language-selection'
                size='small'
                bordered={false}
                onChange={(value) => setCurrentLanguage(value)}
                options={[
                    {
                        value: 'en',
                        label: (
                            <div className='items'>
                                <FlagIcon code='US' size={14} className='icons' /> EN
                            </div>
                        ),
                    },
                    {
                        value: 'sa',
                        label: (
                            <div className='items'>
                                <FlagIcon code='SA' size={12} className='icons' /> عربي
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default HomeLanguageMenu;
