import TemplateMenuWrapper from '../../template-generator/template-menu-wrapper/TemplateMenuWrapper';
import { CoreButton, CoreTitle } from '@packages';
import { Checkbox, Col, ColorPicker, Input, Row, Select } from 'antd';
import React from 'react';

const TemplateFooter = () => {
    return (
        <TemplateMenuWrapper>
            <div className='template-form-wrapper'>
                <CoreTitle className='doc-title' tag='h4' text='Notes' />
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Label</label>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Notes' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Font Size</label>
                        </Col>
                        <Col span={12}>
                            <Input addonAfter='pt' defaultValue='10' />
                        </Col>
                    </Row>
                </div>
                <CoreTitle className='doc-title' tag='h4' text='Notes' />
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Online Payment Link</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Payment Option' />
                        </Col>
                    </Row>
                </div>
                <CoreTitle className='title' tag='h6' text='Add your bank details' />
                <div className='form-field'>
                    <Checkbox className='general-checkbox'>Terms & Conditions</Checkbox>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Label</label>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Terms & Conditions' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Font Size</label>
                        </Col>
                        <Col span={12}>
                            <Input addonAfter='pt' defaultValue='8' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Checkbox className='general-checkbox'>Signature</Checkbox>
                </div>
                <CoreTitle className='doc-title' tag='h4' text='Total(Subtotal,Tax)' />
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Font Size</label>
                        </Col>
                        <Col span={12}>
                            <Input addonAfter='pt' defaultValue='8' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Font Color</label>
                        </Col>
                        <Col span={12}>
                            <ColorPicker showText className='custom-color-picker' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Background Color</Checkbox>
                        </Col>
                        <Col span={12}>
                            <ColorPicker showText className='custom-color-picker' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <label className='general-label'>Background Image</label>
                    <Row gutter={8}>
                        <Col span={12}>
                            <CoreButton text='Choose from Desktop' isFullWidth={true} />
                        </Col>
                        <Col span={12}>
                            <CoreButton text='Choose from Gallery' isFullWidth={true} />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <label className='general-label'>Image Position</label>
                    <Select
                        defaultValue='top-left'
                        style={{ width: '100%' }}
                        options={[{ value: 'top-left', label: 'Top Left' }]}
                    />
                </div>
                <div className='form-field'>
                    <Checkbox className='general-checkbox'>Show Page Number</Checkbox>
                </div>

                <div className='form-field'>
                    <CoreButton text='Customize your footer' type='secondary' isFullWidth={false} />
                </div>

                <div className='save-button'>
                    <CoreButton type='primary' text='Save' />
                </div>
            </div>
        </TemplateMenuWrapper>
    );
};
export default TemplateFooter;
