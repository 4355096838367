import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const EditSvg = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5652_4656)'>
            <path
                d='M15 1.66675L18.3333 5.00008M1.66663 18.3334L2.73029 14.4333C2.79969 14.1789 2.83439 14.0516 2.88765 13.933C2.93495 13.8276 2.99306 13.7275 3.06106 13.6342C3.13764 13.5291 3.23088 13.4358 3.41738 13.2493L12.0286 4.63815C12.1936 4.47315 12.2761 4.39064 12.3712 4.35973C12.4549 4.33254 12.545 4.33254 12.6287 4.35973C12.7239 4.39064 12.8064 4.47315 12.9714 4.63815L15.3619 7.02868C15.5269 7.19368 15.6094 7.27619 15.6403 7.37132C15.6675 7.45501 15.6675 7.54515 15.6403 7.62884C15.6094 7.72398 15.5269 7.80648 15.3619 7.97149L6.75071 16.5827C6.56422 16.7692 6.47097 16.8624 6.36586 16.939C6.27254 17.007 6.1724 17.0651 6.06706 17.1124C5.94841 17.1657 5.82119 17.2004 5.56674 17.2697L1.66663 18.3334Z'
                stroke='currentColor'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_5652_4656'>
                <rect width='20' height='20' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
const EditIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={EditSvg} {...props} />
);

export default EditIcon;
