import { Col, Row, Select, Tooltip } from 'antd';
import { FC, Key } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import GenericErrorMessage, { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { CustomRules } from '@models/rules.model';
import { isFiledFoundError, isRequiredValue } from '@utils/errorMessageOption';
import _ from 'lodash';
const Option = Select.Option;
export type ISelectOption = { value: Key | boolean; label: string; hoverName?: string };
interface IInlineGenericSelectParams {
    control: Control<any, any>;
    errors: FieldErrors<any>;
    level: string;
    dataKey: string;
    options: ISelectOption[];
    setFormValue: UseFormSetValue<any>;
    onChange?: (value: any, option?: any) => void;
    rules?: CustomRules;
    allowClear?: boolean;
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    disabled?: boolean;
    placeHolder?: string;
    children?: React.ReactNode;
    hideError?: boolean;
    gutter?: number;
    loading?: boolean;
    tooltip?: string;
    sorting?: boolean;
    className?: string;
    showSearch?: boolean;
    errorMessageSize?: ErrorMessageSize;
    marginBottom?: number;
    isMultipleSelect?: boolean;
}

const InlineGenericSelect: FC<IInlineGenericSelectParams> = ({
    control,
    errors,
    level,
    dataKey,
    rules,
    options,
    setFormValue,
    onChange,
    allowClear,
    disabled,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    placeHolder,
    children,
    hideError,
    gutter = 130,
    loading = false,
    tooltip,
    sorting = true,
    className = '',
    showSearch = true,
    errorMessageSize,
    marginBottom = 15,
    isMultipleSelect,
}) => {
    const { t } = useTranslation();

    return (
        <Row gutter={gutter} align='middle' style={{ marginBottom: marginBottom }}>
            {showLevel && (
                <Col span={levelCol}>
                    <label className='general-label'>
                        {t(level)}
                        {rules?.required && <span className='required-field'>*</span>}
                        {tooltip && <GenericTooltip text={tooltip} />}
                    </label>
                </Col>
            )}
            <Col span={inputCol}>
                <div className='general-select-wrapper'>
                    <Controller
                        render={({ field }) => (
                            <Select
                                className={className ? className : 'general-select'}
                                {...field}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                showSearch={showSearch}
                                style={{ width: '100%' }}
                                placeholder={placeHolder ? t(placeHolder) : `Search ${level}`}
                                optionFilterProp='children'
                                getPopupContainer={(trigger) => trigger.parentNode}
                                filterOption={(input, option: any) => {
                                    return (
                                        option.children?.props?.children.toLowerCase() ?? ''
                                    ).includes(input.toLowerCase());
                                }}
                                filterSort={(optionA, optionB: any) => {
                                    if (!sorting) return;
                                    return (optionA?.children?.props?.children ?? '')
                                        .toLowerCase()
                                        .localeCompare(
                                            optionB?.children?.props?.children ?? ''.toLowerCase(),
                                        );
                                }}
                                onChange={(value, option) => {
                                    setFormValue &&
                                        setFormValue(dataKey, value || null, {
                                            shouldValidate: true,
                                        });
                                    onChange && onChange(value, option?.data);
                                }}
                                allowClear={allowClear || false}
                                disabled={disabled}
                                loading={loading}
                                mode={isMultipleSelect ? 'multiple' : undefined}
                            >
                                {options.map((selectOption) => (
                                    <Option
                                        key={selectOption?.value?.toString()}
                                        value={selectOption?.value}
                                        data={selectOption}
                                    >
                                        <Tooltip title={t(selectOption?.hoverName || '')}>
                                            {t(selectOption?.label)}
                                        </Tooltip>
                                    </Option>
                                ))}
                            </Select>
                        )}
                        control={control}
                        name={dataKey}
                        rules={{ ...rules, required: isRequiredValue(rules) }}
                    />
                    {!hideError && (
                        <GenericErrorMessage
                            errors={errors}
                            dataKey={dataKey}
                            rules={rules}
                            level={level}
                            errorMessageSize={errorMessageSize}
                        />
                    )}
                    {children}
                </div>
            </Col>
        </Row>
    );
};

export default InlineGenericSelect;
