import { IUser } from '@models/user.model';
import { LANGUAGE_KEY } from '@config/constants';

const path = {
    auth: 'auth',
    login: 'login',
    loggedProfile: 'profile',
    gApiKey: 'gapiKey',
    accessToken: 'access_token',
    refreshToken: 'refresh_token',
    Anonymous: 'anonymous',
};
export const localStoreAPI = {
    setLocalStorageLogin: (): void => {
        localStoreAPI.setItemLocalStore(path.login, localStoreAPI.strEncript('true'));
    },
    getLocalStorageLogin: (): boolean => {
        const isLogin = sessionStorage.getItem(path.login);
        if (!isLogin) {
            return false;
        }
        return localStoreAPI.strDecript(isLogin) === 'true';
    },
    setLocalStorageLoggedUserProfile: (payload: any): void => {
        localStoreAPI.setItemLocalStore(path.loggedProfile, payload);
    },
    getLocalStorageLoggedUserProfile: (): IUser => {
        const data = localStoreAPI.getItemLocalStore(path.loggedProfile);
        const userProfile = data ? data : ({} as any);
        return userProfile;
    },
    setToken: ({
        access_token,
        refresh_token,
    }: {
        access_token: string;
        refresh_token: string;
    }): void => {
        sessionStorage.setItem(path.accessToken, access_token);
        sessionStorage.setItem(path.refreshToken, refresh_token);
    },
    removeLoggedInfo: (): void => {
        const anonymousToken = localStoreAPI.getAnonymousToken() || '';
        localStoreAPI.clearLocalStorage();
        localStoreAPI.setAnonymousToken(anonymousToken);
    },
    setAccessToken: (token: string): void => {
        sessionStorage.setItem(path.accessToken, token);
    },
    getAccessToken: (): string => {
        return sessionStorage.getItem(path.accessToken) ?? '';
    },
    setAnonymousToken: (token: string): void => {
        sessionStorage.setItem(path.Anonymous, token);
    },
    getAnonymousToken: (): string => {
        return sessionStorage.getItem(path.Anonymous) ?? '';
    },
    getRefreshToken: (): string => {
        return sessionStorage.getItem(path.refreshToken) ?? '';
    },
    setRefreshToken: (token: string): void => {
        sessionStorage.setItem(path.refreshToken, token);
    },
    setItemLocalStore: (name: string, payload: any): void => {
        sessionStorage.setItem(name, payload);
    },
    getItemLocalStore: (name: string): string => {
        return sessionStorage.getItem(name) ?? '';
    },
    getLanguage: (): string => {
        return sessionStorage.getItem(LANGUAGE_KEY) ?? 'en';
    },
    setLanguage: (lang: string): void => {
        sessionStorage.setItem(LANGUAGE_KEY, lang);
    },
    clearLocalStorage: (): void => {
        const language = localStoreAPI.getLanguage();
        sessionStorage.clear();
        localStoreAPI.setLanguage(language);
    },
    strEncript: (data: string): string => {
        return btoa(data);
    },
    strDecript: (data: string): string => {
        return atob(data);
    },
    jsonEncript: (data: object | any[]): string => {
        return btoa(JSON.stringify(data));
    },
    jsonDecript: <T>(data: string): T => {
        return JSON.parse(atob(data));
    },
};
