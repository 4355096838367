import React from 'react';
import './InventorySelectFile.scss';
import { Controller, useForm } from 'react-hook-form';
import { Col, Radio, Row, Select } from 'antd';
import { CoreFileUploadDragger } from '@packages';

interface IInventorySelectFile {
    uploadFile: string;
    duplicateHandling: boolean;
}

const InventorySelectFile = () => {
    const options = [
        {
            value: '1',
            label: 'UTF-8 (Unicode)',
        },
        {
            value: '2',
            label: 'UTF-18 (Unicode)',
        },
    ];

    const {
        control,
        handleSubmit,
        formState: any,
    } = useForm<IInventorySelectFile>({ mode: 'onChange' });
    const onSubmit = (data: IInventorySelectFile) => {
        console.log(data);
    };

    return (
        <div className='inventory-select-file-wrapper'>
            <div className='inventory-select-file-left'>
                <p className='download-message'>
                    Download a <span className='download-link'>sample CSV file</span> or{' '}
                    <span className='download-link'>sample xls file</span> and compare it to your
                    impost file to ensure you have the file perfect for the import.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row gutter={100} className='margin-bottom-30'>
                        <Col span={8}>
                            <label className='inventory-label'>Upload file</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='uploadFile'
                                render={({ field }) => <CoreFileUploadDragger />}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={100} className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='inventory-label'>Duplicate Handling</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='uploadFile'
                                render={({ field }) => (
                                    <div>
                                        <Radio className='inventory-radio' checked={true}>
                                            Skip
                                        </Radio>
                                        <p className='inventory-radio-sub-title'>
                                            Retains the existing records and dose not sync the
                                            identical records from the import file.
                                        </p>
                                    </div>
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={100} align='middle' className='margin-bottom-30'>
                        <Col span={8}></Col>
                        <Col span={16}>
                            <Controller
                                name='uploadFile'
                                render={({ field }) => (
                                    <div>
                                        <Radio className='inventory-radio'>Overwrite</Radio>
                                        <p className='inventory-radio-sub-title'>
                                            Overwrites existing records with the identical records
                                            in the import file.
                                        </p>
                                    </div>
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={100} align='middle' className='margin-bottom-30'>
                        <Col span={8}>
                            <label className='inventory-label'>Character Encoding</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='uploadFile'
                                render={({ field }) => (
                                    <Select
                                        className='inventory-select'
                                        showSearch
                                        placeholder='UTF-8 (Unicode)'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
};

export default InventorySelectFile;
