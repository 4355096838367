import React, { useEffect } from 'react';
import './SubscribeInvoiceDetails.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSubscriptionInvoice } from '@store/slices/subscriptionOverviewSlice';
import { CoreBackButton, CoreTitle } from '@packages';
import { Col, Divider, message, Row, Table } from 'antd';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { IUsageItems } from '@models/subscription.model';
import { fileStorageAPI } from '@service/file-storage.service';
import { selectLoggedUserInfo } from '@store/slices/userSlice';

const SubscribeInvoiceDetails = () => {
    const { invoiceId = '' } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { subscriptionInvoice } = useAppSelector((state) => state.subscriptionOverview);
    console.log(subscriptionInvoice);

    const { Email } = useAppSelector(selectLoggedUserInfo).userDetails;

    const dataSource = subscriptionInvoice?.Usage?.Items || [];
    const currancy = subscriptionInvoice?.Currency || DEFAULT_EMPTY_VALUE;

    useEffect(() => {
        dispatch(getSubscriptionInvoice(invoiceId));
    }, []);

    const columns = [
        {
            title: 'Description',
            dataIndex: 'Name',
        },
        {
            title: 'Quantity',
            dataIndex: 'Count',
        },
        {
            title: 'Unit Price',
            dataIndex: 'Price',
            render: (text: number) => (
                <span>
                    {currancy} {text.toFixed(2)}
                </span>
            ),
        },
        {
            title: 'Usage',
            dataIndex: 'usage',
            align: 'right' as const,
            render: (_: any, item: IUsageItems) => (
                <span>
                    {currancy} {(item.Count * item.Price).toFixed(2)}
                </span>
            ),
        },
    ];

    const downloadInvoicePdf = async (event: any, fileId: string) => {
        event.stopPropagation();
        try {
            const response = await fileStorageAPI.getFileUrl(fileId);
            window.open(response, '_blank');
        } catch (error) {
            message.error('File information not found ');
            throw error;
        }
    };

    return (
        <div className='subscribe-invoice-details-wrapper'>
            <CoreBackButton
                text={''}
                onClick={() => {
                    navigate(`/subscribe`, {
                        state: {
                            currentTab: 'invoice',
                        },
                    });
                }}
            />
            <div className='invoice-details-box'>
                <div className='invoice-details-box-header'>
                    <CoreTitle text='Invocie' tag='h4' />
                    <p>
                        Download as:{' '}
                        <span
                            className='link-color'
                            onClick={(event) =>
                                downloadInvoicePdf(event, subscriptionInvoice?.InvoiceFileId || '')
                            }
                        >
                            PDF
                        </span>
                    </p>
                </div>
                <div className='round-box-shadow invoice-details-box-wrapper'>
                    <Row gutter={[24, 24]}>
                        <Col xs={12} lg={7}>
                            <div className='running-total'>
                                <div>
                                    <CoreTitle text='Running Total' tag='h6' />
                                    <p className='running-total-amount'>
                                        {subscriptionInvoice?.Currency}{' '}
                                        {subscriptionInvoice?.Usage?.TotalAmount.toFixed(2)}
                                    </p>
                                </div>
                                <div>
                                    <CoreTitle text='Previous Invoice' tag='h6' />
                                    <p className='previous-invoice-amount'>
                                        {subscriptionInvoice?.PreviousInvoiceAmount ||
                                            DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <div className='invoice-details-devider'>
                            <Divider type='vertical' />
                        </div>

                        <Col xs={12} lg={7}>
                            <div className='billing-period'>
                                <div>
                                    <CoreTitle text='Billing Period' tag='h6' />
                                    <p>
                                        {subscriptionInvoice?.BillingPeriod || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div>
                                    <CoreTitle text='Invoice Number' tag='h6' />
                                    <p>{subscriptionInvoice?.ItemId || DEFAULT_EMPTY_VALUE}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div className='billed-to'>
                                <CoreTitle text='Billed To' tag='h6' />
                                <p>{Email || DEFAULT_EMPTY_VALUE}</p>
                                <a href='#' className='understanding-bill'>
                                    Understanding Your Bill
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='summary-by-service-box round-box-shadow'>
                <CoreTitle text='Summary By Service' tag='h4' />
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: 600 }}
                    footer={() => (
                        <div className='table-footer'>
                            <p>Usage Total</p>
                            <p className='total-amount'>
                                {subscriptionInvoice?.Currency}{' '}
                                {subscriptionInvoice?.Usage?.TotalAmount.toFixed(2)}
                            </p>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};
export default SubscribeInvoiceDetails;
