import dayjs from 'dayjs';

export function calculateTimeDifference(targetDate: string) {
    const today = dayjs();
    const target = dayjs(targetDate);

    // Calculate the difference in days
    const difference = target.diff(today, 'day');

    return difference + 1;
}
