export const PDF_SIZE_OPTIONS = [
    { value: 'A0', label: 'A0 (2383.94 x 3370.39)' },
    { value: 'A1', label: 'A1 (1683.78 x 2383.94)' },
    { value: 'A2', label: 'A2 (1190.55 x 1683.78)' },
    { value: 'A3', label: 'A3 (841.89 x 1190.55)' },
    { value: 'A4', label: 'A4 (595.28 x 841.89)' },
    { value: 'A5', label: 'A5 (419.53 x 595.28)' },
    { value: 'A6', label: 'A6 (297.64 x 419.53)' },
    { value: 'A7', label: 'A7 (209.76 x 297.64)' },
    { value: 'A8', label: 'A8 (147.40 x 209.76)' },
    { value: 'A9', label: 'A9 (104.88 x 147.40)' },
    { value: 'A10', label: 'A10 (73.70 x 104.88)' },
    { value: 'B0', label: 'B0 (2834.65 x 4008.19)' },
    { value: 'B1', label: 'B1 (2004.09 x 2834.65)' },
    { value: 'B2', label: 'B2 (1417.32 x 2004.09)' },
    { value: 'B3', label: 'B3 (1000.63 x 1417.32)' },
    { value: 'B4', label: 'B4 (708.66 x 1000.63)' },
    { value: 'B5', label: 'B5 (498.90 x 708.66)' },
    { value: 'B6', label: 'B6 (354.33 x 498.90)' },
    { value: 'B7', label: 'B7 (249.45 x 354.33)' },
    { value: 'B8', label: 'B8 (175.75 x 249.45)' },
    { value: 'B9', label: 'B9 (124.72 x 175.75)' },
    { value: 'B10', label: 'B10 (87.87 x 124.72)' },
    { value: 'C0', label: 'C0 (2599.37 x 3676.54)' },
    { value: 'C1', label: 'C1 (1836.85 x 2599.37)' },
    { value: 'C2', label: 'C2 (1298.27 x 1836.85)' },
    { value: 'C3', label: 'C3 (918.43 x 1298.27)' },
    { value: 'C4', label: 'C4 (649.13 x 918.43)' },
    { value: 'C5', label: 'C5 (459.21 x 649.13)' },
    { value: 'C6', label: 'C6 (323.15 x 459.21)' },
    { value: 'C7', label: 'C7 (229.61 x 323.15)' },
    { value: 'C8', label: 'C8 (161.57 x 229.61)' },
    { value: 'C9', label: 'C9 (113.39 x 161.57)' },
    { value: 'C10', label: 'C10 (79.37 x 113.39)' },
    { value: 'RA0', label: 'RA0 (2437.80 x 3458.27)' },
    { value: 'RA1', label: 'RA1 (1729.13 x 2437.80)' },
    { value: 'RA2', label: 'RA2 (1218.90 x 1729.13)' },
    { value: 'RA3', label: 'RA3 (864.57 x 1218.90)' },
    { value: 'RA4', label: 'RA4 (609.45 x 864.57)' },
    { value: 'SRA0', label: 'SRA0 (2551.18 x 3628.35)' },
    { value: 'SRA1', label: 'SRA1 (1814.17 x 2551.18)' },
    { value: 'SRA2', label: 'SRA2 (1275.59 x 1814.17)' },
    { value: 'SRA3', label: 'SRA3 (907.09 x 1275.59)' },
    { value: 'SRA4', label: 'SRA4 (637.80 x 907.09)' },
    { value: 'EXECUTIVE', label: 'Executive (521.86 x 756.00)' },
    { value: 'LEGAL', label: 'Legal (612.00 x 1008.00)' },
    { value: 'LETTER', label: 'Letter (612.00 x 792.00)' },
    { value: 'TABLOID', label: 'Tabloid (792.00 x 1224.00)' },
    { value: '4A0', label: '4A0 (4767.89 x 6740.79)' },
    { value: '2A0', label: '2A0 (3370.39 x 4767.87)' },
    { value: 'FOLIO', label: 'Folio (612.00 x 936.00)' },
];
