import { DEFAULT_TABLE_SIZE } from '@config/constants';
import { IBilling } from '@models/billing.model';
import { CoreStatus, CoreTitle } from '@packages';
import { GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import { UTCToShortDate } from '@utils/date';
import { displayCurrency } from '@utils/displayCurrency';
import { message, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSubscriptionInvoices } from '@store/slices/subscriptionOverviewSlice';
import { fileStorageAPI } from '@service/file-storage.service';
import { ISubscriptionInvoices } from '@models/subscription.model';
import { useNavigate } from 'react-router-dom';

export default function PaymentHistory() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { subscriptionInvoices } = useAppSelector((state) => state.subscriptionOverview);
    useEffect(() => {
        dispatch(getSubscriptionInvoices({ PageLimit: 20 }));
    }, []);

    const columns = (t: TFunction<'translation', undefined>): ColumnsType<any> => [
        {
            title: t('Purchase'),
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UTCToShortDate(text)}</span>,
        },
        {
            title: t('Payment Method'),
            dataIndex: 'PaymentMethod',
            render: (text: string) => {
                return <span>{text}</span>;
            },
        },
        {
            title: t('Amount'),
            dataIndex: 'PurchaseAmount',
            render: (amount: number, data: ISubscriptionInvoices) => (
                <span>
                    {displayCurrency({
                        Code: data.Currency || GET_DEFAULT_CURRENCY_CODE,
                        Amount: amount || 0,
                    })}
                </span>
            ),
        },
        {
            title: 'Used Credit',
            dataIndex: 'CreditUsed',
            render: (amount: string, data: ISubscriptionInvoices) => {
                return (
                    <span>
                        {displayCurrency({
                            Code: data.Currency || GET_DEFAULT_CURRENCY_CODE,
                            Amount: amount || 0,
                        })}
                    </span>
                );
            },
        },
        {
            title: 'Payment (PDF)',
            dataIndex: 'InvoiceFileId',
            render: (fileId: string) => (
                <span>
                    View:{' '}
                    <span
                        className='primary-color'
                        onClick={(event) => downloadInvoicePdf(event, fileId)}
                    >
                        PDF
                    </span>
                </span>
            ),
        },
    ];

    const downloadInvoicePdf = async (event: any, fileId: string) => {
        event.stopPropagation();
        try {
            const response = await fileStorageAPI.getFileUrl(fileId);
            window.open(response, '_blank');
        } catch (error) {
            message.error('File information not found ');
            throw error;
        }
    };

    const handleRowClick = (record: any) => {
        navigate(`/subscribe/invoice/${record.ItemId}`);
    };

    return (
        <div className='ksa-table-wrapper table-auto-scroll-with-header' style={{ marginTop: 10 }}>
            <div style={{ padding: '10px 0px' }}>
                <CoreTitle tag='h4' text={t('Payment History')} />
            </div>
            <Table
                columns={columns(t)}
                dataSource={subscriptionInvoices}
                rowClassName='cursor-pointer'
                rowKey={'ItemId'}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => handleRowClick(record), // click row
                    };
                }}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: subscriptionInvoices.length,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${t('OF')} ${total} ${t('ITEMS')}`,
                }}
            />
        </div>
    );
}

export const DUMMY_DATA: any[] = [
    {
        CreatedAt: new Date().toISOString(),
        Amount: 100,
        Type: 'visa',
        Status: 'Success',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 14000,
        Type: 'invoice',
        Status: 'Failed',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 500,
        Type: 'visa',
        Status: 'Success',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 1000,
        Type: 'invoice',
        Status: 'Success',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 0,
        Type: 'invoice',
        Status: 'Success',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 0,
        Type: 'invoice',
        Status: 'Success',
    },

    {
        CreatedAt: new Date().toISOString(),
        Amount: 100,
        Type: 'visa',
        Status: 'Success',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 500,
        Type: 'visa',
        Status: 'Success',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 1000,
        Type: 'invoice',
        Status: 'Success',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 0,
        Type: 'invoice',
        Status: 'Success',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 0,
        Type: 'invoice',
        Status: 'Success',
    },

    {
        CreatedAt: new Date().toISOString(),
        Amount: 100,
        Type: 'visa',
        Status: 'Success',
    },
];
