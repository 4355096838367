import { CustomRules } from '@models/rules.model';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { FieldErrors } from 'react-hook-form';

export function isRequiredValue(rules: CustomRules): boolean {
    const required = rules?.required;
    if (!required) return false;
    if (typeof required === 'boolean') return required;
    return required?.value;
}

export function getErrorMessage(
    messageType: MessageType,
    t: TFunction<'translation', undefined>,
    message?: string,
): string {
    if (messageType === 'pattern') {
        return message ? t(message) : t('GENERIC_PATTERN_MESSAGE');
    }
    if (messageType === 'min') {
        return message ? t(message) : t('GENERIC_MIN_MESSAGE');
    }
    if (messageType === 'max') {
        return message ? t(message) : t('GENERIC_MAX_MESSAGE');
    }
    if (messageType === 'minLength') {
        return message ? t(message) : t('GENERIC_MIN_MESSAGE');
    }
    return message ? t(message) : t('GENERIC_MAX_MESSAGE');
}

export function getRequiredErrorMessage(
    label: string,
    t: TFunction<'translation', undefined>,
    message?: string,
): string {
    const labelText = t(label);
    return message ? t(message) : t('REQUIRED_MESSAGE', { label: labelText });
}

export const isFiledFoundError = (errors: FieldErrors<any>, dataKey: string): boolean => {
    if (_.isEmpty(errors)) return false;
    const modifyKeys = dataKey.split('.');
    if (modifyKeys.length === 1) {
        const error = (errors as any)[modifyKeys[0]];
        return !!error;
    }
    if (modifyKeys.length === 2) {
        const error = (errors as any)?.[modifyKeys[0]]?.[modifyKeys[1]];
        return !!error;
    }
    return false;
};

type MessageType = 'pattern' | 'min' | 'max' | 'minLength' | 'maxLength';
