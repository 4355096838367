import { CoreTitle } from '@packages';
import {
    Bar,
    BarChart,
    CartesianGrid,
    LabelList,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { FC } from 'react';
import './DashboardBarChart.scss';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

interface DashboardLineChartProps {
    passedData: number;
    failedData: number;
    title: string;
    rejectionSummaryLoading: boolean;
}

const DashboardBarChart: FC<DashboardLineChartProps> = ({
    title,
    passedData,
    failedData,
    rejectionSummaryLoading,
}) => {
    const { t } = useTranslation();

    const chartData = [
        {
            passed: passedData,
            failed: failedData,
        },
    ];

    const totalCount = passedData + failedData;

    const renderCustomizedLabel = (props: any) => {
        // eslint-disable-next-line react/prop-types
        const { x, y, width, height, value } = props;
        const radius = 10;

        return (
            <g>
                <text
                    x={x + width / 6}
                    y={y - radius}
                    fill='black'
                    textAnchor='middle'
                    dominantBaseline='middle'
                >
                    {value}
                </text>
            </g>
        );
    };

    return (
        <div className='dashboard-line-chart-wrapper'>
            <CoreTitle tag='h6' text={title} />
            <div className='line-chart-header-block'>
                <div>
                    <p className='line-chart-header'>{t('PASSED')}</p>
                    <CoreTitle tag='h4' text={passedData.toString()} />
                </div>
                <div>
                    <p className='line-chart-header'>{t('FAILED')}</p>
                    <CoreTitle tag='h4' text={failedData.toString()} />
                </div>
            </div>

            <div className='line-chart'>
                <ResponsiveContainer width='100%' height={250}>
                    {rejectionSummaryLoading ? (
                        <div className='loading-spinner'>
                            <Spin size='large' />
                        </div>
                    ) : (
                        <BarChart layout='vertical' data={chartData} barSize={30} barGap={40}>
                            <XAxis type='number' />
                            <YAxis dataKey='name' type='category' scale='point' />
                            <CartesianGrid strokeDasharray='3 3' />
                            <Bar dataKey='passed' fill='#FEC84B'>
                                <LabelList dataKey='passed' position='inside' />
                            </Bar>
                            <Bar dataKey='failed' fill='#1635B7'>
                                <LabelList dataKey='failed' position='inside' />
                            </Bar>
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default DashboardBarChart;