import React from 'react';
import { CoreAuthConfirmation } from '@packages';
import { useTranslation } from 'react-i18next';

const SignUpConfirmation = () => {
    const { t } = useTranslation();
    return (
        <CoreAuthConfirmation
            title={t('CONGRATULATIONS')}
            description={t('WE_HAVESENT_A_VERIFICATION_EMAIL_TO_YOUR_INBOX')}
            subDescription={t('PLEASE_COMPLETE_THE_VERIFICATION_PROCESS_VIA_EMAIL_TO_ACCESS_YOUR_ACCOUNT')}
            linkTo='login'
        />
    );
};

export default SignUpConfirmation;
