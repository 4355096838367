import React from 'react';
import TemplateMenuWrapper from '../../template-generator/template-menu-wrapper/TemplateMenuWrapper';
import './TemplateHeader.scss';
import { Checkbox, Col, Input, Row, Select, ColorPicker } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { CoreButton, CoreTitle } from '@packages';

const TemplateHeader = () => {
    return (
        <TemplateMenuWrapper>
            <div className='template-form-wrapper'>
                <div className='form-field'>
                    <Checkbox className='general-checkbox label'>Show Organization Logo</Checkbox>
                    <Dragger>
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Upload your logo or drag and drop</p>
                    </Dragger>
                </div>
                <div className='form-field'>
                    <Checkbox className='general-checkbox'>Show Organization Name</Checkbox>
                </div>
                <div className='form-field'>
                    <Checkbox className='general-checkbox'>Show Organization Address</Checkbox>
                </div>
                <CoreTitle className='title' tag='h6' text='Organization Address Format' />

                <div className='form-field'>
                    <Checkbox className='general-checkbox label'>Show Document Title</Checkbox>
                    <Input placeholder='TAX INVOICE' />
                </div>

                <div className='form-field'>
                    <label className='general-label'>Background Image</label>
                    <Row gutter={8}>
                        <Col span={12}>
                            <CoreButton text='Choose from Desktop' isFullWidth={true} />
                        </Col>
                        <Col span={12}>
                            <CoreButton text='Choose from Gallery' isFullWidth={true} />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <label className='general-label'>Image Position</label>
                    <Select
                        defaultValue='top-left'
                        style={{ width: '100%' }}
                        options={[{ value: 'top-left', label: 'Top Left' }]}
                    />
                </div>
                <div className='form-field'>
                    <label className='general-label'>Background Color</label>
                    <ColorPicker showText className='custom-color-picker' />
                </div>
                <CoreTitle className='title' tag='h6' text='Customize your header content' />

                <div className='form-field'>
                    <Checkbox className='general-checkbox'>Apply to first page only</Checkbox>
                </div>
                <CoreTitle className='doc-title' tag='h4' text='Document Title' />
                <div className='form-field'>
                    <Row gutter={8}>
                        <Col span={12}>
                            <label className='general-label'>Font Size</label>
                            <Input addonAfter='pt' defaultValue='14' />
                        </Col>
                        <Col span={12}>
                            <label className='general-label'>Font Color</label>
                            <ColorPicker showText className='custom-color-picker' />
                        </Col>
                    </Row>
                </div>
                <CoreTitle className='doc-title' tag='h4' text='Organization' />
                <div className='form-field'>
                    <Row gutter={8}>
                        <Col span={12}>
                            <label className='general-label'>Name Font Size</label>
                            <Input addonAfter='pt' defaultValue='14' />
                        </Col>
                        <Col span={12}>
                            <label className='general-label'>Name Color</label>
                            <ColorPicker showText className='custom-color-picker' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8}>
                        <Col span={12}>
                            <label className='general-label'>Phone</label>
                            <Input placeholder='Phone' />
                        </Col>
                        <Col span={12}>
                            <label className='general-label'>FAX</label>
                            <Input placeholder='FAX' />
                        </Col>
                    </Row>
                </div>
                <CoreTitle className='doc-title' tag='h4' text='Customer Name' />
                <div className='form-field'>
                    <Row gutter={8}>
                        <Col span={12}>
                            <label className='general-label'>Font Size</label>
                            <Input addonAfter='pt' defaultValue='14' />
                        </Col>
                        <Col span={12}>
                            <label className='general-label'>Font Color</label>
                            <ColorPicker showText className='custom-color-picker' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Checkbox className='general-checkbox label'>Bill To</Checkbox>
                    <Input placeholder='Bill To' />
                </div>
                <CoreTitle className='title' tag='h6' text='Billing Address Format' />
                <div className='form-field'>
                    <Checkbox className='general-checkbox label'>Ship To</Checkbox>
                    <Input placeholder='Ship To' />
                </div>
                <CoreTitle className='title' tag='h6' text='Shipping Address Format' />
                <CoreTitle className='doc-title' tag='h4' text='Document Information' />
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Number Field</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Invoice#' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Date Field</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Invoice Date' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Terms</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='P.O.#' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Sales Person</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Sales person' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Project</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Project Name' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Subject</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Subject' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <Checkbox className='general-checkbox'>Number Field</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Input placeholder='Invoice#' />
                        </Col>
                    </Row>
                </div>
                <div className='form-field'>
                    <Checkbox className='general-checkbox'>Save Status Stamp</Checkbox>
                </div>

                <div className='save-button'>
                    <CoreButton type='primary' text='Save' />
                </div>
            </div>
        </TemplateMenuWrapper>
    );
};
export default TemplateHeader;
