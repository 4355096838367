import React from 'react';

const UserCheck = () => {
    return (
        <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='52' height='52' rx='26' fill='url(#paint0_linear_6969_71330)' />
            <rect
                x='0.5'
                y='0.5'
                width='51'
                height='51'
                rx='25.5'
                stroke='white'
                strokeOpacity='0.2'
            />
            <path
                d='M26 29.5H21.5C20.1044 29.5 19.4067 29.5 18.8389 29.6722C17.5605 30.06 16.56 31.0605 16.1722 32.3389C16 32.9067 16 33.6044 16 35M30 32L32 34L36 30M28.5 21.5C28.5 23.9853 26.4853 26 24 26C21.5147 26 19.5 23.9853 19.5 21.5C19.5 19.0147 21.5147 17 24 17C26.4853 17 28.5 19.0147 28.5 21.5Z'
                stroke='url(#paint1_linear_6969_71330)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_6969_71330'
                    x1='0'
                    y1='0'
                    x2='52'
                    y2='52'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='white' stopOpacity='0.09' />
                    <stop offset='1' stopColor='white' stopOpacity='0' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_6969_71330'
                    x1='26'
                    y1='17'
                    x2='26'
                    y2='35'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default UserCheck;
