import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
    OrganizationList,
    OrganizationDetails,
    OrganizationUpdate,
    EntityDetails,
    CsidGenerate,
    EntityCreate,
    CsidDetails,
    DeviceCreate,
    DeviceDetails,
    DeviceUpdate,
} from '../features/organization';
import {
    RecoverAccount,
    Login,
    SignUp,
    SignUpConfirmation,
    TwoStepVerification,
    VerifyAccount,
    ResetPassword,
} from '../features/auth';
import Maintenance from '../features/maintenance/Maintenance';
import { LayoutWrapper, RequireAuth, RequireAnonymous, EntityListTable } from '@components';
import { CustomerCreate, CustomerDetails, CustomerList } from '../features/customer';
import { InventoryCreate, InventoryList, InventoryStepper } from '../features/inventory';
import NewInvoice from '../features/e-invoice/pages/new-invoice/NewInvoice';
import ActivateAccount from '../features/auth/activate-account/ActivateAccount';
import { InvoiceDetails, InvoiceList } from '../features/e-invoice';
import Preference from '../features/preference/preference-tabs/PreferenceTabs';
import CoreContactUs from '../packages/CoreContactUs/CoreContactUs';
import { UserRoles } from '@config/user-roles';
import ResetPasswordConfirmation from '../features/auth/reset-password/ResetPasswordConfirmation';
import Dashboard from '../features/dashboard/Dashboard';
import Authorization from '../features/authorization/Authorization';
import OrganizationEntityUpdate from '@components/organization/organization-entity-update/OrganizationEntityUpdate';
import {
    AddDevices,
    EinvoicePhase,
    OrganizationSetupCompleted,
    AddOrganization,
    AddEntity,
    OrganizationSetUpRoot,
    OrganizationInitiate,
} from '../features/organization-setup';
import { ArchivesList } from '../features/archives';
import { ActionList, PolicyList, RoleList, UserList } from '../features/access-management';
import TemplateHeader from '../features/template-generator/template-header/TemplateHeader';
import TemplateTotal from '../features/template-generator/template-total/TemplateTotal';
import TemplateFooter from '../features/template-generator/template-footer/TemplateFooter';
import TemplateProperties from '../features/template-generator/template-properties/TemplateProperties';
import TemplateItemTable from '../features/template-generator/template-item-table/TemplateItemTable';
import TemplateList from '../features/templates/template-list/TemplateList';
import SetUserRoles from '../features/access-management/user/create-user/set-user-role/SetUserRoles';
import ReviewPermissions from '../features/access-management/user/create-user/review-permissions/ReviewPermissions';
import CreateUserName from '../features/access-management/user/create-user/CreateUserName';
import { CreateTicket, MyTicket, SupportHome } from '../features/support';
import { BillingHome } from '../features/billing';
import { SettingTabs, TwoFactorAuthenticationStepper } from '../features/setting';
import { Profile } from '../features/profile';
import DashboardTwo from '../features/dashboard/pages/dashboard-two/DashboardTwo';
import {
    Subscribe,
    CancelAutoRenewal,
    PricingPlans,
    SubscribeSuccess,
    ManageSubscription,
    PlanComparison,
} from '../features/subscribe';
import AdminTemplateList from '../features/admin-template/admin-template-list/AdminTemplateList';
import AdminTemplateCreate from '../features/admin-template/admin-template-create/AdminTemplateCreate';
import AdminTemplateUpdate from '../features/admin-template/admin-template-update/AdminTemplateUpdate';
import AdminTemplateDetails from '../features/admin-template/admin-template-details/AdminTemplateDetails';
import {
    UploadInvoiceDetails,
    UploadInvoiceList,
    UploadInvoiceStepper,
    ConfirmUploadInvoice,
} from '../features/upload-invoices';
import Home from './../home/Home';
import { ApiServicesList, CreateService } from '../features/api-services';
import SubscribeInvoiceDetails from '../features/subscribe/pages/subscribe-invoice-details/SubscribeInvoiceDetails';
import { PricingCalculator } from '../features/pricing-calculator';

const AppRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='authorization' element={<Authorization />}></Route>
            <Route element={<RequireAuth allowedRoles={[UserRoles.organizationAdmin]} />}>
                <Route
                    path='/organization-setup/:organizationId'
                    element={<OrganizationSetUpRoot />}
                >
                    <Route
                        path='/organization-setup/:organizationId/organization-information'
                        element={<AddOrganization />}
                    ></Route>
                    <Route
                        path='/organization-setup/:organizationId/entity'
                        element={<AddEntity />}
                    ></Route>
                    <Route
                        path='/organization-setup/:organizationId/invoice-phase'
                        element={<EinvoicePhase />}
                    ></Route>
                    <Route
                        path='/organization-setup/:organizationId/device'
                        element={<AddDevices />}
                    ></Route>
                    <Route
                        path='/organization-setup/:organizationId/complete'
                        element={<OrganizationSetupCompleted />}
                    ></Route>
                </Route>
                <Route path='/organization-initiate' element={<OrganizationInitiate />}></Route>
                <Route path='organizations/create' element={<OrganizationInitiate />}></Route>
                <Route path='subscribe/pricing-plans' element={<PricingPlans />}></Route>
                <Route path='pricing-calculator' element={<PricingCalculator />}></Route>
                <Route
                    path='subscribe/manage-subscription'
                    element={<ManageSubscription />}
                ></Route>
                <Route path='plan-comparison' element={<PlanComparison />}></Route>
                <Route path='subscribe/subscribe-success' element={<SubscribeSuccess />}></Route>
                <Route path='' element={<LayoutWrapper />}>
                    <Route path='subscribe' element={<Subscribe />}></Route>
                    <Route
                        path='subscribe/invoice/:invoiceId'
                        element={<SubscribeInvoiceDetails />}
                    ></Route>
                    <Route
                        path='subscribe/cancel-auto-renewal'
                        element={<CancelAutoRenewal />}
                    ></Route>
                    <Route path='dashboar2' element={<Dashboard />}></Route>
                    <Route path='dashboard' element={<DashboardTwo />}></Route>
                    <Route path='organizations' element={<OrganizationList />}></Route>
                    <Route path='entity' element={<EntityListTable />}></Route>

                    <Route
                        path='organizations/update/:organizationId'
                        element={<OrganizationUpdate />}
                    ></Route>
                    <Route
                        path='organizations/:organizationId'
                        element={<OrganizationDetails />}
                    ></Route>
                    <Route path='profile' element={<Profile />}></Route>
                    <Route path='setting' element={<SettingTabs />}></Route>
                    <Route
                        path='organizations/:organizationId/entity/create'
                        element={<EntityCreate />}
                    ></Route>
                    <Route path='entity/create' element={<EntityCreate />}></Route>
                    <Route path='organizations/csid/generate' element={<CsidGenerate />}></Route>
                    <Route
                        path='organizations/:organizationId/entity/:entityId'
                        element={<EntityDetails />}
                    ></Route>
                    <Route
                        path='organizations/entity/update/:entityId'
                        element={<OrganizationEntityUpdate />}
                    ></Route>
                    <Route path='organizations/csid/:id' element={<CsidDetails />}></Route>
                    <Route path='customers' element={<CustomerList />}></Route>
                    <Route path='customers/create' element={<CustomerCreate />}></Route>
                    <Route path='customers/update/:id' element={<CustomerCreate />}></Route>
                    <Route path='customers/details/:id' element={<CustomerDetails />}></Route>
                    <Route path='upload-invoices' element={<UploadInvoiceStepper />}></Route>
                    <Route path='confirm-upload-invoice' element={<ConfirmUploadInvoice />}></Route>
                    <Route path='upload-invoices/list' element={<UploadInvoiceList />}></Route>
                    <Route
                        path='upload-invoices/details/:id'
                        element={<UploadInvoiceDetails />}
                    ></Route>
                    <Route path='inventory-stepper' element={<InventoryStepper />}></Route>
                    <Route path='items' element={<InventoryList />}></Route>
                    <Route path='items/create' element={<InventoryCreate />}></Route>
                    <Route path='items/details' element={<InventoryList />}></Route>
                    <Route path='items/update/:id' element={<InventoryCreate />}></Route>
                    <Route
                        path='setting/two-factor-authentication'
                        element={<TwoFactorAuthenticationStepper />}
                    ></Route>
                    <Route path='preference' element={<Preference />}></Route>
                    <Route path='e-invoices' element={<InvoiceList />}></Route>
                    <Route path='e-invoices/create' element={<NewInvoice />}></Route>
                    <Route path='create-credit-note' element={<NewInvoice />}></Route>
                    <Route path='create-debit-note' element={<NewInvoice />}></Route>
                    <Route path='create-simplified-credit-note' element={<NewInvoice />}></Route>
                    <Route path='create-simplified-debit-note' element={<NewInvoice />}></Route>
                    <Route
                        path='e-invoices/details/:invoiceId'
                        element={<InvoiceDetails />}
                    ></Route>
                    <Route
                        path='organizations/:organizationId/device/create'
                        element={<DeviceCreate />}
                    ></Route>
                    <Route
                        path='organizations/:organizationId/device/update/:deviceId'
                        element={<DeviceUpdate />}
                    ></Route>
                    <Route
                        path='organizations/:organizationId/device/:deviceId'
                        element={<DeviceDetails />}
                    ></Route>
                    <Route path='/' element={<Navigate replace to='/dashboard' />}></Route>

                    <Route path='/support' element={<SupportHome />}></Route>
                    <Route path='/support/my-ticket' element={<MyTicket />}></Route>
                    <Route path='/support/create-ticket' element={<CreateTicket />}></Route>
                    <Route path='/api-services' element={<ApiServicesList />}></Route>
                    <Route path='/api-services/create' element={<CreateService />}></Route>
                    <Route path='/api-services/update/:id' element={<CreateService />}></Route>
                    <Route path='/api-services/details/:id' element={<CreateService />}></Route>
                </Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={[UserRoles.admin]} />}>
                <Route path='' element={<LayoutWrapper />}>
                    <Route path='users' element={<UserList />}></Route>
                    <Route path='users/create-name' element={<CreateUserName />}></Route>
                    <Route path='users/set-roles' element={<SetUserRoles />}></Route>
                    <Route path='users/permissions' element={<ReviewPermissions />}></Route>
                    <Route path='roles' element={<RoleList />}></Route>
                    <Route path='policies' element={<PolicyList />}></Route>
                    <Route path='actions' element={<ActionList />}></Route>
                    <Route path='archives' element={<ArchivesList />}></Route>
                    <Route path='/template' element={<AdminTemplateList />}></Route>
                    <Route path='/template-create' element={<AdminTemplateCreate />}></Route>
                    <Route path='/template-update' element={<AdminTemplateUpdate />}></Route>
                    <Route
                        path='/template-details/:TemplateId'
                        element={<AdminTemplateDetails />}
                    ></Route>
                    <Route
                        path='/template/update/:TemplateId'
                        element={<AdminTemplateUpdate />}
                    ></Route>
                    <Route path='/pdf-templates' element={<TemplateList />}></Route>
                    <Route path='/template-header' element={<TemplateHeader />}></Route>
                    <Route path='/template-total' element={<TemplateTotal />}></Route>
                    <Route path='/template-properties' element={<TemplateProperties />}></Route>
                    <Route path='/template-footer' element={<TemplateFooter />}></Route>
                    <Route path='/template-item-table' element={<TemplateItemTable />}></Route>
                    <Route path='/billing' element={<BillingHome />}></Route>
                </Route>
            </Route>

            <Route path='*' element={<Navigate replace to='/' />}></Route>

            <Route path='contact-us' element={<CoreContactUs />}></Route>
            <Route element={<RequireAnonymous />}>
                <Route path='sign-up' element={<SignUp />}></Route>
                <Route path='sign-up-confirmation' element={<SignUpConfirmation />}></Route>
                <Route path='verify-account' element={<VerifyAccount />}></Route>
                <Route path='login' element={<Login />}></Route>
                <Route path='recover-account' element={<RecoverAccount />}></Route>
                <Route path='activate-account' element={<ActivateAccount />}></Route>
                <Route path='reset-password' element={<ResetPassword />}></Route>
                <Route
                    path='reset-password-confirmation'
                    element={<ResetPasswordConfirmation />}
                ></Route>
                <Route path='maintenance' element={<Maintenance />}></Route>
                <Route path='two-step-verification' element={<TwoStepVerification />}></Route>
            </Route>
        </Routes>
    );
};

export default AppRouter;
