import { RequestStatus } from '@models/async-status.enum';
import { Setup2FAResult } from '@models/setup-2FA.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SETUP_2FA } from '@service/setup-2FA.service';
import { RootState } from '@store/store';

interface ISetup2FAState {
    initialSetup: {
        status: RequestStatus;
        data?: Setup2FAResult;
    };
    isRequest2FASetup: boolean;
}

const initialState: ISetup2FAState = {
    initialSetup: {
        status: 'idle',
        data: undefined,
    },
    isRequest2FASetup: false,
};

export const FetchEnable2FARequest = createAsyncThunk('enabled2FA', async () => {
    try {
        const response = await SETUP_2FA.enable2FA();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});
export const setup2FA = createSlice({
    name: 'setup 2fa',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(FetchEnable2FARequest.pending, (state) => {
                state.initialSetup.status = 'loading';
            })
            .addCase(FetchEnable2FARequest.rejected, (state) => {
                state.initialSetup.status = 'failed';
            })
            .addCase(FetchEnable2FARequest.fulfilled, (state, action) => {
                state.initialSetup.status = 'idle';
                state.initialSetup.data = action.payload;
                state.isRequest2FASetup = true;
            });
    },
});

export const selectInitialSetup2FA = (state: RootState) => state.enable2FAData.initialSetup;
export const isRequest2FASetup = (state: RootState) => state.enable2FAData.isRequest2FASetup;

export default setup2FA.reducer;
