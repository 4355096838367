import { CoreLoginWrapper, CoreLoginPage } from '@packages';
import { useTranslation } from 'react-i18next';


const Login = () => {
    const { t } = useTranslation();

    return (
        <CoreLoginWrapper>
            <CoreLoginPage title={t('WELCOME_BACK')} subTitle={t('LOGIN_TO_YOUR_ACCOUNT')} />
        </CoreLoginWrapper>
    );
};

export default Login;
