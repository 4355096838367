import React from 'react';

const NoteCheck = () => {
    return (
        <svg
            width='52'
            height='52'
            viewBox='0 0 52 52'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='52' height='52' rx='26' fill='url(#paint0_linear_6969_71374)' />
            <rect
                x='0.5'
                y='0.5'
                width='51'
                height='51'
                rx='25.5'
                stroke='white'
                strokeOpacity='0.2'
            />
            <path
                d='M34 26.5V20.8C34 19.1198 34 18.2798 33.673 17.638C33.3854 17.0735 32.9265 16.6146 32.362 16.327C31.7202 16 30.8802 16 29.2 16H22.8C21.1198 16 20.2798 16 19.638 16.327C19.0735 16.6146 18.6146 17.0735 18.327 17.638C18 18.2798 18 19.1198 18 20.8V31.2C18 32.8802 18 33.7202 18.327 34.362C18.6146 34.9265 19.0735 35.3854 19.638 35.673C20.2798 36 21.1198 36 22.8 36H26M28 25H22M24 29H22M30 21H22M28.5 33L30.5 35L35 30.5'
                stroke='url(#paint1_linear_6969_71374)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_6969_71374'
                    x1='0'
                    y1='0'
                    x2='52'
                    y2='52'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='white' stopOpacity='0.09' />
                    <stop offset='1' stopColor='white' stopOpacity='0' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_6969_71374'
                    x1='26.5'
                    y1='16'
                    x2='26.5'
                    y2='36'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default NoteCheck;
