import InlineGenericInput from '@components/common/InlineGenericInput';
import { CoreButton, CoreTitle } from '@packages';
import { SubscribeAPI } from '@service/subscription.service';
import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

export interface IAddCuponModalProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export interface IAddCoupon {
    Code: string;
}

const AddCuponModal = ({ isVisible, onClose, onConfirm }: IAddCuponModalProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<any>({ mode: 'onTouched' });

    const onModalClose = () => {
        reset();
        onClose();
    };

    const onSubmit = async (data: IAddCoupon) => {
        setIsLoading(true);
        try {
            const res = await SubscribeAPI.GetApplyCoupon(data?.Code);
            message.success('Coupon Applied Successfully');
            reset();
            onConfirm();
        } catch (error) {
            message.error('Coupon Applied Failed');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            className='billing-action-modal-wrapper'
            open={isVisible}
            onCancel={onModalClose}
            closable={true}
            maskClosable={false}
            footer={[]}
        >
            <div>
                <CoreTitle text='Apply Code' tag='h4' />
                <form className='margin-top-16' onSubmit={handleSubmit(onSubmit)}>
                    <InlineGenericInput
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='Code'
                        level='Coupon or Activation Code'
                        errorMessageSize='small'
                        marginBottom={10}
                    />
                    <div>
                        <div
                            key='footer'
                            style={{ display: 'flex', gap: '8px', justifyContent: 'end' }}
                        >
                            <CoreButton
                                size='small'
                                type='basic'
                                text='Close'
                                onClick={onModalClose}
                            />
                            <CoreButton
                                size='small'
                                type='primary'
                                text='Submit'
                                htmlType='submit'
                                loading={isLoading}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default AddCuponModal;
