import React from 'react';
import { CoreButton, CoreTitle } from '@packages';
import { LeftOutlined, RedoOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './SetUserRole.scss';
import { Button, Col, Row, Table } from 'antd';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { useForm } from 'react-hook-form';
import { ColumnsType } from 'antd/es/table';

interface ISetUserRolesTableData {
    key: React.Key;
    RolesName: string;
    ManageBy: string;
    CreateAt: string;
}

const SetUserRoles = () => {
    const {
        control,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<any>({
        mode: 'onTouched',
        defaultValues: {
            TaxScheme: 'VAT',
        },
    });

    const navigate = useNavigate();
    const prev = () => {
        navigate(`/users/create-name`);
    };
    const handleSubmit = async () => {
        navigate(`/users/permissions`);
    };
    const cancel = () => {
        navigate(`/users`);
    };

    const columns: ColumnsType<ISetUserRolesTableData> = [
        {
            title: 'Role Name',
            dataIndex: 'RolesName',
        },
        {
            title: 'Manage by',
            dataIndex: 'ManageBy',
        },
        {
            title: 'Created Date',
            dataIndex: 'CreateAt',
        },
    ];

    const userRoles = [
        {
            key: '1',
            RolesName: 'Organization admin',
            ManageBy: 'Hash',
            CreateAt: '3/4/2023',
        },
        {
            key: '2',
            RolesName: 'App User',
            ManageBy: 'Hash',
            CreateAt: '3/4/2023',
        },
    ];

    return (
        <div>
            <div className='step-user-title-area'>
                <div className='left-area'>
                    <CoreButton text='' onClick={prev} icon={<LeftOutlined />} />
                    <div className='content'>
                        <CoreTitle tag='h6' text='User Details' />
                        <p>Step 2 of 3</p>
                    </div>
                </div>
                <div className='step-button-group'>
                    <CoreButton text='Cencel' onClick={cancel} />
                    <CoreButton type='primary' onClick={handleSubmit} text='Continue' />
                </div>
            </div>
            <div className='set-user-wrapper'>
                <div className='wrapper-title'>
                    <CoreTitle tag='h4' text='Permissions Option' />
                </div>
                <div className='permission-dropdown'>
                    <Row gutter={26}>
                        <Col span={6}>
                            <InlineGenericSelect
                                levelCol={24}
                                inputCol={24}
                                control={control}
                                errors={errors}
                                placeHolder='Select a Role'
                                dataKey='RolesName'
                                level='Roles'
                                options={[
                                    { value: 'User', label: 'User' },
                                    { value: 'Admin', label: 'Admin' },
                                ]}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                            />
                        </Col>
                        <Col span={6}>
                            <InlineGenericSelect
                                levelCol={24}
                                inputCol={24}
                                control={control}
                                errors={errors}
                                dataKey='ManageBy'
                                level='Manage By'
                                placeHolder='Manage By'
                                options={[{ value: 'Hash', label: 'Hash' }]}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                            />
                        </Col>
                    </Row>
                </div>
                <div className='set-user-accessibility-bar'>
                    <CoreTitle tag='h4' text='Roles (345)' />
                    <div className='accessibility-bar-actions'>
                        <Button icon={<RedoOutlined className='refresh-icon' />} />
                        <CoreButton text='Create Role' type='primary' />
                    </div>
                </div>
                <div className='set-user-table'>
                    <Table
                        // loading={isLoading}
                        columns={columns}
                        dataSource={userRoles}
                        rowClassName='cursor-pointer'
                        pagination={false}
                    />
                </div>
            </div>
        </div>
    );
};
export default SetUserRoles;
