import { IApiService, IApiServices } from '@models/api-services.model';
import { RequestStatus } from '@models/async-status.enum';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiServicesAPI } from '@service/api-services.service';
import { RootState } from '@store/store';

interface IApiServiceState {
    list: {
        data: IApiServices;
        status: RequestStatus;
    };
    single: {
        data?: IApiService;
        status: RequestStatus;
    };
}

const initialState: IApiServiceState = {
    list: {
        data: [],
        status: 'idle',
    },
    single: {
        data: undefined,
        status: 'idle',
    },
};

export const getApiServiceById = createAsyncThunk('apiServices/single', async (apiKey: string) => {
    try {
        const response = await ApiServicesAPI.getApiServiceById(apiKey);
        return response;
    } catch (error: any) {
        console.log(error);
        throw error?.response?.data?.Message || error?.message;
    }
});

export const getApiServices = createAsyncThunk('apiServices/list', async () => {
    try {
        const response = await ApiServicesAPI.getApiServices();
        return response;
    } catch (error: any) {
        console.log(error);
        throw error?.response?.data?.Message || error?.message;
    }
});
export const apiServicesSlice = createSlice({
    name: 'apiServices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getApiServices.pending, (state) => {
            state.list.status = 'loading';
        });
        builder.addCase(getApiServices.fulfilled, (state, action) => {
            state.list.data = action.payload;
            state.list.status = 'idle';
        });
        builder.addCase(getApiServices.rejected, (state, action) => {
            state.list.data = [];
            state.list.status = 'failed';
        });
    },
});

export const selectApiServices = (state: RootState) => state.apiServices.list;

export default apiServicesSlice.reducer;
