import { Col, Row, Select, Tooltip } from 'antd';
import { FC, Key, useCallback, useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import GenericErrorMessage, { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { CustomRules } from '@models/rules.model';
import { isFiledFoundError, isRequiredValue } from '@utils/errorMessageOption';
import { debounce } from 'lodash';
const Option = Select.Option;
interface IInlineGenericInfiniteScrollingSelectParams {
    control: Control<any, any>;
    errors: FieldErrors<any>;
    level: string;
    dataKey: string;
    options: { value: Key | boolean; label: string; hoverName?: string }[];
    setFormValue: UseFormSetValue<any>;
    onChange?: (value: any, option?: any) => void;
    hasMore: boolean;
    getMoreData: () => void;
    rules?: CustomRules;
    allowClear?: boolean;
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    disabled?: boolean;
    placeHolder?: string;
    children?: React.ReactNode;
    hideError?: boolean;
    gutter?: number;
    loading?: boolean;
    tooltip?: string;
    sorting?: boolean;
    className?: string;
    showSearch?: boolean;
    errorMessageSize?: ErrorMessageSize;
    marginBottom?: number;
    createNewOption?: {
        title: string;
        onCreateNew: () => void;
    };
    onSearch?: (value?: string) => void;
    onSearchText?: string;
}

const InlineGenericInfiniteScrollingSelectParams: FC<
    IInlineGenericInfiniteScrollingSelectParams
> = ({
    control,
    errors,
    level,
    dataKey,
    rules,
    options,
    setFormValue,
    onChange,
    allowClear,
    disabled,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    placeHolder,
    children,
    hideError,
    gutter = 130,
    loading,
    tooltip,
    className = '',
    showSearch = false,
    errorMessageSize,
    marginBottom = 15,
    hasMore,
    getMoreData,
    createNewOption,
    onSearch,
    onSearchText,
}) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState<string | undefined>(undefined);

    const onScroll = async (event: any) => {
        const target = event.target;
        if (!loading && hasMore && target.scrollTop + target.offsetHeight === target.scrollHeight) {
            target.scrollTo(0, target.scrollHeight);
            getMoreData();
        }
    };

    const changeHandler = (value: string) => {
        setSearchText(value);
    };

    const debouncedChangeHandler = useCallback(debounce(changeHandler, 700), [searchText]);

    useEffect(() => {
        onSearch && onSearch(searchText);
    }, [searchText]);

    return (
        <Row gutter={gutter} align='middle' style={{ marginBottom: marginBottom }}>
            {showLevel && (
                <Col span={levelCol}>
                    <label className='general-label'>
                        {t(level)}
                        {rules?.required && <span className='required-field'>*</span>}
                        {tooltip && <GenericTooltip text={tooltip} />}
                    </label>
                </Col>
            )}
            <Col span={inputCol}>
                <div className='general-select-wrapper'>
                    <Controller
                        render={({ field }) => (
                            <Select

                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                className={className}
                                {...field}
                                onPopupScroll={onScroll}
                                showSearch={showSearch}
                                style={{ width: '100%' }}
                                placeholder={placeHolder ? placeHolder : `Search ${level}`}
                                optionFilterProp='children'
                                getPopupContainer={(trigger) => trigger.parentNode}
                                filterOption={(input, option: any) => {
                                    return option;
                                }}
                                onChange={(value, option) => {
                                    if (option?.isCreateNew) {
                                        createNewOption?.onCreateNew();
                                        return;
                                    }
                                    setFormValue &&
                                        setFormValue(dataKey, value || null, {
                                            shouldValidate: true,
                                        });
                                    onChange && onChange(value, option?.data);
                                }}
                                allowClear={allowClear || false}
                                disabled={disabled}
                                loading={loading}
                                onSearch={debouncedChangeHandler}
                            >
                                <>
                                    {createNewOption?.title && (
                                        <Option key={'create-new-button'} isCreateNew={true}>
                                            <span className='create-new-button'>
                                                {createNewOption.title}
                                            </span>
                                        </Option>
                                    )}
                                </>
                                {options.map((selectOption) => (
                                    <Option
                                        key={selectOption?.value?.toString()}
                                        value={selectOption?.value}
                                        data={selectOption}
                                    >
                                        <Tooltip title={t(selectOption?.hoverName || '')}>
                                            {selectOption?.label}
                                        </Tooltip>
                                    </Option>
                                ))}
                            </Select>
                        )}
                        control={control}
                        name={dataKey}
                        rules={{ ...rules, required: isRequiredValue(rules) }}
                    />
                    {!hideError && (
                        <GenericErrorMessage
                            errors={errors}
                            dataKey={dataKey}
                            rules={rules}
                            level={level}
                            errorMessageSize={errorMessageSize}
                        />
                    )}
                    {children}
                </div>
            </Col>
        </Row>
    );
};

export default InlineGenericInfiniteScrollingSelectParams;
