import { Modal } from 'antd';
import './SubscriptionTrailModal.scss';
import { CoreButton, CoreTitle } from '@packages';
import { WarningOutlined } from '@ant-design/icons';
import { useAppSelector } from '@store/hooks';
import { selectIsTrialSubscriptions } from '@store/slices/subscribeSlice';

interface IDeleteModalProps {
    isSubscriptionTrailModalOpen: boolean;
    handleUpgrade: () => void;
    handelLogOut: () => void;
}

const SubscriptionTrailModal = (props: IDeleteModalProps) => {
    const { isSubscriptionTrailModalOpen, handleUpgrade, handelLogOut } = props;
    const isTrial = useAppSelector(selectIsTrialSubscriptions);

    return (
        <>
            <Modal
                className='subscription-trail-modal-wrapper'
                width={492}
                closable={false}
                open={isSubscriptionTrailModalOpen}
                // onOk={handleUpgrade}
                // onCancel={handelLogOut}
                maskClosable={false}
                footer={[
                    <CoreButton key={2} text='Upgrade' type='primary' onClick={handleUpgrade} />,
                    <CoreButton key={1} text='Log out' type='basic' onClick={handelLogOut} />,
                ]}
            >
                <WarningOutlined className='warning-icon' />
                <CoreTitle
                    tag='h3'
                    text={isTrial == true ? 'Your trial has expired.' : 'Your subscription has expired'}
                />
                <p>
                    To continue using Hash Solution’s e-Invoice services, you may upgrade your
                    subscription or log out.
                </p>
            </Modal>
        </>
    );
};

export default SubscriptionTrailModal;
