import AppRouter from './routes/Router';
import { ConfigProvider } from 'antd';
import { antdThemeToken } from './config/antdTheme';
import './packages/scss/style.scss';
import './assets/scss/main.scss';
import locale from 'antd/locale/en_US';

function App() {
    return (
        <ConfigProvider theme={antdThemeToken} locale={locale}>
            <AppRouter />
        </ConfigProvider>
    );
}

export default App;
