import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Select } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import './EinvoiceFilterInputs.scss';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { IInvoicePayload } from '@models/invoice.model';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { GET_ALL_INVOICE_TYPES } from '@utils/InvoiceUtils';
import InlineGenericDate from '@components/common/InlineGenericDate';
import { useTranslation } from 'react-i18next';

interface IEinvoiceFiltersProps {
    handleFilterSubmit: (data: IInvoicePayload) => void;
    defaultValues?: Partial<IInvoicePayload>;
    onInvoiceTypeChange: (value: string) => void;
    invoiceSelectType: string;
}

const EinvoiceFilterInputs = (props: IEinvoiceFiltersProps) => {
    const { t } = useTranslation();
    const { handleFilterSubmit, defaultValues, onInvoiceTypeChange, invoiceSelectType } = props;
    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        reset,
    } = useForm<IInvoicePayload>({ mode: 'onTouched' });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const onSubmit = (data: IInvoicePayload) => {
        handleFilterSubmit(data);
    };

    return (
        <div className='einvoice-filter-wrapper'>
            <div className='einvoice-filter-inner-wrapper'>
                <div className='margin-bottom-20'>
                    <CoreTitle tag='h6' text={t('FILTERS')} />
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row justify='end'>
                        <Row gutter={10}>
                            <Col span={24}>
                                <InlineGenericInput
                                    inputCol={24}
                                    levelCol={24}
                                    control={control}
                                    errors={errors}
                                    dataKey='OrganizationName'
                                    level={t('ORGANIZATION_NAME_ARABIC')}
                                    errorMessageSize='small'
                                    marginBottom={10}
                                />
                            </Col>

                            <Col span={24}>
                                <InlineGenericInput
                                    inputCol={24}
                                    levelCol={24}
                                    control={control}
                                    errors={errors}
                                    dataKey='OrganizationNameEn'
                                    level={t('ORGANIZATION_NAME_ENGLISH')}
                                    errorMessageSize='small'
                                    marginBottom={10}
                                />
                            </Col>
                            <Col span={12}>
                                <label className='general-label'>{t('INVOICE_TYPE')}</label>
                                <Select
                                    style={{ width: '100%' }}
                                    defaultValue={'All'}
                                    value={invoiceSelectType}
                                    placeholder='Select a type'
                                    className='invoice-select'
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    onChange={onInvoiceTypeChange}
                                    options={[
                                        { label: t('ALL_E_INVOICES'), value: 'All' },
                                        ...GET_ALL_INVOICE_TYPES.map((invoice) => ({
                                            ...invoice,
                                            label: t(invoice.displayKey),
                                        })),
                                    ]}
                                />
                            </Col>
                            <Col span={12}>
                                <InlineGenericInput
                                    inputCol={24}
                                    levelCol={24}
                                    control={control}
                                    errors={errors}
                                    dataKey='CustomerName'
                                    level={t('CUSTOMER_NAME')}
                                    errorMessageSize='small'
                                    marginBottom={10}
                                />
                            </Col>
                            <Col span={12}>
                                <InlineGenericSelect
                                    inputCol={24}
                                    levelCol={24}
                                    control={control}
                                    errors={errors}
                                    dataKey='ZatcaStatus'
                                    level={t('ZATCA_STATUS')}
                                    placeHolder={t('ZATCA_STATUS')}
                                    errorMessageSize='small'
                                    marginBottom={0}
                                    setFormValue={setFormValue}
                                    options={[
                                        { value: 'CLEARED', label: t('CLEARED') },
                                        { value: 'FAILED', label: t('FAILED') },
                                        { value: 'PENDING', label: t('PENDING') },
                                        { value: 'CLEARED_WITH_WARNING', label: t('CLEARED_WITH_WARNING') },
                                    ]}
                                    allowClear
                                />
                            </Col>
                            <Col md={12}>
                                <InlineGenericDate
                                    control={control}
                                    levelCol={24}
                                    inputCol={24}
                                    errors={errors}
                                    dataKey='ActualDeliveryDate'
                                    level={t('ACTUAL_DELIVERY_DATE')}
                                    setFormValue={setFormValue}
                                    marginBottom={0}
                                    errorMessageSize='small'
                                    allowClear
                                />
                            </Col>
                            <Col md={12}>
                                <InlineGenericDate
                                    control={control}
                                    levelCol={24}
                                    inputCol={24}
                                    errors={errors}
                                    dataKey='CreatedAt'
                                    level={t('CREATED_AT')}
                                    setFormValue={setFormValue}
                                    marginBottom={15}
                                    errorMessageSize='small'
                                    allowClear
                                />
                            </Col>
                            <Col md={12}>
                                <InlineGenericDate
                                    control={control}
                                    levelCol={24}
                                    inputCol={24}
                                    errors={errors}
                                    dataKey='UpdatedAt'
                                    level={t('UPDATED_AT')}
                                    setFormValue={setFormValue}
                                    marginBottom={15}
                                    errorMessageSize='small'
                                    allowClear
                                />
                            </Col>
                        </Row>

                        <CoreButton
                            type='primary'
                            htmlType='submit'
                            isFullWidth={false}
                            text={t('APPLY_NOW')}
                        />
                    </Row>
                </form>
            </div>
        </div>
    );
};

export default EinvoiceFilterInputs;
