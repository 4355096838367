import { ITier } from '@models/billing.model';
import { CoreTitle } from '@packages';
import { payAsGoTiredData, payAsYouGoId } from '@utils/payAsGoTireData';
import { Divider } from 'antd';
import React from 'react';
import './OrderSummary.scss';

export interface IOrderSummaryProps {
    planId: string;
    recurringType: string;
    singleTier: ITier | null;
}

const OrderSummary = ({ planId, recurringType, singleTier }: IOrderSummaryProps) => {
    return (
        <div className='order-summary-component-wrapper'>
            <div className='summary-title-wrapper'>
                <CoreTitle tag='h4' text='Summary' />
                <p>Starts on July, 1, 2024</p>
            </div>
            <div className='summary-details-wrapper'>
                <p className='normal-text'>{recurringType === 'month' ? 'Monthly' : 'Yearly'}</p>
                <p className='bold-text'>
                    {planId === payAsYouGoId
                        ? `${payAsGoTiredData?.YearlyPrice} ${payAsGoTiredData?.Currency}`
                        : `${
                              recurringType === 'month'
                                  ? singleTier?.MonthlyPrice
                                  : singleTier?.YearlyPrice
                          } ${singleTier?.Currency}`}
                </p>
            </div>

            <Divider style={{ margin: '0', borderBlockStart: '1px solid #EAECF0' }} />

            <div className='summary-details-wrapper'>
                <p className='normal-text'>Total after using credit</p>
                <p className='bold-text'>
                    {planId === payAsYouGoId
                        ? `${payAsGoTiredData?.YearlyPrice} ${payAsGoTiredData?.Currency}`
                        : `${
                              recurringType === 'month'
                                  ? singleTier?.MonthlyPrice
                                  : singleTier?.YearlyPrice
                          } ${singleTier?.Currency}`}
                </p>
            </div>
        </div>
    );
};

export default OrderSummary;
