import { CoreButton, CoreSearchInput } from '@packages';
import './UploadInvoiceList.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UploadListTable from './../../components/upload-list-table/UploadListTable';

const UploadInvoiceList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>();

    return (
        <div className='customer-list-container'>
            <div className='margin-30'>
                <div className='filter-header'>
                    <CoreSearchInput placeholder='Search' handleInputChange={setQuery} />
                    <CoreButton
                        className='submit-button'
                        type='primary'
                        text={t('CREATE_NEW')}
                        isFullWidth={true}
                        onClick={() => navigate('/upload-invoices')}
                    />
                </div>
            </div>
            <div className='margin-top-20'>
                <UploadListTable />
            </div>
        </div>
    );
};
export default UploadInvoiceList;
