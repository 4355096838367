import { CoreButton } from '@packages';
import { GET_CURRENCIES_CODE_OPTIONS, GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import { Col, Input, Modal, Row, Select } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

interface IBillingAlertEditProps {
    isOpenModal: boolean;
    onCancelModal: () => void;
    onUpdateAlert: () => void;
}
export default function BillingAlertEdit({
    isOpenModal,
    onCancelModal,
    onUpdateAlert,
}: IBillingAlertEditProps) {
    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        watch,
        reset,
    } = useForm<any>({
        mode: 'onChange',
        defaultValues: {
            Prefix: GET_DEFAULT_CURRENCY_CODE,
        },
    });
    const onSubmit = () => {
        onCancelModal();
        reset();
    };
    const onCancelButton = () => {
        onCancelModal();
        reset();
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal
                width={800}
                open={isOpenModal}
                closable={false}
                title={'Please enter a billing alert amount'}
                footer={[
                    <CoreButton key={1} text={'Cancel'} type={'basic'} onClick={onCancelButton} />,
                    <CoreButton
                        key={2}
                        text={'Save'}
                        type={'primary'}
                        onClick={() => {
                            handleSubmit(onSubmit)();
                        }}
                        className='submit-modal-button'
                    />,
                ]}
            >
                <div className='billing-alert-info'>
                    You will receive an email whenever your monthly balance reaches the specified
                    amount below
                </div>

                <Row align='middle' gutter={20}>
                    <Col span={24}>
                        <Controller
                            name='Amount'
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type='number'
                                    className='input-custom-group remove-dropdown'
                                    addonBefore={
                                        <Controller
                                            name='Prefix'
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    className='amount-selection'
                                                    options={GET_CURRENCIES_CODE_OPTIONS}
                                                />
                                            )}
                                            control={control}
                                        />
                                    }
                                    placeholder={'Amount'}
                                />
                            )}
                            control={control}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.Amount?.type === 'required' && (
                            <span className='error-message'>Amount is required</span>
                        )}
                    </Col>
                </Row>
            </Modal>
        </form>
    );
}
