import { Col, Row } from 'antd';
import './InvoicePlatform.scss';
import UserConsole from '../../svg/UserConsole';
import { FallOutlined } from '@ant-design/icons';
import { CoreButton } from '@packages';
import RedWave from '../../svg/RedWave';

const InvoicePlatform = () => {
    return (
        <div className='invoice-platform-wrapper'>
            <div className='platform-header'>
                <h1 className='header-title'>What is the Hash E-invoicing platform?</h1>
                <p className='header-sub-title'>
                    Hash e-invoicing platform is a fully automated solution which can be connected
                    with your company ERP or billing system to generate compliant e-invoices in KSA.
                    Hash E-invoicing platform is being designed to work with any types of ERP or
                    billing system and with seamless integration.
                </p>
            </div>
            <div className='margin-top-16'>
                <Row gutter={[24, 24]}>
                    <Col xs={24} lg={10}>
                        <div className='platform-reasons'>
                            <div className='platform-card'>
                                <div className='platform-icon-wrapper'>
                                    <UserConsole />
                                </div>
                                <p className='platform-card-title'>Central user console</p>
                            </div>
                            <div className='platform-card'>
                                <div className='platform-icon-wrapper'>
                                    <UserConsole />
                                </div>
                                <p className='platform-card-title'>Integration Module</p>
                            </div>
                            <div className='platform-card'>
                                <div className='platform-icon-wrapper'>
                                    <UserConsole />
                                </div>
                                <p className='platform-card-title'>Data Validation Module</p>
                            </div>
                            <div className='platform-card'>
                                <div className='platform-icon-wrapper'>
                                    <UserConsole />
                                </div>
                                <p className='platform-card-title'>Generation Module</p>
                            </div>
                            <div className='platform-card'>
                                <div className='platform-icon-wrapper'>
                                    <UserConsole />
                                </div>
                                <p className='platform-card-title'>E-Archiving Module</p>
                            </div>
                            <div className='platform-card'>
                                <div className='platform-icon-wrapper'>
                                    <UserConsole />
                                </div>
                                <p className='platform-card-title'>Comms Module</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} lg={14}>
                        <div className='platform-description'>
                            <p className='description-text'>
                                Our e-invoicing platform has a centralized user console that enables
                                our users to operate the full functionality of the tool in a
                                streamlined and user-centric window. Centralized access control
                                enables the user to access all applications, websites and other
                                computing systems from a single profile, with the same credentials
                                from any location. All information assets in control of the user are
                                subject to unified identity management.
                            </p>
                            <div className='platform-card-wrapper'>
                                <div className='platform-card-inner-wrapper'>
                                    <div className='card-header'>
                                        <p className='header-title'>Visits</p>
                                        <CoreButton text='30days' />
                                    </div>

                                    <div className='statistic-section'>
                                        <p className='statistic-number'>4,123</p>
                                        <FallOutlined className='arrow-icon' />
                                        <div className='percentage-section'>
                                            <p className='percentage-text'>2%</p>
                                            <p className='percentage-line'>below projected</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='wave-wrapepr'>
                                    <RedWave />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default InvoicePlatform;
