import React, { useState } from 'react';
import { Col, Input, Row, Checkbox, Divider, Switch } from 'antd';
import { CoreButton } from '@packages';
import './PreferenceInvoicesGeneral.scss';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const PreferenceInvoicesGeneral = () => {
    const [value, setValue] = useState('');
    const onChange = (checkedValues: CheckboxValueType[]) => {
        console.log('checked = ', checkedValues);
    };

    return (
        <div className='invoice-general-tab-wrapper'>
            <div className='invoice-general-tab-inner-wrapper'>
                <Checkbox.Group onChange={onChange}>
                    <Row>
                        <Col span={24} className='margin-bottom-10'>
                            <Checkbox className='general-checkbox' value='A'>
                                Allow editing of Sent Invoice?
                            </Checkbox>
                        </Col>
                        <Col span={24} className='margin-bottom-10'>
                            <Checkbox className='general-checkbox' value='B'>
                                Associate and display expense receipts in Invoice PDF
                            </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>

                <Divider className='general-preference-divider' />

                <p className='invoice-general-tab-header'>Payments</p>
                <Checkbox.Group onChange={onChange}>
                    <Row>
                        <Col span={24} className='margin-bottom-10'>
                            <Checkbox className='general-checkbox' value='C'>
                                Get notified when customers pay online
                            </Checkbox>
                        </Col>
                        <Col span={24} className='margin-bottom-10'>
                            <Checkbox className='general-checkbox' value='D'>
                                DO you want to include the payment receipt along with the Thank You
                                Note?
                            </Checkbox>
                        </Col>
                        <Col span={24} className='margin-bottom-10'>
                            <Checkbox className='general-checkbox' value='E'>
                                Automate thank you note to customer on receipt of online payment.
                            </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>

                <Divider className='general-preference-divider' />

                <div className='invoice-general-tab-header-group'>
                    <p className='invoice-general-tab-header'>Invoice QR Code</p>
                    <div className='invoice-general-tab-switch'>
                        <Switch />
                        <p>Disabled</p>
                    </div>
                </div>
                <p className='invoice-general-tab-sub-header'>
                    Enable and configure the QR code you want to display on the PDF copy of an
                    Invoice. Your customers can scan the QR code using their device to access the
                    URL or other information that you configure.
                </p>

                <Divider className='general-preference-divider' />

                <div className='invoice-general-tab-header-group'>
                    <p className='invoice-general-tab-header'>Record Locking</p>
                    <div className='invoice-general-tab-switch'>
                        <Switch />
                        <p>Disabled</p>
                    </div>
                </div>
                <p className='invoice-general-tab-sub-header'>
                    Record locking helps you control who can make changes to certain records within
                    your organization. This can be useful if you want to protect important
                    information or prevent accidental changes from being made. When you bck a
                    record, only users with permission to edit locked records will be able to edit
                    or delete it.{' '}
                    <span className='green-text'>
                        How to lock a Invoice <QuestionCircleOutlined />
                    </span>
                </p>
                <Divider className='general-preference-divider' />
                <p className='invoice-general-tab-header'>Zero-value line items</p>
                <Checkbox className='general-checkbox' value='G'>
                    Hide zero-value line items
                </Checkbox>
                <p className='invoice-general-tab-sub-header'>
                    Choose whether you want to hide zero-value line items in an invoice's PDF and
                    the Customer Portal. They will still be visible while editing an invoice. This
                    setting will not apply to invoices whose total is zero.
                </p>

                <p className='invoice-general-tab-header'>Terms & Conditions</p>
                <TextArea
                    className='margin-bottom-30'
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder='Enter a description...'
                    autoSize={{ minRows: 5, maxRows: 5 }}
                />
            </div>
            <div className='invoice-general-tab-button-group'>
                <CoreButton text='Cancel' />
                <CoreButton type='primary' text='Save' />
            </div>
        </div>
    );
};

export default PreferenceInvoicesGeneral;
