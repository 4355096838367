import React from 'react';
import { CoreButton, CoreTitle } from '@packages';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './ReviewPermissions.scss';

interface ISetReviewPermissionTableData {
    key: React.Key;
    UserName: string;
    PasswordReset: string;
}

const ReviewPermissions = () => {
    const navigate = useNavigate();
    const prev = () => {
        navigate(`/users/create-name`);
    };
    const handleSubmit = async () => {
        navigate(`/users/permissions`);
    };
    const cancel = () => {
        navigate(`/users`);
    };

    const columns: ColumnsType<ISetReviewPermissionTableData> = [
        {
            title: 'User Name',
            dataIndex: 'UserName',
        },
        {
            title: 'Require password reset',
            dataIndex: 'PasswordReset',
        },
    ];

    const reviewPermission = [
        {
            key: '1',
            UserName: 'Abed@hashsolution.com',
            PasswordReset: 'Yes',
        },
    ];

    return (
        <div>
            <div className='step-user-title-area'>
                <div className='left-area'>
                    <CoreButton text='' onClick={prev} icon={<LeftOutlined />} />
                    <div className='content'>
                        <CoreTitle tag='h6' text='Set Roles' />
                        <p>Step 3 of 3</p>
                    </div>
                </div>
                <div className='step-button-group'>
                    <CoreButton text='Cancel' onClick={cancel} />
                    <CoreButton type='primary' onClick={handleSubmit} text='Create User' />
                </div>
            </div>
            <div className='review-permission-wrapper'>
                <div className='review-wrapper-title'>
                    <CoreTitle tag='h4' text='Review and create' />
                </div>
                <div className='review-permission-table'>
                    <Table
                        // loading={isLoading}
                        columns={columns}
                        dataSource={reviewPermission}
                        rowClassName='cursor-pointer'
                        pagination={false}
                    />
                </div>
                <div className='permission-summary'>
                    <CoreTitle tag='h4' text='Permissions Summary' />
                    <div className='roles-checkbox'>
                        <p>Roles</p>
                    </div>
                    <Checkbox.Group
                        onChange={() => {
                            console.log('hello');
                        }}
                    >
                        <Row>
                            <Col span={24} className='margin-bottom-19'>
                                <Checkbox className='general-checkbox' value='A'>
                                    App user
                                </Checkbox>
                            </Col>
                            <Col span={24} className='margin-bottom-19'>
                                <Checkbox className='general-checkbox' value='B'>
                                    Admin
                                </Checkbox>
                            </Col>
                            <Col span={24} className='margin-bottom-19'>
                                <Checkbox className='general-checkbox' value='C'>
                                    Organization-admin
                                </Checkbox>
                            </Col>
                            <Col span={24} className='margin-bottom-19'>
                                <Checkbox className='general-checkbox' value='D'>
                                    Organization-user
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </div>
            </div>
        </div>
    );
};
export default ReviewPermissions;
