import { APP_CONFIG } from '@config/constants';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { AxiosError } from 'axios/index';
import { PdfConfigurationPayload } from '@models/pdf.model';

export const PdfAPI = {
    createPdfConfiguration: async (payload: PdfConfigurationPayload) => {
        const url = APP_CONFIG.pdfEngineUrl + `EngineCommand/Configuration`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { ...payload });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
