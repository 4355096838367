import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { UtcToLocalDate } from '@utils/date';
import { DeleteOutlined } from '@ant-design/icons';
import { CoreSearchInput } from '@packages';
import { Table, Tag, message } from 'antd';
import CreateRole from '../create-role/CreateRole';
import { getRoles, selectRolesInfo } from '@store/slices/roleSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ConfirmationModal } from '@components';
import { RoleAPI } from '@service/role.service';
import { IPolicy, IRole } from '@models/role.model';
import { selectLoggedUserInfo } from '@store/slices/userSlice';

const columns: ColumnsType<any> = [
    {
        title: 'Role Name',
        dataIndex: 'RoleName',
    },
    {
        title: 'Policies',
        dataIndex: 'Policies',
        render: (policies: IPolicy[]) => (
            <span>
                {policies.map((policy) => (
                    <Tag key={policy.PolicyName} color='#285FF6' style={{ marginBottom: 2 }}>
                        {policy.PolicyName}
                    </Tag>
                ))}
            </span>
        ),
    },
    {
        title: 'Create Date',
        dataIndex: 'CreatedAt',
        render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
    },
];

function RoleList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const roleInfo = useAppSelector(selectRolesInfo);
    const [isDeleteModalShow, setShowDeleteModal] = useState<boolean>(false);
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false);
    const { userDetails } = useAppSelector(selectLoggedUserInfo);

    const [roleName, setRoleName] = useState<string>();
    const roleColumns: ColumnsType<IRole> = [
        ...columns,
        {
            title: '',
            dataIndex: 'ItemId',
            render: (ItemId: string, data: IRole) => (
                <div className='action-buttons'>
                    <div
                        className='invite-client-icon'
                        onClick={() => {
                            setRoleName(data?.RoleName);
                            setShowDeleteModal(true);
                        }}
                        aria-hidden='true'
                    >
                        <DeleteOutlined />
                    </div>
                </div>
            ),
        },
    ];
    const getAllRoles = () => {
        dispatch(
            getRoles({
                PageLimit: 20,
            }),
        );
    };
    const onDeleteRole = async () => {
        if (!roleName) return;
        setButtonLoading(true);

        try {
            await RoleAPI.deleteRole({ ManagedBy: userDetails?.ManagedBy, RoleName: roleName });
            setButtonLoading(false);
            message.success('Role Deleted Successfully');
            setRoleName('');
            getAllRoles();
            setShowDeleteModal(false);
        } catch {
            message.error('Role Delete Failed');
            setButtonLoading(false);
        }
    };

    const onHideModal = () => {
        setShowDeleteModal(false);
        setRoleName('');
    };
    useEffect(() => {
        getAllRoles();
    }, []);

    return (
        <div>
            <div className='filter-header margin-30'>
                <CoreSearchInput placeholder='Search' />
                <div className='create-button-group'>
                    <CreateRole onPageRefresh={getAllRoles} />
                </div>
            </div>
            <div className='ksa-table-wrapper table-auto-scroll-without-header'>
                <Table
                    loading={roleInfo.status === 'loading' ? true : false}
                    columns={roleColumns}
                    dataSource={roleInfo?.data}
                    rowClassName='cursor-pointer'
                    pagination={false}
                />
            </div>
            <ConfirmationModal
                title='Delete Role'
                description='Do you want to delete this Role?'
                buttonText='Delete'
                isDeleteModalOpen={isDeleteModalShow}
                handleDeleteOk={onDeleteRole}
                handleDeleteCancel={onHideModal}
                isLoading={isButtonLoading}
            />
        </div>
    );
}

export default RoleList;
