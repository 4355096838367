import { CoreBackButton, CoreButton, CoreTitle } from '@packages';
import { Checkbox, Col, Input, Row, Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';

const entityItems = [
    {
        value: '1',
        label: 'Entity 1',
    },
    {
        value: '2',
        label: 'Entity 2',
    },
    {
        value: '3',
        label: 'Entity 3',
    },
    {
        value: '4',
        label: 'Entity 4',
    },
    {
        value: '5',
        label: 'Entity 5',
    },
    {
        value: '6',
        label: 'Entity 6',
    },
];

const CsidGenerate = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<any>({ mode: 'onChange' });
    const onSubmit = (data: any) => {
        alert(JSON.stringify(data));
    };

    return (
        <div className='container create-entity'>
            <CoreBackButton />
            <CoreTitle tag='h1' text='Generate CSID' className='title-margin' />
            <div className='round-box-shadow'>
                <CoreTitle tag='h4' text='Generate CSID Information' />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Organization</label>
                                <Controller
                                    name='organization'
                                    render={({ field }) => (
                                        <Select
                                            style={{ width: '100%' }}
                                            showSearch
                                            placeholder='Organization'
                                            optionFilterProp='children'
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').includes(input)
                                            }
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '')
                                                    .toLowerCase()
                                                    .localeCompare(
                                                        (optionB?.label ?? '').toLowerCase(),
                                                    )
                                            }
                                            options={entityItems}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.organization?.type === 'required' && (
                                    <span className='error-message'>Organization is required</span>
                                )}
                            </div>
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Entity</label>
                                <Controller
                                    name='entity'
                                    render={({ field }) => (
                                        <Select
                                            style={{ width: '100%' }}
                                            showSearch
                                            placeholder='Entity'
                                            optionFilterProp='children'
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').includes(input)
                                            }
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '')
                                                    .toLowerCase()
                                                    .localeCompare(
                                                        (optionB?.label ?? '').toLowerCase(),
                                                    )
                                            }
                                            options={entityItems}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.entity?.type === 'required' && (
                                    <span className='error-message'>Entity is required</span>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Common Name</label>
                                <Controller
                                    name='commonName'
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            ref={field.ref}
                                            placeholder='Common Name'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.commonName?.type === 'required' && (
                                    <span className='error-message'>Common Name is required</span>
                                )}
                            </div>
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Serial Number</label>
                                <Controller
                                    name='serialNumber'
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            ref={field.ref}
                                            placeholder='Serial Number'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.serialNumber?.type === 'required' && (
                                    <span className='error-message'>Serial Number is required</span>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Invoice Type</label>
                                <Controller
                                    name='invoiceType'
                                    render={({ field }) => (
                                        <div className='margin-top-20'>
                                            <Checkbox {...field} ref={field.ref}>
                                                Simplified Invoice
                                            </Checkbox>
                                            <Checkbox {...field} ref={field.ref}>
                                                Standard Invoice
                                            </Checkbox>
                                        </div>
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.invoiceType?.type === 'required' && (
                                    <span className='error-message'>Invoice Type</span>
                                )}
                            </div>
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Organization Unit Name</label>
                                <Controller
                                    name='organizationUnitName'
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            ref={field.ref}
                                            placeholder='Organization Unit Name'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.organizationUnitName?.type === 'required' && (
                                    <span className='error-message'>
                                        Organization Unit Name is required
                                    </span>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Country Name</label>
                                <Controller
                                    name='countryName'
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            ref={field.ref}
                                            placeholder='Country Name'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.countryName?.type === 'required' && (
                                    <span className='error-message'>Country Name is required</span>
                                )}
                            </div>
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Location Address</label>
                                <Controller
                                    name='locationAddress'
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            ref={field.ref}
                                            placeholder='Location Address'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.locationAddress?.type === 'required' && (
                                    <span className='error-message'>
                                        Location Address is required
                                    </span>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Industry Business Category</label>
                                <Controller
                                    name='industryBusinessCategory'
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            ref={field.ref}
                                            placeholder='Industry Business Category'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.industryBusinessCategory?.type === 'required' && (
                                    <span className='error-message'>
                                        Industry Business Category is required
                                    </span>
                                )}
                            </div>
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>OTP</label>
                                <Controller
                                    name='otp'
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            ref={field.ref}
                                            placeholder='OTP'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.otp?.type === 'required' && (
                                    <span className='error-message'>OTP is required</span>
                                )}
                            </div>
                        </Col>
                    </Row>

                    <div className='right-side-submit-button'>
                        <CoreButton
                            type='primary'
                            htmlType='submit'
                            isFullWidth={false}
                            text='Generate'
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CsidGenerate;
