import { Col, Image, Row, Tabs, TabsProps } from 'antd';
import './BusinessModel.scss';
import SmallLogo from '../../svg/SmallLogo';
import organization_list from '../../assets/organization.png';

const BusinessModel = () => {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Marketplaces',
            children:
                'Some of the largest online marketplaces use E-Invoice Solutions to power their invoicing needs. Whether it is for their own intermediary services or to support their marketplace sellers with compliant invoicing.',
        },
        {
            key: '2',
            label: 'E-commerce',
            children:
                'Some of the largest online marketplaces use E-Invoice Solutions to power their invoicing needs. Whether it is for their own intermediary services or to support their marketplace sellers with compliant invoicing.',
        },
        {
            key: '3',
            label: 'Self-billing',
            children:
                'Some of the largest online marketplaces use E-Invoice Solutions to power their invoicing needs. Whether it is for their own intermediary services or to support their marketplace sellers with compliant invoicing.',
        },
    ];

    return (
        <div className='business-model-wrapper'>
            <h1 className='business-model-title'>
                E-invoicing was built for complex, online business models.
            </h1>

            <div>
                <Row gutter={[48, 16]}>
                    <Col xs={24} lg={10}>
                        <div className='tabs-section'>
                            <SmallLogo />
                            <Tabs defaultActiveKey='1' items={items} className='models-tabs' />
                        </div>
                    </Col>
                    <Col xs={24} lg={14}>
                        <div className='business-image-wrapper'>
                            <Image src={organization_list} preview={false} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default BusinessModel;
