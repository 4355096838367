import React, { useState } from 'react';
import './BillingTab.scss';
import { Col, Row } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import BillingList from '../billing-list/BillingList';
import BillingFormModal from '../billing-form-modal/BillingFormModal';

const BillingTab = () => {
    const [isAddModalVisible, setAddIsModalVisible] = useState<boolean>(false);

    return (
        <div className='billing-tab-wrapper'>
            <CoreTitle tag='h4' text='Billing Addresses' />
            <Row gutter={[24, 12]} className='margin-top-16'>
                <Col xs={24} lg={10} xl={8}>
                    <div className='billing-tab-actions'>
                        <CoreTitle tag='h4' text='Manage your billing addresses' />
                        <CoreButton
                            type='primary'
                            text='Add Address'
                            onClick={() => setAddIsModalVisible(true)}
                        />
                    </div>
                    <BillingFormModal
                        isVisible={isAddModalVisible}
                        onClose={() => setAddIsModalVisible(false)}
                        onConfirm={() => setAddIsModalVisible(false)}
                    />
                </Col>
                <Col xs={24} lg={12} xl={10}>
                    <BillingList />
                </Col>
            </Row>
        </div>
    );
};

export default BillingTab;
