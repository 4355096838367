import { useState } from 'react';
import { Checkbox, Col, Input, Row, Radio, RadioChangeEvent, Space, ColorPicker } from 'antd';
import TemplateMenuWrapper from '../../template-generator/template-menu-wrapper/TemplateMenuWrapper';
import './TemplateTotal.scss';
import { CoreButton, CoreTitle } from '@packages';
import GenericTooltip from '@components/common/GenericTooltip';

type TabPosition = 'labels' | 'layout';
const TemplateTotal = () => {
    const [mode, setMode] = useState<TabPosition>('labels');

    const handleModeChange = (e: RadioChangeEvent) => {
        setMode(e.target.value);
    };
    return (
        <TemplateMenuWrapper>
            <div className='template-form-wrapper'>
                <div className='top-label-layout'>
                    <Radio.Group
                        onChange={handleModeChange}
                        value={mode}
                        style={{ marginBottom: 8 }}
                    >
                        <Radio.Button value='labels'>Labels</Radio.Button>
                        <Radio.Button value='layout'>Layout</Radio.Button>
                    </Radio.Group>
                </div>

                {mode === 'labels' && (
                    <>
                        <div className='form-field'>
                            <Checkbox className='general-checkbox'>Show Total Section</Checkbox>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <Checkbox className='general-checkbox'>Sub Total</Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Sub Total' />
                                </Col>
                            </Row>
                        </div>

                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <Checkbox className='general-checkbox'>
                                        Shipping Charges
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Shipping Charges' />
                                </Col>
                            </Row>
                        </div>

                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <Checkbox className='general-checkbox'>Discount</Checkbox>
                                    <GenericTooltip text='Help Text' />
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Discount' />
                                </Col>
                            </Row>
                        </div>

                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <Checkbox className='general-checkbox'>
                                        Total Taxable Amount
                                    </Checkbox>
                                    <GenericTooltip text='Help Text' />
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Amount' />
                                </Col>
                            </Row>
                        </div>

                        <div className='form-field'>
                            <Checkbox className='general-checkbox'>Show Tax Details</Checkbox>
                        </div>

                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label'>Total</label>
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Total' />
                                </Col>
                            </Row>
                        </div>

                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label'>Currency Symbol</label>
                                </Col>
                                <Col span={12}>
                                    <Radio.Group onChange={() => {}} value={1}>
                                        <Space direction='vertical'>
                                            <Radio value={1}>Before amount</Radio>
                                            <Radio value={2}>After amount</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-field'>
                            <Checkbox className='general-checkbox'>Quantity</Checkbox>
                        </div>

                        <div className='form-field'>
                            <Checkbox className='general-checkbox'>Show Payment Details</Checkbox>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label'>Payment Made</label>
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Payment Made' />
                                </Col>
                            </Row>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label'>Credits Applied</label>
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Credits Applied' />
                                </Col>
                            </Row>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label'>Amount Withheld</label>
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Amount Withheld' />
                                </Col>
                            </Row>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label'>Write Off Amount</label>
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Write Off Amount' />
                                </Col>
                            </Row>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label'>Balance Due</label>
                                </Col>
                                <Col span={12}>
                                    <Input placeholder='Balance Due' />
                                </Col>
                            </Row>
                        </div>

                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={24}>
                                    <Checkbox className='general-checkbox'>
                                        Show amount in words
                                    </Checkbox>
                                    <GenericTooltip text='Help Text' />
                                </Col>
                            </Row>
                        </div>
                        <div className='save-button'>
                            <CoreButton type='primary' text='Save' />
                        </div>
                    </>
                )}

                {mode === 'layout' && (
                    <>
                        <CoreTitle className='doc-title' tag='h4' text='Total(Subtotal,Tax)' />
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label margin-bottom-0'>
                                        Font Size
                                    </label>
                                </Col>
                                <Col span={12}>
                                    <Input addonAfter='pt' defaultValue='10' />
                                </Col>
                            </Row>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label margin-bottom-0'>
                                        Font Color
                                    </label>
                                </Col>
                                <Col span={12}>
                                    <ColorPicker showText className='custom-color-picker' />
                                </Col>
                            </Row>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <Checkbox className='general-checkbox label margin-bottom-0'>
                                        Background Color
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <ColorPicker showText className='custom-color-picker' />
                                </Col>
                            </Row>
                        </div>
                        <CoreTitle className='doc-title' tag='h4' text='Balance Due' />

                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label margin-bottom-0'>
                                        Font Size
                                    </label>
                                </Col>
                                <Col span={12}>
                                    <Input addonAfter='pt' defaultValue='10' />
                                </Col>
                            </Row>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <label className='general-label margin-bottom-0'>
                                        Font Color
                                    </label>
                                </Col>
                                <Col span={12}>
                                    <ColorPicker showText className='custom-color-picker' />
                                </Col>
                            </Row>
                        </div>
                        <div className='form-field'>
                            <Row gutter={8} align='middle'>
                                <Col span={12}>
                                    <Checkbox className='general-checkbox label margin-bottom-0'>
                                        Background Color
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <ColorPicker showText className='custom-color-picker' />
                                </Col>
                            </Row>
                        </div>
                        <div className='save-button'>
                            <CoreButton type='primary' text='Save' />
                        </div>
                    </>
                )}
            </div>
        </TemplateMenuWrapper>
    );
};
export default TemplateTotal;
