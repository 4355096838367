import React, { useState } from 'react';
import './PricingCalculator.scss';
import HeaderLayout from '@components/main-layout/header-layout/HeaderLayout';
import PricingCalculatorEmptyContainer from '../components/PricingCalculatorContainer/PricingCalculatorEmptyContainer';
import PricingCalculatorEmptyCard from '../components/PricingCalculatorCard/PricingCalculatorEmptyCard';
import EstimateModal from './../components/EstimationModal/EstimationModal';
import PricingCalculatorContainer from './../components/PricingCalculatorContainer/PricingCalculatorEstimationCard';

export default function PricingCalculator() {
    const [isVisibleModal, setVisibleModal] = useState<boolean>(false);

    const onOpenModal = () => {
        setVisibleModal(true);
    };
    return (
        <HeaderLayout>
            <div className='pricing-main-container'>
                <PricingCalculatorEmptyContainer onPressEstimation={onOpenModal} />
                <PricingCalculatorEmptyCard />
                <EstimateModal
                    isVisibleModal={isVisibleModal}
                    onHideModal={() => setVisibleModal(false)}
                />
            </div>
            <PricingCalculatorContainer />
        </HeaderLayout>
    );
}
