import { useNavigate, useParams } from 'react-router-dom';
import './UploadInvoiceDetails.scss';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    selectUploadCsvConfigDetails,
    uploadCSVConfigFileById,
} from '@store/slices/invoiceMappingSlice';
import { CoreBackButton, CoreTitle } from '@packages';
import { Col, Row, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import UploadDetailsTable from './../../components/upload-details-table/UploadDetailsTable';
import {
    creditNoteFields,
    customerDetailsFields,
    deliveryFields,
    documentLevelFields,
    invoiceDetailsFields,
    invoiceLineFields,
    paymentMeansFields,
    prepaymentFields,
    taxExemptionsFields,
} from './../../constant';
import './UploadInvoiceDetails.scss';

const UploadInvoiceDetails = () => {
    const { id: UploadInvoiceId = '' } = useParams();
    const { t } = useTranslation();

    const details = useAppSelector(selectUploadCsvConfigDetails);
    const data = details.data;
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(uploadCSVConfigFileById(UploadInvoiceId));
    }, []);

    const tabItems = [
        {
            label: `${t('Invoice Details')}`,
            key: '1',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoiceRootHeaders}
                    localHeader={invoiceDetailsFields}
                />
            ),
        },
        {
            label: `${t('Invoice Line Items')}`,
            key: '2',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoiceLineHeaders}
                    localHeader={invoiceLineFields}
                />
            ),
        },
        {
            label: `${t('Prepayment')}`,
            key: '3',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoicePrepaymentHeaders}
                    localHeader={prepaymentFields}
                />
            ),
        },
        {
            label: `${t('Delivery')}`,
            key: '4',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoiceDeliveryHeaders}
                    localHeader={deliveryFields}
                />
            ),
        },
        {
            label: `${t('Credit Note')}`,
            key: '5',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoiceReferenceHeaders}
                    localHeader={creditNoteFields}
                />
            ),
        },
        {
            label: `${t('Customer Details')}`,
            key: '6',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoiceCustomerHeaders}
                    localHeader={customerDetailsFields}
                />
            ),
        },
        {
            label: `${t('Document Level Allowance')}`,
            key: '7',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoiceAllowanceHeaders}
                    localHeader={documentLevelFields}
                />
            ),
        },
        {
            label: `${t('Payment Means')}`,
            key: '8',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoicePaymentMeansHeaders}
                    localHeader={paymentMeansFields}
                />
            ),
        },
        {
            label: `${t('Tax Exemptions')}`,
            key: '9',
            children: (
                <UploadDetailsTable
                    apiHeader={data.InvoiceTaxExemptHeaders}
                    localHeader={taxExemptionsFields}
                />
            ),
        },
    ];
    return (
        <Spin spinning={details.status === 'loading'}>
            <div className='container upload-invoice-details'>
                <CoreBackButton text={''} />
                <Row justify='space-between'>
                    <Col>
                        <CoreTitle tag='h2' text={data.MapConfigurationName} />
                        <div>
                            {t('Organization')}: {data.OrganizationId} | {t('Entity')}:{' '}
                            {data.EntityId} | {t('Device')}: {data.DeviceId}
                        </div>
                    </Col>
                </Row>

                <div className='details-tab-wrapper'>
                    <Tabs items={tabItems} />
                </div>
            </div>
        </Spin>
    );
};
export default UploadInvoiceDetails;
