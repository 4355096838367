import "./AdminTemplateUpdate.scss";
import { CoreBackButton, CoreButton } from "@packages";
import InlineGenericInput from "@components/common/InlineGenericInput";
import InlineGenericTextArea from "@components/common/InlineGenericTextArea";
import InlineGenericSelect from "@components/common/InlineGenericSelect";
import { useForm } from "react-hook-form";
import { IUpdateAdminTemplate } from "@models/template.modle";
import { AdminTemplateAPI } from "@service/adminTemplate.service";
import { unwrapResult } from "@reduxjs/toolkit";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect } from "react";
import { getAdminSingleTemplate, singleTemplateData } from "@store/slices/adminTemplateSlice";

const AdminTemplateUpdate = () => {
    const navigate = useNavigate()
    const { TemplateId } = useParams();
    const dispatch = useAppDispatch();
    const { data: template } = useAppSelector(singleTemplateData)

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        reset,
    } = useForm<IUpdateAdminTemplate>({
        mode: 'onChange',
    });

    useEffect(() => {
        dispatch(getAdminSingleTemplate({ TemplateId: TemplateId || '' }));
    }, [])

    useEffect(() => {
        if (template) {
            reset({
                TemplateName: template.TemplateName,
                TemplateHtml: template.TemplateHtml,
                PreviewHtml: template.PreviewHtml,
                TemplateType: template.TemplateType,
            });
        }
    }, [template]);


    const onSubmit = async (data: IUpdateAdminTemplate) => {
        const payload = {
            ...data,
            TemplateId: TemplateId || '',
        };
        await AdminTemplateAPI.updateAdminTemplate(payload)
            .then(unwrapResult)
            .then((result) => {
                message.success('Template Successfully Updated');
            })
            .catch((err) => {
                message.error('Template Update Failed');
            }).finally(() => {
                reset();
                navigate(`/template`)
            });
    };

    return (
        <div className='admin-template-update-wrapper'>
            <CoreBackButton text='' />
            <div className='template-update-form-wrapper'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='TemplateName'
                        level='Template Name'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericTextArea
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='TemplateHtml'
                        level='Template HTML'
                        marginBottom={20}
                        minRows={4}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericTextArea
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='PreviewHtml'
                        level='Preview Template HTML'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericSelect
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='TemplateType'
                        level='Template Type'
                        options={[
                            { label: 'Standard', value: 'Standard' },
                            { label: 'Simplified', value: 'Simplified' },
                        ]}
                        setFormValue={setFormValue}
                        errorMessageSize='small'
                        allowClear={true}
                        showSearch={false}
                        marginBottom={-5}
                    />

                    <div className='template-update-action'>
                        <CoreButton htmlType='submit' type='primary' text='Update' />
                    </div>
                </form>
            </div>
        </div>
    )
};
export default AdminTemplateUpdate;
