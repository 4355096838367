import { Modal } from 'antd';
import './file-view.scss';
import { CoreBackButton, CoreButton } from '@packages';
import { Document, Page } from 'react-pdf';
import { useEffect, useRef, useState } from 'react';
import { range } from 'lodash';

interface IPdfViewProps {
    url: string;
    isOpenModal: boolean;
    closeModal: () => void;
}

const PdfView = ({ isOpenModal, closeModal, url }: IPdfViewProps) => {
    const ref = useRef<any>(null);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        console.log(numPages);
        setNumPages(numPages);
    };
    const ranges = numPages && range(0, numPages);
    console.log(ranges);

    useEffect(() => {
        if (ref && ref.current /* + other conditions */) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [pageNumber]);

    return (
        <>
            <Modal
                open={isOpenModal}
                className='file-view-custom-modal'
                closable={false}
                maskClosable={false}
                footer={null}
            >
                <>
                    <div ref={ref}></div>
                    <CoreBackButton
                        onClick={closeModal}
                        text='Back'
                        className='file-view-back-button'
                    />
                    {numPages &&
                        range(1, numPages + 1).map((page) => (
                            <>
                                <Document key={page} file={url}>
                                    <Page pageNumber={page} />
                                </Document>
                                <div>
                                    Page {page} of {numPages}
                                </div>
                                <div style={{ width: '100%', height: 1, background: 'grey' }}></div>
                            </>
                        ))}

                    <div style={{ position: 'absolute', display: 'none' }}>
                        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default PdfView;
