import React from 'react';

const FooterLogo = () => {
    return (
        <svg
            width='192'
            height='64'
            viewBox='0 0 192 64'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_i_6971_71494)'>
                <path
                    d='M19.0252 2.78613H58.1137C59.5711 2.78613 60.6525 4.13737 60.3333 5.55934L49.2807 54.7838H11.4704C6.5087 54.7838 2.83031 50.1782 3.92735 45.3393L12.3696 8.10172C13.0744 4.9929 15.8375 2.78613 19.0252 2.78613Z'
                    fill='url(#paint0_linear_6971_71494)'
                />
            </g>
            <g filter='url(#filter1_f_6971_71494)'>
                <path
                    d='M31.1149 21.3372C31.4711 20.4985 32.2933 19.9543 33.2042 19.9543L47.3438 19.9543C48.5915 20.3057 45.8643 26.9176 45.0316 28.8289L38.3141 44.2489L20.9343 42.2478C21.7074 42.3122 25.2544 34.9422 26.1482 33.0347L31.1149 21.3372Z'
                    fill='black'
                    fillOpacity='0.88'
                />
            </g>
            <g filter='url(#filter2_i_6971_71494)'>
                <path
                    d='M25.18 19.4157C25.4146 18.379 26.3358 17.6431 27.3987 17.6431H57.6068C61.3975 17.6431 64.2074 21.172 63.3683 24.8789L56.9999 53.0117C56.7652 54.0483 55.844 54.7843 54.7812 54.7843H12.2881C14.516 54.5192 17.2872 53.5311 18.3017 49.8011L25.18 19.4157Z'
                    fill='url(#paint1_linear_6971_71494)'
                />
            </g>
            <path
                d='M154.627 25.4914L156.948 12.9352H160.23L157.909 25.4914H154.627Z'
                fill='#101828'
            />
            <path
                d='M159.069 11.1368C158.544 11.1368 158.109 10.9788 157.764 10.6627C157.42 10.3466 157.268 9.96784 157.311 9.52641C157.353 9.08498 157.574 8.70623 157.973 8.39014C158.377 8.07406 158.84 7.91602 159.36 7.91602C159.885 7.91602 160.317 8.07406 160.656 8.39014C161 8.70623 161.151 9.08498 161.109 9.52641C161.073 9.96784 160.852 10.3466 160.447 10.6627C160.049 10.9788 159.589 11.1368 159.069 11.1368Z'
                fill='#101828'
            />
            <path
                d='M109.389 8.74982L106.307 25.4914H102.944L106.026 8.74982H109.389Z'
                fill='#101828'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M78.4154 24.9519C79.328 25.475 80.4823 25.7366 81.8783 25.7366C82.9662 25.7366 83.9543 25.5895 84.8427 25.2952C85.7371 24.9955 86.5047 24.5758 87.1453 24.0363C87.7859 23.4913 88.2724 22.8537 88.6048 22.1235L85.6042 21.8128C85.3866 22.1671 85.1146 22.4668 84.7883 22.712C84.468 22.9518 84.1024 23.1317 83.6914 23.2516C83.2804 23.3714 82.8332 23.4314 82.3497 23.4314C81.6124 23.4314 81.002 23.3142 80.5185 23.0799C80.035 22.8455 79.6966 22.4804 79.5032 21.9845C79.3098 21.4886 79.2796 20.8482 79.4126 20.0635L79.4205 20.0226H89.1306L89.2847 19.1152C89.4902 18.0144 89.4992 17.0661 89.3119 16.2705C89.1245 15.4693 88.7831 14.8099 88.2875 14.2922C87.7919 13.7745 87.1846 13.393 86.4654 13.1478C85.7462 12.8971 84.9575 12.7717 84.0993 12.7717C82.7758 12.7717 81.5671 13.0469 80.4732 13.5974C79.3854 14.1423 78.4788 14.9053 77.7536 15.8862C77.0284 16.8617 76.5479 17.9953 76.3122 19.2869C76.0705 20.6003 76.1309 21.742 76.4935 22.712C76.8622 23.6766 77.5028 24.4232 78.4154 24.9519ZM79.8113 18.028C79.9262 17.5549 80.1589 17.1025 80.5095 16.671C80.8902 16.2023 81.3586 15.8208 81.9146 15.5266C82.4766 15.2268 83.081 15.077 83.7277 15.077C84.3622 15.077 84.891 15.205 85.3141 15.4612C85.7371 15.7173 86.0393 16.0688 86.2206 16.5157C86.4019 16.9571 86.4382 17.4612 86.3294 18.028H79.8113Z'
                fill='#101828'
            />
            <path
                d='M100.408 19.7365L100.852 17.3004H92.5029L92.0587 19.7365H100.408Z'
                fill='#101828'
            />
            <path
                d='M113.104 25.4914L114.445 18.1343C114.566 17.5293 114.79 17.0143 115.116 16.5893C115.449 16.1642 115.857 15.8399 116.34 15.6165C116.824 15.3876 117.352 15.2731 117.927 15.2731C118.785 15.2731 119.407 15.5129 119.794 15.9925C120.187 16.4666 120.305 17.1206 120.148 17.9544L118.761 25.4914H122.042L123.529 17.4966C123.716 16.4939 123.674 15.641 123.402 14.938C123.13 14.2295 122.671 13.6927 122.024 13.3276C121.377 12.957 120.58 12.7717 119.631 12.7717C118.585 12.7717 117.67 12.9788 116.884 13.393C116.098 13.8072 115.488 14.3658 115.053 15.0688H114.881L115.279 12.9352H112.134L109.813 25.4914H113.104Z'
                fill='#101828'
            />
            <path
                d='M139.62 12.9352L132.34 25.4914H128.714L126.076 12.9352H129.449L130.935 22.2624H131.08L136.003 12.9352H139.62Z'
                fill='#101828'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M141.78 24.9192C142.692 25.4641 143.834 25.7366 145.206 25.7366C146.554 25.7366 147.769 25.4723 148.851 24.9437C149.932 24.4096 150.83 23.663 151.543 22.7039C152.262 21.7447 152.737 20.6248 152.966 19.3441C153.202 18.0307 153.132 16.8808 152.758 15.8944C152.389 14.908 151.751 14.1423 150.845 13.5974C149.938 13.0469 148.799 12.7717 147.427 12.7717C146.08 12.7717 144.862 13.0388 143.774 13.5728C142.692 14.1015 141.795 14.8481 141.082 15.8127C140.375 16.7773 139.903 17.9026 139.667 19.1888C139.432 20.4913 139.498 21.6357 139.867 22.6221C140.236 23.6085 140.873 24.3742 141.78 24.9192ZM147.482 22.8101C146.908 23.1807 146.24 23.366 145.478 23.366C144.747 23.366 144.173 23.1862 143.756 22.8265C143.339 22.4668 143.067 21.9818 142.94 21.3714C142.813 20.761 142.819 20.0798 142.958 19.3277C143.097 18.5484 143.351 17.8427 143.72 17.2105C144.088 16.5729 144.563 16.0661 145.143 15.6901C145.729 15.3086 146.403 15.1178 147.164 15.1178C147.896 15.1178 148.467 15.3004 148.878 15.6655C149.295 16.0252 149.567 16.513 149.694 17.1288C149.821 17.7392 149.818 18.4204 149.685 19.1724C149.54 19.9517 149.28 20.6602 148.905 21.2978C148.536 21.93 148.062 22.4341 147.482 22.8101Z'
                fill='#101828'
            />
            <path
                d='M163.894 24.911C164.789 25.4614 165.931 25.7366 167.321 25.7366C168.488 25.7366 169.536 25.5459 170.467 25.1644C171.398 24.7775 172.177 24.2434 172.806 23.5622C173.44 22.8755 173.881 22.088 174.129 21.1997H170.993C170.811 21.6521 170.56 22.0363 170.24 22.3523C169.926 22.6684 169.56 22.9109 169.143 23.0799C168.726 23.2434 168.276 23.3251 167.793 23.3251C167.11 23.3251 166.545 23.1616 166.097 22.8346C165.656 22.5022 165.351 22.0308 165.182 21.4204C165.019 20.8046 165.016 20.0689 165.173 19.2133C165.324 18.3686 165.596 17.6438 165.989 17.0389C166.381 16.4339 166.862 15.9707 167.43 15.6492C167.998 15.3222 168.621 15.1587 169.297 15.1587C170.077 15.1587 170.669 15.3631 171.074 15.7718C171.479 16.1751 171.688 16.6792 171.7 17.2841H174.845C174.936 16.3794 174.782 15.5892 174.383 14.9135C173.99 14.2323 173.392 13.7064 172.588 13.3358C171.79 12.9597 170.826 12.7717 169.696 12.7717C168.336 12.7717 167.107 13.0469 166.007 13.5974C164.907 14.1423 163.994 14.9053 163.269 15.8862C162.55 16.8617 162.075 17.9871 161.846 19.2624C161.61 20.5321 161.667 21.6548 162.018 22.6303C162.375 23.6003 163 24.3606 163.894 24.911Z'
                fill='#101828'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M182.145 25.7366C180.749 25.7366 179.595 25.475 178.682 24.9519C177.77 24.4232 177.129 23.6766 176.76 22.712C176.398 21.742 176.337 20.6003 176.579 19.2869C176.815 17.9953 177.295 16.8617 178.02 15.8862C178.746 14.9053 179.652 14.1423 180.74 13.5974C181.834 13.0469 183.043 12.7717 184.366 12.7717C185.224 12.7717 186.013 12.8971 186.732 13.1478C187.451 13.393 188.059 13.7745 188.554 14.2922C189.05 14.8099 189.391 15.4693 189.579 16.2705C189.766 17.0661 189.757 18.0144 189.552 19.1152L189.397 20.0226H179.687L179.679 20.0635C179.546 20.8482 179.577 21.4886 179.77 21.9845C179.963 22.4804 180.302 22.8455 180.785 23.0799C181.269 23.3142 181.879 23.4314 182.617 23.4314C183.1 23.4314 183.547 23.3714 183.958 23.2516C184.369 23.1317 184.735 22.9518 185.055 22.712C185.382 22.4668 185.653 22.1671 185.871 21.8128L188.872 22.1235C188.539 22.8537 188.053 23.4913 187.412 24.0363C186.772 24.5758 186.004 24.9955 185.11 25.2952C184.221 25.5895 183.233 25.7366 182.145 25.7366ZM180.776 16.671C180.426 17.1025 180.193 17.5549 180.078 18.028H186.596C186.705 17.4612 186.669 16.9571 186.487 16.5157C186.306 16.0688 186.004 15.7173 185.581 15.4612C185.158 15.205 184.629 15.077 183.995 15.077C183.348 15.077 182.744 15.2268 182.181 15.5266C181.625 15.8208 181.157 16.2023 180.776 16.671Z'
                fill='#101828'
            />
            <path
                d='M87.4263 34.7123C87.9642 35.1156 88.221 35.696 88.1969 36.4535H91.4332C91.5782 35.4835 91.4362 34.6306 91.0071 33.8949C90.5841 33.1537 89.9132 32.576 88.9946 32.1619C88.082 31.7477 86.967 31.5406 85.6495 31.5406C84.3441 31.5406 83.1414 31.7477 82.0415 32.1619C80.9416 32.5706 80.026 33.1455 79.2947 33.8867C78.5634 34.6224 78.1072 35.4862 77.9259 36.478C77.7083 37.677 77.9742 38.6361 78.7236 39.3555C79.479 40.0749 80.585 40.6144 82.0415 40.9741L84.054 41.4645C84.7007 41.6171 85.2567 41.7915 85.722 41.9877C86.1934 42.1785 86.5409 42.4182 86.7645 42.7071C86.9881 42.9959 87.0576 43.3556 86.973 43.7861C86.8884 44.2657 86.6557 44.6853 86.275 45.045C85.9003 45.3993 85.4168 45.6772 84.8246 45.8788C84.2323 46.075 83.5705 46.1731 82.8393 46.1731C82.1382 46.1731 81.5218 46.0777 80.9899 45.887C80.4581 45.6963 80.0502 45.4074 79.7661 45.0205C79.4881 44.6336 79.3823 44.1485 79.4488 43.5654H76.14C75.9768 44.6717 76.1219 45.6118 76.5751 46.3857C77.0344 47.1595 77.7597 47.7508 78.7508 48.1595C79.7419 48.5628 80.9567 48.7645 82.3951 48.7645C83.8818 48.7645 85.1841 48.5574 86.3022 48.1432C87.4263 47.7236 88.3328 47.1432 89.0218 46.402C89.7168 45.6554 90.155 44.7916 90.3363 43.8107C90.4753 43.0858 90.442 42.4537 90.2365 41.9142C90.0311 41.3746 89.7047 40.9141 89.2575 40.5326C88.8163 40.1457 88.2935 39.8296 87.6892 39.5844C87.0848 39.3337 86.4503 39.1348 85.7855 38.9876L84.1175 38.5953C83.7488 38.5135 83.3862 38.4127 83.0296 38.2928C82.6791 38.1729 82.3648 38.023 82.0868 37.8432C81.8149 37.6634 81.6124 37.4454 81.4795 37.1892C81.3465 36.9331 81.3133 36.6334 81.3797 36.29C81.4583 35.8704 81.6608 35.4971 81.9871 35.1701C82.3135 34.8377 82.7426 34.5788 83.2744 34.3935C83.8123 34.2028 84.4317 34.1074 85.1328 34.1074C86.13 34.1074 86.8945 34.3091 87.4263 34.7123Z'
                fill='#101828'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M98.4384 48.7563C97.0665 48.7563 95.9243 48.4838 95.0117 47.9388C94.1052 47.3939 93.4676 46.6282 93.0989 45.6418C92.7303 44.6554 92.6638 43.5109 92.8995 42.2084C93.1352 40.9223 93.6066 39.7969 94.3137 38.8323C95.0268 37.8677 95.9243 37.1211 97.0061 36.5925C98.0939 36.0584 99.3117 35.7914 100.659 35.7914C102.031 35.7914 103.17 36.0666 104.077 36.617C104.984 37.162 105.621 37.9277 105.99 38.9141C106.364 39.9005 106.434 41.0504 106.198 42.3638C105.969 43.6444 105.494 44.7644 104.775 45.7235C104.062 46.6827 103.164 47.4293 102.083 47.9634C101.001 48.492 99.7861 48.7563 98.4384 48.7563ZM98.7103 46.3857C99.4718 46.3857 100.14 46.2004 100.714 45.8298C101.294 45.4538 101.768 44.9496 102.137 44.3175C102.512 43.6799 102.772 42.9714 102.917 42.1921C103.05 41.44 103.053 40.7588 102.926 40.1484C102.799 39.5326 102.527 39.0449 102.11 38.6852C101.699 38.3201 101.128 38.1375 100.396 38.1375C99.635 38.1375 98.9612 38.3282 98.3749 38.7097C97.7947 39.0857 97.3203 39.5926 96.9517 40.2302C96.583 40.8624 96.3292 41.5681 96.1902 42.3474C96.0512 43.0995 96.0451 43.7807 96.1721 44.3911C96.299 45.0014 96.5709 45.4864 96.9879 45.8461C97.4049 46.2058 97.9791 46.3857 98.7103 46.3857Z'
                fill='#101828'
            />
            <path
                d='M111.141 48.511L114.223 31.7695H110.942L107.86 48.511H111.141Z'
                fill='#101828'
            />
            <path
                d='M124.361 43.2303L125.711 35.9549H128.993L126.672 48.511H123.49L123.907 46.2794H123.762C123.327 46.9824 122.705 47.5573 121.895 48.0042C121.091 48.4511 120.166 48.6745 119.121 48.6745C118.208 48.6745 117.438 48.492 116.809 48.1268C116.181 47.7563 115.733 47.2195 115.468 46.5164C115.208 45.808 115.168 44.9524 115.35 43.9496L116.836 35.9549H120.118L118.722 43.4918C118.583 44.2875 118.71 44.9197 119.103 45.3884C119.502 45.857 120.094 46.0914 120.88 46.0914C121.363 46.0914 121.85 45.9851 122.339 45.7726C122.835 45.56 123.267 45.2439 123.635 44.8243C124.004 44.3992 124.246 43.8679 124.361 43.2303Z'
                fill='#101828'
            />
            <path
                d='M138.895 38.2438L139.321 35.9549H136.563L137.118 32.9466H133.836L133.285 35.9549H131.316L130.899 38.2438H132.866L131.588 45.2249C131.437 46.0096 131.5 46.6636 131.778 47.1868C132.062 47.7045 132.51 48.0887 133.12 48.3394C133.736 48.5846 134.462 48.6991 135.296 48.6827C135.761 48.6718 136.16 48.6337 136.492 48.5683C136.831 48.5029 137.1 48.4429 137.299 48.3884L137.154 46.075C137.051 46.0914 136.912 46.1159 136.737 46.1486C136.562 46.1758 136.374 46.1895 136.175 46.1895C135.903 46.1895 135.658 46.1513 135.441 46.075C135.229 45.9987 135.075 45.857 134.978 45.6499C134.888 45.4374 134.876 45.1322 134.942 44.7344L136.14 38.2438H138.895Z'
                fill='#101828'
            />
            <path
                d='M139.744 48.511L142.065 35.9549H145.347L143.026 48.511H139.744Z'
                fill='#101828'
            />
            <path
                d='M144.186 34.1565C143.661 34.1565 143.226 33.9984 142.881 33.6823C142.537 33.3663 142.386 32.9875 142.428 32.5461C142.47 32.1046 142.691 31.7259 143.09 31.4098C143.495 31.0937 143.957 30.9357 144.477 30.9357C145.002 30.9357 145.434 31.0937 145.773 31.4098C146.117 31.7259 146.268 32.1046 146.226 32.5461C146.19 32.9875 145.969 33.3663 145.564 33.6823C145.166 33.9984 144.706 34.1565 144.186 34.1565Z'
                fill='#101828'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M149.084 47.9388C149.997 48.4838 151.139 48.7563 152.511 48.7563C153.858 48.7563 155.073 48.492 156.155 47.9634C157.237 47.4293 158.134 46.6827 158.847 45.7235C159.567 44.7644 160.041 43.6444 160.271 42.3638C160.506 41.0504 160.437 39.9005 160.062 38.9141C159.693 37.9277 159.056 37.162 158.149 36.617C157.243 36.0666 156.104 35.7914 154.732 35.7914C153.384 35.7914 152.166 36.0584 151.078 36.5925C149.997 37.1211 149.099 37.8677 148.386 38.8323C147.679 39.7969 147.207 40.9223 146.972 42.2084C146.736 43.5109 146.803 44.6554 147.171 45.6418C147.54 46.6282 148.177 47.3939 149.084 47.9388ZM154.786 45.8298C154.212 46.2004 153.544 46.3857 152.783 46.3857C152.051 46.3857 151.477 46.2058 151.06 45.8461C150.643 45.4864 150.371 45.0014 150.244 44.3911C150.117 43.7807 150.124 43.0995 150.263 42.3474C150.402 41.5681 150.655 40.8624 151.024 40.2302C151.393 39.5926 151.867 39.0857 152.447 38.7097C153.033 38.3282 153.707 38.1375 154.469 38.1375C155.2 38.1375 155.771 38.3201 156.182 38.6852C156.599 39.0449 156.871 39.5326 156.998 40.1484C157.125 40.7588 157.122 41.44 156.989 42.1921C156.844 42.9714 156.584 43.6799 156.209 44.3175C155.841 44.9496 155.366 45.4538 154.786 45.8298Z'
                fill='#101828'
            />
            <path
                d='M166.573 41.1539L165.232 48.511H161.941L164.262 35.9549H167.407L167.008 38.0884H167.181C167.616 37.3854 168.226 36.8268 169.012 36.4126C169.798 35.9985 170.713 35.7914 171.759 35.7914C172.707 35.7914 173.505 35.9767 174.152 36.3473C174.799 36.7124 175.258 37.2492 175.53 37.9576C175.802 38.6607 175.844 39.5135 175.657 40.5163L174.17 48.511H170.888L172.275 40.9741C172.432 40.1403 172.315 39.4863 171.922 39.0122C171.535 38.5326 170.913 38.2928 170.054 38.2928C169.48 38.2928 168.951 38.4073 168.468 38.6361C167.984 38.8596 167.576 39.1838 167.244 39.6089C166.918 40.034 166.694 40.549 166.573 41.1539Z'
                fill='#101828'
            />
            <path
                d='M187.17 39.568L190.188 39.2738C190.158 38.2056 189.723 37.3582 188.883 36.7315C188.043 36.1047 186.795 35.7914 185.139 35.7914C184.039 35.7914 183.045 35.944 182.157 36.2492C181.268 36.5489 180.54 36.9767 179.972 37.5326C179.41 38.083 179.056 38.7397 178.911 39.5026C178.736 40.44 178.92 41.2166 179.464 41.8324C180.014 42.4428 180.936 42.876 182.229 43.1322L184.514 43.5818C185.136 43.7071 185.568 43.8761 185.81 44.0886C186.058 44.3011 186.151 44.5763 186.091 44.9142C186.018 45.3666 185.701 45.7508 185.139 46.0668C184.583 46.3775 183.9 46.5301 183.09 46.5246C182.371 46.5192 181.809 46.3748 181.404 46.0914C180.999 45.8025 180.779 45.3747 180.742 44.808L177.515 45.0859C177.509 46.2303 177.971 47.1295 178.902 47.7835C179.839 48.432 181.174 48.7563 182.909 48.7563C184.051 48.7563 185.091 48.5928 186.027 48.2658C186.97 47.9388 187.744 47.4838 188.348 46.9007C188.953 46.3121 189.327 45.6309 189.472 44.857C189.641 43.9306 189.445 43.1812 188.883 42.609C188.327 42.0368 187.414 41.6226 186.145 41.3665L183.843 40.9087C183.172 40.7615 182.713 40.5817 182.465 40.3692C182.217 40.1566 182.126 39.8814 182.193 39.5435C182.265 39.0912 182.571 38.7206 183.108 38.4318C183.646 38.1429 184.272 37.9985 184.985 37.9985C185.474 37.9985 185.873 38.0721 186.182 38.2192C186.496 38.3609 186.732 38.5517 186.889 38.7915C187.046 39.0312 187.139 39.2901 187.17 39.568Z'
                fill='#101828'
            />
            <defs>
                <filter
                    id='filter0_i_6971_71494'
                    x='3.73242'
                    y='2.78613'
                    width='56.6572'
                    height='52.5099'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='0.512293' />
                    <feGaussianBlur stdDeviation='0.512293' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0'
                    />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_6971_71494' />
                </filter>
                <filter
                    id='filter1_f_6971_71494'
                    x='1.82594'
                    y='0.845474'
                    width='64.9477'
                    height='62.5122'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feGaussianBlur
                        stdDeviation='9.55431'
                        result='effect1_foregroundBlur_6971_71494'
                    />
                </filter>
                <filter
                    id='filter2_i_6971_71494'
                    x='12.2881'
                    y='17.6431'
                    width='51.2295'
                    height='37.9096'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='0.768439' />
                    <feGaussianBlur stdDeviation='0.640366' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.51 0'
                    />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_6971_71494' />
                </filter>
                <linearGradient
                    id='paint0_linear_6971_71494'
                    x1='31.371'
                    y1='2.78613'
                    x2='31.371'
                    y2='54.7838'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_6971_71494'
                    x1='60.8025'
                    y1='19.8077'
                    x2='20.3735'
                    y2='54.6741'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#F7CB0B' />
                    <stop offset='0.468466' stopColor='#C5D53E' />
                    <stop offset='1' stopColor='#5CE3AB' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FooterLogo;
