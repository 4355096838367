export enum EInvoiceType {
    'Standard Tax Invoice' = 'STANDARD_TAX_INVOICE',
    'Simplified Tax Invoice' = 'SIMPLIFIED_TAX_INVOICE',
    'Credit Note' = 'CREDIT_NOTE',
    'Debit Note' = 'DEBIT_NOTE',
    'Simplified Credit Note' = 'SIMPLIFIED_CREDIT_NOTE',
    'Simplified Debit Note' = 'SIMPLIFIED_DEBIT_NOTE',
}

export type IInvoiceCreation = IInvoice & {
    TaxExemptions: ITaxExemptions[];
    InvoiceTypeOptions?: string;
};

export interface ITaxExemptions {
    TaxCategory: string;
    TaxExemptionCode?: string;
    TaxExemptionReason: string;
    TaxExemptionReasonCode: string;
    TaxExemptionReasonEn: string;
}

export interface IInvoiceResult {
    InvoiceList: ISingleInvoice[];
    TotalCount: number;
}

export interface ISingleInvoice {
    ClearedAt: string;
    CreatedAt: string;
    CustomerEmail: string;
    CustomerId: string;
    CustomerName: string;
    CustomerNameEn: string;
    DeviceId: string;
    EntityId: string;
    HashValidation: string;
    InvoiceId: string;
    InvoiceType: string;
    InvoiceTypeCode: string;
    ItemId: string;
    OrganizationId: string;
    OrganizationName: string;
    OrganizationNameEn: string;
    RowLevelPermissions: null;
    SubscriptionId: string;
    TaxAmount: number;
    TaxExclusiveAmount: number;
    TaxInclusiveAmount: number;
    TotalAmount: number;
    ZatcaStatus: 'CLEARED' | 'FAILED' | 'PENDING';
    IsXmlFileGenerated: boolean;
    IsPdfGenerated: boolean;
    DocumentCurrencyCode: string;
    TaxCurrencyCode: string;
    IssueDateTime: string;
    PdfFile: File;
}

export interface IInvoice {
    ItemId: string;
    OrganizationId: string;
    VatRegistrationNumber: string;
    EntityId: string;
    DeviceId: string;
    SubscriptionId: string;
    CustomerId: string;
    CustomerName: string;
    CustomerNameEn: string;
    InvoiceId: string;
    TransactionCode: string;
    IssueDateTime: string;
    IssueDate: string;
    IssueTime: string;
    InvoiceTypeCode: string;
    InvoiceType: string;
    DocumentCurrencyCode: string;
    TaxCurrencyCode: string;
    Reference: Reference;
    Delivery: Delivery;
    PaymentMeans: PaymentMeans;
    LegalMonetaryTotal: LegalMonetaryTotal;
    InvoiceLines: IInvoiceLinesItem[];
    CreatedAt: string;
    UpdatedAt: string;
    IsGeneratedFromMapper: boolean;
    IsXmlFileGenerated: boolean;
    IsPdfGenerated: boolean;
    XmlFile: File;
    PdfFile: File;
    MapperFile: File;
    DiscountType: string;
    DiscountAmount: number;
    CustomerNote: string;
    TermsAndConditions: string;
    PrepaidAmount: number;
    IsSubmittedToZATCA: boolean;
    ZatcaStatus: 'CLEARED' | 'FAILED' | 'PENDING';
    TotalAccountingTaxAmount?: number;
    ExchangeRate?: number;
    PrePayments: IPrePayments;
    AllowanceCharges: IAllowanceCharges;
    ContractDocumentID: string;
    OrderReferenceID: string;
    ZatcaResponse: any;
}

export interface Delivery {
    ActualDeliveryDateTime: string;
    ActualDeliveryDate: string;
    ActualDeliveryTime: string;
    LatestDeliveryDateTime: string;
    LatestDeliveryDate: string | null;
    LatestDeliveryTime: string;
}

export interface IInvoiceLinesItem {
    ID: number;
    SerialId: string;
    ProductId: string;
    Quantity: number;
    UnitCode: string;
    LineExtensionAmount: number;
    TaxAmount: number;
    RoundingAmount: number;
    ItemName: string;
    ItemNameEn: string;
    TaxCategory: string;
    TaxPercent: number;
    PriceAmount: number;
    BaseQuantity: number;
    AllowanceChargeIndicator: boolean;
    DiscountReason: string;
    DiscountType: '%' | 'Amount';
    DiscountAmount: number;
    TaxScheme: 'VAT';
    DiscountValue: number;
    TaxExemptionCode: string;
    Reason?: string;
    ReasonEn?: string;
    ReasonCode?: string;
    AllowanceCharges?: IInvoiceItemCharges[];
}

export interface IInvoiceItemCharges {
    AllowanceTax: AllowanceTax;
    Amount: number;
    BaseAmount?: number;
    ChargeIndicator: boolean;
    MultiplierFactorNumeric?: number;
    Reason: string;
    ReasonEn: string;
    ReasonCode: string;
}

export interface AllowanceTax {
    Category: string;
    Percent: number;
}

export interface LegalMonetaryTotal {
    LineExtensionAmount: number;
    TaxAmount: number;
    TaxExclusiveAmount: number;
    TaxInclusiveAmount: number;
    AllowanceTotalAmount: number;
    PayableAmount: number;
    PrepaidAmount: number;
}

export interface File {
    FileId: string;
    FileName: string;
}

export interface PaymentMeans {
    PaymentMeansCode: string | number;
    InstructionNote?: string;
    PaymentChannelCode: string;
    PaymentAccountIdentifier: string;
    PaymentTerms: string;
    OtherPaymentMeans: string;
}

export type IPrePayments = IPrePayment[];

export interface IPrePayment {
    Amount: number;
    DocumentId: string;
    IssueDateTime: string;
    TaxCategory: string;
    TaxPercent: number;
    Id: string;
    TaxType: string;
}

export type IAllowanceCharges = IAllowanceCharge[];

export type IAllowanceCharge = IInvoiceItemCharges & {
    Id: string;
};

export interface IAllowanceTax {
    Category: string;
    Percent: number;
    Type: string;
}

export interface Reference {
    ID: string;
    IssueDate: string;
    IssueTime: string;
    IssueDatetime: string;
    NoteReason: string;
    NoteReasonEn: string;
}

export interface IInvoicePayload {
    CustomerName?: string;
    InvoiceId?: string;
    InvoiceType?: string;
    OrganizationName?: string;
    OrganizationNameEn?: string;
    InvoiceTypeCode?: string;
    PageLimit: number;
    PageNumber: number;
    Search?: string;
    ZatcaStatus?: 'CLEARED' | 'FAILED' | 'PENDING';
    ActualDeliveryDate?: string;
    CreatedAt?: string;
    UpdatedAt?: string;
    EntityId?: string;
    DeviceId?: string;
}

export interface IInvoiceState {
    InvoiceType: string;
    InvoiceTypeCode: string;
    OrganizationId: string;
    DeviceId: string;
    InvoicePhase: 1 | 2;
    EntityId: string;
    CustomerId: string;
    Reference: {
        ID: string;
        IssueDatetime: string;
        FileId: string;
    };
    linesItems: IInvoiceLinesItem[];
    isGenerateCreditNote: boolean;
    totalAmount: number;
}
export type IInvoicePagination = Pick<IInvoicePayload, 'PageLimit' | 'PageNumber'>;
export type IInvoiceParams = Pick<IInvoicePayload, 'InvoiceTypeCode' | 'InvoiceType'>;
