import React from 'react';
import { Modal } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import { useTranslation } from 'react-i18next';
import { IApiService } from '@models/api-services.model';
import './Details-modal.scss';
import { Typography, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import InputKey from './../InputKey';
const { Text, Paragraph, Link } = Typography;

interface IDetailsModal {
    data: IApiService;
    handleOk: () => void;
    isModalOpen: boolean;
}

export default function DetailsModal(props: IDetailsModal) {
    const { t } = useTranslation();
    const { handleOk, isModalOpen, data } = props;

    const navigate = useNavigate();

    return (
        <>
            <Modal
                className='details-modal-wrapper'
                width={'550px'}
                open={isModalOpen}
                onCancel={handleOk}
                closable={false}
                footer={[<CoreButton key={1} text={t('Ok')} type='primary' onClick={handleOk} />]}
            >
                <div
                    style={{ textAlign: 'left' }} // Left-align the modal content
                >
                    <CoreTitle tag='h4' text={data?.Description} />
                    <Text>Use this key in your application by passing it with the </Text>
                    <Text strong>X-AUTH-KEY=API_KEY</Text>
                    <Text> header parameter.</Text>

                    <Row style={{ marginTop: '20px' }}>
                        <Col span={22}>
                            <InputKey apiKey={data?.APIKey} />
                        </Col>
                    </Row>

                    <Paragraph style={{ marginTop: '20px' }}>
                        <ExclamationCircleOutlined style={{ marginRight: '8px' }} />
                        This key is unrestricted. To prevent unauthorized use, we recommend
                        restricting where and for which APIs it can be used.
                        <Link onClick={() => navigate(`/api-services/update/${data?.APIKey}`)}>
                            {' '}
                            Edit API key
                        </Link>{' '}
                        to add restrictions.
                    </Paragraph>
                </div>
            </Modal>
        </>
    );
}
