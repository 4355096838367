import React from 'react';
import { Alert, Collapse } from 'antd';
import './InventoryInvoiceSelect.scss';

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const InventoryInvoiceSelect = () => {
    return (
        <div className='customer-invoice-select-wrapper'>
            <Alert className='ksa-alert' message='None of the rows can be imported' type='error' />
            <Collapse className='ksa-collapse' bordered={false}>
                <Panel header='Invoices that are ready to be imported' key='1'>
                    <p>{text}</p>
                </Panel>
                <Panel header='No. of Records skipped (100)' key='2'>
                    <p>{text}</p>
                </Panel>
                <Panel header='Unmapped Fields (20)' key='3'>
                    <p>{text}</p>
                </Panel>
            </Collapse>
        </div>
    );
};

export default InventoryInvoiceSelect;
