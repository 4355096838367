import React from 'react';
import './AboutUs.scss';
import { Col, Image, Row } from 'antd';
import about_us from '../../assets/about-us.png';
import { CoreButton } from '@packages';
import { ArrowRightOutlined } from '@ant-design/icons';

const AboutUs = () => {
    return (
        <div className='about-us-wrapper'>
            <div className='about-us-inner-wrapper'>
                <Row gutter={[24, 24]} justify='center'>
                    <Col md={24} lg={12}>
                        <div className='about-us-details'>
                            <p className='about-us-title'>E-Invoice Solutions</p>
                            <h1 className='about-us-heading'>
                                Generate tax compliant invoices globally.
                            </h1>
                            <div>
                                <CoreButton
                                    type='primary'
                                    text='Contact sales'
                                    size='middle'
                                    isFullWidth={false}
                                    icon={<ArrowRightOutlined />}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={24} lg={12}>
                        <div className='about-us-image'>
                            <Image src={about_us} alt='about us' preview={false} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AboutUs;
