import { Row, Col } from 'antd';
import './BasicInfo.scss';
import { CoreTitle } from '@packages';
import { useAppSelector } from '@store/hooks';
import { selectLoggedUserInfo } from '@store/slices/userSlice';
import _ from 'lodash';
import { UtcToLocalDate } from '@utils/date';
import { getCountryNameFromCode } from '@utils/getCountryNameFromCode';
import { useTranslation } from 'react-i18next';

const BasicInfo = () => {
    const { t } = useTranslation();
    const { DateOfBirth, Gender, Address } = useAppSelector(selectLoggedUserInfo).userDetails;

    return (
        <div className='basic-info'>
            <div className='margin-top-30'>
                <CoreTitle tag='h4' text={t('PROFILE_INFORMATION')} />
            </div>
            <Row className='margin-top-20'>
                <Col span={6}>
                    <div className='margin-bottom-10'>{t('DATE_OF_BIRTH')}</div>
                    <CoreTitle
                        tag='h6'
                        text={
                            UtcToLocalDate(DateOfBirth) === '01/01/1'
                                ? ''
                                : UtcToLocalDate(DateOfBirth)
                        }
                    />
                </Col>
                <Col span={6}>
                    <div className='margin-bottom-10'>{t('GENDER')}</div>
                    <CoreTitle tag='h6' text={_.capitalize(Gender)} />
                </Col>
                <Col span={6}>
                    <div className='margin-bottom-10'>{t('COUNTRY')}</div>
                    <CoreTitle tag='h6' text={getCountryNameFromCode(Address?.CountryCode)} />
                </Col>
                <Col span={6}>
                    <div className='margin-bottom-10'>{t('CITY')}</div>
                    <CoreTitle tag='h6' text={Address?.City} />
                </Col>
            </Row>

            {/*<Row className='margin-top-30'>*/}
            {/*    <Col span={6}>*/}
            {/*        <div className='margin-bottom-10'>{t('WEBSITE')}</div>*/}
            {/*        <a className='link' target='_blank' rel='noopener noreferrer' href={Website}>*/}
            {/*            {Website}*/}
            {/*            <ArrowIcon />*/}
            {/*        </a>*/}
            {/*    </Col>*/}
            {/*    <Col span={6}>*/}
            {/*        <div className='margin-bottom-10'>Twitter</div>*/}
            {/*        <a className='link' target='_blank' rel='noopener noreferrer' href={Twitter}>*/}
            {/*            {Twitter}*/}
            {/*            <ArrowIcon />*/}
            {/*        </a>*/}
            {/*    </Col>*/}
            {/*    <Col span={6}>*/}
            {/*        <div className='margin-bottom-10'>Linkedin</div>*/}
            {/*        <a className='link' target='_blank' rel='noopener noreferrer' href={LinkedIn}>*/}
            {/*            {LinkedIn}*/}
            {/*            <ArrowIcon />*/}
            {/*        </a>*/}
            {/*    </Col>*/}
            {/*    <Col span={6}>*/}
            {/*        <div className='margin-bottom-10'>Instagram</div>*/}
            {/*        <a className='link' target='_blank' rel='noopener noreferrer' href={Instagram}>*/}
            {/*            {Instagram}*/}
            {/*            <ArrowIcon />*/}
            {/*        </a>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    );
};

export default BasicInfo;
