import { Typography, Space, Row, Col, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './PaymentDetails.scss';
import React, { useState } from 'react';
import { selectPaymentMethods, selectPaymentMethodsIsLoading } from '@store/slices/paymentSlice';
import { useAppSelector } from '@store/hooks';
import { CoreButton, CoreTitle } from '@packages';
import AddPayment from './../add-payment/AddPayment';
import PaymentActionModal from './payment-action-modal/PaymentActionModal';
import { IPaymentMethod } from '@models/payment-method';
import PaymentCard from './payment-card/PaymentCard';

export default function PaymentDetails() {
    const [isAddPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);

    const paymentMethods = useAppSelector(selectPaymentMethods);
    const isLoading = useAppSelector(selectPaymentMethodsIsLoading);
    const [selectedPaymentInfo, setSelectedPaymentInfo] = useState<IPaymentMethod>();

    const [isDeleteModalVisible, setDeleteIsModalVisible] = useState<boolean>(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);

    console.log(paymentMethods);
    return (
        <Row style={{ padding: '20px' }}>
            <Col span={24} md={24} lg={6} style={{ marginBottom: 20 }}>
                <div className='card-details-header'>
                    {/* <WalletOutlined style={{ fontSize: 20, marginRight: 4 }} /> */}
                    <CoreTitle tag='h6' text='Card details' />
                    <p className='card-details-header-sub-title'>Select default payment method.</p>
                </div>
            </Col>
            <Col span={24} md={24} lg={18}>
                <Spin spinning={isLoading}>
                    <Space
                        direction='vertical'
                        style={{ width: '100%', maxWidth: '500px', gap: 16 }}
                    >
                        {paymentMethods.map((card, index) => (
                            <PaymentCard
                                key={index}
                                card={card}
                                onSetDefault={(card) => {
                                    setSelectedPaymentInfo(card);
                                    setIsUpdateModalVisible(true);
                                }}
                                onDelete={(card) => {
                                    setSelectedPaymentInfo(card);
                                    setDeleteIsModalVisible(true);
                                }}
                            />
                        ))}
                    </Space>
                </Spin>
                <div className='add-card-btn'>
                    <CoreButton
                        size='small'
                        antType='link'
                        text='Add New Card'
                        icon={<PlusOutlined />}
                        onClick={() => setAddPaymentModalOpen(true)}
                    />
                </div>
            </Col>
            <AddPayment
                isOpenModal={isAddPaymentModalOpen}
                onCancelModal={() => setAddPaymentModalOpen(false)}
            />
            <PaymentActionModal
                title='Are you sure you want to delete this card?'
                subTitle='This credit card will be deleted.'
                isVisible={isDeleteModalVisible}
                onClose={() => setDeleteIsModalVisible(false)}
                actionType='Delete'
                paymentInfo={selectedPaymentInfo}
            />

            <PaymentActionModal
                title='Update default card'
                subTitle='This credit card will be used by default for billing.'
                isVisible={isUpdateModalVisible}
                onClose={() => setIsUpdateModalVisible(false)}
                actionType='Update'
                paymentInfo={selectedPaymentInfo}
            />
        </Row>
    );
}
