import { PlusOutlined } from '@ant-design/icons';
import { CoreButton, CoreTitle } from '@packages';
import BillingFormModal from '../../billing/billing-form-modal/BillingFormModal';
import './BillingAddressSection.scss';
import { useState } from 'react';
import BillingCardView from '../billing-card-view/BillingCardView';
import { Radio, RadioChangeEvent } from 'antd';

export const addresses = [
    {
        addressId: '1',
        addressName: "Mujammal's House",
        addressDetails: 'Kumarpra Road, Road no 3, Sylhe, Bangladesh',
        isDefault: true,
    },
    {
        addressId: '2',
        addressName: 'Office',
        addressDetails: 'Gulshan Avenue, Dhaka, Bangladesh',
        isDefault: false,
    },
];

const BillingAddressSection = () => {
    const [isAddModalVisible, setAddIsModalVisible] = useState<boolean>(false);
    const [cardId, setCardId] = useState<string>();

    const onRadioChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setCardId(e.target.value);
    };

    return (
        <div className='billing-address-info'>
            <div className='summary-title-wrapper'>
                <CoreTitle tag='h4' text='Billing Address' />
                <CoreButton
                    text='Add New Address'
                    antType='link'
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => setAddIsModalVisible(true)}
                />
                <BillingFormModal
                    isVisible={isAddModalVisible}
                    onClose={() => setAddIsModalVisible(false)}
                    onConfirm={() => setAddIsModalVisible(false)}
                />
            </div>

            <div className='billing-address-actions'>
                <Radio.Group onChange={onRadioChange}>
                    {addresses.map((address) => (
                        <BillingCardView
                            key={address.addressId}
                            selectedCardId={cardId}
                            addressName={address.addressName}
                            addressDetails={address.addressDetails}
                            addressId={address.addressId}
                        />
                    ))}
                </Radio.Group>
            </div>
        </div>
    );
};

export default BillingAddressSection;
