import { ISelectOption } from '@components/common/InlineGenericSelect';

export type IAllowanceChargesReason = ISelectOption & { reasonCode: string; reason: string };

export const AllowanceChargesReason: IAllowanceChargesReason[] = [
    { value: 'Advertising', label: 'Advertising', reasonCode: 'AA', reason: 'Advertising' },
    {
        value: 'Telecommunication',
        label: 'Telecommunication',
        reasonCode: 'AA',
        reason: 'Telecommunication',
    },
    {
        value: 'Technical modification',
        label: 'Technical modification',
        reasonCode: 'AAC',
        reason: 'Technical modification',
    },
    {
        value: 'Job-order production',
        label: 'Job-order production',
        reasonCode: 'AAD',
        reason: 'Job-order production',
    },
    { value: 'Outlays', label: 'Outlays', reasonCode: 'AAE', reason: 'Outlays' },
    { value: 'Off-premises', label: 'Off-premises', reasonCode: 'AAF', reason: 'Off-premises' },
    {
        value: 'Additional processing',
        label: 'Additional processing',
        reasonCode: 'AAH',
        reason: 'Additional processing',
    },
    { value: 'Acceptance', label: 'Acceptance', reasonCode: 'AAS', reason: 'Acceptance' },
    { value: 'Rush delivery', label: 'Rush delivery', reasonCode: 'AAT', reason: 'Rush delivery' },
    {
        value: 'Special construction',
        label: 'Special construction',
        reasonCode: 'AAV',
        reason: 'Special construction',
    },
    {
        value: 'Airport facilities',
        label: 'Airport facilities',
        reasonCode: 'AAY',
        reason: 'Airport facilities',
    },
    { value: 'Concession', label: 'Concession', reasonCode: 'AAZ', reason: 'Concession' },
    {
        value: 'Compulsory storage',
        label: 'Compulsory storage',
        reasonCode: 'ABA',
        reason: 'Compulsory storage',
    },
    { value: 'Fuel removal', label: 'Fuel removal', reasonCode: 'ABB', reason: 'Fuel removal' },
    { value: 'Into plane', label: 'Into plane', reasonCode: 'ABC', reason: 'Into plane' },
    { value: 'Overtime', label: 'Overtime', reasonCode: 'ABD', reason: 'Overtime' },
    { value: 'Tooling', label: 'Tooling', reasonCode: 'ABF', reason: 'Tooling' },
    { value: 'Miscellaneous', label: 'Miscellaneous', reasonCode: 'ABK', reason: 'Miscellaneous' },
    {
        value: 'Additional packaging',
        label: 'Additional packaging',
        reasonCode: 'ABL',
        reason: 'Additional packaging',
    },
    { value: 'Dunnage', label: 'Dunnage', reasonCode: 'ABN', reason: 'Dunnage' },
    {
        value: 'Containerisation',
        label: 'Containerisation',
        reasonCode: 'ABR',
        reason: 'Containerisation',
    },
    {
        value: 'Carton packing',
        label: 'Carton packing',
        reasonCode: 'ABS',
        reason: 'Carton packing',
    },
    {
        value: 'Hessian wrapped',
        label: 'Hessian wrapped',
        reasonCode: 'ABT',
        reason: 'Hessian wrapped',
    },
    {
        value: 'Polyethylene wrap packing',
        label: 'Polyethylene wrap packing',
        reasonCode: 'ABU',
        reason: 'Polyethylene wrap packing',
    },
    {
        value: 'Miscellaneous treatment',
        label: 'Miscellaneous treatment',
        reasonCode: 'ACF',
        reason: 'Miscellaneous treatment',
    },
    {
        value: 'Enamelling treatment',
        label: 'Enamelling treatment',
        reasonCode: 'ACG',
        reason: 'Enamelling treatment',
    },
    {
        value: 'Heat treatment',
        label: 'Heat treatment',
        reasonCode: 'ACH',
        reason: 'Heat treatment',
    },
    {
        value: 'Plating treatment',
        label: 'Plating treatment',
        reasonCode: 'ACI',
        reason: 'Plating treatment',
    },    {
        value: 'Painting',
        label: 'Painting',
        reasonCode: 'ACJ',
        reason: 'Painting',
    },    {
        value: 'Polishing',
        label: ' Polishing',
        reasonCode: 'ACK',
        reason: 'Polishing',
    },    {
        value: 'Priming',
        label: 'Priming',
        reasonCode: 'ACL',
        reason: 'Priming',
    },     {
        value: 'Preservation treatment',
        label: 'Preservation treatment',
        reasonCode: 'ACM',
        reason: 'Preservation treatment',
    },    {
        value: 'Fitting',
        label: 'Fitting',
        reasonCode: 'ACS',
        reason: 'Fitting',
    },    {
        value: 'Consolidation',
        label: 'Consolidation',
        reasonCode: 'ADC',
        reason: 'Consolidation',
    },    {
        value: ' Bill of lading',
        label: ' Bill of lading',
        reasonCode: 'ADE',
        reason: ' Bill of lading',
    },    {
        value: 'Airbag',
        label: 'Airbag',
        reasonCode: 'ADJ',
        reason: 'Airbag',
    },    {
        value: 'Transfer',
        label: 'Transfer',
        reasonCode: 'ADK',
        reason: 'Transfer',
    },    {
        value: 'Slipsheet',
        label: 'Slipsheet',
        reasonCode: 'ADL',
        reason: 'Slipsheet',
    },    {
        value: 'Binding',
        label: 'Binding',
        reasonCode: 'ADM',
        reason: 'Binding',
    },    {
        value: ' Repair or replacement of broken returnable package',
        label: ' Repair or replacement of broken returnable package',
        reasonCode: 'ADN',
        reason: ' Repair or replacement of broken returnable package',
    },    {
        value: 'Efficient logistics',
        label: 'Efficient logistics',
        reasonCode: 'ADO',
        reason: 'Efficient logistics',
    },    {
        value: 'Merchandising',
        label: 'Merchandising',
        reasonCode: 'ADP',
        reason: 'Merchandising',
    },    {
        value: 'Product mix',
        label: 'Product mix',
        reasonCode: 'ADQ',
        reason: 'Product mix',
    },    {
        value: 'Other services',
        label: 'Other services',
        reasonCode: 'ADR',
        reason: 'Other services',
    },    {
        value: ' Pick-up',
        label: ' Pick-up',
        reasonCode: 'ADT',
        reason: ' Pick-up',
    },    {
        value: 'Chronic illness',
        label: 'Chronic illness',
        reasonCode: 'ADW',
        reason: 'Chronic illness',
    },    {
        value: 'New product introduction',
        label: 'New product introduction',
        reasonCode: 'ADY',
        reason: 'New product introduction',
    },    {
        value: 'Direct delivery',
        label: 'Direct delivery',
        reasonCode: 'ADZ',
        reason: 'Direct delivery',
    },    {
        value: 'Diversion',
        label: 'Diversion',
        reasonCode: 'AEA',
        reason: 'Diversion',
    },    {
        value: 'Disconnect',
        label: 'Disconnect',
        reasonCode: 'AEB',
        reason: 'Disconnect',
    },    {
        value: 'Distribution',
        label: 'Distribution',
        reasonCode: 'AEC',
        reason: 'Distribution',
    },    {
        value: 'Handling of hazardous cargo',
        label: 'Handling of hazardous cargo',
        reasonCode: 'AED',
        reason: 'Handling of hazardous cargo',
    },    {
        value: 'Rents and leases',
        label: 'Rents and leases',
        reasonCode: 'AEF',
        reason: 'Rents and leases',
    },    {
        value: 'Location differential',
        label: 'Location differential',
        reasonCode: 'AEH',
        reason: 'Location differential',
    },    {
        value: 'Aircraft refueling',
        label: 'Aircraft refueling',
        reasonCode: 'AEI',
        reason: 'Aircraft refueling',
    },    {
        value: 'Fuel shipped into storage',
        label: 'Fuel shipped into storage',
        reasonCode: 'AEJ',
        reason: 'Fuel shipped into storage',
    },    {
        value: 'Cash on delivery',
        label: 'Cash on delivery',
        reasonCode: 'AEK',
        reason: 'Cash on delivery',
    },    {
        value: 'Small order processing service',
        label: 'Small order processing service',
        reasonCode: 'AEL',
        reason: 'Small order processing service',
    },    {
        value: 'Clerical or administrative services',
        label: 'Clerical or administrative services',
        reasonCode: 'AEM',
        reason: 'Clerical or administrative services',
    },    {
        value: 'Guarantee',
        label: 'Guarantee',
        reasonCode: 'AEN',
        reason: 'Guarantee',
    },    {
        value: 'Collection and recycling',
        label: 'Collection and recycling',
        reasonCode: 'AEO',
        reason: 'Collection and recycling',
    },    {
        value: 'Copyright fee collection',
        label: 'Copyright fee collection',
        reasonCode: 'AEP',
        reason: 'Copyright fee collection',
    },    {
        value: 'Veterinary inspection service',
        label: 'Veterinary inspection service',
        reasonCode: 'AES',
        reason: 'Veterinary inspection service',
    },    {
        value: 'Pensioner service',
        label: 'Pensioner service',
        reasonCode: 'AET',
        reason: 'Pensioner service',
    },    {
        value: 'Medicine free pass holder',
        label: 'Medicine free pass holder',
        reasonCode: 'AEU',
        reason: 'Medicine free pass holder',
    },    {
        value: 'Environmental protection service',
        label: 'Environmental protection service',
        reasonCode: 'AEV',
        reason: 'Environmental protection service',
    },    {
        value: 'Environmental clean-up service',
        label: 'Environmental clean-up service',
        reasonCode: 'AEW',
        reason: 'Environmental clean-up service',
    },    {
        value: 'National cheque processing service outside account area',
        label: 'National cheque processing service outside account area',
        reasonCode: 'AEX',
        reason: 'National cheque processing service outside account area',
    },    {
        value: 'National payment service outside account area',
        label: 'National payment service outside account area',
        reasonCode: 'AEY',
        reason: 'National payment service outside account area',
    },    {
        value: 'National payment service within account area',
        label: 'National payment service within account area',
        reasonCode: 'AEZ',
        reason: 'National payment service within account area',
    },    {
        value: 'Adjustments',
        label: 'Adjustments',
        reasonCode: 'AJ',
        reason: 'Adjustments',
    },    {
        value: 'Authentication',
        label: 'Authentication',
        reasonCode: 'AU',
        reason: 'Authentication',
    },    {
        value: 'Cataloguing',
        label: 'Cataloguing',
        reasonCode: 'CA',
        reason: 'Cataloguing',
    },    {
        value: 'Cartage',
        label: 'Cartage',
        reasonCode: 'CAB',
        reason: 'Cartage',
    },    {
        value: 'Certification',
        label: 'Certification',
        reasonCode: 'CAD',
        reason: 'Certification',
    },    {
        value: 'Certificate of conformance',
        label: 'Certificate of conformance',
        reasonCode: 'CAE',
        reason: 'Certificate of conformance',
    },    {
        value: 'Certificate of origin',
        label: 'Certificate of origin',
        reasonCode: 'CAF',
        reason: 'Certificate of origin',
    },    {
        value: 'Cutting',
        label: 'Cutting',
        reasonCode: 'CAI',
        reason: 'Cutting',
    },    {
        value: 'Consular service',
        label: 'Consular service',
        reasonCode: 'CAJ',
        reason: 'Consular service',
    },    {
        value: 'Customer collection',
        label: 'Customer collection',
        reasonCode: 'CAK',
        reason: 'Customer collection',
    },    {
        value: 'Payroll payment service',
        label: 'Payroll payment service',
        reasonCode: 'CAL',
        reason: 'Payroll payment service',
    },    {
        value: 'Cash transportation',
        label: 'Cash transportation',
        reasonCode: 'CAM',
        reason: 'Cash transportation',
    },    {
        value: 'Home banking service',
        label: 'Home banking service',
        reasonCode: 'CAN',
        reason: 'Home banking service',
    },    {
        value: 'Bilateral agreement service',
        label: 'Bilateral agreement service',
        reasonCode: 'CAO',
        reason: 'Bilateral agreement service',
    },    {
        value: 'Insurance brokerage service',
        label: 'Insurance brokerage service',
        reasonCode: 'CAP',
        reason: 'Insurance brokerage service',
    },    {
        value: 'Cheque generation',
        label: 'Cheque generation',
        reasonCode: 'CAQ',
        reason: 'Cheque generation',
    },    {
        value: 'Preferential merchandising location',
        label: 'Preferential merchandising location',
        reasonCode: 'CAR',
        reason: 'Preferential merchandising location',
    },    {
        value: 'Crane',
        label: 'Crane',
        reasonCode: 'CAS',
        reason: 'Crane',
    },    {
        value: 'Special colour service',
        label: 'Special colour service',
        reasonCode: 'CAT',
        reason: 'Special colour service',
    },    {
        value: 'Sorting',
        label: 'Sorting',
        reasonCode: 'CAU',
        reason: 'Sorting',
    },    {
        value: 'Battery collection and recycling',
        label: 'Battery collection and recycling',
        reasonCode: 'CAV',
        reason: 'Battery collection and recycling',
    },    {
        value: 'Product take back fee',
        label: 'Product take back fee',
        reasonCode: 'CAW',
        reason: 'Product take back fee',
    },    {
        value: 'Car loading',
        label: 'Car loading',
        reasonCode: 'CD',
        reason: 'Car loading',
    },    {
        value: 'Cleaning',
        label: 'Cleaning',
        reasonCode: 'CG',
        reason: 'Cleaning',
    },    {
        value: 'Cigarette stamping',
        label: 'Cigarette stamping',
        reasonCode: 'CS',
        reason: 'Cigarette stamping',
    },    {
        value: 'Count and recount',
        label: 'Count and recount',
        reasonCode: 'CT',
        reason: 'Count and recount',
    },    {
        value: 'Layout/design',
        label: 'Layout/design',
        reasonCode: 'DAB',
        reason: 'Layout/design',
    },    {
        value: 'Driver assigned unloading',
        label: 'Driver assigned unloading',
        reasonCode: 'DAD',
        reason: 'Driver assigned unloading',
    },    {
        value: 'Delivery',
        label: 'Delivery',
        reasonCode: 'DL',
        reason: 'Delivery',
    },    {
        value: 'Engraving',
        label: 'Engraving',
        reasonCode: 'EG',
        reason: 'Engraving',
    },    {
        value: 'Expediting',
        label: 'Expediting',
        reasonCode: 'EP',
        reason: 'Expediting',
    },    {
        value: 'Exchange rate guarantee',
        label: 'Exchange rate guarantee',
        reasonCode: 'ER',
        reason: 'Exchange rate guarantee',
    },    {
        value: 'Fabrication',
        label: 'Fabrication',
        reasonCode: 'FAA',
        reason: 'Fabrication',
    },    {
        value: 'Freight equalization',
        label: 'Freight equalization',
        reasonCode: 'FAB',
        reason: 'Freight equalization',
    },    {
        value: 'Freight extraordinary handling',
        label: 'Freight extraordinary handling',
        reasonCode: 'FAC',
        reason: 'Freight extraordinary handling',
    },    {
        value: 'Freight service',
        label: 'Freight service',
        reasonCode: 'FC',
        reason: 'Freight service',
    },    {
        value: 'Filling/handling',
        label: 'Filling/handling',
        reasonCode: 'FH',
        reason: 'Filling/handling',
    },    {
        value: 'Financing',
        label: 'Financing',
        reasonCode: 'FI',
        reason: 'Financing',
    },    {
        value: 'Grinding',
        label: 'Grinding',
        reasonCode: 'GAA',
        reason: 'Grinding',
    },    {
        value: 'Hose',
        label: 'Hose',
        reasonCode: 'HAA',
        reason: 'Hose',
    },    {
        value: 'Handling',
        label: 'Handling',
        reasonCode: 'HD',
        reason: 'Handling',
    },    {
        value: 'Hoisting and hauling',
        label: 'Hoisting and hauling',
        reasonCode: 'HH',
        reason: 'Hoisting and hauling',
    },    {
        value: 'Installation',
        label: 'Installation',
        reasonCode: 'IAA',
        reason: 'Installation',
    },    {
        value: 'Installation and warranty',
        label: 'Installation and warranty',
        reasonCode: 'IAB',
        reason: 'Installation and warranty',
    },    {
        value: 'Inside delivery',
        label: 'Inside delivery',
        reasonCode: 'ID',
        reason: 'Inside delivery',
    },    {
        value: 'Inspection',
        label: 'Inspection',
        reasonCode: 'IF',
        reason: 'Inspection',
    },    {
        value: 'Installation and training',
        label: 'Installation and training',
        reasonCode: 'IR',
        reason: 'Installation and training',
    },    {
        value: 'Invoicing',
        label: 'Invoicing',
        reasonCode: 'IS',
        reason: 'Invoicing',
    },    {
        value: 'Koshering',
        label: 'Koshering',
        reasonCode: 'KO',
        reason: 'Koshering',
    },    {
        value: 'Carrier count',
        label: 'Carrier count',
        reasonCode: 'L1',
        reason: 'Carrier count',
    },    {
        value: 'Labelling',
        label: 'Labelling',
        reasonCode: 'LA',
        reason: 'Labelling',
    },    {
        value: 'Labour',
        label: 'Labour',
        reasonCode: 'LAA',
        reason: 'Labour',
    },    {
        value: 'Repair and return',
        label: 'Repair and return',
        reasonCode: 'LAB',
        reason: 'Repair and return',
    },    {
        value: 'Legalisation',
        label: 'Legalisation',
        reasonCode: 'LF',
        reason: 'Legalisation',
    },    {
        value: 'Mounting',
        label: 'Mounting',
        reasonCode: 'MAE',
        reason: 'Mounting',
    },    {
        value: ' Mail invoice',
        label: ' Mail invoice',
        reasonCode: 'MI',
        reason: ' Mail invoice',
    },    {
        value: 'Mail invoice to each location',
        label: 'Mail invoice to each location',
        reasonCode: 'ML',
        reason: 'Mail invoice to each location',
    },    {
        value: 'Non-returnable containers',
        label: 'Non-returnable containers',
        reasonCode: 'NAA',
        reason: 'Non-returnable containers',
    },    {
        value: 'Outside cable connectors',
        label: 'Outside cable connectors',
        reasonCode: 'OA',
        reason: 'Outside cable connectors',
    },    {
        value: 'Invoice with shipment',
        label: 'Invoice with shipment',
        reasonCode: 'PA',
        reason: 'Invoice with shipment',
    },    {
        value: 'Phosphatizing (steel treatment)',
        label: 'PAA',
        reasonCode: 'Phosphatizing (steel treatment)',
        reason: 'Phosphatizing (steel treatment)',
    },    {
        value: 'Packing',
        label: 'Packing',
        reasonCode: 'PC',
        reason: 'Packing',
    },    {
        value: 'Palletizing',
        label: 'Palletizing',
        reasonCode: 'PL',
        reason: 'Palletizing',
    },    {
        value: 'Repacking',
        label: 'Repacking',
        reasonCode: 'RAB',
        reason: 'Repacking',
    },    {
        value: 'Repair',
        label: 'Repair',
        reasonCode: 'RAC',
        reason: 'Repair',
    },    {
        value: 'Returnable container',
        label: 'Returnable container',
        reasonCode: 'RAD',
        reason: 'Returnable container',
    },    {
        value: 'Restocking',
        label: 'Restocking',
        reasonCode: 'RAF',
        reason: 'Restocking',
    },    {
        value: 'Re-delivery',
        label: 'Re-delivery',
        reasonCode: 'RE',
        reason: 'Re-delivery',
    },    {
        value: 'Refurbishing',
        label: 'Refurbishing',
        reasonCode: 'RF',
        reason: 'Refurbishing',
    },    {
        value: 'Rail wagon hire',
        label: 'Rail wagon hire',
        reasonCode: 'RH',
        reason: 'Rail wagon hire',
    },    {
        value: 'Loading',
        label: 'Loading',
        reasonCode: 'RV',
        reason: 'Loading',
    },    {
        value: 'Salvaging',
        label: 'Salvaging',
        reasonCode: 'SA',
        reason: 'Salvaging',
    },    {
        value: 'Shipping and handling',
        label: 'Shipping and handling',
        reasonCode: 'SAA',
        reason: 'Shipping and handling',
    },    {
        value: 'Special packaging',
        label: 'Special packaging',
        reasonCode: 'SAD',
        reason: 'Special packaging',
    },    {
        value: 'Stamping',
        label: 'Stamping',
        reasonCode: 'SAE',
        reason: 'Stamping',
    },    {
        value: 'Consignee unload',
        label: 'Consignee unload',
        reasonCode: 'SAI',
        reason: 'Consignee unload',
    },    {
        value: 'Shrink-wrap',
        label: 'Shrink-wrap',
        reasonCode: 'SG',
        reason: 'Shrink-wrap',
    },    {
        value: 'Special handling',
        label: 'Special handling',
        reasonCode: 'SH',
        reason: 'Special handling',
    },    {
        value: 'Special finish',
        label: 'Special finish',
        reasonCode: 'SM',
        reason: 'Special finish',
    },    {
        value: 'Set-up',
        label: 'Set-up',
        reasonCode: 'SU',
        reason: 'Set-up',
    },    {
        value: 'Tank renting',
        label: 'Tank renting',
        reasonCode: 'TAB',
        reason: 'Tank renting',
    },    {
        value: 'Testing',
        label: 'Testing',
        reasonCode: 'TAC',
        reason: 'Testing',
    },    {
        value: 'Transportation - third party billing',
        label: 'Transportation - third party billing',
        reasonCode: 'TT',
        reason: 'Transportation - third party billing',
    },    {
        value: 'Transportation by vendor',
        label: 'Transportation by vendor',
        reasonCode: 'TV',
        reason: 'Transportation by vendor',
    },    {
        value: 'Drop yard',
        label: 'Drop yard',
        reasonCode: 'V1',
        reason: 'Drop yard',
    },    {
        value: 'Drop dock',
        label: 'Drop dock',
        reasonCode: 'V2',
        reason: 'Drop dock',
    },    {
        value: 'Warehousing',
        label: 'Warehousing',
        reasonCode: 'WH',
        reason: 'Warehousing',
    },    {
        value: 'Combine all same day shipment',
        label: 'Combine all same day shipment',
        reasonCode: 'XAA',
        reason: 'Combine all same day shipment',
    },    {
        value: 'Split pick-up',
        label: 'Split pick-up',
        reasonCode: 'YY',
        reason: 'Split pick-up',
    },    {
        value: 'Mutually defined',
        label: 'Mutually defined',
        reasonCode: 'ZZZ',
        reason: 'Mutually defined',
    },
];
