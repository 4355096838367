import './TaxCodeOverview.scss';
import { CoreTitle } from '@packages';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { FC } from 'react';
import { ITotalTaxCodeOverview } from '@models/dashboard.model';
import { NoDataPieChart } from '@components';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

const COLORS = ['#183190', '#7CD4FD', '#FEC84B', '#C5B5FD'];

interface TaxCodeOverviewProps {
    TaxCodeOverviewData: ITotalTaxCodeOverview;
    taxCodeOverviewLoading: boolean;
}

interface LegendProps {
    payload?: any;
    t: (key: string) => string;
}

const renderLegend = (props: LegendProps) => {
    const { payload, t } = props;

    return (
        <div className='tax-code-legend-wrapper'>
            <ul className='tax-code-legend-column'>
                {payload && payload.map((entry: any, index: any) => (
                    <li key={`item-${index}`} className=''>
                        <div className='column-inner-div'>
                            <span className='square' style={{ backgroundColor: entry.color }} />
                            {entry.payload.name === "S" && <span className='legend-paragraph ml-1'> {t("STANDARD_TAX_RATE")}</span>}
                            {entry.payload.name === "E" && <span className='legend-paragraph ml-1'> {t("EXEMPTED_TAX_RATE")}</span>}
                            {entry.payload.name === "Z" && <span className='legend-paragraph ml-1'> {t("ZERO_RATED_TEXT")}</span>}
                            {entry.payload.name === "O" && <span className='legend-paragraph ml-1'> {t("OUT_OF_SCOPE")}</span>}
                        </div>
                        <div className='column-inner-div'>
                            <span className='legend-paragraph'>{entry.payload.value}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const TaxCodeOverviewTooltip = (props: any) => {
    const { t } = useTranslation();
    const { active, payload } = props;

    if (active && payload && payload.length) {
        const singleData = payload[0].payload;

        const tooltipLabel: { [key: string]: string } = {
            S: t('STANDARD_TAX_RATE'),
            E: t('EXEMPTED_TAX_RATE'),
            Z: t('ZERO_RATED_TAX'),
            O: t('OUT_OF_SCOPE'),
        };
        const customLabel = tooltipLabel[singleData.name] || singleData.Name;

        return (
            <div className='tax-code-tooltip-wrapper'>
                <p>
                    {customLabel}: {payload[0].value}
                </p>
            </div>
        );
    }

    return null;
};

const TaxCodeOverview: FC<TaxCodeOverviewProps> = ({
    TaxCodeOverviewData,
    taxCodeOverviewLoading,
}) => {
    const { t } = useTranslation();

    return (
        <div className='tax-code-overview-wrapper'>
            <CoreTitle tag='h6' text={t('TAX_CODE_OVERVIEW')} />
            <ResponsiveContainer width={'100%'} height={295}>
                {taxCodeOverviewLoading ? (
                    <div className='loading-spinner'>
                        <Spin size='large' />
                    </div>
                ) : TaxCodeOverviewData.taxCodeOverview.length === 0 ? (
                    <NoDataPieChart />
                ) : (
                    <PieChart>
                        <Pie
                            widths={200}
                            cy='50%'
                            data={TaxCodeOverviewData?.taxCodeOverview}
                            innerRadius={80}
                            outerRadius={120}
                            fill='red'
                            paddingAngle={1}
                            nameKey='name'
                            dataKey='count'
                            className='pie'
                        >
                            {TaxCodeOverviewData?.taxCodeOverview.map(
                                (entry: any, index: number) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ),
                            )}
                        </Pie>
                        <Tooltip content={<TaxCodeOverviewTooltip />} />
                        <Legend
                            align='right'
                            verticalAlign='top'
                            layout='vertical'
                            content={(props) => renderLegend({ ...props, t })}
                        />
                    </PieChart>
                )}
            </ResponsiveContainer>
        </div>
    );
};

export default TaxCodeOverview;
