import { Col, Input, Row, Button } from 'antd';
import { FC, useEffect } from 'react';
import { Control, Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { CustomRules } from '@models/rules.model';

interface IInputGenericBaseParams {
    control: Control<any, any>;
    errors: FieldErrors<any>;
    level: string;
    dataKey: string;
    disabled?: boolean;
    rules?: CustomRules;
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    marginBottom?: number;
    placeHolder?: string;
    hideError?: boolean;
    children?: React.ReactNode;
    gutter?: number;
    tooltip?: string;
    errorMessageSize?: ErrorMessageSize;
    defaultValue?: string[];
}

const InlineGenericMultiInput: FC<IInputGenericBaseParams> = ({
    control,
    errors,
    level,
    dataKey,
    rules,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    marginBottom = 30,
    hideError = false,
    children,
    gutter = 130,
    tooltip,
    errorMessageSize,
}) => {
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({
        control,
        name: dataKey, // expects an array in the form data
    });

    // Ensure at least one input field is displayed
    useEffect(() => {
        if (fields.length === 0) {
            append(''); // append an empty string to ensure one input field
        }
    }, [fields, append]);

    return (
        <Row gutter={gutter} align='middle' style={{ marginBottom: marginBottom }}>
            {showLevel && (
                <Col span={levelCol}>
                    <label className='general-label'>
                        {t(level)}
                        {level && rules?.required && <span className='required-field'>*</span>}
                        {tooltip && <GenericTooltip text={tooltip} />}
                    </label>
                </Col>
            )}
            <Col span={inputCol}>
                {fields.map((field, index) => (
                    <>
                        <Row
                            key={field.id}
                            gutter={8}
                            align='middle'
                            style={{ marginBottom: 20, position: 'relative' }}
                        >
                            <Col flex='auto' span={20}>
                                <Controller
                                    name={`${dataKey}.${index}`} // dynamic index
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            className='general-input'
                                            placeholder={t(level)}
                                        />
                                    )}
                                    rules={rules}
                                />
                            </Col>
                            <Col span={4}>
                                <Button type='dashed' onClick={() => remove(index)}>
                                    -
                                </Button>
                            </Col>
                        </Row>
                        {!hideError && (errors[dataKey] as any)?.[index] && (
                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: -17,
                                    marginBottom: 20,
                                    fontSize: 12,
                                }}
                            >
                                <span className='error-message'>
                                    {(errors[dataKey] as any)?.[index]?.message}
                                </span>
                            </div>
                        )}
                    </>
                ))}
                <Button disabled={!!errors[dataKey]} type='dashed' onClick={() => append('')}>
                    + Add More
                </Button>

                {children}
            </Col>
        </Row>
    );
};

export default InlineGenericMultiInput;
