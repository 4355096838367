import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    IInvoiceTypeCount,
    IMonthlyStats,
    ITopCustomer,
    ITotalCount,
} from '@models/dashboard.model';
import { processQuery } from '@utils/processQuery';

export const DashboardAPI = {
    getInvoiceTypeCounts: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardQuery/InvoiceTypeCounts`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result as IInvoiceTypeCount[];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getTotalCounts: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardQuery/TotalCounts`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result as ITotalCount;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getTopCustomers: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardQuery/TopCustomers`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result as ITopCustomer;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getMonthlyStats: async (year: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardQuery/MonthlyStats${processQuery({ year })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result as IMonthlyStats;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    // For Dashboard 2

    getTaxCodeOverview: async (payload: object): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardCommand/TaxCodeOverview`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getDataQualitySummary: async (payload: object): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardCommand/DataQualitySummary`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getCustomerSummary: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardQuery/CustomerSummary`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getTotalInvoicesInterfaced: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardQuery/TotalInvoicesInterfaced`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getTotalTaxAmount: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardQuery/TotalTaxAmount`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getTypesOfGeneratedInvoices: async (payload: object): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardCommand/TypesOfGeneratedInvoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getTotalInvoicesYetToBeSubmitted: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardQuery/TotalInvoicesYetToBeSubmitted`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getDashboardData: async (payload: object): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardCommand/DashboardData`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getDashboardCharts: async (payload: object): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardCommand/DashboardCharts`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getDashboardChips: async (payload: object): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardCommand/DashboardChips`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getProcessingSummary: async (payload: object): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardCommand/InvoiceProcessingSummary`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getRejectionSummary: async (payload: object): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `DashboardCommand/RejectionSummary`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
