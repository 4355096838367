import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Input, message } from "antd";
import { CoreButton, CoreTitle, CoreLoginWrapper, CoreAuthConfirmation } from "@packages";
import { RegexValidators } from "@config/regex-validator-constant";
import './CoreRecoverAccountPage.scss'
import { NavLink, useNavigate } from "react-router-dom";
import { authAPI } from "@service/auth.service";
import { IRecoverAccount } from "@models/auth.model";
import { useTranslation } from "react-i18next";


const CoreRecoverAccountPage = () => {
    const { t } = useTranslation();

    const [messageApi, contextHolder] = message.useMessage();
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const { control, handleSubmit, formState: { errors, isValid } } = useForm<IRecoverAccount>({ mode: "onTouched" });

    const onSubmit = async (data: IRecoverAccount) => {
        if (isValid) {
            setLoading(true);
            const response: any = await authAPI.recoverAccount(data);
            setLoading(false);
            if (response.Success) {
                setEmailSent(true);

            } else {
                messageApi.open({
                    type: 'error',
                    content: "Error",
                });
            }

        }
    };

    return (
        <>
            {!emailSent ? (
                <CoreLoginWrapper>
                    <div className='core-recover-account-wrapper'>
                        {contextHolder}
                        <div className="core-recover-account-wrapper-content">
                            <div>
                                <CoreTitle tag="h1" text={t('RECOVER_ACCOUNT')}/>
                                <p className='sub-title'>{t('PLEASE_ENTER_YOUR_EMAIL_ADDRESS')}</p>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='general-input-wrapper'>
                                    <label className='general-label'>{t('EMAIL_ADDRESS')}</label>
                                    <Controller
                                        name="email"
                                        render={({ field }) => <Input {...field} placeholder={t('EMAIL_ADDRESS')}
                                                                      className="general-input" />}
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: RegexValidators.EMAIL
                                        }}
                                    />
                                    {errors?.email?.type === "required" && (
                                        <span className='error-message'>{t('EMAIL_FIELD_IS_REQUIRED')}</span>)}
                                    {errors?.email?.type === "pattern" && (
                                        <span className='error-message'>{t('INVALID_EMAIL_ADDRESS')}</span>)}
                                </div>

                                <CoreButton
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!isValid}
                                    isFullWidth={true}
                                    text={t('RECOVER_aCCOUNT')}
                                    loading={isLoading} />

                            </form>

                            <div className='core-recover-account-buttons'>
                                <p>{t('ALREADY_HAVE_AN_ACCOUNT')}</p>
                                <NavLink to={'/login'} className='login-here'>{t('LOGIN_HERE')}</NavLink>
                            </div>
                        </div>
                    </div>
                </CoreLoginWrapper>
            ) : (
                <CoreAuthConfirmation
                    title={t('PASSWORD_RECOVERY_EMAIL_SENT')}
                    description={t('PLEASE_CHECK_YOUR_INBOX_FOR_TRANSLATIONS_ON_HOW_TO_RESET_YOUR_PASSWORD')}
                />
            )}
        </>

    );
};

export default CoreRecoverAccountPage;