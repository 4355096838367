import './Home.scss';
import Navbar from './components/navbar/Navbar';
import HeroSection from './components/hero-section/HeroSection';
import ClientSection from './components/clients-section/ClientSection';
import AboutUs from './components/about-us/AboutUs';
import WhyInvoicing from './components/why-invoicing/WhyInvoicing';
import FlexibilityBusiness from './components/flexibility-business/FlexibilityBusiness';
import InvoicePlatform from './components/invoice-platform/InvoicePlatform';
import BusinessModel from './components/business-model/BusinessModel';
import Faq from './components/faq-section/Faq';
import AdvanceInvoicing from './components/advance-invoicing/AdvanceInvoicing';
import SolutionSupport from './components/solution-support/SolutionSupport';
import Footer from './components/footer/Footer';
function Home() {
    return (
        <div className='home-wrapper'>
            <div className='background-wrapper'>
                <Navbar />
                <HeroSection />
            </div>
            <ClientSection />
            <AboutUs />
            <WhyInvoicing />
            <AdvanceInvoicing />
            <SolutionSupport />
            <FlexibilityBusiness />
            <InvoicePlatform />
            <BusinessModel />
            <Faq />
            <Footer />
        </div>
    );
}

export default Home;
