import React from 'react';
import { Radio } from 'antd';
import './BillingCardView.scss';

interface IBillingCardViewProps {
    selectedCardId?: string;
    addressName: string;
    addressDetails: string;
    addressId: string;
}

const BillingCardView = ({
    selectedCardId,
    addressName,
    addressDetails,
    addressId,
}: IBillingCardViewProps) => {
    return (
        <div
            className='billing-card-view-wrapper round-box-shadow'
            style={
                (selectedCardId &&
                    selectedCardId === addressId && { border: '2px solid #285FF6' }) ||
                {}
            }
        >
            <Radio value={addressId}>
                <div className='billing-card-info'>
                    <p className='address-name'>{addressName}</p>
                    <p className='address-details'>{addressDetails}</p>
                </div>
            </Radio>
        </div>
    );
};

export default BillingCardView;
