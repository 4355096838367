import { CoreList, CoreTitle } from '@packages';
import { getAddressInformation, getEntityInformation } from '@utils/helper';
import  { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SupplyInformation.scss';
import { IEntity } from '@models/organization.model';

type CoreList = { key: string; value: string }[];

type SupplyInformationProps = {
    entity: IEntity | null;
};

function SupplyInformation({ entity }: SupplyInformationProps) {
    const { t } = useTranslation();
    const [entityInfo, setEntityInfo] = useState<CoreList>([]);
    const [entityAddress, setEntityAddress] = useState<CoreList>([]);


    useEffect(() => {
        if (entity) {
            const entityInfo = getEntityInformation(entity, t);
            const entityAddress = getAddressInformation(entity?.Address, t);
            setEntityInfo(entityInfo);
            setEntityAddress(entityAddress);
        }
    }, [entity, t]);
    return (
        <div className='invoice-supply-information'>
            <CoreList listData={entityInfo} />
            <CoreTitle tag='h5' text={t('ADDRESS_INFORMATION')} />
            <CoreList listData={entityAddress} />
        </div>
    );
}

export default SupplyInformation;
