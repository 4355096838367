import React from 'react';
import { Link } from 'react-router-dom';
import './TemplateCreate.scss';
import { PlusOutlined } from '@ant-design/icons';

const TemplateCreate = () => {
    return (
        <Link to='/template-properties'>
            <div className='template-create-wrapper'>
                <PlusOutlined className='plus-icon' />
            </div>
        </Link>
    );
};
export default TemplateCreate;
