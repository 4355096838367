import { IAllowanceCharge, IInvoiceCreation } from '@models/invoice.model';
import { FC, useState } from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import './AllowanceCharges.scss';
import { CoreButton } from '@packages';
import CreateAllowanceCharges, { DiscountType } from './CreateAllowanceCharges';
import { DEFAULT_TABLE_SIZE } from '@config/constants';
import Table, { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { deleteAllowanceCharge, selectAllowanceCharges } from '@store/slices/einvoiceSlice';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface IAllowanceChargesProps {
    control: Control<IInvoiceCreation, any>;
    errors: FieldErrors<IInvoiceCreation>;
    setFormValue: UseFormSetValue<IInvoiceCreation>;
    watch: UseFormWatch<IInvoiceCreation>;
}

const columns = (t: TFunction<'translation', undefined>): ColumnsType<IAllowanceCharge> => [
    {
        title: t('AMOUNT'),
        dataIndex: 'Amount',
    },
    {
        title: t('TYPE'),
        render: (text, data) => <span>{data.ChargeIndicator ? 'Charge' : 'Discount'}</span>,
    },

    {
        title: t('REASON'),
        dataIndex: 'Reason',
        render: (text, data) => <span>{data.Reason}</span>,
    },
    {
        title: t('REASON_CODE'),
        dataIndex: 'ReasonCode',
    },
];
const AllowanceCharges: FC<IAllowanceChargesProps> = () => {
    const { t } = useTranslation();
    const [isShowModal, setShowModal] = useState<boolean>(false);
    const [disCountType, setDisCountType] = useState<DiscountType>('Charge');
    const allowanceCharges = useAppSelector(selectAllowanceCharges);
    const dispatch = useAppDispatch();

    const allowanceChargesColumns = [
        ...columns(t),
        {
            title: '',
            dataIndex: 'Id',
            render: (key: string) => (
                <div className='action-buttons'>
                    <div
                        className='edit-table-icon'
                        onClick={(e) => dispatch(deleteAllowanceCharge(key))}
                        aria-hidden='true'
                    >
                        <DeleteOutlined />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <CoreButton
                className='add-Payments-button'
                text={t('ADD_CHARGE')}
                onClick={() => {
                    setDisCountType('Charge');
                    setShowModal(true);
                }}
            />

            <CoreButton
                className='add-Payments-button margin-20'
                text={t('ADD_DISCOUNT')}
                onClick={() => {
                    setDisCountType('Discount');
                    setShowModal(true);
                }}
            />

            <Table
                columns={allowanceChargesColumns}
                dataSource={allowanceCharges}
                rowClassName='cursor-pointer'
                rowKey={'Id'}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: allowanceCharges?.length ?? 0,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
            />
            <CreateAllowanceCharges
                isShowModal={isShowModal}
                setShowModal={setShowModal}
                discountType={disCountType}
            />
        </>
    );
};
export default AllowanceCharges;
