import { CoreButton, CoreTitle } from '@packages';
import './disable2FA.scss';
import { useState } from 'react';
import { SETUP_2FA } from '@service/setup-2FA.service';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { onChange2FAActivation } from '@store/slices/userSlice';

const Disable2FAView = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onDisable2FA = async () => {
        try {
            setLoading(true);
            const response = await SETUP_2FA.disable2FA();
            setLoading(false);
            dispatch(onChange2FAActivation(false));
            message.success('Successfully disabled 2FA authentication');
        } catch {
            setLoading(false);
            message.error('2FA authentication disabled failed');
        }
    };
    return (
        <>
            <div className='disable-view-inner'>
                <CoreTitle tag='h4' text='Two-factor authentication' />
                <CoreButton
                    loading={loading}
                    text='Disable'
                    antType='dashed'
                    type='danger'
                    size='middle'
                    onClick={onDisable2FA}
                />
            </div>
        </>
    );
};
export default Disable2FAView;
