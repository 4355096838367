import { useState } from 'react';
import { Col, RadioChangeEvent, Row } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import { useForm } from 'react-hook-form';
import './InviteClient.scss';
import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericRadio from '@components/common/InlineGenericRadio';
import { useTranslation } from 'react-i18next';

interface IInviteClient {
    handleOk: () => void;
    handleCancel: () => void;
}

export type UserRole = 'OrgAdmin' | 'EntityAdmin' | 'User' | 'Auditor';

interface IInviteClientForm {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Email: string;
    Organization: string;
    Entity: string;
    UserRole: UserRole;
}

const InviteClient = (props: IInviteClient) => {
    const { t } = useTranslation();
    const { handleOk, handleCancel } = props;

    const {
        control,
        handleSubmit,
        clearErrors,
        setValue: setFormValue,
        formState: { errors, isValid },
    } = useForm<IInviteClientForm>({ mode: 'onBlur' });

    const handleSubmitButton = () => {
        if (isValid) {
            handleOk();
        }
    };

    const onSubmit = (data: IInviteClientForm) => {
        console.log(data, 'invite client data');
    };

    return (
        <div className='invite-wrapper'>
            <div className='margin-top-20 margin-bottom-20'>
                <CoreTitle tag='h5' text={t('INVITE_USER')} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='invite-form'>
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='FirstName'
                        level={t('FIRST_NAME')}
                        marginBottom={20}
                        rules={{
                            required: false,
                        }}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='MiddleName'
                        level={t('MIDDLE_NAME')}
                        marginBottom={20}
                        rules={{
                            required: false,
                        }}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='LastName'
                        level={t('LAST_NAME')}
                        marginBottom={20}
                        rules={{
                            required: false,
                        }}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Email'
                        level={t('EMAIL_ADDRESS')}
                        marginBottom={20}
                        rules={{
                            required: false,
                        }}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Organization'
                        level={t('ORGANIZATION')}
                        marginBottom={20}
                        rules={{
                            required: false,
                        }}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Entity'
                        level={t('ENTITY')}
                        marginBottom={20}
                        rules={{
                            required: false,
                        }}
                    />

                    <InlineGenericRadio
                        levelCol={24}
                        inputCol={24}
                        setFormValue={setFormValue}
                        control={control}
                        errors={errors}
                        clearErrors={clearErrors}
                        options={[
                            { label: t('ORG_ADMIN'), value: 'OrgAdmin' },
                            { label: t('ENTITY_ADMIN'), value: 'EntityAdmin' },
                            { label: t('USER'), value: 'User' },
                            { label: t('AUDITOR'), value: 'Auditor' },
                        ]}
                        dataKey='UserRole'
                        level={t('USER_ROLE')}
                        rules={{
                            required: false,
                        }}
                    />
                </div>

                <Row gutter={16} className='invite-actions'>
                    <Col span={12}>
                        <CoreButton
                            type='basic'
                            isFullWidth={true}
                            onClick={handleCancel}
                            text={t('CANCEL')}
                        />
                    </Col>
                    <Col span={12}>
                        <CoreButton
                            type='primary'
                            htmlType='submit'
                            isFullWidth={true}
                            onClick={handleSubmitButton}
                            text={t('INVITE')}
                        />
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default InviteClient;
