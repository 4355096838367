import { FC } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './SimpleBarChart.scss';
import { IMonthlyStat } from '@models/dashboard.model';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({ active, payload, label }: any) => {
    const { t } = useTranslation();
    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip'>
                <p className='bold'>{label}</p>
                <p>
                    {t('TOTAL_SALES')} : SAR <span className='bold'>{payload[0].value}</span>
                </p>
                <p>
                    {t('TOTAL_TAXABLE_AMOUNT')} : SAR{' '}
                    <span className='bold'>{payload[1].value}</span>
                </p>
            </div>
        );
    }

    return null;
};

interface BarChartProps {
    data: IMonthlyStat[];
}

const formatYAxisLabel = (value: number): string => {
    const abbreviations = [
        { value: 1e12, symbol: 'T' },
        { value: 1e9, symbol: 'B' },
        { value: 1e6, symbol: 'M' },
        { value: 1e3, symbol: 'K' },
    ];

    const abbreviation = abbreviations.find((abbr) => Math.abs(value) >= abbr.value);

    if (abbreviation) {
        const formattedNumber = (value / abbreviation.value).toFixed(2);
        return `${formattedNumber}${abbreviation.symbol}`;
    }

    return value.toString();
};

const SimpleBarChart: FC<BarChartProps> = ({ data }) => {
    return (
        <ResponsiveContainer width='100%' height={300}>
            <BarChart
                width={500}
                height={280}
                data={data}
                barSize={15}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 0,
                }}
            >
                {/*<CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />*/}
                <XAxis dataKey='MonthName' />
                <YAxis
                    // Customize Y-axis properties here
                    scale='auto'
                    // domain={['auto', 'auto']}
                    label={{ value: '', angle: -90, position: 'insideLeft' }}
                    tickFormatter={formatYAxisLabel}
                />
                {/*<Tooltip />*/}
                {/*<Legend />*/}
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey='TotalSales' fill='#02B59F' />
                <Bar dataKey='TotalTaxableAmount' fill='#FEC84B' />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default SimpleBarChart;
