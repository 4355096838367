import { CoreButton, CoreTitle } from '@packages';
import React from 'react';
import './ConfirmUploadInvoice.scss';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';

const ConfirmUploadInvoice = () => {
    const navigate = useNavigate();

    return (
        <div className='confirm-invoice-upload-wrapper'>
            <CheckCircleOutlined className='checked-icon' />
            <CoreTitle tag='h2' text='Thanks for uploading invoice' />

            <div className='confirm-invoice-upload-actions'>
                <CoreButton
                    text='Go To Invocie List'
                    type='primary'
                    size='middle'
                    onClick={() => navigate('/e-invoices')}
                />
                <CoreButton
                    text='Go To Upload Invocie'
                    type='primary'
                    size='middle'
                    onClick={() => navigate('/upload-invoices')}
                />
            </div>
        </div>
    );
};

export default ConfirmUploadInvoice;
