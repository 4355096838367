import { useEffect, useState } from 'react';
import { Input, Row, Col, Select, Checkbox, message, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import './InventoryCreate.scss';
import { CoreButton } from '@packages';
import TextArea from 'antd/es/input/TextArea';
import { ICreateInventory } from '@models/inventory.model';
import { INVENTORY_API } from '@service/inventory.service';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_CURRENCIES_CODE_OPTIONS, GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import { RegexValidators } from '@config/regex-validator-constant';
import { UNIT_CODE_OPTIONS } from '@utils/unitCodeOptions';
import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericRadio from '@components/common/InlineGenericRadio';
import { useTranslation } from 'react-i18next';

const ACCOUNT_CURRENCIES_TYPE = GET_CURRENCIES_CODE_OPTIONS;

const InventoryCreate = () => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isPageLoading, setPageLoading] = useState<boolean>(false);
    const [unitCode, setUnitCode] = useState<string[] | undefined>([]);
    const [visibleTag, setVisibleTag] = useState(false);

    const navigate = useNavigate();
    const { id: inventoryId } = useParams();

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        clearErrors,
        formState: { errors },
        reset,
    } = useForm<ICreateInventory>({
        mode: 'onChange',
        defaultValues: {
            InventoryType: 'Goods',
            Sale: {
                Currency: GET_DEFAULT_CURRENCY_CODE,
            },
            Purchase: {
                Currency: GET_DEFAULT_CURRENCY_CODE,
            },
        },
    });

    const onCreate = async (data: ICreateInventory) => {
        setLoading(true);
        const response = await INVENTORY_API.createInventory({
            ...data,
            BaseQuantity: Number(data.BaseQuantity),
            UnitCode: unitCode && unitCode?.length > 0 ? unitCode[0] : '',
        });

        if (response) {
            navigate('/items', {
                state: {
                    delay: true,
                },
            });
        } else {
            message.error('Item Creation Failed');
        }
        setLoading(false);
    };

    const onUpdate = async (data: ICreateInventory) => {
        setLoading(true);
        const response = await INVENTORY_API.updateInventory({
            ...data,
            InventoryId: inventoryId || '',
            BaseQuantity: Number(data.BaseQuantity),
            UnitCode: unitCode ? unitCode[0] : '',
        });

        if (response) {
            navigate('/items', {
                state: {
                    delay: true,
                },
            });
            message.success('Item Update Successfully');
        } else {
            message.error('Item Update Failed');
        }
        setLoading(false);
    };

    const getCustomerInfo = async () => {
        if (!inventoryId) return;
        try {
            setPageLoading(true);
            const response = await INVENTORY_API.getSingleInventory(inventoryId);
            reset(response);
            response?.UnitCode && setUnitCode([response.UnitCode]);
            setPageLoading(false);
        } catch (ero) {
            message.error('Item Information Load Failed');
            setPageLoading(false);
        }
    };

    useEffect(() => {
        getCustomerInfo();
    }, [inventoryId]);

    return (
        <Spin spinning={isPageLoading}>
            <div className='inventory-create-wrapper'>
                <form onSubmit={handleSubmit(inventoryId ? onUpdate : onCreate)}>
                    <div className='inventory-create-inner-wrapper'>
                        <div className='form-wrapper'>
                            <Row gutter={120} align='middle' className='margin-bottom-10'>
                                <Col span={11}>
                                    <InlineGenericRadio
                                        gutter={0}
                                        levelCol={8}
                                        inputCol={16}
                                        setFormValue={setFormValue}
                                        control={control}
                                        errors={errors}
                                        clearErrors={clearErrors}
                                        options={[
                                            { label: 'Goods', value: 'Goods' },
                                            {
                                                label: 'Services',
                                                value: 'Services',
                                            },
                                        ]}
                                        dataKey='InventoryType'
                                        level={t('TYPE')}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={120} align='middle' className='margin-bottom-30'>
                                <Col span={11}>
                                    <InlineGenericInput
                                        marginBottom={20}
                                        gutter={0}
                                        levelCol={8}
                                        inputCol={16}
                                        control={control}
                                        errors={errors}
                                        dataKey='Name'
                                        level={t('PRODUCT_NAME_ARABIC')}
                                        type='arabic'
                                        rules={{
                                            required: true,
                                            pattern: {
                                                message:
                                                    'Item Name Arabic allow only arabic character',
                                                value: RegexValidators.ARABIC_CHARACTER,
                                            },
                                        }}
                                    />
                                    <InlineGenericInput
                                        marginBottom={0}
                                        gutter={0}
                                        levelCol={8}
                                        inputCol={16}
                                        control={control}
                                        errors={errors}
                                        dataKey='NameEn'
                                        level={t('PRODUCT_NAME_ENGLISH')}
                                        rules={{
                                            required: true,
                                            pattern: {
                                                value: RegexValidators.ENGLISH_CHARACTER,
                                                message:
                                                    'Item Name English only allow english character',
                                            },
                                        }}
                                    />
                                </Col>
                                <Col span={11}>
                                    <Row align='middle' className='margin-bottom-20'>
                                        <Col span={8}>
                                            <label className='inventory-label'>
                                                {t('UNIT')}
                                                <span className='required-field'>*</span>
                                            </label>
                                        </Col>
                                        <Col span={16}>
                                            <Controller
                                                name='UnitCode'
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={unitCode}
                                                        mode='tags'
                                                        style={{ width: '100%' }}
                                                        placeholder='Select Item Unit'
                                                        options={UNIT_CODE_OPTIONS}
                                                        onChange={(values) => {
                                                            const lastItem = values
                                                                .slice(-1)[0]
                                                                ?.trim();
                                                            if (lastItem?.length > 0) {
                                                                setUnitCode([lastItem]);
                                                                setFormValue(
                                                                    'UnitCode',
                                                                    '[lastItem]',
                                                                );
                                                            } else {
                                                                setUnitCode(undefined);
                                                            }
                                                            setVisibleTag(false);
                                                        }}
                                                        onDropdownVisibleChange={setVisibleTag}
                                                        open={visibleTag}
                                                    />
                                                )}
                                                control={control}
                                                defaultValue=''
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.UnitCode?.type === 'required' && (
                                                <span className='error-message'>
                                                    {t('UNIT_FIELD_IS_REQUIRED')}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>

                                    <InlineGenericInput
                                        gutter={0}
                                        levelCol={8}
                                        inputCol={16}
                                        control={control}
                                        errors={errors}
                                        marginBottom={11}
                                        type='number'
                                        dataKey='BaseQuantity'
                                        level={t('BASE_QUANTITY')}
                                        allowDecimal={false}
                                        rules={{
                                            required: true,
                                            pattern: {
                                                value: RegexValidators.GRATER_THEN_ZERO,
                                                message: 'Base Quantity must be grater then zero',
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className='margin-bottom-40'>
                            <Row gutter={120} align='middle' className='margin-bottom-60'>
                                <Col span={11}>
                                    <Checkbox checked className='inventory-checkbox'>
                                        {t('SALES_INFORMATION')}
                                    </Checkbox>
                                    <Row align='middle' className='margin-bottom-20'>
                                        <Col span={8}>
                                            <label className='inventory-label'>
                                                {t('SELLING_PRICE')}
                                                <span className='required-field'>*</span>
                                            </label>
                                        </Col>
                                        <Col span={16}>
                                            <Controller
                                                name='Sale.Price'
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type='number'
                                                        className='input-custom-group remove-dropdown'
                                                        addonBefore={
                                                            <Controller
                                                                name='Sale.Currency'
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        className='inventory-select'
                                                                        options={
                                                                            ACCOUNT_CURRENCIES_TYPE
                                                                        }
                                                                    />
                                                                )}
                                                                control={control}
                                                            />
                                                        }
                                                        placeholder={t('SELLING_PRICE')}
                                                    />
                                                )}
                                                control={control}
                                                rules={{
                                                    pattern: RegexValidators.GRATER_THEN_ZERO,
                                                    required: true,
                                                }}
                                            />
                                            {errors?.Sale?.Price?.type === 'pattern' && (
                                                <span className='error-message'>
                                                    Selling Price must be grater then zero
                                                </span>
                                            )}
                                            {errors?.Sale?.Price?.type === 'required' && (
                                                <span className='error-message'>
                                                    {t('SELLING_PRICE_FIELD_IS_REQUIRED')}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row
                                        align='middle'
                                        className='margin-bottom-20 alignment-label'
                                    >
                                        <Col span={8}>
                                            <label className='inventory-label'>
                                                {t('DESCRIPTION')}
                                                <span className='required-field'>*</span>
                                            </label>
                                        </Col>
                                        <Col span={16}>
                                            <Controller
                                                name='Sale.Description'
                                                render={({ field }) => (
                                                    <TextArea
                                                        {...field}
                                                        // value={value}
                                                        className='general-input'
                                                        // onChange={(e) => setValue(e.target.value)}
                                                        placeholder='Input sales information regarding your item'
                                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                                    />
                                                )}
                                                control={control}
                                                rules={{
                                                    maxLength: 127,
                                                    required: true,
                                                }}
                                            />
                                            {errors?.Sale?.Description?.type === 'maxLength' && (
                                                <span className='error-message'>
                                                    Description max-length 127 characters
                                                </span>
                                            )}
                                            {errors?.Sale?.Description?.type === 'required' && (
                                                <span className='error-message'>
                                                    {t('DESCRIPTION_FIELD_IS_REQUIRED')}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={11}>
                                    <Checkbox checked className='inventory-checkbox'>
                                        {t('PURCHASE_INFORMATION')}
                                    </Checkbox>
                                    <Row align='middle' className='margin-bottom-20'>
                                        <Col span={8}>
                                            <label className='inventory-label'>
                                                {t('COST_PRICE')}
                                                <span className='required-field'>*</span>
                                            </label>
                                        </Col>
                                        <Col span={16}>
                                            <Controller
                                                name='Purchase.Price'
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type='number'
                                                        className='input-custom-group remove-dropdown'
                                                        addonBefore={
                                                            <Controller
                                                                name='Purchase.Currency'
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        className='inventory-select'
                                                                        options={
                                                                            ACCOUNT_CURRENCIES_TYPE
                                                                        }
                                                                    />
                                                                )}
                                                                control={control}
                                                            />
                                                        }
                                                        placeholder={t('COST_PRICE')}
                                                    />
                                                )}
                                                control={control}
                                                rules={{
                                                    pattern: RegexValidators.GRATER_THEN_ZERO,
                                                    required: true,
                                                }}
                                            />
                                            {errors?.Purchase?.Price?.type === 'pattern' && (
                                                <span className='error-message'>
                                                    Cost Price field must be grater then zero
                                                </span>
                                            )}
                                            {errors?.Purchase?.Price?.type === 'required' && (
                                                <span className='error-message'>
                                                    {t('COST_PRICE_FIELD_IS_REQUIRED')}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row
                                        align='middle'
                                        className='margin-bottom-20 alignment-label'
                                    >
                                        <Col span={8}>
                                            <label className='inventory-label'>
                                                {t('DESCRIPTION')}
                                                <span className='required-field'>*</span>
                                            </label>
                                        </Col>
                                        <Col span={16}>
                                            <Controller
                                                name='Purchase.Description'
                                                render={({ field }) => (
                                                    <TextArea
                                                        {...field}
                                                        // value={value}
                                                        className='general-input'
                                                        // onChange={(e) => setValue(e.target.value)}
                                                        placeholder='Input purchase information regarding your item'
                                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                                    />
                                                )}
                                                control={control}
                                                rules={{
                                                    maxLength: 127,
                                                    required: true,
                                                }}
                                            />
                                            {errors?.Purchase?.Description?.type ===
                                                'maxLength' && (
                                                <span className='error-message'>
                                                    Description max-length 127 characters
                                                </span>
                                            )}
                                            {errors?.Purchase?.Description?.type === 'required' && (
                                                <span className='error-message'>
                                                    {t('DESCRIPTION_FIELD_IS_REQUIRED')}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className='submit-action-buttons'>
                        <CoreButton
                            onClick={() => navigate('/items')}
                            text='Cancel'
                            type='basic'
                            htmlType='button'
                        />
                        <CoreButton
                            loading={isLoading}
                            text='Save'
                            type='primary'
                            htmlType='submit'
                        />
                    </div>
                </form>
            </div>
        </Spin>
    );
};

export default InventoryCreate;
