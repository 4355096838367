import { useEffect, useState } from 'react';
import { getTiers, tierData } from '@store/slices/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { CoreButton } from '@packages';
import CreateTier from './CreateTier';
import { Table, TableProps } from 'antd';
import { DEFAULT_TABLE_SIZE } from '@config/constants';
import { useTranslation } from 'react-i18next';
import { ISubscribePlan } from '@models/subscription.model';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ConfirmationModal } from '@components';
import UpdateTier from "./update-tier/UpdateTier";
import { IUpdateTierPayload } from "@models/billing.model";

const TierList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isTierModalOpen, setTierModalOpen] = useState<boolean>(false);
    const [isUpdateTierModalOpen, setUpdateTierModalOpen] = useState<boolean>(false);
    const [selectedTier, setSelectedTier] = useState<IUpdateTierPayload>();
    const tiers = useAppSelector(tierData);

    useEffect(() => {
        dispatch(getTiers());
    }, []);

    const columns: TableProps<ISubscribePlan>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'Name',
            render: (text: string) => <span>{text}</span>,
        },
        {
            title: 'Number Of Users',
            dataIndex: 'NumberOfUsers',
            render: (text: string) => <span>{text}</span>,
        },
        {
            title: 'Currency',
            dataIndex: 'Currency',
            render: (text: string) => <span>{text}</span>,
        },

        {
            title: 'Monthly Price',
            dataIndex: 'MonthlyPrice',
            render: (text: string) => <span>{text}</span>,
        },
        {
            title: '',
            dataIndex: 'ItemId',
            render: (ItemId: string, data: ISubscribePlan) => (
                <div style={{ display: 'flex', gap: '12px' }}>
                    <EditOutlined onClick={(e) => handleUpdateClick(e, data)} />
                </div>
            ),
        },
    ];

    const handleUpdateClick = (e: any, data: any) => {
        console.log(data,'data')
        setSelectedTier(data);
        setUpdateTierModalOpen(true)
        e.stopPropagation();
    };

    return (
        <div>
            <div className='billing-header'>
                <div className='title'>Tiers</div>
                <div className='right-side'>
                    <CoreButton
                        className='submit-button margin-right-10'
                        type='primary'
                        text={'Create Tier'}
                        isFullWidth={true}
                        size='small'
                        onClick={() => setTierModalOpen(true)}
                    />
                </div>
            </div>
            <CreateTier
                isOpenModal={isTierModalOpen}
                onCancelModal={() => setTierModalOpen(false)}
            />

            {tiers?.length && (
                <Table
                    columns={columns}
                    dataSource={tiers}
                    rowClassName='cursor-pointer'
                    rowKey={'InvoiceId'}
                    pagination={{
                        pageSize: DEFAULT_TABLE_SIZE,
                        total: tiers.length,
                        showSizeChanger: false,
                        showQuickJumper: false,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} ${t('OF')} ${total} ${t('ITEMS')}`,
                    }}
                />
            )}

            <UpdateTier
                isOpenModal={isUpdateTierModalOpen}
                onCancelModal={() => setUpdateTierModalOpen(false)}
                tier={selectedTier || undefined}
            />

        </div>
    );
};
export default TierList;
