import React from "react";
import "./CoreStatus.scss";
import { useTranslation } from "react-i18next";

interface ICoreSearchProps {
    type: "basic" | "success" | "failed" | "inactive" | "cleared" | 'pending' | 'rejected' | 'warning';
    status: string
}

const CoreStatus = (props: ICoreSearchProps) => {
    const { t } = useTranslation();

    const { status, type } = props;
    return (
        <div className={`core-status ${type}`}>
            {t(status)}
        </div>
    );
};

export default CoreStatus;
