import { CoreButton, CoreTitle } from '@packages';
import { Modal } from 'antd';
import './PaymentActionModal.scss';
import { WalletOutlined } from '@ant-design/icons';
import { IPaymentMethod } from '@models/payment-method';
import { PaymentAPI } from '@service/payment.service';
import { ReactNode, useState } from 'react';
import { useAppDispatch } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import warning from '@assets/images/warning-ing.png';
import active from '@assets/images/activate_icon.png';

interface IPaymentActionModalProps {
    title: string;
    subTitle: string;
    isVisible: boolean;
    onClose: () => void;
    actionType: 'Delete' | 'Update' | 'Sucess';
    paymentInfo?: IPaymentMethod;
}

const PaymentActionModal = ({
    title,
    subTitle,
    isVisible,
    onClose,
    actionType,
    paymentInfo,
}: IPaymentActionModalProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onConfirm = async () => {
        setLoading(true);
        PaymentAPI.removePaymentMethods(paymentInfo?.ID || '')
            .then((response) => {
                dispatch(getPaymentMethods());
                setLoading(false);
                onClose();
            })
            .catch((error) => {
                setLoading(false);

                console.error(error);
            });
    };

    return (
        <Modal
            className='payment-action-modal-wrapper'
            open={isVisible}
            onCancel={onClose}
            closable={false}
            footer={[]}
        >
            {actionType === 'Delete' && (
                <div className='warning-icon'>
                    <img src={warning} alt='warning' />
                </div>
            )}

            {actionType === 'Sucess' && (
                <div className='warning-icon'>
                    <img src={active} alt='active' />
                </div>
            )}

            <div className='payment-action-modal-header'>
                <CoreTitle tag='h5' text={title} />
                <p>{subTitle}</p>
            </div>
            {actionType != 'Sucess' && (
                <div className='card-info'>
                    <p className='info-card-title'>
                        <WalletOutlined />
                        Credit Card
                    </p>
                    <p className='info-card-sub-title'>
                        {paymentInfo?.Brand} Ending in {paymentInfo?.Last4}
                    </p>
                </div>
            )}

            {actionType === 'Sucess' ? (
                <div className='modal-submition-actions'>
                    <CoreButton size='middle' type='primary' text='Done' onClick={onClose} />
                </div>
            ) : (
                <div className='modal-submition-actions'>
                    <CoreButton size='middle' type='basic' text='Cancel' onClick={onClose} />
                    <CoreButton
                        size='middle'
                        type={actionType === 'Delete' ? 'danger' : 'primary'}
                        text={actionType}
                        onClick={onConfirm}
                        loading={isLoading}
                    />
                </div>
            )}
        </Modal>
    );
};

export default PaymentActionModal;
