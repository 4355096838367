import * as customer from './customer';
import * as common from './common';
import * as organization from './organization';
import * as invoice from './invoice';
import * as inventory from './inventory';
import * as auth from './inventory';
import * as subscription from './subscription';
import * as dashboard from './dashboard';
import * as product from './product';
import * as profile from './profile';
import * as invoiceLines from './invoice-lines';
import * as dashboardTwo from './dashboard-two'; 

export const enTransLation = {
    ...common.enTranslations,
    ...customer.enTranslations,
    ...organization.enTranslations,
    ...invoice.enTranslations,
    ...inventory.enTranslations,
    ...auth.enTranslations,
    ...subscription.enTranslations,
    ...dashboard.enTranslations,
    ...product.enTranslations,
    ...profile.enTranslations,
    ...invoiceLines.enTranslations,
    ...dashboardTwo.enTranslations,
};

export const saTransLation = {
    ...common.saTranslations,
    ...customer.saTranslations,
    ...organization.saTranslations,
    ...invoice.saTranslations,
    ...inventory.saTranslations,
    ...auth.saTranslations,
    ...subscription.saTranslations,
    ...dashboard.saTranslations,
    ...product.saTranslations,
    ...profile.saTranslations,
    ...invoiceLines.saTranslations,
    ...dashboardTwo.saTranslations,
};
