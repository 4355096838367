import { IMapField } from './../../constant';
import { InvoiceHeader } from '@models/upload-csv';
import { List } from 'antd';
import { mergedData } from '../../../../utils/InvoiceUtils';

interface IUploadDetailsTable {
    apiHeader: InvoiceHeader[];
    localHeader: IMapField[];
}

const UploadInvoiceCollapseView = ({ apiHeader, localHeader }: IUploadDetailsTable) => {
    const data = mergedData(apiHeader, localHeader);
    return (
        <div>
            <List
                bordered
                dataSource={data}
                renderItem={(item: any) => (
                    <List.Item>
                        <div>
                            {item.label} - {item.fileHeader !== '' ? item.fileHeader : 'Empty'}
                        </div>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default UploadInvoiceCollapseView;
