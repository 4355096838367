import React from 'react';
import { Button } from 'antd';
import { CoreTitle } from '@packages';
import { WarningTwoTone } from '@ant-design/icons';
import './RevokeCertificate.scss';

interface IRevokeCertificate {
    handleYes: () => void;
    handleNo: () => void;
}

const RevokeCertificate = (props: IRevokeCertificate) => {
    const { handleYes, handleNo } = props;

    return (
        <div className='revoke-certificate-wrapper'>
            <div className='revoke-certificate-icon'>
                <WarningTwoTone twoToneColor='#DC6803' />
            </div>
            <div>
                <CoreTitle tag='h5' text='Revoke a certificate' />
                <p className='confirm-message'>Are you sure revoke this certificate</p>
            </div>
            <div className='revoke-button-wrapper'>
                <Button className='revoke-button no' onClick={handleNo}>
                    No
                </Button>
                <Button className='revoke-button yes' onClick={handleYes}>
                    Yes
                </Button>
            </div>
        </div>
    );
};

export default RevokeCertificate;
