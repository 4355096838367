import { useEffect, useMemo } from 'react';
import { Row, Col, message } from 'antd';
import { CoreBackButton, CoreButton, CoreTitle } from '@packages';
import { useForm } from 'react-hook-form';
import './OrganizationUpdate.scss';
import { RegexValidators } from '@config/regex-validator-constant';
import { ICountry } from '@models/generic.model';
import countryList from 'react-select-country-list';
import { IOrganizationUpdatePayload } from '@models/organization.model';
import { OrganizationAPI } from '@service/organization.service';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@store/hooks';
import { getOrganization } from '@store/slices/organizationSlice';
import { CRS_OPTIONS } from '@utils/CRSOptions';
import { useTranslation } from 'react-i18next';
import InlineGenericInput from '@components/common/InlineGenericInput';
import GenericLegalRegistrationName from '@components/common/GenericLegalRegistrationName';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import GenericStreetName from '@components/common/GenericStreetName';
import GenericInputMaxLimit from '@components/common/GenericInputMaxLimit';
import GenericPostalCode from '@components/common/GenericPostalCode';
import { getSubscription } from '@store/slices/subscribeSlice';

const OrganizationUpdate = () => {
    const { t } = useTranslation();
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);
    const { organization } = useSelector((state: RootState) => state.organization);
    const { organizationId = '' } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getOrganization(organizationId));
    }, []);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<IOrganizationUpdatePayload>({
        mode: 'onTouched',
    });

    useEffect(() => {
        if (organization) {
            reset({
                Address: {
                    Street: organization?.Address?.Street,
                    StreetEn: organization?.Address?.StreetEn,
                    BuildingNumber: organization?.Address?.BuildingNumber,
                    AdditionalNumber: organization?.Address?.AdditionalNumber,
                    AdditionalStreet: organization?.Address?.AdditionalStreet,
                    AdditionalStreetEn: organization?.Address?.AdditionalStreetEn,
                    City: organization?.Address?.City,
                    CityEn: organization?.Address?.CityEn,
                    CountryCode: organization?.Address?.CountryCode,
                    District: organization?.Address?.District,
                    DistrictEn: organization?.Address?.DistrictEn,
                    PostalCode: organization?.Address?.PostalCode,
                    ProvinceOrState: organization?.Address?.ProvinceOrState,
                    ProvinceOrStateEn: organization?.Address?.ProvinceOrStateEn,
                },
                CompanyRegistrationNumber: organization?.CompanyRegistrationNumber,
                CompanyRegistrationScheme: organization?.CompanyRegistrationScheme,
                Contact: {
                    FirstName: organization?.Contact?.FirstName,
                    FirstNameEn: organization?.Contact?.FirstNameEn,
                    JobTitle: organization?.Contact?.JobTitle,
                    JobTitleEn: organization?.Contact?.JobTitleEn,
                    LastName: organization?.Contact?.LastName,
                    LastNameEn: organization?.Contact?.LastNameEn,
                    MiddleName: organization?.Contact?.MiddleName,
                    MiddleNameEn: organization?.Contact?.MiddleNameEn,
                },
                Email: organization?.Email,
                Fax: organization?.Fax,
                LegalRegistrationName: organization?.LegalRegistrationName,
                LegalRegistrationNameEn: organization?.LegalRegistrationNameEn,
                OrganizationId: organization?.OrganizationId,
                PhoneNumber: organization?.PhoneNumber,
                TaxScheme: 'VAT',
                VatRegistrationNumber: organization?.VatRegistrationNumber,
            });
        }
    }, [organization]);
    const onSubmit = async (data: IOrganizationUpdatePayload) => {
        const payload = { ...data };
        payload.Address.AdditionalNumber = +data?.Address?.AdditionalNumber;
        payload.Address.BuildingNumber = +data?.Address?.BuildingNumber;
        payload.Address.PostalCode = +data?.Address?.PostalCode;
        const response = await OrganizationAPI.updateOrganization(payload);
        if (response.Success) {
            message.success(t('ORGNIZATION_UPDATED_SUCCESSFULLY'));
            navigate(`/organizations/${organizationId}`);
            dispatch(getSubscription());
        }
    };
    return (
        <div className='container organization-update'>
            <CoreBackButton text={''} />
            <div className='round-box-shadow'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CoreTitle tag='h4' text={t('ORGANIZATION_INFORMATION')} />

                    <InlineGenericInput
                        tooltip={t(
                            'VAT_REGISTRATION_NUMBER_REFERS_TO_A_UNIQUE_IDENTIFICATION_NUMBER_ASSIGNED_TO_A_BUSINESS',
                        )}
                        disabled={true}
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='VatRegistrationNumber'
                        level={t('VAT_REGISTRATION_NUMBER')}
                        marginBottom={20}
                        type='vatRegistrationNumber'
                        rules={{
                            required: true,
                            pattern: RegexValidators.VAT_REGISTRATION_NUMBER,
                        }}
                        hideError={true}
                    >
                        {errors.VatRegistrationNumber?.type === 'required' && (
                            <span className='error-message'>
                                {t('VAT_REGISTRATION_NUMBER_IS_REQUIRED')}
                            </span>
                        )}

                        {errors.VatRegistrationNumber?.type === 'pattern' && (
                            <span className='error-message'>
                                {t('VAT_REGISTRATION_NUMBER_MUST_BEING_WITH')}
                                &apos;3&apos; {t('AND_END_WITH')} &apos;3&apos;. (e.g.
                                311115670001243).
                            </span>
                        )}
                    </InlineGenericInput>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <GenericLegalRegistrationName
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='LegalRegistrationName'
                                level={t('LEGAL_REGISTRATION_NAME_ARABIC')}
                                marginBottom={20}
                                type='arabic'
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t(
                                            'ONLY_ARBIC_TEXT_IS_ALLOWED_FOR_LEGAL_REGISTRATION_NAME',
                                        ),
                                    },
                                    maxLength: 1000,
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <GenericLegalRegistrationName
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='LegalRegistrationNameEn'
                                level={t('LEGAL_REGISTRATION_NAME_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t(
                                            'ONLY_ENGLISH_TEXT_IS_ALLOWED_FOR_LEGAL_REGISTRATION_NAME',
                                        ),
                                    },
                                    maxLength: 1000,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericSelect
                                control={control}
                                tooltip={t(
                                    'AN_ALTERNATICE_IDENTIFICATION_METHOD_USED_TO_IDENTITY_SELLER',
                                )}
                                disabled={true}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='CompanyRegistrationScheme'
                                level={t('OTHER_SELLER_ID_TYPE')}
                                placeHolder={t('OTHER_SELLER_ID_TYPE')}
                                options={CRS_OPTIONS}
                                setFormValue={setFormValue}
                                rules={{
                                    required: true,
                                }}
                                allowClear
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                tooltip={t(
                                    'LICENSE_NUMBER_THAT_CORRESPONDS_WITH_THE_OTHER_SELLER_ID_TYPE_SELECTED_PREVIOUSLY',
                                )}
                                disabled={true}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='CompanyRegistrationNumber'
                                level={t('OTHER_SELLER_ID')}
                                marginBottom={20}
                                type='otherSellerId'
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ONLY_NUMERIC,
                                        message: t('OTHER_SELLER_ID_NUMERIC'),
                                    },
                                    maxLength: 10,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='PhoneNumber'
                                level={t('PHONE_NUMBER')}
                                type='phoneNumber'
                                marginBottom={20}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.NUMERIC_WITH_PLUS,
                                        message: t('PHONE_NUMBER_BE_ONLY_NUMERIC'),
                                    },
                                    maxLength: 15,
                                }}
                                hideError={false}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='TaxScheme'
                                level={t('TAX_SCHEME')}
                                marginBottom={20}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <CoreTitle tag='h4' text={t('POSTAL_ADDRESS')} />
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.BuildingNumber'
                                level={t('BUILDING_NUMBER')}
                                marginBottom={20}
                                type='buildingNumber'
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ONLY_NUMERIC,
                                        message: t('BUILDING_NUMBER_SHOULD_BE_NUMERIC'),
                                    },
                                    maxLength: {
                                        value: 4,
                                        message: t('BUILDING_NUMBER_MUST_BE_4_DIGITS'),
                                    },
                                    minLength: {
                                        value: 4,
                                        message: t('BUILDING_NUMBER_MUST_BE_4_DIGITS'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.AdditionalNumber'
                                level={t('ADDITIONAL_NUMBER')}
                                rules={{
                                    maxLength: {
                                        value: 4,
                                        message: t('ADDITIONAL_NUMBER_MUST_BE_4_DIGITS'),
                                    },
                                }}
                                marginBottom={20}
                                type='number'
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <GenericStreetName
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.Street'
                                level={t('STREET_ARABIC')}
                                marginBottom={20}
                                type='arabic'
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED_IN_STREET'),
                                    },
                                    maxLength: 1000,
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <GenericStreetName
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.StreetEn'
                                level={t('STREET_ENGLISH')}
                                type='english'
                                marginBottom={20}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED_IN_STREET'),
                                    },
                                    maxLength: 1000,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.AdditionalStreet'
                                level={t('ADDITIONAL_STREET_ARABIC')}
                                marginBottom={20}
                                maxLimit={127}
                                type='arabic'
                                rules={{
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.AdditionalStreetEn'
                                level={t('ADDITIONAL_STREET_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.City'
                                level={t('CITY_ARABIC')}
                                marginBottom={20}
                                maxLimit={127}
                                type='arabic'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.CityEn'
                                level={t('CITY_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.District'
                                level={t('DISTRICT_ARABIC')}
                                marginBottom={20}
                                maxLimit={127}
                                type='arabic'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.DistrictEn'
                                level={t('DISTRICT_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.ProvinceOrState'
                                level={t('PROVINCE_ARABIC')}
                                marginBottom={20}
                                maxLimit={127}
                                type='arabic'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.ProvinceOrStateEn'
                                level={t('PROVINCE_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <GenericPostalCode
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.PostalCode'
                                level={t('POSTAL_CODE')}
                                marginBottom={20}
                                type='number'
                                rules={{
                                    required: true,
                                    minLength: {
                                        value: 5,
                                        message: 'Postal Code minimum length is 5 digit',
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericSelect
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.CountryCode'
                                level={t('COUNTRY')}
                                placeHolder={t('SELECT_COUNTRY')}
                                options={countryOptions}
                                setFormValue={setFormValue}
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>

                    <CoreTitle tag='h4' text={t('CONTACT')} />
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.FirstName'
                                level={t('FIRST_NAME_ARABIC')}
                                type='arabic'
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ARABIC_TEXT_VALIDATION'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.FirstNameEn'
                                level={t('FIRST_NAME_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ENGLISH_TEXT_VALIDATION'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.LastName'
                                level={t('LAST_NAME_ARABIC')}
                                type='arabic'
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ARABIC_TEXT_VALIDATION'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.LastNameEn'
                                level={t('LAST_NAME_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ENGLISH_TEXT_VALIDATION'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.MiddleName'
                                level={t('MIDDLE_NAME_ARABIC')}
                                type='arabic'
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ARABIC_TEXT_VALIDATION'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.MiddleNameEn'
                                level={t('MIDDLE_NAME_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ENGLISH_TEXT_VALIDATION'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.JobTitle'
                                level={t('JOB_TITLE_ARABIC')}
                                type='arabic'
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ARABIC_TEXT_VALIDATION'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.JobTitleEn'
                                level={t('JOB_TITLE_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ENGLISH_TEXT_VALIDATION'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Email'
                                level={t('EMAIL_ADDRESS')}
                                marginBottom={20}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('EMAIL_FIELD_IS_REQUIRED'),
                                    },
                                    pattern: {
                                        value: RegexValidators.EMAIL,
                                        message: t('INVALID_ENTRY_EMAIL.COM'),
                                    },
                                }}
                            />
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                type='number'
                                dataKey='Fax'
                                level={t('FAX')}
                                marginBottom={20}
                            />
                        </Col>
                    </Row>
                    <div className='right-side-submit-button'>
                        <CoreButton htmlType='submit' type='primary' text={t('UPDATE')} />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OrganizationUpdate;
