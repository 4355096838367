import { CloudUploadOutlined } from "@ant-design/icons";
import { UploadFile, UploadProps, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import "./CoreFileUploader.scss";
import { RcFile } from "antd/es/upload";
import { fileStorageAPI } from "@service/file-storage.service";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IFileInfo {
    uploadId: string;
    url: string
}

export interface ICoreFileUPloadProps {
    accept?: 'image' | 'pdf' | 'excel' | 'all';
    onFileUpload?: (info: IFileInfo) => void,
    onRemoveFile?: (info: IFileInfo) => void,
    uploadHint?: string,
    maxWidth?: number,
    maxHeight?: number,

}

const fileAcceptValue = {
    pdf: 'application/pdf',
    image: 'image/png, image/jpeg',
    excel: 'application/vnd.ms-excel',
    all: 'application/vnd.ms-excel, application/pdf, image/png, image/jpeg,'
}

const CoreFileUploadDragger = ({
    accept,
    onFileUpload,
    onRemoveFile,
    uploadHint,
    maxHeight = 400,
    maxWidth = 800
}: ICoreFileUPloadProps) => {
    const [file, setFile] = useState<UploadFile<any>>();
    const fileAccept = accept ? fileAcceptValue[accept] : fileAcceptValue.image;
    const { t } = useTranslation()

    const props: UploadProps = {
        accept: fileAccept,
        multiple: false,
        maxCount: 1,
        async beforeUpload(file: RcFile) {
            try {
                const isImage = file.type.startsWith('image/');
                if (!isImage) {
                    message.error(t('YOU_CAN_ONLY_UPLOAD_IMAGE_FILE'));
                }



                const getBase64File = await fileStorageAPI.getBase64File(file);

                const image = new Image();
                image.src = getBase64File;

                await new Promise<void>((resolve) => {
                    image.onload = () => resolve();
                });

                const { width, height } = image;

                console.log(width, 'width')
                console.log(height, 'height')
                if (width > maxWidth || height > maxHeight) {
                    message.error(t('UNSUCCESSFUL_PICTURE_UPLOAD'));
                    return false;
                }


                setFile({ name: file.name, uid: file.uid, status: 'uploading' });
                const fileId = await fileStorageAPI.uploadFile(file);
                setFile({ name: file.name, uid: file.uid, status: 'done' })
                onFileUpload && onFileUpload({ uploadId: fileId, url: getBase64File });
                return false;
            } catch (error: any) {
                message.error(error?.message || t('FILE_UPLOAD_FAILED'));
                setFile({ name: file.name, uid: file.uid, status: 'error' })
                return false;
            }
        },
        onRemove() {
            setFile(undefined)
            onFileUpload && onFileUpload({ uploadId: '', url: '' })
        }
    };
    return (
        <div className="ksa-upload-wrapper">
            <Dragger {...props} fileList={file ? [file] : []}>
                <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                    <span className="upload-text">{t('CLICK_TO_UPLOAD')}</span>
                    <span className="drag-n-drop-text">{t('OR_DRAG_AND_DROP')}</span>
                </p>
                <p className="ant-upload-hint">{uploadHint || 'File Format: CSV, TSV or XLS (Maximum file size: 5MB)'}</p>
            </Dragger>
        </div>
    );
};
export default CoreFileUploadDragger;
