import { PlusOutlined } from '@ant-design/icons';
import CardList from '../../card/CardList';
import AddPayment from '../../add-payment/AddPayment';
import { CoreButton, CoreTitle } from '@packages';
import { payAsYouGoId } from '@utils/payAsGoTireData';
import { message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISubscribe } from '@models/subscription.model';
import { SubscribeAPI } from '@service/subscription.service';
import { useAppDispatch } from '@store/hooks';
import { getSubscription } from '@store/slices/subscribeSlice';
import './PaymentMethodSection.scss';

export interface IPaymentMethodSectionProps {
    planId: string;
    recurringType: string;
}

const PaymentMethodSection = ({ planId, recurringType }: IPaymentMethodSectionProps) => {
    const [isAddPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);
    const [cardId, setCardId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onPaymentSubmit = async () => {
        if (!planId || !cardId || !recurringType) {
            message.warning('Your payment method, recurring mode or tire missing');
            return;
        }
        setLoading(true);

        try {
            const payload: ISubscribe = {};
            if (planId === payAsYouGoId) {
                payload.PayAsYouGo = true;
                payload.PaymentMethodId = cardId;
            } else {
                payload.Recurring = recurringType;
                payload.TierId = planId;
                payload.PaymentMethodId = cardId;
            }

            const response = await SubscribeAPI.Subscribe(payload);
            message.success('Payment Successfully Created');
            setLoading(false);
            dispatch(getSubscription());
            navigate('/subscribe/subscribe-success');
        } catch (error: any) {
            message.error(error?.Message);
            setLoading(false);
        }
    };

    return (
        <div className='payment-method-section'>
            <div className='summary-title-wrapper'>
                <CoreTitle tag='h4' text='Payment Method' />
                <CoreButton
                    text='Add New Card'
                    antType='link'
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => setAddPaymentModalOpen(true)}
                />
                <AddPayment
                    isOpenModal={isAddPaymentModalOpen}
                    onCancelModal={() => setAddPaymentModalOpen(false)}
                />
            </div>
            <div className='payment-method-actions'>
                <CardList selectedCardId={cardId} setSelectedId={setCardId} />
                <CoreButton
                    loading={loading}
                    type='primary'
                    text='Subscribe'
                    isFullWidth={true}
                    onClick={onPaymentSubmit}
                />
            </div>
        </div>
    );
};

export default PaymentMethodSection;
