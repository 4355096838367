export function isCardExpired(expMonth: number, expYear: number) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based

    // Check if the year is in the past
    if (expYear < currentYear) {
        return true;
    }

    // If the year is the same, check the month
    if (expYear === currentYear && expMonth < currentMonth) {
        return true;
    }

    // If neither condition is met, the card is not expired
    return false;
}
