import {
    checkArabicCharacterInput,
    checkBuildingNumber,
    checkNumberInput,
    checkOtherSellerId,
    checkPhoneNumber,
    checkVatRegistrationNumber,
} from '@utils/checkInputType';
import { Col, Input, Row } from 'antd';
import { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import GenericErrorMessage, { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { CustomRules } from '@models/rules.model';
import { isFiledFoundError, isRequiredValue } from '@utils/errorMessageOption';

interface IInputGenericBaseParams {
    control: Control<any, any>;
    errors: FieldErrors<any>;
    level: string;
    dataKey: string;
    disabled?: boolean;
    rules?: CustomRules;
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    marginBottom?: number;
    placeHolder?: string;
    hideError?: boolean;
    children?: React.ReactNode;
    gutter?: number;
    tooltip?: string;
    errorMessageSize?: ErrorMessageSize;
    defaultValue?: string | number | readonly string[] | undefined;
}
type IInputGenericTextParams = IInputGenericBaseParams & {
    type?: 'text' | 'arabic';
};
type IInputGenericNumberParams = IInputGenericBaseParams & {
    type?: 'number' | 'otherSellerId' | 'phoneNumber' | 'buildingNumber' | 'vatRegistrationNumber';
    allowDecimal?: boolean;
};
type IInlineGenericInputParams = IInputGenericTextParams | IInputGenericNumberParams;

const InlineGenericInput: FC<IInlineGenericInputParams> = ({
    control,
    errors,
    level,
    dataKey,
    rules,
    type,
    disabled,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    marginBottom = 30,
    placeHolder,
    hideError = false,
    children,
    gutter = 130,
    tooltip,
    errorMessageSize,
    defaultValue,
    ...others
}) => {
    const isAllowDecimal = (others as IInputGenericNumberParams)?.allowDecimal ?? false;
    const { t } = useTranslation();

    return (
        <Row gutter={gutter} align='middle' style={{ marginBottom: marginBottom }}>
            {showLevel && (
                <Col span={levelCol}>
                    <label className='general-label'>
                        {t(level)}
                        {level && rules?.required && <span className='required-field'>*</span>}
                        {tooltip && <GenericTooltip text={tooltip} />}
                    </label>
                </Col>
            )}
            <Col span={inputCol}>
                <Controller
                    name={dataKey as any}
                    render={({ field }) =>
                        type === 'number' ? (
                            <Input
                                {...field}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                onKeyPress={isAllowDecimal ? undefined : checkNumberInput}
                                type='number'
                                placeholder={placeHolder ? placeHolder : t(level)}
                                max={rules?.max as number}
                                min={rules?.min as number}
                                disabled={disabled ?? false}
                                className='general-input remove-dropdown'
                                defaultValue={defaultValue}
                            />
                        ) : type === 'otherSellerId' ? (
                            <Input
                                {...field}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                onKeyPress={checkOtherSellerId}
                                placeholder={placeHolder ? placeHolder : t(level)}
                                type={'text'}
                                max={rules?.max as number}
                                min={rules?.min as number}
                                className='general-input'
                                disabled={disabled ?? false}
                                defaultValue={defaultValue}
                            />
                        ) : type === 'vatRegistrationNumber' ? (
                            <Input
                                {...field}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                onKeyPress={checkVatRegistrationNumber}
                                placeholder={placeHolder ? placeHolder : t(level)}
                                type={'text'}
                                max={rules?.max as number}
                                min={rules?.min as number}
                                className='general-input'
                                defaultValue={defaultValue}
                                disabled={disabled ?? false}
                            />
                        ) : type === 'phoneNumber' ? (
                            <Input
                                {...field}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                onKeyPress={checkPhoneNumber}
                                placeholder={placeHolder ? placeHolder : t(level)}
                                type={'text'}
                                max={rules?.max as number}
                                min={rules?.min as number}
                                className='general-input'
                                defaultValue={defaultValue}
                                disabled={disabled ?? false}
                            />
                        ) : type === 'buildingNumber' ? (
                            <Input
                                {...field}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                onKeyPress={checkBuildingNumber}
                                placeholder={placeHolder ? placeHolder : t(level)}
                                type={'text'}
                                max={rules?.max as number}
                                min={rules?.min as number}
                                className='general-input'
                                defaultValue={defaultValue}
                                disabled={disabled ?? false}
                            />
                        ) : (
                            <Input
                                {...field}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                onKeyPress={
                                    type === 'arabic' ? checkArabicCharacterInput : undefined
                                }
                                placeholder={placeHolder ? placeHolder : t(level)}
                                type={'text'}
                                max={rules?.max as number}
                                min={rules?.min as number}
                                defaultValue={defaultValue}
                                className='general-input'
                                disabled={disabled ?? false}
                            />
                        )
                    }
                    control={control}
                    rules={{ ...rules, required: isRequiredValue(rules) }}
                />
                {!hideError && (
                    <GenericErrorMessage
                        errors={errors}
                        dataKey={dataKey}
                        rules={rules}
                        level={level}
                        errorMessageSize={errorMessageSize}
                    />
                )}
                {children}
            </Col>
        </Row>
    );
};

export default InlineGenericInput;
