import './DashboardTwo.scss';
import {
    DashboardBarChart,
    DashboardBlockCard,
    DashboardDoubleLineChart,
    DashboardLineChart,
    InvoiceProcessingSummary,
    TaxCodeOverview,
    TypesOfGeneratedInvoices,
} from '../../index';
import { CoreTitle } from '@packages';
import { useTranslation } from 'react-i18next';
import DashboardFilter from '@components/dashboard-filter/DashboardFilter';
import { Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    dashboardCharts,
    dashboardChips,
    dashboardFilter,
    dataQuality,
    getDashboardCharts,
    getDashboardChips,
    getDashboardData,
    getDataQualitySummary,
    getRejectionSummary,
    getTaxCodeOverview,
    getTypesOfGeneratedInvoices,
    rejectSummary,
    taxCodeOverview,
    totalGeneratedSum,
    totalGeneratedTax,
    typesOfGeneratedInvoices,
} from '@store/slices/dashboardSlice';
import { useEffect } from 'react';
import DataQualitySummaryCard from '../../components/data-quality-summary-card/DataQualitySummaryCard';
import { selectLoggedUserInfo } from '@store/slices/userSlice';
import { TotalInvoicesYetToBeSubmittedDummyData } from '@utils/dashboardDummyData';

const DashboardTwo = () => {
    const { FirstName, LastName } = useAppSelector(selectLoggedUserInfo).userDetails;
    const { t } = useTranslation();

    const { isLoading: typesOfGeneratedInvoicesLoading, data: typesOfGeneratedInvoicesData } =
        useAppSelector(typesOfGeneratedInvoices);
    const { isLoading: taxCodeOverviewLoading, data: taxCodeOverviewData } =
        useAppSelector(taxCodeOverview);
    const { isLoading: dataQualityLoading, data: dataQualityData } = useAppSelector(dataQuality);
    const { isLoading: dashboardChipsLoading, data: dashboardChipsData } =
        useAppSelector(dashboardChips);
    const { isLoading: dashboardChartsLoading, data: dashboardChartsData } =
        useAppSelector(dashboardCharts);
    const { isLoading: rejectSummaryLoading, data: rejectSummaryData } =
        useAppSelector(rejectSummary);
    const totalInvoices = useAppSelector(totalGeneratedSum);
    const totalTaxAmount = useAppSelector(totalGeneratedTax);
    const dashboardFilterDate = useAppSelector(dashboardFilter);

    const dispatch = useAppDispatch();

    const callDashboardEndPoint = () => {
        dispatch(getDashboardData(dashboardFilterDate));
        dispatch(getTaxCodeOverview(dashboardFilterDate));
        dispatch(getTypesOfGeneratedInvoices(dashboardFilterDate));
        dispatch(getDataQualitySummary(dashboardFilterDate));
        dispatch(getDashboardCharts(dashboardFilterDate));
        dispatch(getDashboardChips(dashboardFilterDate));
        dispatch(getRejectionSummary(dashboardFilterDate));
    };

    useEffect(() => {
        callDashboardEndPoint();
    }, [dashboardFilterDate]);

    return (
        <div className='dashboard-two-wrapper'>
            <div className='dashboard-two-inner-wrapper'>
                <div className='header dashboard-header'>
                    <div>
                        <CoreTitle tag='h1' text={t('WELCOME_BACK')} />
                        <p>
                            {t('GOOD_TO_SEE_YOU_AGAIN')} {FirstName} {LastName}
                        </p>
                    </div>
                    <div className='monthly-filter-container'>
                        <div className='monthly-filter-label'>{t('FILTER')}</div>
                        <div className='monthly-filter-content'>
                            <DashboardFilter />
                        </div>
                    </div>
                </div>
                <div className='dashboard-main-container'>
                    <DashboardBlockCard
                        title={t('TOTAL_INVOICE_COUNT')}
                        value={dashboardChipsData?.totalCount || 0}
                        // percentage={24}
                        // rightArrow={true}
                        // bottomArrow={true}
                        dashboardChipsLoading={dashboardChipsLoading}
                        style={{ gridArea: 'box1' }}
                    />
                    <DashboardBlockCard
                        title={t('STANDARD_TAX_INVOICES')}
                        value={dashboardChipsData?.standardInvoiceCount || 0}
                        // percentage={24}
                        // rightArrow={true}
                        variant='red'
                        dashboardChipsLoading={dashboardChipsLoading}
                        style={{ gridArea: 'box2' }}
                    />
                    <DashboardBlockCard
                        title={t('CREDIT_NOTE')}
                        value={dashboardChipsData?.standardCreditNoteCount || 0}
                        // percentage={24}
                        // rightArrow={true}
                        variant='yellow'
                        dashboardChipsLoading={dashboardChipsLoading}
                        style={{ gridArea: 'box3' }}
                    />
                    <DashboardBlockCard
                        title={t('STANDARD_INVOICE_CLEARED')}
                        value={dashboardChipsData?.standardClearedCount || 0}
                        // percentage={24}
                        variant='purple'
                        dashboardChipsLoading={dashboardChipsLoading}
                        style={{ gridArea: 'box4' }}
                    />
                    <DashboardBlockCard
                        title={t('SIMPLIFIED_TAX_INVOICES')}
                        value={dashboardChipsData?.simplifiedInvoiceCount || 0}
                        // percentage={24}
                        // bottomArrow={true}
                        variant='orange'
                        dashboardChipsLoading={dashboardChipsLoading}
                        style={{ gridArea: 'box5' }}
                    />
                    <DashboardBlockCard
                        title={t('SIMPLIFIED_CREDIT_NOTE')}
                        value={dashboardChipsData?.simplifiedCreditNoteCount || 0}
                        // percentage={24}
                        // bottomArrow={true}
                        variant='blue'
                        dashboardChipsLoading={dashboardChipsLoading}
                        style={{ gridArea: 'box6' }}
                    />
                    <DashboardBlockCard
                        title={t('SIMPLIFIED_INVOICE_CLEARED')}
                        value={dashboardChipsData?.simplifiedReportedCount || 0}
                        // percentage={24}
                        variant='pink'
                        dashboardChipsLoading={dashboardChipsLoading}
                        style={{ gridArea: 'box7' }}
                    />
                    <div style={{ gridArea: 'content', position: 'relative' }} className='content'>
                        <InvoiceProcessingSummary />
                    </div>
                </div>
                <Row gutter={24}>
                    <Col span={24} xl={12}>
                        {taxCodeOverviewData && (
                            <TaxCodeOverview
                                TaxCodeOverviewData={taxCodeOverviewData}
                                taxCodeOverviewLoading={taxCodeOverviewLoading}
                            />
                        )}
                    </Col>
                    <Col span={24} xl={12}>
                        {typesOfGeneratedInvoicesData && (
                            <TypesOfGeneratedInvoices
                                typesOfGeneratedInvoicesData={typesOfGeneratedInvoicesData}
                                typesOfGeneratedInvoicesLoading={typesOfGeneratedInvoicesLoading}
                            />
                        )}
                    </Col>
                </Row>

                <div>
                    <Row gutter={24}>
                        <Col span={12}>
                            {dashboardChartsData && (
                                <DashboardLineChart
                                    dashboardChartsLoading={dashboardChartsLoading}
                                    data={dashboardChartsData}
                                    total={totalInvoices}
                                    title={t('TOTAL_INVOICES_INTERFACED')}
                                    header_title={t('TOTAL_INVOICES')}
                                    totalNumberType={'number'}
                                    stroke_color={'#142057'}
                                />
                            )}
                        </Col>
                        <Col span={12}>
                            {dashboardChartsData && (
                                <DashboardLineChart
                                    dashboardChartsLoading={dashboardChartsLoading}
                                    data={dashboardChartsData}
                                    total={totalTaxAmount}
                                    title={t('TOTAL_TAX_AMOUNT')}
                                    header_title={t('TAX_AMOUNT')}
                                    stroke_color={'#77A6FF'}
                                />
                            )}
                        </Col>
                        {/*<Col span={8}>*/}
                        {/*    <DashboardDoubleLineChart*/}
                        {/*        data={TotalInvoicesYetToBeSubmittedDummyData}*/}
                        {/*        title={t('TOTAL_INVOICES_YET_TO_SUBMIT')}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                    </Row>
                </div>

                <div className='margin-top-20'>
                    <Row gutter={24}>
                        <Col span={12}>
                            {rejectSummaryData && (
                                <DashboardBarChart
                                    passedData={rejectSummaryData?.passed}
                                    failedData={rejectSummaryData?.failed}
                                    title={t('REJECTION_SUMMARY')}
                                    rejectionSummaryLoading={rejectSummaryLoading}
                                />
                            )}
                        </Col>
                        <Col span={12}>
                            {dataQualityData && (
                                <DataQualitySummaryCard
                                    cleared={dataQualityData?.cleared}
                                    clearedWithWarning={dataQualityData?.clearedWithWarning}
                                    dataQualityLoading={dataQualityLoading}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default DashboardTwo;
