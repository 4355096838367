import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const OrgSvg = () => (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M6.25008 8.16667H3.83341C3.3667 8.16667 3.13335 8.16667 2.95509 8.25749C2.79829 8.33739 2.6708 8.46487 2.59091 8.62167C2.50008 8.79993 2.50008 9.03329 2.50008 9.5V16.5M13.7501 8.16667H16.1667C16.6335 8.16667 16.8668 8.16667 17.0451 8.25749C17.2019 8.33739 17.3294 8.46487 17.4093 8.62167C17.5001 8.79993 17.5001 9.03329 17.5001 9.5V16.5M13.7501 16.5V4.16667C13.7501 3.23325 13.7501 2.76654 13.5684 2.41002C13.4086 2.09641 13.1537 1.84144 12.8401 1.68166C12.4835 1.5 12.0168 1.5 11.0834 1.5H8.91675C7.98333 1.5 7.51662 1.5 7.1601 1.68166C6.84649 1.84144 6.59153 2.09641 6.43174 2.41002C6.25008 2.76654 6.25008 3.23325 6.25008 4.16667V16.5M18.3334 16.5H1.66675M9.16675 4.83333H10.8334M9.16675 8.16667H10.8334M9.16675 11.5H10.8334'
            stroke='currentColor'
            strokeWidth='1'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

const OrganizationIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={OrgSvg} {...props} />
);

export default OrganizationIcon;
