import { Control, Controller, FormProvider, useForm } from "react-hook-form";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import './CoreDatePicker.scss';
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

interface CoreDatePickerProps {
    control: Control<any>;
    name: string;
    placeholder?: string;
    required?: boolean;
    rangePicker?: boolean;
}

const CoreDatePicker = (props: CoreDatePickerProps) => {
    const {t} = useTranslation();
    const methods = useForm()
    const { control, name, placeholder, required, rangePicker } = props;
    return (
        <FormProvider {...methods}>
            <Controller
            control={control}
            name={name}
            rules={{
                required: required,
            }}
            render={({ field, fieldState }) => {
                return (
                    <>
                        {!rangePicker && (
                            <DatePicker
                                placeholder={placeholder}
                                className="general-datepicker"
                                status={fieldState.error ? "error" : undefined}
                                ref={field.ref}
                                name={field.name}
                                onBlur={field.onBlur}
                                value={field.value ? dayjs(field.value) : null}
                                onChange={(date) => {
                                    field.onChange(date ? date.toISOString() : null);
                                }}
                            />
                        )}
                        {rangePicker && (
                            <RangePicker
                                placeholder={[`${t('START_DATE')}`, `${t('END_DATE')}`]}
                                status={fieldState.error ? "error" : undefined}
                                className="general-datepicker"
                                ref={field.ref}
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={(date) => {
                                    field.onChange(
                                        date
                                            ? {
                                                startDate: date[0]?.toISOString(),
                                                endDate: date[1]?.toISOString(),
                                            }
                                            : null
                                    );
                                }}
                            />
                        )}

                        {fieldState.error ? (
                            <span style={{ color: "red" }}>{fieldState.error?.message}</span>
                        ) : null}
                    </>
                );
            }}
        />
        </FormProvider>
    );
};
export default CoreDatePicker;
