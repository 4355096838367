import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { RequestStatus } from '@models/async-status.enum';
import { ICustomer, ICustomerGetPayload } from '@models/customer.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CustomerAPI } from '@service/customer.service';
import { RootState } from '@store/store';
import { uniqBy } from 'lodash';

interface ICustomerState {
    isLoading: boolean;
    openFilter: boolean;
    customerFilters: ICustomerFilters;
    customers: {
        data: ICustomer[];
        status: RequestStatus;
        totalCount: number;
    };
    customer: ICustomer | null;
    customerSelect: {
        status: RequestStatus;
        data: ICustomer[];
        hasMore: boolean;
        PageLimit: number;
        PageNumber: number;
    };
}

interface ICustomerFilters {
    legalRegistrationName: string;
    vatRegistrationNumber: string;
    organizationLegacyId: string;
    organizationRegistrationScheme: string;
    activeStatus: string;
    dateRange: string;
}

const initialState: ICustomerState = {
    isLoading: false,
    openFilter: false,
    customerFilters: {
        legalRegistrationName: '',
        vatRegistrationNumber: '',
        organizationLegacyId: '',
        organizationRegistrationScheme: '',
        activeStatus: '',
        dateRange: '',
    },
    customers: {
        data: [],
        status: 'idle',
        totalCount: 0,
    },
    customer: null,
    customerSelect: {
        status: 'idle',
        data: [],
        hasMore: true,
        PageLimit: 10,
        PageNumber: 1,
    },
};

export const getCustomers = createAsyncThunk(
    'getCustomers',
    async (customer: ICustomerGetPayload) => {
        try {
            const response = await CustomerAPI.getCustomers(customer);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getCustomersForSelect = createAsyncThunk(
    'getCustomersSelect',
    async (payload: ICustomerGetPayload) => {
        try {
            const response = await CustomerAPI.getCustomers(payload);
            const customerList = (response.CustomerList || []).filter(
                (customer) => customer?.IsActive === true && customer?.IsArchived === false,
            );
            return (
                { ...response, CustomerList: customerList, isReset: !!payload?.isReset } || {
                    CustomerList: [],
                    TotalCount: 0,
                    isReset: !!payload?.isReset,
                }
            );
        } catch (error: any) {
            throw error as any;
        }
    },
);
export const getCustomer = createAsyncThunk('getCustomer', async (customerId: string) => {
    try {
        const response = await CustomerAPI.getCustomer(customerId);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        toggleFilter: (state) => {
            state.openFilter = !state.openFilter;
        },
        setFilters: (state: any, action) => {
            state.customerFilters = action.payload;
        },
        removeFilters: (state, action) => {
            const key: keyof ICustomerFilters = action.payload;
            state.customerFilters[key] = '';
        },
        clearAll: (state) => {
            state.customerFilters = initialState.customerFilters;
        },
        resetCustomerSelect: (state) => {
            state.customerSelect.status = 'idle';
            state.customerSelect.data = [];
            state.customerSelect.hasMore = true;
            state.customerSelect.PageNumber = 1;
        },
        resetSingleCustomer: (state) => {
            state.customer = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getCustomers.pending, (state) => {
                state.customers.status = 'loading';
            })
            .addCase(getCustomers.rejected, (state) => {
                state.customers.status = 'failed';
            })
            .addCase(getCustomers.fulfilled, (state, action) => {
                state.customers.data = action.payload?.CustomerList || [];
                state.customers.totalCount = action.payload?.TotalCount || 0;
                state.customers.status = 'idle';
            })
            .addCase(getCustomer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCustomer.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getCustomer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.customer = action.payload;
            })
            .addCase(getCustomersForSelect.pending, (state) => {
                state.customerSelect.status = 'loading';
            })
            .addCase(getCustomersForSelect.rejected, (state) => {
                state.customerSelect.status = 'failed';
            })
            .addCase(getCustomersForSelect.fulfilled, (state, action) => {
                state.customerSelect.status = 'idle';
                const customerList = action?.payload?.CustomerList || [];
                const totalCount = action?.payload?.TotalCount || 0;
                if (action.payload.isReset) {
                    state.customerSelect.data = customerList;
                    state.customerSelect.hasMore = customerList.length < totalCount;
                    state.customerSelect.PageNumber = 1;
                    return;
                }
                state.customerSelect.data = uniqBy(
                    state.customerSelect.data.concat(customerList),
                    'ItemId',
                );
                state.customerSelect.hasMore = state.customerSelect.data.length < totalCount;
                state.customerSelect.PageNumber = state.customerSelect.PageNumber + 1;
            });
    },
});

export const {
    toggleFilter,
    setFilters,
    removeFilters,
    clearAll,
    resetCustomerSelect,
    resetSingleCustomer,
} = customerSlice.actions;
export const selectCustomers = (state: RootState) => state.customers.customers;

export const selectCustomersOption = (state: RootState) => {
    const customerSelect = state.customers?.customerSelect;
    return {
        ...customerSelect,
        data: customerSelect?.data?.map(
            (data) =>
                ({
                    ...data,
                    value: data.ItemId,
                    label: data.LegalRegistrationName || DEFAULT_EMPTY_VALUE,
                    hoverName: data?.LegalRegistrationNameEn,
                } || []),
        ),
    };
};

export default customerSlice.reducer;
