export const payAsYouGoId = 'payasyougo';
export const payAsGoTiredData: any = {
    ItemId: payAsYouGoId,
    Name: 'Pay As You Go',
    NumberOfUsers: 'Unlimited',
    NumberOfInvoices: 'Unlimited',
    NumberOfEntities: 'Unlimited',
    NumberOfDevices: 'Unlimited',
    NumberOfOrganizations: 'Unlimited',
    MonthlyPrice: 'Unlimited',
    YearlyPrice: 'Unlimited',
    Currency: '',
    SDKValidation: true,
    IsPublic: true,
    IsActive: true,
    Description:
        'The Pay As You Go Tier is tailored for larger businesses in KSA that need comprehensive and highly customizable e-invoicing solutions.',
};
