import React from 'react';
import './CoreTitle.scss'

interface ICoreTitleProps {
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    text?: string;
    className?: string;
}

const CoreTitle = (props: ICoreTitleProps) => {
    const { tag = 'h1', text, className = "" } = props
    return (
        <>
            {tag === 'h1' && <h1 className={`core-title ${tag} ${className}`}>{text}</h1>}
            {tag === 'h2' && <h2 className={`core-title ${tag} ${className}`}>{text}</h2>}
            {tag === 'h3' && <h3 className={`core-title ${tag} ${className}`}>{text}</h3>}
            {tag === 'h4' && <h4 className={`core-title ${tag} ${className}`}>{text}</h4>}
            {tag === 'h5' && <h5 className={`core-title ${tag} ${className}`}>{text}</h5>}
            {tag === 'h6' && <h6 className={`core-title ${tag} ${className}`}>{text}</h6>}
        </>

    );
};

export default CoreTitle;
