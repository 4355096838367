import React from 'react';

const UserConsole = () => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 76 76'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='0.5' y='0.5' width='75' height='75' rx='37.5' fill='white' />
            <rect x='0.5' y='0.5' width='75' height='75' rx='37.5' stroke='#F5F5F5' />
            <path
                d='M47.9163 50.75L43.6663 46.5M43.6663 46.5L47.9163 42.25M43.6663 46.5H52.1663M42.958 25.6619C45.0347 26.5025 46.4997 28.5385 46.4997 30.9167C46.4997 33.2948 45.0347 35.3308 42.958 36.1714M37.9997 42.25H32.333C29.6927 42.25 28.3725 42.25 27.3311 42.6813C25.9426 43.2565 24.8395 44.3596 24.2644 45.7481C23.833 46.7895 23.833 48.1097 23.833 50.75M40.1247 30.9167C40.1247 34.0463 37.5876 36.5833 34.458 36.5833C31.3284 36.5833 28.7913 34.0463 28.7913 30.9167C28.7913 27.7871 31.3284 25.25 34.458 25.25C37.5876 25.25 40.1247 27.7871 40.1247 30.9167Z'
                stroke='#101828'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default UserConsole;
