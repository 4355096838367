import { CustomRules } from '@models/rules.model';
import { getErrorMessage, getRequiredErrorMessage } from '@utils/errorMessageOption';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IGenericErrorMessage {
    errors: any;
    dataKey: string;
    level: string;
    rules?: CustomRules;
    errorMessageSize?: ErrorMessageSize;
}

export type ErrorMessageSize = 'small' | 'default';

const GenericErrorMessage: FC<IGenericErrorMessage> = ({
    errors,
    dataKey,
    rules,
    level,
    errorMessageSize = 'default',
}) => {
    const modifyKeys = dataKey.split('.');
    const { t } = useTranslation();
    const requiredMessage = (rules as any)?.required?.required;
    const patternMessage = (rules as any)?.pattern?.message;
    const maxMessage = (rules as any)?.max?.message;
    const minMessage = (rules as any)?.min?.message;
    const minLengthMessage = (rules as any)?.minLength?.message;
    const maxLengthMessage = (rules as any)?.maxLength?.message;

    return (
        <div style={{ position: 'absolute' }}>
            {errors && modifyKeys && modifyKeys.length === 1 && (
                <div style={{ fontSize: errorMessageSize === 'small' ? 12 : '' }}>
                    {(errors as any)[modifyKeys[0]]?.type === 'required' && (
                        <span className='error-message'>
                            {getRequiredErrorMessage(level, t, requiredMessage)}
                        </span>
                    )}
                    {(errors as any)[modifyKeys[0]]?.type === 'pattern' && (
                        <span className='error-message'>
                            {getErrorMessage('pattern', t, patternMessage)}
                        </span>
                    )}
                    {(errors as any)[modifyKeys[0]]?.type === 'max' && (
                        <span className='error-message'>
                            {getErrorMessage('max', t, maxMessage)}
                        </span>
                    )}
                    {(errors as any)[modifyKeys[0]]?.type === 'min' && (
                        <span className='error-message'>
                            {getErrorMessage('min', t, minMessage)}
                        </span>
                    )}
                    {(errors as any)[modifyKeys[0]]?.type === 'minLength' && (
                        <span className='error-message'>
                            <span className='error-message'>
                                {getErrorMessage('minLength', t, minLengthMessage)}
                            </span>
                        </span>
                    )}
                    {(errors as any)?.[modifyKeys[0]]?.type === 'maxLength' && (
                        <span className='error-message'>
                            <span className='error-message'>
                                {getErrorMessage('maxLength', t, maxLengthMessage)}
                            </span>
                        </span>
                    )}
                </div>
            )}
            {errors && modifyKeys && modifyKeys.length === 2 && (
                <div style={{ fontSize: errorMessageSize === 'small' ? 12 : '' }}>
                    {(errors as any)?.[modifyKeys[0]]?.[modifyKeys[1]]?.type === 'required' && (
                        <span className='error-message'>
                            {getRequiredErrorMessage(level, t, requiredMessage)}
                        </span>
                    )}
                    {(errors as any)?.[modifyKeys[0]]?.[modifyKeys[1]]?.type === 'pattern' && (
                        <span className='error-message'>
                            {getErrorMessage('pattern', t, patternMessage)}
                        </span>
                    )}
                    {(errors as any)?.[modifyKeys[0]]?.[modifyKeys[1]]?.type === 'max' && (
                        <span className='error-message'>
                            {getErrorMessage('max', t, maxMessage)}
                        </span>
                    )}
                    {(errors as any)?.[modifyKeys[0]]?.[modifyKeys[1]]?.type === 'min' && (
                        <span className='error-message'>
                            {getErrorMessage('min', t, minMessage)}
                        </span>
                    )}
                    {(errors as any)?.[modifyKeys[0]]?.[modifyKeys[1]]?.type === 'minLength' && (
                        <span className='error-message'>
                            <span className='error-message'>
                                {getErrorMessage('minLength', t, minLengthMessage)}
                            </span>
                        </span>
                    )}
                    {(errors as any)?.[modifyKeys[0]]?.[modifyKeys[1]]?.type === 'maxLength' && (
                        <span className='error-message'>
                            <span className='error-message'>
                                {getErrorMessage('maxLength', t, maxLengthMessage)}
                            </span>
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default GenericErrorMessage;
