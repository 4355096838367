import InlineGenericInput from '@components/common/InlineGenericInput';
import { IApiService, ICreateAPiService } from '@models/api-services.model';
import { Col, Divider, Typography, Form, message, Row, Spin, Radio, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import './create-service.scss';
import { CoreButton, CoreTitle, CoreList } from '@packages';
import { ApiServicesAPI } from '@service/api-services.service';
import InlineGenericSelect, { ISelectOption } from '@components/common/InlineGenericSelect';
import InputKey from '../../components/InputKey';
import InlineGenericRadio from '@components/common/InlineGenericRadio';
import { UTCToShortDate } from '@utils/date';
import InlineGenericMultiInput from '@components/common/InlineGenericMultiInput';
import { RegexValidators } from '@config/regex-validator-constant';

export default function CreateService() {
    const [isPageLoading, setPageLoading] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false);
    const [apiService, setApiService] = useState<IApiService>();
    const { id: serviceId } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        clearErrors,
        setValue: setFormValue,
        formState: { errors },
        reset,
        watch,
    } = useForm<ICreateAPiService>({
        mode: 'onChange',
        defaultValues: {
            RestrictionItems: [],
            RestrictionType: 'none',
            IsRestricted: 'false' as any,
        },
    });

    const onCustomerCreate = async (data: ICreateAPiService) => {
        try {
            setButtonLoading(true);
            const response = await ApiServicesAPI.createApiService({
                ...data,
                ExpiresInMonths: Number(data.ExpiresInMonths),
                IsRestricted: Boolean(data.IsRestricted),
            });
            setButtonLoading(false);
            message.success(t('Api Service Create Successfully'));
            goBack();
        } catch (error: any) {
            setButtonLoading(false);
            message.error(t(error?.response?.data?.Message || 'Api Service Create Failed'));
        }
    };
    const onCustomerUpdate = async (data: ICreateAPiService) => {
        try {
            setButtonLoading(true);
            const response = await ApiServicesAPI.updateApiService(serviceId ?? '', {
                ...data,
                ExpiresInMonths: Number(data.ExpiresInMonths),
                IsRestricted: Boolean(data.IsRestricted),
            });
            setButtonLoading(false);
            message.success(t('Api Service update Successfully'));
            goBack();
        } catch (error: any) {
            console.log(error);
            setButtonLoading(false);
            message.error(t(error?.response?.data?.Message || 'Api Service Update Failed'));
        }
    };
    const getServiceInfo = async () => {
        if (!serviceId) {
            return;
        }
        try {
            setPageLoading(true);
            const response = await ApiServicesAPI.getApiServiceById(serviceId);
            setApiService(response);
            setPageLoading(false);
            reset({ ...response, IsRestricted: response?.IsRestricted?.toString() as any });
        } catch {
            setPageLoading(false);
            message.error(t('CUSTOMER_DATA_LOAD_FAILED'));
        }
    };
    const goBack = () => {
        navigate('/api-services', {});
    };
    useEffect(() => {
        getServiceInfo();
    }, [serviceId]);

    return (
        <div>
            <Spin spinning={isPageLoading}>
                <div className='service-create-wrapper'>
                    <form onSubmit={handleSubmit(serviceId ? onCustomerUpdate : onCustomerCreate)}>
                        <div className='service-create-inner-wrapper'>
                            <div className='form-wrapper'>
                                <Row align='top' gutter={80}>
                                    <Col span={14}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            inputCol={24}
                                            levelCol={24}
                                            dataKey='Description'
                                            level={t('Description')}
                                            rules={{
                                                required: true,
                                            }}
                                            marginBottom={20}
                                            errorMessageSize='small'
                                        />
                                        {!serviceId && (
                                            <InlineGenericSelect
                                                setFormValue={setFormValue}
                                                options={selectOption}
                                                control={control}
                                                sorting={false}
                                                errors={errors}
                                                inputCol={24}
                                                levelCol={24}
                                                dataKey='ExpiresInMonths'
                                                level={t('Expires In Months')}
                                                rules={{
                                                    required: true,
                                                }}
                                                marginBottom={20}
                                                errorMessageSize='small'
                                            />
                                        )}
                                        <CoreTitle tag='h4' text={'Key Restrictions'} />
                                        <p style={{ marginBottom: 20 }}>
                                            Restricting where and for which APIs this key can be
                                            used helps prevent unauthorized use.
                                        </p>

                                        <CoreTitle
                                            tag='h4'
                                            text={'Set an Application Restriction'}
                                        />
                                        <p>
                                            Application restrictions limit an API key's usage to
                                            specific websites, IP addresses, Android applications,
                                            or iOS applications. You can set one application
                                            restriction per key.
                                        </p>

                                        <InlineGenericRadio
                                            levelCol={24}
                                            inputCol={24}
                                            setFormValue={setFormValue}
                                            control={control}
                                            errors={errors}
                                            clearErrors={clearErrors}
                                            options={[
                                                {
                                                    label: `None`,
                                                    value: 'none',
                                                },
                                                {
                                                    label: `Websites`,
                                                    value: 'website',
                                                },
                                                {
                                                    label: `IP addresses`,
                                                    value: 'ip',
                                                },
                                            ]}
                                            direction='vertical'
                                            dataKey='RestrictionType'
                                            level={'Key Restriction'}
                                            showLevel={false}
                                            marginBottom={20}
                                            errorMessageSize='small'
                                        />

                                        {['ip', 'website'].includes(watch('RestrictionType')) && (
                                            <InlineGenericMultiInput
                                                levelCol={24}
                                                inputCol={24}
                                                control={control}
                                                errors={errors}
                                                dataKey='RestrictionItems'
                                                level={
                                                    watch('RestrictionType') === 'ip'
                                                        ? 'Ip Address'
                                                        : 'Website URL'
                                                }
                                                showLevel={false}
                                                marginBottom={20}
                                                errorMessageSize='small'
                                                rules={
                                                    watch('RestrictionType') === 'ip'
                                                        ? ErrorPattern.ip
                                                        : ErrorPattern.website
                                                }
                                            />
                                        )}

                                        <CoreTitle tag='h4' text={'API restrictions'} />
                                        <p>
                                            API restrictions specify the enabled APIs that this key
                                            can call.
                                        </p>

                                        <InlineGenericRadio
                                            levelCol={24}
                                            inputCol={24}
                                            setFormValue={setFormValue}
                                            control={control}
                                            errors={errors}
                                            direction='vertical'
                                            clearErrors={clearErrors}
                                            options={[
                                                {
                                                    label: 'Don’t restrict key',
                                                    value: 'false',
                                                },
                                                {
                                                    label: `Restrict key`,
                                                    value: 'true',
                                                },
                                            ]}
                                            dataKey='IsRestricted'
                                            level={'APiRestriction'}
                                            showLevel={false}
                                            marginBottom={20}
                                            errorMessageSize='small'
                                        />
                                        <p>
                                            Note: It may take up to 5 minutes for settings to take
                                            effect.
                                        </p>
                                    </Col>

                                    {apiService && (
                                        <Col span={10}>
                                            <div>
                                                <CoreTitle
                                                    tag='h4'
                                                    text={'Additional Information'}
                                                />
                                                <label
                                                    style={{ marginTop: 10 }}
                                                    className='general-label'
                                                >
                                                    API Key
                                                </label>
                                                <InputKey apiKey={apiService?.APIKey} />
                                                <p>
                                                    Use this key in your application by passing it
                                                    with <code>key=API_KEY</code> parameter.
                                                </p>

                                                <div className='time-display'>
                                                    <CoreList
                                                        listData={[
                                                            {
                                                                key: 'Creation Date',
                                                                value: UTCToShortDate(
                                                                    apiService?.CreatedAt,
                                                                ),
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                        <div className='submit-action-buttons'>
                            <CoreButton
                                text={t('CANCEL')}
                                type='basic'
                                htmlType='button'
                                onClick={goBack}
                            />
                            <CoreButton
                                loading={isButtonLoading}
                                text={t('SAVE')}
                                type='primary'
                                htmlType='submit'
                            />
                        </div>
                    </form>
                </div>
            </Spin>
        </div>
    );
}

export const selectOption: ISelectOption[] = [
    {
        value: 1,
        label: '1',
    },

    {
        value: 3,
        label: '3',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 12,
        label: '12',
    },
    {
        value: 24,
        label: '24',
    },
];

export const ErrorPattern = {
    ip: {
        required: {
            value: true,
            message: 'Ip number is required',
        },
        pattern: {
            value: RegexValidators.IP_ADDRESS,
            message: 'Ip number pattern not matching',
        },
    },
    website: {
        required: {
            value: true,
            message: 'Website is required',
        },
        pattern: {
            value: RegexValidators.WEBSITE,
            message: 'Enter valid website address',
        },
    },
};
