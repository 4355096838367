import { CoreList, CoreTitle } from '@packages';
import { RootState } from '@store/store';
import { getAddressInformation, getCustomerInformation } from '@utils/helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './CustomerInformation.scss';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';

type CoreList = { key: string; value: string }[];

function CustomerInformation() {
    const { customer } = useSelector((state: RootState) => state.customers);
    const { invoice } = useSelector((state: RootState) => state.einvoice);

    const { t } = useTranslation();
    const [customerInfo, setCustomerInfo] = useState<CoreList>([]);
    const [customerAddress, setCustomerAddress] = useState<CoreList>([]);

    useEffect(() => {
        if (customer) {
            const customerInfo = getCustomerInformation(customer, t);
            const addressInfo = getAddressInformation(customer?.Address, t);
            setCustomerInfo(customerInfo);
            setCustomerAddress(addressInfo);
        }
    }, [customer,t]);
    return (
        <>
            {(customer && (
                <div className='invoice-customer-information'>
                    <CoreList
                        listData={[
                            {
                                key: t('CUSTOMER_NOTES'),
                                value: invoice?.CustomerNote || DEFAULT_EMPTY_VALUE,
                            },
                            ...customerInfo,
                        ]}
                    />
                    <CoreTitle tag='h5' text={t('ADDRESS_INFORMATION')} />
                    <CoreList listData={[...customerAddress]} />
                </div>
            )) ||
                'No Customer Found'}
        </>
    );
}

export default CustomerInformation;
