import React, { MouseEventHandler, useState } from "react";
import { useForm } from "react-hook-form";
import { Input, message } from "antd";
import { CoreButton, CoreTitle, CoreLoginWrapper } from "../../packages";
import { BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
import "./CoreSetPasswordPage.scss";
import { authAPI } from "@service/auth.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IResetPassword } from "@models/auth.model";
import { RegexValidators } from "@config/regex-validator-constant";

interface CoreSetPasswordPageProps {
    title: string;
    subTitle: string;
    onClick?:
        | (MouseEventHandler<HTMLAnchorElement> &
        MouseEventHandler<HTMLButtonElement>)
        | undefined;
}

interface ISetPassword {
    password: string;
    ConfirmPassword: string;
}

const CoreSetPasswordPage = (props: CoreSetPasswordPageProps) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { title = "", subTitle = "" } = props;
    const [isConfirmPasswordTouch, setConfirmPasswordTouch] = useState<boolean>(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [matchPassword, setMatchPassword] = useState(false);
    const [isTenChar, setIsTenChar] = useState(false);
    const [containsUpperAndLower, setContainsUpperAndLower] = useState(false);
    const [containsNumber, setContainsNumber] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setLoading] = useState(false);
    const [containsSpecial, setContainsSpecial] = useState(false);

    const {
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<ISetPassword>({ mode: "onChange" });


    const onSubmit = async () => {

        let payload: IResetPassword = {
            newPassword: "",
            verificationCode: searchParams.get('token') || '',
        }

        if (matchPassword) {
            payload.newPassword = password
        }

        if (isValid && matchPassword) {
            setLoading(true)
            const response = await authAPI.resetPassword(payload);
            setLoading(false)
            if (response.Success) {
                navigate('/reset-password-confirmation');

            } else {
                messageApi.open({
                    type: 'error',
                    content: "Error",
                });
            }

        }
    };

    const onChangePassword = (e: any) => {
        const pass = e.target.value;
        pass.length >= 10 ? setIsTenChar(true) : setIsTenChar(false);
        pass.match(RegexValidators.UPPERCASE_LOWERCASE)
            ? setContainsUpperAndLower(true)
            : setContainsUpperAndLower(false);
        pass.match(RegexValidators.ONLY_ONE_NUMBER)
            ? setContainsNumber(true)
            : setContainsNumber(false);
        pass.match(RegexValidators.SPECIAL_CHARACTER)
            ? setContainsSpecial(true)
            : setContainsSpecial(false);
        setPassword(pass);
        pass === confirmPassword ? setMatchPassword(true) : setMatchPassword(false)
    };

    const onChangeConfirmPassword = (e: any) => {
        const confirmPassword = e.target.value
        password === confirmPassword ? setMatchPassword(true) : setMatchPassword(false)
        setConfirmPassword(confirmPassword)
        setConfirmPasswordTouch(true)
    }

    const isValidationMatch =  matchPassword && isTenChar && containsUpperAndLower && containsNumber && containsSpecial ? true : false;

    const handleKeyPress = (event: any) => {
        if (event.key === ' ') {
            event.preventDefault();
        }
    };



    return (
        <CoreLoginWrapper>
            <div className="core-set-password-wrapper">
                <div className="core-set-password-wrapper-content">
                    <div>
                        <CoreTitle tag="h1" text="Welcome to" />
                        <CoreTitle tag="h1" text={title} />
                        <p className="core-set-password-sub-title">{subTitle}</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="general-input-wrapper">
                            <label className="general-label">New password</label>
                            <Input.Password className="general-input" onChange={onChangePassword}  onKeyPress={handleKeyPress}/>
                            {errors?.password?.type === "required" && (
                                <span className="error-message">Password field is required</span>
                            )}

                            <div className="mb-10">
                                <p className="validation-header">
                                    Your password needs to be at least
                                </p>
                                <div className="error-validation">
                                    {!isTenChar ? (
                                        <BorderOutlined className="success-box" />
                                    ) : (
                                        <CheckSquareOutlined className="success-checkbox" />
                                    )}
                                    <p className="validation-message">10 Characters long</p>
                                </div>
                                <div className="error-validation">
                                    {!containsUpperAndLower ? (
                                        <BorderOutlined className="success-box" />
                                    ) : (
                                        <CheckSquareOutlined className="success-checkbox" />
                                    )}
                                    <p className="validation-message">Including 1 uppercase and 1 lowercase</p>
                                </div>
                               
                                <div className="error-validation">
                                    {!containsSpecial ? (
                                        <BorderOutlined className="success-box" />
                                    ) : (
                                        <CheckSquareOutlined className="success-checkbox" />
                                    )}
                                    <p className="validation-message">Including 1 special character #?!@$%^&-</p>
                                </div>

                                <div className="error-validation">
                                    {!containsNumber ? (
                                        <BorderOutlined className="success-box" />
                                    ) : (
                                        <CheckSquareOutlined className="success-checkbox" />
                                    )}
                                    <p className="validation-message">And 1 number</p>
                                </div>
                               
                            </div>
                            <div className="general-input-wrapper mb-30">
                                <label className="general-label">Confirm password</label>
                                <Input.Password className="general-input"
                                                onClick={() => {
                                                    setConfirmPasswordTouch(true)}
                                                }
                                                onChange={onChangeConfirmPassword} onKeyPress={handleKeyPress} />
                                {!matchPassword && isConfirmPasswordTouch && (
                                    <span className="error-message">Password doesn't match</span>
                                )}
                            </div>
                        </div>
                        <CoreButton
                            type="primary"
                            htmlType="submit"
                            isFullWidth={true}
                            text="Save Password"
                            disabled={!isValidationMatch}
                            loading={isLoading}
                        />
                    </form>
                </div>
            </div>
        </CoreLoginWrapper>
    );
};

export default CoreSetPasswordPage;
