import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const DeactivateSvg = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5652_4678)'>
            <path
                d='M12.5 7.50008L7.49996 12.5001M7.49996 7.50008L12.5 12.5001M18.3333 10.0001C18.3333 14.6025 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6025 1.66663 10.0001C1.66663 5.39771 5.39759 1.66675 9.99996 1.66675C14.6023 1.66675 18.3333 5.39771 18.3333 10.0001Z'
                stroke='#667085'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_5652_4678'>
                <rect width='20' height='20' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
const DeactivateIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={DeactivateSvg} {...props} />
);

export default DeactivateIcon;
