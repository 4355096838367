import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    ICustomer,
    ICustomerCreate,
    ICustomerGetPayload,
    ICustomerResult,
} from '@models/customer.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';

export const CustomerAPI = {
    getCustomers: async ({
        PageNumber,
        PageLimit,
        ...others
    }: ICustomerGetPayload): Promise<ICustomerResult> => {
        const url = APP_CONFIG.eisUrl + `CustomerCommand/GetCustomers`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                PageLimit,
                PageNumber,
                ...others,
            });
            return response?.data?.Result as ICustomerResult;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getCustomer: async (customerId: string): Promise<ICustomer> => {
        const url = APP_CONFIG.eisUrl + `CustomerQuery/GetDetails${processQuery({ customerId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result as ICustomer;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    createCustomer: async (payload: ICustomerCreate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CustomerCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    updateCustomer: async (customerId: string, payload: ICustomerCreate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `CustomerCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { ...payload, customerId });
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    activeCustomer: async (customerId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `CustomerCommand/Activate`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { customerId });
            return Promise.resolve(true);
        } catch (error) {
            const err = error as AxiosError;
            console.log(err);
            return Promise.resolve(false);
        }
    },
    deActiveCustomer: async (customerId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `CustomerCommand/Deactivate`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { customerId });
            return Promise.resolve(true);
        } catch (error) {
            const err = error as AxiosError;
            console.log(err);
            return Promise.resolve(false);
        }
    },
    archiveCustomer: async (customerId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `CustomerCommand/Archive`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { customerId });
            return Promise.resolve(true);
        } catch (error) {
            const err = error as AxiosError;
            console.log(err);
            return Promise.resolve(false);
        }
    },
    unarchiveCustomer: async (customerId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `CustomerCommand/Unarchive`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { customerId });
            return Promise.resolve(true);
        } catch (error) {
            const err = error as AxiosError;
            console.log(err);
            return Promise.resolve(false);
        }
    },
};
