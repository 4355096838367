/* eslint-disable jsx-a11y/anchor-is-valid */
import { IInvoiceLinesItem } from '@models/invoice.model';
import { Button, Dropdown, MenuProps } from 'antd';
import { useState } from 'react';
import DiscountAmountLine, { DiscountType } from './ItemDiscount/DiscountAmountLine';
import { useTranslation } from 'react-i18next';

interface IMoreOverLineProps {
    data: IInvoiceLinesItem;
    updateInvoiceLine: (itemId: string) => (object: Partial<IInvoiceLinesItem>) => void;
    documentTaxCurrencyCode?: string;
}

function MoreOverLine({ data, updateInvoiceLine, documentTaxCurrencyCode }: IMoreOverLineProps) {
    const { t } = useTranslation();
    const [isShowDiscountModal, setDiscountShowModal] = useState<boolean>(false);
    const [discountType, setDiscountType] = useState<DiscountType>('charge');

    const items: MenuProps['items'] = [
        {
            key: `discount`,
            label: (
                <a
                    onClick={() => {
                        setDiscountType('discount');
                        setDiscountShowModal(true);
                    }}
                    aria-hidden='true'
                >
                    {t('ADD_DISCOUNT')}
                </a>
            ),
        },
        {
            key: `charge`,
            label: (
                <a
                    onClick={() => {
                        setDiscountType('charge');
                        setDiscountShowModal(true);
                    }}
                    aria-hidden='true'
                >
                    {t('ADD_CHARGE')}
                </a>
            ),
        },
    ];
    return (
        <div style={{ cursor: 'pointer', marginLeft: 10 }}>
            <Dropdown menu={{ items }} trigger={['click']}>
                <Button size='small' type='primary'>
                    {t('ADD')}
                </Button>
            </Dropdown>
            <DiscountAmountLine
                data={data}
                discountType={discountType}
                updateInvoiceLine={updateInvoiceLine}
                isOpenModal={isShowDiscountModal}
                setHideModal={() => setDiscountShowModal(false)}
                documentTaxCurrencyCode={documentTaxCurrencyCode}
            />
        </div>
    );
}

export default MoreOverLine;
