import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    IOrganizationDeviceOTP,
    IOrganizationInitiate,
    IOrganizationPhasePayload,
    IOrganizationUpdatePayload,
    IOrganization,
    IPostOTPPayload,
} from '@models/organization.model';
import { IGenericResponse } from '@models/generic.model';
import { processQuery } from '@utils/processQuery';

export const OrganizationAPI = {
    getOrganizations: async (): Promise<IGenericResponse<IOrganization[]>> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/Organizations`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {});
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getAvailableOrganizations: async (): Promise<IOrganization[]> => {
        const url = APP_CONFIG.eisUrl + `OrganizationQuery/CompleteOrganizations`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result || [];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getOrganization: async (organizationId: string): Promise<IGenericResponse<IOrganization>> => {
        const url =
            APP_CONFIG.eisUrl + `OrganizationQuery/Organization${processQuery({ organizationId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    postOrganizationWorkflow: async (
        organizationId: string,
    ): Promise<IGenericResponse<IOrganization>> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/Workflow`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                OrganizationId: organizationId,
            });
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    postOrganizationInitiate: async (payload: IOrganizationInitiate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/Initiate`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    updateOrganization: async (payload: IOrganizationUpdatePayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/UpdateOrganization`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    postOTP: async (payload: IPostOTPPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/OTP`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    postOrganizationPhase: async (
        payload: IOrganizationPhasePayload,
    ): Promise<IGenericResponse<null>> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/Phase`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data as IGenericResponse<null>;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    postOrganizationOTP: async (payload: IOrganizationDeviceOTP): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/OTP`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
