import React from 'react';
import { CoreLoginWrapper } from '@packages';
import CoreTwoStepVerificationPage from '../../../packages/CoreTwoStepVerificationPage/CoreTwoStepVerificationPage';

const TwoStepVerification = () => {
    return (
        <CoreLoginWrapper>
            <CoreTwoStepVerificationPage
                title='2-Step Verification'
                subTitle="This extra step shows it's really you trying to sign in"
            />
        </CoreLoginWrapper>
    );
};

export default TwoStepVerification;
