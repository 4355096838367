import './PricingPlans.scss';
import { CoreTitle } from '@packages';
import { Collapse, CollapseProps, Tabs } from 'antd';
import PriceCard from '../../components/price-card/PriceCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SubscribeLayout from '@components/main-layout/header-layout/HeaderLayout';
import { BalanceIcon } from '@assets/svg';

const tabItems = (t: any) => [
    {
        label: 'Monthly Billing',
        key: '1',
        children: <PriceCard type='month' />,
    },
    {
        label: (
            <div>
                Annual <span className='percent-off-text'>(Save 20%)</span>
            </div>
        ),
        key: '2',
        children: <PriceCard type='year' />,
    },
];

const CollapseContent = [
    {
        key: '1',
        label: 'Is there a free trial available?',
        content: `You can use ClickMagick free for up to 14 Days, with full access to everything offered on the plan you sign up for. If you don't cancel within 14 Days, we'll charge you for the plan you selected. You can upgrade, downgrade, or cancel at any time with just a few clicks.`,
    },
    {
        key: '2',
        label: 'Why do you need my credit card for the free trial?',
        content:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    {
        key: '3',
        label: 'How do I cancel if I&apos;m not super impressed',
        content:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
];

const PricingPlans = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const CollapseItems: CollapseProps['items'] = CollapseContent.map((item) => ({
        key: item.key,
        label: item.label,
        children: <p>{item.content}</p>,
    }));

    return (
        <SubscribeLayout>
            <div className='pricing-plans-wrapper'>
                <div className='pricing-plans-column'>
                    <div className='column-one'>
                        <div className='collapse-section'>
                            <p className='welcome-message'>Welcome to e-invocie solutions</p>
                            <CoreTitle tag='h1' text='Select your Plan' />
                            <div className='collapse-wrapper'>
                                <Collapse
                                    items={CollapseItems}
                                    defaultActiveKey={['1']}
                                    bordered={false}
                                    expandIcon={({ isActive }) =>
                                        isActive ? (
                                            <MinusCircleOutlined
                                                style={{ fontSize: '18px', color: '#A4A7AE' }}
                                            />
                                        ) : (
                                            <PlusCircleOutlined
                                                style={{ fontSize: '18px', color: '#A4A7AE' }}
                                            />
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='column-two'>
                        <div className='pricing-plans-card-section'>
                            <div className='pricing-plans-card-wrapper'>
                                <div className='pricing-plans-inner-wrapper'>
                                    <Tabs items={tabItems(t)} centered={true} />
                                </div>
                                <div
                                    className='comparison-action'
                                    onClick={() => {
                                        navigate('/plan-comparison');
                                    }}
                                >
                                    <p>Show full comparison</p>
                                    <BalanceIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SubscribeLayout>
    );
};

export default PricingPlans;
