import React from 'react';
import { CoreStaticPage } from '../../packages';
import under_maintenance from '../../assets/images/under_maintenance.png';

const Maintenance = () => {
    return (
        <CoreStaticPage
            title='Under maintenance'
            subTitle="Sorry, the page you are looking for doesn't exist or has been moved. Try searching our site:"
            imageSrc={under_maintenance}
            imageWidth='391.56'
            imageHeight='241.86'
        />
    );
};

export default Maintenance;
