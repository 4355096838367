import React, { FC, useState } from 'react';
import './DownloadRecoveryCodes.scss';
import { CoreButton, CoreTitle } from '@packages';
import { Col, Row } from 'antd';
import { InfoCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { selectInitialSetup2FA } from '@store/slices/setup-2FASlice';
import { useAppSelector } from '@store/hooks';
import { useTranslation } from 'react-i18next';
interface DownloadRecoveryCodesProps {
    next(): void;
}
const DownloadRecoveryCodes: FC<DownloadRecoveryCodesProps> = ({ next }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isDownloadCode, setDownloadCode] = useState(false);
    const { data } = useAppSelector(selectInitialSetup2FA);

    const downloadCodeTextFile = () => {
        const element = document.createElement('a');
        let txt = '';
        data &&
            data.FormattedRecoveryCodes.forEach((item) => {
                txt = txt + item + '\n\n';
            });
        const file = new Blob([txt], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'hash-recover-keys.txt';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setDownloadCode(true);
    };

    return (
        <>
            <div className='download-recovery-codes-wrapper'>
                <CoreTitle className='title-color' tag='h5' text={t('DOWLOAD_YOUR_RECOVERY_CODES')} />
                <div className='tow-fa-sub-title'>
                    {t('YOU_CAN_USE_RECOVERY_CODES_AS_A_SECOND_FACTOR_TO_AUTHENTICATION_IN_CASE_YOU_LOSE_ACCESS_TO_YOUR_DEVICE')}
                </div>
                <div className='two-factor-info-container'>
                    <div className='info-box'>
                        <div>
                            <InfoCircleOutlined className='info-icons' />
                        </div>
                        <div>
                            <p>{t('KEEP_YOUR_RECOVERY_CODES_IN_A_SAFE_SPOT')}</p>
                        </div>
                    </div>
                    <div className='margin-left-42'>
                        {t('IF_YOU_LOSE_YOUR_DEVICE_AND_CANNOT_FIND_YOUR_RECOVERY_CODES')}
                        <br /> {t('WILL_LOSE_ACCESS_TO_YOUR_ACCOUNT')}
                    </div>
                </div>
                <div className='two-factor-pin'>
                    <Row>
                        <Col>
                            <ul className='list'>
                                {data?.FormattedRecoveryCodes.slice(0, 5).map((code) => (
                                    <li key={code}>{code}</li>
                                ))}
                            </ul>
                        </Col>
                        <Col>
                            <ul className='list'>
                                {data?.FormattedRecoveryCodes.slice(5, 10).map((code) => (
                                    <li key={code}>{code}</li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div className='download-button'>
                    <CoreButton
                        type='primary'
                        htmlType='submit'
                        isFullWidth={false}
                        icon={<VerticalAlignBottomOutlined />}
                        text={t('DOWNLOAD')}
                        onClick={() => downloadCodeTextFile()}
                    />
                </div>
            </div>
            <div className='two-factor-auth-step-button-group'>
                <CoreButton onClick={() => navigate('/setting')} text={t('CANCEL')} />
                <CoreButton
                    onClick={() => next()}
                    text={t('I_HAVE_SAVED_MY_RECOVERY_CODES')}
                    type='primary'
                    disabled={!isDownloadCode}
                />{' '}
            </div>
        </>
    );
};

export default DownloadRecoveryCodes;
