import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import InlineGenericTextArea from '@components/common/InlineGenericTextArea';
import { IAddBillingAddress } from '@models/billing.model';
import { ICountry } from '@models/generic.model';
import { CoreButton, CoreTitle } from '@packages';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';

interface IBillingFormModalProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const BillingFormModal = ({ isVisible, onClose, onConfirm }: IBillingFormModalProps) => {
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<IAddBillingAddress>({ mode: 'onTouched' });

    useEffect(() => {
        updateForm();
    }, []);

    const updateForm = () => {};
    const onSubmit = (data: any) => {
        console.log(data, 'form data');
        onConfirm();
    };

    return (
        <Modal
            className='billing-form-modal-wrapper'
            visible={isVisible}
            onCancel={onClose}
            closable={true}
            footer={[]}
        >
            <div className='billing-from-wrapper'>
                <CoreTitle text='Add New Address' tag='h4' />
                <form onSubmit={handleSubmit(onSubmit)} className='margin-top-16'>
                    <InlineGenericInput
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='AddressTitle'
                        level='Address Title'
                        errorMessageSize='small'
                        marginBottom={10}
                        rules={{
                            required: true,
                        }}
                    />

                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <InlineGenericInput
                                inputCol={24}
                                levelCol={24}
                                control={control}
                                errors={errors}
                                dataKey='Name'
                                level='Name'
                                errorMessageSize='small'
                                marginBottom={10}
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                inputCol={24}
                                levelCol={24}
                                control={control}
                                errors={errors}
                                dataKey='Surname'
                                level='Surname'
                                errorMessageSize='small'
                                marginBottom={10}
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>

                    <InlineGenericInput
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='PhoneNumber'
                        level='Phone Number'
                        type='phoneNumber'
                        hideError={true}
                        marginBottom={10}
                        errorMessageSize='small'
                        rules={{
                            required: true,
                        }}
                    >
                        <div style={{ position: 'absolute', fontSize: 12 }}>
                            {errors.PhoneNumber?.type === 'required' && (
                                <span className='error-message'>
                                    {t('PHONE_NUMBER_IS_REQUIRED')}
                                </span>
                            )}
                            {errors.PhoneNumber?.type === 'pattern' && (
                                <span className='error-message'>{t('PHONE_NUMBER_INVALID')}</span>
                            )}
                        </div>
                    </InlineGenericInput>

                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <InlineGenericSelect
                                inputCol={24}
                                levelCol={24}
                                control={control}
                                errors={errors}
                                dataKey='Country'
                                level='Country'
                                placeHolder='Select Country'
                                errorMessageSize='small'
                                marginBottom={0}
                                setFormValue={setFormValue}
                                options={countryOptions}
                                allowClear
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericSelect
                                inputCol={24}
                                levelCol={24}
                                control={control}
                                errors={errors}
                                dataKey='City'
                                level='City'
                                placeHolder='Select City'
                                errorMessageSize='small'
                                marginBottom={0}
                                setFormValue={setFormValue}
                                options={countryOptions}
                                allowClear
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>

                    <InlineGenericTextArea
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='Address'
                        level='Address'
                        marginBottom={30}
                        minRows={2}
                        rules={{
                            required: true,
                        }}
                    />

                    <CoreButton
                        type='primary'
                        htmlType='submit'
                        isFullWidth={true}
                        text='Add Address'
                    />
                </form>
            </div>
        </Modal>
    );
};

export default BillingFormModal;
