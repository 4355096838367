import { I2FAPayload, IAdditionalTokenResponse } from '@models/auth.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authAPI } from '@service/auth.service';
import { localStoreAPI } from '@service/local-storage.service';
import { RootState } from '@store/store';

interface IUserState {
    isLogin: boolean;
    isLoading: boolean;
    anonymousToken: string;
    accessToken: string;
    refreshTokenId: string;
    isNeed2FA: boolean;
    method: string;
    token: string;
}

const initialState: IUserState = {
    isLogin: !!localStoreAPI.getAccessToken(),
    isLoading: false,
    anonymousToken: '',
    accessToken: '',
    refreshTokenId: '',
    isNeed2FA: false,
    method: '',
    token: '',
};

export const postLogin = createAsyncThunk(
    'auth/login',
    async (payload: { email: string; password: string }) => {
        try {
            const response = await authAPI.login(payload);
            return response;
        } catch (error: any) {
            console.log(error);
            throw error?.response?.data?.Message || error?.message;
        }
    },
);

export const post2FALogin = createAsyncThunk('auth/2FAlogin', async (payload: I2FAPayload) => {
    try {
        const response = await authAPI.login2FA(payload);
        return response;
    } catch (error: any) {
        console.log(error);
        throw error?.response?.data?.Message || error?.message;
    }
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logOutUser: (state) => {
            localStoreAPI.clearLocalStorage();
            state.isLogin = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(postLogin.pending, (state) => {
            state.isLoading = true;
            state.isLogin = false;
        });
        builder.addCase(postLogin.fulfilled, (state, action) => {
            state.isLoading = false;
            const payload = action.payload;
            if (Object.prototype.hasOwnProperty.call(payload, 'additionalSteps')) {
                state.token = (payload as IAdditionalTokenResponse).token;
                state.isNeed2FA = (payload as IAdditionalTokenResponse).additionalSteps;
                state.method = (payload as IAdditionalTokenResponse).method;
                state.isLogin = false;
            } else {
                state.token = (payload as IAdditionalTokenResponse).token;
                state.isNeed2FA = true;
                state.method = (payload as IAdditionalTokenResponse).method;
                state.isLogin = true;
            }
        });
        builder.addCase(postLogin.rejected, (state, action) => {
            state.isLoading = false;
            state.isLogin = false;
        });

        builder.addCase(post2FALogin.pending, (state) => {
            state.isLoading = true;
            state.isLogin = false;
        });
        builder.addCase(post2FALogin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isLogin = true;
            state.accessToken = action.payload.token;
            state.refreshTokenId = action.payload.refreshTokenId;
        });
        builder.addCase(post2FALogin.rejected, (state, action) => {
            state.isLoading = false;
            state.isLogin = false;
        });
    },
});

export const { logOutUser } = authSlice.actions;
export const select2FARequiredData = (state: RootState) => ({
    token: state.auth.token,
    app: state.auth.method,
});

export const isLoginUser = (state: RootState) => state.auth.isLogin;

export default authSlice.reducer;
