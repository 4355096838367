import { CoreBackButton } from '@packages';
import { Modal } from 'antd';
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
// THIS IS VERY IMPORTANT.
import 'react-json-view-lite/dist/index.css';

import './file-view.scss';

interface IJsonViewProps {
    jsonData: any;
    isOpenModal: boolean;
    closeModal: () => void;
}
const JsonViewer = ({ closeModal, isOpenModal, jsonData }: IJsonViewProps) => {
    return (
        <>
            <Modal
                open={isOpenModal}
                className='file-view-custom-modal'
                closable={false}
                maskClosable={false}
                footer={null}
            >
                <>
                    <CoreBackButton
                        onClick={closeModal}
                        text='Back'
                        className='file-view-back-button'
                    />
                    <div className='file-container'>
                        {jsonData ? (
                            <JsonView
                                data={jsonData}
                                shouldExpandNode={allExpanded}
                                style={defaultStyles}
                            />
                        ) : (
                            <h1 className='view-info-text'>Zatca response not found</h1>
                        )}
                    </div>
                </>
            </Modal>
        </>
    );
};

export default JsonViewer;
