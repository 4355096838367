import { useEffect, useState } from 'react';
import './CustomerDetails.scss';
import { CoreBackButton, CoreList, CoreStatus, CoreTitle } from '@packages';
import { Col, Row, Spin, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { UtcToLocalDate } from '@utils/date';
import DropDownEdit from './DropDownEdit';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { getAddressInformation, getCustomerInformation, getPersonInformation } from '@utils/helper';
import { useAppDispatch } from '@store/hooks';
import { getCustomer } from '@store/slices/customerSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { useTranslation } from 'react-i18next';

type CoreList = { key: string; value: string }[];

const CustomerDetails = () => {
    const { t } = useTranslation();
    const { isLoading, customer } = useSelector((state: RootState) => state.customers);
    const dispatch = useAppDispatch();
    const [customerInfoData, setCustomerInfoData] = useState<CoreList>([]);
    const [addressInfoData, setAddressInfoData] = useState<CoreList>([]);
    const [personInfoData, setPersonInfoData] = useState<CoreList>([]);

    const { id: customerId } = useParams();

    const tabItems = [
        {
            label: `${t('CUSTOMER_INFORMATION')}`,
            key: '1',
            children: <CoreList listData={customerInfoData} />,
        },
        {
            label: `${t('ADDRESS_INFORMATION')}`,
            key: '3',
            children: <CoreList listData={addressInfoData} />,
        },
        {
            label: `${t('CONTACT_INFORMATION')}`,
            key: '2',
            children: <CoreList listData={personInfoData} />,
        },
    ];

    const getCustomerDetails = () => {
        if (!customerId) return;
        dispatch(getCustomer(customerId));
    };

    useEffect(() => {
        getCustomerDetails();
    }, [customerId]);

    useEffect(() => {
        if (customer) {
            const customerInfo = getCustomerInformation(customer, t);
            const addressInfo = getAddressInformation(customer?.Address, t);
            const personInfo = getPersonInformation(customer?.Person, t);
            setCustomerInfoData(customerInfo);
            setAddressInfoData(addressInfo);
            setPersonInfoData(personInfo);
        }
    }, [customer, t]);

    return (
        <Spin spinning={isLoading}>
            <div className='container customer-details'>
                <CoreBackButton text={''} />
                <Row justify='space-between'>
                    <Col>
                        <CoreTitle tag='h2' text={customer?.LegalRegistrationName} />
                        <div>
                            {t('VAT_REGISTRATION_NUMBER')}:{' '}
                            <CoreStatus
                                status={customer?.VatRegistrationNumber || DEFAULT_EMPTY_VALUE}
                                type='basic'
                            />
                            | {t('CREATED_DATE')}: {UtcToLocalDate(customer?.CreatedAt)} |{' '}
                            {t('LAST_UPDATED_DATE')}: {UtcToLocalDate(customer?.UpdatedAt)}
                        </div>
                    </Col>
                    <Col>
                        <DropDownEdit customer={customer} getCustomerDetails={getCustomerDetails} />
                    </Col>
                </Row>

                <div className='customer-details-tab-wrapper'>
                    <Tabs items={tabItems} />
                </div>
            </div>
        </Spin>
    );
};

export default CustomerDetails;
