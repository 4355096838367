import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IFetchListPayload } from '@models/generic.model';
import {
    ICreateInventory,
    IInventory,
    IInventoryGetPayload,
    IInventoryResponse,
    IUpdateInventory,
} from '@models/inventory.model';
import { processQuery } from '@utils/processQuery';

export const INVENTORY_API = {
    getAllInventories: async (payload: IInventoryGetPayload): Promise<IInventoryResponse> => {
        const url = APP_CONFIG.eisUrl + `InventoryCommand/GetItems`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getSingleInventory: async (id: string): Promise<IInventory> => {
        const url =
            APP_CONFIG.eisUrl + `InventoryQuery/GetItem${processQuery({ inventoryId: id })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    createInventory: async (payload: ICreateInventory): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `InventoryCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            console.log(response);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    updateInventory: async (payload: IUpdateInventory): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `InventoryCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            console.log(response);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },
    activeInventory: async (inventoryId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `InventoryCommand/Activate`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { inventoryId });
            return Promise.resolve(true);
        } catch (error) {
            const err = error as AxiosError;
            console.log(err);
            return Promise.resolve(false);
        }
    },
    deActiveInventory: async (inventoryId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `InventoryCommand/Deactivate`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { inventoryId });
            return Promise.resolve(true);
        } catch (error) {
            const err = error as AxiosError;
            console.log(err);
            return Promise.resolve(false);
        }
    },
    archiveInventory: async (inventoryId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `InventoryCommand/Archive`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { inventoryId });
            return Promise.resolve(true);
        } catch (error) {
            const err = error as AxiosError;
            console.log(err);
            return Promise.resolve(false);
        }
    },
    unarchiveInventory: async (inventoryId: string): Promise<boolean> => {
        const url = APP_CONFIG.eisUrl + `InventoryCommand/Unarchive`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, { inventoryId });
            return Promise.resolve(true);
        } catch (error) {
            const err = error as AxiosError;
            console.log(err);
            return Promise.resolve(false);
        }
    },
};
