import { isEmpty } from 'lodash';

export function generateInvoiceType(invoiceType: string, options?: string): string {
    if (isEmpty(options) || !invoiceType) return invoiceType;
    const position = TypePosition[options as any];
    if (position) {
        invoiceType = replaceChar(invoiceType, position as any, '1');
    }
    return invoiceType;
}

export function parseInvoiceOptions(InvoiceType: string): {
    options: string;
    InvoiceType: string;
} {
    const options: string[] = [];
    // Extracting options based on positions
    for (const option in TypePosition) {
        if (isNaN(Number(option))) {
            const position = TypePosition[option];
            if (InvoiceType[position as any] === '1') {
                options.push(option);
            }
        }
    }

    return { options: options[0], InvoiceType: getFixedInvoiceType(InvoiceType) };
}

export function getFixedInvoiceType(invoiceType?: string): string {
    if (!invoiceType) return '';
    const prefix = invoiceType.substring(0, 2);
    return prefix + '00000';
}

// Function to replace character at a specific index in a string
function replaceChar(str: string, index: number, char: string): string {
    return str.substring(0, index) + char + str.substring(index + 1);
}

export function getInvoiceTypeOptions(invoiceType?: string) {
    if (!invoiceType) return [];
    if (invoiceType?.toLocaleLowerCase().includes('simplified')) {
        return SimplifiedInvoiceTypeOption;
    }
    return StandardInvoiceTypeOption;
}

export enum InvoiceTypeOptions {
    '3rd party' = '3rd party',
    'Nominal' = 'Nominal',
    'Exports' = 'Exports',
    'Summary' = 'Summary',
    'Self-billed' = 'Self-billed',
    'None of the Above' = 'None of the Above',
}

export enum TypePosition {
    '3rd party' = 2,
    'Nominal' = 3,
    'Exports' = 4,
    'Summary' = 5,
    'Self-billed' = 6,
}

export const CommonInvoiceTypeOption = [
    {
        value: InvoiceTypeOptions['3rd party'],
        label: '3_RD_PARTY',
        Key: InvoiceTypeOptions['3rd party'],
    },
    {
        value: InvoiceTypeOptions['Nominal'],
        label: 'NOMINAL',
        Key: InvoiceTypeOptions['Nominal'],
    },
    {
        value: InvoiceTypeOptions['Summary'],
        label: 'SUMMARY',
        Key: InvoiceTypeOptions['Summary'],
    },
];

export const SimplifiedInvoiceTypeOption = CommonInvoiceTypeOption.concat([
    {
        value: InvoiceTypeOptions['None of the Above'],
        label: 'NONE_OF_ABOVE',
        Key: InvoiceTypeOptions['None of the Above'],
    },
]);

export const StandardInvoiceTypeOption = CommonInvoiceTypeOption.concat([
    {
        value: InvoiceTypeOptions['Exports'],
        label: 'EXPORTS',
        Key: InvoiceTypeOptions['Exports'],
    },
    {
        value: InvoiceTypeOptions['Self-billed'],
        label: 'SELF_BILLED',
        Key: InvoiceTypeOptions['Self-billed'],
    },
    {
        value: InvoiceTypeOptions['None of the Above'],
        label: 'NONE_OF_THE_ABOVE',
        Key: InvoiceTypeOptions['None of the Above'],
    },
]);
