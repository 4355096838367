import { CoreButton, CoreTitle } from '@packages';
import './BillingPayment.scss';
import { UTCToShortDate } from '@utils/date';
import { subscribe } from '@store/slices/subscribeSlice';
import { useAppSelector } from '@store/hooks';
import { useNavigate } from 'react-router-dom';

const BillingPayment = () => {
    const { subscription } = useAppSelector(subscribe);
    const navigate = useNavigate();

    return (
        <div className='billing-payment-wrapper round-box-shadow'>
            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='Billing Period' />
                <p>
                    {UTCToShortDate(subscription?.LastPaymentDate)} -{' '}
                    {UTCToShortDate(subscription?.DueDate)}
                </p>
            </div>

            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='Last Billed on' />
                <p>{UTCToShortDate(subscription?.LastPaymentDate)}</p>
            </div>

            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='Previous Invoice' />
                <p>SAR 0.40</p>
            </div>

            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='This Time Last Month' />
                <p>SAR 0.00</p>
            </div>

            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='Next Payment' />
                <p>{UTCToShortDate(subscription?.LastPaymentDate)}</p>
            </div>

            <div>
                <CoreButton
                    type='primary'
                    text='Manage Payments'
                    size='small'
                    onClick={() => {
                        navigate('/subscribe/pricing-plans');
                    }}
                />
            </div>
        </div>
    );
};

export default BillingPayment;
