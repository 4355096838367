import { Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { localStoreAPI } from '@service/local-storage.service';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { authAPI } from '@service/auth.service';

const RequireAnonymous = () => {
    const { isLogin } = useSelector((state: RootState) => state.auth);

    const removeLoggedInfo = async () => {
        if (isLogin) {
            return;
        }
        localStoreAPI.removeLoggedInfo();
    };

    const getAnonymousToken = async () => {
        try {
            const response = await authAPI.getAnonymousToken();
            return response;
        } catch (error) {
            console.error('Failed to retrieve anonymous token:', error);
            return null;
        }
    };

    useEffect(() => {
        removeLoggedInfo();
        getAnonymousToken();
    }, []);
    return isLogin ? <Navigate to='/authorization' /> : <Outlet />;
};

export default RequireAnonymous;
