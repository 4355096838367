import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined } from '@ant-design/icons';
import { CoreSearchInput } from '@packages';
import { Table, Tag, message } from 'antd';
import CreatePolicy from '../create-policy/CreatePolicy';
import { UtcToLocalDate } from '@utils/date';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getPolicies, selectPoliciesInfo } from '@store/slices/policySlice';
import { IPolicy } from '@models/policy.model';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { ConfirmationModal } from '@components';
import { PolicyAPI } from '@service/policy.service';

const columns: ColumnsType<IPolicy> = [
    {
        title: 'Policy Name',
        dataIndex: 'PolicyName',
    },
    {
        title: 'Managed By',
        dataIndex: 'ManagedBy',
        render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
    },

    {
        title: 'Actions',
        dataIndex: 'Actions',
        render: (texts: string[]) => (
            <span>
                {texts.map((text) => (
                    <Tag key={text} color='#285FF6' style={{ marginBottom: 2 }}>
                        {text}
                    </Tag>
                ))}
            </span>
        ),
    },
    {
        title: 'Create Date',
        dataIndex: 'CreatedAt',
        render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
    },
];

function PolicyList() {
    const dispatch = useAppDispatch();
    const policyInfo = useAppSelector(selectPoliciesInfo);
    const [isDeleteModalShow, setShowDelateModal] = useState<boolean>(false);
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false);
    const [policyName, setPolicyName] = useState<string>();
    const [managedBy, setManagedBy] = useState<string>();

    const policyColumns: ColumnsType<IPolicy> = [
        ...columns,
        {
            title: '',
            dataIndex: 'ItemId',
            render: (ItemId: string, data: IPolicy) => (
                <div className='action-buttons'>
                    <div
                        className='invite-client-icon'
                        onClick={() => {
                            setPolicyName(data?.PolicyName);
                            setManagedBy(data?.ManagedBy);
                            setShowDelateModal(true);
                        }}
                        aria-hidden='true'
                    >
                        <DeleteOutlined />
                    </div>
                </div>
            ),
        },
    ];
    const getAllPolicies = () => {
        dispatch(
            getPolicies({
                PageLimit: 20,
            }),
        );
    };

    const onDeletePolicy = async () => {
        if (!policyName) return;
        setButtonLoading(true);

        try {
            await PolicyAPI.deletePolicy({ ManagedBy: managedBy || '', PolicyName: policyName });
            setButtonLoading(false);
            message.success('Policy Deleted Successfully');
            setPolicyName('');
            getAllPolicies();
            setShowDelateModal(false);
        } catch {
            message.error('Policy Deleted Failed');
            setButtonLoading(false);
        }
    };

    const onHideModal = () => {
        setShowDelateModal(false);
        setPolicyName('');
    };

    useEffect(() => {
        getAllPolicies();
    }, []);
    return (
        <div>
            <div className='filter-header margin-30'>
                <CoreSearchInput placeholder='Search' />
                <div className='create-button-group'>
                    <CreatePolicy onPageRefresh={getAllPolicies} />
                </div>
            </div>
            <div className='ksa-table-wrapper table-auto-scroll-without-header'>
                <Table
                    loading={policyInfo.status === 'loading' ? true : false}
                    rowKey={'ItemId'}
                    columns={policyColumns}
                    dataSource={policyInfo.data}
                    rowClassName='cursor-pointer'
                    pagination={false}
                />
            </div>
            <ConfirmationModal
                title='Delete Policy'
                description='Do you want to delete this policy?'
                buttonText='Delete'
                isDeleteModalOpen={isDeleteModalShow}
                handleDeleteOk={onDeletePolicy}
                handleDeleteCancel={onHideModal}
                isLoading={isButtonLoading}
            />
        </div>
    );
}

export default PolicyList;
