const SmallLogo = () => {
    return (
        <svg
            width='42'
            height='41'
            viewBox='0 0 42 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_i_10715_1250)'>
                <path
                    d='M11.9046 2.11768H36.362C37.2739 2.11768 37.9506 2.96314 37.7508 3.85286L30.8352 34.6524H7.17754C4.07305 34.6524 1.7715 31.7706 2.45791 28.743L7.7402 5.44361C8.1812 3.49844 9.91004 2.11768 11.9046 2.11768Z'
                    fill='url(#paint0_linear_10715_1250)'
                />
            </g>
            <g filter='url(#filter1_f_10715_1250)'>
                <path
                    d='M19.4686 13.7248C19.6914 13.2 20.2058 12.8595 20.7758 12.8595L29.6229 12.8595C30.4036 13.0794 28.6972 17.2164 28.1762 18.4123L23.9731 28.0605L13.0986 26.8084C13.5823 26.8487 15.8016 22.2374 16.3609 21.0438L19.4686 13.7248Z'
                    fill='black'
                    fillOpacity='0.88'
                />
            </g>
            <g filter='url(#filter2_i_10715_1250)'>
                <path
                    d='M15.7558 12.5227C15.9027 11.8741 16.4791 11.4136 17.1441 11.4136H36.0451C38.4169 11.4136 40.1751 13.6216 39.6501 15.941L35.6654 33.5435C35.5186 34.1921 34.9422 34.6526 34.2772 34.6526H7.68945C9.08343 34.4868 10.8174 33.8685 11.4521 31.5347L15.7558 12.5227Z'
                    fill='url(#paint1_linear_10715_1250)'
                />
            </g>
            <defs>
                <filter
                    id='filter0_i_10715_1250'
                    x='2.33594'
                    y='2.11768'
                    width='35.4502'
                    height='32.8552'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='0.320539' />
                    <feGaussianBlur stdDeviation='0.320539' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0'
                    />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_10715_1250' />
                </filter>
                <filter
                    id='filter1_f_10715_1250'
                    x='1.14247'
                    y='0.903213'
                    width='40.6379'
                    height='39.1135'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feGaussianBlur
                        stdDeviation='5.97808'
                        result='effect1_foregroundBlur_10715_1250'
                    />
                </filter>
                <filter
                    id='filter2_i_10715_1250'
                    x='7.68945'
                    y='11.4136'
                    width='32.0537'
                    height='23.7201'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='0.480808' />
                    <feGaussianBlur stdDeviation='0.400673' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.51 0'
                    />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_10715_1250' />
                </filter>
                <linearGradient
                    id='paint0_linear_10715_1250'
                    x1='19.6293'
                    y1='2.11768'
                    x2='19.6293'
                    y2='34.6524'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#02B59F' />
                    <stop offset='1' stopColor='#02A390' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_10715_1250'
                    x1='38.0446'
                    y1='12.768'
                    x2='12.7484'
                    y2='34.5837'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#F7CB0B' />
                    <stop offset='0.468466' stopColor='#C5D53E' />
                    <stop offset='1' stopColor='#5CE3AB' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SmallLogo;
