export const NoteReasonEnglish = [
    {
        value: 'Cancellation or suspension of the supplies after its occurrence either wholly or partially',
        label: 'Cancellation or suspension of the supplies after its occurrence either wholly or partially',
        hoverName: 'تم إلغاء أو وقف التوريد بعد حدوثه أو اعتباره كلياً أو جزئياً',
        id: 1,
    },
    {
        value: 'In case of essential change or amendment in the supply, which leads to the change of the VAT due',
        label: 'In case of essential change or amendment in the supply, which leads to the change of the VAT due',
        hoverName:
            'وجود تغيير أو تعديل جوهري في طبيعة التوريد بحيث يؤدي إلى تغيير الضريبة المستحقة',
        id: 2,
    },
    {
        value: 'Amendment of the supply value which is pre-agreed upon between the supplier and consumer',
        label: 'Amendment of the supply value which is pre-agreed upon between the supplier and consumer',
        hoverName: 'تم الاتفاق على تعديل قيمة التوريد مسبقاً',
        id: 3,
    },
    {
        value: 'In case of goods or services refund',
        label: 'In case of goods or services refund',
        hoverName: 'عند ترجيع السلع أو الخدمات',
        id: 4,
    },
    {
        value: "In case of change in Seller's or Buyer's information",
        label: "In case of change in Seller's or Buyer's information",
        hoverName: 'عند التعديل على بيانات المورد أو المشتري',
        id: 5,
    },
];
export const NoteReasonArabic = [
    {
        value: 'تم إلغاء أو وقف التوريد بعد حدوثه أو اعتباره كلياً أو جزئياً',
        label: 'تم إلغاء أو وقف التوريد بعد حدوثه أو اعتباره كلياً أو جزئياً',
        hoverName:
            'Cancellation or suspension of the supplies after its occurrence either wholly or partially',
        id: 1,
    },
    {
        value: 'وجود تغيير أو تعديل جوهري في طبيعة التوريد بحيث يؤدي إلى تغيير الضريبة المستحقة',
        label: 'وجود تغيير أو تعديل جوهري في طبيعة التوريد بحيث يؤدي إلى تغيير الضريبة المستحقة',
        hoverName:
            'In case of essential change or amendment in the supply, which leads to the change of the VAT due',
        id: 2,
    },
    {
        value: 'تم الاتفاق على تعديل قيمة التوريد مسبقاً',
        label: 'تم الاتفاق على تعديل قيمة التوريد مسبقاً',
        hoverName:
            'Amendment of the supply value which is pre-agreed upon between the supplier and consumer',
        id: 3,
    },
    {
        value: 'عند ترجيع السلع أو الخدمات',
        label: 'عند ترجيع السلع أو الخدمات',
        hoverName: 'In case of goods or services refund',
        id: 4,
    },
    {
        value: 'عند التعديل على بيانات المورد أو المشتري',
        label: 'عند التعديل على بيانات المورد أو المشتري',
        hoverName: "In case of change in Seller's or Buyer's information",
        id: 5,
    },
];
