import { useState } from 'react';
import AddressDetails from './AddressDetails';
import './Address.scss';
import AddressEdit, { AddressEditType } from './AddressEdit';

export default function Address() {
    const [isEditModalOpen, setModalOpen] = useState<boolean>(false);
    const [addressType, setAddressType] = useState<AddressEditType>('billing');

    const onUpdateInfo = () => {
        setModalOpen(false);
    };
    return (
        <>
            <AddressDetails
                openEditBillingModal={() => {
                    setAddressType('billing');
                    setModalOpen(true);
                }}
                openEditShippingModal={() => {
                    setAddressType('shipping');
                    setModalOpen(true);
                }}
            />
            <AddressEdit
                isOpenModal={isEditModalOpen}
                onCancelModal={() => {
                    setModalOpen(false);
                }}
                onUpdateAddress={onUpdateInfo}
                addressType={addressType}
            />
        </>
    );
}
