import { Col, Divider, Input, Row, Spin, Tabs, message } from 'antd';
import './NewInvoice.scss';
import { Controller, useForm } from 'react-hook-form';
import { CoreButton } from '@packages';
import InvoiceLines from '../../components/Items/InvoiceLines';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import InlineGenericInput from '@components/common/InlineGenericInput';
import dayjs from 'dayjs';
import {
    EInvoiceType,
    IInvoiceCreation,
    IInvoiceLinesItem,
    IInvoiceState,
    ISingleInvoice,
} from '@models/invoice.model';
import {
    GET_MANDATORY_KEYS_BY_INVOICE_TYPE,
    GET_INVOICE_TYPE_CODE_BY_NAME,
    GET_MANDATORY_KEYS_BY_CUSTOMER,
    GET_PHASE_ONE_INVOICE_TYPES,
} from '@utils/InvoiceUtils';
import { GET_CURRENCIES_CODE_OPTIONS, GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    getCustomersForSelect,
    resetCustomerSelect,
    selectCustomersOption,
} from '@store/slices/customerSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    getAvailableOrganizations,
    selectAvailableOrganizationsInfo,
} from '@store/slices/organizationSlice';
import {
    addNewMultipleInvoiceLineItem,
    createInvoices,
    getInvoices,
    getInvoicesForSelect,
    resetAllowanceCharge,
    resetInvoicesSelect,
    resetPrePayment,
    selectInvoiceForReferences,
    selectInvoiceLineItems,
    selectInvoiceValidateLineItems,
    selectTotalAllowanceCharges,
    setShowInvoiceItemRequired,
} from '@store/slices/einvoiceSlice';
import InlineGenericDate from '@components/common/InlineGenericDate';
import PaymentMeans from '../../components/payment-means/PaymentMeans';
import { IDevice, IOrganization } from '@models/organization.model';
import { fileStorageAPI } from '@service/file-storage.service';
import PdfView from '@components/file-view/PdfView';
import { useTranslation } from 'react-i18next';
import { getActiveDevices, selectActiveDeviceInfo } from '@store/slices/deviceSlice';
import { getActiveEntities, selectActiveEntityInfo } from '@store/slices/entitySlice';
import InlineGenericInfiniteScrollingSelectParams from '@components/common/InlineGenericInfiniteScrollingSelect';
import { invoiceTypeOptionsForInitial } from '@utils/InvoiceTypeOptions';
import { unwrapResult } from '@reduxjs/toolkit';
import PrePayments from '../../components/pre-payments/PrePayments';
import AllowanceCharges from '../../components/allowanceCharges/AllowanceCharges';
import CreateCustomerModal from '../../components/customer-modal/CreateCustomerModal';
import { DEFAULT_DELAY_TIME } from '@config/constants';
import InvoiceType, {
    IInvoiceSelectedType,
    getInvoiceTypeByValue,
} from '../../components/invoice-type/InvoiceType';
import { generateUUID } from '@utils/uuid';
import { getInvoiceCalculation, getInvoiceTotalTaxCalculation } from '@utils/InvoiceCalculation';
import { NoteReasonArabic, NoteReasonEnglish } from '@utils/InvoiceNoteReason';
import { cloneDeep } from 'lodash';
import { addAllowanceTaxToInvoiceLineItems } from '@utils/invoiceAllowanceCharge';
import { getTaxExemptions } from '@utils/taxExemptions';
import {
    InvoiceTypeOptions,
    generateInvoiceType,
    getInvoiceTypeOptions,
    parseInvoiceOptions,
} from '@utils/InvoiceTypeSelectOption';
import { getSubscription } from '@store/slices/subscribeSlice';

const { TextArea } = Input;

const NewInvoice: FC = () => {
    const { t } = useTranslation();

    const customersInfo = useAppSelector(selectCustomersOption);
    const availableOrganizationInfo = useAppSelector(selectAvailableOrganizationsInfo);
    const entitiesInfo = useAppSelector(selectActiveEntityInfo);
    const devicesInfo = useAppSelector(selectActiveDeviceInfo);

    const invoiceForReferences = useAppSelector(selectInvoiceForReferences);
    const invoiceValidateLineItems = useAppSelector(selectInvoiceValidateLineItems);
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false);
    const [organizationPhase, setOrganizationPhase] = useState<1 | 2>(1);
    const [selectedDeviceInvoiceType, setSelectedDeviceInvoiceType] = useState('');
    const [deviceIsManualIRN, setDeviceIsManualIRN] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [pdfUrl, setPDFUrl] = useState<string>();
    const [referenceFileId, setReferenceFileId] = useState<string>();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isShowCustomerModal, setShowCustomerModal] = useState<boolean>(false);
    const [invoiceTypeInfo, setInvoiceTypeInfo] = useState<IInvoiceSelectedType>();
    const [invoiceMaxLinesAmountLimit, setInvoiceMaxLinesAmountLimit] = useState<number>(0);
    const [customerSearchText, setCustomerSearchText] = useState<string | undefined>(undefined);
    const totalDocumentAllowance = useAppSelector(selectTotalAllowanceCharges);

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        getValues,
        formState: { errors },
        watch,
    } = useForm<IInvoiceCreation>({
        mode: 'onChange',
        defaultValues: {
            DocumentCurrencyCode: GET_DEFAULT_CURRENCY_CODE,
            TaxCurrencyCode: GET_DEFAULT_CURRENCY_CODE,
        },
    });

    const { state, pathname } = useLocation();
    const isCreateNote = pathname !== '/e-invoices/create';

    const invoiceLineItems = useAppSelector(selectInvoiceLineItems);

    const goToInvoices = () => {
        navigate('/e-invoices', {
            state: {
                delay: true,
            },
        });
    };

    const onSubmit = async (data: IInvoiceCreation) => {
        const totalAmount =
            getInvoiceCalculation(invoiceLineItems) +
            totalDocumentAllowance?.totalTaxDiscountCharge;

        if (totalAmount < 0) {
            message.warning(t('TOTAL_AMOUNT_CAN_NOT_BE_NEGATIVE'));
            return;
        }
        if (invoiceValidateLineItems.length < 1) {
            message.warning(t('BEFORE_CREATING_A_NEW_ENTRY_PLEASE_ADD_ALL_REQUIRED_ITEMS'));
            dispatch(setShowInvoiceItemRequired());
            return;
        }
        if (
            invoiceMaxLinesAmountLimit &&
            getInvoiceCalculation(invoiceValidateLineItems) > invoiceMaxLinesAmountLimit
        ) {
            message.error(
                t('Credit Note value must be less than or equal to original invoice value'),
            );
            dispatch(setShowInvoiceItemRequired());
            return;
        }
        const getInvoiceTypeInfo = GET_INVOICE_TYPE_CODE_BY_NAME(data.InvoiceType as EInvoiceType);
        const InvoiceType = generateInvoiceType(
            getInvoiceTypeInfo?.InvoiceTypeCode || '',
            data?.InvoiceTypeOptions,
        );
        data?.ExchangeRate && (data.ExchangeRate = Number(data?.ExchangeRate));
        data?.PaymentMeans?.PaymentMeansCode &&
            (data.PaymentMeans.PaymentMeansCode = Number(data?.PaymentMeans?.PaymentMeansCode));
        const itemData = await addAllowanceTaxToInvoiceLineItems(invoiceValidateLineItems);

        const totalAccountingTaxAmount = data?.ExchangeRate
            ? data?.ExchangeRate * getInvoiceTotalTaxCalculation(invoiceLineItems)
            : getInvoiceTotalTaxCalculation(invoiceLineItems);

        const newData: IInvoiceCreation = {
            ...data,
            TotalAccountingTaxAmount: totalAccountingTaxAmount,
            ...getInvoiceTypeInfo,
            InvoiceTypeCode: InvoiceType,
            InvoiceLines: itemData,
            TaxExemptions: getTaxExemptions(invoiceValidateLineItems),
        };

        setButtonLoading(true);
        dispatch(createInvoices(newData))
            .then(unwrapResult)
            .then((response) => {
                // if(response?.IRN){
                //     message.success(`${response?.IRN} ${t('GENERATED_SUCCESSFULLY')}`);
                // }
                // else{
                //     message.success(`${t('ID_NOT_FOUND_GENERATED_SUCCESSFULLY')}`);
                // }
                dispatch(getSubscription());
                message.success(t('INVOICE_CREATED_SUCCESSFULLY'));
                goToInvoices();
                setButtonLoading(false);
            })
            .catch((error) => {
                message.error(t('INVOICE_CREATION_FAILED'));
                setButtonLoading(false);
                goToInvoices();
            });
    };
    const onDownloadItem = async (itemId: string) => {
        setIsDownloading(true);
        try {
            const response = await fileStorageAPI.getFileUrl(itemId);
            setPDFUrl(response);
            setIsDownloading(false);
        } catch (error) {
            setIsDownloading(false);
            message.error(t('FILE_INFORMATION_NOT_FOUND'));
            throw error;
        }
    };

    const getAllFetchRequest = () => {
        getMoreCustomers();
        dispatch(getAvailableOrganizations());
        getInitialInvoiceCheck();
    };

    const getInitialInvoiceCheck = () => {
        dispatch(
            getInvoices({
                payload: {
                    PageLimit: 10,
                    PageNumber: 1,
                },
            }),
        );
    };

    const getMoreInvoice = (isReset = false) => {
        const { PageLimit, PageNumber } = invoiceForReferences;
        const entityId = watch('EntityId');
        const deviceId = watch('DeviceId');
        dispatch(
            getInvoicesForSelect({
                payload: {
                    PageLimit: PageLimit,
                    PageNumber: isReset ? 1 : PageNumber,
                    InvoiceType: invoiceTypeInfo?.InvoiceType,
                    InvoiceTypeCode: invoiceTypeInfo?.InvoiceTypeCode?.substring(0, 2),
                    EntityId: entityId,
                    DeviceId: deviceId,
                },
            }),
        );
    };

    const getMoreCustomers = (isReset = false) => {
        const { PageLimit, PageNumber } = customersInfo;
        dispatch(
            getCustomersForSelect({
                PageLimit: PageLimit,
                PageNumber: isReset ? 1 : PageNumber,
                Search: customerSearchText,
                isReset: isReset,
            }),
        )
            .then(unwrapResult)
            .then((result) => {
                if (!isReset) return;
                const customerList = result?.CustomerList || [];
                if (customerList && !isCustomerFieldHasValue() && customerList?.length === 1) {
                    setFormValue('CustomerId', customerList[0].ItemId || '');
                }
            });
    };

    const onResponseCustomerModal = ({ isCancel }: { isCancel: boolean }) => {
        !getValues('CustomerId') && setFormValue('CustomerId', null as any);
        if (isCancel) return setShowCustomerModal(false);
        dispatch(resetCustomerSelect());
        setTimeout(() => {
            getMoreCustomers(true);
            setShowCustomerModal(false);
        }, DEFAULT_DELAY_TIME);
        return;
    };

    const isCustomerFieldHasValue = (): boolean => {
        return !!getValues('CustomerId');
    };

    const getAllOrgDependency = (orgId: string, organization: IOrganization) => {
        const phaseNumber = organization.InvoicePhase;
        setOrganizationPhase(phaseNumber as 1 | 2);
        if (phaseNumber == 2) {
            dispatch(getActiveEntities(orgId))
                .then(unwrapResult)
                .then((result) => {
                    if (result && result.length === 1) {
                        setFormValue('EntityId', result[0].EntityId || '');
                    }
                });

            dispatch(getActiveDevices(orgId))
                .then(unwrapResult)
                .then((result) => {
                    if (result && result.length === 1) {
                        setFormValue('DeviceId', result[0].DeviceId || '');
                        setSelectedDeviceInvoiceType(result[0]?.InvoiceType || '');
                        setDeviceIsManualIRN(result[0]?.IsManualIRN || false);
                    }
                });
            onResetInvoiceType();
        }
        if (phaseNumber == 1) {
            setFormValue('InvoiceType', GET_PHASE_ONE_INVOICE_TYPES[0].value);
        }

        setFormValue('EntityId', '');
        setFormValue('DeviceId', '');
    };

    const getDeviceDependency = (deviceId: string, device: IDevice) => {
        setSelectedDeviceInvoiceType(device?.InvoiceType || '');
        setDeviceIsManualIRN(device?.IsManualIRN);
        onResetInvoiceType();
    };

    const onResetInvoiceType = () => {
        setFormValue('InvoiceType', '');
        setFormValue('InvoiceTypeOptions', undefined);
    };

    const setLinesItemsToStore = (invoiceLines: IInvoiceLinesItem[]) => {
        const linesItems = invoiceLines || [];
        const modifyItems = linesItems.map(
            (item) =>
                ({
                    ...item,
                    SerialId: generateUUID(),
                    DiscountType: 'Amount',
                    DiscountValue: 0,
                    DiscountAmount: 0,
                } as IInvoiceLinesItem),
        );
        dispatch(addNewMultipleInvoiceLineItem(modifyItems));
    };

    useEffect(() => {
        getAllFetchRequest();
        return () => {
            dispatch(resetInvoicesSelect());
            dispatch(resetCustomerSelect());
            dispatch(resetPrePayment());
            dispatch(resetAllowanceCharge());
        };
    }, []);

    useEffect(() => {
        if (!selectedDeviceInvoiceType || invoiceForReferences?.data?.length > 0) return;
        const getInvoiceTypeList = invoiceTypeOptionsForInitial(selectedDeviceInvoiceType);
        if (getInvoiceTypeList.length === 1) {
            const invoiceType = getInvoiceTypeList[0].value;
            setFormValue('InvoiceType', invoiceType);
        }
    }, [selectedDeviceInvoiceType]);

    useEffect(() => {
        if (!state) return;
        const {
            InvoiceTypeCode,
            InvoiceType,
            DeviceId,
            EntityId,
            OrganizationId,
            InvoicePhase,
            CustomerId,
            Reference,
            linesItems,
            totalAmount,
            isGenerateCreditNote,
        } = state as IInvoiceState;
        const { InvoiceType: fixedInvoiceType, options } = parseInvoiceOptions(InvoiceTypeCode);
        const invoiceCodeInfo = getInvoiceTypeByValue(fixedInvoiceType);
        setInvoiceTypeInfo(invoiceCodeInfo);
        setFormValue('OrganizationId', OrganizationId);
        setFormValue('InvoiceType', InvoiceType);
        setFormValue('InvoiceTypeOptions', options);
        DeviceId && setFormValue('DeviceId', DeviceId);
        EntityId && setFormValue('EntityId', EntityId);
        OrganizationId &&
            dispatch(getActiveDevices(OrganizationId))
                .unwrap()
                .then((devices) => {
                    if (!DeviceId) return;
                    const deviceInfo = devices.find((device) => device.DeviceId === DeviceId);
                    setSelectedDeviceInvoiceType(deviceInfo?.InvoiceType || '');
                    setDeviceIsManualIRN(deviceInfo?.IsManualIRN || false);
                });

        OrganizationId && dispatch(getActiveEntities(OrganizationId));
        InvoicePhase && setOrganizationPhase(InvoicePhase);
        Reference?.ID && setFormValue('Reference.ID', Reference?.ID);
        Reference?.IssueDatetime &&
            setFormValue('Reference.IssueDatetime', Reference?.IssueDatetime);
        Reference?.FileId && setReferenceFileId(Reference?.FileId);
        CustomerId && setFormValue('CustomerId', CustomerId);
        setLinesItemsToStore(linesItems);
        isGenerateCreditNote && setInvoiceMaxLinesAmountLimit(totalAmount);
    }, [state]);

    useEffect(() => {
        if (!invoiceTypeInfo) return;
        getMoreInvoice(true);
    }, [invoiceTypeInfo, watch('DeviceId'), watch('EntityId')]);

    useEffect(() => {
        getMoreCustomers(true);
    }, [customerSearchText]);

    return (
        <div className='new-invoice-wrapper'>
            <form id='newInvoice'>
                <div className='new-invoice-inner-wrapper'>
                    <div className='new-invoice-form-wrapper'>
                        <div className='new-invoice-form-inner-wrapper'>
                            <Row align='middle' gutter={20}>
                                <Col md={12} lg={8}>
                                    <InlineGenericSelect
                                        levelCol={24}
                                        inputCol={24}
                                        control={control}
                                        errors={errors}
                                        dataKey='OrganizationId'
                                        level={t('ORGANIZATIONS')}
                                        placeHolder={t('SELECT_ORGANIZATION')}
                                        options={availableOrganizationInfo.data}
                                        loading={
                                            availableOrganizationInfo.status === 'loading'
                                                ? true
                                                : false
                                        }
                                        setFormValue={setFormValue}
                                        onChange={getAllOrgDependency}
                                        rules={{
                                            required: true,
                                        }}
                                        marginBottom={0}
                                        disabled={isCreateNote}
                                        errorMessageSize='small'
                                    />
                                </Col>
                                {organizationPhase == 2 && (
                                    <>
                                        {entitiesInfo?.data?.length > 0 && (
                                            <Col md={12} lg={8}>
                                                <InlineGenericSelect
                                                    levelCol={24}
                                                    inputCol={24}
                                                    control={control}
                                                    errors={errors}
                                                    dataKey='EntityId'
                                                    level={t('ENTITY')}
                                                    options={entitiesInfo.data}
                                                    loading={
                                                        entitiesInfo.status === 'loading'
                                                            ? true
                                                            : false
                                                    }
                                                    setFormValue={setFormValue}
                                                    rules={{
                                                        required: entitiesInfo?.data?.length > 0,
                                                    }}
                                                    disabled={isCreateNote}
                                                    marginBottom={0}
                                                    errorMessageSize='small'
                                                />
                                            </Col>
                                        )}

                                        <Col md={12} lg={8}>
                                            <InlineGenericSelect
                                                levelCol={24}
                                                inputCol={24}
                                                control={control}
                                                errors={errors}
                                                dataKey='DeviceId'
                                                level={t('DEVICE')}
                                                placeHolder={t('SELECT_DEVICE')}
                                                options={devicesInfo.data}
                                                loading={
                                                    devicesInfo.status === 'loading' ? true : false
                                                }
                                                setFormValue={setFormValue}
                                                rules={{
                                                    required: true,
                                                }}
                                                disabled={isCreateNote}
                                                onChange={getDeviceDependency}
                                                marginBottom={0}
                                                errorMessageSize='small'
                                            />
                                        </Col>
                                    </>
                                )}

                                <Col md={12} lg={8}>
                                    <InvoiceType
                                        errors={errors}
                                        control={control}
                                        setFormValue={setFormValue}
                                        isCreateNote={isCreateNote}
                                        organizationPhase={organizationPhase}
                                        selectedInvoiceType={selectedDeviceInvoiceType}
                                        invoiceForReferences={invoiceForReferences}
                                        onInvoiceSelect={setInvoiceTypeInfo}
                                    />
                                </Col>

                                <Col md={12} lg={8}>
                                    <InlineGenericSelect
                                        levelCol={24}
                                        inputCol={24}
                                        control={control}
                                        errors={errors}
                                        dataKey='InvoiceTypeOptions'
                                        level={'TYPE_OPTIONS'}
                                        placeHolder={'SELECT_TYPE_OPTIONS'}
                                        options={getInvoiceTypeOptions(watch('InvoiceType'))}
                                        setFormValue={setFormValue}
                                        marginBottom={0}
                                        isMultipleSelect={false}
                                        sorting={false}
                                        errorMessageSize='small'
                                    />
                                </Col>

                                {GET_MANDATORY_KEYS_BY_CUSTOMER.includes(
                                    watch('InvoiceType') as any,
                                ) && (
                                    <Col md={12} lg={8}>
                                        <InlineGenericInfiniteScrollingSelectParams
                                            levelCol={24}
                                            inputCol={24}
                                            gutter={0}
                                            control={control}
                                            errors={errors}
                                            dataKey='CustomerId'
                                            level={t('CUSTOMER_NAME')}
                                            placeHolder={t('SELECT_CUSTOMER_NAME')}
                                            options={customersInfo.data}
                                            hasMore={customersInfo.hasMore}
                                            loading={
                                                customersInfo.status === 'loading' ? true : false
                                            }
                                            getMoreData={getMoreCustomers}
                                            setFormValue={setFormValue}
                                            rules={{
                                                required: true,
                                            }}
                                            allowClear
                                            errorMessageSize='small'
                                            marginBottom={0}
                                            disabled={isCreateNote && !!watch('CustomerId')}
                                            createNewOption={{
                                                title: t('CREATE_NEW'),
                                                onCreateNew: () => setShowCustomerModal(true),
                                            }}
                                            showSearch={true}
                                            onSearch={setCustomerSearchText}
                                        />
                                    </Col>
                                )}
                                {deviceIsManualIRN === true ? (
                                    <Col md={12} lg={8}>
                                        <InlineGenericInput
                                            levelCol={24}
                                            inputCol={24}
                                            control={control}
                                            errors={errors}
                                            dataKey='InvoiceID'
                                            level='Invoice ID'
                                            marginBottom={15}
                                            errorMessageSize='small'
                                            tooltip={'This device has Manual IRN Configured'}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Col>
                                ) : (
                                    <></>
                                )}

                                {GET_MANDATORY_KEYS_BY_INVOICE_TYPE.includes(
                                    watch('InvoiceType') as any,
                                ) && (
                                    <>
                                        <Col span={8}>
                                            <InlineGenericInfiniteScrollingSelectParams
                                                className={referenceFileId && 'reference-selection'}
                                                hasMore={invoiceForReferences.hasMore}
                                                getMoreData={getMoreInvoice}
                                                levelCol={24}
                                                inputCol={24}
                                                control={control}
                                                errors={errors}
                                                dataKey='Reference.ID'
                                                level={
                                                    watch('InvoiceType').search('CREDIT') == -1
                                                        ? 'DEBIT_NOTE_REFERENCE_ID'
                                                        : 'CREDIT_NOTE_REFERENCE_ID'
                                                }
                                                placeHolder={t('SELECT_REFERENCE_ID')}
                                                options={invoiceForReferences.data}
                                                rules={{
                                                    required: true,
                                                }}
                                                setFormValue={setFormValue}
                                                onChange={(id, option: ISingleInvoice) => {
                                                    setFormValue(
                                                        'Reference.IssueDatetime',
                                                        option?.IssueDateTime,
                                                    );
                                                    setReferenceFileId(option?.PdfFile?.FileId);
                                                }}
                                                loading={
                                                    invoiceForReferences.status === 'loading'
                                                        ? true
                                                        : false
                                                }
                                                marginBottom={0}
                                                errorMessageSize='small'
                                                disabled={isCreateNote}
                                            />
                                            {referenceFileId && (
                                                <div
                                                    className='reference-icon'
                                                    onClick={() => onDownloadItem(referenceFileId)}
                                                    aria-hidden='true'
                                                >
                                                    <Spin spinning={isDownloading}>
                                                        {' '}
                                                        {t('VIEW')}{' '}
                                                    </Spin>
                                                </div>
                                            )}
                                        </Col>
                                        <Col span={8}>
                                            <InlineGenericSelect
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='Reference.NoteReason'
                                                level='NOTE_REASON_ARABIC'
                                                placeHolder={t('NOTE_REASON_ARABIC')}
                                                sorting={false}
                                                options={NoteReasonArabic}
                                                onChange={(value, option) => {
                                                    const noteReasonEng =
                                                        cloneDeep(NoteReasonEnglish);
                                                    const reason = noteReasonEng.find(
                                                        (reason) => reason.id === option.id,
                                                    )?.value;
                                                    reason &&
                                                        setFormValue(
                                                            'Reference.NoteReasonEn',
                                                            reason,
                                                            {
                                                                shouldValidate: true,
                                                            },
                                                        );
                                                }}
                                                setFormValue={setFormValue}
                                                rules={{
                                                    required: true,
                                                }}
                                                marginBottom={0}
                                                errorMessageSize='small'
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <InlineGenericSelect
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='Reference.NoteReasonEn'
                                                level='NOTE_REASON_ENGLISH'
                                                placeHolder={t('NOTE_REASON_ENGLISH')}
                                                sorting={false}
                                                options={NoteReasonEnglish}
                                                onChange={(value, option) => {
                                                    const reason = cloneDeep(NoteReasonArabic).find(
                                                        (reason) => reason.id === option.id,
                                                    )?.value;
                                                    reason &&
                                                        setFormValue(
                                                            'Reference.NoteReason',
                                                            reason,
                                                            {
                                                                shouldValidate: true,
                                                            },
                                                        );
                                                }}
                                                setFormValue={setFormValue}
                                                rules={{
                                                    required: true,
                                                }}
                                                marginBottom={0}
                                                errorMessageSize='small'
                                            />
                                        </Col>
                                    </>
                                )}

                                <Col md={12} lg={8}>
                                    <InlineGenericInput
                                        levelCol={24}
                                        inputCol={24}
                                        control={control}
                                        errors={errors}
                                        dataKey='OrderReferenceID'
                                        level={t('ORDER_REFERENCE_ID')}
                                        marginBottom={15}
                                        errorMessageSize='small'
                                    />
                                </Col>

                                <Col md={12} lg={8}>
                                    <InlineGenericInput
                                        levelCol={24}
                                        inputCol={24}
                                        control={control}
                                        errors={errors}
                                        dataKey='ContractDocumentID'
                                        level='CONTRACT_DOCUMENT_ID'
                                        marginBottom={15}
                                        errorMessageSize='small'
                                    />
                                </Col>

                                <Col md={12} lg={8}>
                                    <InlineGenericSelect
                                        levelCol={24}
                                        inputCol={24}
                                        control={control}
                                        errors={errors}
                                        dataKey='DocumentCurrencyCode'
                                        level='INVOICE_CURRENCY_CODE'
                                        options={GET_CURRENCIES_CODE_OPTIONS}
                                        onChange={(value) => {
                                            if (value === 'SAR') {
                                                setFormValue('ExchangeRate', undefined, {
                                                    shouldValidate: true,
                                                });
                                            } else {
                                                setFormValue('ExchangeRate', 1, {
                                                    shouldValidate: true,
                                                });
                                            }
                                        }}
                                        sorting={false}
                                        rules={{
                                            required: true,
                                        }}
                                        disabled={
                                            invoiceValidateLineItems?.length > 0 ? true : false
                                        }
                                        showSearch={false}
                                        setFormValue={setFormValue}
                                        marginBottom={0}
                                        errorMessageSize='small'
                                    />
                                </Col>

                                <Col md={12} lg={8}>
                                    <InlineGenericSelect
                                        levelCol={24}
                                        inputCol={24}
                                        control={control}
                                        errors={errors}
                                        dataKey='TaxCurrencyCode'
                                        level='TAX_CURRENCY_CODE'
                                        options={GET_CURRENCIES_CODE_OPTIONS}
                                        rules={{
                                            required: true,
                                        }}
                                        showSearch={false}
                                        setFormValue={setFormValue}
                                        marginBottom={0}
                                        errorMessageSize='small'
                                        disabled={true}
                                    />
                                </Col>
                                {watch('DocumentCurrencyCode') &&
                                    watch('TaxCurrencyCode') &&
                                    watch('DocumentCurrencyCode') !== watch('TaxCurrencyCode') && (
                                        <Col md={12} lg={8}>
                                            <InlineGenericInput
                                                levelCol={24}
                                                inputCol={24}
                                                type='number'
                                                control={control}
                                                errors={errors}
                                                dataKey='ExchangeRate'
                                                level='EXCHANGE_RATE'
                                                allowDecimal={true}
                                                rules={{
                                                    required: true,
                                                }}
                                                marginBottom={15}
                                                defaultValue={1}
                                                errorMessageSize='small'
                                            />
                                        </Col>
                                    )}
                            </Row>
                        </div>

                        <Divider className='new-invoice-divider' />
                        <div className='new-invoice-form-inner-wrapper'>
                            <Row align='middle' gutter={20}>
                                <Col md={12} lg={8}>
                                    <InlineGenericDate
                                        control={control}
                                        levelCol={24}
                                        inputCol={24}
                                        errors={errors}
                                        dataKey='Delivery.ActualDeliveryDate'
                                        level='SUPPLY_START_DATE'
                                        setFormValue={setFormValue}
                                        rules={{
                                            required: true,
                                        }}
                                        marginBottom={15}
                                        errorMessageSize='small'
                                        disableTodayAfterDate={true}
                                        onChange={() =>
                                            setFormValue('Delivery.LatestDeliveryDate', null, {
                                                shouldValidate: true,
                                            })
                                        }
                                    />
                                </Col>

                                <Col span={8}>
                                    <InlineGenericDate
                                        levelCol={24}
                                        inputCol={24}
                                        control={control}
                                        errors={errors}
                                        dataKey='Delivery.LatestDeliveryDate'
                                        level='SUPPLY_END_DATE'
                                        setFormValue={setFormValue}
                                        marginBottom={15}
                                        rules={{
                                            required:
                                                (watch('InvoiceType') === 'STANDARD_TAX_INVOICE' ||
                                                    watch('InvoiceType') ===
                                                        'SIMPLIFIED_TAX_INVOICE') &&
                                                watch('InvoiceTypeOptions') ===
                                                    InvoiceTypeOptions.Summary,
                                        }}
                                        errorMessageSize='small'
                                        tooltip={t('ONLY_REQUIRED_FOR_CONTINUOUS_SUPPLIES')}
                                        disableDateAfter={
                                            watch('Delivery.ActualDeliveryDate')
                                                ? dayjs(
                                                      watch('Delivery.ActualDeliveryDate'),
                                                  ).subtract(0, 'day')
                                                : undefined
                                        }
                                        disableTodayAfterDate={true}
                                        allowClear
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <InvoiceLines
                        documentTaxCurrencyCode={watch('DocumentCurrencyCode')}
                        exchangeRate={watch('ExchangeRate')}
                    />

                    <div className='new-invoice-form-wrapper'>
                        <div className='new-invoice-form-inner-wrapper'>
                            <Tabs
                                defaultActiveKey='1'
                                items={[
                                    {
                                        key: '1',
                                        label: t('PAYMENT_MEANS'),
                                        children: (
                                            <PaymentMeans
                                                errors={errors}
                                                control={control}
                                                setFormValue={setFormValue}
                                                watch={watch}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <div className='new-invoice-form-wrapper'>
                        <div className='new-invoice-form-inner-wrapper'>
                            <Tabs
                                defaultActiveKey='1'
                                items={[
                                    {
                                        key: '1',
                                        label: t('PREPAYMENTS'),
                                        children: (
                                            <PrePayments
                                                errors={errors}
                                                control={control}
                                                setFormValue={setFormValue}
                                                watch={watch}
                                            />
                                        ),
                                    },
                                    {
                                        key: '2',
                                        label: t('ALLOWANCE_CHARGES'),
                                        children: (
                                            <AllowanceCharges
                                                errors={errors}
                                                control={control}
                                                setFormValue={setFormValue}
                                                watch={watch}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <div className='condition-and-attachment'>
                        <Row gutter={20} className=''>
                            <Col span={12}>
                                <div className='margin-bottom-6'>
                                    <label className='new-invoice-label'>
                                        {t('CUSTOMER_NOTES')}
                                    </label>
                                </div>
                                <Controller
                                    name='CustomerNote'
                                    render={({ field }) => (
                                        <TextArea
                                            {...field}
                                            className='general-input'
                                            placeholder={t('CUSTOMER_NOTES')}
                                            rows={2}
                                            style={{ resize: 'none' }}
                                        />
                                    )}
                                    control={control}
                                />
                            </Col>
                            <Col span={12}>
                                <div className='margin-bottom-6'>
                                    <label className='new-invoice-label'>
                                        {t('TERMS_AND_CONDITIONS')}
                                    </label>
                                </div>
                                <Controller
                                    name='TermsAndConditions'
                                    render={({ field }) => (
                                        <TextArea
                                            {...field}
                                            className='general-input'
                                            placeholder={t(
                                                'ENTER_THE_TERMS_AND_CONDITIONS_FOR_THE_BUSINESS_TO_BE_DISPLAYED_IN_YOUR_TRANSACTION',
                                            )}
                                            rows={2}
                                            style={{ resize: 'none' }}
                                        />
                                    )}
                                    control={control}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className='item-submit-action-buttons'>
                    <div></div>
                    <div className='item-submit-inner-action-buttons'>
                        <CoreButton
                            text={t('CANCEL')}
                            type='basic'
                            htmlType='button'
                            onClick={() => {
                                navigate('/e-invoices');
                            }}
                        />
                        <CoreButton
                            loading={isButtonLoading}
                            text={
                                organizationPhase === 1
                                    ? `${t('SAVE')}`
                                    : `${t('GENERATE_AND_SEND')}`
                            }
                            type='primary'
                            onClick={() => {
                                dispatch(setShowInvoiceItemRequired());
                                handleSubmit(onSubmit)();
                            }}
                        />
                    </div>
                </div>

                {pdfUrl && (
                    <PdfView
                        url={pdfUrl}
                        isOpenModal={!!pdfUrl}
                        closeModal={() => setPDFUrl(undefined)}
                    />
                )}
            </form>

            <CreateCustomerModal
                isShowModal={isShowCustomerModal}
                onResponse={onResponseCustomerModal}
            />
        </div>
    );
};

export default NewInvoice;
