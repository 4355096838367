import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IAddEntityPayload, IEntity, IEntityIdPayload } from '@models/organization.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';
import { IGenericResponse } from '@models/generic.model';

export const EntityAPI = {
    getActiveEntities: async (OrganizationId: string): Promise<IEntity[]> => {
        const url =
            APP_CONFIG.eisUrl +
            `OrganizationQuery/ActiveEntities${processQuery({ OrganizationId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return (response?.data?.Result as IEntity[]) || [];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getEntities: async (organizationId: string): Promise<IGenericResponse<IEntity[]>> => {
        const url =
            APP_CONFIG.eisUrl + `OrganizationQuery/Entities${processQuery({ organizationId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getEntity: async (entityId: string): Promise<IGenericResponse<IEntity>> => {
        const url = APP_CONFIG.eisUrl + `OrganizationQuery/Entity${processQuery({ entityId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    addEntity: async (payload: IAddEntityPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/AddEntity`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    updateEntity: async (payload: IAddEntityPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/UpdateEntity`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    removeEntity: async (payload: IEntityIdPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/RemoveEntity`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    enableEntity: async (payload: IEntityIdPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/EnableEntity`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    disableEntity: async (payload: IEntityIdPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `OrganizationCommand/DisableEntity`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
