import React from 'react';
import { CoreBackButton, CoreButton } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { useForm } from 'react-hook-form';
import './CreateUser.scss';
import { useNavigate } from 'react-router-dom';

const CreateUserName = () => {
    const navigate = useNavigate();
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<any>({
        mode: 'onChange',
    });

    const onSubmit = async () => {
        navigate('/users/set-roles');
    };

    return (
        <>
            <div className='user-initiate-wrapper'>
                <div className='user-initiate-header'>
                    <p className='user-initiate-title'>User details</p>
                    <p className='user-initiate-sub-title'>Please enter your user details.</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InlineGenericInput
                        levelCol={24}
                        inputCol={24}
                        control={control}
                        errors={errors}
                        dataKey='UserName'
                        level='User Name'
                        rules={{
                            required: true,
                        }}
                        placeHolder='User Name'
                    />
                    <div className='submit-button'>
                        <CoreButton
                            text='Next'
                            htmlType='submit'
                            type='primary'
                            isFullWidth={true}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            </div>
        </>
    );
};
export default CreateUserName;
