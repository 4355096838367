export const DEFAULT_YEAR = new Date().getFullYear().toString();


export const getYearOptions = (year: string) => {
    const yearOptions = [];
    const currentYear = parseInt(DEFAULT_YEAR);
    const createdYear = parseInt(year);

    for (let year = currentYear; year >= createdYear; year--) {
        yearOptions.push({ value: year.toString(), label: year.toString() });
    }

    return yearOptions;
}