export interface IMapField {
    key: string;
    label: string;
    isRequired: boolean;
}
//invoiceRootHeaders
export const invoiceDetailsFields: IMapField[] = [
    {
        key: 'InvoiceID',
        label: 'Invoice Id',
        isRequired: true,
    },
    {
        key: 'CustomerId',
        label: 'Customer Id',
        isRequired: false,
    },
    {
        key: 'InvoiceTypeCode',
        label: 'Invoice Type Code',
        isRequired: true,
    },
    {
        key: 'InvoiceNote',
        label: 'Invoice Note',
        isRequired: false,
    },
    {
        key: 'InvoiceType',
        label: 'Invoice Type',
        isRequired: false,
    },
    {
        key: 'OrderReferenceID',
        label: 'Order Reference Id',
        isRequired: false,
    },
    {
        key: 'ContractDocumentID',
        label: 'Contract Document Id',
        isRequired: false,
    },
    {
        key: 'DocumentCurrencyCode',
        label: 'Document Currency Code',
        isRequired: true,
    },
    {
        key: 'ExchangeRate',
        label: 'Exchange Rate',
        isRequired: false,
    },
    {
        key: 'TaxCurrencyCode',
        label: 'Tax Currency Code',
        isRequired: true,
    },
    {
        key: 'DueIn',
        label: 'Due In',
        isRequired: false,
    },
    {
        key: 'TotalAccountingTaxAmount',
        label: 'Total Accounting Tax Amount',
        isRequired: false,
    },
    {
        key: 'CustomerNote',
        label: 'Customer Note',
        isRequired: false,
    },
    {
        key: 'TermsAndConditions',
        label: 'Terms And Conditions',
        isRequired: false,
    },
    {
        key: 'PrepaidAmount',
        label: 'Prepaid Amount',
        isRequired: false,
    },
    {
        key: 'RetentionAmount',
        label: 'Retention Amount',
        isRequired: false,
    },
];
//invoiceLineHeaders
export const invoiceLineFields: IMapField[] = [
    {
        key: 'ProductId',
        label: 'Product Id',
        isRequired: false,
    },
    {
        key: 'ItemName',
        label: 'Item Name',
        isRequired: true,
    },
    {
        key: 'ItemNameEn',
        label: 'Item Name En',
        isRequired: false,
    },
    {
        key: 'Quantity',
        label: 'Quantity',
        isRequired: true,
    },
    {
        key: 'UnitCode',
        label: 'Unit Code',
        isRequired: true,
    },
    {
        key: 'TaxCategory',
        label: 'Tax Category',
        isRequired: true,
    },
    {
        key: 'TaxPercent',
        label: 'Tax Percent',
        isRequired: true,
    },
    {
        key: 'PriceAmount',
        label: 'Price Amount',
        isRequired: true,
    },
    {
        key: 'BaseQuantity',
        label: 'Base Quantity',
        isRequired: true,
    },
    {
        key: 'DiscountReason',
        label: 'Discount Reason',
        isRequired: false,
    },
    {
        key: 'DiscountReasonCode',
        label: 'Discount Reason Code',
        isRequired: false,
    },
    {
        key: 'DiscountAmount',
        label: 'Discount Amount',
        isRequired: false,
    },
    {
        key: 'DiscountMultiplierFactorNumeric',
        label: 'Discount Multiplier Factor Numeric',
        isRequired: false,
    },
    {
        key: 'DiscountBaseAmount',
        label: 'Discount Base Amount',
        isRequired: false,
    },
    {
        key: 'ChargeReason',
        label: 'Charge Reason',
        isRequired: false,
    },
    {
        key: 'ChargeReasonCode',
        label: 'Charge Reason Code',
        isRequired: false,
    },
    {
        key: 'ChargeAmount',
        label: 'Charge Amount',
        isRequired: false,
    },
    {
        key: 'ChargeMultiplierFactorNumeric',
        label: 'Charge Multiplier Factor Numeric',
        isRequired: false,
    },
    {
        key: 'ChargeBaseAmount',
        label: 'Charge Base Amount',
        isRequired: false,
    },
];

//invoicePrepaymentHeaders
export const prepaymentFields: IMapField[] = [
    {
        key: 'Prepayment_Amount',
        label: 'Prepayment Amount',
        isRequired: false,
    },
    {
        key: 'Prepayment_DocumentId',
        label: 'Prepayment Document Id',
        isRequired: false,
    },
    {
        key: 'Prepayment_IssueDateTime',
        label: 'Prepayment Issue Date Time',
        isRequired: false,
    },
    {
        key: 'Prepayment_TaxCategory',
        label: 'Prepayment Tax Category',
        isRequired: false,
    },
    {
        key: 'Prepayment_TaxPercent',
        label: 'Prepayment Tax Percent',
        isRequired: false,
    },
];

//invoiceDeliveryHeaders
export const deliveryFields: IMapField[] = [
    {
        key: 'ActualDeliveryDate',
        label: 'Actual Delivery Date',
        isRequired: true,
    },
    {
        key: 'LatestDeliveryDate',
        label: 'Latest Delivery Date',
        isRequired: false,
    },
];

//invoiceReferenceHeaders
export const creditNoteFields: IMapField[] = [
    {
        key: 'ReferenceID',
        label: 'Reference Id',
        isRequired: true,
    },
    {
        key: 'ReferenceIssueDatetime',
        label: 'Reference Issue Datetime',
        isRequired: false,
    },
    {
        key: 'ReferenceNoteReason',
        label: 'Reference Note Reason',
        isRequired: false,
    },
    {
        key: 'ReferenceNoteReasonEn',
        label: 'Reference Note Reason En',
        isRequired: false,
    },
];

//invoiceCustomerHeaders
export const customerDetailsFields: IMapField[] = [
    {
        key: 'CustomerType',
        label: 'Customer Type',
        isRequired: false,
    },
    {
        key: 'VatRegistrationNumber',
        label: 'Vat Registration Number',
        isRequired: true,
    },
    {
        key: 'LegalRegistrationName',
        label: 'Legal Registration Name',
        isRequired: true,
    },
    {
        key: 'LegalRegistrationNameEn',
        label: 'Legal Registration Name En',
        isRequired: true,
    },
    {
        key: 'CompanyRegistrationScheme',
        label: 'Company Registration Scheme',
        isRequired: true,
    },
    {
        key: 'CompanyRegistrationNumber',
        label: 'Company Registration Number',
        isRequired: true,
    },
    {
        key: 'Email',
        label: 'Email',
        isRequired: false,
    },
    {
        key: 'PhoneNumber',
        label: 'Phone Number',
        isRequired: false,
    },
    {
        key: 'Street',
        label: 'Street',
        isRequired: false,
    },
    {
        key: 'StreetEn',
        label: 'Street En',
        isRequired: false,
    },
    {
        key: 'AdditionalStreet',
        label: 'Additional Street',
        isRequired: false,
    },
    {
        key: 'AdditionalStreetEn',
        label: 'Additional Street En',
        isRequired: false,
    },
    {
        key: 'BuildingNumber',
        label: 'Building Number',
        isRequired: false,
    },
    {
        key: 'AdditionalNumber',
        label: 'Additional Number',
        isRequired: false,
    },
    {
        key: 'City',
        label: 'City',
        isRequired: false,
    },
    {
        key: 'CityEn',
        label: 'City En',
        isRequired: false,
    },
    {
        key: 'PostalCode',
        label: 'Postal Code',
        isRequired: false,
    },
    {
        key: 'ProvinceOrState',
        label: 'Province Or State',
        isRequired: false,
    },
    {
        key: 'ProvinceOrStateEn',
        label: 'Province Or State En',
        isRequired: false,
    },
    {
        key: 'District',
        label: 'District',
        isRequired: false,
    },
    {
        key: 'DistrictEn',
        label: 'District En',
        isRequired: false,
    },
    {
        key: 'CountryCode',
        label: 'Country Code',
        isRequired: false,
    },
    {
        key: 'FirstName',
        label: 'First Name',
        isRequired: false,
    },
    {
        key: 'LastName',
        label: 'Last Name',
        isRequired: false,
    },
    {
        key: 'MiddleName',
        label: 'Middle Name',
        isRequired: false,
    },
    {
        key: 'JobTitle',
        label: 'Job Title',
        isRequired: false,
    },
    {
        key: 'JobTitleEn',
        label: 'Job Title En',
        isRequired: false,
    },
    {
        key: 'FirstNameEn',
        label: 'First Name En',
        isRequired: false,
    },
    {
        key: 'LastNameEn',
        label: 'Last Name En',
        isRequired: false,
    },
    {
        key: 'MiddleNameEn',
        label: 'Middle Name En',
        isRequired: false,
    },
];

//invoiceAllowanceHeaders
export const documentLevelFields: IMapField[] = [
    {
        key: 'DocumentLevel_ChargeReason',
        label: 'Document Level Charge Reason',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_ChargeReasonCode',
        label: 'Document Level Charge Reason Code',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_ChargeAmount',
        label: 'Document Level Charge Amount',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_ChargeMultiplierFactorNumeric',
        label: 'Document Level Charge Multiplier Factor Numeric',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_ChargeBaseAmount',
        label: 'Document Level Charge Base Amount',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_ChargeTaxCategory',
        label: 'Document Level Charge Tax Category',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_ChargeTaxPercent',
        label: 'Document Level Charge Tax Percent',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_DiscountReason',
        label: 'Document Level Discount Reason',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_DiscountReasonCode',
        label: 'Document Level Discount ReasonCode',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_DiscountAmount',
        label: 'Document Level Discount Amount',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_DiscountMultiplierFactorNumeric',
        label: 'Document Level Discount Multiplier Factor Numeric',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_DiscountBaseAmount',
        label: 'DocumentLevel Discount Base Amount',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_DiscountTaxCategory',
        label: 'Document Level Discount Tax Category',
        isRequired: false,
    },
    {
        key: 'DocumentLevel_DiscountTaxPercent',
        label: 'Document Level Discount Tax Percent',
        isRequired: false,
    },
];

//invoicePaymentMeansHeaders
export const paymentMeansFields: IMapField[] = [
    {
        key: 'PaymentMeansCode',
        label: 'Payment Means Code',
        isRequired: true,
    },
    {
        key: 'InstructionNote',
        label: 'Instruction Note',
        isRequired: false,
    },
    {
        key: 'PaymentChannelCode',
        label: 'Payment Channel Code',
        isRequired: false,
    },
    {
        key: 'PaymentAccountIdentifier',
        label: 'Payment Account Identifier',
        isRequired: false,
    },
    {
        key: 'PaymentTerms',
        label: 'Payment Terms',
        isRequired: false,
    },
];

//invoiceTaxExemptHeaders
export const taxExemptionsFields: IMapField[] = [
    {
        key: 'Exemption_TaxCategory',
        label: 'Exemption Tax Ex Tax Category',
        isRequired: false,
    },
    {
        key: 'Exemption_TaxExemptionReason',
        label: 'Exemption Tax Exemption Reason',
        isRequired: false,
    },
    {
        key: 'Exemption_TaxExemptionReasonEn',
        label: 'Exemption Tax Exemption Reason En',
        isRequired: false,
    },
    {
        key: 'Exemption_TaxExemptionReasonCode',
        label: 'Exemption Tax Exemption Reason Code',
        isRequired: false,
    },
];
