import React, { useState } from 'react';
import BillingAlertDetails from './BillingAlertDetails';
import './BillingAlert.scss';
import BillingAlertEdit from './BillingAlertEdit';
export default function BillingAlert() {
    const [isEditModalOpen, setModalOpen] = useState<boolean>(false);
    const onUpdateInfo = () => {
        setModalOpen(false);
    };
    return (
        <>
            <BillingAlertDetails openEditModal={() => setModalOpen(true)} />
            <BillingAlertEdit
                isOpenModal={isEditModalOpen}
                onCancelModal={() => setModalOpen(false)}
                onUpdateAlert={onUpdateInfo}
            />
        </>
    );
}
