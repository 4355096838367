/* eslint-disable react/display-name */
import { Collapse, message, Modal } from 'antd';
import './UploadInvoiceSelect.scss';
import { useAppSelector } from '@store/hooks';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { mapConfigId, mapReferenceId } from '@store/slices/invoiceMappingSlice';
import { INVOICE_API } from '@service/invoice.service';
import { CollapseProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import UploadInvoiceCollapseView from '../upload-invoice-collapse-view/UploadInvoiceCollapseView';
import {
    creditNoteFields,
    customerDetailsFields,
    deliveryFields,
    documentLevelFields,
    invoiceDetailsFields,
    invoiceLineFields,
    paymentMeansFields,
    prepaymentFields,
    taxExemptionsFields,
} from './../../constant';
import { CoreButton } from '@packages';
import { useNavigate } from 'react-router-dom';

export interface PreviewHeaderRef {
    onSubmitPreview: () => void;
}

export interface PreviewHeaderProps {
    onNext: () => void;
    setLoading: (loading: boolean) => void;
}

const UploadInvoiceSelect = forwardRef<PreviewHeaderRef, PreviewHeaderProps>((props, ref) => {
    const { t } = useTranslation();
    const mappingPayload = useAppSelector((state) => state.invoiceMapping.mappingPayload);
    const referenceId = useAppSelector(mapReferenceId);
    const ConfigId = useAppSelector(mapConfigId);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const submitInvoices = async () => {
        const payload = {
            ConfigId: ConfigId,
            ReferenceId: referenceId,
        };

        try {
            props.setLoading && props.setLoading(true);
            const result = await INVOICE_API.submitInvoices(payload);
            props.setLoading && props.setLoading(false);
            message.success('Invoice Mapping Submitted');
            setIsModalVisible(true);
            navigate('/confirm-upload-invoice');
        } catch (error) {
            console.error('Error saving mapping:', error);
            message.error('Invoice Mapping Submission Failed');
            props.setLoading && props.setLoading(false);
            // Handle the error accordingly, e.g., show a notification to the user
        }
    };

    useImperativeHandle(ref, () => ({
        onSubmitPreview: () => {
            submitInvoices();
        },
    }));

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const collapseContent = [
        {
            key: '1',
            label: `${t('Invoice Details')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoiceRootHeaders}
                    localHeader={invoiceDetailsFields}
                />
            ),
        },
        {
            key: '2',
            label: `${t('Invoice Line Items')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoiceLineHeaders}
                    localHeader={invoiceLineFields}
                />
            ),
        },
        {
            key: '3',
            label: `${t('Prepayment')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoicePrepaymentHeaders}
                    localHeader={prepaymentFields}
                />
            ),
        },
        {
            key: '4',
            label: `${t('Delivery')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoiceDeliveryHeaders}
                    localHeader={deliveryFields}
                />
            ),
        },
        {
            key: '5',
            label: `${t('Credit Note')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoiceReferenceHeaders}
                    localHeader={creditNoteFields}
                />
            ),
        },
        {
            key: '6',
            label: `${t('Customer Details')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoiceCustomerHeaders}
                    localHeader={customerDetailsFields}
                />
            ),
        },
        {
            key: '7',
            label: `${t('Document Level Allowance')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoiceAllowanceHeaders}
                    localHeader={documentLevelFields}
                />
            ),
        },
        {
            key: '8',
            label: `${t('Payment Means')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoicePaymentMeansHeaders}
                    localHeader={paymentMeansFields}
                />
            ),
        },
        {
            key: '9',
            label: `${t('Tax Exemptions')}`,
            content: (
                <UploadInvoiceCollapseView
                    apiHeader={mappingPayload?.InvoiceTaxExemptHeaders}
                    localHeader={taxExemptionsFields}
                />
            ),
        },
    ];

    const items: CollapseProps['items'] = collapseContent.map((item) => ({
        key: item.key,
        label: item.label,
        children: <div>{item.content}</div>,
    }));

    return (
        <div className='customer-invoice-select-wrapper'>
            <Collapse
                bordered={false}
                defaultActiveKey={[]}
                expandIconPosition='end'
                items={items}
            />

            {/* <Modal
                title='Submission Successful'
                visible={isModalVisible}
                onOk={handleOk}
                footer={null}
                onCancel={handleCancel}
                closable={false}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '20px',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    
                </div>
            </Modal> */}
        </div>
    );
});

export default UploadInvoiceSelect;
