import React from 'react';
import './WhyInvoicing.scss';
import { Col, Divider, Image, Row } from 'antd';
import map from '../../assets/map.png';
import ReasonCard from '../reason-card/ReasonCard';
import UserCheck from '../../svg/UserCheck';
import NoteCheck from '../../svg/NoteCheck';
import PoundIcon from '../../svg/PoundIcon';

const WhyInvoicing = () => {
    return (
        <div className='why-invoicing-wrapper'>
            <div className='why-invoicing-inner-wrapper'>
                <div className='map-section'>
                    <Row gutter={[120, 12]} align='middle'>
                        <Col md={24} lg={12}>
                            <h1 className='why-invoicing-title'>Why is invoicing so crucial?</h1>
                            <p className='why-invoicing-sub-title '>
                                Every country has specific invoicing rules. Not meeting them leads
                                to fines, customer complaints and business risk.
                            </p>
                        </Col>
                        <Col md={24} lg={12}>
                            <Image src={map} preview={false} />
                        </Col>
                    </Row>
                </div>
                <div className='reason-card-section'>
                    <Row gutter={[24, 8]}>
                        <Col xs={24} md={12} xl={8}>
                            <ReasonCard
                                icon={<UserCheck />}
                                title='Avoid penalties, unnecessary compliance costs and audit risk.'
                            />
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <ReasonCard
                                icon={<NoteCheck />}
                                title='Remove the need to build and maintain your own local tax templates and technology.'
                            />
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <ReasonCard
                                icon={<PoundIcon />}
                                title='Make it easy for your business customers to access accurate tax invoices and reclaim indirect taxes on their purchase.'
                            />
                        </Col>
                    </Row>
                </div>
                <Divider dashed className='divider-border' />
                <div className='footer-section'>
                    <p>
                        E-Invoice solution allows you to automatically generate tax compliant
                        invoices across all your markets as well as credit notes, third-party
                        billing and self-billing invoices.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WhyInvoicing;
