import { useState } from 'react';
import { Col, Row } from 'antd';
import TemplateCreate from '../../templates/template-create/TemplateCreate';
import TemplateItem from '../../templates/template-item/TemplateItem';
import { CoreTitle } from '@packages';
import { useTranslation } from 'react-i18next';
import './TemplateList.scss';

type ListItem = string;

const TemplateList = () => {
    const { t } = useTranslation();
    const items: ListItem[] = [
        t('QUOTES'),
        t('DELIVERY_CHALLANS'),
        t('INVOICES'),
        t('CREDITED_NOTES'),
        t('PAYMENT_RECEIPTS'),
        t('CUSTOMER_STATEMENT'),
        t('BILLS'),
        t('VENDOR_CREDITS'),
        t('VENDOR_PAYMENT'),
        t('VENDOR_STATEMENt'),
        t('JOURNALS'),
    ];

    const [activeItem, setActiveItem] = useState<ListItem>('Invoices');

    const handleItemClick = (item: ListItem) => {
        setActiveItem(item);
    };

    return (
        <div className='template-list-wrapper'>
            <Row>
                <Col span={4}>
                    <div className='templates-category'>
                        <CoreTitle tag='h4' text={t('TEMPLATES')} />

                        <div className='templates-category-list'>
                            <ul>
                                {items.map((item, index) => (
                                    <li key={index}>
                                        <div
                                            className={`list-item-name ${
                                                item === activeItem ? 'active-list-item-name' : ''
                                            }`}
                                            onClick={() => handleItemClick(item)}
                                        >
                                            {item}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col span={20}>
                    <div className='templates-cards-heading'>
                        <CoreTitle tag='h4' text={t('INVOICE_TEMPLATES')} />
                    </div>
                    <div className='templates-cards-wrapper'>
                        <Row gutter={16} className='templates-cards'>
                            <Col span={12} xl={8} xxl={6} className='margin-bottom-20'>
                                <TemplateCreate />
                            </Col>
                            <Col span={12} xl={8} xxl={6} className='margin-bottom-20'>
                                <TemplateItem />
                            </Col>
                            <Col span={12} xl={8} xxl={6} className='margin-bottom-20'>
                                <TemplateItem />
                            </Col>
                            <Col span={12} xl={8} xxl={6} className='margin-bottom-20'>
                                <TemplateItem />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default TemplateList;
