import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { RoleAPI } from '@service/role.service';
import { getPolicies, selectPolicyOptions } from '@store/slices/policySlice';
import { getAvailableOrganizations } from '@store/slices/organizationSlice';
import { ICreateRolePayload } from '@models/role.model';
interface ICreateRoleProps {
    onPageRefresh: () => void;
}
const CreateRole = ({ onPageRefresh }: ICreateRoleProps) => {
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [isModalLoading, setModalLoading] = useState(false);
    const policyOptions = useAppSelector(selectPolicyOptions);

    const dispatch = useAppDispatch();

    useEffect(() => {}, []);

    const {
        control,
        handleSubmit,
        reset,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<any>();

    const onSubmit = async (data: any) => {
        const payload: ICreateRolePayload = {
            RoleName: data.RoleName,
            Policies: [],
        };

        data?.Policies?.forEach((policyName: any) => {
            policyOptions.data.forEach((policy: any) => {
                if (policyName === policy.PolicyName) {
                    payload.Policies.push({
                        PolicyName: policy.PolicyName,
                        ManagedBy: policy.ManagedBy,
                    });
                }
            });
        });

        try {
            setModalLoading(true);
            await RoleAPI.createRole(payload);
            message.success('Role Created Successfully');
            setIsActionModalOpen(false);
            setModalLoading(false);
            onPageRefresh();
        } catch {
            setModalLoading(false);
            message.error('Role Create Failed');
        }
    };

    const handleOkForPolicy = () => {
        handleSubmit(onSubmit)();
    };

    const handleCancelForAction = () => {
        setIsActionModalOpen(false);
        reset();
    };

    const getAllInitialData = () => {
        dispatch(
            getPolicies({
                PageLimit: 100,
            }),
        );
        dispatch(getAvailableOrganizations());
    };

    useEffect(() => {
        getAllInitialData();
    }, []);

    return (
        <>
            <CoreButton
                type='primary'
                text='Create Role'
                isFullWidth={true}
                onClick={() => setIsActionModalOpen(true)}
            />

            <Modal
                width={500}
                open={isActionModalOpen}
                onOk={handleOkForPolicy}
                okText='Create'
                onCancel={handleCancelForAction}
                maskClosable={false}
                closable={false}
                confirmLoading={isModalLoading}
            >
                <form onSubmit={handleSubmit(handleOkForPolicy)}>
                    <div className='add-user-title'>
                        <CoreTitle tag='h5' text={'Create Role'} />
                    </div>
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='RoleName'
                        level='Role Name'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />
                    <InlineGenericSelect
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Policies'
                        level='Policies'
                        loading={policyOptions.isLoading}
                        options={policyOptions.data}
                        marginBottom={5}
                        setFormValue={setFormValue}
                        rules={{
                            required: true,
                        }}
                        isMultipleSelect={true}
                    />
                </form>
            </Modal>
        </>
    );
};
export default CreateRole;
