import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { UtcToLocalDate } from '@utils/date';
import { DeleteOutlined } from '@ant-design/icons';
import { CoreButton, CoreSearchInput } from '@packages';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './UserList.scss';
import React, { useEffect } from 'react';
import { getRoles } from '@store/slices/roleSlice';
import { useAppDispatch } from '@store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { getUsers } from '@store/slices/userSlice';

function UserList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useSelector((state: RootState) => state.user.users);
    const navigate = useNavigate();
    const columns: ColumnsType<any> = [
        {
            title: 'User Name',
            dataIndex: 'UserName',
        },
        {
            title: 'Pass Age',
            dataIndex: 'PassAge',
        },
        {
            title: 'Last Sign In Date',
            dataIndex: 'LastLoginTime',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        // {
        //     title: 'Access Key Id',
        //     dataIndex: 'AccessKeyId',
        // },
        // {
        //     title: 'Active Key Age',
        //     dataIndex: 'AccessKeyAge',
        // },
        {
            title: '',
            dataIndex: 'UserId',
            render: (UserId: string) => (
                <div className='action-buttons'>
                    <div className='invite-client-icon'>
                        <DeleteOutlined />
                    </div>
                </div>
            ),
        },
    ];
    const userList = [
        {
            UserName: 'Salman',
            PassAge: '27',
            LastSignInDate: new Date(),
            AccessKeyId: '85698',
            AccessKeyAge: '26',
        },
        {
            UserName: 'Joy',
            PassAge: '27',
            LastSignInDate: new Date(),
            AccessKeyId: '85698',
            AccessKeyAge: '26',
        },
        {
            UserName: 'Mujammel',
            PassAge: '27',
            LastSignInDate: new Date(),
            AccessKeyId: '85698',
            AccessKeyAge: '26',
        },
    ];

    useEffect(() => {
        dispatch(
            getUsers({
                PageLimit: 20,
            }),
        );
    }, []);

    return (
        <>
            <div className='filter-header margin-30'>
                <CoreSearchInput placeholder='Search' />
                <div className='create-button-group'>
                    <CoreButton
                        type='primary'
                        text='Create User'
                        isFullWidth={true}
                        onClick={() => navigate(`/users/create-name`)}
                    />
                </div>
            </div>
            <div className='ksa-table-wrapper table-auto-scroll-without-header'>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowClassName='cursor-pointer'
                    pagination={false}
                />
            </div>
        </>
    );
}

export default UserList;
