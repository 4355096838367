import { CoreButton } from '@packages';

interface IAddressDetailsProps {
    openEditBillingModal: () => void;
    openEditShippingModal: () => void;
}
export default function BillingAddressDetails({
    openEditBillingModal,
    openEditShippingModal,
}: IAddressDetailsProps) {
    return (
        <div className='details-content card-container'>
            <div className='header-address'>
                <div className='bold-title'>Billing Address</div>
                <CoreButton
                    type='basic'
                    text='Edit Billing Address'
                    size='small'
                    onClick={openEditBillingModal}
                />
            </div>
            <div className='address-content'>
                <div style={{ marginBottom: 10 }}>
                    This address appears on your monthly invoice and should be legal address of your
                    home and business
                </div>
                <div style={{ marginBottom: 20 }}>28/B, Sylhet, Dhaka, Bangladesh</div>
            </div>

            <div className='header-address'>
                <div className='bold-title'>Shipping Address</div>
                <CoreButton
                    type='basic'
                    text='Edit Shipping Address'
                    size='small'
                    onClick={openEditShippingModal}
                />
            </div>

            <div className='address-content'>
                <div style={{ marginBottom: 10 }}>
                    This address appears on your monthly invoice and should be legal address of your
                    home and business
                </div>
                <div style={{ marginBottom: 20 }}>28/B, Sylhet, Dhaka, Bangladesh</div>
            </div>

            <div className='tax-content'>
                <div className='bold-title' style={{ marginBottom: 16 }}>
                    Tax Location
                </div>
                <div style={{ marginBottom: 16 }}>
                    Taxes are not collected for your location at this time
                </div>
                <div style={{ marginBottom: 2 }}>
                    Your tax location determines the taxes that are applied to your bill
                </div>
                <div className='location-help primary-color'>How do I correct my tax location?</div>
            </div>
        </div>
    );
}
