import './CoreContactUs.scss';
import { SubscribeLayoutWrapper } from '@components';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, Col, Image, Input, message, Row } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import dotTop_img from "@assets/images/dotTop_img.png";
import contact_us from "@assets/images/contact_us.png";
import dotBottom_img from "@assets/images/dotBottom_img.png";
import React, { useState } from "react";
import { RegexValidators } from "@config/regex-validator-constant";
import { ContactUsAPI } from "@service/contactUs.service";
import { NavLink } from "react-router-dom";
import { checkNumberInput } from "@utils/checkInputType";


interface IContactUs {
    Name: string;
    CompanyName: string;
    Phone: string;
    Email: string;
    Message: string;
    Checkbox: boolean;
}

interface IContactUsProps {
    coreImageSrc?: string,
}

const { TextArea } = Input;

const CoreContactUs = (props: IContactUsProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { coreImageSrc } = props;
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<IContactUs>({ mode: 'onChange' });


    const onSubmit = async (data: IContactUs) => {
        try {
            setLoading(true);
            const response = await ContactUsAPI.submitContactUs(data);
            setLoading(false);
            message.success('Thank you for scheduling a demo with us. Our team is ready to provide you with an insightful session. You\'ll receive the access link and confirmation details shortly. We look forward to showcasing our e-invoice management solutions and addressing any questions you may have');
            reset()
        } catch {
            setLoading(false);
            message.error('Support Request Failed');
        }
    };

    return (
        <SubscribeLayoutWrapper>
            <div className='contact-us-wrapper'>
                <div className="contact-us-content">
                    <div className='contact-us-form'>
                        <p className='contact-us-header'>Seamless Invoicing <br/>
                            Solutions for a <br/>
                            Digital Future</p>

                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Name</label>
                                <Controller
                                    render={({ field }) => (
                                        <Input {...field} placeholder='Full Name' className='general-input' />
                                    )}
                                    control={control}
                                    name='Name'
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.Name?.type === 'required' && (
                                    <span className='error-message'>Please enter your Full Name</span>
                                )}
                            </div>

                            <div className='general-input-wrapper'>
                                <label className='general-label'>Company Name</label>
                                <Controller
                                    render={({ field }) => (
                                        <Input {...field} placeholder='Company Name'
                                               className='general-input' />
                                    )}
                                    control={control}
                                    name='CompanyName'
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.CompanyName?.type === 'required' && (
                                    <span className='error-message'>Please enter your Company Name</span>
                                )}
                            </div>

                            <div className='general-input-wrapper'>
                                <label className='general-label'>Phone Number</label>
                                <Controller
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            onKeyPress={checkNumberInput}
                                            placeholder='Phone Number'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    name='Phone'
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.Phone?.type === 'required' && (
                                    <span className='error-message'>Please enter your phone number</span>
                                )}
                            </div>

                            <div className='general-input-wrapper'>
                                <label className='general-label'>Email</label>
                                <Controller
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            placeholder='Email'
                                            className='general-input'
                                        />
                                    )}
                                    control={control}
                                    name='Email'
                                    rules={{
                                        required: true,
                                        pattern: RegexValidators.EMAIL
                                    }}
                                />
                                {errors?.Email?.type === 'required' && (
                                    <span className='error-message'>Email is required</span>
                                )}
                                {errors?.Email?.type === 'pattern' && (
                                    <span className='error-message'>Please entera valid email address</span>
                                )}
                            </div>

                            <div className='general-input-wrapper'>
                                <label className='general-label'>Message</label>
                                <Controller
                                    render={({ field }) => (
                                        <TextArea
                                            {...field}
                                            className='general-input'
                                            placeholder='Message'
                                            rows={4}
                                            style={{ height: '153.14px', resize: 'none' }}
                                        />
                                    )}
                                    control={control}
                                    name='Message'
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.Message?.type === 'required' && (
                                    <span className='error-message'>Message is required</span>
                                )}
                            </div>

                            <div className='general-input-wrapper'>
                                <Controller
                                    render={({ field }) => (
                                        <Checkbox className='general-checkbox' {...field} checked={field.value}>
                                            By submitting this form, you agree to allow representatives of Hash
                                            Solution to contact you for providing demonstration of our product.
                                        </Checkbox>
                                    )}
                                    control={control}
                                    name='Checkbox'
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.Checkbox?.type === 'required' && (
                                    <span className='error-message'>You must agree to the term</span>
                                )}
                            </div>


                            <CoreButton text='Book a Demo' type='primary' htmlType='submit'
                                        isFullWidth={true} loading={loading} />


                        </form>
                        <NavLink to='/dashboard' className='go-back-button'>Go Back</NavLink>
                    </div>

                </div>
                <div className="contact-us-layout-images">
                    {coreImageSrc ? (
                        <>
                            <div className='contact-us-image'>
                                <Image width="807px" height="714px" src={coreImageSrc} preview={false} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="dot-image top">
                                <Image src={dotTop_img} preview={false} />
                            </div>
                            <div className='contact-us-image'>
                                <Image src={contact_us} preview={false} />
                            </div>
                            <div className="dot-image bottom">
                                <Image src={dotBottom_img} preview={false} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </SubscribeLayoutWrapper>
    );
};

export default CoreContactUs;
