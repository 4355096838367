import './OrganizationSetupCompleted.scss';
import { Image } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import featured_icon from '@assets/images/featured_icon.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OrganizationSetupCompleted = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate('/dashboard');
    };

    return (
        <div className='completed-wrapper'>
            <div className='completed-wrapper-content'>
                <Image src={featured_icon} preview={false} />
                <div>
                    <CoreTitle className='core-title-h1' tag='h1' text={t('CONGRATULATIONS')} />
                    <p className='core-login-sub-title'>
                        {t('YOU_HAVE_NOW_SUCCESSFULLY_ADDES_YOUR_ORGANIZATION')}
                    </p>
                </div>
                <div className='completed-button'>
                    <CoreButton
                        type='primary'
                        onClick={onClickHandler}
                        isFullWidth={true}
                        text={t('GO_TO_DASHBOARD')}
                    />
                </div>
            </div>
        </div>
    );
};

export default OrganizationSetupCompleted;
