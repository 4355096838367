import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { ISetup2FAResponse, Setup2FAResult } from '@models/setup-2FA.model';

export const SETUP_2FA = {
    enable2FA: async (): Promise<Setup2FAResult> => {
        const url = APP_CONFIG.identityUrl + `TwoFactorAuthentication/InitiateSetup`;
        try {
            const response = await AXIOS_INSTANCE.post<ISetup2FAResponse>(url, { App: 'app' });
            return response.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    OtpValidation: async (code: string): Promise<{ success: boolean; error?: string }> => {
        const url = APP_CONFIG.identityUrl + `TwoFactorAuthentication/Verify`;
        try {
            const response = await AXIOS_INSTANCE.post<ISetup2FAResponse>(url, {
                App: 'app',
                OTP: code,
            });
            return { success: true };
        } catch (error) {
            const err = error as AxiosError<any>;
            return { success: false, error: err.response?.data?.Message as string };
        }
    },
    disable2FA: async (): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `TwoFactorAuthentication/Disable`;
        try {
            const response = await AXIOS_INSTANCE.post<ISetup2FAResponse>(url, { App: 'app' });
            return { success: true };
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
