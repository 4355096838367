import PlanCard from '../plan-card/PlanCard';
import './PriceCard.scss';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { useEffect, useState } from 'react';
import {
    getTiers,
    selectCurrentSubscriptions,
    selectUpdatedTiredData,
    subscribe,
} from '@store/slices/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useNavigate } from 'react-router-dom';
import { CoreButton } from '@packages';

export interface IAnnualPriceProps {
    type: 'month' | 'year';
}

export default function PriceCard({ type }: IAnnualPriceProps) {
    const dispatch = useAppDispatch();
    const tiers = useAppSelector(selectUpdatedTiredData(type));
    const navigate = useNavigate();
    const { TierId } = useAppSelector(selectCurrentSubscriptions) || {};
    const { subscription } = useAppSelector(subscribe);
    const [radioValue, setRadioValue] = useState(
        subscription.Recurring === type ? TierId || '' : '',
    );

    useEffect(() => {
        dispatch(getTiers());
    }, []);

    const onChange = (e: RadioChangeEvent) => {
        setRadioValue(e.target.value);
    };

    return (
        <div className='annual-plan-wrapper'>
            <Row>
                <Radio.Group onChange={onChange} value={radioValue}>
                    {tiers?.length &&
                        tiers.map((data) => (
                            <Col span={24} key={data.ItemId} style={{ marginBottom: '16px' }}>
                                <PlanCard
                                    title={data?.Name}
                                    subtitle={data?.Description}
                                    price={`${data?.Currency} ${
                                        type === 'month' ? data.MonthlyPrice : data.YearlyPrice
                                    }`}
                                    period={`${type === 'month' ? 'mo' : 'ye'}`}
                                    radioValue={radioValue || ''}
                                    value={data.ItemId}
                                />
                            </Col>
                        ))}
                </Radio.Group>

                <Col span={24}>
                    <CoreButton
                        type='primary'
                        text='Select Plan'
                        isFullWidth={true}
                        onClick={() => {
                            navigate(
                                `/subscribe/manage-subscription?id=${radioValue}&type=${type}`,
                            );
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
