import { IInvoiceLinesItem } from '@models/invoice.model';
import { UNIT_CODE_OPTIONS } from '@utils/unitCodeOptions';
import { Select } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IUnitSelect {
    data: IInvoiceLinesItem;
    updateInvoiceLine: (itemId: string) => (object: Partial<IInvoiceLinesItem>) => void;
    isRequiredShow: boolean;
}
const UnitSelect: FC<IUnitSelect> = ({ data, updateInvoiceLine, isRequiredShow }) => {
    const { t } = useTranslation();
    const [visibleTag, setVisibleTag] = useState(false);
    return (
        <Select
            value={data?.UnitCode}
            mode='tags'
            className={`unit-code remove-dropdown ${isRequiredShow && !data?.UnitCode && 'required-border'
                }`}
            placeholder={t('SELECT_UNIT')}
            options={UNIT_CODE_OPTIONS}
            onChange={(values) => {
                const lastItem = values.slice(-1)[0]?.trim();
                if (lastItem?.length > 0) {
                    updateInvoiceLine(data.SerialId)({ UnitCode: lastItem });
                } else {
                    updateInvoiceLine(data.SerialId)({ UnitCode: undefined });
                }
                setVisibleTag(false);
            }}
            onDropdownVisibleChange={setVisibleTag}
            open={visibleTag}
        />
    );
};

export default UnitSelect;
