import { CoreButton } from '@packages';
import React, { useState } from 'react';
import './BillingHeader.scss';
import MakePayment from '../make-payment/MakePayment';
import AddPayment from '../add-payment/AddPayment';
export default function BillingHeader() {
    const [isMakePaymentModalOpen, setMakePaymentModalOpen] = useState<boolean>(false);
    const [isAddPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);

    return (
        <>
            <div className='note'>
                Note: Information of this page is updated daily. Last updated December 11,2023 at
                04:36 AM UTC
            </div>

            <div className='billing-header'>
                <div className='title'>Billing</div>
                <div className='right-side'>
                    <CoreButton
                        className='submit-button margin-right-10'
                        type='primary'
                        text={'Add Payment Method'}
                        isFullWidth={true}
                        size='small'
                        onClick={() => setAddPaymentModalOpen(true)}
                    />
                    <CoreButton
                        className='submit-button'
                        type='primary'
                        text={'Make a Payment'}
                        isFullWidth={true}
                        size='small'
                        onClick={() => setMakePaymentModalOpen(true)}
                    />
                </div>
            </div>

            <MakePayment
                isOpenModal={isMakePaymentModalOpen}
                onCancelModal={() => setMakePaymentModalOpen(false)}
            />

            <AddPayment
                isOpenModal={isAddPaymentModalOpen}
                onCancelModal={() => setAddPaymentModalOpen(false)}
            />
        </>
    );
}
