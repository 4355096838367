import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { UtcToLocalDate } from '@utils/date';
import { CoreStatus } from '@packages';
import React from 'react';

interface IOpenTicketTableData {
    key: React.Key;
    Name: string;
    CreatedAt: string;
    LastActivity: string;
    IsActive: boolean;
}

const OpenTickets = () => {
    const columns: ColumnsType<IOpenTicketTableData> = [
        {
            title: 'Name',
            dataIndex: 'Name',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Created',
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            title: 'Last Activity',
            dataIndex: 'LastActivity',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? 'Active' : 'Inactive'}
                />
            ),
        },
    ];

    const openTickets = [
        {
            key: '1',
            Name: '[#12132] Credit card charged',
            CreatedAt: '2023-11-16T16:03:27.866869405Z',
            LastActivity: '2023-11-16T16:03:27.866869405Z',
            IsActive: false,
        },
        {
            key: '2',
            Name: '[#12132] Important notice',
            CreatedAt: '2023-11-16T16:03:27.866869405Z',
            LastActivity: '2023-11-16T16:03:27.866869405Z',
            IsActive: true,
        },
    ];

    return (
        <div className='ksa-table-wrapper'>
            <Table
                columns={columns}
                dataSource={openTickets}
                rowClassName='cursor-pointer'
                pagination={false}
                scroll={{ x: 240 }}
            />
        </div>
    );
};

export default OpenTickets;
