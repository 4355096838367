import { CoreBackButton, CoreButton, CoreTitle } from '@packages';
import './MyTicket.scss';
import { Tabs, TabsProps } from 'antd';
import { OpenTickets } from '../../index';
import { ClosedTickets } from '../../index';

const MyTicket = () => {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Open Tickets',
            children: <OpenTickets />,
        },
        {
            key: '2',
            label: 'Closed Tickets',
            children: <ClosedTickets />,
        },
    ];

    return (
        <div className='my-ticket-wrapper'>
            <CoreBackButton />
            <div className='my-ticket-header'>
                <CoreTitle tag={'h3'} text='My Tickets' />
                <CoreButton
                    type='primary'
                    text='Create New Ticket'
                    linkTo={'/support/create-ticket'}
                    size='small'
                />
            </div>
            <p>
                If you don't see your tickets, use the menu in the upper right to switch to the
                correct team or account
            </p>
            <div className='round-box-shadow top-tab-wrapper'>
                <Tabs defaultActiveKey='1' className='tabs-custom-content' items={items} />
            </div>
        </div>
    );
};
export default MyTicket;
