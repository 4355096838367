import React from 'react';
import { CoreAuthConfirmation } from '@packages';

const ResetPasswordConfirmation = () => {
    return (
        <CoreAuthConfirmation
            title='Congratulation!'
            description='Your password has been changed successfully.'
            linkTo='login'
        />
    );
};

export default ResetPasswordConfirmation;