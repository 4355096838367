import React, { useState } from 'react';
import './BillingCard.scss';
import { CoreButton } from '@packages';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BillingActionModal from '../billing-action-modal/BillingActionModal';

interface IBillingCardProps {
    addressName: string;
    addressDetails: string;
    addressId: number;
    isDefault: boolean;
}

const BillingCard = ({ addressName, addressDetails, addressId, isDefault }: IBillingCardProps) => {
    const [isDeleteModalVisible, setDeleteIsModalVisible] = useState<boolean>(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);

    const handleDelateConfirm = () => {
        console.log('delete confirmed clicked');
        setDeleteIsModalVisible(false);
    };

    const handleUpdateConfirm = () => {
        console.log('update confirmed clicked');
        setIsUpdateModalVisible(false);
    };

    return (
        <div className='billing-card-wrapper round-box-shadow' key={addressId}>
            <div className='billing-card-info'>
                <p className='address-name'>{addressName}</p>
                <p className='address-details'>{addressDetails}</p>
            </div>
            <div className='billing-card-actions'>
                {isDefault ? (
                    <CoreButton
                        size='small'
                        text='Default'
                        type='danger'
                        className='default-button'
                    />
                ) : (
                    <CoreButton
                        size='small'
                        text='Set as Default'
                        antType='link'
                        onClick={() => setIsUpdateModalVisible(true)}
                    />
                )}
                <EditOutlined className='update-icon' />
                <DeleteOutlined
                    className='delete-icon'
                    onClick={() => setDeleteIsModalVisible(true)}
                />
            </div>

            <BillingActionModal
                title='Are you sure you want to delete this address?'
                subTitle='This address will be deleted.'
                isVisible={isDeleteModalVisible}
                onClose={() => setDeleteIsModalVisible(false)}
                onConfirm={handleDelateConfirm}
                actionType='Delete'
                addressName={addressName}
                addressDetails={addressDetails}
            />

            <BillingActionModal
                title='Update default card'
                subTitle='This address will be used by default for billing.'
                isVisible={isUpdateModalVisible}
                onClose={() => setIsUpdateModalVisible(false)}
                onConfirm={handleUpdateConfirm}
                actionType='Update'
                addressName={addressName}
                addressDetails={addressDetails}
            />
        </div>
    );
};

export default BillingCard;
