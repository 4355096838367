import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    ICustomerSummary,
    IDailyChartCounts,
    IDashboardCharts,
    IDashboardChips,
    IDashboardData,
    IDataQualitySummary,
    IInvoiceTypeCount,
    IMonthlyStats,
    IProcessingSummary,
    IRejectionSummary,
    ITopCustomer,
    ITotalCount,
    ITotalInvoicesInterfaced,
    ITotalInvoicesYetToBeSubmitted,
    ITotalTaxAmount,
    ITotalTaxCodeOverview,
    ITypesOfGeneratedInvoices,
} from '@models/dashboard.model';
import { DashboardAPI } from '@service/dashboard.service';
import { RootState } from '@store/store';
import { propagateCounts } from '@utils/calculateInvoiceSummary';
import { initialCurrentDate, initialPreviousDate } from '@utils/date';

interface IDashboardState {
    InvoiceTypeCounts: {
        isLoading: boolean;
        data: IInvoiceTypeCount[] | [];
    };
    TopCustomers: {
        isLoading: boolean;
        data: ITopCustomer[] | [];
    };
    TotalCounts: {
        isLoading: boolean;
        data: ITotalCount | undefined;
    };
    MonthlyStats: {
        isLoading: boolean;
        data: IMonthlyStats | undefined;
    };
    TotalTaxCodeOverview: {
        isLoading: boolean;
        data: ITotalTaxCodeOverview | undefined;
    };
    dataQualitySummary: {
        isLoading: boolean;
        data: IDataQualitySummary | undefined;
    };
    customerSummary: {
        isLoading: boolean;
        data: ICustomerSummary | undefined;
    };
    totalInvoicesInterfaced: {
        isLoading: boolean;
        data: ITotalInvoicesInterfaced | undefined;
    };
    totalTaxAmount: {
        isLoading: boolean;
        data: ITotalTaxAmount | undefined;
    };
    typesOfGeneratedInvoices: {
        isLoading: boolean;
        data: ITypesOfGeneratedInvoices | undefined;
    };
    totalInvoicesYetToBeSubmitted: {
        isLoading: boolean;
        data: ITotalInvoicesYetToBeSubmitted | undefined;
    };
    dashboardData: {
        isLoading: boolean;
        data: IDashboardData | undefined;
    };
    dashboardCharts: {
        isLoading: boolean;
        data: IDashboardCharts | undefined;
    };
    dashboardChips: {
        isLoading: boolean;
        data: IDashboardChips | undefined;
    };
    processingSummary: {
        isLoading: boolean;
        data: IProcessingSummary[] | [];
    };
    filter: {
        From: string;
        To: string;
    };
    rejectionSummary: {
        isLoading: boolean;
        data: IRejectionSummary | undefined;
    };
}

const initialState: IDashboardState = {
    InvoiceTypeCounts: {
        isLoading: false,
        data: [],
    },

    TopCustomers: {
        isLoading: false,
        data: [],
    },

    TotalCounts: {
        isLoading: false,
        data: undefined,
    },

    MonthlyStats: {
        isLoading: false,
        data: undefined,
    },

    TotalTaxCodeOverview: {
        isLoading: false,
        data: undefined,
    },
    dataQualitySummary: {
        isLoading: false,
        data: undefined,
    },
    customerSummary: {
        isLoading: false,
        data: undefined,
    },
    totalInvoicesInterfaced: {
        isLoading: false,
        data: undefined,
    },
    totalTaxAmount: {
        isLoading: false,
        data: undefined,
    },
    typesOfGeneratedInvoices: {
        isLoading: false,
        data: undefined,
    },
    totalInvoicesYetToBeSubmitted: {
        isLoading: false,
        data: undefined,
    },
    dashboardData: {
        isLoading: false,
        data: undefined,
    },
    dashboardCharts: {
        isLoading: false,
        data: undefined,
    },
    dashboardChips: {
        isLoading: false,
        data: undefined,
    },
    processingSummary: {
        isLoading: false,
        data: [],
    },
    filter: {
        From: initialPreviousDate,
        To: initialCurrentDate,
    },
    rejectionSummary: {
        isLoading: false,
        data: undefined,
    },
};

export const getInvoiceTypeCounts = createAsyncThunk('dashboard/invoiceTypeCount', async () => {
    try {
        const response = await DashboardAPI.getInvoiceTypeCounts();
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});
export const getTopCustomers = createAsyncThunk('dashboard/topCustomers', async () => {
    try {
        const response = await DashboardAPI.getTopCustomers();
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});
export const getTotalCounts = createAsyncThunk('dashboard/totalCounts', async () => {
    try {
        const response = await DashboardAPI.getTotalCounts();
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});
export const getMonthlyStats = createAsyncThunk('dashboard/monthlyStats', async (year: string) => {
    try {
        const response = await DashboardAPI.getMonthlyStats(year);
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});
export const getTaxCodeOverview = createAsyncThunk(
    'dashboard/taxCodeOverview',
    async (payload: object) => {
        try {
            const response = await DashboardAPI.getTaxCodeOverview(payload);
            return response;
        } catch (error: any) {
            console.error(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getDataQualitySummary = createAsyncThunk(
    'dashboard/dataQualitySummary',
    async (payload: object) => {
        try {
            const response = await DashboardAPI.getDataQualitySummary(payload);
            return response;
        } catch (error: any) {
            console.error(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getCustomerSummary = createAsyncThunk('dashboard/customerSummary', async () => {
    try {
        const response = await DashboardAPI.getCustomerSummary();
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});

export const getTotalInvoicesInterfaced = createAsyncThunk(
    'dashboard/totalInvoicesInterfaced',
    async () => {
        try {
            const response = await DashboardAPI.getTotalInvoicesInterfaced();
            return response;
        } catch (error: any) {
            console.error(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getTotalTaxAmount = createAsyncThunk('dashboard/totalTaxAmount', async () => {
    try {
        const response = await DashboardAPI.getTotalTaxAmount();
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});

export const getTypesOfGeneratedInvoices = createAsyncThunk(
    'dashboard/typesOfGeneratedInvoices',
    async (payload: object) => {
        try {
            const response = await DashboardAPI.getTypesOfGeneratedInvoices(payload);
            return response;
        } catch (error: any) {
            console.error(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getTotalInvoicesYetToBeSubmitted = createAsyncThunk(
    'dashboard/totalInvoicesYetToBeSubmitted',
    async () => {
        try {
            const response = await DashboardAPI.getTotalInvoicesYetToBeSubmitted();
            return response;
        } catch (error: any) {
            console.error(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getDashboardData = createAsyncThunk('dashboard/getData', async (payload: object) => {
    try {
        const response = await DashboardAPI.getDashboardData(payload);
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});

export const getDashboardCharts = createAsyncThunk(
    'dashboard/getCharts',
    async (payload: object) => {
        try {
            const response = await DashboardAPI.getDashboardCharts(payload);
            return response;
        } catch (error: any) {
            console.error(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getDashboardChips = createAsyncThunk('dashboard/getChips', async (payload: object) => {
    try {
        const response = await DashboardAPI.getDashboardChips(payload);
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});

export const getProcessingSummary = createAsyncThunk(
    'dashboard/getProcessingSummary',
    async (payload: object) => {
        try {
            const response = await DashboardAPI.getProcessingSummary(payload);
            return response;
        } catch (error: any) {
            console.error(error);
            throw error?.response?.Message || error?.message;
        }
    },
);
export const getRejectionSummary = createAsyncThunk(
    'dashboard/getRejectionSummary',
    async (payload: object) => {
        try {
            const response = await DashboardAPI.getRejectionSummary(payload);
            return response;
        } catch (error: any) {
            console.error(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setFilter: (state: any, action) => {
            state.filter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInvoiceTypeCounts.pending, (state) => {
            state.InvoiceTypeCounts.isLoading = true;
        });
        builder.addCase(getInvoiceTypeCounts.fulfilled, (state, action) => {
            state.InvoiceTypeCounts.isLoading = false;
            state.InvoiceTypeCounts.data = action.payload;
        });
        builder.addCase(getInvoiceTypeCounts.rejected, (state) => {
            state.InvoiceTypeCounts.isLoading = false;
        });
        builder.addCase(getTopCustomers.pending, (state) => {
            state.TopCustomers.isLoading = true;
        });
        builder.addCase(getTopCustomers.fulfilled, (state, action) => {
            state.TopCustomers.isLoading = false;
            state.TopCustomers.data = action.payload;
        });
        builder.addCase(getTopCustomers.rejected, (state) => {
            state.TopCustomers.isLoading = false;
        });
        builder.addCase(getTotalCounts.pending, (state) => {
            state.TotalCounts.isLoading = true;
        });
        builder.addCase(getTotalCounts.fulfilled, (state, action) => {
            state.TotalCounts.isLoading = false;
            state.TotalCounts.data = action.payload;
        });
        builder.addCase(getTotalCounts.rejected, (state) => {
            state.TotalCounts.isLoading = false;
        });
        builder.addCase(getMonthlyStats.pending, (state) => {
            state.MonthlyStats.isLoading = true;
        });
        builder.addCase(getMonthlyStats.fulfilled, (state, action) => {
            state.MonthlyStats.isLoading = false;
            state.MonthlyStats.data = action.payload;
        });
        builder.addCase(getMonthlyStats.rejected, (state) => {
            state.MonthlyStats.isLoading = false;
        });

        builder.addCase(getTaxCodeOverview.pending, (state) => {
            state.TotalTaxCodeOverview.isLoading = true;
        });
        builder.addCase(getTaxCodeOverview.fulfilled, (state, action) => {
            state.TotalTaxCodeOverview.isLoading = false;
            state.TotalTaxCodeOverview.data = action.payload;
        });
        builder.addCase(getTaxCodeOverview.rejected, (state) => {
            state.TotalTaxCodeOverview.isLoading = false;
        });

        builder.addCase(getDataQualitySummary.pending, (state) => {
            state.dataQualitySummary.isLoading = true;
        });
        builder.addCase(getDataQualitySummary.fulfilled, (state, action) => {
            state.dataQualitySummary.isLoading = false;
            state.dataQualitySummary.data = action.payload;
        });
        builder.addCase(getDataQualitySummary.rejected, (state) => {
            state.dataQualitySummary.isLoading = false;
        });

        builder.addCase(getCustomerSummary.pending, (state) => {
            state.customerSummary.isLoading = true;
        });
        builder.addCase(getCustomerSummary.fulfilled, (state, action) => {
            state.customerSummary.isLoading = false;
            state.customerSummary.data = action.payload;
        });
        builder.addCase(getCustomerSummary.rejected, (state) => {
            state.customerSummary.isLoading = false;
        });

        builder.addCase(getTotalInvoicesInterfaced.pending, (state) => {
            state.totalInvoicesInterfaced.isLoading = true;
        });
        builder.addCase(getTotalInvoicesInterfaced.fulfilled, (state, action) => {
            state.totalInvoicesInterfaced.isLoading = false;
            state.totalInvoicesInterfaced.data = action.payload;
        });
        builder.addCase(getTotalInvoicesInterfaced.rejected, (state) => {
            state.totalInvoicesInterfaced.isLoading = false;
        });

        builder.addCase(getTotalTaxAmount.pending, (state) => {
            state.totalTaxAmount.isLoading = true;
        });
        builder.addCase(getTotalTaxAmount.fulfilled, (state, action) => {
            state.totalTaxAmount.isLoading = false;
            state.totalTaxAmount.data = action.payload;
        });
        builder.addCase(getTotalTaxAmount.rejected, (state) => {
            state.totalTaxAmount.isLoading = false;
        });

        builder.addCase(getTypesOfGeneratedInvoices.pending, (state) => {
            state.typesOfGeneratedInvoices.isLoading = true;
        });
        builder.addCase(getTypesOfGeneratedInvoices.fulfilled, (state, action) => {
            state.typesOfGeneratedInvoices.isLoading = false;
            state.typesOfGeneratedInvoices.data = action.payload;
        });
        builder.addCase(getTypesOfGeneratedInvoices.rejected, (state) => {
            state.typesOfGeneratedInvoices.isLoading = false;
        });

        builder.addCase(getTotalInvoicesYetToBeSubmitted.pending, (state) => {
            state.totalInvoicesYetToBeSubmitted.isLoading = true;
        });
        builder.addCase(getTotalInvoicesYetToBeSubmitted.fulfilled, (state, action) => {
            state.totalInvoicesYetToBeSubmitted.isLoading = false;
            state.totalInvoicesYetToBeSubmitted.data = action.payload;
        });
        builder.addCase(getTotalInvoicesYetToBeSubmitted.rejected, (state) => {
            state.totalInvoicesYetToBeSubmitted.isLoading = false;
        });

        builder.addCase(getDashboardData.pending, (state) => {
            state.dashboardData.isLoading = true;
        });
        builder.addCase(getDashboardData.fulfilled, (state, action) => {
            state.dashboardData.isLoading = false;
            state.dashboardData.data = action.payload;
        });
        builder.addCase(getDashboardData.rejected, (state) => {
            state.dashboardData.isLoading = false;
        });

        builder.addCase(getDashboardCharts.pending, (state) => {
            state.dashboardCharts.isLoading = true;
        });
        builder.addCase(getDashboardCharts.fulfilled, (state, action) => {
            state.dashboardCharts.isLoading = false;
            state.dashboardCharts.data = action.payload;
        });
        builder.addCase(getDashboardCharts.rejected, (state) => {
            state.dashboardCharts.isLoading = false;
        });

        builder.addCase(getDashboardChips.pending, (state) => {
            state.dashboardChips.isLoading = true;
        });
        builder.addCase(getDashboardChips.fulfilled, (state, action) => {
            state.dashboardChips.isLoading = false;
            state.dashboardChips.data = action.payload;
        });
        builder.addCase(getDashboardChips.rejected, (state) => {
            state.dashboardChips.isLoading = false;
        });

        builder.addCase(getProcessingSummary.pending, (state) => {
            state.processingSummary.isLoading = true;
        });
        builder.addCase(getProcessingSummary.fulfilled, (state, action) => {
            state.processingSummary.isLoading = false;
            state.processingSummary.data = action.payload.invoiceStats;
        });
        builder.addCase(getProcessingSummary.rejected, (state) => {
            state.processingSummary.isLoading = false;
        });

        builder.addCase(getRejectionSummary.pending, (state) => {
            state.rejectionSummary.isLoading = true;
        });
        builder.addCase(getRejectionSummary.fulfilled, (state, action) => {
            state.rejectionSummary.isLoading = false;
            state.rejectionSummary.data = action.payload;
        });
        builder.addCase(getRejectionSummary.rejected, (state) => {
            state.rejectionSummary.isLoading = false;
        });
    },
});

export default dashboardSlice.reducer;

export const totalGeneratedSum = (state: RootState) => {
    const last7DaySums = state.dashboard.dashboardCharts.data?.Last7DaySums;
    if (!last7DaySums) {
        return 0;
    }
    return last7DaySums.reduce(
        (sum: number, entry: IDailyChartCounts) => sum + entry.TotalB2BandB2CGeneratedCount,
        0,
    );
};

export const totalGeneratedTax = (state: RootState) => {
    const last7DaySums = state.dashboard.dashboardCharts.data?.Last7DaySums;
    if (!last7DaySums) {
        return 0;
    }
    return last7DaySums.reduce(
        (sum: number, entry: IDailyChartCounts) => sum + entry.TotalB2BandB2CTaxAmountSum,
        0,
    );
};

export const InvoiceTypeCounts = (state: RootState) => state.dashboard.InvoiceTypeCounts;
export const TopCustomers = (state: RootState) => state.dashboard.TopCustomers;
export const TotalCounts = (state: RootState) => state.dashboard.TotalCounts;
export const MonthlyStats = (state: RootState) => state.dashboard.MonthlyStats;
export const taxCodeOverview = (state: RootState) => state.dashboard.TotalTaxCodeOverview;
export const dataQuality = (state: RootState) => state.dashboard.dataQualitySummary;
export const customerSummary = (state: RootState) => state.dashboard.customerSummary;
export const totalInvoicesInterfaced = (state: RootState) =>
    state.dashboard.totalInvoicesInterfaced;
export const totalTaxAmount = (state: RootState) => state.dashboard.totalTaxAmount;
export const typesOfGeneratedInvoices = (state: RootState) =>
    state.dashboard.typesOfGeneratedInvoices;
export const totalInvoicesYetToBeSubmitted = (state: RootState) =>
    state.dashboard.totalInvoicesYetToBeSubmitted;
export const dashboardData = (state: RootState) => state.dashboard.dashboardData;
export const dashboardCharts = (state: RootState) => state.dashboard.dashboardCharts;
export const dashboardChips = (state: RootState) => state.dashboard.dashboardChips;
export const dashboardFilter = (state: RootState) => state.dashboard.filter;
export const rejectSummary = (state: RootState) => state.dashboard.rejectionSummary;

export const {
    setFilter,
} = dashboardSlice.actions;

export const selectProcessingSummary = (state: RootState) => {
    const summary = state.dashboard.processingSummary
    return propagateCounts(summary?.data)
}

