import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Input, Row, Select } from 'antd';
import { CoreButton, CoreDatePicker, CoreTitle } from '@packages';
import { useDispatch } from 'react-redux';
import { toggleFilter } from '@store/slices/organizationSlice';
import './EntityFilterInputs.scss';
import { useTranslation } from 'react-i18next';

interface IEntityFiltersProps {
    handleFilterSubmit: any;
}

interface IEntityFilters {
    entityName: string;
    vatRegistrationNumber: string;
    certificateRegistrationScheme: string;
    certificateRegNo: string;
    dateRange: {
        startDate: string;
        endDate: string;
    };
}

const EntityFilterInputs = (props: IEntityFiltersProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { handleFilterSubmit } = props;
    const [scheme, setScheme] = useState('');
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IEntityFilters>({ mode: 'onTouched' });

    const onSubmit = (data: IEntityFilters) => {
        data.certificateRegistrationScheme = scheme;
        handleFilterSubmit(data);
        dispatch(toggleFilter());
    };
    const handleSchemeChange = (e: any) => {
        setScheme(e);
    };

    return (
        <div className='entity-filter-wrapper'>
            <div className='margin-bottom-20'>
                <CoreTitle tag='h6' text={t('FILTERS')} />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={20}>
                    <Col span={24}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>{t('ENTITY_NAME')}</label>
                            <Controller
                                name='entityName'
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        className='general-input'
                                        placeholder={t('ENTITY_NAME')}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: false,
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>{t('VAT_REGISTRATION_NUMBER')}</label>
                            <Controller
                                name='vatRegistrationNumber'
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        className='general-input'
                                        placeholder={t('VAT_REGISTRATION_NUMBER')}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: false,
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={24}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>
                                {t('CERTIFICATE_REGISTRATION_NUMBER')}
                            </label>
                            <Controller
                                name='certificateRegNo'
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        className='general-input'
                                        placeholder={t('CERTIFICATE_REGISTRATION_NUMBER')}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: false,
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>
                                {t('CERTIFICATE_REGISTRATION_NUMBER')}
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                placeholder={t('CERTIFICATE_REGISTRATION_NUMBER')}
                                className='general-select'
                                optionFilterProp='children'
                                onChange={handleSchemeChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '')
                                        .toLowerCase()
                                        .localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[
                                    {
                                        value: 'TAX',
                                        label: 'TAX',
                                    },
                                    {
                                        value: 'VAT',
                                        label: 'VAT',
                                    },
                                ]}
                            />
                        </div>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={24}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>{t('START_DATE')}</label>
                            <CoreDatePicker
                                placeholder={t('START_DATE')}
                                control={control}
                                rangePicker={true}
                                name='dateRange'
                            />
                        </div>
                    </Col>
                </Row>
                <Row justify='end'>
                    <CoreButton
                        type='primary'
                        htmlType='submit'
                        isFullWidth={false}
                        text={t('APPLY_NOW')}
                    />
                </Row>
            </form>
        </div>
    );
};

export default EntityFilterInputs;
