import React from "react";
import { Col, Image, Row, Select } from "antd";
import { CoreLogo } from "@packages";
import './CoreLoginWrapper.scss'

import dotTop_img from "@assets/images/dotTop_img.png"
import dotBottom_img from "@assets/images/dotBottom_img.png"
import login_wrapper from "@assets/images/contact_us.png";
import { useTranslation } from "react-i18next";
import LanguageMenu from "@components/main-layout/layout-wrapper/LanguageMenu";

interface ILoginWrapperProps {
    children: React.ReactNode,
    coreImageSrc?: string,
}

const CoreLoginWrapper = (props: ILoginWrapperProps) => {

    const { t } = useTranslation();
    const { children, coreImageSrc } = props;

    const currentDate = new Date();

    return (
        <div className="core-login-layout-wrapper">
            <Row>
                <Col className="core-layout-content" sm={24} lg={24} xl={12}>
                    <div className='core-login-layout-logo'>
                        <CoreLogo size={'medium'} />
                        <LanguageMenu />
                    </div>
                    <div className='core-login-layout-content'>
                        {children}
                    </div>
                    <div className='core-login-layout-footer'>
                        <p>©{t('E_INVOICE_SOLUTIONS')}{currentDate.getFullYear()}</p>
                    </div>
                </Col>
                <Col className="core-layout-images" span={12}>
                    {coreImageSrc ? (
                        <>
                            <div className='core-login-wrapper'>
                                <Image width="807px" height="714px" src={coreImageSrc} preview={false} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="dot-image top">
                                <Image src={dotTop_img} preview={false} />
                            </div>
                            <div className='core-login-wrapper'>
                                <Image src={login_wrapper} preview={false} />
                            </div>
                            <div className="dot-image bottom">
                                <Image src={dotBottom_img} preview={false} />
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default CoreLoginWrapper;
