import { useEffect, useRef, useState } from 'react';
import './UploadStepper.scss';
import { Steps } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import { InvoiceMapField, UploadInvoiceSelectFile, UPloadInvoiceSelect } from '../../index';
import { setCustomerTitle } from '@store/slices/dynamicTitleSlice';
import { UploadInvoiceSelectFileRef } from '../../components/upload-invoice-select-file/UploadInvoiceSelectFile';
import { InvoiceMapFieldRef } from '../../components/Invoice-map-field/InvoiceMapField';
import { PreviewHeaderRef } from '../../components/upload-invoice-select/UploadInvoiceSelect';
import { uploadCSVConfigFiles } from '@store/slices/invoiceMappingSlice';
import { useAppDispatch } from '@store/hooks';

const steps = [
    {
        title: 'Configure',
    },
    {
        title: 'Map Fields',
    },
    {
        title: 'Preview',
    },
];

const UploadStepper = () => {
    const [current, setCurrent] = useState(0);
    const [isLoading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const uploadInvoiceSelectFile = useRef<UploadInvoiceSelectFileRef>();
    const invoiceMapFieldRef = useRef<InvoiceMapFieldRef>();
    const previewHeaderRef = useRef<PreviewHeaderRef>();
    const [fileName, setFileName] = useState<string>();

    const onStepChange = (value: number) => {
        setCurrent(value);
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const stepOne = () => {
        setCurrent(0);
    };

    if (current === 0) {
        dispatch(setCustomerTitle('Customers - Select file'));
    } else if (current === 1) {
        dispatch(setCustomerTitle('Map Fields'));
    } else if (current === 2) {
        dispatch(setCustomerTitle('Customers - Select File'));
    }

    const getCsvConfig = () => {
        dispatch(uploadCSVConfigFiles());
    };

    useEffect(() => {
        getCsvConfig();
    }, []);

    return (
        <div className='customer-stepper-wrapper'>
            <div className='customer-stepper-inner-wrapper'>
                <div>
                    <div className='steps-wrapper'>
                        <Steps
                            current={current}
                            onChange={onStepChange}
                            size={'small'}
                            items={steps}
                        />
                    </div>
                    <div className='customer-stepper-content'>
                        {current === 0 && (
                            <UploadInvoiceSelectFile
                                ref={uploadInvoiceSelectFile as any}
                                onNext={(data) => {
                                    setFileName(data?.fileName);
                                    next();
                                }}
                                setLoading={setLoading}
                            />
                        )}
                        {current === 1 && (
                            <InvoiceMapField
                                fileName={fileName}
                                ref={invoiceMapFieldRef as any}
                                onNext={(data) => {
                                    next();
                                }}
                                setLoading={setLoading}
                            />
                        )}
                        {current === 2 && (
                            <UPloadInvoiceSelect
                                ref={previewHeaderRef as any}
                                onNext={() => {
                                    stepOne();
                                }}
                                setLoading={setLoading}
                            />
                        )}
                    </div>
                </div>
                {current === 0 && (
                    <div className='customer-select-file-right'>
                        <div>
                            <CoreTitle tag='h5' text='Page Tips' />
                            <ul>
                                <li>
                                    Explore our Sample XLS File: Access a comprehensive guide to
                                    data field usage during the import process by downloading our
                                    sample XLS file..
                                </li>
                                <li>
                                    Format Conversion Made Easy: If your files are in different
                                    formats, effortlessly convert them to an accepted file format
                                    using a wide range of online or offline converters.
                                </li>
                                <li>
                                    Personalized Import Settings: Customize your import preferences
                                    and conveniently save them for future use.
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>

            <div className='customer-step-button-group'>
                {current < steps.length - 1 && <CoreButton onClick={() => prev()} text='Cancel' />}
                {current < steps.length && (
                    <CoreButton
                        type='primary'
                        onClick={() => {
                            if (current === 0) {
                                uploadInvoiceSelectFile?.current?.onSubmitData();
                                return;
                            }
                            if (current === 1) {
                                invoiceMapFieldRef?.current?.onSubmitMaps();
                                return;
                            }
                            if (current === 2) {
                                previewHeaderRef?.current?.onSubmitPreview();
                                return;
                            }
                        }}
                        text={current === 2 ? 'Submit' : 'Next'}
                        loading={isLoading}
                    />
                )}
            </div>
        </div>
    );
};

export default UploadStepper;
