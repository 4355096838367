import { FC, useEffect, useState } from 'react';
import { Tabs, Spin, message } from 'antd';
import './CustomerCreate.scss';
import type { TabsProps } from 'antd';
import { CoreButton } from '@packages';
import OtherDetails from './address/Address';
import { useNavigate, useParams } from 'react-router-dom';
import { ICustomerCreate } from '@models/customer.model';
import InlineGenericInput from '@components/common/InlineGenericInput';
import PersonInfo from './person/Person';
import { CustomerAPI } from '@service/customer.service';
import { RegexValidators } from '@config/regex-validator-constant';
import _ from 'lodash';
import { IAddress } from '@models/generic.model';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { CRS_OPTIONS } from '@utils/CRSOptions';
import InlineGenericRadio from '@components/common/InlineGenericRadio';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GenericLegalRegistrationName from '@components/common/GenericLegalRegistrationName';

type CustomerCreateProps = {
    isComponent?: boolean;
    onResponse?: ({ isCancel }: { isCancel: boolean }) => void;
};

const CustomerCreate: FC<CustomerCreateProps> = ({ isComponent = false, onResponse }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id: customerId } = useParams();
    const [isPageLoading, setPageLoading] = useState<boolean>(false);
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        clearErrors,
        setValue: setFormValue,
        formState: { errors},
        reset,
        watch,
    } = useForm<ICustomerCreate>({
        mode: 'onChange',
        defaultValues: {
            CustomerType: 'Business',
            TaxScheme: 'VAT',
            Address: {
                CountryCode: 'SA',
            },
        },
    });

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `${t('ADDRESS')}`,
            children: (
                <OtherDetails
                    errors={errors}
                    control={control}
                    setValue={setFormValue}
                    customerType={watch('CustomerType')}
                />
            ),
        },
        {
            key: '2',
            label: `${t('CONTACT_INFORMATION')}`,
            children: <PersonInfo errors={errors} control={control} setValue={setFormValue} />,
        },
    ];

    const onCustomerCreate = async (data: ICustomerCreate) => {
        try {
            setButtonLoading(true);
            const response = await CustomerAPI.createCustomer(modifyCustomerData(data));
            setButtonLoading(false);
            message.success(t('CUSTOMER_CREATED_SUCCESSFULLY'));
            if (isComponent && onResponse) {
                return onResponse({ isCancel: false });
            }
            navigate('/customers', {
                state: {
                    delay: true,
                },
            });
        } catch {
            setButtonLoading(false);
            message.error(t('CUSTOMER_CREATED_FAILED'));
        }
    };
    const onCustomerUpdate = async (data: ICustomerCreate) => {
        try {
            setButtonLoading(true);
            const response = await CustomerAPI.updateCustomer(
                customerId ?? '',
                modifyCustomerData(data),
            );
            setButtonLoading(false);
            message.success(t('CUSTOMER_UPDATED_SUCCESSFULLY'));
            navigate('/customers', {
                state: {
                    delay: true,
                },
            });
        } catch {
            setButtonLoading(false);
            message.error(t('CUSTOMER_UPDATED_FAILED'));
        }
    };
    const getCustomerInfo = async () => {
        if (!customerId) {
            return;
        }
        try {
            setPageLoading(true);
            const response = await CustomerAPI.getCustomer(customerId);
            setPageLoading(false);
            reset(response);
        } catch {
            setPageLoading(false);
            message.error(t('CUSTOMER_DATA_LOAD_FAILED'));
        }
    };

    const onCancel = () => {
        if (isComponent && onResponse) {
            return onResponse({ isCancel: true });
        }
        navigate('/customers');
    };

    useEffect(() => {
        getCustomerInfo();
    }, [customerId]);
    return (
        <Spin spinning={isPageLoading}>
            <div className='customer-create-wrapper'>
                <form onSubmit={handleSubmit(customerId ? onCustomerUpdate : onCustomerCreate)}>
                    <div className='customer-create-inner-wrapper'>
                        <div className='form-wrapper'>
                            <InlineGenericRadio
                                setFormValue={setFormValue}
                                control={control}
                                errors={errors}
                                clearErrors={clearErrors}
                                options={[
                                    { label: `${t('BUSINESS')}`, value: 'Business' },
                                    {
                                        label: `${t('INDIVIDUAL')}`,
                                        value: 'Individual',
                                        disabled: true,
                                    },
                                ]}
                                dataKey='CustomerType'
                                level={t('CUSTOMER_TYPE')}
                                rules={{
                                    required: true,
                                }}
                                marginBottom={20}
                                errorMessageSize='small'
                            />
                            <GenericLegalRegistrationName
                                control={control}
                                errors={errors}
                                dataKey='LegalRegistrationName'
                                level={t('LEGAL_REGISTRATION_NAME_ARABIC')}
                                type='arabic'
                                rules={{
                                    required: watch('CustomerType') === 'Business',
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: `${t(
                                            'LEGAL_REGISTRATION_NAME_ONLY_ALLOW_ARABIC',
                                        )}`,
                                    },
                                    maxLength: 1000,
                                }}
                                marginBottom={20}
                                errorMessageSize='small'
                            />

                            <GenericLegalRegistrationName
                                control={control}
                                errors={errors}
                                dataKey='LegalRegistrationNameEn'
                                level={t('LEGAL_REGISTRATION_NAME_ENGLISH')}
                                rules={{
                                    required: watch('CustomerType') === 'Business',
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: `${t(
                                            'LEGAL_REGISTRATION_NAME_ONLY_ALLOW_ENGLISH',
                                        )}`,
                                    },
                                    maxLength: 1000,
                                }}
                                marginBottom={20}
                                errorMessageSize='small'
                            />
                            <InlineGenericInput
                                control={control}
                                tooltip={
                                watch('Address.CountryCode') === 'SA'
                                        ? t('VAT_REGISTRATION_NUMBER_REFERS_TO_A_UNIQUE_IDENTIFICATION_NUMBER_ASSIGNED_TO_A_BUSINESS')
                                        : t('PLEASE_ENTER_VALID_VAT_REGISTRATION_NUMBER')
                                }
                                errors={errors}
                                dataKey='VatRegistrationNumber'
                                level={t('VAT_REGISTRATION_NUMBER')}
                                type='vatRegistrationNumber'
                                rules={{
                                    required: watch('CustomerType') === 'Business',
                                    maxLength: 15,
                                    minLength: 15,
                                }}
                                hideError={true}
                                marginBottom={20}
                                errorMessageSize='small'
                            >
                                <div style={{ position: 'absolute', fontSize: 12 }}>
                                    {errors.VatRegistrationNumber?.type === 'required' && (
                                        <span className='error-message'>
                                            {t('VAT_REGISTRATION_NUMBER_IS_REQUIRED')}
                                        </span>
                                    )}
                                    {errors.VatRegistrationNumber?.type === 'maxLength' && (
                                        <span className='error-message'>
                                            Vat Registration Number must be 15 digit
                                        </span>
                                    )}
                                    {errors.VatRegistrationNumber?.type === 'minLength' && (
                                        <span className='error-message'>
                                            Vat Registration Number must be 15 digit
                                        </span>
                                    )}
                                </div>
                            </InlineGenericInput>
                            <InlineGenericSelect
                                control={control}
                                errors={errors}
                                dataKey='CompanyRegistrationScheme'
                                level={t('OTHER_BUYER_ID_TYPE')}
                                placeHolder={t('SELECT_OTHER_BUYER_ID_TYPE')}
                                tooltip={t('OTHER_BUYER_ID_TYPE_TOOLTIP')}
                                options={CRS_OPTIONS}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                            />
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                dataKey='CompanyRegistrationNumber'
                                level={t('OTHER_BUYER_ID')}
                                type='otherSellerId'
                                tooltip={t('OTHER_BUYER_ID_TYPE_TOOLTIP')}
                                marginBottom={20}
                                errorMessageSize='small'
                                disabled={
                                    watch('CompanyRegistrationScheme') === undefined ||
                                    watch('CompanyRegistrationScheme') === ''
                                }
                                rules={{
                                    required:
                                        watch('CompanyRegistrationScheme') === undefined ||
                                            watch('CompanyRegistrationScheme') === ''
                                            ? false
                                            : true,
                                    pattern: {
                                        value: RegexValidators.ONLY_NUMERIC,
                                        message: `${t('OTHER_SELLER_ID_NUMERIC')}`,
                                    },
                                    maxLength: 10,
                                }}
                            />
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                dataKey='Email'
                                level={t('EMAIL_ADDRESS')}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.EMAIL,
                                        message: `${t('EMAIL_WARNING')}`,
                                    },
                                }}
                                marginBottom={20}
                                errorMessageSize='small'
                            />
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                dataKey='PhoneNumber'
                                level={t('PHONE_NUMBER')}
                                type='phoneNumber'
                                rules={{
                                    required: true,
                                }}
                                hideError={true}
                                marginBottom={20}
                                errorMessageSize='small'
                            >
                                <div style={{ position: 'absolute', fontSize: 12 }}>
                                    {errors.PhoneNumber?.type === 'required' && (
                                        <span className='error-message'>
                                            {t('PHONE_NUMBER_IS_REQUIRED')}
                                        </span>
                                    )}
                                    {errors.PhoneNumber?.type === 'pattern' && (
                                        <span className='error-message'>
                                            {t('PHONE_NUMBER_INVALID')}
                                        </span>
                                    )}
                                </div>
                            </InlineGenericInput>
                            <Tabs defaultActiveKey='1' items={items} />
                        </div>
                    </div>

                    <div className='submit-action-buttons'>
                        <CoreButton
                            text={t('CANCEL')}
                            type='basic'
                            htmlType='button'
                            onClick={onCancel}
                        />
                        <CoreButton
                            loading={isButtonLoading}
                            text={t('SAVE')}
                            type='primary'
                            htmlType='submit'
                        />
                    </div>
                </form>
            </div>
        </Spin>
    );
};

export default CustomerCreate;

function modifyCustomerData(data: ICustomerCreate): ICustomerCreate {
    const { AdditionalNumber, BuildingNumber, PostalCode } = data?.Address ?? {};
    AdditionalNumber && (data.Address.AdditionalNumber = Number(AdditionalNumber));
    BuildingNumber && (data.Address.BuildingNumber = Number(BuildingNumber));
    PostalCode && (data.Address.PostalCode = String(PostalCode));
    data.Address = _.omitBy(data.Address, (v) => v === '') as IAddress;
    return data;
}
