import { FC } from 'react';
import './Person.scss';
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { ICustomerCreate } from '@models/customer.model';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { RegexValidators } from '@config/regex-validator-constant';
import { useTranslation } from 'react-i18next';

interface IPersonInfoParams {
    control: Control<ICustomerCreate, any>;
    errors: FieldErrors<ICustomerCreate>;
    setValue: UseFormSetValue<ICustomerCreate>;
}

const PersonInfo: FC<IPersonInfoParams> = ({ control, errors, setValue }) => {
    const { t } = useTranslation();
    return (
        <div className='person-wrapper'>
            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Person.FirstName'
                level={t('FIRST_NAME_ARABIC')}
                type='arabic'
                rules={{
                    pattern: {
                        value: RegexValidators.ARABIC_CHARACTER,
                        message: `${t('ARABIC_TEXT_VALIDATION')}`,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />
            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Person.FirstNameEn'
                level={t('FIRST_NAME_ENGLISH')}
                rules={{
                    pattern: {
                        message: `${t('ENGLISH_TEXT_VALIDATION')}`,
                        value: RegexValidators.ENGLISH_CHARACTER,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />

            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Person.LastName'
                level={t('LAST_NAME_ARABIC')}
                type='arabic'
                rules={{
                    pattern: {
                        value: RegexValidators.ARABIC_CHARACTER,
                        message: `${t('ARABIC_TEXT_VALIDATION')}`,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />
            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Person.LastNameEn'
                level={t('LAST_NAME_ENGLISH')}
                rules={{
                    pattern: {
                        message: `${t('ENGLISH_TEXT_VALIDATION')}`,
                        value: RegexValidators.ENGLISH_CHARACTER,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />

            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Person.MiddleName'
                level={t('MIDDLE_NAME_ARABIC')}
                type='arabic'
                rules={{
                    pattern: {
                        value: RegexValidators.ARABIC_CHARACTER,
                        message: `${t('ARABIC_TEXT_VALIDATION')}`,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />
            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Person.MiddleNameEn'
                level={t('MIDDLE_NAME_ENGLISH')}
                rules={{
                    pattern: {
                        message: `${t('ENGLISH_TEXT_VALIDATION')}`,
                        value: RegexValidators.ENGLISH_CHARACTER,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />
            
            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Person.JobTitle'
                level={t('JOB_TITLE_ARABIC')}
                type='arabic'
                rules={{
                    pattern: {
                        message: `${t('ARABIC_TEXT_VALIDATION')}`,
                        value: RegexValidators.ARABIC_CHARACTER,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />
            <InlineGenericInput
                control={control}
                errors={errors}
                dataKey='Person.JobTitleEn'
                level={t('JOB_TITLE_ENGLISH')}
                rules={{
                    pattern: {
                        message: `${t('ENGLISH_TEXT_VALIDATION')}`,
                        value: RegexValidators.ENGLISH_CHARACTER,
                    },
                }}
                marginBottom={20}
                errorMessageSize='small'
            />
        </div>
    );
};

export default PersonInfo;
