export enum EResourceType {
    'Identity' = 'Identity',
    'EInvoiceService' = 'EInvoiceService',
    'MailService' = 'MailService',
    'Notification' = 'Notification',
    'Storage' = 'Storage',
    'MultiFactorAuthentication' = 'MultiFactorAuthentication',
    'SystemTokenService' = 'SystemTokenService',
    'PDFEngine' = 'PDFEngine',
}
export const GET_ALL_RESOURCE_FROM_SERVICE: {
    value: EResourceType;
    label: keyof typeof EResourceType;
}[] = [
    {
        label: 'Identity',
        value: EResourceType.Identity,
    },
    {
        label: 'EInvoiceService',
        value: EResourceType.EInvoiceService,
    },
    {
        label: 'MailService',
        value: EResourceType.MailService,
    },
    {
        label: 'Notification',
        value: EResourceType.Notification,
    },
    {
        label: 'Storage',
        value: EResourceType.Storage,
    },
    {
        label: 'MultiFactorAuthentication',
        value: EResourceType.MultiFactorAuthentication,
    },
    {
        label: 'SystemTokenService',
        value: EResourceType.SystemTokenService,
    },
    {
        label: 'PDFEngine',
        value: EResourceType.PDFEngine,
    },
];
