import React, { useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import "./CoreSearchInput.scss";
import { debounce } from "lodash";

interface ICoreSearchProps {
    placeholder?: string;
    handleInputChange?: (text: string) => void;
}

const CoreSearchInput = (props: ICoreSearchProps) => {
    const [query, setQuery] = useState("");
    const { placeholder, handleInputChange } = props

    const changeHandler = (event: any) => {
        setQuery(event.target.value);
      };
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 700)
      , []);
   
      useEffect(() => {
        handleInputChange && handleInputChange(query)
    }, [query]);
    
    return (
        <div className="core-input-search">
            <SearchOutlined className="icon"/>
            <input
                className="input-field"
                type="text"
                placeholder={placeholder}
                onChange={debouncedChangeHandler}
            />
        </div>
    );
};

export default CoreSearchInput;
