export interface InvoiceHeader {
    fileHeader: string | undefined; // Assuming fileHeader could be undefined
    invoiceHeader: string;
}

export const mapInvoiceHeaders = (
    fields: { key: string; label: string; isRequired?: boolean }[],
    values: Record<string, any>,
): InvoiceHeader[] => {
    return fields.map((field) => ({
        fileHeader: values[field.key] || '',
        invoiceHeader: field.key || '',
    }));
};

export const displayMapInvoiceHeaders = (data: any[]) => {
    return data.reduce((acc, item) => {
        acc[item.InvoiceHeader] = item.FileHeader || undefined;
        return acc;
    }, {});
};

export const formatHeaderLabel = (header: string) => {
    return header.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
};
