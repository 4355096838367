import './CreateTicket.scss';
import { CoreBackButton, CoreButton, CoreFileUploadDragger, CoreTitle } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { useForm } from 'react-hook-form';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import { IFileInfo } from '../../../../packages/CoreFileUploader/CoreFileUploader';
import { useState } from 'react';
import { Col, Row } from 'antd';

interface ICreateTicketsPayload {
    Billing: string;
    Bandwidth: string;
    BillingInquiry: string;
    Subject: string;
    Message: string;
}

const CreateTicket = () => {
    const navigate = useNavigate();
    const [fileID, setFileID] = useState<string>();
    const [localFile, setLocalFile] = useState<string>();

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<ICreateTicketsPayload>({
        mode: 'onTouched',
    });

    const onFileUpload = ({ uploadId, url }: IFileInfo) => {
        setLocalFile(url);
        setFileID(uploadId);
    };

    const onSubmit = async (data: ICreateTicketsPayload) => {
        console.log(data);
    };

    return (
        <div className='create-ticket-wrapper'>
            <div className='create-ticket-inner-wrapper'>
                <CoreBackButton text={''} />
                <div className='create-ticket-header'>
                    <CoreTitle tag={'h4'} text='Create Ticket' />
                    <p className='create-ticket-sub-title'>
                        Follow these 3 steps to help resolve your ticket faster
                    </p>
                    <ol>
                        <li>
                            Search the Help Center for solutions. You may be able to solve an issue
                            without having to wait for an answer.
                        </li>
                        <li>
                            Select the team or account where you need help in the upper right. This
                            gives your support agent the context to help you better.
                        </li>
                        <li>
                            Include key details and provide steps to reproduce the problem. This
                            helps us troubleshoot your issue faster.
                        </li>
                    </ol>
                </div>
                <div className='create-ticket-form'>
                    <CoreTitle tag={'h3'} text='Create a New Support Ticket' />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='create-ticket-form-wrapper'>
                            <Row gutter={14} align='middle'>
                                <Col span={12}>
                                    <InlineGenericSelect
                                        control={control}
                                        errors={errors}
                                        levelCol={24}
                                        inputCol={24}
                                        dataKey='Billing'
                                        level='Billing'
                                        placeHolder='Billing'
                                        options={[]}
                                        setFormValue={setFormValue}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InlineGenericSelect
                                        control={control}
                                        errors={errors}
                                        levelCol={24}
                                        inputCol={24}
                                        dataKey='Bandwidth'
                                        level='Bandwidth'
                                        placeHolder='Bandwidth'
                                        options={[]}
                                        setFormValue={setFormValue}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={14} align='middle'>
                                <Col span={12}>
                                    <InlineGenericSelect
                                        control={control}
                                        errors={errors}
                                        levelCol={24}
                                        inputCol={24}
                                        dataKey='BillingInquiry'
                                        level='Billing Inquiry'
                                        placeHolder='Billing Inquiry'
                                        options={[]}
                                        setFormValue={setFormValue}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InlineGenericInput
                                        control={control}
                                        errors={errors}
                                        levelCol={24}
                                        inputCol={24}
                                        dataKey='Subject'
                                        level='Subject'
                                        marginBottom={20}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={14} align='middle'>
                                <Col span={12}>
                                    <div className='general-input-wrapper'>
                                        <p className='general-label margin-top-0'>Message</p>
                                        <TextArea
                                            rows={6}
                                            placeholder='Please let us know the question you have and any further details'
                                        />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='general-input-wrapper'>
                                        <p className='general-label margin-top-0'>Upload File</p>
                                        <CoreFileUploadDragger
                                            uploadHint='SVG, PNG, JPG or GIF (max. 800x400px)'
                                            onFileUpload={onFileUpload}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className='submit-ticket-buttons'>
                                <CoreButton
                                    text='Submit Ticket'
                                    type='primary'
                                    htmlType={'submit'}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default CreateTicket;
