import { DEFAULT_TABLE_SIZE } from '@config/constants';
import { IBilling } from '@models/billing.model';
import { GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import { UtcToLocalDate } from '@utils/date';
import { displayCurrency } from '@utils/displayCurrency';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

const columns = (t: TFunction<'translation', undefined>): ColumnsType<IBilling> => [
    {
        title: t('CREATED_DATE'),
        dataIndex: 'CreatedAt',
        render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
    },
    {
        title: t('DESCRIPTION'),
        dataIndex: 'Type',
        render: (text: string, options: IBilling) => {
            const color = options.Type === 'visa' ? 'black-color' : 'primary-color';
            const description =
                options.Type === 'visa'
                    ? `Payment Visa (2246)`
                    : `Invoice for ${UtcToLocalDate(options.CreatedAt)}`;

            return <span className={color}>{description}</span>;
        },
    },
    {
        title: t('Amount'),
        dataIndex: 'Amount',
        render: (amount: number, options: IBilling) => (
            <span>
                {(options.Type === 'visa' && <>-</>) || <> </>}
                {displayCurrency({
                    Code: GET_DEFAULT_CURRENCY_CODE,
                    Amount: amount || 0,
                })}
            </span>
        ),
    },
    {
        title: '',
        dataIndex: 'CreatedAt',
        render: (text: string) => (
            <span>
                Download: <span className='primary-color'>PDF</span>
            </span>
        ),
    },
];
export default function BillingHistory() {
    const { t } = useTranslation();

    return (
        <div className='ksa-table-wrapper table-auto-scroll-with-header' style={{ marginTop: 10 }}>
            <Table
                columns={columns(t)}
                dataSource={DUMMY_DATA}
                rowClassName='cursor-pointer'
                rowKey={'InvoiceId'}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: DUMMY_DATA.length,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${t('OF')} ${total} ${t('ITEMS')}`,
                }}
            />
        </div>
    );
}

export const DUMMY_DATA: IBilling[] = [
    {
        CreatedAt: new Date().toISOString(),
        Amount: 100,
        Type: 'visa',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 14000,
        Type: 'invoice',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 500,
        Type: 'visa',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 1000,
        Type: 'invoice',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 0,
        Type: 'invoice',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 0,
        Type: 'invoice',
    },

    {
        CreatedAt: new Date().toISOString(),
        Amount: 100,
        Type: 'visa',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 500,
        Type: 'visa',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 1000,
        Type: 'invoice',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 0,
        Type: 'invoice',
    },
    {
        CreatedAt: new Date().toISOString(),
        Amount: 0,
        Type: 'invoice',
    },

    {
        CreatedAt: new Date().toISOString(),
        Amount: 100,
        Type: 'visa',
    },
];
