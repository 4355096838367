import { CloudUploadOutlined } from '@ant-design/icons';
import { UploadFile, UploadProps, message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import './CSVFileUploader.scss';
import { RcFile } from 'antd/es/upload';
import { fileStorageAPI } from '@service/file-storage.service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IFileInfo {
    file?: File;
}

export interface ICsvFileUPloadProps {
    accept?: 'image' | 'pdf' | 'excel' | 'all';
    onFileUpload?: (info: IFileInfo) => void;
    onRemoveFile?: (info: IFileInfo) => void;
    uploadHint?: string;
    maxWidth?: number;
    maxHeight?: number;
}

const fileAcceptValue = {
    pdf: 'application/pdf',
    image: 'image/png, image/jpeg',
    excel: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv',
    all: 'application/vnd.ms-excel, application/pdf, image/png, image/jpeg,',
};

const CSVFileUploadDragger = ({ accept, onFileUpload, uploadHint }: ICsvFileUPloadProps) => {
    const [file, setFile] = useState<UploadFile<any>>();
    const fileAccept = accept ? fileAcceptValue[accept] : fileAcceptValue.excel;
    const { t } = useTranslation();

    const props: UploadProps = {
        accept: fileAccept,
        multiple: false,
        maxCount: 1,
        async beforeUpload(file: RcFile) {
            try {
                setFile({ name: file.name, uid: file.uid, status: 'done' });
                onFileUpload && onFileUpload({ file });
                return false;
            } catch (error: any) {
                message.error(error?.message || t('FILE_UPLOAD_FAILED'));
                setFile({ name: file.name, uid: file.uid, status: 'error' });
                return false;
            }
        },
        onRemove() {
            setFile(undefined);
            onFileUpload && onFileUpload({ file: undefined });
        },
    };
    return (
        <div className='ksa-upload-wrapper'>
            <Dragger {...props} fileList={file ? [file] : []}>
                <p className='ant-upload-drag-icon'>
                    <CloudUploadOutlined />
                </p>
                <p className='ant-upload-text'>
                    <span className='upload-text'>{t('CLICK_TO_UPLOAD')}</span>
                    <span className='drag-n-drop-text'>{t('OR_DRAG_AND_DROP')}</span>
                </p>
                <p className='ant-upload-hint'>
                    {uploadHint || 'File Format: CSV (Maximum file size: 5MB)'}
                </p>
            </Dragger>
        </div>
    );
};
export default CSVFileUploadDragger;
