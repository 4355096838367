import TemplateMenuWrapper from '../../template-generator/template-menu-wrapper/TemplateMenuWrapper';
import { CoreButton, CoreTitle } from '@packages';
import { Checkbox, Col, ColorPicker, Input, Row, Select } from 'antd';

const TemplateItemTable = () => {
    return (
        <TemplateMenuWrapper>
            <div className='template-form-wrapper'>
                <CoreTitle className='doc-title' tag='h4' text='Invoice ID' />
                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={24}>
                            <Checkbox className='general-checkbox'>ID</Checkbox>
                            <Input placeholder='ID' />
                        </Col>
                    </Row>
                </div>

                <div className='form-field'>
                    <Row gutter={8}>
                        <Col span={12}>
                            <label className='general-label'>Data Type</label>
                            <Select
                                className='template-select'
                                defaultValue='text'
                                options={[
                                    { value: 'decimal', label: 'Decimal' },
                                    { value: 'text', label: 'Text' },
                                    { value: 'image', label: 'Image' },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <label className='general-label'>Decimal Up to</label>
                            <Select
                                className='template-select'
                                defaultValue='2'
                                options={[
                                    { value: '2', label: '2' },
                                    { value: '4', label: '4' },
                                    { value: '6', label: '6' },
                                ]}
                            />
                        </Col>
                    </Row>
                </div>

                <div className='form-field'>
                    <label className='general-label'>Image</label>
                    <Row gutter={8}>
                        <Col span={12}>
                            <CoreButton text='Choose from Desktop' isFullWidth={true} />
                        </Col>
                        <Col span={12}>
                            <CoreButton text='Choose from Gallery' isFullWidth={true} />
                        </Col>
                    </Row>
                </div>

                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={24}>
                            <Checkbox className='general-checkbox'>Is Dynamic</Checkbox>
                        </Col>
                    </Row>
                </div>

                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Postfix</label>
                            <Select
                                className='template-select'
                                defaultValue='%'
                                options={[{ value: '%', label: '%' }]}
                            />
                        </Col>
                        <Col span={12}>
                            <label className='general-label'>Background Color</label>
                            <ColorPicker showText className='custom-color-picker' />
                        </Col>
                    </Row>
                </div>

                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Font Color</label>
                            <ColorPicker showText className='custom-color-picker' />
                        </Col>
                        <Col span={12}>
                            <label className='general-label'>Font Size</label>
                            <Input addonAfter='pt' defaultValue='8' />
                        </Col>
                    </Row>
                </div>

                <div className='form-field'>
                    <Row gutter={8} align='middle'>
                        <Col span={12}>
                            <label className='general-label'>Font Weight</label>
                            <Select
                                className='template-select'
                                defaultValue='400'
                                options={[
                                    { value: '100', label: '100' },
                                    { value: '200', label: '200' },
                                    { value: '300', label: '300' },
                                    { value: '400', label: '400' },
                                    { value: '500', label: '500' },
                                    { value: '600', label: '600' },
                                ]}
                            />
                        </Col>
                    </Row>
                </div>

                <div className='save-button'>
                    <CoreButton type='primary' text='Save' />
                </div>
            </div>
        </TemplateMenuWrapper>
    );
};
export default TemplateItemTable;
