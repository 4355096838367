import { RequestStatus } from '@models/async-status.enum';
import { IUpdateUser, IUser, IUserGetPayload, IUserList } from '@models/user.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { localStoreAPI } from '@service/local-storage.service';
import { UserAPI } from '@service/user.service';
import { RootState } from '@store/store';
import { isNil, omitBy } from 'lodash';
import { UserRoles, UserRolesValues } from '@config/user-roles';
import { getRoles } from '@store/slices/roleSlice';

interface IUserState {
    status: RequestStatus;
    userDetails: IUser;
    isActive2Fa: boolean;
    users: {
        status: RequestStatus;
        data: IUserList;
    };
}

const initialState: IUserState = {
    status: 'idle',
    userDetails: localStoreAPI.getLocalStorageLoggedUserProfile(),
    isActive2Fa: localStoreAPI.getLocalStorageLoggedUserProfile()?.Is2FaEnabled ?? false,
    users: {
        status: 'idle',
        data: [],
    },
};

export const getUserDetails = createAsyncThunk('getUserDetails', async () => {
    try {
        return UserAPI.GetUserDetails();
    } catch (error: any) {
        throw error as any;
    }
});
export const getUsers = createAsyncThunk('getUsers', async (payload: IUserGetPayload) => {
    try {
        return UserAPI.getUsers(payload);
    } catch (error: any) {
        throw error as any;
    }
});

export const UpdateLoggedUserRequest = createAsyncThunk(
    'updateUserData',
    async (user: IUpdateUser) => {
        try {
            const response = await UserAPI.updateLoggedUserInfo(user);
            return user;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const User = createSlice({
    name: 'user',
    initialState,
    reducers: {
        onChange2FAActivation: (state, action: { type: string; payload: boolean }) => {
            state.isActive2Fa = action.payload;
        },
        onChangeUserProfile: (state, action: { type: string; payload: string }) => {
            state.userDetails.DisplayPictureUrl = action.payload;
            const prevData: IUser = { ...state.userDetails, DisplayPictureUrl: action.payload };
            localStoreAPI.setLocalStorageLoggedUserProfile(prevData);
        },
        onClearUserInfo: (state) => {
            state.userDetails = {} as any;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserDetails.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                state.status = 'idle';
                state.userDetails = action.payload;
                state.isActive2Fa = !!action.payload.Is2FaEnabled;
                localStoreAPI.setLocalStorageLoggedUserProfile(action.payload);
            })
            .addCase(UpdateLoggedUserRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UpdateLoggedUserRequest.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(UpdateLoggedUserRequest.fulfilled, (state, action) => {
                const response = omitBy(action.payload, isNil);
                const prevData = { ...state.userDetails, ...response };
                console.log(response);
                state.status = 'idle';
                state.userDetails = prevData as IUser;
                localStoreAPI.setLocalStorageLoggedUserProfile(prevData);
            })
            .addCase(getUsers.pending, (state) => {
                state.users.status = 'loading';
            })
            .addCase(getUsers.rejected, (state) => {
                state.users.status = 'failed';
                state.users.data = [];
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users.status = 'idle';
                state.users.data = action.payload.Users || [];
            });
    },
});

export const { onChange2FAActivation, onChangeUserProfile, onClearUserInfo } = User.actions;

export const selectLoggedUserInfo = (state: RootState) => state.user;
export const selectUser2FaActivity = (state: RootState) => state.user.isActive2Fa;

export const userSelectRole = (
    state: RootState,
): {
    name: UserRolesValues;
    isAdmin: boolean;
    isAppUser: boolean;
    isOrganizationAdmin: boolean;
} => {
    const roles = state?.user?.userDetails?.Roles || [];
    if (roles.some((data) => data.RoleName === UserRoles.organizationAdmin)) {
        return {
            name: UserRoles.organizationAdmin,
            isAdmin: false,
            isAppUser: false,
            isOrganizationAdmin: true,
        };
    }
    if (roles.some((data) => data.RoleName === UserRoles.admin)) {
        return {
            name: UserRoles.admin,
            isAdmin: true,
            isAppUser: false,
            isOrganizationAdmin: false,
        };
    }
    if (roles.some((data) => data.RoleName === UserRoles.appuser)) {
        return {
            name: UserRoles.appuser,
            isAdmin: false,
            isAppUser: true,
            isOrganizationAdmin: false,
        };
    }
    return {
        name: UserRoles.anonymous,
        isAdmin: false,
        isAppUser: false,
        isOrganizationAdmin: false,
    };
};

export default User.reducer;
