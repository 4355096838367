import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enTransLation, saTransLation } from '@assets/i18n';
import { DEFAULT_LANGUAGE } from '@config/constants';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng: DEFAULT_LANGUAGE,
        fallbackLng: DEFAULT_LANGUAGE,
        resources: {
            en: {
                translation: {
                    ...enTransLation,
                },
            },
            sa: {
                translation: {
                    ...saTransLation,
                },
            },
        },
    });
