import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useTranslation } from 'react-i18next';
import { selectAppLanguage, updateLanguage } from '@store/slices/languageSlice';
import { localStoreAPI } from '@service/local-storage.service';
import { useEffect } from 'react';
import { DEFAULT_LANGUAGE, LANGUAGE_KEY } from '@config/constants';

export default function useLanguage(): {
    setCurrentLanguage: (value: string) => void;
    getCurrentLanguage: () => string;
} {
    const dispatch = useAppDispatch();
    const { selection } = useAppSelector(selectAppLanguage);
    const { i18n } = useTranslation();

    const setCurrentLanguage = (value: string) => {
        dispatch(updateLanguage(value));
        i18n.changeLanguage(value);
    };

    const setInitialLanguage = () => {
        const language = localStoreAPI.getItemLocalStore(LANGUAGE_KEY) || DEFAULT_LANGUAGE;
        i18n.changeLanguage(language);
    };

    const getCurrentLanguage = (): string => {
        return selection;
    };
    useEffect(() => {
        setInitialLanguage();
    }, []);

    return { getCurrentLanguage, setCurrentLanguage };
}
