import { FC, useEffect, useState } from 'react';
import { message } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { ConfirmationModal } from '@components';
import { CoreButton } from '@packages';
import { generateUUID } from '@utils/uuid';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { resetProductSelect } from '@store/slices/InventoriesSlice';
import InvoiceSingleLine from './InvoiceSingleLine';
import { getOrganizations } from '@store/slices/organizationSlice';
import {
    addNewInvoiceLineItem,
    deleteInvoiceLineItem,
    resetInvoiceLineItem,
    selectAllowanceCharges,
    selectInvoiceLineItems,
    selectTotalAllowanceCharges,
    selectTotalPrePaymentsAmount,
    setShowInvoiceItemRequired,
} from '@store/slices/einvoiceSlice';
import _ from 'lodash';
import { IInvoiceLinesItem } from '@models/invoice.model';
import {
    DEFAULT_LINES_ITEMS,
    IS_INVOICE_ITEM_VALID,
    TAX_CATEGORY_DESCRIPTION,
} from '@utils/InvoiceUtils';
import { displayCurrency } from '@utils/displayCurrency';
import GenericTooltip from '@components/common/GenericTooltip';
import './InvoiceLines.scss';
import { useTranslation } from 'react-i18next';
import DocumentLevelDiscount from './DocumentLevelDiscount';
import {
    getInvoiceCalculation,
    getInvoiceTotalTaxCalculation,
    getTotalChargeValue,
    getTotalDiscountValue,
    getTotalTaxExclusiveAmount,
    getTotalTaxInclusiveAmount,
} from '@utils/InvoiceCalculation';

interface IInvoiceLines {
    documentTaxCurrencyCode?: string;
    exchangeRate?: number;
}

const InvoiceLines: FC<IInvoiceLines> = ({ documentTaxCurrencyCode, exchangeRate }) => {
    const { t } = useTranslation();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const invoiceLineItems = useAppSelector(selectInvoiceLineItems);
    const [selectedItem, setSelectedItem] = useState<IInvoiceLinesItem>();
    const [isShowDocumentModal, setDocumentShowModal] = useState<boolean>(false);
    const totalPrePayments = useAppSelector(selectTotalPrePaymentsAmount);
    const totalDocumentAllowance = useAppSelector(selectTotalAllowanceCharges);

    const handleDeleteOk = () => {
        setIsDeleteModalOpen(false);
        if (!selectedItem?.SerialId) return;
        dispatch(deleteInvoiceLineItem(selectedItem?.SerialId));
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
        setSelectedItem(undefined);
    };

    const addDeviceRow = () => {
        const lastInvoiceLine = _.last(invoiceLineItems);

        if (
            lastInvoiceLine &&
            lastInvoiceLine?.LineExtensionAmount &&
            lastInvoiceLine?.LineExtensionAmount < 0
        ) {
            message.warning(t('SALE_PRICE_CAN_NOT_BE_NEGATIVE'));
            return;
        }
        if (!IS_INVOICE_ITEM_VALID(lastInvoiceLine)) {
            message.warning(t('BEFORE_CREATING_A_NEW_ENTRY_PLEASE_ADD_ALL_REQUIRED_ITEMS'));
            dispatch(setShowInvoiceItemRequired());

            return;
        }

        dispatch(
            addNewInvoiceLineItem({
                ...DEFAULT_LINES_ITEMS,
                SerialId: generateUUID(),
            } as IInvoiceLinesItem),
        );
    };

    const clearAllItem = () => {
        dispatch(resetInvoiceLineItem());
    };

    const getOrganizationsData = async () => {
        dispatch(getOrganizations());
    };
    useEffect(() => {
        getOrganizationsData();
        return () => {
            dispatch(resetInvoiceLineItem());
            dispatch(resetProductSelect());
        };
    }, []);

    return (
        <>
            <div className='items-table-wrapper'>
                <div className='items-table-wrapper-child'>
                    <div className='add-items-table'>
                        <div className='table-row table-header'>
                            <div className='col item-col'>
                                {t('PRODUCT_NAME_ARABIC')}
                                <span className='required-field'>*</span>
                            </div>
                            <div className='col item-col-english'>{t('PRODUCT_NAME_ENGLISH')}</div>
                            <div className='col quantity'>
                                {t('QUANTITY')}
                                <span className='required-field'>*</span>
                            </div>
                            <div className='col rate' style={{ position: 'relative' }}>
                                {t('SALE_PRICE')}
                                <span className='required-field'>*</span>
                                <GenericTooltip text={t('SALE_PRICE_MUST_BE_LARGER_THEN_ZERO')} />
                            </div>
                            <div className='col unit-code'>
                                {t('UNIT')}
                                <span className='required-field'>*</span>
                            </div>
                            <div className='col price-tax' style={{ position: 'relative' }}>
                                {t('TAX_RATE')}
                                <span className='required-field'>*</span>
                                <GenericTooltip text={TAX_CATEGORY_DESCRIPTION} />
                            </div>
                            <div className='col rounding-amount'>{t('SALE_PRICE')}</div>
                        </div>
                        {invoiceLineItems.map((data, indexNumber) => (
                            <>
                                <InvoiceSingleLine
                                    key={data.SerialId}
                                    data={data}
                                    indexNumber={indexNumber}
                                    onDeleteItem={(data: IInvoiceLinesItem) => {
                                        setSelectedItem(data);
                                        setIsDeleteModalOpen(true);
                                    }}
                                    documentTaxCurrencyCode={documentTaxCurrencyCode}
                                />
                                <DocumentLevelDiscount
                                    data={data}
                                    isOpenModal={isShowDocumentModal}
                                    setHideModal={() => setDocumentShowModal(false)}
                                    documentTaxCurrencyCode={documentTaxCurrencyCode}
                                />
                            </>
                        ))}
                    </div>

                    <div className='item-table-add-button margin-bottom-20'>
                        <div className='table-modal-buttons'>
                            <CoreButton
                                className='add-another-button'
                                antType='link'
                                text={t('ADD_ANOTHER_ITEM')}
                                onClick={addDeviceRow}
                                icon={<PlusOutlined />}
                            />

                            {invoiceLineItems?.length > 0 && (
                                <CoreButton
                                    className='add-another-button'
                                    antType='link'
                                    onClick={clearAllItem}
                                    text={t('CLEAR_ALL_ITEMS')}
                                    icon={<CloseOutlined />}
                                />
                            )}
                        </div>
                        <div className='table-totals'>
                            <div>
                                <div className='table-total-wrapper-three'>
                                    <p>{t('EXCHANGE_RATE')}</p>
                                    <p className='table-total-unit'>
                                        {exchangeRate ? exchangeRate : '0.00'}
                                    </p>
                                </div>

                                <div className='table-total-wrapper-five'>
                                    <p>{t('CHARGE_TOTAL')}</p>
                                    <p>
                                        {displayCurrency({
                                            Amount:
                                                getTotalChargeValue(invoiceLineItems) +
                                                totalDocumentAllowance?.totalCharges,
                                            Code: documentTaxCurrencyCode || 'SAR',
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className='table-total-wrapper-six'>
                                    <p>{t('TAX_EXCLUSIVE_AMOUNT')}</p>
                                    <p className='table-total-unit'>
                                        {displayCurrency({
                                            Amount:
                                                getTotalTaxExclusiveAmount(invoiceLineItems) +
                                                totalDocumentAllowance.totalDiscountCharge,
                                            Code: documentTaxCurrencyCode || 'SAR',
                                        })}
                                    </p>
                                </div>
                                <div className='table-total-wrapper-six'>
                                    <p>{t('TAX_INCLUSIVE_AMOUNT')}</p>
                                    <p className='table-total-unit'>
                                        {displayCurrency({
                                            Amount:
                                                getTotalTaxInclusiveAmount(invoiceLineItems) +
                                                totalDocumentAllowance.totalTaxDiscountCharge,
                                            Code: documentTaxCurrencyCode || 'SAR',
                                        })}
                                    </p>
                                </div>
                                <div className='table-total-wrapper-seven'>
                                    <p>{t('TOTAL_TAX_AMOUNT')}</p>
                                    <p className='table-total-unit'>
                                        {displayCurrency({
                                            Amount:
                                                (getInvoiceTotalTaxCalculation(invoiceLineItems) +
                                                    totalDocumentAllowance.totalTaxAmount) *
                                                (exchangeRate || 1),
                                            Code: 'SAR',
                                        })}
                                    </p>
                                </div>
                                <div className='table-total-wrapper-eight'>
                                    <p>{t('PAYABLE_AMOUNT')}</p>
                                    <p className='table-total-unit'>
                                        {displayCurrency({
                                            Amount:
                                                getInvoiceCalculation(invoiceLineItems) +
                                                totalDocumentAllowance?.totalTaxDiscountCharge -
                                                totalPrePayments,
                                            Code: documentTaxCurrencyCode || 'SAR',
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className='table-total-wrapper-nine'>
                                    <p>{t('PAYABLE_ROUNDING_AMOUNT')}</p>
                                    <p className='table-total-unit'>
                                        {displayCurrency({
                                            Amount: 0,
                                            Code: documentTaxCurrencyCode || 'SAR',
                                        })}
                                    </p>
                                </div>
                                <div className='table-total-wrapper-ten'>
                                    <p>{t('PREPAID_AMOUNT')}</p>
                                    <p className='table-total-unit'>
                                        {displayCurrency({
                                            Amount: totalPrePayments,
                                            Code: documentTaxCurrencyCode || 'SAR',
                                        })}
                                    </p>
                                </div>
                                <div className='table-total-wrapper-one'>
                                    <p>{t('TOTAL_DISCOUNTS')}</p>
                                    <p>
                                        {displayCurrency({
                                            Amount:
                                                getTotalDiscountValue(invoiceLineItems) +
                                                totalDocumentAllowance?.totalDiscount,
                                            Code: documentTaxCurrencyCode || 'SAR',
                                        })}
                                    </p>
                                </div>
                                <div className='table-total-wrapper-two'>
                                    <p>{t('TOTAL_AMOUNT')}</p>
                                    <p className='table-total-unit'>
                                        {displayCurrency({
                                            Amount:
                                                getInvoiceCalculation(invoiceLineItems) +
                                                totalDocumentAllowance?.totalTaxDiscountCharge,
                                            Code: documentTaxCurrencyCode || 'SAR',
                                        })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ConfirmationModal
                    title='Are you sure?'
                    description='Do you really want to delete this item? This process cannot be undone.'
                    isDeleteModalOpen={isDeleteModalOpen}
                    handleDeleteOk={handleDeleteOk}
                    handleDeleteCancel={handleDeleteCancel}
                />
            </div>
        </>
    );
};

export default InvoiceLines;
