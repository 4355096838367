import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { useForm } from 'react-hook-form';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    formattedActionIds,
    formattedResourcePaths,
    getActionIds,
    getActions,
    getResourcePaths,
} from '@store/slices/actionSlice';
import { IActionIdPayload, IActionPayload } from '@models/action.model';
import { ActionAPI } from '@service/action.service';
import { values } from 'lodash';
import { EResourceType, GET_ALL_RESOURCE_FROM_SERVICE } from '@utils/resourceService';

const CreateActionId = () => {
    const [isIDModalOpen, setIsIDModalOpen] = useState(false);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const resourcePaths = useAppSelector(formattedResourcePaths);
    const actionIds = useAppSelector(formattedActionIds);

    useEffect(() => {
        dispatch(
            getActionIds({
                PageLimit: 20,
            }),
        );
    }, []);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue: setFormValue,
        formState: { errors, isValid },
    } = useForm<any>();

    const clearForm = () => {};

    const onSubmitForId = async (data: IActionIdPayload) => {
        try {
            const response = await ActionAPI.createActionId(data);
            message.success('Action Id Created Successfully');
            setIsIDModalOpen(false);
            dispatch(
                getActionIds({
                    PageLimit: 20,
                }),
            );
            reset();
        } catch {
            message.error('Action Id Create Failed');
        }

        // clearForm();
    };

    const handleOkForID = () => {
        handleSubmit(onSubmitForId)();
    };

    const handleCancelForID = () => {
        setIsIDModalOpen(false);
        reset();
    };

    const onSubmitForAction = async (data: IActionPayload) => {
        try {
            const response = await ActionAPI.createAction(data);
            message.success('Action Created Successfully');
            setIsActionModalOpen(false);
            dispatch(
                getActions({
                    PageLimit: 100,
                }),
            );
            reset();
        } catch (error: any) {
            if (error.response.data.Message) {
                message.error(error.response.data.Message);
            } else {
                message.error('Action Create Failed');
            }
        }
    };

    const handleOkForAction = () => {
        handleSubmit(onSubmitForAction)();
    };

    const handleCancelForAction = () => {
        setIsActionModalOpen(false);
        reset();
    };

    function handleMethod(value: any) {
        const selectedResourcePath = resourcePaths.find((data) => data.value === value);
        setFormValue('Method', selectedResourcePath?.method);
    }
    function handleResourceFrom(value: EResourceType) {
        dispatch(getResourcePaths(value));
        setFormValue('ResourcePath', '');
    }

    return (
        <>
            <CoreButton
                type='primary'
                text='Create ID'
                isFullWidth={true}
                onClick={() => setIsIDModalOpen(true)}
            />

            <CoreButton
                type='primary'
                text='Create Action'
                isFullWidth={true}
                onClick={() => setIsActionModalOpen(true)}
            />

            <Modal
                width={500}
                open={isIDModalOpen}
                onOk={handleOkForID}
                okText='Create'
                onCancel={handleCancelForID}
                maskClosable={false}
            >
                <form onSubmit={handleSubmit(onSubmitForId)}>
                    <div className='add-user-title'>
                        <CoreTitle tag='h5' text={'Create Action ID'} />
                    </div>
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='ActionId'
                        level='Action Id'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />
                </form>
            </Modal>
            <Modal
                width={500}
                open={isActionModalOpen}
                onOk={handleOkForAction}
                okText='Create'
                onCancel={handleCancelForAction}
                maskClosable={false}
            >
                <form onSubmit={handleSubmit(onSubmitForAction)}>
                    <div className='add-user-title'>
                        <CoreTitle tag='h5' text={'Create Action'} />
                    </div>
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='ActionName'
                        level='Action Name'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />
                    <InlineGenericSelect
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='ActionId'
                        level='Action ID'
                        options={actionIds}
                        setFormValue={setFormValue}
                        marginBottom={5}
                        errorMessageSize='small'
                        rules={{
                            required: true,
                        }}
                    />
                    <InlineGenericSelect
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='ResourceFrom'
                        level='Resource From'
                        options={GET_ALL_RESOURCE_FROM_SERVICE}
                        setFormValue={setFormValue}
                        marginBottom={5}
                        errorMessageSize='small'
                        onChange={(value) => {
                            handleResourceFrom(value);
                        }}
                        rules={{
                            required: true,
                        }}
                    />
                    <InlineGenericSelect
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='ResourcePath'
                        level='Resource Path'
                        options={resourcePaths}
                        setFormValue={setFormValue}
                        marginBottom={5}
                        errorMessageSize='small'
                        onChange={(value) => {
                            handleMethod(value);
                        }}
                        rules={{
                            required: true,
                        }}
                    />
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Method'
                        level='Method'
                        disabled={true}
                    />
                </form>
            </Modal>
        </>
    );
};
export default CreateActionId;
