import React from 'react';
import './CoreIconButton.scss'
interface ICoreIconButton {
    variant: 'round' | 'square';
    icon: React.ReactNode;
}
const CoreIconButton = (props: ICoreIconButton) => {
    const {icon, variant} = props;
    return (
        <div className={`core-icon-button ${variant}`}>
            {icon}
        </div>
    );
};

export default CoreIconButton;
