import './ValidateTaxTab.scss';
import NoteIcon from '../../svg/NoteIcon';
import DownloadingIcon from '../../svg/DownloadingIcon';
import CheckedIcon from '../../svg/CheckedIcon';
import { CoreButton } from '@packages';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const ValidateTaxTab = () => {
    const screens = useBreakpoint();
    const buttonSize = screens.xs ? 'small' : 'middle';

    return (
        <div className='validate-tax-wrapper'>
            <div className='validate-tax-single-section'>
                <div className='single-section-text-area'>
                    <div className='single-section-icon-wrapper'>
                        <NoteIcon />
                    </div>
                    <p className='single-section-title'>Confirm your customer’s tax status</p>
                    <p className='single-section-sub-title'>
                        To calculate tax on cross-border sales, you must know if your buyer is a
                        business or a consumer - and that they've got a valid/active tax ID.
                    </p>
                </div>
                <div className='single-section-text-area'>
                    <div className='single-section-icon-wrapper'>
                        <DownloadingIcon />
                    </div>
                    <p className='single-section-title'>
                        Know your vendor's tax status on purchases
                    </p>
                    <p className='single-section-sub-title'>
                        To recover taxes charged to you on purchases, you must confirm that your
                        supplier is a tax-registered business with a valid/active tax ID.
                    </p>
                </div>
                <div className='single-section-text-area'>
                    <div className='single-section-icon-wrapper'>
                        <CheckedIcon />
                    </div>
                    <p className='single-section-title'>
                        Ensure your Data Sharing report contains valid tax IDs
                    </p>
                    <p className='single-section-sub-title'>
                        Digital platforms and marketplaces must confirm tax ID numbers before
                        fulfilling their reporting obligations (e.g. DAC7).
                    </p>
                </div>
            </div>
            <div className='validate-tax-action'>
                <CoreButton
                    size={buttonSize}
                    text='Explore Lookup'
                    type='primary'
                    icon={<ArrowRightOutlined />}
                />
            </div>
        </div>
    );
};

export default ValidateTaxTab;
