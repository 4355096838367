import { Collapse } from 'antd';
import './Faq.scss';
import { CollapseProps } from 'antd/lib';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const Faq = () => {
    const dynamicContent = [
        {
            key: '1',
            label: 'What are E-Invoicing enforcement dates in KSA?',
            content:
                'E-Invoicing will be implemented in two phases:- Phase One, known as the Generation phase and enforceable as of December 4, 2021.- Phase Two, known as the Integration phase and enforceable starting from January 1, 2023 and implemented in waves by targeted taxpayer groups. Taxpayers will be notified by ZATCA on the date of their integration at least 6 months in advance.',
        },
        {
            key: '2',
            label: 'How can I implement E-Invoicing in KSA?',
            content:
                'E-Invoicing will be implemented in two phases:- Phase One, known as the Generation phase and enforceable as of December 4, 2021.- Phase Two, known as the Integration phase and enforceable starting from January 1, 2023 and implemented in waves by targeted taxpayer groups. Taxpayers will be notified by ZATCA on the date of their integration at least 6 months in advance.',
        },
        {
            key: '3',
            label: 'Is E-Invoicing mandatory for me?',
            content:
                'E-Invoicing will be implemented in two phases:- Phase One, known as the Generation phase and enforceable as of December 4, 2021.- Phase Two, known as the Integration phase and enforceable starting from January 1, 2023 and implemented in waves by targeted taxpayer groups. Taxpayers will be notified by ZATCA on the date of their integration at least 6 months in advance.',
        },
        {
            key: '4',
            label: 'When to issue a Tax invoice and Simplified Tax Invoice?',
            content:
                'E-Invoicing will be implemented in two phases:- Phase One, known as the Generation phase and enforceable as of December 4, 2021.- Phase Two, known as the Integration phase and enforceable starting from January 1, 2023 and implemented in waves by targeted taxpayer groups. Taxpayers will be notified by ZATCA on the date of their integration at least 6 months in advance.',
        },
        {
            key: '5 ',
            label: 'Do I need to integrate my E-Invoicing solution from Dec 4th?',
            content:
                'E-Invoicing will be implemented in two phases:- Phase One, known as the Generation phase and enforceable as of December 4, 2021.- Phase Two, known as the Integration phase and enforceable starting from January 1, 2023 and implemented in waves by targeted taxpayer groups. Taxpayers will be notified by ZATCA on the date of their integration at least 6 months in advance.',
        },
    ];

    const items: CollapseProps['items'] = dynamicContent.map((item) => ({
        key: item.key,
        label: item.label,
        children: <p>{item.content}</p>,
    }));

    return (
        <div className='faq-wrapper'>
            <h1 className='faq-title'>Frequently Asked Questions</h1>
            <div className='collapse-section'>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIconPosition='end'
                    expandIcon={({ isActive }) =>
                        isActive ? (
                            <MinusOutlined style={{ fontSize: '22px' }} />
                        ) : (
                            <PlusOutlined style={{ fontSize: '22px' }} />
                        )
                    }
                    items={items}
                />
            </div>
            <p className='additional-info'>
                Connect your business ERP system with our cutting edge E-Invoicing solution with
                minimal efforts. Speak to an expert (button).
            </p>
        </div>
    );
};

export default Faq;
