import { Button, Tabs, TabsProps } from 'antd';
import './InventoryDetails.scss';
import { CoreTitle } from '@packages';
import { CloseOutlined } from '@ant-design/icons';
import InventoryOverview from '../../../inventory/inventory-view/inventory-overview/InventoryOverview';
import { useDispatch } from 'react-redux';
import { clearSelectedInventory, selectSelectedInventory } from '@store/slices/InventoriesSlice';
import { useAppSelector } from '@store/hooks';
import DropDownEdit from './DropDownEdit';
import { useTranslation } from 'react-i18next';

const onChange = (key: string) => {
    console.log(key);
};

const InventoryDetails = () => {
    const { t } = useTranslation();
    const inventory = useAppSelector(selectSelectedInventory);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `${t('OVERVIEW')}`,
            children: <InventoryOverview />,
        },
    ];

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(clearSelectedInventory());
    };

    return (
        <div className='inventory-details-wrapper'>
            <div className='inventory-details-header'>
                <CoreTitle tag='h6' text={inventory?.Name} />
                <div className='inventory-details-header-buttons'>
                    <DropDownEdit />
                    <Button icon={<CloseOutlined />} onClick={handleClose} />
                </div>
            </div>
            <div className='inventory-details-tab-wrapper'>
                <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
            </div>
        </div>
    );
};

export default InventoryDetails;
