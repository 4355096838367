import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import './CustomerFilterInputs.scss';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { ICustomerGetPayload } from '@models/customer.model';
import { useTranslation } from 'react-i18next';

export type ICustomerFilterData = Omit<ICustomerGetPayload, 'PageLimit' | 'Search' | 'PageNumber'>;

interface ICustomerFiltersProps {
    handleFilterSubmit: (data: ICustomerFilterData) => void;
    defaultValues?: ICustomerFilterData;
}


const CustomerFilterInputs = ({ handleFilterSubmit, defaultValues }: ICustomerFiltersProps) => {
    const { t } = useTranslation();
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm<ICustomerFilterData>({ mode: 'onTouched' });

    const onSubmit = (data: ICustomerFilterData) => {
        handleFilterSubmit(data);
    };

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    return (
        <div className='customer-filter-wrapper'>
            <div className='margin-bottom-20'>
                <CoreTitle tag='h6' text={t('FILTERS')} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={10}>
                    <Col span={12}>
                        <InlineGenericSelect
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='CustomerType'
                            level={t('CUSTOMER_TYPE')}
                            placeHolder={t('CUSTOMER_TYPE')}
                            options={[
                                { label: `${t('BUSINESS')}`, value: 'Business' },
                                { label: `${t('INDIVIDUAL')}`, value: 'Individual' },
                            ]}
                            setFormValue={setValue}
                            errorMessageSize='small'
                            allowClear={true}
                            marginBottom={-5}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericInput
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Email'
                            level={t('EMAIL')}
                            errorMessageSize='small'
                            marginBottom={10}
                        />
                    </Col>
                </Row>

                <InlineGenericInput
                    inputCol={24}
                    levelCol={24}
                    control={control}
                    errors={errors}
                    dataKey='PhoneNumber'
                    level={t('PHONE_NUMBER')}
                    errorMessageSize='small'
                    marginBottom={10}
                />
                <InlineGenericInput
                    inputCol={24}
                    levelCol={24}
                    control={control}
                    errors={errors}
                    dataKey='LegalRegistrationNameEn'
                    level={t('LEGAL_REGISTRATION_NAME_ENGLISH')}
                    errorMessageSize='small'
                    marginBottom={10}
                />
                <InlineGenericInput
                    inputCol={24}
                    levelCol={24}
                    control={control}
                    errors={errors}
                    dataKey='VatRegistrationNumber'
                    level={t('VAT_REGISTRATION_NUMBER')}
                    type='number'
                    errorMessageSize='small'
                    marginBottom={10}
                />
                <Row justify='end'>
                    <CoreButton
                        type='primary'
                        htmlType='submit'
                        isFullWidth={false}
                        text={t('APPLY_NOW')}
                    />
                </Row>
            </form>
        </div>
    );
};

export default CustomerFilterInputs;
