import BillingCard from '../billing-card/BillingCard';
import './BillingList.scss';

export const addresses = [
    {
        addressId: 1,
        addressName: "Mujammal's House",
        addressDetails: 'Kumarpra Road, Road no 3, Sylhe, Bangladesh',
        isDefault: true,
    },
    {
        addressId: 2,
        addressName: 'Office',
        addressDetails: 'Gulshan Avenue, Dhaka, Bangladesh',
        isDefault: false,
    },
];

const BillingList = () => {
    return (
        <div className='billing-list-wrapper'>
            {addresses.map((address) => (
                <BillingCard
                    key={address.addressId}
                    addressName={address.addressName}
                    addressDetails={address.addressDetails}
                    addressId={address.addressId}
                    isDefault={address.isDefault}
                />
            ))}
        </div>
    );
};

export default BillingList;
