import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const PropertiesSvg = () => (
    <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M9.66667 1.8913V5.33341C9.66667 5.80012 9.66667 6.03348 9.75749 6.21174C9.83739 6.36854 9.96487 6.49602 10.1217 6.57592C10.2999 6.66675 10.5333 6.66675 11 6.66675H14.4421M5.5 12.5H10.5M9.66667 1.66669H5.33333C3.9332 1.66669 3.23314 1.66669 2.69836 1.93917C2.22795 2.17885 1.8455 2.56131 1.60582 3.03171C1.33333 3.56649 1.33333 4.26656 1.33333 5.66669V14.3334C1.33333 15.7335 1.33333 16.4336 1.60582 16.9683C1.8455 17.4387 2.22795 17.8212 2.69836 18.0609C3.23314 18.3334 3.9332 18.3334 5.33333 18.3334H10.6667C12.0668 18.3334 12.7669 18.3334 13.3016 18.0609C13.772 17.8212 14.1545 17.4387 14.3942 16.9683C14.6667 16.4336 14.6667 15.7335 14.6667 14.3334V6.66669L9.66667 1.66669Z'
            stroke='currentColor'
            strokeWidth='1.67'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
const PropertiesIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={PropertiesSvg} {...props} />
);

export default PropertiesIcon;
