import { IInvoiceItemCharges, IInvoiceLinesItem } from '@models/invoice.model';

export function getInvoiceCalculation(items: IInvoiceLinesItem[]): number {
    return items
        .map((invoice) => invoice.RoundingAmount || 0)
        .reduce((total, current) => total + current || 0, 0);
}

export function getInvoiceTotalTaxCalculation(items: IInvoiceLinesItem[]): number {
    return items
        .map((invoice) => invoice?.TaxAmount || 0)
        .reduce((total, current) => total + current || 0, 0);
}

export function getTotalTaxExclusiveAmount(items: IInvoiceLinesItem[]): number {
    return items
        .map((invoice) => invoice?.LineExtensionAmount || 0)
        .reduce((total, current) => total + current || 0, 0);
}

export function getTotalTaxInclusiveAmount(items: IInvoiceLinesItem[]): number {
    return getInvoiceTotalTaxCalculation(items) + getTotalTaxExclusiveAmount(items);
}

export function getTotalRoundingAmount(items: IInvoiceLinesItem[]): number {
    return items
        .map((invoice) => getRoundingAmount(invoice) || 0)
        .reduce((total, current) => total + current || 0, 0);
}

export function getInvoiceTotalDiscountCalculation(items: IInvoiceLinesItem[]): number {
    return items
        .map((invoice) => invoice.DiscountValue || 0)
        .reduce((total, current) => total + current || 0, 0);
}

export function getInvoiceItemCalculationData(data: IInvoiceLinesItem): IInvoiceLinesItem {
    const LineExtensionAmount = getLineExtensionAmount(data);
    const TaxAmount = getTaxAmount({ ...data, LineExtensionAmount });
    const RoundingAmount = getRoundingAmount({
        ...data,
        LineExtensionAmount,
        TaxAmount,
    });
    return { ...data, LineExtensionAmount, TaxAmount, RoundingAmount };
}

export function getLineExtensionAmount(data: IInvoiceLinesItem): number {
    const allowanceChange = getChargeDiscountAdditionValue(data.AllowanceCharges || [], 'both');
    const lineExtensionAmount =
        (data.Quantity / data.BaseQuantity) * data.PriceAmount + allowanceChange || 0;

    return lineExtensionAmount;
}

export function getRoundingAmount(data: IInvoiceLinesItem): number {
    const roundingAmount = data?.LineExtensionAmount + data?.TaxAmount;
    return roundingAmount;
}

export function getTaxAmount(data: IInvoiceLinesItem): number {
    const lineExtensionAmount = data?.LineExtensionAmount;
    return (lineExtensionAmount * data?.TaxPercent) / 100 || 0;
}

export function getTotalChargeDiscountAdditionValue(data: IInvoiceLinesItem[]): number {
    return data.reduce((acc, lines) => {
        return acc + getChargeDiscountAdditionValue(lines?.AllowanceCharges || [], 'both');
    }, 0);
}

export function getTotalDiscountValue(data: IInvoiceLinesItem[]): number {
    return data.reduce((acc, lines) => {
        return acc + getChargeDiscountAdditionValue(lines?.AllowanceCharges || [], 'discount');
    }, 0);
}

export function getTotalChargeValue(data: IInvoiceLinesItem[]): number {
    return data.reduce((acc, lines) => {
        return acc + getChargeDiscountAdditionValue(lines?.AllowanceCharges || [], 'charge');
    }, 0);
}

export function getPayableRoundingAmount(data: IInvoiceLinesItem[]): number {
    const taxAmountFromLineTotal = getInvoiceTotalTaxCalculation(data);
    const taxAmountByTaxable = getTotalTaxExclusiveAmount(data);
    const allowanceChange = getTotalChargeDiscountAdditionValue(data);
    const payableRoundingAmount = taxAmountByTaxable - taxAmountFromLineTotal + allowanceChange;
    return payableRoundingAmount;
}
export function getChargeDiscountAdditionValue(
    data: IInvoiceItemCharges[],
    type: AdditionType,
): number {
    if (!data) {
        return 0;
    }
    return data?.reduce((acc, item) => {
        if (item.ChargeIndicator) {
            if (type === 'discount') {
                return acc;
            }
            if (type === 'charge') {
                return acc + item.Amount;
            }
            return acc + item.Amount;
        } else {
            if (type === 'discount') {
                return acc + item.Amount;
            }
            if (type === 'charge') {
                return acc;
            }
            return acc - item.Amount;
        }
    }, 0);
}

type AdditionType = 'discount' | 'charge' | 'both';
