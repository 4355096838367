import { FC, useMemo, useState, useEffect } from 'react';
import { CoreButton, CoreStatus, CoreTitle } from '@packages';
import { LeftOutlined, MoreOutlined } from '@ant-design/icons';
import { Table, Modal, Row, Col, MenuProps, Dropdown, message, Button, Tooltip } from 'antd';
import './AddDevices.scss';
import { ICountry } from '@models/generic.model';
import countryList from 'react-select-country-list';
import { useForm } from 'react-hook-form';
import {
    IAddDevicePayload,
    IDevice,
    IDeviceTableData,
    ILogoData,
} from '@models/organization.model';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { defaultCountryCode } from '@config/default-value';
import { RegexValidators } from '@config/regex-validator-constant';
import { INVOICE_TYPE_OPTIONS } from '@utils/InvoiceTypeOptions';
import { useNavigate, useParams } from 'react-router-dom';
import { getCountryNameFromCode } from '@utils/getCountryNameFromCode';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { ColumnsType } from 'antd/es/table';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { OrganizationAPI } from '@service/organization.service';
import { unwrapResult } from '@reduxjs/toolkit';
import { getDevices, selectDevicesInfo } from '@store/slices/deviceSlice';
import { DeviceAPI } from '@service/device.service';
import GenericUnitName from '@components/common/GenericUnitName';
import { DEFAULT_DELAY_TIME } from '@config/constants';
import { getActiveEntities, getEntities, selectActiveEntityInfo } from '@store/slices/entitySlice';
import { useTranslation } from 'react-i18next';
import GenericCheckbox from '@components/common/GenericCheckbox';
import { CoreFileLogo } from '@packages';
import { ILogoInfo } from 'packages/CoreFileCropper/CoreFileLogo';
import AddPhaseOneDevice from './AddPhaseOneDevice';
import { getSubscription } from '@store/slices/subscribeSlice';
import { UtcToLocalDate } from '@utils/date';

const AddDevices: FC = () => {
    const { t } = useTranslation();
    const { isLoading, organization } = useSelector((state: RootState) => state.organization);
    const { isLoading: isDevicesLoading, data: devices } = useAppSelector(selectDevicesInfo);
    const [logoInfo, setLogoInfo] = useState<ILogoInfo>({} as ILogoInfo);

    const { status, data: activeEntity } = useAppSelector(selectActiveEntityInfo);
    const isItTinNumber = () => {
        return organization?.VatRegistrationNumber[10] === '1';
    };

    const [deviceList, setDeviceList] = useState<IDeviceTableData[]>([]);
    const [isAddDevice, setAddDevice] = useState<boolean>(false);
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);
    const [isDelayLoading, setDelayLoading] = useState(false);
    const [deviceId, setDeviceId] = useState<string>('');
    const [isActiveModalOpen, setActiveModalOpen] = useState(false);
    const [isDeactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const { entities } = useSelector((state: RootState) => state.entity);

    const { organizationId = '' } = useParams();
    const dispatch = useAppDispatch();
    getCountryNameFromCode('BD');

    const {
        control,
        handleSubmit,
        reset,
        setValue: setFormValue,
        watch,
        formState: { errors },
    } = useForm<IAddDevicePayload>({
        mode: 'onTouched',
        defaultValues: {
            Country: defaultCountryCode,
            Location: defaultCountryCode,
            IsManualIRN: false,
        },
    });

    const formatDeviceListData = () => {
        const data: IDeviceTableData[] = [];
        if (devices.length) {
            devices.forEach((device: IDevice, index) => {
                data.push({
                    key: index + 1 || '',
                    DeviceId: device.DeviceId,
                    Category: device?.Category || '---',
                    CommonName: device?.CommonName || '---',
                    DeviceName: device?.DeviceName || '---',
                    Country: device?.Country || '---',
                    Email: device?.Email || '---',
                    InvoiceType: device?.InvoiceType || '---',
                    Location: device?.Location || '---',
                    OrganizationUnitName: device?.OrganizationUnitName || '---',
                    HasCSID: device.HasCSID || false,
                    IsActive: device.IsActive || false,
                    ActionButtons: device.IsActive || false,
                    UpdatedAt: device.UpdatedAt || '---',
                    EntityID: device.EntityId || '---',
                });
            });
        }
        setDeviceList(data);
    };

    useEffect(() => {
        if (!isDevicesLoading && devices) {
            formatDeviceListData();
        }
    }, [devices]);

    useEffect(() => {
        if (organizationId) {
            dispatch(getActiveEntities(organizationId));
            dispatch(getDevices(organizationId));
            dispatch(getEntities(organizationId));
        }
    }, []);

    useEffect(() => {
        if (isDevicesLoading) {
            setDelayLoading(true);
            setTimeout(() => {
                dispatch(getActiveEntities(organizationId));
                dispatch(getDevices(organizationId));
                setDelayLoading(false);
            }, DEFAULT_DELAY_TIME);
        }
    }, [isAddDevice]);

    const onSubmit = async (data: IAddDevicePayload) => {
        const { url, height, width } = logoInfo || {};
        const Logo: ILogoData = {
            ShowOnPdf: data?.Logo?.ShowOnPdf ?? false,
            Data: url,
            Height: height,
            Width: width,
        };
        const payload: IAddDevicePayload = { ...data, Logo };
        !url && delete payload.Logo;
        payload.OrganizationId = organizationId;
        setAddDevice(true);
        await DeviceAPI.addDevice(payload)
            .then(unwrapResult)
            .then((result) => {
                message.success(t('DEVICE_SUCCESSFULLY_ADDED'));
                dispatch(getSubscription());
                setAddDevice(false);
                setIsModalOpen(false);
                dispatch(getDevices(organizationId));
                reset({
                    Category: '',
                    CommonName: '',
                    DeviceName: '',
                    UpdatedAt: '',
                    Country: defaultCountryCode,
                    Email: '',
                    EntityId: '',
                    InvoiceType: '',
                    Location: defaultCountryCode,
                    OrganizationUnitName: '',
                    Otp: '',
                    SerialNumber: '',
                    OrganizationId: '',
                    SimplifiedCreditNotePrefix: '',
                    SimplifiedDebitNotePrefix: '',
                    SimplifiedInvoicePrefix: '',
                    StandardCreditNotePrefix: '',
                    StandardDebitNotePrefix: '',
                    StandardInvoicePrefix: '',
                    IsManualIRN: false,
                });
            })
            .catch((err) => {
                setAddDevice(false);
                message.error(t('DEVICE_ADDITION_FAILED'));
            });
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        handleSubmit(onSubmit)();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleDeviceStatus = (e: any, DeviceId: string, IsActive: boolean) => {
        e.stopPropagation();
        setDeviceId(DeviceId);
        if (!IsActive) {
            setActiveModalOpen(true);
        } else {
            setDeactivateModalOpen(true);
        }
    };
    const handleUpdateLogo = (e: any, DeviceId: string) => {
        e.stopPropagation();
        navigate(`/organizations/${organizationId}/device/update/${DeviceId}`);
    };

    const handleDropdownItemClick = async (e: any, deviceId: string) => {
        if (parseInt(e.key) === 1) {
            try {
                await DeviceAPI.removeDevice({ DeviceId: deviceId });
                message.success('Device Removed Successfully');
                await dispatch(getDevices(organizationId));
                await dispatch(getSubscription());
            } catch {
                message.error('Device Removed Failed');
            }
        }
        if (parseInt(e.key) === 2) {
            try {
                await DeviceAPI.enableDevice({ DeviceId: deviceId });
                message.success('Device Enable Successfully');
                await dispatch(getDevices(organizationId));
            } catch {
                message.error('Device Enable Failed');
            }
        }
        if (parseInt(e.key) === 3) {
            try {
                await DeviceAPI.disableDevice({ DeviceId: deviceId });
                message.success('Device Disable Successfully');
                await dispatch(getDevices(organizationId));
            } catch {
                message.error('Device Disable Failed');
            }
        }
    };

    const phaseOneColumns: ColumnsType<IDeviceTableData> = [
        {
            title: `${t('Device Name')}`,
            dataIndex: 'DeviceName',
        },
        {
            title: `${t('Invoice Type')}`,
            dataIndex: 'InvoiceType',
        },
        {
            title: `${t('HAS_CSID')}`,
            dataIndex: 'HasCSID',
            render: (text: boolean) => <span>{text ? t('YES') : t('NO')}</span>,
        },
        {
            title: `${t('LAST_UPDATED_DATE')}`,
            dataIndex: 'UpdatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            title: `${t('ACTIVE_STATUS')}`,
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? t('ACTIVE') : t('INACTIVE')}
                />
            ),
        },
    ];

    const phaseTwoColumns: ColumnsType<IDeviceTableData> = [
        {
            title: `${t('Device Name')}`,
            dataIndex: 'DeviceName',
        },
        {
            title: entities.length > 0 ? `${t('ENTITY_ID')}` : `${t('ORGANIZATION_ID')}`,
            dataIndex: 'EntityId',
            render: (text, data) => (
                <Tooltip
                    title={entities.length > 0 ? data?.EntityID : organization?.OrganizationId}
                >
                    {entities.length > 0 ? (
                        <span>{data.EntityID?.slice(-5)}</span>
                    ) : (
                        <span>{organization?.OrganizationId?.slice(-5)}</span>
                    )}
                </Tooltip>
            ),
        },
        {
            title: `${t('COMMON_NAME')}`,
            dataIndex: 'CommonName',
        },
        {
            title: `${t('ORGANIZATION_TIN_NUMBER')}`,
            dataIndex: 'OrganizationUnitName',
        },
        {
            title: `${t('INVOICE_TYPE')}`,
            dataIndex: 'InvoiceType',
            render: (InvoiceType: string) => {
                const invoiceType = INVOICE_TYPE_OPTIONS.find(
                    (data) => data.value === InvoiceType,
                )?.label;
                return <span>{t(`${invoiceType}`)}</span>;
            },
        },
        {
            title: `${t('HAS_CSID')}`,
            dataIndex: 'HasCSID',
            render: (text: boolean) => <span>{text ? t('YES') : t('NO')}</span>,
        },
        {
            title: `${t('ACTIVE_STATUS')}`,
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? t('ACTIVE') : t('INACTIVE')}
                />
            ),
        },
    ];

    const handleRowClick = (record: any) => {
        navigate(`/organizations/${organizationId}/device/${record.key}`);
    };

    const navigate = useNavigate();

    const next = () => {
        OrganizationAPI.postOrganizationWorkflow(organizationId).then(() => {
            navigate(`/organization-setup/${organizationId}/complete`);
        });
    };
    const prev = () => {
        navigate(`/organization-setup/${organizationId}/invoice-phase`);
    };

    const saveAndClose = () => {
        navigate(`/organizations`);
    };

    const entityLength = activeEntity.length === 0 ? 1 : activeEntity.length;

    const isSimplifiedBoth = watch('InvoiceType') === '0100' || watch('InvoiceType') === '1100';
    const isStandardBoth = watch('InvoiceType') === '1000' || watch('InvoiceType') === '1100';
    const IsManualIrn = watch('IsManualIRN');

    return (
        <>
            <div className='step-title-area'>
                <div className='left-area'>
                    <CoreButton text='' onClick={prev} icon={<LeftOutlined />} />
                    <div className='content'>
                        <CoreTitle tag='h6' text={t('ADD_ORGANIZATION_DETAILS')} />
                        <p>{t('STEP_4_of_5')}</p>
                    </div>
                </div>
                <div className='step-button-group'>
                    <CoreButton text={t('SAVE_&_CLOSED')} onClick={saveAndClose} />
                    <CoreButton type='primary' onClick={next} text={t('CONTINUE')} />
                </div>
            </div>
            <div className='step-area'>
                <div className='stepper-content'>
                    <div className='add-devices-wrapper'>
                        <CoreButton
                            className='add-button'
                            onClick={() => setIsModalOpen(true)}
                            text={t('ADD_DEVICE')}
                            disabled={deviceList.length === entityLength * 3}
                        />

                        <div className='general-device-limit'>
                            {entityLength === 0 ? (
                                <p className='warning-label'>
                                    {t(
                                        'YOU_HAVE_RECHED_THE_MAXIMUM_DEVICE_ALLOWED_FOR_THE_ORGANIZATION',
                                    )}
                                </p>
                            ) : deviceList.length === entityLength * 3 ? (
                                <p className='warning-label'>
                                    {t(
                                        'YOU_HAVE_RECHED_THE_MAXIMUM_DEVICE_ALLOWED_FOR_(3-PER-ENTITY)',
                                    )}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>

                        <div>
                            <Table
                                loading={isLoading ? true : isDelayLoading ? true : false}
                                columns={
                                    organization?.InvoicePhase === 1
                                        ? phaseOneColumns
                                        : phaseTwoColumns
                                }
                                dataSource={deviceList}
                                rowClassName='cursor-pointer'
                                pagination={false}
                            />
                        </div>

                        {organization?.InvoicePhase === 1 ? (
                            <AddPhaseOneDevice
                                organizationId={organizationId}
                                isModalOpen={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                isAddDevice={isAddDevice}
                                control={control}
                                watch={watch}
                                errors={errors}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                setFormValue={setFormValue}
                                logoInfo={logoInfo}
                                setLogoInfo={setLogoInfo}
                            />
                        ) : (
                            <Modal
                                width={890}
                                open={isModalOpen}
                                onOk={handleOk}
                                okText={t('SAVE')}
                                onCancel={handleCancel}
                                cancelText={t('CANCEL')}
                                maskClosable={false}
                                confirmLoading={isAddDevice}
                            >
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='add-device-title'>
                                        <CoreTitle tag='h4' text={t('ADD_DEVICE')} />
                                    </div>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <InlineGenericInput
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='Email'
                                                level={t('EMAIL_ADDRESS')}
                                                marginBottom={20}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: `${t('EMAIL_FIELD_IS_REQUIRED')}`,
                                                    },
                                                    pattern: {
                                                        value: RegexValidators.EMAIL,
                                                        message: t('INVALID_ENTRY_EG'),
                                                    },
                                                }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <InlineGenericInput
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='SerialNumber'
                                                level={t('SERIAL_NUMBER')}
                                                marginBottom={20}
                                                rules={{
                                                    required: true,
                                                    pattern: {
                                                        value: RegexValidators.SERIAL_NUMBER,
                                                        message:
                                                            'Serial number pattern not matching',
                                                    },
                                                }}
                                                tooltip='Example: 1-anything|2-anything|3-anything'
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <InlineGenericInput
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='DeviceName'
                                                level={'Device Name'}
                                                marginBottom={20}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <InlineGenericInput
                                                tooltip={t(
                                                    'THE_COMMON_NAME_REFERS_TO_THE_EVERYDAY_OR_COMMONLY_USED_NAME_OF_YOUR_ORGANIZATION',
                                                )}
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='CommonName'
                                                level={t('COMMON_NAME')}
                                                marginBottom={20}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            {isItTinNumber() ? (
                                                <InlineGenericInput
                                                    tooltip={t(
                                                        'THE_ORGANIZATION_TAX_IDENTIFICATION_NUMBER_TIN_IS_A_UNIQUE_IDENTIFICATION',
                                                    )}
                                                    control={control}
                                                    errors={errors}
                                                    levelCol={24}
                                                    inputCol={24}
                                                    dataKey='OrganizationUnitName'
                                                    level={t('ORGANIZATION_TIN_NUMBER')}
                                                    type={'otherSellerId'}
                                                    marginBottom={20}
                                                    rules={{
                                                        required: true,
                                                        pattern: RegexValidators.TIN_NUMBER,
                                                    }}
                                                />
                                            ) : (
                                                <GenericUnitName
                                                    control={control}
                                                    errors={errors}
                                                    levelCol={24}
                                                    inputCol={24}
                                                    dataKey='OrganizationUnitName'
                                                    level={t('ORGANIZATION_UNIT_NAME')}
                                                    marginBottom={20}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <InlineGenericSelect
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='Country'
                                                level={t('COUNTRY')}
                                                options={countryOptions}
                                                rules={{
                                                    required: true,
                                                }}
                                                setFormValue={setFormValue}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <InlineGenericSelect
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='Location'
                                                level={t('BUSINESS_LOCATION')}
                                                placeHolder={t('SELECT_BUSINESS_LOCATION')}
                                                options={countryOptions}
                                                rules={{
                                                    required: true,
                                                }}
                                                setFormValue={setFormValue}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <InlineGenericInput
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='Category'
                                                level={t('BUSINESS_SECTOR')}
                                                placeHolder={t('BUSINESS_SECTOR')}
                                                marginBottom={20}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <InlineGenericSelect
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='InvoiceType'
                                                level={t('INVOICE_TYPE')}
                                                placeHolder={t('SELECT_INVOICE_TYPE')}
                                                options={INVOICE_TYPE_OPTIONS}
                                                sorting={false}
                                                rules={{
                                                    required: true,
                                                }}
                                                setFormValue={setFormValue}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <InlineGenericSelect
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='EntityId'
                                                level={t('ENTITY')}
                                                placeHolder={t('SELECT_ENTITY')}
                                                options={activeEntity}
                                                rules={{
                                                    required: activeEntity.length > 0,
                                                }}
                                                loading={status === 'loading' ? true : false}
                                                setFormValue={setFormValue}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <InlineGenericInput
                                                tooltip={t(
                                                    'OTP_STANDS_FOR_ONE_TIME_PASSWORD_IT_IS_A_TEMPORARY_CODE_USED_FOR_VERIFYING',
                                                )}
                                                control={control}
                                                errors={errors}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='Otp'
                                                level={t('OTP')}
                                                marginBottom={20}
                                                type='number'
                                                rules={{
                                                    required: true,
                                                    maxLength: {
                                                        value: 6,
                                                        message: t('OTP_ERROR_MESSAGE'),
                                                    },
                                                    minLength: {
                                                        value: 6,
                                                        message: t('OTP_ERROR_MESSAGE'),
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        {!IsManualIrn && (
                                            <>
                                                {isSimplifiedBoth && (
                                                    <>
                                                        <Col span={12}>
                                                            <InlineGenericInput
                                                                control={control}
                                                                errors={errors}
                                                                levelCol={24}
                                                                inputCol={24}
                                                                dataKey='SimplifiedInvoicePrefix'
                                                                level={t(
                                                                    'SIMPLIFIED_INVOICE_PREFIX',
                                                                )}
                                                                marginBottom={20}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <InlineGenericInput
                                                                control={control}
                                                                errors={errors}
                                                                levelCol={24}
                                                                inputCol={24}
                                                                dataKey='SimplifiedCreditNotePrefix'
                                                                level={t(
                                                                    'SIMPLIFIED_CREDIT_NOTE_PREFIX',
                                                                )}
                                                                marginBottom={20}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <InlineGenericInput
                                                                control={control}
                                                                errors={errors}
                                                                levelCol={24}
                                                                inputCol={24}
                                                                dataKey='SimplifiedDebitNotePrefix'
                                                                level={t(
                                                                    'SIMPLIFIED_DEBIT_NOTE_PREFIX',
                                                                )}
                                                                marginBottom={20}
                                                            />
                                                        </Col>
                                                    </>
                                                )}
                                                {isStandardBoth && (
                                                    <>
                                                        <Col span={12}>
                                                            <InlineGenericInput
                                                                control={control}
                                                                errors={errors}
                                                                levelCol={24}
                                                                inputCol={24}
                                                                dataKey='StandardInvoicePrefix'
                                                                level={t('STANDARD_INVOICE_PREFIX')}
                                                                marginBottom={20}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <InlineGenericInput
                                                                control={control}
                                                                errors={errors}
                                                                levelCol={24}
                                                                inputCol={24}
                                                                dataKey='StandardCreditNotePrefix'
                                                                level={t(
                                                                    'STANDARD_CREDIT_NOTE_PREFIX',
                                                                )}
                                                                marginBottom={20}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <InlineGenericInput
                                                                control={control}
                                                                errors={errors}
                                                                levelCol={24}
                                                                inputCol={24}
                                                                dataKey='StandardDebitNotePrefix'
                                                                level={t(
                                                                    'STANDARD_DEBIT_NOTE_PREFIX',
                                                                )}
                                                                marginBottom={20}
                                                            />
                                                        </Col>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <Col className='checkbox-col' span={12}>
                                            <GenericCheckbox
                                                tooltip={t(
                                                    'CHECK_THIS_BOX_IF_YOU_WANT_TO_MANUALLY_CREATE_INVOICE_REFERENCE_NUMBER_IRNS',
                                                )}
                                                control={control}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='IsManualIRN'
                                                level={t('CREATE_MANUAL_IRN')}
                                            />
                                        </Col>
                                        <Col className='checkbox-col' span={12}>
                                            <GenericCheckbox
                                                control={control}
                                                levelCol={24}
                                                inputCol={24}
                                                dataKey='Logo.ShowOnPdf'
                                                level={t('Show on PDF')}
                                            />
                                        </Col>

                                        <Col span={24}>
                                            <div className='logo-title'>Logo Information</div>
                                            <CoreFileLogo
                                                url={logoInfo.url}
                                                maxHeight={200}
                                                maxWidth={200}
                                                maxSize={800}
                                                onFileUpload={setLogoInfo}
                                            />
                                        </Col>
                                    </Row>
                                </form>
                            </Modal>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddDevices;
